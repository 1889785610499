import { gql } from '@apollo/client';

export const CoursesQuery = gql`
  query PaginatedCourses($query: String, $first: Int, $after: String) {
    allCourses(query: $query, first: $first, after: $after) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          educationForm
          category {
            name
          }
        }
      }
    }
  }
`;

export const GetCoursesForOptionsQuery = gql`
  query GetCoursesForOptions($agreementDate: Date!) {
    allCourses {
      edges {
        node {
          id
          name
          groups(hideCompleted: $agreementDate) {
            edges {
              node {
                id
                name
                price
                currency
              }
            }
          }
        }
      }
    }
  }
`;

export const GetCoursesForTeacherQuery = gql`
  query GetCoursesForTeacher($first: Int, $query: String) {
    allCourses(first: $first, query: $query) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GetCourseDataForGroupQuery = gql`
  query getCourseDataForGroup($id: ID!) {
    course(courseId: $id) {
      id
      name
      price
      currency
      teachers {
        edges {
          node {
            id
            fullName
          }
        }
      }
      modules {
        id
        name
        lessons {
          edges {
            node {
              id
              index
              name
              lmsDescription
              taskTypes
              teacherAttachments {
                edges {
                  node {
                    id
                  }
                }
              }
              studentAttachments {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GetCourseDataForApplicationQuery = gql`
  query getCourseDataForApplication($id: ID!, $groupsDate: Date!) {
    course(courseId: $id) {
      groups(hideCompleted: $groupsDate) {
        edges {
          node {
            id
            name
            freeLessonsCount
          }
        }
      }
    }
  }
`;
