import * as React from 'react';

import styled, { css } from 'styled-components';

import { IColors } from 'types';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { transFormAlignProp } from 'services/TransformService';

enum HeadingSizes {
  Small = 0,
  Medium = 1,
  Large = 2,
}

type AlignTypes = 'left' | 'center' | 'right';

export interface IHeading extends IHeadingStyle {
  children: React.ReactNode;
}

export interface IHeadingStyle {
  colors?: IColors;
  align?: AlignTypes;
  size?: HeadingSizes;
}

interface ISizeProps {
  fontFamily: string;
  fontWeight: string;
  fontSize: string;
  lineHeight: string;
}

const getSizeProps = (size?: HeadingSizes): ISizeProps => {
  switch (size) {
    case HeadingSizes.Large:
      return {
        fontFamily: getFontFamily('bold'),
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '40px',
      };
    default:
      return {
        fontFamily: getFontFamily('bold'),
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '40px',
      };
  }
};

export const Heading = styled.h4<IHeadingStyle>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }) => transFormAlignProp(align)};
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${({ colors }) => colors?.color || Colors.Black};
  background-color: ${({ colors }) =>
    colors?.backgroundColor || Colors.Transparent};
  font-style: normal;
  ${({ size }) => {
    const { fontFamily, fontWeight, fontSize, lineHeight } = getSizeProps(size);
    return css`
      font-family: ${fontFamily};
      font-weight: ${fontWeight};
      font-size: ${fontSize};
      line-height: ${lineHeight};
    `;
  }}
`;
