import React from 'react';
import { Switch } from 'antd';
import 'antd/dist/antd.css';

import styled from 'styled-components';

export interface ISwitcher {
  checked?: boolean;
  onClick?: (checked: boolean) => void;
}

export const Switcher = ({ checked, onClick }: ISwitcher) => (
  <SwitcherContainer>
    <Switch checked={checked} size="small" onClick={onClick} />
  </SwitcherContainer>
);

const SwitcherContainer = styled.div`
  .ant-switch-checked {
    background-color: #3cc13b;
  }
`;
