import { IGroup } from 'types';

import { GroupEdge } from 'core/graphql';

export const toIGroupArray = (edge: GroupEdge): IGroup | undefined => {
  const { node } = edge;
  if (node) {
    return {
      id: node.id,
      group: node.name,
      startDate: node.startDate,
      publishedLms: node.publishedLms,
      publishedLmsForTeacher: node.publishedLmsForTeacher,
      publishedLmsForMethodist: node.publishedLmsForMethodist,
      numberOfLessons: String(node.lessonsCount),
      numberOfStudents: String(node.studentsCount),
      lessonDays: node.classesDays.reduce(
        (acc: number[], cur) => [...acc, cur.weekDay],
        [],
      ),
      agreementsSum: `${node.contributionsSum} ${node.currency}`,
    };
  }
};
