import { makeVar } from '@apollo/client';
import { GlobalToastTypeNames, IToastData, NetworkErrors } from 'types';

export interface IAppGlobalToast {
  id: string;
  data: IToastData;
  type: GlobalToastTypeNames;
}

export interface IAppState {
  bootstrapped: boolean;
  toasts: IAppGlobalToast[] | null;
  networkError: NetworkErrors | null;
}

export const defaultAppState: IAppState = Object.freeze({
  bootstrapped: false,
  toasts: null,
  networkError: null,
});

export const appState = makeVar(defaultAppState);
