import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AddNewTeacherRoleMutationVariables,
  useAddNewTeacherRoleMutation,
  CustomErrorType,
} from 'core';
import {
  TeachersMetaQuery,
  TeachersQuery,
  ApplicationsQuery,
} from 'core/operations';

import {
  ServerCodes,
  ErrorsType,
  ToastTypes,
  GlobalToastTypeNames,
} from 'types';
import {
  IAddApplicationFieldsValues,
  ITeacherAddFieldsValues,
} from 'molecules';

import {
  applicationTeacherErrorsReducer,
  getApplicationErrors,
  useToasts,
} from 'core/hooks';

type IAddTeacherRoleHookType = [
  (teacherRole: AddNewTeacherRoleMutationVariables) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<
      ITeacherAddFieldsValues & IAddApplicationFieldsValues
    > | null;
  },
];

export const useAddTeacherRole = (): IAddTeacherRoleHookType => {
  const { t } = useTranslation();
  const [errors, dispatch] = useReducer(applicationTeacherErrorsReducer, null);
  const { addToast } = useToasts();

  const [
    addNewTeacherRoleMutation,
    { data, loading },
  ] = useAddNewTeacherRoleMutation({
    refetchQueries: [
      {
        query: TeachersQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: ApplicationsQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: TeachersMetaQuery,
      },
    ],
  });

  const addTeacherRole = useCallback(
    ({
      application,
      employmentTypes,
      siteDisplay,
      userId,
    }: AddNewTeacherRoleMutationVariables) => {
      addNewTeacherRoleMutation({
        variables: {
          application,
          employmentTypes,
          siteDisplay,
          userId,
        },
      });
    },
    [addNewTeacherRoleMutation],
  );

  useEffect(() => {
    const serverErrors = data?.addTeacherRole?.customErrors;
    if (serverErrors && serverErrors.length) {
      const errorsFound = getApplicationErrors(
        serverErrors as CustomErrorType[],
      );
      switch (true) {
        case errorsFound.applicationExist:
          dispatch({ type: ServerCodes.applicationTitleExist });
          break;
        case errorsFound.lessonCountExceeds:
          dispatch({ type: ServerCodes.lessonCountExceeds });
          break;

        default:
          addToast({
            data: {
              title: `${t('common.somethingWentWrong')}`,
              type: ToastTypes.error,
            },
            type: GlobalToastTypeNames.TeacherAdd,
          });
      }
    }
  }, [data?.addTeacherRole?.customErrors, addToast, t]);

  return [
    addTeacherRole,
    {
      success: data?.addTeacherRole?.success || false,
      loading,
      errors,
    },
  ];
};
