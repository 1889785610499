import React, { memo, useCallback } from 'react';
import { ValueType, OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
  Button,
  Form,
  Input,
  InputLabel,
  DateSelect,
  Select,
  WeekDaySelect,
  TimePicker,
  Text,
  IconButton,
  IconsNames,
  ISelectOption,
} from 'atoms';

import { Module, PopupContainer, PopupErrors } from 'molecules';

import { Colors } from 'services/ColorService';
import { WEEK_DAYS } from 'components/constants';
import { ICourse, IGroupModule, IconSizes, ErrorsType } from 'types';

type InputHandler<T> = (value: T, inputName: string) => void;

export interface IAddGroupPopup {
  onClose: () => void;
  onChangeInput: InputHandler<
    string | Date | null | ValueType<OptionTypeBase, false> | undefined
  >;
  errors?: ErrorsType<IAddGroupPopupValues> | null;
  errorMessage?: string | null;
  values: IAddGroupPopupValues;
  courses?: ICourse[];
  modules: {
    [key: string]: IGroupModule;
  };
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onAddLessonDay: () => void;
  onDeleteLessonDay: () => void;
}

type LessonDay = {
  id: string;
  weekDay: number;
  startTime: ValueType<OptionTypeBase, false>;
  endTime: ValueType<OptionTypeBase, false>;
};

export interface ICourses {
  value: string;
  label: string;
}

export interface IAddGroupPopupValues {
  course: ISelectOption<string> | null;
  groupNumber: string | null;
  price: string | null;
  currency: ISelectOption<string> | null;
  startDate: Date | null;
  lessonsDays: LessonDay[];
}

export const AddGroupPopupComponent = memo(
  ({
    onClose,
    onChangeInput,
    handleSubmit,
    onAddLessonDay,
    onDeleteLessonDay,
    errors,
    errorMessage,
    values,
    courses,
    modules,
  }: IAddGroupPopup) => {
    const { t } = useTranslation();
    const changeLessonDays = useCallback(
      (lessonDay: LessonDay, index: number) => {
        const lessonDays = [...values.lessonsDays];
        lessonDays[index] = lessonDay;
        onChangeInput(lessonDays, 'lessonsDays');
      },

      [onChangeInput, values.lessonsDays],
    );

    const showLessonDays = useCallback(
      () =>
        values.lessonsDays.map(({ id, weekDay, startTime, endTime }, index) => (
          <TimeWrapper key={id} index={index}>
            <WeekDayWrapper>
              <InputLabel>
                {t('groupPopup.fields.lessonDay.label')} {index + 1}
              </InputLabel>
              <WeekDaySelect
                hasError={Boolean(errors?.lessonsDays?.[index]?.weekDay)}
                onClick={(value) =>
                  changeLessonDays(
                    { id, weekDay: value, startTime, endTime },

                    index,
                  )
                }
                selected={weekDay}
              />
            </WeekDayWrapper>

            <TimePickerWrapper>
              <InputLabel>{t('groupPopup.fields.lessonTime.label')}</InputLabel>
              <TimePicker
                onChange={(value) =>
                  changeLessonDays(
                    { id, weekDay, startTime: value, endTime },
                    index,
                  )
                }
                value={startTime}
                hasError={Boolean(errors?.lessonsDays?.[index]?.startTime)}
              />
            </TimePickerWrapper>

            <TimePicker
              onChange={(value) =>
                changeLessonDays(
                  { id, weekDay, startTime, endTime: value },
                  index,
                )
              }
              value={endTime}
              hasError={Boolean(errors?.lessonsDays?.[index]?.endTime)}
            />
            {index !== 0 && (
              <WrapperDeleteLessonDays>
                <IconButton
                  name={IconsNames.CloseIconSquare}
                  size={IconSizes.xl}
                  onClick={onDeleteLessonDay}
                />
              </WrapperDeleteLessonDays>
            )}
          </TimeWrapper>
        )),

      [
        changeLessonDays,
        values.lessonsDays,
        onDeleteLessonDay,
        errors?.lessonsDays,
        t,
      ],
    );

    return (
      <PopupContainer title={t('groupPopup.addGroup')} onClose={onClose}>
        <Form onSubmit={handleSubmit}>
          <WrappedForm>
            <WrapperInput>
              <InputLabel hasError={Boolean(errors?.course)}>
                {t('groupPopup.fields.course.label')}
              </InputLabel>
              <Select
                type="default"
                placeholder={t('groupPopup.fields.course.placeholder')}
                options={courses?.map((course: ICourse) => ({
                  label: course.name,
                  value: course.id,
                }))}
                onChange={(value) => onChangeInput(value, 'course')}
                hasError={Boolean(errors?.course)}
              />
            </WrapperInput>

            <WrapperInput>
              <InputLabel hasError={Boolean(errors?.groupNumber)}>
                {t('groupPopup.fields.groupNumber.label')}
              </InputLabel>
              <Input
                placeholder={t('groupPopup.fields.groupNumber.placeholder')}
                type="text"
                value={values.groupNumber || ''}
                name="groupNumber"
                onChange={({ target: { value } }) =>
                  onChangeInput(value, 'groupNumber')
                }
                hasError={Boolean(errors?.groupNumber)}
              />
            </WrapperInput>

            <WrapperInput>
              <InputLabel hasError={Boolean(errors?.price)}>
                {t('groupPopup.fields.price.label')}
              </InputLabel>
              <InputWithSelect>
                <Input
                  placeholder={t('groupPopup.fields.price.placeholder')}
                  type="text"
                  value={values.price || ''}
                  name="price"
                  onChange={({ target: { value } }) =>
                    onChangeInput(value, 'price')
                  }
                  hasError={Boolean(errors?.price)}
                  financialNumber
                />
                <Select
                  type="primary"
                  width="128px"
                  isDisabled
                  value={values.currency}
                  defaultValue={null}
                />
              </InputWithSelect>
            </WrapperInput>

            <WrapperInput>
              <InputLabel hasError={Boolean(errors?.startDate)}>
                {t('groupPopup.fields.startDate.label')}
              </InputLabel>
              <DateSelect
                value={values.startDate}
                onChange={(value) => onChangeInput(value, 'startDate')}
                hasError={Boolean(errors?.startDate)}
              />
            </WrapperInput>
          </WrappedForm>
          <WrapperLessonDays>{showLessonDays()}</WrapperLessonDays>
          {values.lessonsDays.length < WEEK_DAYS.length && (
            <WrapperAddLessonDay>
              <IconButton
                name={IconsNames.PlusLargeIcon}
                size={IconSizes.xl}
                onClick={onAddLessonDay}
              />
              <WrapperText>
                <Text size="md">
                  {t('groupPopup.fields.addAnotherDay.label')}
                </Text>
              </WrapperText>
            </WrapperAddLessonDay>
          )}

          {modules &&
            Object.keys(modules).map((index) => (
              <>
                <Separator />
                <Module
                  key={`item-${index}`}
                  index={Number(index)}
                  onSubmitHandler={handleSubmit}
                />
              </>
            ))}
          <WrapperSendButton>
            <Button
              type="submit"
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
              }}
            >
              {t('groupPopup.button.addGroup')}
            </Button>
          </WrapperSendButton>
        </Form>
        <PopupErrorsStyled>{errorMessage}</PopupErrorsStyled>
      </PopupContainer>
    );
  },
);

const WrapperInput = styled.div<{ margin?: string; width?: string }>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin || 'initial'};
  width: ${({ width }) => width || '352px'};
`;

const WrappedForm = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  margin-top: 32px;
`;

const TimeWrapper = styled.div<{ index: number }>`
  display: flex;
  align-items: flex-end;
  margin-top: ${({ index }) => (index === 0 ? '0' : '24px')};
`;

const WeekDayWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperLessonDays = styled.div`
  margin: 24px 0 0 0;
`;

const WrapperDeleteLessonDays = styled.div`
  margin: 0 0 0 16px;
`;

const InputWithSelect = styled.div`
  display: flex;
  flex-direction: row;
`;

const TimePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 32px;
`;

const WrapperSendButton = styled.div`
  width: 224px;
  margin: 32px 0 0 auto;
`;

const Separator = styled.div`
  width: calc(100% + 128px);
  height: 1px;
  background-color: ${Colors.LightGray};
  margin: 40px -64px;
`;

const WrapperAddLessonDay = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 24px 0 0 0;
`;

const WrapperText = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 16px;
`;

const PopupErrorsStyled = styled((props) => <PopupErrors {...props} />)`
  margin-top: 32px;
`;
