import * as React from 'react';

import styled from 'styled-components';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

export interface IPaymentNumber {
  number: string | number;
}

export const PaymentNumber = ({ number }: IPaymentNumber) => (
  <StyledPaymentNumber>{number}</StyledPaymentNumber>
);

const StyledPaymentNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${Colors.Blue};
  color: ${Colors.White};
  font-family: ${getFontFamily('bold')};
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 40px;
`;
