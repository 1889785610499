import { gql } from '@apollo/client';

export const AppendTeacherApplicationMutation = gql`
  mutation appendTeacherApplication(
    $application: ApplicationInputType!
    $teacherId: ID!
  ) {
    addTeacherApplication(application: $application, teacherId: $teacherId) {
      success
      customErrors {
        field
        message
        code
      }
    }
  }
`;

export const UpdateTeacherApplicationMutation = gql`
  mutation updateTeacherApplication($application: UpdateApplicationInputType!) {
    updateTeacherApplication(application: $application) {
      success
      customErrors {
        field
        message
        code
      }
    }
  }
`;

export const DeleteApplication = gql`
  mutation removeApplication($id: ID!) {
    deleteTeacherApplication(applicationId: $id) {
      success
      errors
    }
  }
`;
