import * as Yup from 'yup';
import { TFunction } from 'i18next';

import {
  WRONG_SYMBOLS,
  _namesRegExp,
  _agreementTitleRegExp,
} from 'services/ValidationService';
import {
  YupFieldErrorTypes,
  yupValidation,
} from 'services/YupValidationService';
import { AccountsPayableType, Currencies, FieldErrorTypes } from 'types';
import { ISelectOption } from 'atoms';

export const getStudentPaymentSchema = (t: TFunction) =>
  Yup.object().shape({
    agreementTitle: Yup.string()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('agreementPopup.fields.agreementTitle.label')}`,
      })
      .matches(_agreementTitleRegExp, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: WRONG_SYMBOLS,
      })),
    date: yupValidation.date(`${t('agreementPopup.fields.date.label')}`),
    course: Yup.object()
      .nullable()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('agreementPopup.fields.course.label')}`,
      }),
    group: Yup.object()
      .nullable()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('agreementPopup.fields.group.label')}`,
      }),
    currency: Yup.object()
      .nullable()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('agreementPopup.fields.currency.label')}`,
      })
      .shape({
        label: Yup.string(),
        value: Yup.string().oneOf<Currencies>(
          Object.values(Currencies),
          () => ({
            type: FieldErrorTypes.VALIDATION,
            message: `${t('agreementPopup.validation.currency.invalid')}`,
          }),
        ),
      }),
    price: Yup.string()
      .nullable()
      .test({ ...yupValidation.validateNumber(t) })
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('agreementPopup.fields.price.label')}`,
      })
      .test(
        'accountsPayables',
        () => ({
          type: FieldErrorTypes.VALIDATION,
          message: `${t(
            'chargebackForm.validation.accountsPayable.payablesAmountTooBig',
          )}`,
          fieldName: 'accountsPayables',
        }),
        (price, { parent: { accountsPayables, currency } }) => {
          const payablesAmount = accountsPayables?.reduce(
            (payablesSum: number, payable: { amount?: number }) =>
              payablesSum + +(payable?.amount || 0),
            0,
          );
          if (
            payablesAmount > 0 &&
            !accountsPayables.every(
              (payable: AccountsPayableType) =>
                payable.currency.value === currency.value,
            )
          ) {
            return true;
          }

          return !price || payablesAmount <= +price;
        },
      )
      .test(
        'payments',
        () => ({
          type: FieldErrorTypes.VALIDATION,
          message: `${t('paymentForm.validation.sumsDontMatch')}`,
          fieldName: 'payments',
        }),
        (price, { parent: { accountsPayables, payments, currency } }) => {
          const paymentsAmount = Number(
            payments
              ?.reduce(
                (paymentsSum: number, payment: { sum: number }) =>
                  paymentsSum + +(payment?.sum || 0),
                0,
              )
              .toFixed(2),
          );

          const payablesAmount = Number(
            accountsPayables
              ?.reduce(
                (payablesSum: number, payable: { amount?: number }) =>
                  payablesSum + +(payable?.amount || 0),
                0,
              )
              .toFixed(2),
          );

          if (
            payablesAmount > 0 &&
            !accountsPayables.every(
              (payable: AccountsPayableType) =>
                payable.currency.value === currency.value,
            )
          ) {
            return true;
          }

          if (paymentsAmount === 0 && payablesAmount === 0) {
            return true;
          }

          if (!price) {
            return true;
          }

          return (
            Number((paymentsAmount + payablesAmount).toFixed(2)) ===
            Number(price)
          );
        },
      )
      .test(
        'payments',
        () => ({
          type: FieldErrorTypes.VALIDATION,
          message: `${t('paymentForm.validation.pleaseEnterData')}`,
          fieldName: 'payments_empty',
        }),
        (price, { parent: { accountsPayables, payments } }) => {
          const agreementCost = Number(price);
          const payablesAmount = accountsPayables?.reduce(
            (payablesSum: number, payable: { amount?: number }) =>
              payablesSum + Number(payable?.amount || 0),
            0,
          );
          const paymentsAmount = payments?.reduce(
            (paymentSum: number, payment: { sum?: number }) =>
              paymentSum + Number(payment?.sum || 0),
            0,
          );

          const needToFillPayments =
            agreementCost && paymentsAmount === 0 && payablesAmount === 0;

          return !needToFillPayments;
        },
      ),
    firstLesson: Yup.object().when('partialAttendance', {
      is: true,
      then: Yup.object().typeError({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('partialLessonForm.fields.firstLesson.labelSecond')}`,
      }),
      otherwise: Yup.object().nullable(),
    }),
    lastLesson: Yup.object().when('partialAttendance', {
      is: true,
      then: Yup.object().typeError({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('partialLessonForm.fields.lastLesson.label')}`,
      }),
      otherwise: Yup.object().nullable(),
    }),
    company: Yup.object().when('isPaymentByLegal', {
      is: true,
      then: Yup.object().typeError({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('companyForm.fields.company.label')}`,
      }),
      otherwise: Yup.object().nullable(),
    }),
    refundDate: Yup.date().when('isTerminatedAgreement', {
      is: true,
      then: yupValidation
        .date(`${t('chargebackForm.fields.refundDate.label')}`)
        .min(Yup.ref('date'), {
          type: FieldErrorTypes.VALIDATION,
          message: `${t('chargebackForm.validation.refundDate.dateIsEarly')}`,
        }),
      otherwise: Yup.date().nullable(),
    }),
    refundAmount: Yup.string().when('isTerminatedAgreement', {
      is: true,
      then: Yup.string()
        .test({ ...yupValidation.validateNumber(t) })
        .test(
          'refundAmount',
          {
            type: FieldErrorTypes.VALIDATION,
            fieldName: `${t('chargebackForm.fields.refundAmount.label')}`,
          },
          (value = '', context) => {
            const price = Number(context.parent.price || 0);
            const accountsPayable = Number(context.parent.accountsPayable || 0);
            const refundAmount = Number(value);

            if (Number.isNaN(accountsPayable) || Number.isNaN(refundAmount)) {
              return true;
            }

            return Boolean(price >= accountsPayable + refundAmount);
          },
        ),
      otherwise: Yup.string(),
    }),
    accountsPayable: Yup.string().when('isTerminatedAgreement', {
      is: true,
      then: Yup.string()
        .test({ ...yupValidation.validateNumber(t) })
        .test(
          'accountsPayable',
          {
            type: FieldErrorTypes.VALIDATION,
            fieldName: `${t('chargebackForm.fields.accountsPayable.label')}`,
          },
          (value = '', context) => {
            const price = Number(context.parent.price || 0);
            const refundAmount = Number(context.parent.refundAmount || 0);
            const accountsPayable = Number(value);

            if (Number.isNaN(accountsPayable) || Number.isNaN(refundAmount)) {
              return true;
            }

            return Boolean(price >= refundAmount + accountsPayable);
          },
        ),
      otherwise: Yup.string(),
    }),
    payments: Yup.array().when('date', (date: Date) =>
      date
        ? Yup.array().of(
            Yup.object().shape({
              date: Yup.date()
                .nullable()
                .min(date, {
                  type: FieldErrorTypes.VALIDATION,
                  message: `${t('paymentForm.validation.paymentIsEarly')}`,
                })
                .test(
                  'payments',
                  {
                    type: FieldErrorTypes.EMPTY,
                    fieldName: `${t('paymentForm.fields.date.label')}`,
                  },
                  (value, context) => {
                    const { parent } = context;
                    return Boolean(parent.date ? true : !parent.sum);
                  },
                ),
              sum: Yup.string()
                .test({ ...yupValidation.validateNumber(t) })
                .test(
                  'payments',
                  {
                    type: FieldErrorTypes.EMPTY,
                    fieldName: `${t('paymentForm.fields.sum.label')}`,
                  },
                  (value, context) => {
                    const { parent } = context;
                    return Boolean(parent.sum ? true : !parent.date);
                  },
                ),
            }),
          )
        : Yup.array().of(
            Yup.object().shape({
              date: Yup.date()
                .nullable()
                .test(
                  'payments',
                  {
                    type: FieldErrorTypes.EMPTY,
                    fieldName: `${t('paymentForm.fields.date.label')}`,
                  },
                  (value, context) => {
                    const { parent } = context;
                    return Boolean(parent.date ? true : !parent.sum);
                  },
                ),
              sum: Yup.string()
                .test({ ...yupValidation.validateNumber(t) })
                .test(
                  'payments',
                  {
                    type: FieldErrorTypes.EMPTY,
                    fieldName: `${t('paymentForm.fields.sum.label')}`,
                  },
                  (value, context) => {
                    const { parent } = context;
                    return Boolean(parent.sum ? true : !parent.date);
                  },
                ),
            }),
          ),
    ),
    accountsPayables: Yup.array().when(
      'currency',
      (currency: ISelectOption<Currencies>, schema: any) =>
        schema.of(
          Yup.object().shape({
            currency: Yup.object().test(
              'accountPayableCurrency',
              { type: YupFieldErrorTypes.ACCOUNT_PAYABLES },
              (accountPayableValue) =>
                Boolean(accountPayableValue?.value === currency?.value),
            ),
          }),
        ),
    ),
  });

export const getCompanySchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('companyForm.fields.name.label')}`,
      })
      .max(300, {
        type: YupFieldErrorTypes.VALIDATION,
        message: `${t('companyForm.validation.name.tooLong')}`,
      }),
    responsiblePerson: Yup.string()
      .required(() => ({
        type: YupFieldErrorTypes.EMPTY,
        fieldName: `${t('companyForm.fields.responsiblePerson.label')}`,
      }))
      .matches(_namesRegExp, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: WRONG_SYMBOLS,
      }))
      .max(100, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: `${t('companyForm.validation.responsiblePerson.tooLong')}`,
      })),
    phone: yupValidation.phone(`${t('companyForm.fields.phone.label')}`, t),
    email: yupValidation.email(`${t('companyForm.fields.email.label')}`, t),
    registrationNumber: Yup.number()
      .typeError({
        type: FieldErrorTypes.VALIDATION,
        message: `${t(
          'companyForm.validation.registrationNumber.onlyNumberAllowed',
        )}`,
      })
      .test(
        'registrationNumber',
        {
          type: YupFieldErrorTypes.VALIDATION,
          message: `${t('input.validation.lessThan20', {
            fieldName: `${t('companyForm.fields.registrationNumber.label')}`,
          })}`,
        },
        (value) => (value ? value.toString().length <= 20 : true),
      )
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('companyForm.fields.registrationNumber.label')}`,
      }),
  });
