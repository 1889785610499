import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { OptionTypeBase } from 'react-select';
import { Colors } from 'services/ColorService';
import { useTranslation } from 'react-i18next';

import { PopupContainer, PopupErrors } from 'molecules';
import {
  Button,
  Form,
  IconButton,
  IconsNames,
  Text,
  ISelectOption,
} from 'atoms';
import { IconSizes, TeacherHireType, ErrorsType } from 'types';

import { TeacherAddTypeFields } from 'molecules/TeacherAddTypePopup/TeacherAddTypeFields';

import { IIeFieldsValues } from 'molecules/TeacherAddTypePopup/IeFields';
import { ISeFieldsValues } from 'molecules/TeacherAddTypePopup/SeFields';
import { ILeFieldsValues } from 'molecules/TeacherAddTypePopup/LeFields';
import { INpFieldsValues } from 'molecules/TeacherAddTypePopup/NpFields';
import {
  TeacherEditFields,
  ITeacherEditFieldsValues,
} from './TeacherEditFields';

export interface ITeacherEditValues extends ITeacherEditFieldsValues {
  teacherTypes: {
    [TeacherHireType.Ie]: IIeFieldsValues;
    [TeacherHireType.Se]: ISeFieldsValues;
    [TeacherHireType.Le]: ILeFieldsValues;
    [TeacherHireType.Np]: INpFieldsValues;
    type: ISelectOption<string> | null;
    id: string;
    disabled?: boolean;
  }[];
}

export interface ITeacherEditPopup {
  values: ITeacherEditValues;
  errorMessage?: string | null;
  loading?: boolean;
  errors?: ErrorsType<ITeacherEditValues> | null;
  className?: string;
  countries: ISelectOption<string>[];
  disabledButton: boolean;
  teacherTypesOptions: ISelectOption<string>[];
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onAddType: () => void;
  onClose: () => void;
  onDeleteType: (index: number) => void;
  onInputChange: (
    name: string,
    value?: string | boolean | ISelectOption<string> | null | OptionTypeBase,
  ) => void;
}

export const TeacherEditPopupComponent = memo(
  ({
    values,
    errors,
    errorMessage,
    countries,
    teacherTypesOptions,
    disabledButton,
    onInputChange,
    handleSubmit,
    onClose,
    onAddType,
    onDeleteType,
    loading,
    className,
  }: ITeacherEditPopup) => {
    const { t } = useTranslation();
    return (
      <PopupContainer title={t('teacherPopup.editTeacher')} onClose={onClose}>
        <FormWrapper onSubmit={handleSubmit} className={className}>
          <TeacherEditFields
            values={values}
            errors={errors}
            onInputChange={onInputChange}
          />
          <Separator />
          {values?.teacherTypes.map((element, index) => (
            <TeacherAddTypeFields
              key={element.id}
              index={index}
              values={element}
              countries={countries}
              teacherTypesOptions={teacherTypesOptions}
              teacherTypesInputDisabled={
                !!(index === 0 && values.teacherTypes.length > 1) ||
                Boolean(element.disabled)
              }
              onInputChange={onInputChange}
              onDeleteType={onDeleteType}
            />
          ))}
          <WrapperSubmitButton>
            {Boolean(values.teacherTypes[0].type) &&
              values.teacherTypes.length < 4 && (
                <>
                  <IconButton
                    name={IconsNames.PlusLargeIcon}
                    size={IconSizes.xl}
                    onClick={onAddType}
                  />
                  <WrapperText>
                    <Text size="md">{t('teacherPopup.addTeacherType')}</Text>
                  </WrapperText>
                </>
              )}

            <SubmitButton
              type="submit"
              variant="primary"
              colors={{
                color: Colors.White,
                activeColor: Colors.Blue,
                backgroundColor: Colors.Blue,
                bgDisabledColor: Colors.DeactivatedGrey,
              }}
              loading={loading}
              disabled={disabledButton}
            >
              {t('teacherPopup.button.save')}
            </SubmitButton>
          </WrapperSubmitButton>
        </FormWrapper>
        <PopupErrorsStyled>{errorMessage}</PopupErrorsStyled>
      </PopupContainer>
    );
  },
);

const FormWrapper = styled(Form)`
  margin-top: 40px;
`;

const SubmitButton = styled(Button)`
  width: 258px;
  margin: 0 0 0 auto;
`;

const PopupErrorsStyled = styled(PopupErrors)`
  margin-top: 24px;
`;

const WrapperSubmitButton = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
`;

const WrapperText = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 16px;
`;

const Separator = styled.div`
  width: calc(100% + 128px);
  height: 1px;
  background-color: ${Colors.LightGray};
  margin: 40px -64px;
`;
