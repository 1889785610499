import * as React from 'react';
import { memo, ReactNode } from 'react';

import styled from 'styled-components';

import { Colors } from 'services/ColorService';

export interface IModal {
  children: ReactNode;
}

export const Modal = memo(({ children }: IModal) => (
  <ModalWrapper>
    <Inner>
      <ModalContent>{children}</ModalContent>
    </Inner>
  </ModalWrapper>
));

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${Colors.ModalBackground};
  overflow-x: hidden;
  overflow-y: auto;
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100vw;
  padding: 80px 0;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
