import React, { memo } from 'react';

import styled from 'styled-components';

import { Text } from 'atoms';
import { PopupContainer } from 'molecules';

export interface ICommentPopup {
  title: string;
  comment: string;
  onClose: () => void;
}

export const CommentPopupComponent = memo(
  ({ title, comment, onClose }: ICommentPopup) => (
    <PopupContainer onClose={onClose} title={title}>
      <TextWrapper size="md">{comment}</TextWrapper>
    </PopupContainer>
  ),
);

const TextWrapper = styled((props) => <Text {...props} />)`
  margin-top: 24px;
`;
