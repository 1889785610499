import { ILesson } from 'types';

import { usePaginatedJournalQuery } from 'core/graphql';
import { DateService } from 'services/DateService';

type JournalHookType = {
  journalName: string;
  comment: string;
  lessons: ILesson[];
  loading?: boolean;
  error?: string;
};

export const useJournal = (groupId: string): JournalHookType => {
  const { data, error, loading } = usePaginatedJournalQuery({
    variables: {
      groupId,
    },
  });

  const lessons: ILesson[] =
    data?.journal?.edges.reduce((lessonsDates: ILesson[], lessonEdge) => {
      if (lessonEdge && lessonEdge.node?.date) {
        const date = DateService.parseDate(lessonEdge.node.date, 'ISO');

        if (date) {
          return [
            ...lessonsDates,
            {
              id: lessonEdge.node?.id,
              date,
              lessonNumber: lessonEdge.node.lessonNumber || null,
            },
          ];
        }
      }

      return lessonsDates;
    }, []) || [];

  return {
    journalName: data?.journal?.groupName || '',
    comment: data?.journal?.comment || '',
    lessons,
    loading,
    error: error?.message,
  };
};
