import * as React from 'react';
import { FC, memo } from 'react';

import { CellProps, Row, useTable } from 'react-table';
import styled from 'styled-components';

import { Table, TableBody, TableHead, TableCell, TableRow } from 'atoms';

import { InfiniteScrollContainer, LoadingData, LoadingTable } from 'molecules';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { AdmissionStatusTypes } from 'types';

export type PerformanceAccessors =
  | 'id'
  | 'name'
  | 'group'
  | 'passRate'
  | 'courseProgress'
  | 'attendance'
  | 'graduationProject'
  | 'admissionStatus';

export type IPerformanceRow = {
  [key in PerformanceAccessors]: React.ReactNode;
} & {
  admissionStatus: AdmissionStatusTypes;
};

export interface IPerformanceColumn {
  accessor: PerformanceAccessors;
  flexible?: boolean;
  align?: 'left' | 'right' | 'center';
  Header?: string | React.ReactElement;
  columns?: IPerformanceColumn[];
  marginRight?: string;
  marginLeft?: string;
  columnWidth?: string;
  Cell?: string | ((props: CellProps<IPerformanceRow>) => React.ReactElement);
}

export interface IPerformancesTableComponent {
  columns: IPerformanceColumn[];
  rows: IPerformanceRow[];
  isFlexible: boolean;
  noDataText: string;
  hasMore: boolean;
  loading?: boolean;
  loadMore: () => void;
}

const getCellWrapper = (columnId: PerformanceAccessors): FC => {
  switch (columnId) {
    case 'id':
      return CourseTextWrapper;
    case 'name':
      return UsernameWrapper;
    case 'group':
      return CourseTextWrapper;
    case 'passRate':
      return CourseDefaultWrapper;
    case 'courseProgress':
      return CourseDefaultWrapper;
    case 'attendance':
      return CourseDefaultWrapper;
    case 'graduationProject':
      return CourseDefaultWrapper;
    case 'admissionStatus':
      return CourseDefaultWrapper;
    default:
      return CourseDefaultWrapper;
  }
};

export const PerformancesTableComponent = memo(
  ({
    columns,
    rows: rowsSource,
    isFlexible,
    noDataText,
    hasMore,
    loading,
    loadMore,
  }: IPerformancesTableComponent) => {
    const {
      getTableProps,
      headers,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data: rowsSource,
    });

    return (
      <Wrapper>
        <InfiniteScrollContainer
          loadMore={loadMore}
          hasMore={hasMore}
          dataLength={rows.length}
          loading={loading || false}
        >
          <Table {...getTableProps()} flexible={isFlexible} rowShadowOnHover>
            <TableHead>
              <TableRow type="row">
                {headers.map((column: any) => {
                  const {
                    key: cellKey,
                    ...restCellProps
                  } = column.getHeaderProps({
                    type: 'headerCell',
                    flexible: column.flexible && isFlexible,
                    width: column.columnWidth,
                    margin: {
                      right: column.marginRight,
                      left: column.marginLeft,
                    },
                  });
                  return (
                    <TableCell key={cellKey} {...restCellProps}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {!rows.length && !loading && (
                <TableRow key="noData">
                  <TableCell type="cell" width="100%" align="center">
                    {noDataText}
                  </TableCell>
                </TableRow>
              )}
              {!rows.length && loading && (
                <TableRow>
                  <TableCell width="100%" align="center">
                    <LoadingTable />
                  </TableCell>
                </TableRow>
              )}
              {Boolean(rows.length) &&
                rows.map((row: Row<IPerformanceRow>) => {
                  prepareRow(row);
                  const { key: rowKey, ...restRowProps } = row.getRowProps();

                  return (
                    <WrappedTableRow key={rowKey} {...restRowProps}>
                      {row.cells.map((cell: any) => {
                        const {
                          key: cellKey,
                          ...restCellProps
                        } = cell.getCellProps({
                          width: cell.column.columnWidth,
                          margin: {
                            right: cell.column.marginRight,
                            left: cell.column.marginLeft,
                          },
                          flexible: cell.column.flexible && isFlexible,
                        });

                        const columnId = cell.column.id;
                        const CellWrapper = getCellWrapper(columnId);
                        return (
                          <TableCell
                            key={cellKey}
                            {...restCellProps}
                            align="left"
                          >
                            <CellWrapper>{cell.render('Cell')}</CellWrapper>
                          </TableCell>
                        );
                      })}
                    </WrappedTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </InfiniteScrollContainer>
        {Boolean(rows.length) && loading && <LoadingData />}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 60px;
`;

const CourseDefaultWrapper = styled.span`
  font-family: ${getFontFamily()};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${Colors.Black};
`;

const UsernameWrapper = styled(CourseDefaultWrapper)`
  position: relative;
  width: auto;
`;

const CourseTextWrapper = styled(CourseDefaultWrapper)`
  color: ${Colors.Blue};
`;

const WrappedTableRow = styled((props) => (
  <TableRow {...props}>{props.children}</TableRow>
))`
  position: relative;
`;
