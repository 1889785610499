import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

import styled from 'styled-components';

export interface IInputLabel extends IInputLabelStyle {
  children: React.ReactNode;
  extraLink?: IExtraLink;
  extraLinkOnlyWhenError?: boolean;
  isOptional?: boolean;
}

export interface IExtraLink {
  title: string;
  onClick: () => void;
}

export interface IInputLabelStyle {
  color?: string;
  hasError?: boolean;
}

const getLabelColor = (color?: string, hasError?: boolean) => {
  if (hasError) return Colors.Red;
  return color || Colors.Black;
};

const getExtraLink = (
  extraLink: IExtraLink,
  hasError?: boolean,
  eextraLinkOnlyWhenError?: boolean,
) => {
  const extraLinkComponent = (
    <LinkStyled onClick={extraLink.onClick}>{extraLink.title}</LinkStyled>
  );

  if (eextraLinkOnlyWhenError) {
    return hasError ? extraLinkComponent : null;
  }

  return extraLinkComponent;
};

export const InputLabel = memo(
  ({
    children,
    color,
    extraLink,
    extraLinkOnlyWhenError,
    hasError,
    isOptional = false,
  }: IInputLabel) => {
    const { t } = useTranslation();
    return (
      <LabelContainer>
        <LabelStyled color={color} hasError={hasError}>
          {children}
        </LabelStyled>
        {isOptional && (
          <LabelStyledOptional>{t('input.optional')}</LabelStyledOptional>
        )}
        {extraLink && getExtraLink(extraLink, hasError, extraLinkOnlyWhenError)}
      </LabelContainer>
    );
  },
);

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LabelStyledOptional = styled.div`
  color: ${Colors.Gray};
  background-color: ${Colors.Transparent};
  font-family: ${getFontFamily('regular')};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  opacity: 0.8;
`;

const LabelStyled = styled.div<IInputLabelStyle>`
  color: ${({ color, hasError }) => getLabelColor(color, hasError)};
  background-color: ${Colors.Transparent};
  font-family: ${getFontFamily('bold')};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
`;

const LinkStyled = styled.a`
  color: ${Colors.Blue};
  text-decoration: none;
  cursor: pointer;
  font-family: ${getFontFamily('regular')};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;
