import { useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { nanoid } from 'nanoid';
import { SortEnd } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { useTranslation } from 'react-i18next';

import { modulesStateVar } from 'core/state/moduleState';
import { applicationOptionsStateVar } from 'core/state/applicationOptionsState';
import { ErrorsType, FieldErrorTypes, IGroupModuleLessons } from 'types';
import type { IModuleLesson, IModuleValues } from 'molecules';

export interface IModuleHook {
  index: number;
}

export const useModulesData = () => useReactiveVar(modulesStateVar).modules;

export const useModule = ({ index }: IModuleHook) => {
  const { t } = useTranslation();
  const { modules } = useReactiveVar(modulesStateVar);
  const { applicationOptions } = useReactiveVar(applicationOptionsStateVar);
  const [errors, setErrors] = useState<ErrorsType<IModuleValues> | null>(null);

  const { lessons } = modules[index] || { lessons: [] };
  const { disabledLessons } = modules[index] || { disabledLessons: [] };
  const { showInput } = modules[index] || {};
  const { name } = modules[index];
  const allLessonsName = useMemo(() => {
    if (disabledLessons?.length) {
      return [...lessons, ...disabledLessons].map(
        (lesson) => lesson.lessonName,
      );
    }
    return lessons.map((lesson) => lesson.lessonName);
  }, [lessons, disabledLessons]);

  const allCheckedLessons = useMemo(() => lessons.filter((e) => e.checked), [
    lessons,
  ]);

  const allLessons = useMemo(
    () => Boolean(lessons.length && lessons.every(({ checked }) => checked)),
    [lessons],
  );

  const showWithoutTeacherOption = useMemo(
    () => Boolean(lessons.find((e) => e.checked && e.teacher?.value)),
    [lessons],
  );

  const onShowInput = () => {
    modulesStateVar({
      ...modulesStateVar(),

      modules: {
        ...modules,
        [index]: { ...modules[index], showInput: !showInput },
      },
    });
  };

  const onCloseInput = () => {
    modulesStateVar({
      ...modulesStateVar(),

      modules: {
        ...modules,
        [index]: { ...modules[index], showInput: !showInput },
      },
    });
    setErrors(null);
  };

  const onAddLesson = ({ lessonInputName, isAdditional }: IModuleValues) => {
    if (!lessonInputName.length) {
      setErrors({
        lessonInputName: {
          type: FieldErrorTypes.EMPTY,
          fieldName: `${t('common.lesson')}`,
        },
      });
      return;
    }

    if (allLessonsName.includes(lessonInputName)) {
      setErrors({
        lessonInputName: {
          type: FieldErrorTypes.VALIDATION,
          fieldName: `${t('common.lesson')}`,
          message: `${t('common.lessonExist')}`,
        },
      });
      return;
    }

    const newLessons: IGroupModuleLessons[] = [
      ...lessons,
      {
        checked: false,
        lessonName: lessonInputName,
        additional: isAdditional,
        teacher: null,
        lessonId: nanoid(6),
        lmsDescription: '',
        isNewLesson: true,
      },
    ];

    modulesStateVar({
      ...modulesStateVar(),
      modules: {
        ...modules,
        [index]: {
          ...modules[index],
          showInput: !showInput,
          lessons: newLessons,
        },
      },
    });

    setErrors(null);
  };

  const onAddLessonForEditing = ({
    lessonInputName,
    isAdditional,
  }: IModuleValues) => {
    if (!lessonInputName.length) {
      setErrors({
        ...errors,
        lessonInputName: {
          type: FieldErrorTypes.EMPTY,
          fieldName: `${t('common.lesson')}`,
        },
      });
      return;
    }

    if (allLessonsName.includes(lessonInputName)) {
      setErrors({
        ...errors,
        lessonInputName: {
          type: FieldErrorTypes.VALIDATION,
          fieldName: `${t('common.lesson')}`,
          message: `${t('common.lessonExist')}`,
        },
      });
      return;
    }

    const newLessons: IGroupModuleLessons[] = [
      ...lessons,
      {
        checked: false,
        lessonName: lessonInputName,
        additional: isAdditional,
        teacher: null,
        lessonId: nanoid(6),
        lmsDescription: '',
        isNewLesson: true,
      },
    ];

    modulesStateVar({
      ...modulesStateVar(),
      modules: {
        ...modules,
        [index]: {
          ...modules[index],
          showInput: !showInput,
          lessons: newLessons,
        },
      },
    });

    setErrors(null);
  };

  const onToggleAllLessons = () => {
    const lessonsWithToggledAllLessons = lessons.reduce(
      (accumulator: IModuleLesson[], lesson) => [
        ...accumulator,
        {
          ...lesson,
          checked: !allLessons,
        },
      ],
      [],
    );

    modulesStateVar({
      ...modulesStateVar(),
      modules: {
        ...modules,
        [index]: {
          ...modules[index],
          lessons: lessonsWithToggledAllLessons,
        },
      },
    });

    setErrors(null);
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const sortedLessons = arrayMove(lessons, oldIndex, newIndex);

    modulesStateVar({
      ...modulesStateVar(),
      modules: {
        ...modules,
        [index]: {
          ...modules[index],
          lessons: sortedLessons,
        },
      },
    });
  };

  const onSetTeacher = ({
    application: selectedApplication,
  }: IModuleValues) => {
    if (
      allCheckedLessons.length > Number(selectedApplication?.freeLessonsCount)
    ) {
      setErrors({
        ...errors,
        application: {
          type: FieldErrorTypes.VALIDATION,
          fieldName: `${t('common.setTeacher')}`,
          message: `${t('common.moreLessonsThanInApplication')}`,
        },
      });
      return;
    }

    const lessonsWithUpdatedTeachers = lessons.reduce(
      (accumulator: IModuleLesson[], lesson) => [
        ...accumulator,
        {
          ...lesson,
          checked: lesson.checked ? !lesson.checked : lesson.checked,
          teacher:
            lesson.checked && selectedApplication
              ? {
                  value: selectedApplication?.value,
                  label: selectedApplication?.teacherName,
                }
              : lesson.teacher,
        },
      ],
      [],
    );

    const newApplicationOptions = applicationOptions.map((application) => {
      const newAllCheckedLessons = allCheckedLessons.filter(
        (checkedLesson) =>
          !checkedLesson.teacher?.value ||
          checkedLesson.teacher?.value !== selectedApplication?.value,
      );
      const otherAllCheckedLessons = allCheckedLessons.filter(
        (checkedLesson) => checkedLesson.teacher?.value === application.value,
      );

      return application.value === selectedApplication?.value
        ? {
            ...application,
            freeLessonsCount:
              application.freeLessonsCount - newAllCheckedLessons.length,
          }
        : {
            ...application,
            freeLessonsCount:
              application.freeLessonsCount + otherAllCheckedLessons.length,
          };
    });

    modulesStateVar({
      ...modulesStateVar(),
      modules: {
        ...modules,
        [index]: {
          ...modules[index],
          lessons: lessonsWithUpdatedTeachers,
        },
      },
    });

    applicationOptionsStateVar({
      ...applicationOptionsStateVar(),
      applicationOptions: newApplicationOptions,
    });

    setErrors(null);
  };

  const onChooseLesson = (lessonId: string) => {
    const lessonsWithChooseLessons = lessons.reduce(
      (accumulator: IModuleLesson[], lesson) => [
        ...accumulator,
        {
          ...lesson,
          checked:
            lesson.lessonId === lessonId ? !lesson.checked : lesson.checked,
        },
      ],
      [],
    );

    modulesStateVar({
      ...modulesStateVar(),
      modules: {
        ...modules,
        [index]: {
          ...modules[index],
          lessons: lessonsWithChooseLessons,
        },
      },
    });

    setErrors(null);
  };

  return {
    onShowInput,
    onAddLesson,
    onAddLessonForEditing,
    onToggleAllLessons,
    onSortEnd,
    onSetTeacher,
    onChooseLesson,
    onCloseInput,
    showInput,
    allLessons,
    lessons,
    disabledLessons: disabledLessons || [],
    applicationOptions,
    showWithoutTeacherOption,
    name,
    errors,
  };
};
