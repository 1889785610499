import { useCallback } from 'react';
import { IGroup } from 'types';

import {
  usePaginatedGroupsQuery,
  GroupEdge,
  PaginatedGroupsQueryVariables,
  PageInfo,
} from 'core/graphql';

import { toIGroupArray } from './transform';

type GroupsHookType = {
  data: IGroup[];
  loading?: boolean;
  error?: string;
  totalCount?: number | null;
  pageInfo?: Pick<
    PageInfo,
    'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
  >;
  operations: {
    fetchMoreData: (vars: PaginatedGroupsQueryVariables) => void;
  };
};

const useGetGroupsFromApi = () => {
  const { data, error, loading, fetchMore } = usePaginatedGroupsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      first: 10,
    },
    notifyOnNetworkStatusChange: true,
  });

  const result = (): IGroup[] => {
    const edges = data?.allGroups?.edges;
    if (!edges) {
      return [];
    }

    return edges.reduce((acc: IGroup[], edge) => {
      if (edge) {
        const groupArr = toIGroupArray(edge as GroupEdge);
        if (groupArr) {
          return [...acc, groupArr];
        }
      }

      return acc;
    }, []);
  };

  return {
    fetchMore,
    result: result(),
    error,
    loading,
    pageInfo: data?.allGroups?.pageInfo,
    totalCount: data?.allGroups?.totalCount,
  };
};

export const useGroups = (): GroupsHookType => {
  const {
    result,
    error,
    loading,
    totalCount,
    fetchMore = () => null,
    pageInfo,
  } = useGetGroupsFromApi();

  const fetchMoreData = useCallback(
    ({ query, after }: PaginatedGroupsQueryVariables) => {
      fetchMore({
        variables: {
          query,
          after,
        },
      });
    },
    [fetchMore],
  );

  return {
    data: result || [],
    loading,
    pageInfo,
    error: error?.message,
    totalCount,
    operations: {
      fetchMoreData,
    },
  };
};
