import { invalidPhoneError } from 'components/constants';
import { ITeacherAddFieldsValues } from 'molecules';
import { ServerCodes, ErrorsType, FieldErrorTypes } from 'types';
import i18n from '../../../i18n';

interface ITeacherErrorsAction {
  type: ServerCodes;
}

export const teacherErrorsReducer = (
  state: ErrorsType<ITeacherAddFieldsValues> | null,
  action: ITeacherErrorsAction,
): ErrorsType<ITeacherAddFieldsValues> | null => {
  switch (action.type) {
    case ServerCodes.invalidPhone:
      return invalidPhoneError;
    case ServerCodes.teacherExist:
      return {
        phone: {
          type: FieldErrorTypes.SERVER_ERROR,
          message: `${i18n.t('common.userWithMailAndPhoneExist')}`,
        },
        email: {
          type: FieldErrorTypes.SERVER_ERROR,
          message: `${i18n.t('common.userWithMailAndPhoneExist')}`,
        },
      };
    case ServerCodes.phoneExist:
      return {
        phone: {
          type: FieldErrorTypes.SERVER_ERROR,
          message: `${i18n.t('common.userWithPhoneExist')}`,
        },
      };
    case ServerCodes.emailExist:
      return {
        email: {
          type: FieldErrorTypes.SERVER_ERROR,
          message: `${i18n.t('common.userWithMailExist')}`,
        },
      };
    default:
      return null;
  }
};
