import * as React from 'react';
import { memo } from 'react';
import { Row, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
  Icon,
  IconButton,
  IconsNames,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'atoms';

import { LoadingData, LoadingTable, InfiniteScrollContainer } from 'molecules';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { IconSizes } from 'components/types';
import { Link } from 'react-router-dom';

type Accessors = 'id' | 'name' | 'teachersString' | 'course';

export type IRow = {
  [key in Accessors]: React.ReactNode;
};

export interface IColumn {
  accessor: Accessors;
  flexible?: boolean;
  align?: 'left' | 'right' | 'center';
  Header?: string | React.ReactElement;
  columns?: IColumn[];
  marginRight?: string;
  marginLeft?: string;
  columnWidth?: string;
}

export interface IJournalsTableComponent {
  columns: IColumn[];
  rows: IRow[];
  isFlexible: boolean;
  noDataText: string;
  hasMore: boolean;
  loadMore: () => void;
  onClickEdit: (journalId: string) => void;
  loading?: boolean;
}

export const JournalsTableComponent = memo(
  ({
    isFlexible,
    noDataText,
    rows: rowsSource,
    columns,
    hasMore,
    loadMore,
    onClickEdit,
    loading,
  }: IJournalsTableComponent) => {
    const { t } = useTranslation();
    const {
      getTableProps,
      headers,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data: rowsSource,
    });

    return (
      <Wrapper>
        <InfiniteScrollContainer
          loadMore={loadMore}
          hasMore={hasMore}
          dataLength={rows.length}
          loading={loading || false}
        >
          <Table {...getTableProps()} flexible={isFlexible} rowShadowOnHover>
            <TableHead>
              <TableRow type="row">
                {headers.map((column: any) => {
                  const {
                    key: cellKey,
                    ...restCellProps
                  } = column.getHeaderProps({
                    type: 'headerCell',
                    flexible: column.flexible && isFlexible,
                    width: column.columnWidth,
                    margin: {
                      right: column.marginRight,
                      left: column.marginLeft,
                    },
                  });

                  return (
                    <TableCell key={cellKey} {...restCellProps}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {!rows.length && !loading && (
                <TableRow key="noData">
                  <TableCell type="cell" width="100%" align="center">
                    {noDataText}
                  </TableCell>
                </TableRow>
              )}
              {!rows.length && loading && (
                <TableRow>
                  <TableCell width="100%" align="center">
                    <LoadingTable />
                  </TableCell>
                </TableRow>
              )}
              {Boolean(rows.length) &&
                rows.map((row: Row<IRow>) => {
                  prepareRow(row);
                  const { key: rowKey, ...restRowProps } = row.getRowProps();

                  return (
                    <IconContainer key={rowKey} type="row" {...restRowProps}>
                      {row.cells.map((cell: any, index) => {
                        const {
                          key: cellKey,
                          ...restCellProps
                        } = cell.getCellProps({
                          width: cell.column.columnWidth,
                          flexible: cell.column.flexible && isFlexible,
                          margin: {
                            right: cell.column.marginRight,
                            left: cell.column.marginLeft,
                          },
                        });

                        return (
                          <JournalsCellWrapper
                            key={cellKey}
                            textColor={!index ? Colors.Blue : Colors.Black}
                            {...restCellProps}
                          >
                            {!index && `${t('table.journal.name')}`}
                            {cell.render('Cell')}
                          </JournalsCellWrapper>
                        );
                      })}

                      <WrappedOptions>
                        <Link to={`/journal/${row.original.id}`}>
                          <IconStyled
                            name={IconsNames.Table}
                            width="32"
                            height="32"
                          />
                        </Link>
                        <WrappedEdit>
                          <IconButtonStyled
                            name={IconsNames.Edit}
                            size={IconSizes.lg}
                            onClick={() =>
                              onClickEdit(row.original.id as string)
                            }
                          />
                        </WrappedEdit>
                      </WrappedOptions>
                    </IconContainer>
                  );
                })}
            </TableBody>
          </Table>
        </InfiniteScrollContainer>
        {Boolean(rows.length) && loading && <LoadingData />}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 60px;
`;

const JournalsCellWrapper = styled((props) => <TableCell {...props} />)`
  font-family: ${getFontFamily('medium')};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
`;

const WrappedEdit = styled.div`
  margin-left: 24px;
  height: 32px;
`;

const IconContainer = styled((props) => (
  <TableRow {...props}>{props.children}</TableRow>
))`
  position: relative;
`;

const WrappedOptions = styled.td`
  position: absolute;
  top: 12px;
  right: 24px;
  bottom: 12px;
  display: none;
  align-items: center;

  ${IconContainer}:hover & {
    display: flex;
  }
`;

const IconButtonStyled = styled((props) => <IconButton {...props} />)`
  display: flex;
  height: 32px;
  width: 32px;
  border-radius: 100%;

  :hover {
    & svg {
      & > path {
        fill: ${Colors.White};
      }
      & > circle {
        fill: ${Colors.Blue};
      }
    }
  }
`;

const IconStyled = styled((props) => <Icon {...props} />)`
  :hover {
    & > path {
      fill: ${Colors.White};
    }
    & > circle {
      fill: ${Colors.Blue};
    }
  }
`;
