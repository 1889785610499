import React, { memo, useCallback, useState } from 'react';
import { ValueType, OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Button, Text, CheckBox, Row, ISelectOption } from 'atoms';
import { PopupContainer, PopupErrors } from 'molecules';

import { Colors } from 'services/ColorService';

import {
  AccountsPayableType,
  ICourseOption,
  OptionsType,
  ErrorsType,
  Currencies,
} from 'types';
import { ContractForm } from './ContractForm';
import { CompanyFormContainer } from './CompanyFormContainer';
import {
  AccountsPayablesHandler,
  PaymentInputHandler,
  PaymentsForm,
} from './PaymentsForm';
import { PartialLessonsForm } from './PartialLessonsForm';

type InputHandler<T> = (value: T, inputName: string) => void;

export interface IStudentPaymentPopup {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onChangeInput: InputHandler<
    string | boolean | ValueType<OptionTypeBase, false>
  >;
  onChangePayment: PaymentInputHandler<string | Date | null>;

  onClose: () => void;
  onAddPayment: () => void;
  onClosePayment: (index: number) => void;
  onAddAccountPayable: () => void;
  onChangeAccountPayable: AccountsPayablesHandler<
    ValueType<OptionTypeBase, false>
  >;
  onCloseAccountPayment: (index: number) => void;
  onChangeCurrency: (currency: ValueType<OptionTypeBase, false>) => void;
  values: IStudentPaymentPopupValues;
  errors?: ErrorsType<IStudentPaymentPopupValues> | null;
  errorMessage?: string | null;
  accountsPayables?: AccountsPayableType[];
  coursesLoading?: boolean;
  coursesOptions: ICourseOption[];
  companiesOptions: ISelectOption<string>[];
  lessonsOptions: OptionsType;
  countriesOptions: ISelectOption<string>[];
}

export interface IStudentPaymentPopupValues {
  payments: IPayments[];
  agreementTitle: string;
  course: ValueType<OptionTypeBase, false>;
  group: ValueType<OptionTypeBase, false>;
  promocode: string;
  legalEntity?: ValueType<OptionTypeBase, false>;
  price: string;
  isPaymentByLegal: boolean;
  isVAT: boolean;
  currency: ISelectOption<Currencies> | null;
  company: ValueType<OptionTypeBase, false>;
  country: ISelectOption<string> | null;
  legalEntityName: string;
  responsiblePersonFullName: string;
  responsiblePersonPhone: string;
  responsiblePersonEmail: string;
  UNP: string;
  isNotResident: boolean;
  date: Date | null;
  partialAttendance: boolean;
  firstLesson: ValueType<OptionTypeBase, false>;
  lastLesson: ValueType<OptionTypeBase, false>;
  accountsPayables: AccountsPayableType[];
}

export interface IPayments {
  date: Date | null;
  sum: string;
  id: number;
  isPaid?: boolean;
}

export const StudentPaymentPopupComponent = memo(
  ({
    values,
    errors,
    errorMessage,
    coursesLoading = false,
    coursesOptions,
    companiesOptions,
    countriesOptions,
    lessonsOptions,
    accountsPayables,
    onClose,
    handleSubmit,
    onChangeInput,
    onChangePayment,
    onAddPayment,
    onClosePayment,
    onAddAccountPayable,
    onChangeAccountPayable,
    onCloseAccountPayment,
    onChangeCurrency,
  }: IStudentPaymentPopup) => {
    const { t } = useTranslation();
    const [isShowLegalForm, setShowLegalForm] = useState(false);

    const changePartialAttendance = useCallback(() => {
      onChangeInput(!values.partialAttendance, 'partialAttendance');

      if (values.partialAttendance) {
        onChangeInput(null, 'firstLesson');
        onChangeInput(null, 'lastLesson');
      }
    }, [onChangeInput, values.partialAttendance]);

    return (
      <PopupContainer
        onClose={onClose}
        title={t('agreementPopup.addAgreement')}
      >
        <ContractForm
          values={values}
          errors={errors}
          coursesLoading={coursesLoading}
          coursesOptions={coursesOptions}
          onChangeInput={onChangeInput}
          countriesOptions={countriesOptions}
          onChangeCurrency={onChangeCurrency}
        />

        <CheckboxRow>
          <WrappedCheckbox
            checked={values.isPaymentByLegal}
            onClick={() =>
              onChangeInput(!values.isPaymentByLegal, 'isPaymentByLegal')
            }
          />
          <Text size="md">{t('agreementPopup.isPaymentByLegal')}</Text>

          <WrappedCheckbox
            right
            checked={values.partialAttendance}
            onClick={changePartialAttendance}
          />
          <Text size="md">{t('agreementPopup.partialAttendance')}</Text>
        </CheckboxRow>

        {values.partialAttendance && (
          <PartialLessonsForm
            values={values}
            errors={errors}
            lessonsOptions={lessonsOptions}
            onChangeInput={onChangeInput}
          />
        )}

        {values.isPaymentByLegal && (
          <CompanyFormContainer
            company={values?.company}
            companyError={Boolean(errors?.company)}
            isShowLegalForm={isShowLegalForm}
            setShowLegalForm={setShowLegalForm}
            companiesOptions={companiesOptions}
            onChangeCompany={onChangeInput}
          />
        )}

        <Separator />

        <PaymentsForm
          values={values}
          errors={errors}
          accountsPayables={accountsPayables}
          onChangePayment={onChangePayment}
          onAddPayment={onAddPayment}
          onClosePayment={onClosePayment}
          onAddAccountPayable={onAddAccountPayable}
          onChangeAccountPayable={onChangeAccountPayable}
          onCloseAccountPayment={onCloseAccountPayment}
        />

        <Row>
          <WrappedCheckbox
            checked={values.isVAT}
            onClick={() => onChangeInput(!values.isVAT, 'isVAT')}
          />
          <Text>{t('agreementPopup.isVat')}</Text>
          <WrappedCheckboxRight
            checked={values.isNotResident}
            onClick={() =>
              onChangeInput(!values.isNotResident, 'isNotResident')
            }
          />
          <Text>{t('agreementPopup.isNotResident')}</Text>

          <WrapperSendButton
            type="submit"
            onClick={handleSubmit}
            colors={{
              backgroundColor: Colors.Blue,
              color: Colors.White,
            }}
          >
            {t('agreementPopup.button.save')}
          </WrapperSendButton>
        </Row>

        <Row>
          <PopupErrors>{errorMessage}</PopupErrors>
        </Row>
      </PopupContainer>
    );
  },
);

const CheckboxRow = styled.div`
  display: flex;
  margin-top: 32px;
`;

const WrappedCheckbox = styled((props) => <CheckBox {...props} />)`
  margin-right: 12px;
  margin-left: ${({ right }) => (right ? '24px' : '0')};
`;

const WrappedCheckboxRight = styled((props) => <WrappedCheckbox {...props} />)`
  margin-left: 16px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${Colors.LightGray};
  margin: 40px -64px;
`;

const WrapperSendButton = styled((props) => <Button {...props} />)`
  margin-left: auto;
  width: 209px;
`;
