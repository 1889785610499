import { useCallback, useMemo } from 'react';
import { OptionTypeBase, ValueType } from 'react-select';
import * as Ramda from 'ramda';
import { nanoid } from 'nanoid';
import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';

import { ISelectOption } from 'atoms';
import {
  IPayments,
  IStudentPaymentPopupValues,
} from 'molecules/StudentPaymentPopup';
import { IEditAgreementPopupValues } from 'molecules';
import { AccountsPayableType, Currencies } from 'types';
import { useCountriesOptions } from 'core/hooks';

type StudentPaymentHelpersType = {
  countriesOptions: ISelectOption<string>[];
  onChangePayment: (
    value: string | Date | null,
    name: string,
    index: number,
  ) => void;
  onAddPayment: () => void;
  onClosePayment: (index: number) => void;
  onChangeAccountPayable: (value: OptionTypeBase | null, index: number) => void;
  onAddAccountPayable: () => void;
  onCloseAccountPayment: (index: number) => void;
  onChangeInput: (
    value: string | boolean | number | ValueType<OptionTypeBase, false>,
    name: string,
  ) => void;
  onChangeCurrency: (currency: ValueType<OptionTypeBase, false>) => void;
};

interface IStudentPaymentArguments {
  values: IStudentPaymentPopupValues | IEditAgreementPopupValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<IStudentPaymentPopupValues | IEditAgreementPopupValues>
      >;
}

export const useStudentPaymentHelpers = ({
  values,
  setFieldValue,
}: IStudentPaymentArguments): StudentPaymentHelpersType => {
  const { t } = useTranslation();
  const { countries } = useCountriesOptions();

  const onChangePayment = useCallback(
    (value: string | Date | null, name: string, index: number) => {
      const newValue = values.payments.reduce(
        (acc: IPayments[], cur: IPayments, indx: number) =>
          indx === index ? [...acc, { ...cur, [name]: value }] : [...acc, cur],
        [],
      );
      setFieldValue('payments', newValue);
    },
    [values, setFieldValue],
  );

  const onAddPayment = useCallback(
    () =>
      setFieldValue('payments', [
        ...values.payments,
        {
          date: '',
          sum: '',
          id: nanoid(),
        },
      ]),
    [values, setFieldValue],
  );

  const onClosePayment = useCallback(
    (index: number) =>
      setFieldValue('payments', Ramda.remove(index, 1, values.payments)),
    [values, setFieldValue],
  );

  const onChangeAccountPayable = useCallback(
    (value: OptionTypeBase | null, index: number) => {
      if (!value) {
        return;
      }

      const newAccountsPayables: AccountsPayableType[] = Ramda.clone(
        values.accountsPayables,
      );

      newAccountsPayables[index] = value as AccountsPayableType;

      setFieldValue('accountsPayables', newAccountsPayables);
    },
    [values, setFieldValue],
  );

  const onAddAccountPayable = useCallback(
    () =>
      setFieldValue('accountsPayables', [
        ...values.accountsPayables,
        {
          value: nanoid(),
        },
      ]),
    [values, setFieldValue],
  );

  const onCloseAccountPayment = useCallback(
    (index: number) =>
      setFieldValue(
        'accountsPayables',
        Ramda.remove(index, 1, values.accountsPayables),
      ),
    [values, setFieldValue],
  );

  const onChangeInput = useCallback(
    (
      value: string | boolean | number | ValueType<OptionTypeBase, false>,
      name: string,
    ) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const onChangeCurrency = useCallback(
    (currency: ValueType<OptionTypeBase, false>) => {
      if (currency && currency.value === Currencies.BYN) {
        setFieldValue(
          'country',
          countries.find(({ label }) => label === 'Беларусь'),
        );
        return;
      }

      if (currency && currency.value === Currencies.RUB) {
        setFieldValue(
          'country',
          countries.find(({ label }) => label === 'Россия'),
        );
        return;
      }

      setFieldValue('country', null);
    },
    [countries, setFieldValue],
  );

  const countriesOptions = useMemo(
    () => [{ value: '', label: `${t('common.choseCountry')}` }, ...countries],
    [countries, t],
  );

  return {
    countriesOptions,
    onChangePayment,
    onAddPayment,
    onClosePayment,
    onChangeAccountPayable,
    onAddAccountPayable,
    onCloseAccountPayment,
    onChangeInput,
    onChangeCurrency,
  };
};
