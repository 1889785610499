import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PopupNames } from 'core';
import {
  usePopupStateOperations,
  useDeleteUser,
  useSearch,
  SearchNames,
  useToasts,
} from 'core/hooks';
import { GlobalToastTypeNames, ToastTypes } from 'components/types';

import { UserDeletePopupComponent } from './UserDeletePopup';

export const UserDeletePopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const {
    operations: { hidePopup, showPopup },
  } = usePopupStateOperations();
  const [
    deleteUser,
    { user, success, getUserLoading, loading, error },
  ] = useDeleteUser();

  const { setSearchQuery } = useSearch(SearchNames.users);

  const onCloseHandler = useCallback(() => {
    hidePopup(PopupNames.deleteUser);
  }, [hidePopup]);

  const onCancelHandler = useCallback(() => {
    showPopup(PopupNames.editUser);
  }, [showPopup]);

  const onDeleteHandler = useCallback(() => {
    deleteUser();
  }, [deleteUser]);

  useEffect(() => {
    if (success) {
      setSearchQuery('');
      addToast({
        data: {
          title: `${t('deleteUserPopup.success')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.UserDelete,
      });
      hidePopup(PopupNames.deleteUser);
    }

    if (error) {
      addToast({
        data: {
          title: `${t('deleteUserPopup.deleteError')}`,
          text: error,
          type: ToastTypes.error,
        },
        type: GlobalToastTypeNames.UserDelete,
      });
    }
  }, [success, error, hidePopup, setSearchQuery, addToast, t]);

  return getUserLoading ? null : (
    <UserDeletePopupComponent
      roles={user.userRoles}
      loading={loading}
      onClose={onCloseHandler}
      onCancel={onCancelHandler}
      onDelete={onDeleteHandler}
    />
  );
});
