import { Button } from 'components/atoms';
import React, { memo, useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { PATHS } from 'router/config';
import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import styled from 'styled-components';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

type errorPagesType = {
  [status: number]: {
    heading: string;
    text_1: string;
    text_2: string;
  };
};

const getErrorPages = (t: TFunction): errorPagesType => ({
  404: {
    heading: `${t('error.404.heading')}`,
    text_1: `${t('error.404.text_1')}`,
    text_2: `${t('error.404.text_2')}`,
  },
  500: {
    heading: `${t('error.500.heading')}`,
    text_1: `${t('error.500.text_1')}`,
    text_2: `${t('error.500.text_2')}`,
  },
  503: {
    heading: `${t('error.503.heading')}`,
    text_1: `${t('error.503.text_1')}`,
    text_2: `${t('error.503.text_2')}`,
  },
});

interface IErrorPage {
  status: number;
  isLoggedIn?: boolean | null;
}

export const ErrorPage = memo(({ status, isLoggedIn }: IErrorPage) => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState('');
  const { heading, text_1, text_2 } = getErrorPages(t)[status];

  const goHome = useCallback(
    () => (isLoggedIn ? setRedirect(PATHS.main) : setRedirect(PATHS.login)),
    [isLoggedIn],
  );

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <ErrorPageContainer>
      <GradientText>{status}</GradientText>

      <Heading>{heading}</Heading>

      <Text>{text_1}</Text>
      <Text>{text_2}</Text>

      <ButtonWrapper>
        <Button
          colors={{
            backgroundColor: Colors.Blue,
            color: Colors.White,
          }}
          onClick={goHome}
        >
          {t('error.goToMain')}
        </Button>
      </ButtonWrapper>
    </ErrorPageContainer>
  );
});

const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const GradientText = styled.span`
  background: -webkit-linear-gradient(240deg, #0d53fc, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: ${getFontFamily('bold')};
  font-style: normal;
  font-weight: bold;
  font-size: 160px;
  line-height: 160px;
`;

const Heading = styled.h4`
  font-family: ${getFontFamily('bold')};
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  margin-top: 40px;
`;

const Text = styled.div`
  font-family: ${getFontFamily()};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

const ButtonWrapper = styled((props) => <Button {...props} />)`
  width: 240px;
  margin-top: 40px;
`;
