import { useMemo } from 'react';

import { useGetAllCompaniesQuery } from 'core/graphql';
import { CompanyOptionsType } from 'components/types';

import { transformCompanies } from './transform';

export const useCompanies = (): { companies: CompanyOptionsType } => {
  const { data } = useGetAllCompaniesQuery();

  const companies = useMemo(() => transformCompanies(data), [data]);

  return {
    companies,
  };
};
