import * as React from 'react';
import { memo, useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { IDebtor } from 'types';

import { DebtorsTableComponent, IColumn, IRow } from './DebtorsTable';

export interface IDebtorsTable {
  data: IDebtor[];
  loading?: boolean;
  error?: string;
  loadMore: () => void;
  hasMore: boolean;
}

const getColumnsSource = (t: TFunction): IColumn[] => [
  {
    Header: `${t('table.debtors.studentName')}`,
    accessor: 'name',
    flexible: true,
  },
  {
    Header: `${t('table.debtors.agreementId')}`,
    accessor: 'contractId',
    columnWidth: '160px',
    marginRight: '48px',
    marginLeft: '40px',
  },
  {
    Header: `${t('table.debtors.group')}`,
    accessor: 'group',
    columnWidth: '80px',
  },
  {
    Header: `${t('table.debtors.phone')}`,
    accessor: 'phone',
    columnWidth: '128px',
    marginRight: '48px',
    marginLeft: '48px',
  },
  {
    Header: `${t('table.debtors.paymentDate')}`,
    accessor: 'paymentDate',
    columnWidth: '112px',
  },
  {
    Header: `${t('table.debtors.expiredDays')}`,
    accessor: 'expiredDays',
    columnWidth: '80px',
    marginRight: '48px',
    marginLeft: '48px',
  },
  {
    Header: `${t('table.debtors.amount')}`,
    accessor: 'amount',
    columnWidth: '112px',
    marginRight: '32px',
  },
];

export const DebtorsTable = memo(
  ({ data, loading, error, loadMore, hasMore }: IDebtorsTable) => {
    const { t } = useTranslation();
    const columns: IColumn[] = useMemo(() => getColumnsSource(t), [t]);
    const rows: IRow[] = useMemo(
      () => data.map((debtor, idx) => ({ ...debtor, num: idx + 1 })),
      [data],
    );

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <DebtorsTableComponent
        rows={rows}
        columns={columns}
        isFlexible
        noDataText={t('table.debtors.noData')}
        loadMore={loadMore}
        hasMore={hasMore}
        loading={loading}
      />
    );
  },
);
