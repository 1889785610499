export enum Colors {
  Transparent = 'transparent',
  White = '#FFFFFF',
  Gray = '#88959E',
  LightGray = '#EBEFF2',
  Red = '#F44336',
  SeaBuckthorn = '#FAAE2B',
  SpringWood = '#faf9f6',
  Black = '#263446',
  Blue = '#0D53FC',
  MainBackground = '#F2F5F9',
  PrimaryBlack = '#000000',
  SecondLightGray = '#DFE6F1',
  ModalBackground = 'rgb(49, 65, 109, 0.4)',
  LightBlue = '#FAFBFD',
  DeactivatedGrey = '#C6CCD0',
  SuccessDefault = '#3CC13B',
  LightShadow = 'rgba(136, 149, 158, 0.08)',
  BlueShadow = 'rgba(38, 52, 70, 0.04)',
  BlueShadowRow = 'rgba(38, 52, 70, 0.12)',
  WarningDefault = '#F3BB1C',
  ErrorDefault = '#F03738',
  BasicBlueLight = '#E7EEFF',
}
