import { useCallback } from 'react';

import { useRemoveApplicationMutation } from 'core';
import { TeachersMetaQuery, ApplicationsQuery } from 'core/operations';

type IDeleteApplicationHookType = [
  (id: string) => void,
  {
    success: boolean;
    loading: boolean;
    error?: string;
  },
];

export const useDeleteApplication = (): IDeleteApplicationHookType => {
  const [
    removeApplicationMutation,
    { data, loading, error },
  ] = useRemoveApplicationMutation({
    refetchQueries: [
      {
        query: ApplicationsQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: TeachersMetaQuery,
      },
    ],
  });

  const deleteApplication = useCallback(
    (id: string) => {
      removeApplicationMutation({
        variables: {
          id,
        },
      });
    },
    [removeApplicationMutation],
  );

  return [
    deleteApplication,
    {
      success: data?.deleteTeacherApplication?.success || false,
      loading,
      error: error?.message || '',
    },
  ];
};
