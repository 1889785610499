import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ValueType, OptionTypeBase } from 'react-select';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  InputLabel,
  Select,
  Text,
  Button,
  Title,
  Icon,
  IconsNames,
  IconButton,
  ISelectOption,
} from 'components/atoms';

import { Colors } from 'services/ColorService';

import {
  useCompany,
  useCreateNewCompany,
  useSortErrors,
  useUpdateCompany,
  useToasts,
} from 'core/hooks';
import { IconSizes, ToastTypes, ErrorsType, GlobalToastTypeNames } from 'types';

import { CompanyForm, ICompanyFormValues } from './CompanyForm';
import { getCompanySchema } from './studentPaymentSchema';

interface ICompanyFormContainer {
  company?: ValueType<OptionTypeBase, false>;
  companyError?: boolean;
  companiesOptions: ISelectOption<string>[];
  isShowLegalForm: boolean;
  isEdititng?: boolean;
  setShowLegalForm: (isShow: boolean) => void;
  onChangeCompany: (
    value: ValueType<OptionTypeBase, false>,
    inputName: string,
  ) => void;
}

export const CompanyFormContainer = memo(
  ({
    company,
    companyError,
    companiesOptions,
    isShowLegalForm,
    isEdititng = false,
    setShowLegalForm,
    onChangeCompany,
  }: ICompanyFormContainer) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [isEdititngCompany, setIsCompanyEdit] = useState(false);
    const onCloseForm = useCallback(() => setShowLegalForm(false), [
      setShowLegalForm,
    ]);

    const [
      loadCompanyData,
      { company: companyData, loading: loadingCompanyData },
    ] = useCompany();

    const [
      createNewCompany,
      {
        success: createCompanySuccess,
        loading,
        errors: createErrors,
        clearErrors: clearCreateCompanyErrors,
      },
    ] = useCreateNewCompany();

    const [
      updateCompany,
      {
        success: updateCompanySuccess,
        loading: updateLoading,
        errors: updateErrors,
        clearErrors: clearUpdateCompanyErrors,
      },
    ] = useUpdateCompany();

    const onSubmit = useCallback(
      (companyValues) => {
        clearCreateCompanyErrors();
        clearUpdateCompanyErrors();
        if (!loading && !updateLoading) {
          if (isEdititngCompany && company) {
            updateCompany(company.value, {
              ...companyValues,
              registrationNumber: undefined,
            });
          }

          if (!isEdititngCompany) {
            createNewCompany({ ...companyValues });
          }
        }
      },
      [
        company,
        createNewCompany,
        isEdititngCompany,
        loading,
        updateCompany,
        updateLoading,
        clearUpdateCompanyErrors,
        clearCreateCompanyErrors,
      ],
    );

    const {
      values,
      errors,
      setFieldValue,
      setValues,
      handleSubmit,
      setErrors,
    } = useFormik({
      initialValues: {
        name: '',
        responsiblePerson: '',
        phone: '',
        email: '',
        registrationNumber: '',
        comment: '',
      },
      onSubmit,
      validateOnChange: false,
      validationSchema: getCompanySchema(t),
    });

    const onCreateCompany = useCallback(() => {
      setIsCompanyEdit(false);
      setShowLegalForm(true);
      setValues({
        name: '',
        responsiblePerson: '',
        phone: '',
        email: '',
        registrationNumber: '',
        comment: '',
      });
    }, [setShowLegalForm, setValues]);

    const onEditCompany = useCallback(() => {
      if (company?.value) {
        loadCompanyData(company.value);
      }
      setIsCompanyEdit(true);
      setShowLegalForm(true);
    }, [company?.value, setShowLegalForm, loadCompanyData]);

    const onCancelEditCompany = useCallback(() => {
      setIsCompanyEdit(false);
      setShowLegalForm(false);
    }, [setIsCompanyEdit, setShowLegalForm]);

    const onChangeInput = useCallback(
      (value: string, name: string) => {
        setFieldValue(name, value);
      },
      [setFieldValue],
    );

    useEffect(() => {
      if (!loadingCompanyData && isEdititngCompany) {
        setValues({
          name: companyData?.name || '',
          responsiblePerson: companyData?.responsiblePerson || '',
          phone: companyData?.phone || '',
          email: companyData?.email || '',
          registrationNumber: companyData?.registrationNumber || '',
          comment: companyData?.comment || '',
        });
      }
    }, [companyData, isEdititngCompany, loadingCompanyData, setValues]);

    useEffect(() => {
      if (createCompanySuccess) {
        addToast({
          data: {
            title: `${t('companyForm.companyAddSuccess')}`,
            type: ToastTypes.success,
          },
          type: GlobalToastTypeNames.StudentAgreementCompany,
        });
        onCloseForm();
      }
    }, [addToast, createCompanySuccess, onCloseForm, t]);

    useEffect(() => {
      if (updateCompanySuccess) {
        addToast({
          data: {
            title: `${t('companyForm.companyUpdateSuccess')}`,
            type: ToastTypes.success,
          },
          type: GlobalToastTypeNames.StudentAgreementCompany,
        });
        onCloseForm();
      }
    }, [addToast, updateCompanySuccess, onCloseForm, t]);

    const [
      sortErrors,
      { errors: sortedErrors, message },
    ] = useSortErrors<ICompanyFormValues>();

    useEffect(() => {
      sortErrors({
        ...(errors as ErrorsType<ICompanyFormValues>),
        ...createErrors,
        ...updateErrors,
      });
    }, [errors, createErrors, updateErrors, sortErrors]);

    useEffect(() => {
      if (company?.value) {
        loadCompanyData(company.value);
      }
    }, [loadCompanyData, company?.value]);

    useEffect(() => {
      clearCreateCompanyErrors();
      clearUpdateCompanyErrors();
      setErrors({});
    }, [
      company?.value,
      isShowLegalForm,
      clearCreateCompanyErrors,
      clearUpdateCompanyErrors,
      setErrors,
    ]);

    const onSaveBtnClick = useCallback(() => {
      clearCreateCompanyErrors();
      clearUpdateCompanyErrors();
      setErrors({});
      handleSubmit();
    }, [
      handleSubmit,
      clearCreateCompanyErrors,
      clearUpdateCompanyErrors,
      setErrors,
    ]);

    return (
      <>
        <LegalEntityRow>
          <WrapperSelect>
            <InputLabel hasError={Boolean(companyError)}>
              {t('companyForm.fields.company.label')}{' '}
              {company?.comment && (
                <CompanyCommentContainer>
                  <CompanyComment text={company?.comment} width="240px" />
                  <Icon name={IconsNames.Comment} width={16} height={16} />
                </CompanyCommentContainer>
              )}
            </InputLabel>
            <Select
              placeholder={t('companyForm.fields.company.placeholder')}
              options={companiesOptions}
              onChange={(value) => onChangeCompany(value, 'company')}
              hasError={companyError}
              value={company}
            />
          </WrapperSelect>

          {isShowLegalForm ? (
            <LegalEntityButtons>
              <WrappedCancelButton
                type="button"
                onClick={onCancelEditCompany}
                variant="outline"
                colors={{
                  backgroundColor: Colors.White,
                  color: Colors.Blue,
                }}
              >
                {t('companyForm.button.cancel')}
              </WrappedCancelButton>

              <Button
                type="submit"
                onClick={onSaveBtnClick}
                colors={{
                  backgroundColor: Colors.Blue,
                  color: Colors.White,
                }}
              >
                {t('companyForm.button.save')}
              </Button>
            </LegalEntityButtons>
          ) : (
            <WrappedButton>
              {company && isEdititng && (
                <>
                  <IconButton
                    name={IconsNames.EditLargeIcon}
                    size={IconSizes.xl}
                    onClick={onEditCompany}
                  />
                  <WrappedEditText>
                    <Text size="md">{t('companyForm.button.edit')}</Text>
                  </WrappedEditText>
                </>
              )}
              <IconButton
                name={IconsNames.PlusLargeIcon}
                size={IconSizes.xl}
                onClick={onCreateCompany}
              />
              <WrappedCreateText>
                <Text size="md">{t('companyForm.button.create')}</Text>
              </WrappedCreateText>
            </WrappedButton>
          )}
        </LegalEntityRow>

        {isShowLegalForm && (
          <CompanyForm
            loading={loadingCompanyData}
            values={values}
            isEdititngCompany={isEdititngCompany}
            errors={sortedErrors}
            errorMessage={message}
            onChangeInput={onChangeInput}
            handleSubmit={handleSubmit}
          />
        )}
      </>
    );
  },
);

const WrapperSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 16px);
`;

const LegalEntityRow = styled.div`
  margin: 32px 0 0 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const WrappedEditText = styled.div`
  margin-left: 16px;
  margin-right: 32px;
`;

const WrappedCreateText = styled.div`
  margin-left: 16px;
`;

const WrappedButton = styled.div<{ hide?: boolean }>`
  margin-left: 32px;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  margin-top: auto;
`;

const LegalEntityButtons = styled.div`
  display: flex;
  margin-top: auto;
  margin-left: 32px;
`;

const WrappedCancelButton = styled((props) => <Button {...props} />)`
  margin-right: 8px;
`;

const CompanyCommentContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const CompanyComment = styled((props) => <Title {...props} />)`
  display: none;
  bottom: 40px;

  ${CompanyCommentContainer}:hover & {
    display: block;
  }
`;
