import * as React from 'react';
import { memo, ReactNode, useEffect } from 'react';

import { createPortal } from 'react-dom';

import { Modal } from 'atoms';

export interface IGlobalModalComponent {
  children: ReactNode;
}

export const GlobalModalComponent = memo(
  ({ children }: IGlobalModalComponent) => {
    useEffect(() => {
      if (children) {
        document.body.style.overflowY = 'hidden';
        return;
      }
      document.body.style.overflowY = 'auto';
    }, [children]);

    return children
      ? createPortal(<Modal>{children}</Modal>, document.body)
      : null;
  },
);
