import * as React from 'react';
import { memo } from 'react';

import { GuestTemplate } from 'templates';
import { ForgotPasswordForm } from 'molecules';

export const ForgotPassword = memo(() => (
  <GuestTemplate>
    <ForgotPasswordForm />
  </GuestTemplate>
));
