import { useCreateUserMutation, CustomErrorType } from 'core/graphql';

import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UsersSettingsQuery } from 'core/operations';
import {
  IUser,
  ServerCodes,
  ToastTypes,
  ErrorsType,
  GlobalToastTypeNames,
} from 'types';

import { IUserPopupValues } from 'molecules';
import { useToasts } from 'core/hooks';

import { userErrorReducer, getUserErrors } from './reducer';

export type CreateNewUserErrorsType = {
  [key in keyof IUserPopupValues]: [
    {
      code: string;
      message: string;
    },
  ];
};

type CreateNewUserType = [
  (user: IUser) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<IUserPopupValues> | null;
    code: ServerCodes | undefined;
  },
];

export const useCreateNewUser = (): CreateNewUserType => {
  const { t } = useTranslation();
  const [createUserMutation, { data, loading }] = useCreateUserMutation({
    refetchQueries: [
      {
        query: UsersSettingsQuery,
        variables: {
          first: 10,
        },
      },
    ],
  });

  const [errors, dispatch] = useReducer(userErrorReducer, null);
  const [code, setCode] = useState<ServerCodes | undefined>();
  const { addToast } = useToasts();

  useEffect(() => {
    const serverErrors = data?.createUser?.customErrors;
    if (serverErrors && serverErrors.length) {
      const errorsFound = getUserErrors(serverErrors as CustomErrorType[]);

      switch (true) {
        case errorsFound.uniqueSum > 1:
          setCode(ServerCodes.userExist);
          break;
        case errorsFound.invalidPhone:
          dispatch({ type: ServerCodes.phoneInvalid });
          break;
        case errorsFound.alreadyExist:
          dispatch({ type: ServerCodes.alreadyExist });
          break;
        case errorsFound.uniqueEmail:
          setCode(ServerCodes.emailExist);
          break;
        case errorsFound.uniquePhone:
          setCode(ServerCodes.phoneExist);
          break;
        default:
          addToast({
            data: {
              title: `${t('common.somethingWentWrong')}`,
              type: ToastTypes.error,
            },
            type: GlobalToastTypeNames.UserAdd,
          });
      }
    }
  }, [data?.createUser?.customErrors, addToast, t]);

  const createNewUser = useCallback(
    (user: IUser) => {
      createUserMutation({
        variables: {
          firstName: user.firstName,
          middleName: user.middleName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          roles: (user.roles && user?.roles.join(',')) || '',
        },
      });
    },
    [createUserMutation],
  );

  return [
    createNewUser,
    {
      success: Boolean(data?.createUser?.success),
      loading,
      errors,
      code,
    },
  ];
};
