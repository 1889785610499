import { MockedResponse } from '@apollo/client/testing';
import { PaymentsVerificationQuery as PaymentsVerificationQueryType } from 'core';
import { PaymentsVerificationQuery } from 'core/operations';

import { Currencies, IVerifiedPaymentInfo } from 'types';
import { DateService } from 'services/DateService';

export const mockRequestData: IVerifiedPaymentInfo[] = [
  {
    date: DateService.parseDate('2022-02-12', 'ISO'),
    contractNumber: 'Test1',
    contractCurrency: Currencies.BYN,
    sum: 300,
    sumCurrency: Currencies.BYN,
    unparsed: 'Some Test Username/Test1/300/BYN',
  },
  {
    date: DateService.parseDate('2022-02-12', 'ISO'),
    contractNumber: 'Test2',
    contractCurrency: Currencies.BYN,
    sum: 300,
    sumCurrency: Currencies.BYN,
    unparsed: 'Some Test Username 2/Test1/300/BYN',
  },
];

export const mockRequestResponse: MockedResponse<PaymentsVerificationQueryType> = {
  request: {
    query: PaymentsVerificationQuery,
    variables: {
      contracts: ['Test1', 'Test2'],
    },
  },
  result: {
    data: {
      paymentsVerification: [
        {
          fullname: 'Some Test Username',
          agreement_id: '1',
          agreementTitle: 'Test1',
          currency: 'BYN',
        },
        {
          fullname: 'Some Test Username 2',
          agreement_id: '2',
          agreementTitle: 'Test2',
          currency: 'BYN',
        },
      ],
    },
  },
};
