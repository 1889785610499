import React, { memo, useEffect, useCallback } from 'react';

import styled from 'styled-components';

import { GroupsTable } from 'molecules';

import { useGroups, useSearch, SearchNames } from 'core/hooks';

export const Groups = memo(() => {
  const {
    data: groups,
    loading,
    error,
    pageInfo,
    operations: { fetchMoreData },
  } = useGroups();

  const { searchQuery } = useSearch(SearchNames.settings);

  const loadMore = useCallback(() => {
    const vars = {
      query: searchQuery || null,
      after: pageInfo?.endCursor,
    };

    fetchMoreData(vars);
  }, [fetchMoreData, pageInfo?.endCursor, searchQuery]);

  useEffect(() => {
    const query = searchQuery.trim();

    fetchMoreData({ query });
  }, [fetchMoreData, searchQuery]);
  return (
    <GroupsTableWrapper>
      <GroupsTable
        data={groups}
        loading={loading}
        error={error}
        hasMore={pageInfo?.hasNextPage || false}
        loadMore={loadMore}
      />
    </GroupsTableWrapper>
  );
});

const GroupsTableWrapper = styled.div`
  margin-top: 24px;
`;
