import { ErrorsType, FieldErrorTypes, TeacherHireType } from 'types';
import { ISelectOption } from 'components/atoms';
import i18n from '../../i18n';

export const CURRENCY_OPTIONS_RU = [
  { value: 'BYN', label: 'BYN' },
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'RUB', label: 'RUB' },
];

export const CURRENCY_OPTIONS_TEACHER_RU = [
  { value: 'Byn', label: 'BYN' },
  { value: 'Usd', label: 'USD' },
  { value: 'Eur', label: 'EUR' },
  { value: 'Rub', label: 'RUB' },
];

export const CURRENCY_OPTIONS_PL = [
  { value: 'PLN', label: 'PLN' },
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
];

export const CURRENCY_OPTIONS_TEACHER_PL = [
  { value: 'Pln', label: 'PLN' },
  { value: 'Usd', label: 'USD' },
  { value: 'Eur', label: 'EUR' },
];

export const WEEK_DAYS = [
  { value: 0, label: `${i18n.t('common.weekDays.monday')}` },
  { value: 1, label: `${i18n.t('common.weekDays.tuesday')}` },
  { value: 2, label: `${i18n.t('common.weekDays.wednesday')}` },
  { value: 3, label: `${i18n.t('common.weekDays.thursday')}` },
  { value: 4, label: `${i18n.t('common.weekDays.friday')}` },
  { value: 5, label: `${i18n.t('common.weekDays.saturday')}` },
  { value: 6, label: `${i18n.t('common.weekDays.sunday')}` },
];

export const TIME_OPTIONS = [
  { value: '21:00:00', label: '00:00' },
  { value: '22:00:00', label: '01:00' },
  { value: '23:00:00', label: '02:00' },
  { value: '00:00:00', label: '03:00' },
  { value: '01:00:00', label: '04:00' },
  { value: '02:00:00', label: '05:00' },
  { value: '03:00:00', label: '06:00' },
  { value: '04:00:00', label: '07:00' },
  { value: '05:00:00', label: '08:00' },
  { value: '06:00:00', label: '09:00' },
  { value: '07:00:00', label: '10:00' },
  { value: '08:00:00', label: '11:00' },
  { value: '09:00:00', label: '12:00' },
  { value: '10:00:00', label: '13:00' },
  { value: '11:00:00', label: '14:00' },
  { value: '12:00:00', label: '15:00' },
  { value: '13:00:00', label: '16:00' },
  { value: '14:00:00', label: '17:00' },
  { value: '15:00:00', label: '18:00' },
  { value: '16:00:00', label: '19:00' },
  { value: '17:00:00', label: '20:00' },
  { value: '18:00:00', label: '21:00' },
  { value: '19:00:00', label: '22:00' },
  { value: '20:00:00', label: '23:00' },
];

export const TEACHER_TYPE_OPTIONS: ISelectOption<string>[] = [
  { value: TeacherHireType.Se, label: `${i18n.t('common.teacherTypes.se')}` },
  { value: TeacherHireType.Ie, label: `${i18n.t('common.teacherTypes.ie')}` },
  { value: TeacherHireType.Np, label: `${i18n.t('common.teacherTypes.np')}` },
  { value: TeacherHireType.Le, label: `${i18n.t('common.teacherTypes.le')}` },
];

export const TEACHER_TYPE_COUNT_FIELDS = {
  [TeacherHireType.Ie]: 5,
  [TeacherHireType.Se]: 7,
  [TeacherHireType.Np]: 6,
  [TeacherHireType.Le]: 6,
};

export const weekDays = [
  `${i18n.t('common.weekDaysShort.monday')}`,
  `${i18n.t('common.weekDaysShort.tuesday')}`,
  `${i18n.t('common.weekDaysShort.wednesday')}`,
  `${i18n.t('common.weekDaysShort.thursday')}`,
  `${i18n.t('common.weekDaysShort.friday')}`,
  `${i18n.t('common.weekDaysShort.saturday')}`,
  `${i18n.t('common.weekDaysShort.sunday')}`,
];

export const invalidPhoneError: ErrorsType<{ phone: string }> = {
  phone: {
    message: `${i18n.t('common.wrongPhoneNumber')}`,
    type: FieldErrorTypes.SERVER_ERROR,
  },
};

export const MONTH_NAMES = [
  `${i18n.t('common.months.january')}`,
  `${i18n.t('common.months.february')}`,
  `${i18n.t('common.months.march')}`,
  `${i18n.t('common.months.april')}`,
  `${i18n.t('common.months.may')}`,
  `${i18n.t('common.months.june')}`,
  `${i18n.t('common.months.july')}`,
  `${i18n.t('common.months.august')}`,
  `${i18n.t('common.months.september')}`,
  `${i18n.t('common.months.october')}`,
  `${i18n.t('common.months.november')}`,
  `${i18n.t('common.months.december')}`,
];
