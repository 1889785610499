import * as React from 'react';

import { Icon, IconsNames } from 'atoms';
import { Paper } from 'templates';

export const Splash = () => (
  <Paper>
    <Icon name={IconsNames.Splash} width={250} height={250} />
  </Paper>
);
