import { useCallback, useReducer, useEffect } from 'react';
import {
  useEditTeacherWithTypeMutation,
  EditTeacherWithTypeMutationVariables,
} from 'core';
import { TeachersMetaQuery, TeachersQuery } from 'core/operations';

import { ServerCodes, ErrorsType } from 'types';
import { ITeacherEditValues } from 'molecules';

import { teacherErrorsReducer } from '../reducer';

type AddTeacherExistHookType = [
  (variables: EditTeacherWithTypeMutationVariables) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<ITeacherEditValues> | null;
  },
];

export const useUpdateTeacherWithType = (): AddTeacherExistHookType => {
  const [errors, dispatch] = useReducer(teacherErrorsReducer, null);

  const [mutation, { data, loading }] = useEditTeacherWithTypeMutation({
    refetchQueries: [
      {
        query: TeachersQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: TeachersMetaQuery,
      },
    ],
  });

  const updateTeacher = useCallback(
    (variables: EditTeacherWithTypeMutationVariables) => {
      mutation({ variables });
    },
    [mutation],
  );

  useEffect(() => {
    const updateErrors = data?.updateTeacherWithType?.customErrors;
    if (
      updateErrors?.some(
        (error) => error?.code === 'invalid' && error.field === 'phone',
      )
    ) {
      dispatch({ type: ServerCodes.invalidPhone });
      return;
    }

    const uniquePhone = updateErrors?.some(
      (error) => error?.code === 'unique' && error.field === 'phone',
    );
    const uniqueEmail = updateErrors?.some(
      (error) => error?.code === 'unique' && error.field === 'email',
    );

    if (uniquePhone && uniqueEmail) {
      dispatch({ type: ServerCodes.teacherExist });
      return;
    }

    if (uniquePhone) {
      dispatch({ type: ServerCodes.phoneExist });
      return;
    }

    if (uniqueEmail) {
      dispatch({ type: ServerCodes.emailExist });
    }
  }, [data?.updateTeacherWithType?.customErrors]);

  return [
    updateTeacher,
    {
      success: data?.updateTeacherWithType?.success || false,
      loading,
      errors,
    },
  ];
};
