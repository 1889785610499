import { OptionsType } from 'types';

import { useGetCoursesForTeacherQuery, GetCoursesForTeacherQuery } from 'core';

import { transformCoursesData } from './transform';

type IGetCoursesForApplicationHookType = {
  courses: OptionsType | undefined;
  loading: boolean;
  error: string | undefined;
};

export const useGetCoursesForApplication = (
  first?: number,
): IGetCoursesForApplicationHookType => {
  const { data, loading, error } = useGetCoursesForTeacherQuery({
    fetchPolicy: 'network-only',
    variables: {
      first,
    },
  });

  const courses = transformCoursesData(data as GetCoursesForTeacherQuery);

  return {
    courses,
    loading,
    error: error?.message,
  };
};
