import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { ValueType, OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';

import { Heading, InputLabel, Select } from 'components/atoms';
import { OptionsType, IFieldError, ErrorsType } from 'types';
import { Colors } from 'services/ColorService';

type InputHandler<T> = (value: T, inputName: string) => void;

interface IPartialLessonsForm {
  values: IPartialLessonsFormValues;
  errors?: ErrorsType<IPartialLessonsFormValues> | null;
  lessonsOptions: OptionsType;
  isGroupStarted?: boolean;
  onChangeInput: InputHandler<ValueType<OptionTypeBase, false>>;
}

export interface IPartialLessonsFormValues {
  firstLesson: ValueType<OptionTypeBase, false>;
  lastLesson: ValueType<OptionTypeBase, false>;
  isTerminatedAgreement?: boolean;
}

export type IPartialLessonsFormErrors = {
  firstLesson?: IFieldError;
  lastLesson?: IFieldError;
};

export const PartialLessonsForm = memo(
  ({
    values,
    errors,
    lessonsOptions,
    isGroupStarted = false,
    onChangeInput,
  }: IPartialLessonsForm) => {
    const { t } = useTranslation();
    const firstLessonOptions = useMemo(() => {
      if (!values.lastLesson) {
        return lessonsOptions;
      }

      const lastLessonIndex = lessonsOptions.findIndex(
        ({ value }) => value === values.lastLesson?.value,
      );

      return lessonsOptions.slice(0, lastLessonIndex + 1);
    }, [lessonsOptions, values.lastLesson]);

    const lastLessonOptions = useMemo(() => {
      if (!values.firstLesson) {
        return [];
      }

      const firstLessonIndex = lessonsOptions.findIndex(
        ({ value }) => value === values.firstLesson?.value,
      );

      return [
        ...lessonsOptions
          .slice(0, firstLessonIndex)
          .map((option) => ({ ...option, disabled: true })),
        ...lessonsOptions.slice(firstLessonIndex),
      ];
    }, [lessonsOptions, values.firstLesson]);

    return (
      <>
        <Separator />
        <Heading>{t('partialLessonForm.lessons')}</Heading>
        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.firstLesson)}>
            {t('partialLessonForm.fields.firstLesson.label')}
          </InputLabel>
          <Select
            isDisabled={isGroupStarted || values.isTerminatedAgreement}
            placeholder={t('partialLessonForm.fields.firstLesson.placeholder')}
            options={firstLessonOptions}
            onChange={(value) => onChangeInput(value, 'firstLesson')}
            hasError={Boolean(errors?.firstLesson)}
            value={values.firstLesson}
          />
        </WrapperInput>
        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.firstLesson)}>
            {t('partialLessonForm.fields.lastLesson.label')}
          </InputLabel>
          <Select
            isDisabled={!values.firstLesson || values.isTerminatedAgreement}
            placeholder={t('partialLessonForm.fields.lastLesson.placeholder')}
            options={lastLessonOptions}
            onChange={(value) => onChangeInput(value, 'lastLesson')}
            hasError={Boolean(errors?.lastLesson)}
            value={values.lastLesson}
          />
        </WrapperInput>
      </>
    );
  },
);

const Separator = styled.div`
  height: 1px;
  background-color: ${Colors.LightGray};
  margin: 36px -64px 40px -64px;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;
