import React, { memo, useCallback } from 'react';
import { OptionTypeBase, ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
  Button,
  Form,
  TextArea,
  Text,
  WeekDaySelect,
  TimePicker,
  ISelectOption,
} from 'atoms';

import { IModuleLesson, Module, PopupContainer, PopupErrors } from 'molecules';

import { ErrorsType, IApplicationOptions } from 'types';
import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

type InputHandler<T> = (value: T, inputName: string) => void;

export interface IEditJournalPopup {
  values: IEditJournalPopupValues;
  errors?: ErrorsType<IEditJournalPopupValues> | null;
  errorsMessage?: string | null;
  applicationOptions: IApplicationOptions[];
  modules: string[];
  onChangeInput: InputHandler<
    | ValueType<OptionTypeBase, false>
    | ISelectOption<string>[]
    | IModuleLesson[]
    | string
    | null
    | undefined
    | boolean
  >;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onClose: () => void;
}

type LessonDay = {
  id: string;
  weekDay: number;
  startTime: ValueType<OptionTypeBase, false>;
  endTime: ValueType<OptionTypeBase, false>;
};

export interface IEditJournalPopupValues {
  lessonsDays: LessonDay[];
  comment: string;
}

export const EditJournalPopupComponent = memo(
  ({
    values,
    errors,
    errorsMessage,
    applicationOptions,
    modules,
    onChangeInput,
    handleSubmit,
    onClose,
  }: IEditJournalPopup) => {
    const { t } = useTranslation();
    const changeLessonDays = useCallback(
      (lessonDay: LessonDay, index: number) => {
        const lessonDays = [...values.lessonsDays];
        lessonDays[index] = lessonDay;

        onChangeInput(lessonDays, 'lessonsDays');
      },
      [onChangeInput, values.lessonsDays],
    );

    const showLessonDays = useCallback(
      () =>
        values.lessonsDays.map(({ id, weekDay, startTime, endTime }, index) => (
          <TimeWrapper key={id} index={index}>
            <WeekDayWrapper>
              {t('journalPopup.fields.day.label')} {index + 1}
              <WeekDaySelect
                hasError={Boolean(errors?.lessonsDays?.[index]?.weekDay)}
                onClick={(value) =>
                  changeLessonDays(
                    { id, weekDay: value, startTime, endTime },
                    index,
                  )
                }
                selected={weekDay}
              />
            </WeekDayWrapper>

            <TimePickerWrapper>
              {t('journalPopup.fields.time.label')}
              <TimePicker
                onChange={(value) =>
                  changeLessonDays(
                    { id, weekDay, startTime: value, endTime },
                    index,
                  )
                }
                value={startTime}
                hasError={Boolean(errors?.lessonsDays?.[index]?.startTime)}
              />
            </TimePickerWrapper>

            <TimePicker
              onChange={(value) =>
                changeLessonDays(
                  { id, weekDay, startTime, endTime: value },
                  index,
                )
              }
              value={endTime}
              hasError={Boolean(errors?.lessonsDays?.[index]?.endTime)}
            />
          </TimeWrapper>
        )),
      [errors?.lessonsDays, changeLessonDays, values.lessonsDays, t],
    );

    return (
      <PopupContainer onClose={onClose} title={t('journalPopup.editJournal')}>
        <WrappedForm onSubmit={handleSubmit}>
          {showLessonDays()}

          {modules &&
            modules.map((id) => (
              <>
                <Separator />
                <Module
                  key={id}
                  index={Number(id)}
                  applicationOptions={applicationOptions || []}
                  onSubmitHandler={handleSubmit}
                />
              </>
            ))}

          <Separator />

          <WrappedComment size="md">
            {t('journalPopup.fields.comment.label')}
          </WrappedComment>
          <TextArea
            name="comment"
            placeholder=""
            value={values.comment}
            rows={3}
            onChange={({ target: { value } }) =>
              onChangeInput(value, 'comment')
            }
          />

          <BottomWrapper>
            <PopupErrors>{errorsMessage}</PopupErrors>
            <WrapperSendButton
              type="submit"
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
              }}
            >
              {t('journalPopup.button.save')}
            </WrapperSendButton>
          </BottomWrapper>
        </WrappedForm>
      </PopupContainer>
    );
  },
);

const WeekDayWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeWrapper = styled.div<{ index: number }>`
  display: flex;
  align-items: flex-end;
  margin-top: ${({ index }) => (index === 0 ? '0' : '24px')};
`;

const TimePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 32px;
`;

const WrappedForm = styled(Form)`
  margin-top: 32px;
`;

const Separator = styled.div`
  height: 1px;
  width: calc(100% + 128px);
  background-color: ${Colors.LightGray};
  margin: 40px -64px;
`;

const WrappedComment = styled((props) => <Text {...props} />)`
  font-family: ${getFontFamily('bold')};
  margin-top: 24px;
`;

const WrapperSendButton = styled((props) => <Button {...props} />)`
  flex-shrink: 0;
  margin-left: 32px;
  width: 209px;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
`;
