import * as React from 'react';
import { memo, useCallback } from 'react';
import NumberFormat from 'react-number-format';

import styled, { css } from 'styled-components';

import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';

export interface IInputPhone extends IInputPhoneStyle {
  value: string;
  placeholder?: string;
  name?: string;
  className?: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onEnter?: (value: string) => void;
}

interface IInputPhoneStyle {
  hasError?: boolean;
}

export const InputPhone = memo(
  ({
    value,
    placeholder,
    name,
    className,
    disabled,
    onChange,
    onFocus,
    onBlur,
    onEnter,
    hasError,
  }: IInputPhone) => {
    const onKeyDownHandler = useCallback(
      ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
        if (key === 'Enter') {
          if (onEnter) onEnter(value);
        }
      },
      [value, onEnter],
    );

    return (
      <InputMaskStyled
        value={value}
        format="+############"
        placeholder={placeholder}
        name={name}
        className={`${className} ${hasError && 'hasError'}`}
        disabled={disabled}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDownHandler}
      />
    );
  },
);

const InputMaskStyled = styled(NumberFormat)<{ disabled: boolean }>`
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  border: 1px solid ${Colors.LightGray};
  padding: 11px 16px;
  outline: none;
  font-family: ${getFontFamily('regular')};
  font-size: 14px;
  line-height: 24px;
  background-color: ${Colors.White};
  cursor: pointer;

  &:focus {
    border-color: ${Colors.Blue};
  }

  &.hasError {
    border-color: ${Colors.Red};
  }

  ${({ disabled }) => {
    if (disabled) {
      return css`
        background-color: ${Colors.MainBackground};
        color: ${Colors.Black};
        cursor: default;
      `;
    }
  }}
`;
