import moment from 'moment';

enum DateFormat {
  default = 'DD.MM.YYYY',
  ISO = 'YYYY-MM-DD',
}

export class DateService {
  static parseDate(
    date: string,
    format: keyof typeof DateFormat = 'default',
  ): Date {
    const result = moment(date, DateFormat[format]).toDate();
    return result;
  }

  static parseTime(time: string): Date {
    return moment(time, 'HH:mm:ss').toDate();
  }

  static isAfter(date1: Date, date2: Date): boolean {
    return moment(date1).isAfter(moment(date2), 'day');
  }

  static isSameOrAfter(date1: Date, date2: Date): boolean {
    return moment(date1).isSameOrAfter(moment(date2), 'day');
  }

  static isBefore(date1: Date, date2: Date): boolean {
    return moment(date1).isBefore(moment(date2), 'day');
  }

  static isSameOrBefore(date1: Date, date2: Date): boolean {
    return moment(date1).isSameOrBefore(moment(date2), 'day');
  }

  static toISO(date: Date | null): string {
    if (!date) {
      return '';
    }

    const result = moment(date).toISOString(true).substring(0, 10);
    return result;
  }

  static dateRange(startDate: Date, endDate: Date): string {
    const start = moment(startDate)
      .locale(process.env.REACT_APP_LANG || 'ru')
      .format('DD MMM');
    const end = moment(endDate)
      .locale(process.env.REACT_APP_LANG || 'ru')
      .format('DD MMM, YYYY');
    return `${start} - ${end}`;
  }
}
