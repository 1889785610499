import { gql } from '@apollo/client';

export const TeachersMetaQuery = gql`
  query teachersMeta($query: String) {
    metaTeachers(query: $query) {
      totalCount
      applicationsCount
    }
  }
`;
