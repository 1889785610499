import * as React from 'react';

import { memo, useCallback } from 'react';

import styled, { css } from 'styled-components';

import { Icon, IconsNames } from '../Icon';

export enum TabsButtonActive {
  None = 0,
  Left = 1,
  Right = 2,
  All = 3,
}

export interface ITabsButton {
  onClick: (clickedArrow: TabsButtonActive) => void;
  active: TabsButtonActive;
}

export const TabsButton = memo(({ onClick, active }: ITabsButton) => {
  const leftIconOnClick = useCallback(() => onClick(TabsButtonActive.Left), [
    onClick,
  ]);
  const rightIconOnClick = useCallback(() => onClick(TabsButtonActive.Right), [
    onClick,
  ]);
  return (
    <StyledButton>
      <IconWrapper type="left" onClick={leftIconOnClick} active={active}>
        <Icon
          name={
            active === TabsButtonActive.Left || active === TabsButtonActive.All
              ? IconsNames.ArrowIconActive
              : IconsNames.ArrowIcon
          }
          width={16}
          height={16}
        />
      </IconWrapper>
      <IconWrapper type="right" onClick={rightIconOnClick} active={active}>
        <Icon
          name={
            active === TabsButtonActive.Right || active === TabsButtonActive.All
              ? IconsNames.ArrowIconActive
              : IconsNames.ArrowIcon
          }
          width={16}
          height={16}
        />
      </IconWrapper>
    </StyledButton>
  );
});

const StyledButton = styled.div`
  width: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: none;
  border: none;
  align-items: center;
  outline: none;
`;

const IconWrapper = styled.span<{
  type: 'left' | 'right';
  active: TabsButtonActive;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: rotate(${({ type }) => (type === 'left' ? '-90deg' : '90deg')});
  cursor: initial;
  pointer-events: none;
  ${({ type, active }) => {
    if (type === 'left') {
      if (active === TabsButtonActive.Left || active === TabsButtonActive.All) {
        return css`
          cursor: pointer;
          pointer-events: auto;
        `;
      }
    }
    if (type === 'right') {
      if (
        active === TabsButtonActive.Right ||
        active === TabsButtonActive.All
      ) {
        return css`
          cursor: pointer;
          pointer-events: auto;
        `;
      }
    }
  }};
`;
