import React, { memo, useEffect } from 'react';
import { useGetPlannedMonthContributions } from 'core/hooks';

import { FinanceMonthTableComponent } from './FinanceMonthTable';

interface IFinanceMonthTableProps {
  year: number;
  month: number;
}

export const FinanceMonthTable = memo(
  ({ year, month }: IFinanceMonthTableProps) => {
    const {
      getPlannedMonthContributions,
      data,
      loading,
    } = useGetPlannedMonthContributions();

    useEffect(() => {
      getPlannedMonthContributions(year, month);
    }, [getPlannedMonthContributions, year, month]);

    return <FinanceMonthTableComponent weeks={data} loading={loading} />;
  },
);
