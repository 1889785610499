import { makeVar } from '@apollo/client';
import { UserNode } from 'core/graphql';

export interface IUserState {
  me: UserNode | undefined | null;
}

export const defaultUserState: IUserState = Object.freeze({
  me: undefined,
});

export const userStateVar = makeVar(defaultUserState);
