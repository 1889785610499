import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Button, Text } from 'atoms';
import { PopupContainer } from 'molecules';

import { Colors } from 'services/ColorService';
import { transFormAlignProp } from 'services/TransformService';

export interface IDeleteGroupPopup extends IDeleteGroupPopupStyle {
  onClose: () => void;
  onConfirm: () => void;
}

export interface IDeleteGroupPopupStyle {
  width?: string;
}

export const DeleteGroupPopupComponent = memo(
  ({ onClose, onConfirm, width }: IDeleteGroupPopup) => {
    const { t } = useTranslation();
    return (
      <PopupContainer
        title={t('deleteGroupPopup.deleteGroup')}
        width={width}
        onClose={onClose}
      >
        <WrapperRow margin="24px 0 32px 0">
          <Text size="md">{t('deleteGroupPopup.youAreGoingToDelete')}</Text>
        </WrapperRow>

        <WrapperRow align="right">
          <WrapperDeleteButton>
            <Button
              onClick={onClose}
              variant="outline"
              colors={{
                backgroundColor: Colors.White,
                color: Colors.Blue,
              }}
            >
              {t('deleteGroupPopup.button.cancel')}
            </Button>
          </WrapperDeleteButton>

          <WrapperSendButton>
            <Button
              onClick={onConfirm}
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
              }}
            >
              {t('deleteGroupPopup.button.delete')}
            </Button>
          </WrapperSendButton>
        </WrapperRow>
      </PopupContainer>
    );
  },
);

const WrapperRow = styled.div<{
  margin?: string;
  wrap?: boolean;
  align?: 'left' | 'right' | 'center';
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: ${({ align }) => (align ? transFormAlignProp(align) : 'center')};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  margin: ${({ margin }) => margin || 'initial'};
`;

const WrapperDeleteButton = styled.div`
  margin: 0 16px 0 auto;
  width: 122px;
`;

const WrapperSendButton = styled.div`
  width: 153px;
`;
