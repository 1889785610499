import * as React from 'react';
import { memo, useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { IVerifiedPaymentInfo } from 'components/types';

import { Colors } from 'services/ColorService';

import { CheckBox } from 'atoms';
import { DateService } from 'services/DateService';
import { ICheckedPayments } from '../DebtorsPageDeferredWrapper/DebtorsPageDeferredWrapper';

import {
  IColumn,
  IRow,
  PaymentsDeferredTableComponent,
} from './PaymentsDeferredTable';

const getColumnSource = (t: TFunction): IColumn[] => [
  {
    accessor: 'checkbox',
    columnWidth: 16,
    marginRight: 24,
  },
  {
    Header: `${t('table.paymentsDeferred.date')}`,
    accessor: 'date',
    columnWidth: 112,
  },
  {
    Header: `${t('table.paymentsDeferred.studentName')}`,
    accessor: 'username',
    flexible: true,
    columnWidth: 280,
    marginRight: 32,
    marginLeft: 32,
  },
  {
    Header: `${t('table.paymentsDeferred.contractNumber')}`,
    accessor: 'contract',
    columnWidth: 190,
  },
  {
    Header: `${t('table.paymentsDeferred.sum')}`,
    accessor: 'sum',
    columnWidth: 112,
    marginRight: 32,
    marginLeft: 32,
  },
  {
    Header: `${t('table.paymentsDeferred.info')}`,
    accessor: 'unparsed',
    flexible: true,
    columnWidth: 400,
  },
];

export interface IPaymentsDeferredTable {
  data?: IVerifiedPaymentInfo[];
  checkedPayments: ICheckedPayments;
  headerCheckboxValue: boolean;
  onHeaderCheckboxChange: () => void;
  onCheckboxChange: (id: string) => void;
  loading?: boolean;
  loadMore: () => void;
  hasMore: boolean;
  userPermission?: boolean;
}

export const PaymentsDeferredTable = memo(
  ({
    data,
    checkedPayments,
    loadMore,
    hasMore,
    headerCheckboxValue,
    onHeaderCheckboxChange,
    onCheckboxChange,
    loading,
    userPermission,
  }: IPaymentsDeferredTable) => {
    const { t } = useTranslation();
    const columns: IColumn[] = useMemo(() => getColumnSource(t), [t]);
    const rows: IRow[] = useMemo(
      () =>
        data?.map((payment, index) => {
          const checkboxName = `data.${index}.checked`;

          return {
            id: payment.deferredPaymentId,
            checkbox: (
              <CheckBox
                name={checkboxName}
                onClick={() =>
                  onCheckboxChange(payment.deferredPaymentId || '')
                }
                checked={
                  checkedPayments[payment.deferredPaymentId || ''] || false
                }
                disabled={userPermission}
              />
            ),
            date: DateService.toISO(payment.date),
            username: payment.username,
            contract: (
              <div>
                <SpanBlue>{payment.contractNumber}</SpanBlue>
                <span>&nbsp;{payment.contractCurrency}</span>
              </div>
            ),
            sum: (
              <div>
                <span>{payment.sum}</span>
                <span>&nbsp;{payment.sumCurrency}</span>
              </div>
            ),
            unparsed: payment.unparsed,
          };
        }) || [],
      [data, checkedPayments, userPermission, onCheckboxChange],
    );

    return (
      <PaymentsDeferredTableComponent
        rows={rows}
        columns={columns}
        isFlexible
        noDataText={t('table.paymentsDeferred.noData')}
        loadMore={loadMore}
        hasMore={hasMore}
        headerCheckboxValue={headerCheckboxValue}
        onHeaderCheckboxChange={onHeaderCheckboxChange}
        loading={loading}
        userPermission={userPermission}
      />
    );
  },
);

const SpanBlue = styled.span`
  color: ${Colors.Blue};
`;
