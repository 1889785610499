import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

import { Menu } from 'organisms';
import { ContactSupport } from 'molecules';

import { Colors } from 'services/ColorService';

export interface IMainTemplate {
  children: React.ReactElement;
}

export const MainTemplate = memo(({ children }: IMainTemplate) => (
  <Paper>
    <MenuWrapper>
      <MenuMargin>
        <Menu />
      </MenuMargin>
    </MenuWrapper>
    <ContentWrapper>{children}</ContentWrapper>
    <ContactSupportWrapper>
      <ContactSupport />
    </ContactSupportWrapper>
  </Paper>
));

export const Paper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${Colors.MainBackground};
`;

const MenuMargin = styled.div`
  max-width: 1312px;
  margin: 0 auto;
`;

const MenuWrapper = styled.div`
  width: 100%;
  background-color: ${Colors.White};
  border-bottom: 1px solid ${Colors.LightGray};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1312px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${Colors.Transparent};
`;

const ContactSupportWrapper = styled.div`
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 999;
`;
