import { useMemo, useCallback } from 'react';

import { useGetEditTeacherLazyQuery } from 'core/graphql';
import { EditTeacherType, transformTeacherData } from './transform';

type EditingTeacherHookType = [
  (teacherId: string) => void,
  {
    data: EditTeacherType | null;
    loading?: boolean;
  },
];

export const useLoadEditingTeacher = (): EditingTeacherHookType => {
  const [query, { data, loading }] = useGetEditTeacherLazyQuery({
    fetchPolicy: 'network-only',
  });

  const getEditTeacher = useCallback(
    (teacherId: string) => {
      query({
        variables: {
          teacherId,
        },
      });
    },
    [query],
  );

  const teacherData = useMemo(() => transformTeacherData(data), [data]);

  return [
    getEditTeacher,
    {
      data: teacherData || null,
      loading,
    },
  ];
};
