/* eslint-disable @typescript-eslint/no-unused-vars */
import { gql } from '@apollo/client';

export const StudentsQuery = gql`
  query PaginatedStudents($query: String, $first: Int, $after: String) {
    allStudents(query: $query, first: $first, after: $after) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          user {
            lastName
            firstName
            middleName
            phone
            email
          }
          agreements {
            id
            agreementTitle
            currency
            budgetAmount
            paidAmount
            terminated
            group {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const loadAgreementData = gql`
  query loadAgreementById($agreementId: ID!) {
    agreement(agreementId: $agreementId) {
      id
      agreementTitle
      currency
      terminated
      paymentByCompany
      vat
      date
      comment
      budgetAmount
      paidAmount
      partialAttendance
      terminatedDate
      firstLesson {
        id
        name
        lessonNumber
      }
      lastLesson {
        id
        name
        date
        lessonNumber
      }
      chargeback {
        id
        amountByn
        amountForeign
        currency
      }
      contributions(orderByDateFilter: true) {
        edges {
          node {
            id
            date
            amountForeign
            amountByn
          }
        }
      }
      promocode
      company {
        id
        name
      }
      country {
        id
        title
      }
      resident
      student {
        id
        user {
          id
          firstName
          middleName
          lastName
          phone
          email
        }
      }
      group {
        id
        name
        startDate
        course {
          id
          name
        }
      }
      startAccountsPayable {
        id
        amountByn
        amountForeign
        repaid
      }
      accountsPayable {
        id
        amountForeign
        currency
        startedAgreement {
          id
          agreementTitle
        }
      }
    }
  }
`;

export const MetaStudentsQuery = gql`
  query MetaStudents($query: String) {
    metaStudents(query: $query) {
      totalCount
      agreementsCount
    }
  }
`;

export const UserExistCheck = gql`
  query CheckUserExist($email: String!, $phone: String!) {
    verifyStudent(email: $email, phone: $phone) {
      student {
        id
        user {
          id
          firstName
          lastName
          middleName
          phone
          email
          roles
        }
      }
      user {
        id
        firstName
        lastName
        middleName
        phone
        email
        roles
      }
      customErrors {
        field
        message
        code
      }
      message
      code
    }
  }
`;

export const loadLessonsOptionsForGroup = gql`
  query loadLessonsOptions($groupId: ID!) {
    group(groupId: $groupId) {
      id
      lessons {
        id
        name
        date
        lessonNumber
      }
    }
  }
`;

export const loadAccountsPayables = gql`
  query accountsPayables($id: ID!) {
    student(id: $id) {
      id
      accountsPayables {
        id
        amountForeign
        currency
        repaid
        startedAgreement {
          id
          agreementTitle
        }
      }
    }
  }
`;
