import { MockedResponse } from '@apollo/client/testing';
import {
  AddDeferredPaymentsMutation as AddDeferredPaymentsMutationType,
  PaymentsDeferredQuery as PaymentsDeferredQueryType,
} from 'core';
import {
  AddDeferredPaymentsMutation,
  PaymentsDeferredQuery,
} from 'core/operations';
import { CustomErrorType, DeferredPaymentCurrency } from 'core/graphql';

import { Currencies, IVerifiedPaymentInfo } from 'types';
import { DateService } from 'services/DateService';

export const mockPaymentsDeferred: IVerifiedPaymentInfo[] = [
  {
    contractCurrency: Currencies.BYN,
    contractId: '603',
    contractNumber: 'example',
    date: DateService.parseDate('2022-02-23', 'ISO'),
    parsedUsername: 'Sanders Breanne Lauren',
    sum: 300,
    sumCurrency: Currencies.USD,
    unparsed: 'Sanders Breanne Lauren/example/300/USD',
    username: 'Sanders Breanne Lauren',
  },
];

const mockPaymentsForRequest = [
  {
    date: '2022-02-23',
    studentFullname: 'Sanders Breanne Lauren',
    agreementTitle: 'example',
    sum: 300,
    currency: 'USD',
    userInput: 'Sanders Breanne Lauren/example/300/USD',
  },
];

interface IMockMutationResponse {
  success?: boolean;
  customErrors?: CustomErrorType[];
}

export const mockMutationResponse = ({
  success,
  customErrors,
}: IMockMutationResponse): MockedResponse<AddDeferredPaymentsMutationType> => ({
  request: {
    query: AddDeferredPaymentsMutation,
    variables: {
      payments: mockPaymentsForRequest,
    },
  },
  result: {
    data: {
      createDeferredPayments: {
        customErrors,
        success,
      },
    },
  },
});

export const mockRefetchResponse: MockedResponse<PaymentsDeferredQueryType> = {
  request: {
    query: PaymentsDeferredQuery,
    variables: {},
  },
  result: {
    data: {
      allDeferredPayments: {
        pageInfo: {
          endCursor: 'someCursorString',
          hasNextPage: false,
        },
        edges: [
          {
            node: {
              id: '164',
              date: '2022-02-23',
              studentFullname: null,
              agreementTitle: 'example555',
              currency: DeferredPaymentCurrency.Byn,
              sum: 300,
              userInput: 'username/example555/300/BYN',
              agreementCurrency: '',
            },
          },
        ],
      },
    },
  },
};
