import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface IProps {
  children: React.ReactNode;
  dataLength: number;
  loadMore: () => void;
  hasMore: boolean;
  loading: boolean;
  loader?: React.ReactNode;
}

export const InfiniteScrollContainer = ({
  children,
  dataLength,
  loadMore,
  hasMore,
  loading,
  loader = null,
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!loading && hasMore && containerRef.current) {
      const { clientHeight, offsetTop } = containerRef.current;
      const height = clientHeight + offsetTop;

      if (height < window.innerHeight) {
        loadMore();
      }
    }
  }, [loading, hasMore, loadMore]);

  return (
    <div ref={containerRef}>
      <InfiniteScroll
        next={loadMore}
        dataLength={dataLength}
        hasMore={hasMore}
        loader={loader}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
};
