import {
  TeacherIEType,
  TeacherHireType,
  TeacherLEType,
  TeacherNPType,
  TeacherSEType,
} from 'types';
import {
  GetEditTeacherQuery,
  IndividualEntrepreneurType,
  LegalEntityType,
  NaturalPersonType,
  SelfEmployedType,
} from 'core';

export type TeacherPossibleType =
  | {
      [TeacherHireType.Ie]: TeacherIEType;
    }
  | {
      [TeacherHireType.Le]: TeacherLEType;
    }
  | {
      [TeacherHireType.Np]: TeacherNPType;
    }
  | {
      [TeacherHireType.Se]: TeacherSEType;
    };

export type EditTeacherType = {
  teacherTypes: TeacherPossibleType[];
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
  displayOnSite: boolean;
  accessToLms: boolean;
};

export const transformTeacherData = (
  data: GetEditTeacherQuery | undefined,
): EditTeacherType | undefined => {
  const teacherTypes = data?.teacher?.employmentTypes;

  if (teacherTypes) {
    const result = teacherTypes.reduce(
      (resultTeacherTypes: TeacherPossibleType[], currentItem) => {
        if (currentItem === null) {
          return resultTeacherTypes;
        }

        const currentItemTypename = currentItem.__typename;
        switch (currentItemTypename) {
          case 'IndividualEntrepreneurType': {
            const type = currentItem as IndividualEntrepreneurType;
            const teacherType: TeacherIEType = {
              id: type.id,
              registrationAddress: type.registrationAddress,
              accountNumber: type.accountNumber,
              bankTitle: type.bankTitle,
              bic: type.bic,
              inn: type.inn,
            };

            return [
              ...resultTeacherTypes,
              {
                [TeacherHireType.Ie]: teacherType,
              },
            ];
          }
          case 'LegalEntityType': {
            const type = currentItem as LegalEntityType;
            const teacherType: TeacherLEType = {
              id: type.id,
              registrationAddress: type.registrationAddress,
              accountNumber: type.accountNumber,
              bankTitle: type.bankTitle,
              bic: type.bic,
              title: type.tittle,
              inn: type.inn,
            };
            return [
              ...resultTeacherTypes,
              {
                [TeacherHireType.Le]: teacherType,
              },
            ];
          }
          case 'NaturalPersonType': {
            const type = currentItem as NaturalPersonType;
            const teacherType: TeacherNPType = {
              id: type.id,
              registrationAddress: type.registrationAddress,
              accountNumber: type.accountNumber,
              bankTitle: type.bankTitle,
              bic: type.bic,
              nationality: {
                id: type.nationality.id,
                title: type.nationality.title,
              },
              passportNumber: type.passportNumber,
            };

            return [
              ...resultTeacherTypes,
              {
                [TeacherHireType.Np]: teacherType,
              },
            ];
          }
          case 'SelfEmployedType': {
            const type = currentItem as SelfEmployedType;
            const teacherType: TeacherSEType = {
              id: type.id,
              registrationAddress: type.registrationAddress,
              accountNumber: type.accountNumber,
              bankTitle: type.bankTitle,
              bic: type.bic,
              nationality: {
                id: type.nationality.id,
                title: type.nationality.title,
              },
              passportNumber: type.passportNumber,
              inn: type.inn,
            };

            return [
              ...resultTeacherTypes,
              {
                [TeacherHireType.Se]: teacherType,
              },
            ];
          }
          default:
            return resultTeacherTypes;
        }
      },
      [],
    );

    return {
      teacherTypes: result,
      firstName: data?.teacher?.user.firstName || '',
      middleName: data?.teacher?.user.middleName || '',
      lastName: data?.teacher?.user.lastName || '',
      phone: data?.teacher?.user.phone || '',
      email: data?.teacher?.user.email || '',
      displayOnSite: data?.teacher?.siteDisplay || false,
      accessToLms: data?.teacher?.accessToLms || false,
    };
  }
};
