import React, { memo, useCallback } from 'react';

import { PopupNames } from 'core';
import { usePopupData, usePopupStateOperations } from 'core/hooks';

import { StudentExistPopupComponent } from './StudentExistPopup';

export const StudentExistPopup = memo(() => {
  const {
    operations: { showPopup },
  } = usePopupStateOperations();
  const onClose = useCallback(() => showPopup(PopupNames.addStudent), [
    showPopup,
  ]);
  const data = usePopupData(PopupNames.studentExist);

  const onAddContract = useCallback(
    () => showPopup(PopupNames.studentPayment, { studentId: data?.studentId }),
    [data?.studentId, showPopup],
  );

  return (
    <StudentExistPopupComponent
      onClose={onClose}
      onAddContract={onAddContract}
      email={data?.unique?.email}
      phone={data?.unique?.phone}
      name={data?.name}
    />
  );
});
