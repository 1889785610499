import React, { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
  useChangeLessonDate,
  useIsPopupOpen,
  useJournal,
  useJournalStudents,
  usePopupStateOperations,
  useUpdateAttendance,
} from 'core/hooks';
import { MainTemplate } from 'templates';

import { JournalTable } from 'components/molecules';
import { Heading, IconButton, IconsNames, Button } from 'components/atoms';
import { IconSizes } from 'components/types';
import { PopupNames } from 'core';
import { Colors } from 'services/ColorService';
import { ButtonSizes } from 'components/atoms/Button/Button';

export const Journal = memo(() => {
  const { t } = useTranslation();
  const { journalId } = useParams<{ journalId: string }>();
  const { journalName, comment, loading, lessons, error } = useJournal(
    journalId,
  );
  const [updateAttendance] = useUpdateAttendance();
  const [changeLessonDate] = useChangeLessonDate(journalId);
  const onSaveLessonDate = useCallback(
    (variables) => changeLessonDate({ ...variables, groupId: journalId }),
    [changeLessonDate, journalId],
  );
  const {
    students,
    loading: loadingStudents,
    pageInfo,
    fetchMoreStudents,
  } = useJournalStudents(Number(journalId));

  const isCommentOpened = useIsPopupOpen(PopupNames.commentPopup);

  const {
    operations: { showPopup },
  } = usePopupStateOperations();

  const showComment = useCallback(() => {
    showPopup(PopupNames.commentPopup, {
      comment,
      title: `${t('pages.journal.comment')}`,
    });
  }, [comment, showPopup, t]);

  const showEditJournalPopup = useCallback(() => {
    showPopup(PopupNames.editJournalPopup, journalId);
  }, [journalId, showPopup]);

  if (loading) {
    return (
      <MainTemplate>
        <div>Loading...</div>
      </MainTemplate>
    );
  }

  return (
    <MainTemplate>
      <JournalWrapper>
        <HeadingWrapper>
          {t('pages.journal.journal')} {journalName}
          <StyledButton
            colors={{
              backgroundColor: Colors.White,
              color: Colors.Black,
            }}
            size={ButtonSizes.sm}
            onClick={showEditJournalPopup}
          >
            {t('pages.journal.edit')}
          </StyledButton>
          {comment && (
            <IconWrapper
              onClick={showComment}
              name={
                isCommentOpened ? IconsNames.CommentPrimary : IconsNames.Comment
              }
              size={IconSizes.lg}
            />
          )}
        </HeadingWrapper>
        <JournalTable
          students={students}
          lessons={lessons}
          loading={loadingStudents}
          error={error}
          hasMore={pageInfo?.hasNextPage || false}
          afterStudent={pageInfo?.endCursor || ''}
          fetchMoreStudents={fetchMoreStudents}
          updateAttendance={updateAttendance}
          changeLessonDate={onSaveLessonDate}
        />
      </JournalWrapper>
    </MainTemplate>
  );
});

const IconWrapper = styled((props) => <IconButton {...props} />)`
  margin-left: auto;
`;

const JournalWrapper = styled.div`
  min-width: 100%;
  margin-top: 48px;
`;

const HeadingWrapper = styled((props) => <Heading {...props} />)`
  margin-bottom: 16px;
`;

const StyledButton = styled((props) => <Button {...props} />)`
  margin-left: 16px;
  width: 138px;
`;
