import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Colors } from 'services/ColorService';

import { PopupContainer, PopupErrors } from 'molecules';
import {
  Button,
  Form,
  IconButton,
  IconsNames,
  Text,
  ISelectOption,
} from 'atoms';
import { IconSizes, TeacherHireType } from 'types';

import {
  ITeacherAddTypeFields,
  TeacherAddTypeFields,
} from './TeacherAddTypeFields';
import { IIeFieldsValues } from './IeFields';
import { ISeFieldsValues } from './SeFields';
import { ILeFieldsValues } from './LeFields';
import { INpFieldsValues } from './NpFields';

export interface ITeacherAddTypeValues {
  teacherTypes: {
    [TeacherHireType.Ie]: IIeFieldsValues;
    [TeacherHireType.Se]: ISeFieldsValues;
    [TeacherHireType.Le]: ILeFieldsValues;
    [TeacherHireType.Np]: INpFieldsValues;
    type: ISelectOption<string> | null;
    id: string;
    disabled?: boolean;
  }[];
}

export interface ITeacherAddTypePopup
  extends Omit<
    ITeacherAddTypeFields,
    'values' | 'index' | 'teacherTypesInputDisabled'
  > {
  values: ITeacherAddTypeValues;
  errorMessage?: string | null;
  loading?: boolean;
  className?: string;
  disabledButton: boolean;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onAddType: () => void;
  onClose: () => void;
  onDeleteType: (index: number) => void;
}

export const TeacherAddTypePopupComponent = memo(
  ({
    values,
    errors,
    errorMessage,
    countries,
    teacherTypesOptions,
    disabledButton,
    onInputChange,
    handleSubmit,
    onClose,
    onAddType,
    onDeleteType,
    loading,
    className,
  }: ITeacherAddTypePopup) => {
    const { t } = useTranslation();
    return (
      <PopupContainer
        title={t('teacherTypePopup.addTeacherType')}
        onClose={onClose}
      >
        <FormWrapper onSubmit={handleSubmit} className={className}>
          {values?.teacherTypes.map((element, index) => (
            <TeacherAddTypeFields
              key={element.id}
              index={index}
              values={element}
              errors={errors}
              countries={countries}
              teacherTypesOptions={teacherTypesOptions}
              teacherTypesInputDisabled={
                !!(index === 0 && values.teacherTypes.length > 1) ||
                Boolean(element.disabled)
              }
              onInputChange={onInputChange}
              onDeleteType={onDeleteType}
            />
          ))}
          <WrapperSubmitButton>
            {Boolean(values.teacherTypes[0].type) &&
              values.teacherTypes.length < 4 && (
                <>
                  <IconButton
                    name={IconsNames.PlusLargeIcon}
                    size={IconSizes.xl}
                    onClick={onAddType}
                  />
                  <WrapperText>
                    <Text size="md">{t('teacherTypePopup.addType')}</Text>
                  </WrapperText>
                </>
              )}

            <SubmitButton
              type="submit"
              variant="primary"
              colors={{
                color: Colors.White,
                activeColor: Colors.Blue,
                backgroundColor: Colors.Blue,
                bgDisabledColor: Colors.DeactivatedGrey,
              }}
              loading={loading}
              disabled={disabledButton}
            >
              {t('teacherTypePopup.button.createApplication')}
            </SubmitButton>
          </WrapperSubmitButton>
        </FormWrapper>
        <PopupErrorsStyled>{errorMessage}</PopupErrorsStyled>
      </PopupContainer>
    );
  },
);

const FormWrapper = styled(Form)`
  margin-top: 8px;
`;

const SubmitButton = styled(Button)`
  width: 258px;
  margin: 0 0 0 auto;
`;

const PopupErrorsStyled = styled(PopupErrors)`
  margin-top: 24px;
`;

const WrapperSubmitButton = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
`;

const WrapperText = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 16px;
`;
