import React, { memo } from 'react';
import styled from 'styled-components';
import { ValueType, OptionTypeBase } from 'react-select';

import {
  Input,
  InputLabel,
  DateSelect,
  Select,
  TextArea,
  Text,
  ISelectOption,
} from 'components/atoms';

import { getFontFamily } from 'services/FontService';
import { Currencies, ErrorsType } from 'types';
import { DateService } from 'services/DateService';
import { useTranslation } from 'react-i18next';

type InputHandler<T> = (value: T, inputName: string) => void;

interface IChargebackForm {
  values: IChargebackFormValues;
  errors?: ErrorsType<IChargebackFormValues> | null;
  repaid: boolean;
  onChangeInput: InputHandler<
    string | boolean | ValueType<OptionTypeBase, false>
  >;
}

export interface IChargebackFormValues {
  refundDate: Date | null;
  currency: ISelectOption<Currencies> | null;
  refundAmount: string;
  accountsPayable: string;
  comment: string;
}

export const ChargebackForm = memo(
  ({ values, errors, repaid, onChangeInput }: IChargebackForm) => {
    const { t } = useTranslation();
    return (
      <>
        <RefundRow>
          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.refundDate)}>
              {t('chargebackForm.fields.refundDate.label')}
            </InputLabel>

            <DateSelect
              onChange={(value: Date | null) =>
                onChangeInput(value, 'refundDate')
              }
              value={values.refundDate}
              hasError={Boolean(errors?.refundDate)}
              disabledDate={(date) => DateService.isAfter(date, new Date())}
              disabled={repaid}
            />
          </WrapperInput>

          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.refundAmount)}>
              {t('chargebackForm.fields.refundAmount.label')}
            </InputLabel>
            <InputWithSelect>
              <Input
                placeholder={t(
                  'chargebackForm.fields.refundAmount.placeholder',
                )}
                type="text"
                value={values.refundAmount}
                name="refundAmount"
                onChange={({ target: { value } }) =>
                  onChangeInput(value, 'refundAmount')
                }
                onKeyPress={(event) =>
                  !new RegExp(/[0-9.]/).test(event.key) &&
                  event.preventDefault()
                }
                hasError={Boolean(errors?.refundAmount)}
                disabled={repaid}
                financialNumber
              />
              <Select
                type="primary"
                width="128px"
                isDisabled
                value={values.currency}
                onChange={(value) => onChangeInput(value, 'currency')}
                hideIndicator
              />
            </InputWithSelect>
          </WrapperInput>

          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.accountsPayable)}>
              {t('chargebackForm.fields.accountsPayable.label')}
            </InputLabel>
            <InputWithSelect>
              <Input
                placeholder={t(
                  'chargebackForm.fields.accountsPayable.placeholder',
                )}
                type="text"
                value={values.accountsPayable}
                name="accountsPayable"
                onChange={({ target: { value } }) =>
                  onChangeInput(value, 'accountsPayable')
                }
                onKeyPress={(event) =>
                  !new RegExp(/[0-9.]/).test(event.key) &&
                  event.preventDefault()
                }
                hasError={Boolean(errors?.accountsPayable)}
                disabled={repaid}
                financialNumber
              />
              <Select
                type="primary"
                width="128px"
                isDisabled
                value={values.currency}
                onChange={(value) => onChangeInput(value, 'currency')}
                hideIndicator
              />
            </InputWithSelect>
          </WrapperInput>
        </RefundRow>
        <WrappedComment size="md">
          {t('chargebackForm.fields.comment.label')}
        </WrappedComment>
        <WrappedTextArea
          name="comment"
          placeholder=""
          value={values.comment}
          rows={3}
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLTextAreaElement>) =>
            onChangeInput(value, 'comment')
          }
        />
      </>
    );
  },
);

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
`;

const RefundRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  margin: 0 0 24px 0;
`;
const InputWithSelect = styled.div`
  display: flex;
  flex-direction: row;
`;

const WrappedComment = styled((props) => <Text {...props} />)`
  font-family: ${getFontFamily('bold')};
`;

const WrappedTextArea = styled((props) => <TextArea {...props} />)`
  margin-bottom: 32px;
`;
