import { useCallback, useMemo } from 'react';
import { FormikErrors } from 'formik';
import { OptionTypeBase, ValueType } from 'react-select';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import { PopupNames } from 'core';
import {
  usePopupStateOperations,
  useGetApplicationPopupData,
} from 'core/hooks';

import { ISelectOption } from 'components/atoms';
import { IAddApplicationFieldsValues } from 'molecules';
import { IUserData, ServerCodes } from 'components/types';
import { TEACHER_TYPE_OPTIONS } from 'components/constants';

type AddApplicationHelpersType = {
  disabledButton: boolean;
  teacherTypes: ISelectOption<string>[];
  handleChange: (
    name: string,
    value?: string | boolean | ISelectOption<string> | null | OptionTypeBase,
  ) => void;
  onClose: () => void;
};

interface IAddApplicationArguments {
  values: IAddApplicationFieldsValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<IAddApplicationFieldsValues>>;
  user?: IUserData;
  code?: ServerCodes;
}

export const useAddApplicationHelpers = ({
  values,
  user,
  code,
  setFieldValue,
}: IAddApplicationArguments): AddApplicationHelpersType => {
  const { t } = useTranslation();
  const {
    operations: { hidePopup, showPopup },
  } = usePopupStateOperations();

  const { types } = useGetApplicationPopupData();

  const disabledButton: boolean = useMemo(() => {
    const newValues = R.omit(['comment', 'servicePlace', 'riaPrice'], values);
    return Object.values(newValues).every((currentValue) =>
      Boolean(currentValue),
    );
  }, [values]);

  const teacherTypes = useMemo(
    () =>
      TEACHER_TYPE_OPTIONS.filter((option) => {
        if (types) {
          return Object.keys(types).includes(option.value);
        }
        return {};
      }),
    [types],
  );

  const handleChange = useCallback(
    (
      name: string,
      value?:
        | string
        | boolean
        | ISelectOption<string>
        | Date
        | null
        | ValueType<OptionTypeBase, false>,
    ) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const onClose = useCallback(() => {
    showPopup(PopupNames.confirmPopup, {
      title: `${t('common.applicationWontBeSaved')}`,
      children: `${t('common.youAreGoingToCloseApplication')}`,
      confirmText: `${t('common.confirmClose')}`,
      onCancel: () =>
        showPopup(PopupNames.addApplicationPopup, {
          values,
          user,
          code,
          types,
        }),
      onConfirm: () => hidePopup(PopupNames.confirmPopup),
    });
  }, [values, user, code, types, showPopup, hidePopup, t]);

  return {
    disabledButton: !disabledButton,
    teacherTypes,
    handleChange,
    onClose,
  };
};
