import { FinanceYearsQuery } from 'core';

export const transformFinanceYears = (
  data: FinanceYearsQuery | undefined,
): number[] | undefined => {
  if (data?.financeYears) {
    const result = data.financeYears.map((year) => Number(year));

    return result;
  }
};
