import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AddUniqueTeacherMutationVariables,
  useAddUniqueTeacherMutation,
  CustomErrorType,
} from 'core';
import {
  TeachersMetaQuery,
  TeachersQuery,
  ApplicationsQuery,
} from 'core/operations';

import {
  ServerCodes,
  ErrorsType,
  ToastTypes,
  GlobalToastTypeNames,
} from 'types';
import {
  IAddApplicationFieldsValues,
  ITeacherAddFieldsValues,
} from 'molecules';
import {
  applicationTeacherErrorsReducer,
  getApplicationErrors,
  useToasts,
} from 'core/hooks';

type ICreateUniqueTeacherHookType = [
  (uniqueTeacher: AddUniqueTeacherMutationVariables) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<
      ITeacherAddFieldsValues & IAddApplicationFieldsValues
    > | null;
  },
];

export const useCreateUniqueTeacher = (): ICreateUniqueTeacherHookType => {
  const { t } = useTranslation();
  const [errors, dispatch] = useReducer(applicationTeacherErrorsReducer, null);
  const { addToast } = useToasts();

  const [
    addUniqueTeacherMutation,
    { data, loading },
  ] = useAddUniqueTeacherMutation({
    refetchQueries: [
      {
        query: TeachersQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: ApplicationsQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: TeachersMetaQuery,
      },
    ],
  });

  const createUniqueTeacher = useCallback(
    ({
      application,
      employmentTypes,
      teacher,
    }: AddUniqueTeacherMutationVariables) => {
      addUniqueTeacherMutation({
        variables: {
          application,
          employmentTypes,
          teacher,
        },
      });
    },
    [addUniqueTeacherMutation],
  );

  useEffect(() => {
    const serverErrors = data?.createUniqueTeacher?.customErrors;
    if (serverErrors && serverErrors.length) {
      const errorsFound = getApplicationErrors(
        serverErrors as CustomErrorType[],
      );
      switch (true) {
        case errorsFound.applicationExist:
          dispatch({ type: ServerCodes.applicationTitleExist });
          break;
        case errorsFound.lessonCountExceeds:
          dispatch({ type: ServerCodes.lessonCountExceeds });
          break;

        default:
          addToast({
            data: {
              title: `${t('common.somethingWentWrong')}`,
              type: ToastTypes.error,
            },
            type: GlobalToastTypeNames.TeacherAdd,
          });
      }
    }
  }, [data?.createUniqueTeacher?.customErrors, addToast, t]);

  return [
    createUniqueTeacher,
    {
      success: data?.createUniqueTeacher?.success || false,
      loading,
      errors,
    },
  ];
};
