import {
  CURRENCY_OPTIONS_RU,
  CURRENCY_OPTIONS_TEACHER_RU,
  CURRENCY_OPTIONS_PL,
  CURRENCY_OPTIONS_TEACHER_PL,
} from 'components/constants/common';

export {
  WEEK_DAYS,
  weekDays,
  TIME_OPTIONS,
  invalidPhoneError,
  MONTH_NAMES,
  TEACHER_TYPE_OPTIONS,
  TEACHER_TYPE_COUNT_FIELDS,
} from './common';

const isPLN = process.env.REACT_APP_LANG === 'pl';
const CURRENCY_OPTIONS = isPLN ? CURRENCY_OPTIONS_PL : CURRENCY_OPTIONS_RU;
const CURRENCY_OPTIONS_TEACHER = isPLN
  ? CURRENCY_OPTIONS_TEACHER_PL
  : CURRENCY_OPTIONS_TEACHER_RU;

export { CURRENCY_OPTIONS, CURRENCY_OPTIONS_TEACHER };
