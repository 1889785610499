import { useCallback, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import { useRemoveGroupMutation } from 'core/graphql';
import { popupData, PopupNames } from 'core';

import { GroupsQuery, SettingsMetaQuery } from 'core/operations/queries';

type deleteGroupType = [
  () => void,
  {
    success: boolean;
    loading: boolean;
    error?: { message?: string };
  },
];

export const useDeleteGroup = (): deleteGroupType => {
  const groupData = useReactiveVar(popupData)[PopupNames.deleteGroup];
  const [removeGroupMutation, { data, loading }] = useRemoveGroupMutation({
    refetchQueries: [
      {
        query: GroupsQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: SettingsMetaQuery,
      },
    ],
  });
  const [error, setError] = useState<{ message?: string }>();
  const deleteGroup = useCallback(() => {
    if (groupData && groupData.groupId) {
      removeGroupMutation({
        variables: { groupId: Number(groupData.groupId) },
      }).catch((err) =>
        setError({
          message: err.message,
        }),
      );
    }
  }, [removeGroupMutation, groupData]);

  return [
    deleteGroup,
    {
      success: data?.deleteGroup?.success || false,
      loading,
      error,
    },
  ];
};
