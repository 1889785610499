import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Form, Heading, Input, InputLabel } from 'atoms';
import { PopupErrors } from 'molecules';

import { ErrorsType } from 'types';
import { Colors } from 'services/ColorService';

type InputHandler<T> = (value: T, inputName: string) => void;
export interface ICreatePasswordForm extends ICreatePasswordFormStyle {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onChangeInput: InputHandler<string>;
  values: ICreatePasswordFormValues;
  loading?: boolean;
  errors?: ErrorsType<ICreatePasswordFormValues> | null;
  errorsMessage?: string | null;
}

export interface ICreatePasswordFormStyle {
  width?: string;
}

export interface IWrapper {
  margin?: string;
  width?: string;
}

export interface ICreatePasswordFormValues {
  newPassword: string;
  confirmPassword: string;
}

export const CreatePasswordFormComponent = memo(
  ({
    handleSubmit,
    onChangeInput,
    values,
    loading = false,
    errors,
    errorsMessage,
    width,
  }: ICreatePasswordForm) => {
    const { t } = useTranslation();
    return (
      <CreatePasswordFormStyled width={width}>
        <Wrapper margin="0 0 40px 0">
          <Heading>{t('passwordForm.createPass')}</Heading>
        </Wrapper>
        <Form onSubmit={handleSubmit}>
          <Wrapper>
            <InputLabel hasError={Boolean(errors?.newPassword)}>
              {t('passwordForm.fields.newPassword.label')}
            </InputLabel>
            <Input
              type="password"
              placeholder={t('passwordForm.fields.newPassword.placeholder')}
              value={values.newPassword}
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'newPassword')
              }
              name="newPassword"
              max={30}
              hasError={Boolean(errors?.newPassword)}
            />
          </Wrapper>
          <Wrapper margin="24px 0 0 0">
            <InputLabel hasError={Boolean(errors?.confirmPassword)}>
              {t('passwordForm.fields.confirmPassword.label')}
            </InputLabel>
            <Input
              type="password"
              placeholder={t('passwordForm.fields.confirmPassword.placeholder')}
              value={values.confirmPassword}
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'confirmPassword')
              }
              name="confirmPassword"
              max={30}
              hasError={Boolean(errors?.confirmPassword)}
            />
          </Wrapper>
          <PopupErrorsStyled>
            <PopupErrors>{errorsMessage}</PopupErrors>
          </PopupErrorsStyled>
          <Wrapper margin="40px 0 0 0">
            <Button
              type="submit"
              loading={loading}
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
              }}
            >
              {t('passwordForm.button.enter')}
            </Button>
          </Wrapper>
        </Form>
      </CreatePasswordFormStyled>
    );
  },
);

const CreatePasswordFormStyled = styled.div<ICreatePasswordFormStyle>`
  width: ${({ width }) => width || '100%'};
  box-sizing: border-box;
  padding: 48px 64px 80px 64px;
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Wrapper = styled.div<IWrapper>`
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin || 'initial'};
`;

const PopupErrorsStyled = styled.div`
  margin-top: 16px;
`;
