import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormik } from 'formik';

import styled from 'styled-components';

import { PopupNames } from 'core';
import { useGetDeferredPayments, usePopupStateOperations } from 'core/hooks';

import { IVerifiedPaymentInfo } from 'components/types';

import { CheckedPaymentsControl, PaymentsDeferredTable } from 'molecules';

export interface ICheckedPayments {
  [id: string]: boolean;
}

interface IDebtorsPageDeferreWrapper {
  searchValue: string;
  userPermission?: boolean;
}

export const DebtorsPageDeferredWrapper = memo(
  ({ searchValue, userPermission }: IDebtorsPageDeferreWrapper) => {
    const [payments, setPayments] = useState<ICheckedPayments>({});
    const [checkedPayments, setCheckedPayments] = useState<
      IVerifiedPaymentInfo[]
    >([]);
    const [checkedPaymentsCount, setCheckedPaymentsCount] = useState<number>(0);

    const [
      fetchPayments,
      {
        data,
        hasMore,
        loading,
        operations: { fetchMore: fetchMorePayments },
      },
    ] = useGetDeferredPayments();

    const {
      operations: { showPopup },
    } = usePopupStateOperations();

    const { values, setValues } = useFormik({
      initialValues: {
        commonCheckbox: false,
        data,
      },
      enableReinitialize: true,
      onSubmit: () => {},
    });

    const onHeaderCheckboxChange = useCallback(() => {
      const checked = values.data.reduce(
        (newPayments: ICheckedPayments, payment) => {
          if (payment.deferredPaymentId) {
            return {
              ...newPayments,
              [payment.deferredPaymentId]: !values.commonCheckbox,
            };
          }

          return newPayments;
        },
        {},
      );

      setPayments(checked);
      setValues((previousValues) => ({
        ...previousValues,
        commonCheckbox: !values.commonCheckbox,
      }));
    }, [values, setValues]);

    const onCheckboxChange = useCallback(
      (id: string) => {
        const newValue = !payments[id];
        setPayments({
          ...payments,
          [id]: newValue,
        });
      },
      [payments, setPayments],
    );

    const onControlClose = useCallback(() => {
      setPayments({});
    }, []);

    useEffect(() => {
      const checkedId = Object.entries(payments).reduce(
        (checkedArr: string[], [key, value]) => {
          if (value) {
            return [...checkedArr, key];
          }

          return checkedArr;
        },
        [],
      );
      const count = checkedId.length;
      const checkedVerifiedPayments = values.data.filter(
        (payment) =>
          payment.deferredPaymentId &&
          checkedId.includes(payment.deferredPaymentId),
      );

      setValues({
        commonCheckbox: count === values.data.length,
        data: values.data,
      });

      setCheckedPayments(checkedVerifiedPayments);
      setCheckedPaymentsCount(count);
    }, [
      values.data,
      payments,
      setCheckedPayments,
      setCheckedPaymentsCount,
      setValues,
    ]);

    const loadMore = useCallback(() => {
      fetchMorePayments(searchValue);
    }, [searchValue, fetchMorePayments]);

    useEffect(() => {
      const query = searchValue.trim();
      fetchPayments(query);
    }, [searchValue, fetchPayments]);

    const onValidateClick = useCallback(() => {
      onControlClose();
      showPopup(PopupNames.checkPayment, {
        payments: checkedPayments,
      });
    }, [checkedPayments, showPopup, onControlClose]);

    return (
      <>
        <PaymentsDeferredTable
          headerCheckboxValue={values.commonCheckbox}
          data={values.data}
          onHeaderCheckboxChange={onHeaderCheckboxChange}
          onCheckboxChange={onCheckboxChange}
          checkedPayments={payments}
          loading={loading}
          loadMore={loadMore}
          hasMore={hasMore}
          userPermission={userPermission}
        />
        {checkedPaymentsCount > 0 &&
          createPortal(
            <StyledCheckedPaymentsControl
              count={checkedPaymentsCount}
              onValidate={onValidateClick}
              onClose={onControlClose}
            />,
            document.body,
          )}
      </>
    );
  },
);

const StyledCheckedPaymentsControl = styled((props) => (
  <CheckedPaymentsControl {...props} />
))`
  position: fixed;
  bottom: 0;
  left: 0;
`;
