import React, { memo } from 'react';
import { ValueType, OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Button, Form, Input, InputLabel, InputPhone } from 'atoms';

import { PopupContainer, PopupErrors } from 'molecules';

import { ErrorsType } from 'types';
import { Colors } from 'services/ColorService';

type InputHandler<T> = (value: T, inputName: string) => void;

export interface IAddStudentPopup {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onChangeInput: InputHandler<
    string | number | boolean | ValueType<OptionTypeBase, false>
  >;
  onClose: () => void;
  values: IAddStudentPopupValues;
  errors?: ErrorsType<IAddStudentPopupValues> | null;
  errorsMessage?: string | null;
}

export interface IAddStudentPopupValues {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
}

export const AddStudentPopupComponent = memo(
  ({
    onClose,
    onChangeInput,
    handleSubmit,
    values,
    errors,
    errorsMessage,
  }: IAddStudentPopup) => {
    const { t } = useTranslation();
    return (
      <PopupContainer onClose={onClose} title={t('studentPopup.addStudent')}>
        <WrappedForm onSubmit={handleSubmit}>
          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.lastName)}>
              {t('studentPopup.fields.lastName.label')}
            </InputLabel>
            <Input
              placeholder={t('studentPopup.fields.lastName.placeholder')}
              type="text"
              value={values.lastName}
              name="lastName"
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'lastName')
              }
              hasError={Boolean(errors?.lastName)}
            />
          </WrapperInput>

          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.firstName)}>
              {t('studentPopup.fields.firstName.label')}
            </InputLabel>
            <Input
              placeholder={t('studentPopup.fields.firstName.placeholder')}
              type="text"
              value={values.firstName}
              name="firstName"
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'firstName')
              }
              hasError={Boolean(errors?.firstName)}
            />
          </WrapperInput>

          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.middleName)}>
              {t('studentPopup.fields.middleName.label')}
            </InputLabel>
            <Input
              placeholder={t('studentPopup.fields.middleName.placeholder')}
              type="text"
              value={values.middleName}
              name="middleName"
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'middleName')
              }
              hasError={Boolean(errors?.middleName)}
            />
          </WrapperInput>

          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.email)}>
              {t('studentPopup.fields.email.label')}
            </InputLabel>
            <Input
              placeholder={t('studentPopup.fields.email.placeholder')}
              type="text"
              value={values.email}
              name="email"
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'email')
              }
              hasError={Boolean(errors?.email)}
            />
          </WrapperInput>

          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.phone)}>
              {t('studentPopup.fields.phone.label')}
            </InputLabel>
            <InputPhone
              placeholder={t('studentPopup.fields.phone.placeholder')}
              value={values.phone}
              name="phone"
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'phone')
              }
              hasError={Boolean(errors?.phone)}
            />
          </WrapperInput>

          <WrapperSendButton
            type="submit"
            colors={{
              backgroundColor: Colors.Blue,
              color: Colors.White,
            }}
          >
            {t('studentPopup.button.addAgreement')}
          </WrapperSendButton>

          <PopupErrors>{errorsMessage}</PopupErrors>
        </WrappedForm>
      </PopupContainer>
    );
  },
);

const WrappedForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  margin-top: 32px;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperSendButton = styled((props) => <Button {...props} />)`
  margin-left: auto;
  margin-top: 46px;
  width: 188px;
`;
