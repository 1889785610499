import React, { memo, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

export interface ISwitchFilterValue {
  label: string;
  value: string;
}

export interface ISwitchFilterProps {
  currentValue: string;
  items: ISwitchFilterValue[];
  onChange: (newValue: string) => void;
  loading?: boolean;
  className?: string;
}

export const SwitchFilter = memo(
  ({
    currentValue,
    items,
    onChange,
    loading,
    className,
  }: ISwitchFilterProps) => {
    const onItemClick = useCallback(
      (value: string) => {
        onChange(value);
      },
      [onChange],
    );

    return (
      <Wrapper className={className} $loading={loading && !items.length}>
        {items.map((item) => (
          <Item
            isActive={item.value === currentValue}
            key={item.value}
            onClick={() => onItemClick(item.value)}
          >
            {item.label}
          </Item>
        ))}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{ $loading?: boolean }>`
  box-sizing: border-box;
  display: inline-flex;
  height: 40px;
  border: 1px solid ${Colors.LightGray};
  border-radius: 4px;
  background-color: ${Colors.White};
  overflow: hidden;
  ${({ $loading }) => {
    if ($loading) {
      return css`
        visibility: hidden;
      `;
    }
  }}
`;

const Item = styled.button<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 64px;
  border: none;
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  color: ${Colors.Black};
  background-color: ${Colors.White};
  cursor: pointer;

  ${({ isActive }) => {
    if (isActive) {
      return css`
        color: ${Colors.Blue};
      `;
    }
  }}

  &:not(:first-of-type) {
    border-left: 1px solid ${Colors.LightGray};
  }
`;
