import * as React from 'react';
import { memo } from 'react';
import { Row, useTable } from 'react-table';

import styled from 'styled-components';

import { InfiniteScrollContainer, LoadingData } from 'molecules';
import {
  CheckBox,
  Icon,
  IconsNames,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'atoms';
import { Gradients, IAttendances } from 'components/types';
import { ChangeAttendanceMutationVariables } from 'core/graphql';
import { Colors } from 'services/ColorService';
import { DateService } from 'services/DateService';

export type IRow = {
  name: string;
  agreementIsActive?: boolean | null;
  attendances: IAttendances;
};

export enum Accesors {
  name = 'name',
  attendances = 'attendances',
}

export interface IColumn {
  accessor: Accesors;
  flexible?: boolean;
  align?: 'left' | 'right' | 'center';
  Header?: string | React.ReactElement;
  paddingRight?: string;
  paddingLeft?: string;
  columnWidth?: string;
}

export interface IJournalTableComponent {
  columns: IColumn[];
  rows: IRow[];
  isFlexible: boolean;
  noDataText: string;
  hasMore: boolean;
  afterStudent: string;
  loading: boolean;
  loadMore: (after: string) => void;
  updateAttendance: (variables: ChangeAttendanceMutationVariables) => void;
}

export const JournalTableComponent = memo(
  ({
    isFlexible,
    noDataText,
    rows: rowsSource,
    columns,
    hasMore,
    afterStudent,
    loading,
    loadMore,
    updateAttendance,
  }: IJournalTableComponent) => {
    const {
      getTableProps,
      headers,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data: rowsSource,
    });

    return (
      <Wrapper>
        <InfiniteScrollContainer
          loadMore={() => loadMore(afterStudent)}
          hasMore={hasMore}
          dataLength={rows.length}
          loading={loading || false}
        >
          <Table
            {...getTableProps()}
            flexible={isFlexible}
            variant="defaultJournal"
          >
            <TableHead>
              <TableRow type="row">
                {headers.map((column: any) => {
                  const {
                    key: cellKey,
                    ...restCellProps
                  } = column.getHeaderProps({
                    type: 'headerCell',
                    flexible: column.flexible && isFlexible,
                    width: column.columnWidth,
                    paddings: {
                      right: column.paddingRight,
                      left: column.paddingLeft,
                    },
                  });

                  return (
                    <TableCell key={cellKey} {...restCellProps}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {!rows.length && !loading && (
                <WrappedTableRow key="noData">
                  <TableCell type="cell" width="100%" align="center">
                    {noDataText}
                  </TableCell>
                </WrappedTableRow>
              )}

              {loading && (
                <WrappedTableRow>
                  <TableCell width="100%" align="center">
                    <LoadingDataStyled />
                  </TableCell>
                </WrappedTableRow>
              )}

              {Boolean(rows.length) &&
                !loading &&
                rows.map((row: Row<IRow>) => {
                  prepareRow(row);
                  const { key: rowKey, ...restRowProps } = row.getRowProps();
                  const { agreementIsActive } = row.original;

                  return (
                    <WrappedTableRow key={rowKey} {...restRowProps}>
                      {row.cells.map((cell: any, index) => {
                        const {
                          key: cellKey,
                          ...restCellProps
                        } = cell.getCellProps({
                          width: cell.column.columnWidth,
                          flexible: cell.column.flexible && isFlexible,
                          paddings: {
                            right: cell.column.paddingRight,
                            left: cell.column.paddingLeft,
                          },
                        });

                        const isAfterToday = DateService.isBefore(
                          new Date(),
                          cell.column.Header.props.value,
                        );

                        const isSameOrAfterToday = DateService.isSameOrBefore(
                          new Date(),
                          cell.column.Header.props.value,
                        );

                        if (index === 0) {
                          return (
                            <TableCell
                              key={cellKey}
                              textColor={
                                agreementIsActive
                                  ? Colors.Black
                                  : Colors.DeactivatedGrey
                              }
                              {...restCellProps}
                            >
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        }

                        if (!cell?.value) {
                          return (
                            <EmptyJournalCell
                              key={cellKey}
                              borderColor={Colors.LightGray}
                              {...restCellProps}
                            >
                              <Icon
                                width={128}
                                height={56}
                                name={IconsNames.EmptyCell}
                              />
                            </EmptyJournalCell>
                          );
                        }

                        const { id: attendanceId, attendance } = cell.value;

                        return (
                          <TableCheckBox
                            align="center"
                            checked={attendance}
                            key={cellKey}
                            isSameOrAfterToday={isSameOrAfterToday}
                            borderColor={Colors.LightGray}
                            {...restCellProps}
                            agreementIsActive={agreementIsActive}
                          >
                            <WrappedCheckBox
                              variant={
                                isSameOrAfterToday ? 'default' : 'tableCheckBox'
                              }
                              checked={isAfterToday ? false : attendance}
                              onClick={() =>
                                updateAttendance({
                                  attendanceId,
                                  attendance: !attendance,
                                })
                              }
                              disabled={isAfterToday || !agreementIsActive}
                            />
                          </TableCheckBox>
                        );
                      })}
                    </WrappedTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </InfiniteScrollContainer>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  overflow-y: hidden;
`;

const WrappedTableRow = styled((props) => <TableRow {...props} />)`
  height: 56px;
`;

const TableCheckBox = styled((props) => <TableCell {...props} />)`
  height: 100%;
  padding: 20px 0;
  background: ${({ checked, isSameOrAfterToday, agreementIsActive }) => {
    if (isSameOrAfterToday) {
      if (!agreementIsActive) {
        return Colors.LightBlue;
      }
      return '';
    }

    if (!agreementIsActive) {
      return Colors.MainBackground;
    }

    if (checked) {
      return Gradients.Success;
    }

    return Gradients.Danger;
  }};
`;

const WrappedCheckBox = styled((props) => <CheckBox {...props} />)`
  width: 16px;
`;

const EmptyJournalCell = styled(TableCell)`
  height: 100%;
`;

const LoadingDataStyled = styled((props) => <LoadingData {...props} />)``;
