import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

import { Colors } from 'services/ColorService';

export interface IGuestTemplate {
  children: React.ReactElement;
}

export const GuestTemplate = memo(({ children }: IGuestTemplate) => (
  <Paper>
    <Wrapper>{children}</Wrapper>
  </Paper>
));

export const Paper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.MainBackground};
`;

const Wrapper = styled.div`
  width: 480px;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  box-shadow: 0px 3px 9px rgba(225, 226, 230, 0.48);
`;
