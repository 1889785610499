import { ServerCodes, ErrorsType, FieldErrorTypes } from 'types';
import { IAddGroupPopupValues } from 'molecules';
import { CustomErrorType } from 'core/graphql';
import i18n from '../../../i18n';

type ICreateGroupAction = {
  type: ServerCodes;
};

export const groupErrorReducer = (
  state: ErrorsType<IAddGroupPopupValues | {}> | null,
  action: ICreateGroupAction,
): ErrorsType<IAddGroupPopupValues | {}> | null => {
  switch (action.type) {
    case ServerCodes.groupExist:
      return {
        groupNumber: {
          message: `${i18n.t('common.groupExist')}`,
          type: FieldErrorTypes.SERVER_ERROR,
        },
      };
    case ServerCodes.lessonExist:
      return {
        message: `${i18n.t('common.moduleHasLesson')}`,
        type: FieldErrorTypes.SERVER_ERROR,
      };

    default:
      return null;
  }
};

export const getGroupErrors = (errors: CustomErrorType[]) => {
  const groupExist = errors.some(
    (error) => error.code === 'unique_name' && error.field === '__all__',
  );
  const lessonExist = errors.some(
    (error) => error.code === 'unique_together' && error.field === 'module',
  );
  return {
    groupExist,
    lessonExist,
  };
};
