import { CustomErrorType } from 'core/graphql';
import { ErrorsType, FieldErrorTypes, ServerCodes } from 'types';
import { invalidPhoneError } from 'components/constants';
import { ICompanyFormValues } from 'molecules';
import i18n from '../../../i18n';

type ICreateCompanyAction = {
  type: ServerCodes;
};

export const createCompanyReducer = (
  state: ErrorsType<ICompanyFormValues> | null,
  action: ICreateCompanyAction,
): ErrorsType<ICompanyFormValues> | null => {
  const type = FieldErrorTypes.SERVER_ERROR;

  switch (action.type) {
    case ServerCodes.companyExist:
      return {
        ...state,
        name: {
          message: `${i18n.t('common.companyDataExist')}`,
          type,
        },
        registrationNumber: {
          message: `${i18n.t('common.companyDataExist')}`,
          type,
        },
      };
    case ServerCodes.nameExist:
      return {
        ...state,
        name: {
          message: `${i18n.t('common.companyNameExist')}`,
          type,
        },
      };
    case ServerCodes.requiredName:
      return {
        ...state,
        name: {
          message: `${i18n.t('common.errorInField')}`,
          type,
        },
      };
    case ServerCodes.regNumberExist:
      return {
        ...state,
        registrationNumber: {
          message: `${i18n.t('common.companyNumberExist')}`,
          type,
        },
      };
    case ServerCodes.invalidPhone:
      return {
        ...state,
        ...invalidPhoneError,
      };
    default:
      return null;
  }
};

export const getCompanyMutationErrors = (
  errors: CustomErrorType[],
): ServerCodes => {
  const uniqueName = errors.some(
    (error) => error.code === 'unique' && error.field === 'name',
  );
  const uniqueRegNumber = errors.some(
    (error) => error.code === 'unique' && error.field === 'registration_number',
  );
  const uniqueSum = Number(uniqueName) + Number(uniqueRegNumber);

  const invalidPhone = errors.some(
    (error) => error.code === 'invalid' && error.field === 'phone',
  );

  const requiredName = errors.some(
    (error) => error.code === 'required' && error.field === 'name',
  );

  switch (true) {
    case uniqueSum > 1:
      return ServerCodes.companyExist;
    case uniqueName:
      return ServerCodes.nameExist;
    case requiredName:
      return ServerCodes.requiredName;
    case uniqueRegNumber:
      return ServerCodes.regNumberExist;
    case invalidPhone:
      return ServerCodes.invalidPhone;
    default:
      return ServerCodes.none;
  }
};
