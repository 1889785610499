import { useGetContributionsMetaQuery } from 'core/graphql';
import { useMemo } from 'react';
import { transformContributionsNums } from './transform';

export const useGetContributionsMeta = (): number[] => {
  const { data } = useGetContributionsMetaQuery({
    fetchPolicy: 'cache-first',
  });

  const transformedContributionsNums: number[] = useMemo(() => {
    const untransformed = data?.metaContributions?.contributionsNums || [];

    return transformContributionsNums(untransformed);
  }, [data?.metaContributions?.contributionsNums]);

  return transformedContributionsNums;
};
