import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';

import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';

export interface IPopupErrors {
  children?: React.ReactNode;
  className?: string;
}

export const PopupErrors = memo(({ children, className }: IPopupErrors) => (
  <Text show={Boolean(children)} className={className}>
    {children || null}
  </Text>
));

const Text = styled.p<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: ${getFontFamily('regular')};
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.Red};
`;
