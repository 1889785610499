import { useCallback } from 'react';

import { useReactiveVar } from '@apollo/client';

import {
  usePublishToLmsMutation,
  PublishToLmsMutationVariables,
} from 'core/graphql';
import { popupData, PopupNames } from 'core';

import { GroupsQuery } from 'core/operations/queries';

type publishToLmsType = [
  (variables: PublishToLmsMutationVariables) => void,
  {
    success: boolean;
    loading: boolean;
    error?: { message?: string };
  },
];

export const usePublishedInLmsData = () => {
  const publishedInLmsData = useReactiveVar(popupData)[
    PopupNames.addGroupInLMS
  ];

  return publishedInLmsData;
};

export const useRemoveFromLmsData = () => {
  const removeFromLmsData = useReactiveVar(popupData)[
    PopupNames.removeGroupFromLMS
  ];

  return removeFromLmsData;
};

export const usePublishToLms = (): publishToLmsType => {
  const [
    publishToLmsMutation,
    { data, loading, error },
  ] = usePublishToLmsMutation({
    refetchQueries: [
      {
        query: GroupsQuery,
        variables: {
          first: 10,
        },
      },
    ],
  });

  const publishToLms = useCallback(
    (variables: PublishToLmsMutationVariables) =>
      publishToLmsMutation({
        variables,
      }),
    [publishToLmsMutation],
  );

  return [
    publishToLms,
    {
      success: Boolean(data?.publishGroup?.success),
      loading,
      error,
    },
  ];
};
