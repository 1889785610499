import { gql } from '@apollo/client';

export const DebtorsQuery = gql`
  query PaginatedContributions(
    $contributionNum: Int
    $first: Int
    $query: String
    $after: String
  ) {
    allContributions(
      first: $first
      contributionNum: $contributionNum
      query: $query
      after: $after
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          date
          complete
          delay
          remainder
          amountByn
          amountForeign
          person {
            firstName
            lastName
            middleName
            phone
          }
          agreementTitle
          agreement {
            currency
          }
          group {
            id
            name
          }
        }
      }
    }
  }
`;

export const DebtorsMetaQuery = gql`
  query DebtorsMeta($contributionNum: Int, $query: String) {
    metaContributions(contributionNum: $contributionNum, query: $query) {
      totalCount
    }
  }
`;

export const GetContributionsMetaQuery = gql`
  query GetContributionsMeta {
    metaContributions {
      contributionsNums
    }
  }
`;

export const PaymentsVerificationQuery = gql`
  query PaymentsVerification($contracts: [String]!) {
    paymentsVerification(query: $contracts) {
      fullname
      agreement_id
      agreementTitle
      currency
    }
  }
`;

export const PaymentsDeferredQuery = gql`
  query PaymentsDeferred($first: Int, $after: String, $query: String) {
    allDeferredPayments(first: $first, after: $after, query: $query) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          date
          studentFullname
          agreementTitle
          currency
          sum
          userInput
          agreementCurrency
        }
      }
    }
  }
`;
