import { useCallback, useEffect, useReducer } from 'react';

import {
  UpdateCompanyInput,
  useEditCompanyMutation,
  CustomErrorType,
} from 'core/graphql';
import { companiesQuery } from 'core/operations/queries/companies';
import { ErrorsType, ServerCodes } from 'types';
import { ICompanyFormValues } from 'molecules';
import {
  createCompanyReducer,
  getCompanyMutationErrors,
} from '../companyErrors';

type UpdateCompanyType = [
  (companyId: string, companyInput: UpdateCompanyInput) => {},
  {
    success: boolean;
    loading: boolean;
    errors?: ErrorsType<ICompanyFormValues> | null;
    clearErrors: () => void;
  },
];

export const useUpdateCompany = (): UpdateCompanyType => {
  const [editCompanyMutation, { data, loading }] = useEditCompanyMutation({
    onError: (err) => err,
    refetchQueries: [
      {
        query: companiesQuery,
        variables: {},
      },
    ],
  });

  const [errors, dispatch] = useReducer(createCompanyReducer, null);

  const clearErrors = useCallback(
    () => dispatch({ type: ServerCodes.none }),
    [],
  );

  useEffect(() => {
    const serverErrors = data?.updateCompany?.customErrors;
    if (serverErrors && serverErrors.length) {
      const errorFound = getCompanyMutationErrors(
        serverErrors as CustomErrorType[],
      );

      dispatch({ type: errorFound });
    }
  }, [data?.updateCompany?.customErrors]);

  const updateCompany = useCallback(
    (companyId, companyInput: UpdateCompanyInput) =>
      editCompanyMutation({ variables: { companyId, companyInput } }),
    [editCompanyMutation],
  );

  return [
    updateCompany,
    {
      success: Boolean(data?.updateCompany?.success),
      loading,
      errors,
      clearErrors,
    },
  ];
};
