import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { yupValidation } from 'services/YupValidationService';
import { FieldErrorTypes } from 'types';

export const getApplicationSchema = (t: TFunction) =>
  Yup.object().shape({
    applicationNumber: Yup.string().required({
      type: FieldErrorTypes.EMPTY,
      fieldName: `${t('applicationPopup.fields.number.label')}`,
    }),
    date: yupValidation.date(`${t('applicationPopup.fields.date.label')}`),
    course: Yup.object().required({
      type: FieldErrorTypes.EMPTY,
      fieldName: `${t('applicationPopup.fields.course.label')}`,
    }),
    group: Yup.object()
      .nullable()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('applicationPopup.fields.group.label')}`,
      }),
    teacherType: Yup.object()
      .nullable()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('applicationPopup.fields.teacherType.label')}`,
      }),
    lessonsNumber: Yup.string()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('applicationPopup.fields.lessonsNumber.label')}`,
      })
      .test({ ...yupValidation.validateNumber(t) })
      .test(
        'lessonsNumber',
        {
          type: FieldErrorTypes.VALIDATION,
          fieldName: `${t('applicationPopup.fields.lessonsNumber.label')}`,
          message: `${t(
            'applicationPopup.validation.lessonsNumber.moreThan0',
          )}`,
        },
        (value) => Boolean(Number(value) > 0),
      )
      .test(
        'lessonsNumber',
        {
          type: FieldErrorTypes.VALIDATION,
          fieldName: `${t('applicationPopup.fields.lessonsNumber.label')}`,
          message: `${t(
            'applicationPopup.validation.lessonsNumber.moreThanAvailable',
          )}`,
        },
        (value, context) => {
          const lessonsCount = Number(context.parent.group.lessonsCount);
          return Boolean(Number(value) <= lessonsCount);
        },
      )
      .test(
        'lessonsNumber',
        {
          type: FieldErrorTypes.VALIDATION,
          fieldName: `${t('applicationPopup.fields.lessonsNumber.label')}`,
          message: `${t(
            'applicationPopup.validation.lessonsNumber.teacherHasMore',
          )}`,
        },
        (value, context) => {
          const lessonsCount = Number(
            context.parent.group.appointedLessonsCount,
          );
          return Boolean(Number(value) >= lessonsCount);
        },
      ),

    servicePrice: Yup.string()
      .nullable()
      .test({ ...yupValidation.validateNumber(t) })
      .test(
        'servicePrice',
        {
          type: FieldErrorTypes.VALIDATION,
          fieldName: `${t('applicationPopup.fields.servicePrice.label')}`,
          message: `${t('applicationPopup.validation.servicePrice.moreThan0')}`,
        },
        (value) => Boolean(Number(value) > 0),
      )
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('applicationPopup.fields.servicePrice.label')}`,
      }),
    riaPrice: Yup.string().test(
      'riaPrice',
      {
        type: FieldErrorTypes.VALIDATION,
        fieldName: `${t('applicationPopup.fields.riaPrice.label')}`,
        message: `${t('applicationPopup.validation.riaPrice.moreThan0')}`,
      },
      (value) => {
        if (value) {
          return Boolean(Number(value) > 0);
        }
        return true;
      },
    ),
    currency: Yup.object()
      .nullable()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('applicationPopup.fields.currency.label')}`,
      }),
    lastLessonDate: Yup.date().when('isTerminated', {
      is: true,
      then: yupValidation
        .date(`${t('applicationPopup.fields.lastLessonDate.label')}`)
        .min(Yup.ref('date'), {
          type: FieldErrorTypes.VALIDATION,
          message: `${t(
            'applicationPopup.validation.lastLessonDate.cannotBeBefore',
          )}`,
        }),
      otherwise: Yup.date().nullable(),
    }),
  });
