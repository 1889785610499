import React, { memo, useCallback } from 'react';
import { Row, useTable, useExpanded } from 'react-table';
import styled from 'styled-components';

import { LoadingTable, FinanceMonthTable } from 'molecules';
import {
  Icon,
  IconsNames,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'atoms';

import { Colors } from 'services/ColorService';

export type Accessors =
  | 'period'
  | 'planByn'
  | 'factByn'
  | 'planRub'
  | 'factRub'
  | 'planUsd'
  | 'factUsd'
  | 'planEur'
  | 'factEur'
  | 'planPln'
  | 'factPln';

export type IRow = {
  [key in Accessors]: React.ReactNode;
} & {
  month: number;
  loading: boolean;
};

export interface IColumn {
  accessor: Accessors;
  alignContent?: 'left' | 'right' | 'center';
  columnWidth?: number;
  Header?: string | React.ReactElement;
  flexible?: boolean;
  marginLeft?: number;
}

export interface IFinanceTableComponentProps {
  year: number;
  rows: IRow[];
  columns: IColumn[];
  noDataText: string;
  loading?: boolean;
  className?: string;
}

export const FinanceTableComponent = memo(
  ({
    year,
    rows: rowsSource,
    columns,
    noDataText,
    loading,
    className,
  }: IFinanceTableComponentProps) => {
    const {
      getTableProps,
      headers,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable(
      {
        columns,
        data: rowsSource,
      },
      useExpanded,
    );

    const onExpandClick = useCallback((row: Row<IRow>) => {
      row.toggleRowExpanded();
    }, []);

    return (
      <Wrapper className={className}>
        <Table {...getTableProps()} flexible rowShadowOnHover>
          <TableHead>
            <TableRow type="row">
              {headers.map((column: any) => {
                const width = column.columnWidth
                  ? `${column.columnWidth}px`
                  : 'auto';
                const marginLeft = column.marginLeft
                  ? `${column.marginLeft}px`
                  : 'auto';

                const {
                  key: cellKey,
                  ...restCellProps
                } = column.getHeaderProps({
                  type: 'headerCell',
                  flexible: column.flexible || false,
                  width,
                  margin: {
                    left: marginLeft,
                  },
                });

                return (
                  <TableCell key={cellKey} {...restCellProps}>
                    {column.render('Header')}
                  </TableCell>
                );
              })}
              <EmptyHeaderCell />
            </TableRow>
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {!rows.length && !loading && (
              <TableRow key="noData">
                <TableCell type="cell" width="100%" align="left">
                  {noDataText}
                </TableCell>
              </TableRow>
            )}

            {!rows.length && loading && (
              <TableRow key="loading">
                <TableCell width="100%" align="center">
                  <LoadingTable />
                </TableCell>
              </TableRow>
            )}

            {Boolean(rows.length) &&
              rows.map((row: Row<IRow>) => {
                prepareRow(row);
                const { key: rowKey, ...restRowProps } = row.getRowProps();

                return (
                  <React.Fragment key={rowKey}>
                    <TableRow key={rowKey} type="row" {...restRowProps}>
                      {row.cells.map((cell: any) => {
                        const width = cell.column.columnWidth
                          ? `${cell.column.columnWidth}px`
                          : 'auto';
                        const marginLeft = cell.column.marginLeft
                          ? `${cell.column.marginLeft}px`
                          : 'auto';

                        const {
                          key: cellKey,
                          ...restCellProps
                        } = cell.getCellProps({
                          flexible: cell.column.flexible || false,
                          width,
                          margin: {
                            left: marginLeft,
                          },
                        });

                        return (
                          <TableCell key={cellKey} {...restCellProps}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}

                      <ButtonsCell>
                        <ExpandButton onClick={() => onExpandClick(row)}>
                          <ExpandButtonIcon
                            name={
                              row.isExpanded
                                ? IconsNames.MinusRoundedIcon
                                : IconsNames.PlusRoundedIcon
                            }
                          />
                        </ExpandButton>
                      </ButtonsCell>
                    </TableRow>
                    {row.isExpanded && (
                      <MonthTableRow
                        key={`${rowKey}-${year}-${row.original.month}`}
                        type="row"
                        {...restRowProps}
                      >
                        <MonthTableCell
                          key={`monthcell-${year}-${row.original.month}`}
                        >
                          <FinanceMonthTable
                            year={year}
                            month={row.original.month}
                          />
                        </MonthTableCell>
                      </MonthTableRow>
                    )}
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  display: flex;
`;

const EmptyHeaderCell = styled.td`
  width: 32px;
  margin-left: 32px;
`;

const ButtonsCell = styled.td`
  margin-left: 32px;
`;

const ExpandButtonIcon = styled(Icon)`
  height: 100%;
  width: 100%;
`;

const ExpandButton = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 32px;
  width: 32px;
  padding: 8px;
  border-radius: 50%;
  background-color: ${Colors.MainBackground};
  cursor: pointer;

  & > ${ExpandButtonIcon} > path {
    stroke: ${Colors.Gray};
  }

  &:hover {
    background-color: ${Colors.Blue};

    & > ${ExpandButtonIcon} > path {
      stroke: ${Colors.White};
    }
  }
`;

const MonthTableRow = styled(TableRow)`
  padding: 0 !important;
`;

const MonthTableCell = styled(TableCell)`
  width: 100%;
`;
