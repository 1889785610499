import { useCallback, useEffect, useReducer } from 'react';

import { IUserData, ServerCodes, UserRole, ErrorsType } from 'types';

import { useVerifyTeacherLazyQuery, VerifyTeacherQueryVariables } from 'core';

import { ITeacherAddFieldsValues } from 'molecules';

import { teacherErrorsReducer } from './reducer';

type useCheckTeacherCredentialsHookType = [
  (variables: VerifyTeacherQueryVariables) => void,
  {
    code?: ServerCodes;
    user?: IUserData;
    teacher?: {
      id?: string;
      firstName?: string;
      middleName?: string;
      lastName?: string;
      phone?: string;
      email?: string;
    };
    errors: ErrorsType<ITeacherAddFieldsValues> | null;
    loading: boolean;
  },
];

export const useCheckTeacherCredentials = (): useCheckTeacherCredentialsHookType => {
  const [check, { data, loading }] = useVerifyTeacherLazyQuery();
  const [errors, dispatch] = useReducer(teacherErrorsReducer, null);

  const verifyCredentials = useCallback(
    (variables: VerifyTeacherQueryVariables) => {
      check({ variables });
    },
    [check],
  );

  useEffect(() => {
    if (data?.verifyTeacher?.code === ServerCodes.teacherAgreementActive) {
      dispatch({ type: ServerCodes.teacherExist });
      return;
    }

    const serverErrors = data?.verifyTeacher?.customErrors;
    if (
      serverErrors?.some(
        (error) => error?.code === 'invalid' && error.field === 'phone',
      )
    ) {
      dispatch({ type: ServerCodes.invalidPhone });
    }
  }, [data?.verifyTeacher?.code, data?.verifyTeacher?.customErrors]);

  return [
    verifyCredentials,
    {
      code: data?.verifyTeacher?.code as ServerCodes,
      user: {
        id: data?.verifyTeacher?.user?.id || '',
        firstName: data?.verifyTeacher?.user?.firstName || '',
        middleName: data?.verifyTeacher?.user?.middleName || '',
        lastName: data?.verifyTeacher?.user?.lastName || '',
        phone: data?.verifyTeacher?.user?.phone || '',
        email: data?.verifyTeacher?.user?.email || '',
        roles: (data?.verifyTeacher?.user?.roles as UserRole[]) || [],
      },
      teacher: {
        id: data?.verifyTeacher?.teacher?.id,
        firstName: data?.verifyTeacher?.teacher?.user.firstName,
        middleName: data?.verifyTeacher?.teacher?.user.middleName || '',
        lastName: data?.verifyTeacher?.teacher?.user.lastName,
        phone: data?.verifyTeacher?.teacher?.user.phone || undefined,
        email: data?.verifyTeacher?.teacher?.user.email,
      },
      errors,
      loading,
    },
  ];
};
