import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AppendTeacherApplicationMutationVariables,
  useAppendTeacherApplicationMutation,
  CustomErrorType,
} from 'core';
import { useToasts } from 'core/hooks';
import { ApplicationsQuery, TeachersMetaQuery } from 'core/operations';

import {
  ServerCodes,
  ErrorsType,
  ToastTypes,
  GlobalToastTypeNames,
} from 'types';
import { IAddApplicationFieldsValues } from 'molecules';

import {
  applicationTeacherErrorsReducer,
  getApplicationErrors,
} from './reducer';

type AppendTeacherApplicationHookType = [
  (variables: AppendTeacherApplicationMutationVariables) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<IAddApplicationFieldsValues> | null;
  },
];

export const useAppendTeacherApplication = (): AppendTeacherApplicationHookType => {
  const { t } = useTranslation();
  const [errors, dispatch] = useReducer(applicationTeacherErrorsReducer, null);
  const { addToast } = useToasts();
  const [
    appendTeacherApplicationMutation,
    { data, loading },
  ] = useAppendTeacherApplicationMutation({
    refetchQueries: () => {
      if (!data?.addTeacherApplication?.success) {
        return [];
      }

      return [
        {
          query: ApplicationsQuery,
          variables: {
            first: 10,
          },
        },
        {
          query: TeachersMetaQuery,
        },
      ];
    },
  });

  const appendTeacherApplication = useCallback(
    ({ application, teacherId }: AppendTeacherApplicationMutationVariables) => {
      appendTeacherApplicationMutation({
        variables: {
          application,
          teacherId,
        },
      });
    },
    [appendTeacherApplicationMutation],
  );

  useEffect(() => {
    const serverErrors = data?.addTeacherApplication?.customErrors;
    if (serverErrors && serverErrors.length) {
      const errorsFound = getApplicationErrors(
        serverErrors as CustomErrorType[],
      );
      switch (true) {
        case errorsFound.applicationExist:
          dispatch({ type: ServerCodes.applicationTitleExist });
          break;
        case errorsFound.lessonCountExceeds:
          dispatch({ type: ServerCodes.lessonCountExceeds });
          break;

        default:
          addToast({
            data: {
              title: `${t('common.somethingWentWrong')}`,
              type: ToastTypes.error,
            },
            type: GlobalToastTypeNames.TeacherApplicationAdd,
          });
      }
    }
  }, [data?.addTeacherApplication?.customErrors, addToast, t]);

  return [
    appendTeacherApplication,
    {
      success: data?.addTeacherApplication?.success || false,
      loading,
      errors,
    },
  ];
};
