import React, { memo } from 'react';

import styled from 'styled-components';

import { useReactiveVar } from '@apollo/client';
import { appState } from 'core';

import { Toast } from 'molecules';

export const GlobalToast = memo(() => {
  const { toasts } = useReactiveVar(appState);

  return (
    <Wrapper>
      {toasts?.map(({ id, data, type }) => (
        <Toast id={id} key={id} data={data} type={type} />
      ))}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  position: fixed;
  top: 80px;
  right: 64px;
  bottom: 0;
  min-width: 358px;
  pointer-events: none;
  overflow: hidden;
  z-index: 999;
`;
