import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SearchNames,
  usePopupStateOperations,
  usePublishedInLmsData,
  usePublishToLms,
  useSearch,
  useToasts,
} from 'core/hooks';
import { PopupNames } from 'core';
import {
  GlobalToastTypeNames,
  PublishedLmsType,
  ToastTypes,
} from 'components/types';

import { AddGroupInLMSPopupComponent } from './AddGroupInLMSPopup';

export const AddGroupInLMSPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const {
    operations: { hidePopup },
  } = usePopupStateOperations();
  const { setSearchQuery } = useSearch(SearchNames.settings);

  const publishedInLmsData = usePublishedInLmsData();

  const [
    publishToLms,
    { success, loading, error: serverErrors },
  ] = usePublishToLms();

  const onClose = useCallback(() => hidePopup(PopupNames.addGroupInLMS), [
    hidePopup,
  ]);

  const onConfirm = useCallback(() => {
    if (!loading && publishedInLmsData?.id) {
      const publishParams = {
        [PublishedLmsType.student]: !publishedInLmsData.publishedLms,
        [PublishedLmsType.teacher]: !publishedInLmsData.publishedLmsForTeacher,
        [PublishedLmsType.methodist]: !publishedInLmsData.publishedLmsForMethodist,
      };

      publishToLms({
        groupId: publishedInLmsData.id,
        publishedLms: publishedInLmsData.publishedLmsType
          ? publishParams[publishedInLmsData.publishedLmsType]
          : false,
        publishedForTeachers:
          publishedInLmsData.publishedLmsType === PublishedLmsType.teacher,
        publishedLmsForMethodist:
          publishedInLmsData.publishedLmsType === PublishedLmsType.methodist,
      });
    }
  }, [publishToLms, loading, publishedInLmsData]);

  useEffect(() => {
    if (serverErrors) {
      addToast({
        data: {
          title: 'Error',
          text: serverErrors.message,
          type: ToastTypes.error,
        },
        type: GlobalToastTypeNames.GroupFromLMSAdd,
      });
    }
  }, [serverErrors, addToast]);

  useEffect(() => {
    if (success) {
      setSearchQuery('');
      addToast({
        data: {
          title: `${t('publishGroupPopup.success')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.GroupFromLMSAdd,
      });
      onClose();
    }
  }, [onClose, success, setSearchQuery, addToast, t]);

  return (
    <AddGroupInLMSPopupComponent
      onClose={onClose}
      onConfirm={onConfirm}
      groupName={publishedInLmsData ? publishedInLmsData.group : ''}
      publishedLmsType={
        publishedInLmsData && publishedInLmsData.publishedLmsType
      }
    />
  );
});
