import { makeVar } from '@apollo/client';

export interface ISearchState {
  settingsSearchQuery: string;
  usersSearchQuery: string;
  studentsSearchQuery: string;
  teachersSearchQuery: string;
  performanceSearchQuery: string;
}

export const defaultSearchState: ISearchState = Object.freeze({
  settingsSearchQuery: '',
  usersSearchQuery: '',
  studentsSearchQuery: '',
  teachersSearchQuery: '',
  performanceSearchQuery: '',
});

export const searchState = makeVar<ISearchState>(defaultSearchState);
