import { useCallback } from 'react';

import { useAddPaymentsMutation } from 'core';
import {
  DebtorsMetaQuery,
  DebtorsQuery,
  GetContributionsMetaQuery,
  PaymentsDeferredQuery,
} from 'core/operations';
import { IVerifiedPaymentInfo } from 'types';

import { DateService } from 'services/DateService';

type AddPaymentsHookType = [
  (payments: IVerifiedPaymentInfo[]) => void,
  {
    success: boolean;
    loading: boolean;
    error: string | undefined;
  },
];

export const useAddPayments = (): AddPaymentsHookType => {
  const [
    addPaymentsMutation,
    { data, loading, error },
  ] = useAddPaymentsMutation({
    refetchQueries: [
      {
        query: DebtorsQuery,
      },
      {
        query: PaymentsDeferredQuery,
      },
      {
        query: GetContributionsMetaQuery,
      },
      {
        query: DebtorsMetaQuery,
      },
    ],
  });

  const addPayments = useCallback(
    (payments: IVerifiedPaymentInfo[]) => {
      const transformedPayments = payments.map((payment) => ({
        date: DateService.toISO(payment.date),
        sum: Number(payment.sum),
        currency: payment.sumCurrency,
        agreement_id: payment.contractId || '',
        deferredPaymentId: payment.deferredPaymentId,
      }));

      addPaymentsMutation({
        variables: {
          payments: transformedPayments,
        },
      });
    },
    [addPaymentsMutation],
  );

  return [
    addPayments,
    {
      success: data?.createPayments?.success || false,
      loading,
      error: error?.message,
    },
  ];
};
