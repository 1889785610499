import * as React from 'react';
import { memo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Form, Input, InputLabel, Button, InputPhone } from 'atoms';
import {
  IToggleCheckBoxButtons,
  ToggleCheckBoxList,
  PopupErrors,
  PopupContainer,
} from 'molecules';

import { IFieldError, ErrorsType } from 'types';
import { Colors } from 'services/ColorService';

export interface IUserPopup {
  editing: boolean;
  onDelete: () => void;
  onClose: () => void;
  onInputChange: (value: string | IToggleCheckBoxButtons, name: string) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  values: IUserPopupValues;
  disabledButton: boolean;
  errors?: ErrorsType<IUserPopupValues> | null;
  errorMessage?: string | null;
  loading: boolean;
}

export interface IUserPopupValues {
  lastname: string;
  firstname: string;
  middlename: string;
  email: string;
  phone: string;
  roles: IToggleCheckBoxButtons;
}

export type IUserPopupErrors = {
  [key in keyof IUserPopupValues]?: IFieldError;
};

export const UserPopupComponent = memo(
  ({
    values,
    editing,
    onDelete,
    onClose,
    onInputChange,
    handleSubmit,
    errors,
    errorMessage,
    disabledButton,
    loading,
  }: IUserPopup) => {
    const { t } = useTranslation();
    const handleToggleCheckBoxListChange = useCallback(
      (activeList: IToggleCheckBoxButtons) => {
        onInputChange(activeList, 'roles');
      },
      [onInputChange],
    );

    return (
      <PopupContainer
        title={
          editing ? `${t('userPopup.editUser')}` : `${t('userPopup.addUser')}`
        }
        onClose={onClose}
      >
        <Form onSubmit={handleSubmit}>
          <FormInner>
            <InputWrapper>
              <InputLabel>{t('userPopup.fields.lastName.label')}</InputLabel>
              <Input
                placeholder={t('userPopup.fields.lastName.placeholder')}
                max={20}
                type="text"
                value={values.lastname}
                name="lastname"
                onChange={({ target: { value } }) =>
                  onInputChange(value, 'lastname')
                }
                hasError={Boolean(errors?.lastname)}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>{t('userPopup.fields.firstName.label')}</InputLabel>
              <Input
                placeholder={t('userPopup.fields.firstName.placeholder')}
                max={20}
                type="text"
                value={values.firstname}
                name="firstname"
                onChange={({ target: { value } }) =>
                  onInputChange(value, 'firstname')
                }
                hasError={Boolean(errors?.firstname)}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel isOptional>
                {t('userPopup.fields.middleName.label')}
              </InputLabel>
              <Input
                placeholder={t('userPopup.fields.middleName.placeholder')}
                max={20}
                type="text"
                value={values.middlename}
                name="middlename"
                onChange={({ target: { value } }) =>
                  onInputChange(value, 'middlename')
                }
                hasError={Boolean(errors?.middlename)}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>{t('userPopup.fields.email.label')}</InputLabel>
              <Input
                placeholder={t('userPopup.fields.email.placeholder')}
                type="text"
                value={values.email}
                name="email"
                onChange={({ target: { value } }) =>
                  onInputChange(value, 'email')
                }
                hasError={Boolean(errors?.email)}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>{t('userPopup.fields.phone.label')}</InputLabel>
              <InputPhone
                placeholder={t('userPopup.fields.phone.placeholder')}
                value={values.phone}
                name="phone"
                onChange={({ target: { value } }) =>
                  onInputChange(value, 'phone')
                }
                hasError={Boolean(errors?.phone)}
              />
            </InputWrapper>
          </FormInner>
          <ToggleCheckboxWrapper>
            <InputWrapper>
              <InputLabel>{t('userPopup.fields.roles.label')}</InputLabel>
              <ToggleCheckBoxList
                values={values.roles}
                onChange={handleToggleCheckBoxListChange}
                hasError={Boolean(errors?.roles)}
              />
            </InputWrapper>
          </ToggleCheckboxWrapper>
          <BottomWrapper>
            <ErrorWrapper>
              <PopupErrors>{errorMessage}</PopupErrors>
            </ErrorWrapper>
            {editing && (
              <DeleteButtonWrapper>
                <Button
                  onClick={onDelete}
                  type="button"
                  variant="outline"
                  colors={{
                    backgroundColor: Colors.White,
                    color: Colors.Blue,
                  }}
                >
                  {t('userPopup.button.delete')}
                </Button>
              </DeleteButtonWrapper>
            )}
            <ButtonWrapper>
              <Button
                loading={loading}
                type="submit"
                colors={{
                  backgroundColor: Colors.Blue,
                  color: Colors.White,
                  disabledColor: Colors.White,
                  bgDisabledColor: Colors.Gray,
                }}
                disabled={disabledButton}
              >
                {editing
                  ? `${t('userPopup.button.save')}`
                  : `${t('userPopup.button.addUser')}`}
              </Button>
            </ButtonWrapper>
          </BottomWrapper>
        </Form>
      </PopupContainer>
    );
  },
);

const FormInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;

const InputWrapper = styled.div`
  width: calc(50% - 32px);

  &:nth-child(2n - 1) {
    margin-right: 32px;
  }

  &:nth-last-child(n - 2) {
    margin-bottom: 24px;
  }
`;

const ToggleCheckboxWrapper = styled.div`
  margin -top: 24px;

  ${InputWrapper} {
    width: 100%;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
`;

const ButtonWrapper = styled.div`
  flex-shrink: 0;
  width: 224px;
  margin-left: 16px;
`;

const ErrorWrapper = styled.div`
  flex-grow: 1;
  margin-right: 52px;
`;

const DeleteButtonWrapper = styled.div`
  flex-shrink: 0;
  width: 127px;
  margin-left: 16px;
`;
