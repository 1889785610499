import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { ValueType, OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';

import {
  DateSelect,
  Input,
  InputLabel,
  ISelectOption,
  Select,
} from 'components/atoms';
import { CURRENCY_OPTIONS } from 'components/constants';
import { ICourseOption, IFieldError, ErrorsType, Currencies } from 'types';
import { DateService } from 'services/DateService';
import { IEditAgreementDisabledFields } from 'molecules/EditAgreementPopup/EditAgreementPopup';

type InputHandler<T> = (value: T, inputName: string) => void;

interface IContractForm {
  values: IContractFormValues;
  errors?: ErrorsType<IContractFormValues> | null;
  coursesOptions: ICourseOption[];
  isGroupStarted?: boolean;
  onChangeInput: InputHandler<
    string | boolean | ValueType<OptionTypeBase, false>
  >;
  coursesLoading?: boolean;
  countriesOptions: ISelectOption<string>[];
  onChangeCurrency: (currency: ValueType<OptionTypeBase, false>) => void;
  disabledFields?: IEditAgreementDisabledFields;
}

export interface IContractFormValues {
  agreementTitle: string;
  date: Date | null;
  course?: ValueType<OptionTypeBase, false>;
  group?: ValueType<OptionTypeBase, false>;
  legalEntity?: ValueType<OptionTypeBase, false>;
  price: string;
  currency: ISelectOption<Currencies> | null;
  promocode: string;
  isTerminatedAgreement?: boolean;
  country?: ValueType<OptionTypeBase, false>;
}

export type IContractFormErrors = {
  agreementTitle?: IFieldError;
  date?: IFieldError;
  course?: IFieldError;
  group?: IFieldError;
  price?: IFieldError;
  currency?: IFieldError;
};

export const ContractForm = memo(
  ({
    values,
    errors,
    isGroupStarted = false,
    coursesOptions,
    onChangeInput,
    coursesLoading = false,
    countriesOptions,
    onChangeCurrency,
    disabledFields,
  }: IContractForm) => {
    const { t } = useTranslation();
    const coursesDisabled = useMemo(
      () =>
        !values.date ||
        values.isTerminatedAgreement ||
        isGroupStarted ||
        coursesLoading,
      [
        values.date,
        values.isTerminatedAgreement,
        isGroupStarted,
        coursesLoading,
      ],
    );

    const promocodesDisabled: boolean = useMemo(
      () => !values.group || !values.price || !values.currency,
      [values.group, values.price, values.currency],
    );

    return (
      <Container>
        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.agreementTitle)}>
            {t('agreementPopup.fields.agreementTitle.label')}
          </InputLabel>
          <Input
            placeholder={t('agreementPopup.fields.agreementTitle.placeholder')}
            type="text"
            max={30}
            value={values.agreementTitle}
            name="agreementTitle"
            onChange={({ target: { value } }) =>
              onChangeInput(value, 'agreementTitle')
            }
            hasError={Boolean(errors?.agreementTitle)}
          />
        </WrapperInput>

        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.date)}>
            {t('agreementPopup.fields.date.label')}
          </InputLabel>
          <DateSelect
            onChange={(value: Date | null) => {
              onChangeInput(value, 'date');
              if (!isGroupStarted && !values.isTerminatedAgreement) {
                onChangeInput(null, 'course');
                onChangeInput(null, 'group');
                onChangeInput(null, 'firstLesson');
                onChangeInput(null, 'lastLesson');
              }
              onChangeInput('', 'price');
              onChangeInput(null, 'currency');
              onChangeCurrency(null);
            }}
            disabledDate={(date) => DateService.isAfter(date, new Date())}
            value={values.date}
            hasError={Boolean(errors?.date)}
            disabled={isGroupStarted}
          />
        </WrapperInput>

        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.course)}>
            {t('agreementPopup.fields.course.label')}
          </InputLabel>
          <Select
            placeholder={t('agreementPopup.fields.course.placeholder')}
            isDisabled={coursesDisabled}
            options={coursesOptions}
            onChange={(value) => {
              onChangeInput(value, 'course');
              onChangeInput(null, 'group');
              onChangeInput('', 'price');
              onChangeInput(null, 'currency');
              onChangeInput(null, 'firstLesson');
              onChangeInput(null, 'lastLesson');
              onChangeCurrency(null);
            }}
            value={values.course}
            defaultValue={values.course || undefined}
            hasError={Boolean(errors?.course)}
          />
        </WrapperInput>

        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.group)}>
            {t('agreementPopup.fields.group.label')}
          </InputLabel>
          <Select
            placeholder={t('agreementPopup.fields.group.placeholder')}
            options={
              coursesOptions?.find(
                (course) => course.value === values?.course?.value,
              )?.groups || []
            }
            onChange={(value) => {
              const currencyOptionsValue: ValueType<
                OptionTypeBase,
                false
              > = value?.currency
                ? {
                    label: value.currency,
                    value: value.currency,
                  }
                : null;
              const priceExist = !Number.isNaN(Number(value?.price));
              const resultPrice = priceExist ? value?.price : '';

              onChangeInput(value, 'group');
              onChangeInput(null, 'firstLesson');
              onChangeInput(null, 'lastLesson');
              onChangeInput(resultPrice, 'price');
              onChangeInput(currencyOptionsValue, 'currency');
              onChangeCurrency(currencyOptionsValue);
            }}
            hasError={Boolean(errors?.group)}
            isDisabled={!values.course || coursesDisabled}
            value={values?.group}
            defaultValue={values?.group || undefined}
          />
        </WrapperInput>

        <WrapperInput>
          <InputLabel
            hasError={Boolean(
              errors?.price && errors.price.fieldName !== 'payments_empty',
            )}
          >
            {t('agreementPopup.fields.price.label')}
          </InputLabel>
          <InputWithSelect>
            <Input
              placeholder={t('agreementPopup.fields.price.placeholder')}
              type="text"
              value={values.price}
              name="price"
              onChange={({ target: { value } }) => {
                onChangeInput(value, 'price');
              }}
              onKeyPress={(event) =>
                !new RegExp(/[0-9.]/).test(event.key) && event.preventDefault()
              }
              hasError={Boolean(
                errors?.price && errors.price.fieldName !== 'payments_empty',
              )}
              disabled={!values.group || disabledFields?.price}
              financialNumber
            />
            <Select
              type="primary"
              width="128px"
              value={values.currency}
              options={CURRENCY_OPTIONS}
              onChange={(value) => {
                onChangeInput(value, 'currency');
                onChangeCurrency(value);
              }}
              hasError={Boolean(errors?.currency)}
              isDisabled={!values.group || disabledFields?.currency}
            />
          </InputWithSelect>
        </WrapperInput>

        <WrapperInput>
          <InputLabel>{t('agreementPopup.fields.promocode.label')}</InputLabel>
          <Input
            placeholder={t('agreementPopup.fields.promocode.placeholder')}
            type="text"
            value={values?.promocode}
            max={100}
            name="promocode"
            onChange={({ target: { value } }) =>
              onChangeInput(value, 'promocode')
            }
            disabled={promocodesDisabled || disabledFields?.promocode}
          />
        </WrapperInput>

        <WrapperInput>
          <InputLabel isOptional>
            {t('agreementPopup.fields.country.label')}
          </InputLabel>
          <Select
            placeholder={t('agreementPopup.fields.country.placeholder')}
            options={countriesOptions}
            onChange={(value) => onChangeInput(value, 'country')}
            isDisabled={!values.currency}
            value={values?.country}
          />
        </WrapperInput>
      </Container>
    );
  },
);

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  margin-top: 32px;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWithSelect = styled.div`
  display: flex;
  flex-direction: row;
`;
