import { useCallback, useEffect, useReducer } from 'react';

import {
  PaginatedUsersSettingsQueryVariables,
  UsersSettingsEdge,
  usePaginatedUsersSettingsQuery,
  PageInfo,
  UserNode,
} from 'core/graphql';

import { usersSettingsErrorsReducer } from './reducer';

type UsersSettingsHookType = {
  data: UserNode[];
  loading?: boolean;
  error?: string;
  totalCount?: number | null;
  pageInfo?: Pick<
    PageInfo,
    'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
  >;
  operations: {
    fetchMoreData: (vars: PaginatedUsersSettingsQueryVariables) => void;
  };
};

const useGetUserSettingsFromApi = () => {
  const { data, loading, error, fetchMore } = usePaginatedUsersSettingsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      query: '',
      first: 10,
    },
    notifyOnNetworkStatusChange: true,
  });

  const result = (): UserNode[] => {
    const edges: Array<UsersSettingsEdge | undefined | null> =
      data?.allUsersSettings?.edges || [];
    if (!edges) return [];
    return edges.map((edge) => edge!.node as UserNode);
  };
  return {
    fetchMore,
    result: result(),
    loading,
    error,
    pageInfo: data?.allUsersSettings?.pageInfo,
  };
};

export const useUsersSettings = (): UsersSettingsHookType => {
  const [validationError, dispatch] = useReducer(
    usersSettingsErrorsReducer,
    null,
  );
  const {
    result,
    loading,
    error,
    fetchMore = () => null,
    pageInfo,
  } = useGetUserSettingsFromApi();

  useEffect(() => {
    if (!error) {
      dispatch({ type: 'none' });
    }
  }, [result, dispatch, error]);

  const fetchMoreData = useCallback(
    ({ query, after }: PaginatedUsersSettingsQueryVariables) => {
      fetchMore({
        variables: {
          query,
          after,
        },
      });
    },
    [fetchMore],
  );

  return {
    data: result || [],
    loading,
    pageInfo,
    error: validationError || undefined,
    operations: {
      fetchMoreData,
    },
  };
};
