import { makeVar } from '@apollo/client';
import { SortMode } from 'types';

export interface ISortState {
  debtorsTable: SortMode;
}

export const defaultSortState: ISortState = Object.freeze({
  debtorsTable: SortMode.None,
});

export const sortState = makeVar(defaultSortState);
