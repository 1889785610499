import * as React from 'react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  UserRole,
  ServerCodes,
  ToastTypes,
  ErrorsType,
  GlobalToastTypeNames,
} from 'types';

import { PopupNames } from 'core';
import {
  useCreateNewUser,
  usePopupStateOperations,
  useFindUserData,
  useSearch,
  SearchNames,
  useSortErrors,
  useToasts,
} from 'core/hooks';

import { IToggleCheckBoxButtons } from 'molecules';
import { IUserPopupValues, UserPopupComponent } from './UserPopup';
import { getUserSchema } from './userSchema';

export const UserPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const {
    operations: { showPopup, hidePopup },
  } = usePopupStateOperations();

  const [
    createUser,
    { success, loading, errors: createUserErrors, code },
  ] = useCreateNewUser();

  const { setSearchQuery } = useSearch(SearchNames.users);

  const [
    findUserData,
    { userData: uniqueUserData, loading: loadingFindUserData },
  ] = useFindUserData();

  const onSubmitHandler = useCallback(
    async (values: IUserPopupValues) => {
      const userRoles = Object.keys(values.roles).filter(
        (role) => values.roles[role],
      );

      const userFields = {
        firstName: values.firstname,
        middleName: values.middlename,
        lastName: values.lastname,
        email: values.email,
        phone: values.phone,
        roles: userRoles as UserRole[],
      };

      createUser(userFields);
    },
    [createUser],
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      middlename: '',
      email: '',
      phone: '',
      roles: {
        [UserRole.ADMIN]: false,
        [UserRole.ACCOUNTANT]: false,
        [UserRole.FINANCIER]: false,
        [UserRole.HRMANAGER]: false,
        [UserRole.SALESMANAGER]: false,
        [UserRole.METHODIST]: false,
      },
    },
    validationSchema: getUserSchema(t),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
  });

  const disabledButton: boolean = useMemo(() => {
    const newValues = R.omit(['roles', 'middlename'], values);
    const firstCheck = R.values(newValues).every((currentValue) =>
      Boolean(currentValue),
    );
    const secondCheck = R.values(values.roles).some((currentValue) =>
      Boolean(currentValue),
    );
    const result = firstCheck && secondCheck;
    return result;
  }, [values]);

  const shouldAddRoles = useCallback(
    (allRoles: UserRole[], exceptionRoles: UserRole[]): boolean =>
      !allRoles.some((role) => !exceptionRoles.includes(role)),
    [],
  );

  useEffect(() => {
    if (success) {
      setSearchQuery('');
      addToast({
        data: {
          title: `${t('userPopup.userAddSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.UserAdd,
      });
      hidePopup(PopupNames.addUser);
    }
  }, [success, hidePopup, setSearchQuery, addToast, t]);

  useEffect(() => {
    if (code) {
      if (
        (code === ServerCodes.phoneExist ||
          code === ServerCodes.emailExist ||
          code === ServerCodes.userExist) &&
        uniqueUserData
      ) {
        const newRoles = R.keys(values.roles).filter(
          (role) => values.roles[role],
        );

        showPopup(PopupNames.addRolesUser, {
          unique:
            code === ServerCodes.userExist
              ? {
                  email: code === ServerCodes.userExist,
                  phone: code === ServerCodes.userExist,
                }
              : {
                  email: code === ServerCodes.emailExist,
                  phone: code === ServerCodes.phoneExist,
                },
          pk: uniqueUserData.pk,
          phone: uniqueUserData.phone || '',
          email: uniqueUserData.email || '',
          existRoles: (uniqueUserData.roles as UserRole[]) || [],
          applyRoles: newRoles,
        });
      }
    }
  }, [values, code, uniqueUserData, showPopup, findUserData, shouldAddRoles]);

  useEffect(() => {
    if (code) {
      if (!uniqueUserData && code === ServerCodes.phoneExist) {
        findUserData({ phone: values.phone });
        return;
      }
      if (!uniqueUserData && code === ServerCodes.emailExist) {
        findUserData({ email: values.email });
      }
      if (!uniqueUserData && code === ServerCodes.userExist) {
        findUserData({ email: values.email, phone: values.phone });
      }
    }
  }, [values, code, uniqueUserData, findUserData]);

  const handleChange = useCallback(
    (value: string | IToggleCheckBoxButtons, name: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const handleDeleteClick = useCallback(() => {
    showPopup(PopupNames.deleteUser);
  }, [showPopup]);

  const onCloseHandler = useCallback(() => {
    hidePopup(PopupNames.addUser);
  }, [hidePopup]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IUserPopupValues>();

  useEffect(() => {
    const clientErrors = Object.values(errors).length;
    if (clientErrors) {
      sortErrors(errors as ErrorsType<IUserPopupValues>);
      return;
    }
    if (createUserErrors) {
      sortErrors(createUserErrors);
      return;
    }

    sortErrors(null);
  }, [errors, createUserErrors, sortErrors]);

  return (
    <UserPopupComponent
      editing={false}
      onDelete={handleDeleteClick}
      onClose={onCloseHandler}
      onInputChange={handleChange}
      handleSubmit={handleSubmit}
      disabledButton={!disabledButton}
      values={values}
      errors={sortedErrors}
      errorMessage={message}
      loading={loading || loadingFindUserData}
    />
  );
});
