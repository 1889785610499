import { useGetTeacherTypesLazyQuery } from 'core';
import { useCallback, useMemo } from 'react';
import { TeacherPossibleType, transformToHireTypes } from './transform';

type GetTeacherTypesHookType = {
  getTeacherTypes: (teacherId: string) => void;
  data: TeacherPossibleType[];
  loading: boolean;
};

export const useGetTeacherTypes = (): GetTeacherTypesHookType => {
  const [query, { data, loading }] = useGetTeacherTypesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const getTeacherTypes = useCallback(
    (teacherId: string) => {
      query({
        variables: {
          teacherId,
        },
      });
    },
    [query],
  );

  const resultData = useMemo(() => transformToHireTypes(data), [data]);

  return {
    getTeacherTypes,
    data: resultData,
    loading,
  };
};
