import { CompanyOptionsType } from 'types';
import { GetAllCompaniesQuery } from 'core';

export const transformCompanies = (
  data: GetAllCompaniesQuery | undefined,
): CompanyOptionsType => {
  if (data) {
    const result = data.allCompanies?.edges?.reduce(
      (companies: CompanyOptionsType, edge) =>
        edge && edge.node
          ? [
              ...companies,
              {
                value: edge.node.id,
                label: edge.node.name,
                comment: edge.node.comment || '',
              },
            ]
          : companies,
      [],
    );

    return result || [];
  }
  return [];
};
