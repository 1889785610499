import * as React from 'react';
import { memo, useMemo, useCallback } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { IGroup, PublishedLmsType } from 'types';
import { PopupNames } from 'core';
import { usePopupStateOperations } from 'core/hooks';

import { weekDays } from 'components/constants';
import { Switcher } from 'atoms';

import { GroupsTableComponent, IColumn, IRow } from './GroupsTable';

export interface IGroupsTable {
  data: IGroup[];
  loading?: boolean;
  error?: string;
  loadMore: () => void;
  hasMore: boolean;
}

const getColumnsSource = (t: TFunction): IColumn[] => [
  {
    Header: `${t('table.groups.number')}`,
    accessor: 'group',
    columnWidth: '208px',
  },
  {
    Header: `${t('table.groups.startDate')}`,
    accessor: 'startDate',
    columnWidth: '96px',
    marginRight: '56px',
    marginLeft: '80px',
  },
  {
    Header: `${t('table.groups.numberOfLessons')}`,
    accessor: 'numberOfLessons',
    columnWidth: '80px',
  },
  {
    Header: `${t('table.groups.numberOfStudents')}`,
    accessor: 'numberOfStudents',
    columnWidth: '88px',
    marginRight: '48px',
    marginLeft: '48px',
  },
  {
    Header: `${t('table.groups.lessonDays')}`,
    accessor: 'lessonDays',
    columnWidth: '100px',
    marginRight: '48px',
  },
  {
    Header: `${t('table.groups.agreementsSum')}`,
    accessor: 'agreementsSum',
    columnWidth: '142px',
    marginRight: '48px',
  },
];

export const GroupsTable = memo(
  ({ data, loading, error, loadMore, hasMore }: IGroupsTable) => {
    const { t } = useTranslation();
    const {
      operations: { showPopup },
    } = usePopupStateOperations();

    const columns: IColumn[] = useMemo(
      () => [
        ...getColumnsSource(t),
        {
          Header: `${t('table.groups.teacher')}`,
          accessor: 'publishedLmsForTeacher',
          columnWidth: '30px',
          marginRight: '48px',
          Cell: ({ row }) => (
            <Switcher
              checked={row.original.publishedLmsForTeacher}
              onClick={(checked) =>
                checked
                  ? showPopup(PopupNames.addGroupInLMS, {
                      ...row.original,
                      publishedLmsType: PublishedLmsType.teacher,
                    })
                  : showPopup(PopupNames.removeGroupFromLMS, {
                      ...row.original,
                      publishedLmsType: PublishedLmsType.teacher,
                    })
              }
            />
          ),
        },
        {
          Header: `${t('table.groups.student')}`,
          accessor: 'publishedLms',
          columnWidth: '30px',
          marginRight: '48px',
          Cell: ({ row }) => (
            <Switcher
              checked={row.original.publishedLms}
              onClick={(checked) =>
                checked
                  ? showPopup(PopupNames.addGroupInLMS, {
                      ...row.original,
                      publishedLmsType: PublishedLmsType.student,
                    })
                  : showPopup(PopupNames.removeGroupFromLMS, {
                      ...row.original,
                      publishedLmsType: PublishedLmsType.student,
                    })
              }
            />
          ),
        },
        {
          Header: `${t('table.groups.methodist')}`,
          accessor: 'publishedLmsForMethodist',
          columnWidth: '30px',
          marginRight: '56px',
          Cell: ({ row }) => (
            <Switcher
              checked={row.original.publishedLmsForMethodist}
              onClick={(checked) =>
                checked
                  ? showPopup(PopupNames.addGroupInLMS, {
                      ...row.original,
                      publishedLmsType: PublishedLmsType.methodist,
                    })
                  : showPopup(PopupNames.removeGroupFromLMS, {
                      ...row.original,
                      publishedLmsType: PublishedLmsType.methodist,
                    })
              }
            />
          ),
        },
      ],
      [showPopup, t],
    );
    const rows: IRow[] = useMemo(
      () =>
        data.map((debtor, idx) => ({
          ...debtor,
          num: idx + 1,
          lessonDays: debtor.lessonDays.map((num) => weekDays[num]).join(', '),
        })),
      [data],
    );

    const onEditButtonClick = useCallback(
      (groupId: string) => {
        showPopup(PopupNames.editGroupPopup, groupId);
      },
      [showPopup],
    );

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <GroupsTableComponent
        rows={rows}
        columns={columns}
        isFlexible
        noDataText={t('table.groups.noData')}
        loadMore={loadMore}
        hasMore={hasMore}
        onEditButtonClick={onEditButtonClick}
        loading={loading}
      />
    );
  },
);
