import { gql } from '@apollo/client';

export const getUserByIdQuery = gql`
  query getUserById($userId: ID!) {
    user(id: $userId) {
      pk
      firstName
      middleName
      lastName
      email
      phone
      roles
    }
  }
`;

export const findUserDataQuery = gql`
  query findUserData($email: String, $phone: String) {
    users(email_Icontains: $email, phone: $phone) {
      edges {
        node {
          pk
          roles
          email
          phone
          firstName
          lastName
          middleName
          student {
            id
          }
        }
      }
    }
  }
`;
