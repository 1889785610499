import { makeVar } from '@apollo/client';
import {
  IConfirmPopup,
  IStudentPaymentPopupValues,
  ITeacherAddFieldsValues,
  ITeacherAddTypeValues,
  IAddApplicationFieldsValues,
  ITeacherAddTypeValuesFields,
} from 'molecules';

import {
  IVerifiedPaymentInfo,
  IStudentAgreement,
  UserRole,
  IGroup,
  IUserData,
  ServerCodes,
  ApplicationDeleteAvailable,
} from 'types';

export enum PopupNames {
  payment = 'isPaymentPopupOpen',
  addUser = 'addUser',
  editUser = 'editUser',
  deleteUser = 'deleteUser',
  addGroup = 'addGroup',
  deleteGroup = 'deleteGroup',
  addRolesUser = 'addRolesUser',
  addStudent = 'addStudent',
  studentExist = 'studentExist',
  addPayment = 'addPayment',
  checkPayment = 'checkPayment',
  addGroupInLMS = 'addGroupInLMS',
  removeGroupFromLMS = 'removeGroupFromLMS',
  studentPayment = 'studentPayment',
  appendStudent = 'appendStudent',
  editAgreement = 'editAgreement',
  commentPopup = 'commentPopup',
  editJournalPopup = 'editJournalPopup',
  editGroupPopup = 'editGroupPopup',
  StudentWarningPopup = 'StudentWarningPopup',
  confirmPopup = 'confirmPopup',
  teacherAddPopup = 'teacherAddPopup',
  teacherAddTypePopup = 'teacherAddTypePopup',
  addApplicationPopup = 'addApplicationPopup',
  appendApplicationPopup = 'appendApplicationPopup',
  editApplicationPopup = 'editApplicationPopup',
  applicationDeletePopup = 'applicationDeletePopup',
  teacherEditPopup = 'teacherEditPopup',
}

export interface IPopupState extends IPopupStateWithData {
  isPaymentPopupOpen: boolean;
  addUser: boolean;
  deleteUser: boolean;
  addGroup: boolean;
  addStudent: boolean;
}

export interface IPopupStateWithData {
  editUser: boolean;
  addRolesUser: boolean;
  appendStudent: boolean;
  deleteGroup: boolean;
  addPayment: boolean;
  checkPayment: boolean;
  studentExist: boolean;
  studentPayment: boolean;
  editStudent: boolean;
  editAgreement: boolean;
  addGroupInLMS: boolean;
  removeGroupFromLMS: boolean;
  commentPopup: boolean;
  editJournalPopup: boolean;
  editGroupPopup: boolean;
  StudentWarningPopup: boolean;
  confirmPopup: boolean;
  teacherAddPopup: boolean;
  teacherAddTypePopup: boolean;
  addApplicationPopup: boolean;
  appendApplicationPopup: boolean;
  editApplicationPopup: boolean;
  applicationDeletePopup: boolean;
  teacherEditPopup: boolean;
}

export interface IAddRolesUser {
  unique: {
    email: boolean;
    phone: boolean;
  };
  pk: string;
  user?: string;
  phone?: string;
  email?: string;
  existRoles: UserRole[];
  applyRoles: UserRole[];
}

export interface IStudentExist {
  unique: {
    email: string;
    phone: string;
  };
  name: string;
  studentId: string;
}

type studentInput = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
};

export interface IPopupData {
  editUser?: string;
  addRolesUser?: IAddRolesUser;
  appendStudent?: IAddRolesUser;
  deleteGroup?: { groupId: string };
  addPayment?: string;
  checkPayment?: {
    payments: IVerifiedPaymentInfo[];
  };
  studentExist?: IStudentExist;
  studentPayment?: {
    studentId?: string;
    userId?: string;
    studentInput?: studentInput;
  };
  editStudent?: IStudentAgreement;
  editAgreement?: { agreementId: string; studentId: string };
  addGroupInLMS?: IGroup;
  removeGroupFromLMS?: IGroup;
  commentPopup: { comment: string; title: string };
  editJournalPopup?: string;
  editGroupPopup?: string;
  StudentWarningPopup?: IStudentPaymentPopupValues;
  confirmPopup?: IConfirmPopup;
  teacherAddPopup?: ITeacherAddFieldsValues;
  teacherAddTypePopup?: {
    values: ITeacherAddTypeValues;
    user: IUserData;
    code: ServerCodes;
  };
  addApplicationPopup?: {
    values: IAddApplicationFieldsValues;
    user: IUserData;
    code: ServerCodes;
    types: ITeacherAddTypeValuesFields;
    agreementId: string;
  };
  appendApplicationPopup?: {
    teacherId: string;
  };
  editApplicationPopup?: {
    applicationId: string;
  };
  applicationDeletePopup?: {
    applicationId: string;
    code: ApplicationDeleteAvailable;
  };
  teacherEditPopup?: {
    teacherId: string;
  };
}

export const defaultPopupData: IPopupData = Object.freeze({
  editUser: undefined,
  addRolesUser: undefined,
  deleteGroup: undefined,
  addPayment: undefined,
  checkPayment: undefined,
  studentExist: undefined,
  addGroupInLMS: undefined,
  removeGroupFromLMS: undefined,
  commentPopup: { comment: '', title: '' },
  editJournalPopup: undefined,
  editGroupPopup: undefined,
  StudentWarningPopup: undefined,
  confirmPopup: undefined,
  teacherAddPopup: undefined,
  teacherAddTypePopup: undefined,
  addApplicationPopup: undefined,
  appendApplicationPopup: undefined,
  editApplicationPopup: undefined,
  applicationDeletePopup: undefined,
  teacherEditPopup: undefined,
});

export const defaultPopupState: IPopupState = Object.freeze({
  isPaymentPopupOpen: false,
  addUser: false,
  editUser: false,
  addRolesUser: false,
  deleteUser: false,
  addGroup: false,
  deleteGroup: false,
  addStudent: false,
  studentExist: false,
  addPayment: false,
  checkPayment: false,
  addGroupInLMS: false,
  removeGroupFromLMS: false,
  studentPayment: false,
  appendStudent: false,
  editStudent: false,
  editAgreement: false,
  commentPopup: false,
  editJournalPopup: false,
  editGroupPopup: false,
  StudentWarningPopup: false,
  confirmPopup: false,
  teacherAddPopup: false,
  teacherAddTypePopup: false,
  addApplicationPopup: false,
  appendApplicationPopup: false,
  editApplicationPopup: false,
  applicationDeletePopup: false,
  teacherEditPopup: false,
});

export const popupState = makeVar(defaultPopupState);
export const popupData = makeVar(defaultPopupData);
