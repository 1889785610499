import { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  IYupFieldError,
  YupFieldErrorTypes,
} from 'services/YupValidationService';
import { ErrorsType } from 'components/types';

type SortErrorsHookType<T> = [
  (errors: ErrorsType<T> | IYupFieldError | null) => void,
  {
    errors: ErrorsType<T> | null;
    message: string | null;
  },
];

export const useSortErrors = <T>(): SortErrorsHookType<T> => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<ErrorsType<T> | null>(null);
  const [errorsInfo, setErrorsInfo] = useState<IYupFieldError[]>([]);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (errorsInfo.length > 0) {
      const emptyErrors = errorsInfo.filter(
        (error) => error.type === YupFieldErrorTypes.EMPTY,
      );

      if (emptyErrors.length === 1) {
        const errorMessage = `${t('common.pleaseFillField', {
          fieldName: emptyErrors[0].fieldName,
        })}`;
        setMessage(errorMessage);
        return;
      }

      if (emptyErrors.length > 1) {
        const errorMessage = `${t('common.pleaseFillHighlightedField')}`;
        setMessage(errorMessage);
        return;
      }

      const paymentsFormatErrors = errorsInfo.filter(
        (error) => error.type === YupFieldErrorTypes.PAYMENTS_WRONG_FORMAT,
      );

      if (paymentsFormatErrors.length) {
        const errorMessage = `${t('common.enterNumberInSumField')}`;
        setMessage(errorMessage);
        return;
      }

      const validationErrors = errorsInfo.filter(
        (error) => error.type === YupFieldErrorTypes.VALIDATION,
      );

      const uniqValidationErrors = R.uniq(validationErrors);

      if (uniqValidationErrors.length === 1) {
        const errorMessage = uniqValidationErrors[0].message;
        setMessage(errorMessage || `${t('common.errorInField')}`);
        return;
      }

      if (uniqValidationErrors.length > 1) {
        const errorMessage = `${t('common.incorrectData')}`;
        setMessage(errorMessage);
        return;
      }

      const accountPayablesErrors = errorsInfo.filter(
        (error) => error.type === YupFieldErrorTypes.ACCOUNT_PAYABLES,
      );

      if (accountPayablesErrors.length) {
        const errorMessage = `${t('common.currenciesDontMatch')}`;
        setMessage(errorMessage);
        return;
      }

      const serverErrors = errorsInfo.filter(
        (error) => error.type === YupFieldErrorTypes.SERVER_ERROR,
      );

      if (serverErrors.length > 0) {
        const errorMessage = serverErrors[0].message;
        if (errorMessage) {
          setMessage(errorMessage);
          return;
        }
      }
    }

    setMessage(null);
  }, [errorsInfo, t]);

  const checkForErrorInfo = useCallback(
    (errorToCheck: IYupFieldError, errorType: YupFieldErrorTypes) => {
      if (errorToCheck.type && errorToCheck.type === errorType) {
        setErrorsInfo((prevState) => [
          ...prevState,
          {
            type: errorToCheck.type,
            message: errorToCheck.message,
            fieldName: errorToCheck.fieldName,
          },
        ]);
      }
    },
    [],
  );

  const sort = useCallback(
    (
      formikErrors: ErrorsType<T> | IYupFieldError,
      errorsType: YupFieldErrorTypes,
    ): ErrorsType<T> | null => {
      if ((formikErrors as IYupFieldError)?.type) {
        checkForErrorInfo(formikErrors as IYupFieldError, errorsType);
      }

      if (typeof formikErrors === 'object') {
        const sortedErrors = Object.entries(formikErrors).reduce(
          (newErrors: ErrorsType<T>, [errorKey, errorValue]) => {
            if (typeof errorValue === 'object' && !Array.isArray(errorValue)) {
              if (errorValue.type && errorValue.type === errorsType) {
                checkForErrorInfo(errorValue, errorsType);

                return {
                  ...newErrors,
                  [errorKey]: errorValue,
                };
              }
            }

            if (Array.isArray(errorValue)) {
              const errorsArray = errorValue.reduce(
                (
                  newErrorsArray: ErrorsType<T>[],
                  err: ErrorsType<T>,
                  index,
                ) => {
                  const sorted = sort(err, errorsType);

                  if (sorted) {
                    newErrorsArray[index] = sorted;
                    return newErrorsArray;
                  }

                  return newErrorsArray;
                },
                [],
              );

              if (errorsArray.length > 0) {
                return {
                  ...newErrors,
                  [errorKey]: errorsArray,
                };
              }

              return newErrors;
            }

            return newErrors;
          },
          {},
        );

        if (Object.keys(sortedErrors).length > 0) {
          return sortedErrors;
        }

        return null;
      }

      return null;
    },
    [checkForErrorInfo],
  );

  const sortHandler = useCallback(
    (formikErrors: ErrorsType<T> | IYupFieldError): ErrorsType<T> | null => {
      setErrorsInfo([]);
      const emptyErrors = sort(formikErrors, YupFieldErrorTypes.EMPTY);
      if (emptyErrors && Object.values(emptyErrors).length) {
        return emptyErrors;
      }

      const paymentsFormatErrors = sort(
        formikErrors,
        YupFieldErrorTypes.PAYMENTS_WRONG_FORMAT,
      );
      if (paymentsFormatErrors && Object.values(paymentsFormatErrors).length) {
        return paymentsFormatErrors;
      }

      const validationErrors = sort(
        formikErrors,
        YupFieldErrorTypes.VALIDATION,
      );
      if (validationErrors && Object.values(validationErrors).length) {
        return validationErrors;
      }

      const accountPayablesErrors = sort(
        formikErrors,
        YupFieldErrorTypes.ACCOUNT_PAYABLES,
      );
      if (
        accountPayablesErrors &&
        Object.values(accountPayablesErrors).length
      ) {
        return accountPayablesErrors;
      }

      const serverErrors = sort(formikErrors, YupFieldErrorTypes.SERVER_ERROR);
      if (serverErrors && Object.values(serverErrors).length) {
        return serverErrors;
      }

      return null;
    },
    [sort],
  );

  const sortErrors = useCallback(
    (errorsToSort: ErrorsType<T> | IYupFieldError | null) => {
      if (errorsToSort) {
        const sorted = sortHandler(errorsToSort);
        setErrors(sorted);
        return;
      }

      setErrors(null);
      setMessage(null);
    },
    [sortHandler, setErrors],
  );

  return [
    sortErrors,
    {
      errors,
      message,
    },
  ];
};
