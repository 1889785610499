import React, { memo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { ValueType, OptionTypeBase } from 'react-select';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import { PopupNames } from 'core';
import { TIME_OPTIONS } from 'components/constants';
import {
  Currencies,
  ToastTypes,
  ErrorsType,
  GlobalToastTypeNames,
} from 'types';
import {
  useModulesData,
  usePopupData,
  usePopupStateOperations,
  useApplicationsOptionsForGroup,
  useLoadEditingGroup,
  useUpdateGroupMutation,
  useSortErrors,
  useToasts,
} from 'core/hooks';

import { DateService } from 'services/DateService';
import {
  EditGroupPopupComponent,
  IEditGroupPopupValues,
} from './EditGroupPopup';
import { getGroupAddSchema } from '../AddGroupPopup';

export const EditGroupPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const {
    operations: { hidePopup, showPopup },
  } = usePopupStateOperations();
  const onClose = useCallback(() => hidePopup(PopupNames.editGroupPopup), [
    hidePopup,
  ]);
  const groupId = usePopupData(PopupNames.editGroupPopup);
  const { data, loading } = useLoadEditingGroup(groupId || '0');
  const [
    updateGroup,
    { success, errors: serverErrors },
  ] = useUpdateGroupMutation();
  const modules = useModulesData();

  const { applicationOptionsState } = useApplicationsOptionsForGroup(
    groupId as string,
  );

  const onSubmit = useCallback(
    (values: IEditGroupPopupValues) => {
      const daysOfClass = values.lessonsDays.map(
        ({ weekDay, startTime, endTime }) => ({
          weekDay,
          startTime: startTime?.value || '19:00',
          endTime: endTime?.value || '22:00',
        }),
      );

      const modulesToUpdate = R.values(modules).map(
        ({ id, lessons, disabledLessons }) => {
          const lessonsToUpdate = [...disabledLessons, ...lessons].map(
            ({ lessonId, teacher, lessonName, isNewLesson, additional }) => {
              const lesson_id = Number(isNewLesson ? '0' : lessonId);

              if (teacher?.value) {
                return {
                  lesson_id,
                  name: lessonName,
                  application_id: teacher.value,
                  additional,
                };
              }

              return { lesson_id, name: lessonName, additional };
            },
          );

          return { module_id: Number(id), lessons: lessonsToUpdate };
        },
      );

      updateGroup({
        groupId: Number(groupId),
        groupInput: {
          name: values.groupNumber,
          price: Number(values.price),
          currency: values.currency?.value
            ? values.currency?.value
            : Currencies.BYN,
          startDate: DateService.toISO(values.startDate),
          course_id: Number(values.course?.value),
          daysOfClass,
          modules: modulesToUpdate,
        },
      });
    },
    [modules, groupId, updateGroup],
  );

  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      course: data?.course || null,
      groupNumber: data?.name || '',
      price: data?.price || '',
      currency: data?.currency || null,
      startDate: data?.startDate || null,
      deleteAvailable: data?.deleteAvailable || false,
      lessonsDays: data?.lessonsDays || [],
    },
    validateOnChange: false,
    validationSchema: getGroupAddSchema(t),
    onSubmit,
    enableReinitialize: true,
  });

  const handleChange = useCallback(
    (
      value:
        | string
        | boolean
        | number
        | undefined
        | ValueType<OptionTypeBase, false>,
      name: string,
    ) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const handleDeleteClick = useCallback(() => {
    showPopup(PopupNames.deleteGroup, { groupId });
  }, [showPopup, groupId]);

  const onAddLessonDay = useCallback(
    () =>
      setFieldValue('lessonsDays', [
        ...values.lessonsDays,
        {
          weekDay: null,
          startTime: TIME_OPTIONS[19],
          endTime: TIME_OPTIONS[22],
          id: values.lessonsDays.length,
        },
      ]),
    [values, setFieldValue],
  );

  const onDeleteLessonDay = useCallback(() => {
    setFieldValue('lessonsDays', R.dropLast(1, values.lessonsDays));
  }, [values, setFieldValue]);

  useEffect(() => {
    if (success) {
      addToast({
        data: {
          title: `${t('groupPopup.groupUpdateSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.GroupEdit,
      });
      onClose();
    }
  }, [success, onClose, addToast, t]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IEditGroupPopupValues>();

  useEffect(() => {
    const clientErrors = Object.values(errors).length;
    if (clientErrors) {
      sortErrors(errors as ErrorsType<IEditGroupPopupValues>);
      return;
    }
    if (serverErrors) {
      sortErrors(serverErrors);
      return;
    }

    sortErrors(null);
  }, [errors, serverErrors, sortErrors]);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <EditGroupPopupComponent
      values={values}
      errors={sortedErrors}
      errorsMessage={message}
      modules={modules}
      applicationOptions={applicationOptionsState}
      onClose={onClose}
      handleSubmit={handleSubmit}
      onChangeInput={handleChange}
      onDeleteClick={handleDeleteClick}
      onAddLessonDay={onAddLessonDay}
      onDeleteLessonDay={onDeleteLessonDay}
    />
  );
});
