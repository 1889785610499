import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import {
  PopupNames,
  ApplicationInputType,
  NewTeacherInput,
  Currencies,
  EmploymentTypes,
  EmploymentInputType,
} from 'core';
import {
  useToasts,
  usePopupStateOperations,
  useCountriesOptions,
  useGetCoursesForApplication,
  useGetGroupsForApplication,
  useGetApplicationPopupData,
  useCreateUniqueTeacher,
  useSortErrors,
  useAddTeacherRole,
  useAddApplicationHelpers,
  useAddTeacherExist,
} from 'core/hooks';

import {
  ServerCodes,
  ToastTypes,
  ErrorsType,
  GlobalToastTypeNames,
  TeacherHireType,
} from 'types';

import { CURRENCY_OPTIONS_TEACHER } from 'components/constants';
import { DateService } from 'services/DateService';
import { AddApplicationPopupComponent } from './AddApplicationPopup';
import { IAddApplicationFieldsValues } from './AddApplicationFields';
import { getApplicationSchema } from './applicationSchema';

export const AddApplicationPopup = memo(() => {
  const { t } = useTranslation();

  const { addToast } = useToasts();
  const {
    values: savedValues,
    types,
    code,
    user,
  } = useGetApplicationPopupData();

  const { countries } = useCountriesOptions();
  const { courses } = useGetCoursesForApplication();
  const [getGroups, { groups }] = useGetGroupsForApplication();

  const [
    createUniqueTeacher,
    { success: createSuccess, loading: createLoading, errors: createErrors },
  ] = useCreateUniqueTeacher();

  const [
    addTeacherRole,
    { success: addRoleSuccess, loading: addRoleLoading, errors: addRoleErrors },
  ] = useAddTeacherRole();

  const [
    addTeacherExist,
    {
      success: addTeacherExistSuccess,
      loading: addTeacherExistLoading,
      errors: addTeacherExistErrors,
    },
  ] = useAddTeacherExist();

  const {
    operations: { hidePopup },
  } = usePopupStateOperations();

  const onSubmit = useCallback(
    (values: IAddApplicationFieldsValues) => {
      const application: ApplicationInputType = {
        title: values.applicationNumber,
        date: DateService.toISO(values.date),
        group: values.group?.value || '',
        lessonsCount: Number(values.lessonsNumber),
        country: values.servicePlace?.value,
        servicePrice: Number(values.servicePrice),
        riaPrice: values.riaPrice ? Number(values.riaPrice) : null,
        currency: Currencies[values.currency?.value as keyof typeof Currencies],
        comment: values.comment,
        teacherType:
          EmploymentTypes[
            values.teacherType?.value as keyof typeof EmploymentTypes
          ],
      };

      const teacher: NewTeacherInput = {
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        middleName: user?.middleName,
        email: user?.email || '',
        phone: user?.phone || '',
        siteDisplay: user?.displayOnSite,
      };

      const employmentTypes: EmploymentInputType = {
        individualEntrepreneur: types?.[TeacherHireType.Ie]
          ? {
              registrationAddress:
                types?.[TeacherHireType.Ie].registrationAddress || '',
              accountNumber: types?.[TeacherHireType.Ie].accountNumber || '',
              bankTitle: types?.[TeacherHireType.Ie].bankTitle || '',
              bic: types?.[TeacherHireType.Ie].bic || '',
              inn: types?.[TeacherHireType.Ie].inn || '',
            }
          : undefined,
        selfEmployed: types?.[TeacherHireType.Se]
          ? {
              registrationAddress:
                types?.[TeacherHireType.Se].registrationAddress || '',
              accountNumber: types?.[TeacherHireType.Se].accountNumber || '',
              bankTitle: types?.[TeacherHireType.Se].bankTitle || '',
              bic: types?.[TeacherHireType.Se].bic || '',
              nationality: types?.[TeacherHireType.Se].nationality?.id || '',
              passportNumber: types?.[TeacherHireType.Se].passportNumber || '',
              inn: types?.[TeacherHireType.Se].inn || '',
            }
          : undefined,
        naturalPerson: types?.[TeacherHireType.Np]
          ? {
              registrationAddress:
                types?.[TeacherHireType.Np].registrationAddress || '',
              accountNumber: types?.[TeacherHireType.Np].accountNumber || '',
              bankTitle: types?.[TeacherHireType.Np].bankTitle || '',
              bic: types?.[TeacherHireType.Np].bic || '',
              nationality: types?.[TeacherHireType.Np].nationality?.id || '',
              passportNumber: types?.[TeacherHireType.Np].passportNumber || '',
            }
          : undefined,
        legalEntity: types?.[TeacherHireType.Le]
          ? {
              registrationAddress:
                types?.[TeacherHireType.Le].registrationAddress || '',
              accountNumber: types?.[TeacherHireType.Le].accountNumber || '',
              bankTitle: types?.[TeacherHireType.Le].bankTitle || '',
              bic: types?.[TeacherHireType.Le].bic || '',
              tittle: types?.[TeacherHireType.Le].title || '',
              inn: types?.[TeacherHireType.Le].inn || '',
            }
          : undefined,
      };

      if (code === ServerCodes.success) {
        createUniqueTeacher({
          application,
          teacher,
          employmentTypes,
        });

        return;
      }

      if (code === ServerCodes.userExist) {
        if (user?.id) {
          addTeacherRole({
            application,
            employmentTypes,
            siteDisplay: user?.displayOnSite,
            userId: user?.id,
          });
        }
      }

      if (code === ServerCodes.teacherExist) {
        if (!user?.id) {
          return;
        }

        addTeacherExist({
          application,
          employmentTypes: {
            individualEntrepreneur: employmentTypes.individualEntrepreneur
              ? {
                  ...employmentTypes.individualEntrepreneur,
                  existId: types?.Ie.id,
                }
              : undefined,
            legalEntity: employmentTypes.legalEntity
              ? {
                  ...employmentTypes.legalEntity,
                  existId: types?.Le.id,
                }
              : undefined,
            naturalPerson: employmentTypes.naturalPerson
              ? {
                  ...employmentTypes.naturalPerson,
                  existId: types?.Np.id,
                }
              : undefined,
            selfEmployed: employmentTypes.selfEmployed
              ? {
                  ...employmentTypes.selfEmployed,
                  existId: types?.Se.id,
                }
              : undefined,
          },
          teacherId: user.id,
        });
      }
    },
    [createUniqueTeacher, addTeacherRole, addTeacherExist, code, types, user],
  );

  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormik<IAddApplicationFieldsValues>({
    initialValues: savedValues || {
      applicationNumber: '',
      date: null,
      course: null,
      group: null,
      teacherType: null,
      lessonsNumber: '',
      servicePlace: null,
      servicePrice: '',
      riaPrice: '',
      currency: null,
      comment: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getApplicationSchema(t),
    onSubmit,
    enableReinitialize: true,
  });

  const {
    disabledButton,
    teacherTypes,
    handleChange,
    onClose,
  } = useAddApplicationHelpers({ values, user, code, setFieldValue });

  useEffect(() => {
    if (values.course && values.date) {
      getGroups({
        id: values.course.value,
        groupsDate: DateService.toISO(values.date),
      });
    }
  }, [getGroups, values.course, values.date]);

  useEffect(() => {
    if (createSuccess) {
      addToast({
        data: {
          title: `${t('teacherPopup.createSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.TeacherAdd,
      });
      hidePopup(PopupNames.addApplicationPopup);
    }
  }, [createSuccess, hidePopup, addToast, t]);

  useEffect(() => {
    if (addRoleSuccess) {
      addToast({
        data: {
          title: `${t('teacherPopup.addRoleSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.TeacherRoleAdd,
      });
      hidePopup(PopupNames.addApplicationPopup);
    }
  }, [addRoleSuccess, hidePopup, addToast, t]);

  useEffect(() => {
    if (addTeacherExistSuccess) {
      addToast({
        data: {
          title: `${t('teacherPopup.addExistSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.TeacherAdd,
      });
      hidePopup(PopupNames.addApplicationPopup);
    }
  }, [addTeacherExistSuccess, hidePopup, addToast, t]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IAddApplicationFieldsValues>();

  useEffect(() => {
    sortErrors({
      ...(errors as ErrorsType<IAddApplicationFieldsValues>),
      ...createErrors,
      ...addRoleErrors,
      ...addTeacherExistErrors,
    });
  }, [errors, createErrors, addRoleErrors, addTeacherExistErrors, sortErrors]);

  return (
    <AddApplicationPopupComponent
      values={values}
      errors={sortedErrors}
      errorsMessage={message}
      onInputChange={handleChange}
      handleSubmit={handleSubmit}
      onClose={onClose}
      loading={createLoading || addRoleLoading || addTeacherExistLoading}
      disabledButton={disabledButton}
      groups={groups || []}
      courses={courses || []}
      teacherTypes={teacherTypes}
      servicePlaces={countries}
      currencies={CURRENCY_OPTIONS_TEACHER}
    />
  );
});
