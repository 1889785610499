import { useCallback, useMemo } from 'react';
import { FormikErrors } from 'formik';
import { OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';

import { PopupNames } from 'core';
import { usePopupStateOperations } from 'core/hooks';

import { ISelectOption } from 'components/atoms';
import { ITeacherAddTypeValues } from 'molecules';

import {
  TEACHER_TYPE_OPTIONS,
  TEACHER_TYPE_COUNT_FIELDS,
} from 'components/constants';

import { TeacherHireType, IUserData, ServerCodes } from 'components/types';

type TeacherTypesHelpersType = {
  disabledButton: boolean;
  teacherTypesOptions: ISelectOption<string>[];
  onAddType: () => void;
  onDeleteType: (index: number) => void;
  handleChange: (
    name: string,
    value?: string | boolean | ISelectOption<string> | null | OptionTypeBase,
  ) => void;
  onClose: () => void;
};

interface ITeacherTypesArguments {
  values: ITeacherAddTypeValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<ITeacherAddTypeValues>>;
  user?: IUserData;
  code?: ServerCodes;
}

export const useTeacherTypesHelpers = ({
  values,
  user,
  code,
  setFieldValue,
}: ITeacherTypesArguments): TeacherTypesHelpersType => {
  const { t } = useTranslation();
  const {
    operations: { showPopup, hidePopup },
  } = usePopupStateOperations();

  const disabledButtonHelper = useCallback((currentValue) => {
    switch (currentValue.type.value) {
      case TeacherHireType.Ie:
        return (
          Object.values(currentValue[TeacherHireType.Ie]).length >=
            TEACHER_TYPE_COUNT_FIELDS[TeacherHireType.Ie] &&
          Object.values(currentValue[TeacherHireType.Ie]).every((cur) =>
            Boolean(cur),
          )
        );
      case TeacherHireType.Se:
        return (
          Object.values(currentValue[TeacherHireType.Se]).length >=
            TEACHER_TYPE_COUNT_FIELDS[TeacherHireType.Se] &&
          Object.values(currentValue[TeacherHireType.Se]).every((cur) =>
            Boolean(cur),
          )
        );
      case TeacherHireType.Np:
        return (
          // TODO: validate values in a better way
          Object.values(currentValue[TeacherHireType.Np]).length >=
            TEACHER_TYPE_COUNT_FIELDS[TeacherHireType.Np] &&
          Object.values(currentValue[TeacherHireType.Np]).every((cur) =>
            Boolean(cur),
          )
        );
      case TeacherHireType.Le:
        return (
          Object.values(currentValue[TeacherHireType.Le]).length >=
            TEACHER_TYPE_COUNT_FIELDS[TeacherHireType.Le] &&
          Object.values(currentValue[TeacherHireType.Le]).every((cur) =>
            Boolean(cur),
          )
        );
      default:
        return false;
    }
  }, []);

  const disabledButton: boolean = useMemo(
    () =>
      values.teacherTypes.every((currentValue) =>
        currentValue.type ? disabledButtonHelper(currentValue) : false,
      ),
    [values.teacherTypes, disabledButtonHelper],
  );

  const teacherTypesOptions = useMemo(
    () =>
      TEACHER_TYPE_OPTIONS.reduce((acc: ISelectOption<string>[], cur) => {
        const isDisabled = Boolean(
          values.teacherTypes.find((e) => e.type?.value === cur.value),
        );
        return [...acc, { ...cur, disabled: isDisabled }];
      }, []),
    [values.teacherTypes],
  );

  const onAddType = useCallback(
    () =>
      setFieldValue('teacherTypes', [
        ...values.teacherTypes,
        {
          [TeacherHireType.Le]: {},
          [TeacherHireType.Ie]: {},
          [TeacherHireType.Np]: {},
          [TeacherHireType.Se]: {},
          type: null,
          id: values.teacherTypes.length,
        },
      ]),
    [values, setFieldValue],
  );

  const onDeleteType = useCallback(
    (id) => {
      const newValues = values.teacherTypes.filter(
        (type, index) => index !== id,
      );
      setFieldValue('teacherTypes', newValues);
    },
    [values.teacherTypes, setFieldValue],
  );

  const handleChange = useCallback(
    (
      name: string,
      value?:
        | string
        | Date
        | boolean
        | ISelectOption<string>[]
        | null
        | OptionTypeBase,
    ) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const onClose = useCallback(() => {
    showPopup(PopupNames.confirmPopup, {
      title: `${t('common.teacherWontBeSaved')}`,
      children: `${t('common.youAreGoingToCloseTeacher')}`,
      confirmText: `${t('common.confirmClose')}`,
      onCancel: () =>
        showPopup(PopupNames.teacherAddTypePopup, { values, user, code }),
      onConfirm: () => hidePopup(PopupNames.confirmPopup),
    });
  }, [values, user, code, showPopup, hidePopup, t]);

  return {
    disabledButton: !disabledButton,
    teacherTypesOptions,
    onAddType,
    onDeleteType,
    handleChange,
    onClose,
  };
};
