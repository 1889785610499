import React, { memo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import {
  SearchNames,
  useAccountsPayables,
  useCompanies,
  useCoursesForOptions,
  useCreateAgreement,
  useLessonsOptions,
  usePopupData,
  usePopupStateOperations,
  useSearch,
  useSortErrors,
  useStudentPaymentHelpers,
  useToasts,
} from 'core/hooks';
import { ToastTypes, ErrorsType, GlobalToastTypeNames } from 'types';
import { PopupNames } from 'core';
import { DateService } from 'services/DateService';
import {
  StudentPaymentPopupComponent,
  IStudentPaymentPopupValues,
} from './StudentPaymentPopup';
import { getStudentPaymentSchema } from './studentPaymentSchema';

export const StudentPaymentPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [
    addAgreementMutation,
    { success, loading, showWarning = false, errors: serverErrors },
  ] = useCreateAgreement();

  const { setSearchQuery } = useSearch(SearchNames.students);
  const { companies } = useCompanies();

  const {
    getCourses,
    data: coursesOptions,
    loading: coursesLoading,
  } = useCoursesForOptions();

  const { accountsPayables } = useAccountsPayables();
  const popupData = usePopupData(PopupNames.StudentWarningPopup);

  const {
    operations: { showPopup, hidePopup },
  } = usePopupStateOperations();

  const [loadLessonsoptions, { lessonsOptions }] = useLessonsOptions();

  const onSubmitHandler = useCallback(
    (values: IStudentPaymentPopupValues) => {
      if (loading) {
        return;
      }

      const { isPaymentByLegal } = values;
      const payments = values.payments
        .filter(
          (payment: { date?: Date | null; sum?: string }) =>
            payment.sum && payment.date,
        )
        .map((payment: { date: Date | null; sum: string }) => ({
          date: DateService.toISO(payment.date),
          sum: +payment.sum,
        }));

      const usedPayables = values.accountsPayables.reduce(
        (payables: string[], accountPayable) =>
          accountPayable.label ? [...payables, accountPayable.value] : payables,
        [],
      );

      addAgreementMutation({
        agreementTitle: values.agreementTitle,
        date: values?.date
          ? DateService.toISO(values.date)
          : DateService.toISO(new Date()),
        currency: values?.currency?.value || '',
        groupId: values?.group?.value,
        paymentByCompany: isPaymentByLegal,
        vat: values.isVAT,
        resident: !values.isNotResident,
        promocode: values.promocode,
        companyId: values?.company?.value,
        countryId: values?.country?.value || null,
        contributions: payments,
        partialAttendance: values?.partialAttendance,
        firstLesson: values?.firstLesson?.value,
        lastLesson: values?.lastLesson?.value,
        usedPayables,
        startAgreementBudget: Number(values.price),
      });
    },
    [addAgreementMutation, loading],
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      agreementTitle: popupData?.agreementTitle || '',
      price: popupData?.price || '',
      isPaymentByLegal: popupData?.isPaymentByLegal || false,
      isVAT: popupData?.isVAT || false,
      currency: popupData?.currency || null,
      course: popupData?.course || null,
      group: popupData?.group || null,
      promocode: popupData?.promocode || '',
      company: popupData?.company || null,
      country: popupData?.country || null,
      legalEntityName: popupData?.legalEntityName || '',
      responsiblePersonFullName: popupData?.responsiblePersonFullName || '',
      responsiblePersonPhone: popupData?.responsiblePersonPhone || '',
      responsiblePersonEmail: popupData?.responsiblePersonEmail || '',
      UNP: popupData?.UNP || '',
      isNotResident: popupData?.isNotResident || false,
      date: popupData?.date || null,
      partialAttendance: popupData?.partialAttendance || false,
      firstLesson: popupData?.firstLesson || null,
      lastLesson: popupData?.lastLesson || null,
      accountsPayables: [],
      payments:
        popupData?.payments ||
        Array(5)
          .fill({
            date: null,
            sum: '',
          })
          .map((item) => ({ ...item, id: nanoid() })),
    },
    onSubmit: onSubmitHandler,
    validateOnChange: false,
    validationSchema: getStudentPaymentSchema(t),
  });

  const onClose = useCallback(() => {
    if (showWarning) {
      showPopup(PopupNames.StudentWarningPopup, values);
    }

    if (!showWarning) {
      hidePopup(PopupNames.studentPayment);
    }
  }, [hidePopup, showPopup, showWarning, values]);

  const {
    countriesOptions,
    onChangePayment,
    onAddPayment,
    onClosePayment,
    onChangeAccountPayable,
    onAddAccountPayable,
    onCloseAccountPayment,
    onChangeInput,
    onChangeCurrency,
  } = useStudentPaymentHelpers({ values, setFieldValue });

  useEffect(() => {
    if (values.date) {
      getCourses(DateService.toISO(values.date));
    }
  }, [values.date, getCourses]);

  useEffect(() => {
    if (values.group?.value) {
      loadLessonsoptions(values.group.value);
    }
  }, [loadLessonsoptions, values.group?.value]);

  useEffect(() => {
    if (success) {
      setSearchQuery('');
      addToast({
        data: {
          title: `${t('agreementPopup.agreementAddSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.StudentAgreementAdd,
      });
      hidePopup(PopupNames.StudentWarningPopup);
      hidePopup(PopupNames.studentPayment);
    }
  }, [success, setSearchQuery, onClose, hidePopup, addToast, t]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IStudentPaymentPopupValues>();

  useEffect(() => {
    if (serverErrors) {
      sortErrors(serverErrors);
    }
  }, [serverErrors, sortErrors]);

  useEffect(() => {
    sortErrors(errors as ErrorsType<IStudentPaymentPopupValues>);
  }, [errors, sortErrors]);

  return (
    <StudentPaymentPopupComponent
      onClose={onClose}
      onAddPayment={onAddPayment}
      onClosePayment={onClosePayment}
      onChangeInput={onChangeInput}
      onChangePayment={onChangePayment}
      handleSubmit={handleSubmit}
      onAddAccountPayable={onAddAccountPayable}
      onChangeAccountPayable={onChangeAccountPayable}
      onCloseAccountPayment={onCloseAccountPayment}
      onChangeCurrency={onChangeCurrency}
      values={values}
      accountsPayables={accountsPayables}
      coursesLoading={coursesLoading}
      coursesOptions={coursesOptions}
      lessonsOptions={lessonsOptions}
      companiesOptions={companies}
      countriesOptions={countriesOptions}
      errors={sortedErrors}
      errorMessage={message}
    />
  );
});
