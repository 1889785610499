import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ValueType, OptionTypeBase } from 'react-select';

import { DateService } from 'services/DateService';

import { ErrorsType, IGroupsOptions } from 'types';

import {
  Input,
  InputLabel,
  ISelectOption,
  DateSelect,
  Select,
  TextArea,
} from 'atoms';

export interface IAddApplicationFieldsValues {
  applicationNumber: string;
  date: Date | null;
  course: ISelectOption<string> | null;
  group: IGroupsOptions | null;
  teacherType: ISelectOption<string> | null;
  lessonsNumber: string;
  servicePlace?: ISelectOption<string> | null;
  servicePrice: string;
  riaPrice?: string;
  currency: ISelectOption<string> | null;
  comment: string;
}

export interface IAddApplicationFields {
  values: IAddApplicationFieldsValues;
  courses: ISelectOption<string>[] | null;
  groups: IGroupsOptions[] | null;
  teacherTypes: ISelectOption<string>[] | null;
  servicePlaces: ISelectOption<string>[] | null;
  currencies: ISelectOption<string>[] | null;
  disableFields?: boolean;
  isGroupStarted?: boolean;
  errors?: ErrorsType<IAddApplicationFieldsValues> | null;
  onInputChange: (
    name: string,
    value?:
      | string
      | boolean
      | ISelectOption<string>
      | Date
      | null
      | ValueType<OptionTypeBase, false>,
  ) => void;
}

export const AddApplicationFields = memo(
  ({
    values,
    disableFields,
    courses,
    groups,
    servicePlaces,
    currencies,
    teacherTypes,
    errors,
    isGroupStarted,
    onInputChange,
  }: IAddApplicationFields) => {
    const { t } = useTranslation();
    return (
      <>
        <FieldsWrapper>
          <div>
            <InputLabel>{t('applicationPopup.fields.number.label')}</InputLabel>
            <Input
              placeholder={t('applicationPopup.fields.number.placeholder')}
              max={30}
              type="text"
              value={values?.applicationNumber || ''}
              name="applicationNumber"
              onChange={({ target: { value } }) =>
                onInputChange('applicationNumber', value)
              }
              hasError={Boolean(errors?.applicationNumber)}
              disabled={disableFields}
            />
          </div>
          <div>
            <InputLabel>{t('applicationPopup.fields.date.label')}</InputLabel>
            <DateSelect
              value={values?.date || null}
              placeholder={t('applicationPopup.fields.date.placeholder')}
              onChange={(value) => {
                onInputChange('date', value);
                onInputChange('course', null);
                onInputChange('group', null);
              }}
              disabledDate={(date) => DateService.isAfter(date, new Date())}
              hasError={Boolean(errors?.date)}
              disabled={disableFields || isGroupStarted}
            />
          </div>
          <div>
            <InputLabel>{t('applicationPopup.fields.course.label')}</InputLabel>
            <Select
              value={values?.course || null}
              options={courses || []}
              type="default"
              placeholder={t('applicationPopup.fields.number.placeholder')}
              onChange={(value) => {
                onInputChange('course', value);
                onInputChange('group', null);
              }}
              hasError={Boolean(errors?.course)}
              isDisabled={
                Boolean(!values?.date) || disableFields || isGroupStarted
              }
            />
          </div>
          <div>
            <InputLabel>{t('applicationPopup.fields.group.label')}</InputLabel>
            <Select
              value={values?.group || null}
              options={groups || []}
              type="default"
              placeholder={t('applicationPopup.fields.group.placeholder')}
              onChange={(value) => onInputChange('group', value)}
              hasError={Boolean(errors?.group)}
              isDisabled={
                Boolean(!values?.course) || disableFields || isGroupStarted
              }
            />
          </div>
          <div>
            <InputLabel>
              {t('applicationPopup.fields.teacherType.label')}
            </InputLabel>
            <Select
              value={values?.teacherType || null}
              options={teacherTypes || []}
              type="default"
              placeholder={t('applicationPopup.fields.teacherType.placeholder')}
              onChange={(value) => onInputChange('teacherType', value)}
              hasError={Boolean(errors?.teacherType)}
              isDisabled={disableFields}
            />
          </div>
          <div>
            <InputLabel>
              {t('applicationPopup.fields.lessonsNumber.label')}
            </InputLabel>
            <Input
              placeholder={t(
                'applicationPopup.fields.lessonsNumber.placeholder',
              )}
              max={30}
              type="text"
              value={values?.lessonsNumber || ''}
              name="lessonsNumber"
              onChange={({ target: { value } }) =>
                onInputChange('lessonsNumber', value)
              }
              hasError={Boolean(errors?.lessonsNumber)}
              disabled={disableFields}
            />
          </div>
          <div>
            <InputLabel isOptional>
              {t('applicationPopup.fields.servicePlace.label')}
            </InputLabel>
            <Select
              value={values?.servicePlace || null}
              options={servicePlaces || []}
              type="default"
              placeholder={t(
                'applicationPopup.fields.servicePlace.placeholder',
              )}
              onChange={(value) => onInputChange('servicePlace', value)}
              hasError={Boolean(errors?.servicePlace)}
              isDisabled={disableFields}
            />
          </div>
          <div>
            <InputLabel>
              {t('applicationPopup.fields.servicePrice.label')}
            </InputLabel>
            <Input
              placeholder={t(
                'applicationPopup.fields.servicePrice.placeholder',
              )}
              max={30}
              type="text"
              value={values?.servicePrice || ''}
              name="servicePrice"
              onChange={({ target: { value } }) =>
                onInputChange('servicePrice', value)
              }
              hasError={Boolean(errors?.servicePrice)}
              disabled={disableFields}
              financialNumber
            />
          </div>
          <div>
            <InputLabel isOptional>
              {t('applicationPopup.fields.riaPrice.label')}
            </InputLabel>
            <Input
              placeholder={t('applicationPopup.fields.riaPrice.placeholder')}
              value={values?.riaPrice || ''}
              name="riaPrice"
              type="text"
              onChange={({ target: { value } }) =>
                onInputChange('riaPrice', value)
              }
              hasError={Boolean(errors?.riaPrice)}
              disabled={disableFields}
              financialNumber
            />
          </div>
          <div>
            <InputLabel>
              {t('applicationPopup.fields.currency.label')}
            </InputLabel>
            <Select
              value={values?.currency || null}
              options={currencies || []}
              type="default"
              placeholder={t('applicationPopup.fields.currency.placeholder')}
              onChange={(value) => onInputChange('currency', value)}
              hasError={Boolean(errors?.currency)}
              isDisabled={disableFields}
            />
          </div>
          <TextAreaWrapper>
            <InputLabel isOptional>
              {t('applicationPopup.fields.comment.label')}
            </InputLabel>
            <TextArea
              placeholder={t('applicationPopup.fields.comment.placeholder')}
              rows={2}
              value={values?.comment || ''}
              name="comment"
              onChange={({ target: { value } }) =>
                onInputChange('comment', value)
              }
              hasError={Boolean(errors?.comment)}
              disabled={disableFields}
            />
          </TextAreaWrapper>
        </FieldsWrapper>
      </>
    );
  },
);

const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 24px;
  width: 100%;
`;

const TextAreaWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;
