import * as Yup from 'yup';
import { TFunction } from 'i18next';

import { FieldErrorTypes } from 'types';
import { yupValidation } from 'services/YupValidationService';

export const getUserSchema = (t: TFunction) =>
  Yup.object().shape({
    firstname: yupValidation.firstName(
      `${t('userPopup.fields.firstName.label')}`,
      t,
    ),
    lastname: yupValidation.lastName(
      `${t('userPopup.fields.lastName.label')}`,
      t,
    ),
    middlename: yupValidation.middleName(
      `${t('userPopup.fields.middleName.label')}`,
      t,
    ),
    email: yupValidation.email(`${t('userPopup.fields.email.label')}`, t),
    phone: yupValidation.phone(`${t('userPopup.fields.phone.label')}`, t),
    roles: Yup.object().test(
      'roles',
      {
        type: FieldErrorTypes.EMPTY,
        message: `${t('userPopup.fields.roles.message')}`,
        fieldName: `${t('userPopup.fields.roles.labelSecond')}`,
      },
      (roles) => Object.values(roles).some((role) => role),
    ),
  });
