import { gql } from '@apollo/client';

export const GET_APP_STATE = gql`
  query GetAppState {
    appState @client {
      bootstrapped
    }
  }
`;

export const GET_AUTH_STATE = gql`
  query GetAuthState {
    authState @client {
      isLoggedIn
      access_token
    }
  }
`;
