/* eslint-disable @typescript-eslint/no-unused-vars */
import { gql } from '@apollo/client';
import { USER_FIELDS } from '../fragments';

export const MeQuery = gql`
  ${USER_FIELDS}
  query me {
    me {
      ...UserFields
    }
  }
`;
