import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import {
  useCompanies,
  usePopupStateOperations,
  useEditStudentWithAgreement,
  useSearch,
  SearchNames,
  useCoursesForOptions,
  useGetAgreementData,
  usePopupData,
  useSortErrors,
  useLessonsOptions,
  useAccountsPayables,
  useToasts,
  useStudentPaymentHelpers,
} from 'core/hooks';
import { ToastTypes, ErrorsType, GlobalToastTypeNames } from 'types';
import { PopupNames, UpdateStudentInput } from 'core';
import { getStudentDataSchema } from 'services/YupValidationService';
import { DateService } from 'services/DateService';
import { getStudentPaymentSchema } from '../StudentPaymentPopup/studentPaymentSchema';
import {
  EditAgreementPopupComponent,
  IEditAgreementPopupValues,
} from './EditAgreementPopup';

export const EditAgreementPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const popupData = usePopupData(PopupNames.editAgreement);
  const { companies } = useCompanies();
  const { accountsPayables } = useAccountsPayables(true);
  const { setSearchQuery } = useSearch(SearchNames.students);
  const [
    editStudentWithAgreement,
    { success, loading: isSubmitting, errors: serverErrors },
  ] = useEditStudentWithAgreement();

  const {
    getCourses,
    data: coursesOptions,
    loading: coursesLoading,
  } = useCoursesForOptions();

  const { agreementData, loading } = useGetAgreementData(
    popupData?.agreementId || '0',
  );

  const [loadLessonsoptions, { lessonsOptions }] = useLessonsOptions();

  const {
    operations: { hidePopup },
  } = usePopupStateOperations();
  const onClose = useCallback(() => hidePopup(PopupNames.editAgreement), [
    hidePopup,
  ]);

  const initialValues: IEditAgreementPopupValues = useMemo(
    () => ({
      firstName: agreementData?.firstName || '',
      lastName: agreementData?.lastName || '',
      middleName: agreementData?.middleName || '',
      email: agreementData?.email || '',
      phone: agreementData?.phone || '',
      agreementTitle: agreementData?.agreementTitle || '',
      date: agreementData?.date || null,
      price: agreementData?.price || '',
      isPaymentByLegal: agreementData?.isPaymentByLegal || false,
      isVAT: agreementData?.isVAT || false,
      currency: agreementData?.currency || null,
      partialAttendance: agreementData?.partialAttendance || false,
      firstLesson: agreementData?.partialAttendance
        ? agreementData?.firstLesson
        : null,
      lastLesson: agreementData?.partialAttendance
        ? agreementData?.lastLesson
        : null,
      group: agreementData?.group || null,
      course: agreementData?.course || null,
      promocode: agreementData?.promocode || '',
      refundAmount: agreementData?.refundAmount
        ? agreementData.refundAmount.toString()
        : '',
      isTerminatedAgreement: agreementData?.isTerminatedAgreement || false,
      comment: agreementData?.comment || '',
      company: agreementData?.company || null,
      country: agreementData?.country || null,
      isNotResident: !agreementData?.isResident || false,
      refundDate: agreementData?.refundDate ? agreementData.refundDate : null,
      accountsPayable: agreementData?.accountsPayable || '',
      accountsPayables: agreementData?.usedPayables || [],
      payments:
        agreementData?.payments ||
        Array(5)
          .fill({
            date: null,
            sum: '',
          })
          .map((item) => ({ ...item, id: nanoid() })),
    }),
    [agreementData],
  );

  const onSubmitHandler = useCallback(
    (values: IEditAgreementPopupValues) => {
      if (isSubmitting || !popupData?.agreementId) {
        return;
      }
      const {
        firstName,
        lastName,
        middleName,
        email,
        phone,
        agreementTitle,
        currency,
        group,
        isPaymentByLegal,
        isVAT,
        isNotResident,
        isTerminatedAgreement,
        date,
        company,
        country,
        refundDate,
        refundAmount,
        comment,
        partialAttendance,
        firstLesson,
        lastLesson,
        accountsPayable,
        promocode,
      } = values;
      const contributions = values.payments
        .filter((payment) => payment.sum && payment.date)
        .map((payment) => ({
          date: DateService.toISO(payment.date),
          sum: +payment.sum,
        }));

      const usedPayables = values.accountsPayables.reduce(
        (payables: string[], accountPayable) =>
          accountPayable.label && !accountPayable.repaid
            ? [...payables, accountPayable.value]
            : payables,
        [],
      );

      const updateStudentInput: UpdateStudentInput = {
        student: {
          firstName,
          lastName,
          middleName,
          email,
          phone,
        },
        agreement: {
          agreementTitle,
          currency: currency?.value || '',
          group_id: group?.value || '',
          paymentByCompany: isPaymentByLegal,
          vat: isVAT,
          resident: !isNotResident,
          terminated: isTerminatedAgreement,
          date: DateService.toISO(date),
          comment,
          partialAttendance,
          terminatedDate: isTerminatedAgreement
            ? DateService.toISO(refundDate)
            : null,
          startAgreementBudget: Number(values.price),
          promocode,
          countryId: country?.value || null,
        },
        usedPayables,
        company: company?.value ? { companyId: company.value } : null,
        contributions,
      };

      if (accountsPayable) {
        updateStudentInput.accountsPayable = {
          date: DateService.toISO(refundDate),
          accountsPayable: +accountsPayable,
        };
      }

      if (isTerminatedAgreement && refundAmount) {
        updateStudentInput.chargeback = {
          date: DateService.toISO(refundDate),
          sum: +refundAmount,
          currency: currency?.value || '',
        };
      }

      if (partialAttendance) {
        updateStudentInput.studentLessons = {
          startLessonId: firstLesson?.value || '',
          lastLessonId: lastLesson?.value || '',
        };
      }

      editStudentWithAgreement({
        agreementId: popupData.agreementId,
        updateStudentInput,
      });
    },
    [isSubmitting, popupData?.agreementId, editStudentWithAgreement],
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: onSubmitHandler,
    validateOnChange: false,
    validationSchema: getStudentPaymentSchema(t).concat(
      getStudentDataSchema(t),
    ),
  });

  const {
    countriesOptions,
    onChangePayment,
    onAddPayment,
    onClosePayment,
    onChangeAccountPayable,
    onAddAccountPayable,
    onCloseAccountPayment,
    onChangeInput,
    onChangeCurrency,
  } = useStudentPaymentHelpers({ values, setFieldValue });

  useEffect(() => {
    if (
      !agreementData?.isTerminatedAgreement &&
      !agreementData?.isGroupStarted &&
      values.date
    ) {
      getCourses(DateService.toISO(values.date));
    }
  }, [
    agreementData?.isTerminatedAgreement,
    agreementData?.isGroupStarted,
    values.date,
    getCourses,
  ]);

  useEffect(() => {
    if (values.group?.value) {
      loadLessonsoptions(values.group.value);
    }
  }, [loadLessonsoptions, values.group?.value]);

  useEffect(() => {
    if (success) {
      setSearchQuery('');
      addToast({
        data: {
          title: `${t('agreementPopup.agreementUpdateSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.StudentAgreementEdit,
      });
      onClose();
    }
  }, [success, setSearchQuery, onClose, addToast, t]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IEditAgreementPopupValues>();

  useEffect(() => {
    if (serverErrors) {
      sortErrors(serverErrors);
    }
  }, [serverErrors, sortErrors]);

  useEffect(() => {
    sortErrors(errors as ErrorsType<IEditAgreementPopupValues>);
  }, [errors, sortErrors]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <EditAgreementPopupComponent
      onClose={onClose}
      onAddPayment={onAddPayment}
      onClosePayment={onClosePayment}
      onChangeInput={onChangeInput}
      onChangePayment={onChangePayment}
      handleSubmit={handleSubmit}
      onChangeAccountPayable={onChangeAccountPayable}
      onAddAccountPayable={onAddAccountPayable}
      onCloseAccountPayment={onCloseAccountPayment}
      onChangeCurrency={onChangeCurrency}
      values={values}
      accountsPayables={
        agreementData?.isTerminatedAgreement ? [] : accountsPayables
      }
      isGroupStarted={agreementData?.isGroupStarted || false}
      lessonsOptions={lessonsOptions}
      coursesLoading={coursesLoading}
      coursesOptions={coursesOptions}
      companiesOptions={companies}
      countriesOptions={countriesOptions}
      errors={sortedErrors}
      errorMessage={message}
      repaid={agreementData?.repaid || false}
    />
  );
});
