import { useMemo } from 'react';

import { useLoadEditingApplicationQuery } from 'core/graphql';
import { IEditingApplicationData, transformApplication } from './transform';

type EditingApplicationHookType = {
  data: IEditingApplicationData | null;
  loading?: boolean;
  error?: string;
};

export const useLoadEditingApplication = (
  applicationId: string,
): EditingApplicationHookType => {
  const { data, loading, error } = useLoadEditingApplicationQuery({
    variables: { applicationId, first: 1, orderBy: 'date' },
  });

  const applicationData = useMemo(() => transformApplication(data), [data]);

  return {
    data: applicationData,
    loading,
    error: error?.message,
  };
};
