import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import {
  PopupNames,
  ApplicationInputType,
  Currencies,
  EmploymentTypes,
} from 'core';
import {
  useToasts,
  usePopupStateOperations,
  useCountriesOptions,
  useGetCoursesForApplication,
  useGetGroupsForApplication,
  useAppendTeacherApplication,
  useSortErrors,
  useAppendApplicationHelpers,
  useGetTeacherTypeNames,
} from 'core/hooks';

import { ToastTypes, ErrorsType, GlobalToastTypeNames } from 'types';

import { CURRENCY_OPTIONS_TEACHER } from 'components/constants';
import { DateService } from 'services/DateService';
import { AddApplicationPopupComponent } from './AddApplicationPopup';
import { IAddApplicationFieldsValues } from './AddApplicationFields';
import { getApplicationSchema } from './applicationSchema';

export const AppendApplicationPopup = memo(() => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const { teacherId, loading } = useGetTeacherTypeNames();

  const { countries } = useCountriesOptions();
  const { courses } = useGetCoursesForApplication();
  const [getGroups, { groups }] = useGetGroupsForApplication();

  const [
    appendTeacherApplication,
    { success: appendSuccess, loading: appendLoading, errors: appendErrors },
  ] = useAppendTeacherApplication();

  const {
    operations: { hidePopup },
  } = usePopupStateOperations();

  const onSubmit = useCallback(
    (values: IAddApplicationFieldsValues) => {
      const application: ApplicationInputType = {
        title: values.applicationNumber,
        date: DateService.toISO(values.date),
        group: values.group?.value || '',
        lessonsCount: Number(values.lessonsNumber),
        country: values.servicePlace?.value,
        servicePrice: Number(values.servicePrice),
        riaPrice: values.riaPrice ? Number(values.riaPrice) : null,
        currency: Currencies[values.currency?.value as keyof typeof Currencies],
        comment: values.comment,
        teacherType:
          EmploymentTypes[
            values.teacherType?.value as keyof typeof EmploymentTypes
          ],
      };

      appendTeacherApplication({
        application,
        teacherId,
      });
    },
    [appendTeacherApplication, teacherId],
  );

  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormik<IAddApplicationFieldsValues>({
    initialValues: {
      applicationNumber: '',
      date: null,
      course: null,
      group: null,
      teacherType: null,
      lessonsNumber: '',
      servicePlace: null,
      servicePrice: '',
      riaPrice: '',
      currency: null,
      comment: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getApplicationSchema(t),
    onSubmit,
    enableReinitialize: true,
  });

  const {
    disabledButton,
    teacherTypes,
    handleChange,
    onClose,
  } = useAppendApplicationHelpers({ values, setFieldValue });

  useEffect(() => {
    if (values.course && values.date) {
      getGroups({
        id: values.course.value,
        groupsDate: DateService.toISO(values.date),
      });
    }
  }, [getGroups, values.course, values.date]);

  useEffect(() => {
    if (appendSuccess) {
      addToast({
        data: {
          title: `${t('applicationPopup.addedSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.TeacherAgreementAdd,
      });
      hidePopup(PopupNames.addApplicationPopup);
    }
  }, [appendSuccess, hidePopup, addToast, t]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IAddApplicationFieldsValues>();

  useEffect(() => {
    sortErrors({
      ...(errors as ErrorsType<IAddApplicationFieldsValues>),
      ...appendErrors,
    });
  }, [errors, appendErrors, sortErrors]);

  return (
    <AddApplicationPopupComponent
      values={values}
      errors={sortedErrors}
      errorsMessage={message}
      onInputChange={handleChange}
      handleSubmit={handleSubmit}
      onClose={onClose}
      loading={loading || appendLoading}
      disabledButton={disabledButton}
      groups={groups || []}
      courses={courses || []}
      teacherTypes={teacherTypes}
      servicePlaces={countries}
      currencies={CURRENCY_OPTIONS_TEACHER}
    />
  );
});
