import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { YupFieldErrorTypes } from 'services/YupValidationService';
import { ICreatePasswordFormValues } from 'molecules';
import { usePasswordSetMutation } from '../../graphql/generated/hooks';

interface ICreatePasswordVars {
  values: ICreatePasswordFormValues;
  token: string;
}

export const useCreatePassword = (): any => {
  const { t } = useTranslation();
  const result = usePasswordSetMutation({ errorPolicy: 'all' });
  const [setNewPassword, { data, loading }] = result;

  const createPassword = useCallback(
    ({
      values: { newPassword, confirmPassword },
      token,
    }: ICreatePasswordVars) => {
      setNewPassword({
        variables: {
          newPassword1: newPassword,
          newPassword2: confirmPassword,
          token,
        },
      });
    },
    [setNewPassword],
  );

  const errors = useMemo(() => {
    if (data?.passwordSet?.errors) {
      return {
        newPassword: {
          type: YupFieldErrorTypes.SERVER_ERROR,
          message: `${t('common.passSameAsEmail')}`,
        },
        confirmPassword: {
          type: YupFieldErrorTypes.SERVER_ERROR,
          message: `${t('common.passSameAsEmail')}`,
        },
      };
    }

    return null;
  }, [data?.passwordSet?.errors, t]);

  return [
    createPassword,
    {
      success: Boolean(data?.passwordSet?.success),
      loading,
      errors,
    },
  ];
};
