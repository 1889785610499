import { useEffect, useCallback, useReducer } from 'react';
import { authStateVar, useLoginMutation } from 'core';
import { appState, userStateVar } from 'core/state';
import { authService } from 'services/AuthorizationService';
import { ILoginFormValues } from 'components/molecules';
import { ErrorsType, FieldErrorTypes } from 'types';
import i18n from '../../../i18n';

interface ILoginVars {
  email: string;
  password: string;
  scope: string;
}

type LoginHookType = [
  (vars: ILoginVars) => void,
  { loading: boolean; errors: ErrorsType<ILoginFormValues> | null },
];

enum LoginErrors {
  wrongCredentials = 'wrongCredentials',
}

interface ILoginErrorsAction {
  type: LoginErrors;
}

export const loginErrorsReducer = (
  state: ErrorsType<ILoginFormValues> | null,
  action: ILoginErrorsAction,
): ErrorsType<ILoginFormValues> | null => {
  switch (action.type) {
    case LoginErrors.wrongCredentials: {
      const message = `${i18n.t('common.wrongMailOrPass')}`;
      return {
        email: {
          type: FieldErrorTypes.SERVER_ERROR,
          message,
        },
        password: {
          type: FieldErrorTypes.SERVER_ERROR,
          message,
        },
      };
    }
    default:
      return null;
  }
};

export const useLogin = (): LoginHookType => {
  const result = useLoginMutation();

  const [login, { data, loading }] = result;

  const [errors, setErrors] = useReducer(loginErrorsReducer, null);

  useEffect(() => {
    if (data && data?.customLogin?.token) {
      authStateVar({
        ...authStateVar(),
        isLoggedIn: true,
        access_token: data.customLogin.token,
      });
      authService.saveTokens({
        access_token: data.customLogin.token,
        refresh_token: data.customLogin.refreshToken!,
      });
      appState({ ...appState(), bootstrapped: false });
      userStateVar({ ...userStateVar(), me: data.customLogin.user! });
    }
  }, [data, loading]);

  useEffect(() => {
    if (data?.customLogin?.errors) {
      setErrors({ type: LoginErrors.wrongCredentials });
    }
  }, [data?.customLogin?.errors]);

  const loginFn = useCallback(
    ({ email, password, scope }: ILoginVars) => {
      if (scope) {
        login({
          variables: {
            email,
            password,
            scope,
          },
        });
      }
    },
    [login],
  );

  return [loginFn, { loading, errors }];
};
