import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IPaymentInfo, ErrorsType } from 'types';

import { Colors } from 'services/ColorService';

import { Button, DateSelect, Form, InputLabel, TextArea } from 'atoms';
import { PopupContainer, PopupErrors } from 'molecules';
import { DateService } from 'services/DateService';

export interface IPaymentAddPopupValues {
  date: Date | null;
  data: string;
  parsedPayments: IPaymentInfo[] | null;
}

export interface IPaymentAddPopup {
  onClose: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onInputChange: (value: string | Date | null, name: string) => void;
  values: IPaymentAddPopupValues;
  errors?: ErrorsType<IPaymentAddPopupValues> | null;
  errorMessage?: string | null;
  loading?: boolean;
  onButtonClick: () => void;
}

const isDateAfterToday = (date: Date): boolean =>
  DateService.isBefore(new Date(), date);

export const PaymentAddPopupComponent = memo(
  ({
    onClose,
    handleSubmit,
    onInputChange,
    onButtonClick,
    values,
    errors,
    errorMessage,
    loading,
  }: IPaymentAddPopup) => {
    const { t } = useTranslation();
    return (
      <PopupContainer title={t('paymentPopup.addPayment')} onClose={onClose}>
        <FormWrapper onSubmit={handleSubmit}>
          <DatePickerWrapper>
            <InputLabel>{t('paymentPopup.fields.date.label')}</InputLabel>
            <DateSelect
              name="date"
              value={values.date}
              onChange={(value) => value && onInputChange(value, 'date')}
              disabledDate={(date) => isDateAfterToday(date)}
            />
          </DatePickerWrapper>
          <TextAreaWrapper>
            <InputLabel>
              {t('paymentPopup.fields.paymentData.label')}
            </InputLabel>
            <TextArea
              name="data"
              value={values.data}
              placeholder={t('paymentPopup.fields.paymentData.placeholder')}
              rows={4}
              onChange={({ target: { value } }) => onInputChange(value, 'data')}
              hasError={Boolean(errors?.data || errors?.parsedPayments)}
            />
          </TextAreaWrapper>
          <BottomWrapper>
            <StyledPopupErrors>{errorMessage}</StyledPopupErrors>
            <ButtonWrapper
              type="button"
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
                disabledColor: Colors.White,
                bgDisabledColor: Colors.Gray,
              }}
              loading={loading}
              onClick={onButtonClick}
            >
              {t('paymentPopup.button.next')}
            </ButtonWrapper>
          </BottomWrapper>
        </FormWrapper>
      </PopupContainer>
    );
  },
);

const FormWrapper = styled(Form)`
  margin-top: 40px;
`;

const DatePickerWrapper = styled.div`
  width: 352px;
`;

const TextAreaWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
`;

const StyledPopupErrors = styled(PopupErrors)`
  margin-right: 100px;
`;

const ButtonWrapper = styled(Button)`
  width: 96px;
`;
