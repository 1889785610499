import * as React from 'react';
import { memo } from 'react';

import { PopupNames } from 'core';
import { usePopupData } from 'core/hooks';

import { ConfirmPopupComponent, IConfirmPopup } from './ConfirmPopup';

export const ConfirmPopup = memo(() => {
  const data = usePopupData(PopupNames.confirmPopup);
  const {
    title,
    confirmText,
    cancelText,
    children,
    onConfirm,
    onCancel,
  } = data as IConfirmPopup;

  return (
    <ConfirmPopupComponent
      title={title}
      confirmText={confirmText}
      cancelText={cancelText}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      {children}
    </ConfirmPopupComponent>
  );
});
