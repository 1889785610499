import * as React from 'react';
import { memo } from 'react';

import { usePopup } from 'core/hooks';

import { GlobalModalComponent } from './GlobalModal';

export const GlobalModal = memo(() => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [active, hide, props = null] = usePopup();

  return (
    <GlobalModalComponent>
      {active ? React.createElement(active, props) : null}
    </GlobalModalComponent>
  );
});
