import * as React from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { translateRoleToValue } from 'services/TransformService';

import { UserNode } from 'core/graphql';

import {
  UsersSettingsTableComponent,
  IColumn,
  IRow,
} from './UsersSettingsTable';

export interface IUsersSettingsTable {
  data: UserNode[];
  loading?: boolean;
  error?: string;
  editUser: (userId: string) => void;
  loadMore: () => void;
  hasMore: boolean;
}

const getColumnsSource = (t: TFunction): IColumn[] => [
  {
    Header: `${t('table.userSettings.name')}`,
    accessor: 'username',
    columnWidth: '240px',
  },
  {
    Header: `${t('table.userSettings.email')}`,
    accessor: 'email',
    columnWidth: '264px',
    marginRight: '72px',
    marginLeft: '72px',
  },
  {
    Header: `${t('table.userSettings.role')}`,
    accessor: 'role',
    flexible: true,
    marginRight: '120px',
  },
];

const transformUsers = (users: UserNode[]) =>
  users.map((user) => ({
    id: user.id,
    username: `${user.lastName} ${user.firstName}${
      user.middleName ? ` ${user.middleName}` : ''
    }`,
    email: user?.email || '',
    role: user.roles.map((role) => translateRoleToValue(role)).join(', '),
  }));

export const UsersSettingsTable = memo(
  ({
    data,
    loading,
    error,
    editUser,
    loadMore,
    hasMore,
  }: IUsersSettingsTable) => {
    const { t } = useTranslation();
    const columns: IColumn[] = useMemo(() => getColumnsSource(t), [t]);
    const rows: IRow[] = useMemo(() => transformUsers(data), [data]);

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <UsersSettingsTableComponent
        rows={rows}
        columns={columns}
        isFlexible
        onUserEditClick={editUser}
        noDataText={t('table.userSettings.noData')}
        loadMore={loadMore}
        hasMore={hasMore}
        loading={loading}
      />
    );
  },
);
