import { useCallback } from 'react';

import {
  useChangeAttendanceMutation,
  ChangeAttendanceMutationVariables,
} from 'core/graphql';
import { useParseServerErrors } from 'services/ErrorsService';

type UpdateAttendanceType = [
  (agreementInput: ChangeAttendanceMutationVariables) => void,
  {
    success: boolean;
    loading: boolean;
  },
];

export const useUpdateAttendance = (): UpdateAttendanceType => {
  const [updateAttendance, { data, loading }] = useChangeAttendanceMutation({
    onError: (err) => err,
  });

  useParseServerErrors(data?.updateAttendance?.customErrors);

  const changeAttendance = useCallback(
    (variables: ChangeAttendanceMutationVariables) =>
      updateAttendance({
        variables,
        update(cache, result) {
          if (result.data?.updateAttendance?.success) {
            cache.modify({
              id: `AttendanceType:${variables.attendanceId}`,
              fields: {
                attendance() {
                  return variables.attendance;
                },
              },
            });
          }

          if (!result.data?.updateAttendance?.success) {
            cache.modify({
              id: `AttendanceType:${variables.attendanceId}`,
              fields: {
                attendance() {
                  return !variables.attendance;
                },
              },
            });
          }
        },
        optimisticResponse: {
          updateAttendance: {
            success: true,
          },
        },
      }),
    [updateAttendance],
  );

  return [
    changeAttendance,
    {
      success: Boolean(data?.updateAttendance?.success),
      loading,
    },
  ];
};
