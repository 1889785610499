import { useCallback, useMemo, useEffect } from 'react';

import {
  PaginatedTeachersApplicationsQueryVariables,
  usePaginatedTeachersApplicationsQuery,
  ApplicationEdge,
  useGetApplicationsForGroupQuery,
} from 'core';
import { useReactiveVar } from '@apollo/client';
import { applicationOptionsStateVar } from 'core/state/applicationOptionsState';

import { IApplication, IApplicationOptions } from 'components/types';
import { transformApplicationsEdges } from './transform';

type GetTeachersHookType = [
  (vars: PaginatedTeachersApplicationsQueryVariables) => void,
  {
    data: IApplication[] | null;
    hasMore: boolean;
    after: string;
    loading: boolean;
    error: string | undefined;
  },
];

export const useApplications = (): GetTeachersHookType => {
  const {
    data,
    loading,
    error,
    fetchMore,
  } = usePaginatedTeachersApplicationsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      first: 10,
    },
    notifyOnNetworkStatusChange: true,
  });

  const fetchMoreApplications = useCallback(
    ({ query, after }: PaginatedTeachersApplicationsQueryVariables) => {
      fetchMore({
        variables: {
          query,
          after,
        },
      });
    },
    [fetchMore],
  );

  const result = (): IApplication[] => {
    const edges = data?.allTeachersApplications?.edges;
    if (!edges) {
      return [];
    }

    return transformApplicationsEdges(edges as ApplicationEdge[]);
  };

  return [
    fetchMoreApplications,
    {
      data: result(),
      hasMore: data?.allTeachersApplications?.pageInfo.hasNextPage || false,
      after: data?.allTeachersApplications?.pageInfo.endCursor || '',
      loading,
      error: error?.message || undefined,
    },
  ];
};

export const useApplicationsOptionsForGroup = (groupId: string) => {
  const { data, loading } = useGetApplicationsForGroupQuery({
    variables: {
      byGroupId: Number(groupId),
      isTerminated: false,
      groupsFilter: true,
    },
    fetchPolicy: 'no-cache',
  });
  const applicationOptions = useMemo(
    () =>
      data?.allTeachersApplications?.edges.reduce(
        (applications: IApplicationOptions[], edge) => {
          if (edge && edge.node?.id) {
            return [
              ...applications,
              {
                value: edge.node.id,
                label: `${edge.node.teacher.fullName} - ${edge.node.title}`,
                teacherName: edge.node.teacher.fullName || '',
                freeLessonsCount: Number(
                  edge.node.lessonsCount - edge.node.appointedLessonsCount,
                ),
              },
            ];
          }

          return applications;
        },
        [],
      ) || [],
    [data?.allTeachersApplications?.edges],
  );

  useEffect(() => {
    if (applicationOptions) {
      applicationOptionsStateVar({
        ...applicationOptionsStateVar(),
        applicationOptions,
      });
    }
  }, [applicationOptions]);

  const applicationOptionsState = useReactiveVar(applicationOptionsStateVar)
    .applicationOptions;

  return {
    applicationOptionsState,
    loading,
  };
};
