import * as React from 'react';
import { memo } from 'react';
import { Colors } from 'services/ColorService';

import styled, { css } from 'styled-components';

export interface ITable extends ITableStyle {
  children: React.ReactNode[] | React.ReactNode;
}

export interface ITableStyle {
  flexible?: boolean;
  variant?: 'default' | 'grey' | 'defaultJournal';
  className?: string;
  rowShadowOnHover?: boolean;
}

export const Table = memo(
  ({
    children,
    flexible,
    variant = 'default',
    className,
    rowShadowOnHover,
  }: ITable) => (
    <TableStyled
      flexible={flexible}
      variant={variant}
      className={className}
      rowShadowOnHover={rowShadowOnHover}
    >
      {children}
    </TableStyled>
  ),
);

const TableStyled = styled.table<ITableStyle>`
  ${({ flexible }) =>
    flexible &&
    css`
      min-width: 100%;
    `};
  display: flex;
  flex-direction: column;
  border-spacing: 0;
  box-sizing: border-box;

  ${({ variant }) => {
    if (variant === 'default') {
      return css`
        border-radius: 8px;

        & > thead {
          border-bottom: 2px solid ${Colors.Blue};
          border-radius: 24px 24px 0 0;
          box-shadow: 0px 16px 24px ${Colors.BlueShadow};

          & > tr {
            padding-top: 12px;
            padding-bottom: 12px;
          }
        }

        & > tbody {
          box-shadow: 0px 4px 8px ${Colors.LightShadow};

          & > tr {
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }

        & > thead tr,
        & > tbody tr {
          padding-right: 24px;
          padding-left: 24px;
        }
      `;
    }

    if (variant === 'defaultJournal') {
      return css`
        border-radius: 8px;

        & > thead {
          border-bottom: 2px solid ${Colors.Blue};
          border-radius: 24px 24px 0 0;
          box-shadow: 0px 16px 24px ${Colors.BlueShadow};

          & > tr {
            padding: 24px 0 24px 24px;
          }
        }

        & > tbody {
          box-shadow: 0px 4px 8px ${Colors.LightShadow};

          & > tr {
            padding: 0 0 0 24px;
          }
        }
      `;
    }

    if (variant === 'grey') {
      return css`
        & > thead {
          border-bottom: none;
          box-shadow: none;
          background-color: ${Colors.MainBackground};

          & > tr {
            padding-top: 16px;
            padding-bottom: 16px;
          }
        }

        & > tbody > tr {
          margin-top: 16px;
        }
      `;
    }
  }}

  ${({ rowShadowOnHover }) => {
    if (rowShadowOnHover) {
      return css`
        & > tbody > tr:hover {
          box-shadow: 0px 8px 32px ${Colors.BlueShadowRow};
        }
      `;
    }
  }}

  background-color: ${Colors.White};
  word-break: break-word;
`;
