import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';

import styled from 'styled-components';

import { Icon, IconsNames } from 'atoms';
import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';

export interface IDateSelect extends IDateStyle {
  name?: string;
  value: Date | null;
  placeholder?: string;
  className?: string;
  defaultValue?: Date;
  disabled?: boolean;
  onChange: (value: Date | null) => void;
  disabledDate?: (date: Date) => boolean;
}

export interface IDateStyle {
  hasError?: boolean;
  disabled?: boolean;
}

export const DateSelect = ({
  name,
  value,
  placeholder,
  hasError = false,
  className,
  disabled,
  defaultValue,
  onChange,
  disabledDate,
}: IDateSelect) => (
  <StyledDatePicker
    className={className}
    name={name}
    defaultValue={moment(defaultValue)}
    value={value ? moment(value) : null}
    placeholder={placeholder}
    style={{ height: '48px', width: '100%' }}
    onChange={(changedValue) =>
      changedValue ? onChange(changedValue.toDate()) : undefined
    }
    format="DD.MM.YYYY"
    hasError={hasError}
    disabled={disabled}
    suffixIcon={
      <Icon
        name={IconsNames.CalendarIcon2}
        height="16"
        width="16"
        color={disabled ? Colors.DeactivatedGrey : Colors.Blue}
      />
    }
    disabledDate={
      disabledDate ? (date) => disabledDate(date.toDate()) : undefined
    }
  />
);

const StyledDatePicker = styled(DatePicker)<IDateStyle>`
  font-family: ${getFontFamily('regular')};
  border: 1px solid
    ${({ hasError }) => (hasError ? Colors.Red : Colors.LightGray)} !important;
  box-shadow: none;
  cursor: pointer;
  background-color: ${({ disabled }) =>
    disabled ? Colors.LightBlue : Colors.White} !important;

  & input {
    &::placeholder {
      color: ${Colors.Gray};
    }
  }

  & input[disabled] {
    color: ${Colors.Black};
  }
  .ant-picker-clear {
    display: none;
  }

  &:hover {
    border-color: ${Colors.LightGray};
  }

  &.ant-picker-focused {
    border-color: ${Colors.Blue};
  }
`;
