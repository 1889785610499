import * as React from 'react';

import styled from 'styled-components';

import { Colors } from 'services/ColorService';

export interface ITableRow extends ITableRowStyle {
  children: React.ReactNode[] | React.ReactNode;
  type?: RowType;
}

export interface ITableRowStyle {
  cursor?: string;
  borders?: 'none';
}

export type RowType = 'row' | 'subRow' | 'subRowParent';

export const TableRow = styled.tr<ITableRow>`
  width: initial;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: inherit;
  cursor: ${({ cursor }) => cursor};

  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0 24px;
    height: 1px;
    width: calc(100% - 48px);
    border-bottom: 1px solid
      ${({ borders }) =>
        borders === 'none' ? 'transparent' : Colors.LightGray};
  }

  :last-child {
    border-bottom: 0;
  }
  background-color: ${({ type }) =>
    type === 'subRow' ? Colors.LightBlue : 'transparent'};
  box-shadow: ${({ type }) =>
    type === 'subRowParent' ? '0px 4px 8px rgba(136, 149, 158, 0.08)' : ''};
`;
