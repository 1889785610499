import { useCallback } from 'react';
import { useDebtorsMetaLazyQuery } from 'core/graphql';

interface IDebtorsMetaQueryArgs {
  query?: string;
  contributionNum?: number;
}

type GetDebtorsMetaHookType = [
  (args?: IDebtorsMetaQueryArgs) => void,
  {
    totalCount: number;
  },
];

export const useGetDebtorsMeta = (): GetDebtorsMetaHookType => {
  const [getMeta, { data }] = useDebtorsMetaLazyQuery({
    fetchPolicy: 'network-only',
  });

  const getContributionsMeta = useCallback(
    (args?: IDebtorsMetaQueryArgs) => {
      getMeta({
        variables: {
          query: args?.query,
          contributionNum: args?.contributionNum,
        },
      });
    },
    [getMeta],
  );

  return [
    getContributionsMeta,
    {
      totalCount: Number(data?.metaContributions?.totalCount || 0),
    },
  ];
};
