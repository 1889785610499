import { gql } from '@apollo/client';

export const deleteGroupMutation = gql`
  mutation removeGroup($groupId: Int!) {
    deleteGroup(groupId: $groupId) {
      success
      errors
    }
  }
`;

export const publishGroupToLMS = gql`
  mutation publishToLMS(
    $groupId: ID!
    $publishedLms: Boolean!
    $publishedForTeachers: Boolean
    $publishedLmsForMethodist: Boolean
  ) {
    publishGroup(
      groupId: $groupId
      publishedLms: $publishedLms
      publishedForTeachers: $publishedForTeachers
      publishedLmsForMethodist: $publishedLmsForMethodist
    ) {
      success
      errors
    }
  }
`;

export const createGroupMutation = gql`
  mutation AddGroup($groupInput: GroupInput!, $moduleInput: [ModuleInput]!) {
    createGroup(groupInput: $groupInput, moduleInput: $moduleInput) {
      success
      errors
      customErrors {
        field
        message
        code
      }
    }
  }
`;

export const UpdateGroupMutation = gql`
  mutation changeGroup($groupId: Int!, $groupInput: UpdateGroupInput!) {
    updateGroup(groupId: $groupId, groupInput: $groupInput) {
      success
      errors
      customErrors {
        field
        message
        code
      }
    }
  }
`;
