import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ErrorsType } from 'types';

import {
  Input,
  InputLabel,
  InputPhone,
  ISelectOption,
  CheckboxWithText,
} from 'atoms';

export interface ITeacherAddFieldsValues {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  displayOnSite?: boolean;
}

export interface ITeacherAddFields {
  values?: ITeacherAddFieldsValues | null;
  disableFields?: boolean;
  errors?: ErrorsType<ITeacherAddFieldsValues> | null;
  onInputChange: (
    name: string,
    value?: string | boolean | ISelectOption<string>[],
  ) => void;
}

export const TeacherAddFields = memo(
  ({ values, disableFields, errors, onInputChange }: ITeacherAddFields) => {
    const { t } = useTranslation();
    return (
      <>
        <FieldsWrapper>
          <div>
            <InputLabel>{t('teacherPopup.fields.lastName.label')}</InputLabel>
            <Input
              placeholder={t('teacherPopup.fields.lastName.placeholder')}
              max={30}
              type="text"
              value={values?.lastName || ''}
              name="lastname"
              onChange={({ target: { value } }) =>
                onInputChange('lastName', value)
              }
              hasError={Boolean(errors?.lastName)}
              disabled={disableFields}
            />
          </div>
          <div>
            <InputLabel>{t('teacherPopup.fields.firstName.label')}</InputLabel>
            <Input
              placeholder={t('teacherPopup.fields.firstName.placeholder')}
              max={30}
              type="text"
              value={values?.firstName || ''}
              name="firstName"
              onChange={({ target: { value } }) =>
                onInputChange('firstName', value)
              }
              hasError={Boolean(errors?.firstName)}
              disabled={disableFields}
            />
          </div>
          <div>
            <InputLabel isOptional>
              {t('teacherPopup.fields.middleName.label')}
            </InputLabel>
            <Input
              placeholder={t('teacherPopup.fields.middleName.placeholder')}
              max={30}
              type="text"
              value={values?.middleName || ''}
              name="middlename"
              onChange={({ target: { value } }) =>
                onInputChange('middleName', value)
              }
              hasError={Boolean(errors?.middleName)}
              disabled={disableFields}
            />
          </div>
          <div>
            <InputLabel>{t('teacherPopup.fields.email.label')}</InputLabel>
            <Input
              placeholder={t('teacherPopup.fields.email.placeholder')}
              type="text"
              value={values?.email || ''}
              name="email"
              onChange={({ target: { value } }) =>
                onInputChange('email', value)
              }
              hasError={Boolean(errors?.email)}
              disabled={disableFields}
            />
          </div>
          <div>
            <InputLabel>{t('teacherPopup.fields.phone.label')}</InputLabel>
            <InputPhone
              placeholder={t('teacherPopup.fields.phone.placeholder')}
              value={values?.phone || ''}
              name="phone"
              onChange={({ target: { value } }) =>
                onInputChange('phone', value)
              }
              hasError={Boolean(errors?.phone)}
              disabled={disableFields}
            />
          </div>
          <CheckboxWrapper>
            <CheckboxWithText
              value={values?.displayOnSite}
              onClick={() =>
                onInputChange('displayOnSite', !values?.displayOnSite)
              }
            >
              {t('teacherPopup.fields.displayOnSite.label')}
            </CheckboxWithText>
          </CheckboxWrapper>
        </FieldsWrapper>
      </>
    );
  },
);

const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 24px;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  padding-top: 40px;
`;
