import { makeVar } from '@apollo/client';

import { IGroupModule } from 'types';

export interface IModulesState {
  modules: { [key: string]: IGroupModule };
}

export const defaultGroupModulesState: IModulesState = Object.freeze({
  modules: {},
});

export const modulesStateVar = makeVar(defaultGroupModulesState);
