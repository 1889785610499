import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { MainTemplate } from 'templates';
import {
  FinanceTable,
  IFinanceTableMonth,
  ISwitchFilterValue,
  SwitchFilter,
} from 'molecules';
import { Heading } from 'atoms';
import {
  useGetFinanceYears,
  useGetPlannedYearContributions,
  useToasts,
} from 'core/hooks';
import { GlobalToastTypeNames, ToastTypes } from 'components/types';

export const Finances = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const {
    data: financeYears,
    loading: financeYearsLoading,
  } = useGetFinanceYears();

  const plannedYears: ISwitchFilterValue[] = useMemo(
    () =>
      financeYears?.map((year) => ({
        value: String(year),
        label: String(year),
      })) || [],
    [financeYears],
  );

  const [filterByYear, setFilterByYear] = useState<string>('');

  useEffect(() => {
    const yearsLength = plannedYears.length;

    if (yearsLength) {
      setFilterByYear(plannedYears[yearsLength - 1].value);
    }
  }, [plannedYears]);

  const {
    getYearContributions,
    data: plannedYearContributions,
    loading: loadingPlannedYearContributions,
  } = useGetPlannedYearContributions();

  useEffect(() => {
    const value = Number(filterByYear);
    if (filterByYear) {
      if (Number.isNaN(value)) {
        addToast({
          data: {
            type: ToastTypes.error,
            title: `${t('pages.finances.error')}`,
            text: `${t('pages.finances.wrongYearFormat')}`,
          },
          type: GlobalToastTypeNames.Finances,
        });
      }

      getYearContributions(Number(filterByYear));
    }
  }, [filterByYear, addToast, getYearContributions, t]);

  const onFilterByYearChange = useCallback(
    (newValue: string) => {
      setFilterByYear(newValue);
    },
    [setFilterByYear],
  );

  const tableData: IFinanceTableMonth[] = useMemo(
    () =>
      plannedYearContributions.map(
        (month, index): IFinanceTableMonth => ({
          contribution: month,
          month: index + 1,
          loading: false,
        }),
      ),
    [plannedYearContributions],
  );

  return (
    <MainTemplate>
      <Wrapper>
        <HeadWrapper>
          <Heading>{t('pages.finances.contributions')}</Heading>
          <SwitchFilterStyled
            items={plannedYears}
            currentValue={filterByYear}
            onChange={onFilterByYearChange}
            loading={financeYearsLoading}
          />
        </HeadWrapper>

        <TableStyled
          data={tableData}
          year={Number(filterByYear)}
          loading={loadingPlannedYearContributions || financeYearsLoading}
        />
      </Wrapper>
    </MainTemplate>
  );
});

const Wrapper = styled.div`
  width: 100%;
  margin-top: 52px;
`;

const HeadWrapper = styled.div`
  display: flex;
`;

const SwitchFilterStyled = styled(SwitchFilter)`
  margin-left: auto;
`;

const TableStyled = styled(FinanceTable)`
  width: 100%;
  margin: 16px 0 64px;
`;
