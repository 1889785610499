import React, { ReactNode, useMemo } from 'react';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import styled from 'styled-components';

import {
  Icon,
  IconsNames,
  CheckBox,
  Text,
  Button,
  Input,
  InputLabel,
  IconButton,
  CheckboxWithText,
} from 'atoms';

import { IconSizes, IFieldError, ErrorsType, IApplicationOptions } from 'types';
import { Colors } from 'services/ColorService';
import { useTranslation } from 'react-i18next';

type InputHandler<T> = (value: T, inputName: string) => void;

export interface ISortableList {
  children: ReactNode;
}

export interface ITeacher {
  value: string;
  label: string;
}

export interface IModuleLessonBlock {
  lessonId: string;
  checked: boolean;
  lessonName: string;
  lessonNumber?: number | null;
  teacher: ITeacher | null;
  isNewLesson?: boolean;
}

export interface IModuleValues {
  application: IApplicationOptions | null;
  lessonInputName: string;
  isAdditional: boolean;
}

export interface IChooseLesson {
  onChooseLesson: (lessonId: string) => void;
  isPopupAdd: boolean;
}

export interface IAddLesson {
  onShowInput: () => void;
}

export interface IAddLessonInput {
  onAddLesson: (values: IModuleValues) => void;
  values: IModuleValues;
  errors?: ErrorsType<IModuleValues> | null;
  onChangeInput: InputHandler<string | boolean>;
  onCloseInput: () => void;
}

export type IModuleErrors = {
  [key in keyof IModuleValues]?: IFieldError;
};

export const DragHandle = SortableHandle(() => (
  <SortableIcons>
    <Icon name={IconsNames.SortableIcon} width={16} height={7} />
  </SortableIcons>
));

export const DisabledLesson = ({
  lessonName,
  lessonNumber,
  teacher,
}: {
  lessonName: string;
  lessonNumber?: number | null;
  teacher: ITeacher | null;
}) => (
  <DisabledLessonItem>
    <WrapperCheckBox>
      <CheckBox checked={false} disabled />
    </WrapperCheckBox>
    <WrapperColumn width="416px">
      <Text type="disabled">
        {lessonNumber ? `${lessonNumber}. ${lessonName}` : `- ${lessonName}`}
      </Text>
    </WrapperColumn>
    <WrapperColumn width="258px">
      <Text type="disabled">{teacher && teacher.label}</Text>
    </WrapperColumn>
  </DisabledLessonItem>
);

export const SortableItem = SortableElement(
  ({
    lessonId,
    lessonName,
    lessonNumber,
    isNewLesson,
    teacher,
    checked,
    isPopupAdd,
    onChooseLesson,
  }: IModuleLessonBlock & IChooseLesson) => {
    const sortableItemText = useMemo(() => {
      if (isNewLesson) {
        return lessonName;
      }

      return lessonNumber
        ? `${lessonNumber}. ${lessonName}`
        : `- ${lessonName}`;
    }, [isNewLesson, lessonName, lessonNumber]);
    return (
      <LessonItem>
        {!isPopupAdd && (
          <WrapperCheckBox>
            <CheckBox
              checked={checked}
              onClick={() => onChooseLesson(lessonId)}
            />
          </WrapperCheckBox>
        )}

        <WrapperColumn
          width={isPopupAdd ? '624px' : '416px'}
          isPopupAdd={isPopupAdd}
        >
          <Text>{sortableItemText}</Text>
        </WrapperColumn>
        {!isPopupAdd && (
          <WrapperColumn width="192px">
            <Text>{teacher && teacher.label}</Text>
          </WrapperColumn>
        )}

        <DragHandle />
      </LessonItem>
    );
  },
);

export const SortableList = SortableContainer(({ children }: ISortableList) => (
  <LessonsList>{children}</LessonsList>
));

export const AddLesson = ({ onShowInput }: IAddLesson) => {
  const { t } = useTranslation();
  return (
    <WrapperAddLesson>
      <IconButton
        name={IconsNames.PlusLargeIcon}
        size={IconSizes.xl}
        onClick={onShowInput}
      />
      <WrapperText>
        <Text size="md">{t('groupPopup.modules.button.addAnotherLesson')}</Text>
      </WrapperText>
    </WrapperAddLesson>
  );
};

export const AddLessonInput = ({
  onAddLesson,
  values,
  errors,
  onChangeInput,
  onCloseInput,
}: IAddLessonInput) => {
  const { t } = useTranslation();
  return (
    <WrapperAddLessonInput>
      <WrapperAddLessonInputRow>
        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.lessonInputName)}>
            {t('groupPopup.modules.fields.lessonName.label')}
          </InputLabel>
          <Input
            placeholder={t('groupPopup.modules.fields.lessonName.placeholder')}
            type="text"
            value={values.lessonInputName}
            name="lessonInputName"
            onChange={({ target: { value } }) =>
              onChangeInput(value, 'lessonInputName')
            }
            hasError={Boolean(errors?.lessonInputName)}
          />
        </WrapperInput>

        <WrapperSendButton>
          <Button
            onClick={() => {
              onAddLesson(values);
            }}
            type="button"
            colors={{
              backgroundColor: Colors.Blue,
              color: Colors.White,
            }}
          >
            {t('groupPopup.modules.button.addLesson')}
          </Button>
        </WrapperSendButton>
        <WrapperCloseButton>
          <IconButton
            name={IconsNames.CloseIconSquare}
            size={IconSizes.xl}
            onClick={() => {
              onCloseInput();
              onChangeInput('', 'lessonInputName');
            }}
          />
        </WrapperCloseButton>
      </WrapperAddLessonInputRow>
      <CheckboxWithText
        value={values.isAdditional}
        onClick={() => {
          onChangeInput(!values.isAdditional, 'isAdditional');
        }}
      >
        {t('groupPopup.modules.isAdditional')}
      </CheckboxWithText>
    </WrapperAddLessonInput>
  );
};

const WrapperAddLesson = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 16px 0 0 0;
`;

const WrapperAddLessonInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 0 16px 0;
`;

const WrapperAddLessonInputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: initial;
  width: 535px;
`;

const WrapperSendButton = styled.div`
  width: 137px;
  margin: 32px 0 0 0;
`;

const WrapperCloseButton = styled.div`
  margin: 32px 0 0 16px;
`;

const WrapperText = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 16px;
`;

const SortableIcons = styled.div`
  width: 64px;
  height: 48px;
  cursor: grabbing;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DisabledLessonItem = styled.li`
  display: flex;
  align-items: center;
  list-style-type: none;
  height: 48px;
  width: 100%;
  background-color: ${Colors.White};
  border-bottom: 1px solid ${Colors.LightGray};
  z-index: 100;
`;

const LessonItem = styled.li`
  display: flex;
  align-items: center;
  list-style-type: none;
  height: 48px;
  width: 100%;
  background-color: ${Colors.White};
  border-bottom: 1px solid ${Colors.LightGray};
  z-index: 100;
`;

const WrapperCheckBox = styled.div`
  margin: 16px 24px;
`;

const WrapperColumn = styled.div<{ width?: string; isPopupAdd?: boolean }>`
  min-width: ${({ width }) => width || '100%'};
  max-width: ${({ width }) => width || '100%'};
  margin: ${({ isPopupAdd }) => (isPopupAdd ? '16px 24px' : '0px')};
  display: flex;
  flex-direction: column;
`;

const LessonsList = styled.ul`
  max-height: 480px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  background-color: ${Colors.MainBackground};
  :hover {
    ::-webkit-scrollbar-thumb {
      background: ${Colors.MainBackground};
    }
  }
  ::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 20px;
    background: transparent;
  }
  ::-webkit-scrollbar {
    width: 4px;
    background-color: ${Colors.White};
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;
