import { useCallback } from 'react';

import { PaymentsDeferredQuery, usePaymentsDeferredLazyQuery } from 'core';
import { Currencies, IVerifiedPaymentInfo } from 'types';

import { DateService } from 'services/DateService';

type GetDeferredPaymentsHookType = [
  (query?: string) => void,
  {
    data: IVerifiedPaymentInfo[];
    hasMore: boolean;
    loading: boolean;
    error?: string;
    operations: {
      fetchMore: (query: string) => void;
    };
  },
];

export const useGetDeferredPayments = (): GetDeferredPaymentsHookType => {
  const [
    getPayments,
    { data, loading, error, fetchMore },
  ] = usePaymentsDeferredLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const fetchDeferred = useCallback(
    (query?: string) => {
      getPayments({
        variables: {
          first: 10,
          query,
        },
      });
    },
    [getPayments],
  );

  const fetchMoreDeferred = useCallback(
    (query: string) => {
      if (fetchMore) {
        fetchMore({
          variables: {
            first: 10,
            after: data?.allDeferredPayments?.pageInfo.endCursor,
            query,
          },
        });
      }
    },
    [data?.allDeferredPayments?.pageInfo.endCursor, fetchMore],
  );

  const transformData = useCallback(
    (queryData: PaymentsDeferredQuery): IVerifiedPaymentInfo[] => {
      const result = queryData.allDeferredPayments?.edges.map(
        (payment): IVerifiedPaymentInfo => ({
          deferredPaymentId: payment!.node!.id,
          date: DateService.parseDate(payment!.node!.date, 'ISO'),
          username: payment!.node!.studentFullname || '',
          contractNumber: payment!.node!.agreementTitle || '',
          contractCurrency: (payment!.node!
            .agreementCurrency as unknown) as Currencies,
          sum: payment?.node?.sum,
          sumCurrency: (payment!.node!.currency as unknown) as Currencies,
          unparsed: payment!.node!.userInput,
        }),
      );

      return result || [];
    },
    [],
  );

  return [
    fetchDeferred,
    {
      data: (data && transformData(data)) || [],
      hasMore: data?.allDeferredPayments?.pageInfo.hasNextPage || false,
      loading,
      error: error?.message,
      operations: {
        fetchMore: fetchMoreDeferred,
      },
    },
  ];
};
