import { usePlannedMonthContributionsLazyQuery } from 'core';

import { IPlannedContribution } from 'components/types';

import { useCallback } from 'react';
import { transformPlannedMonthContributions } from './transform';

export interface IMonthPlannedContributionsState {
  [key: number]: {
    [key: number]: {
      contributions: IPlannedContribution[];
    };
  };
}

type GetPlannedMonthContributionsHookType = {
  getPlannedMonthContributions: (year: number, month: number) => void;
  data: IPlannedContribution[] | undefined;
  loading: boolean;
};

export const useGetPlannedMonthContributions = (): GetPlannedMonthContributionsHookType => {
  const [getData, { data, loading }] = usePlannedMonthContributionsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const getMonthData = useCallback(
    (year: number, month: number) => {
      getData({
        variables: {
          year,
          month,
        },
      });
    },
    [getData],
  );

  return {
    getPlannedMonthContributions: getMonthData,
    data: data ? transformPlannedMonthContributions(data) : undefined,
    loading,
  };
};
