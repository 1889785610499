import * as React from 'react';
import { FC, memo } from 'react';

import { Row, useTable } from 'react-table';
import styled from 'styled-components';

import { Table, TableBody, TableCell, TableHead, TableRow } from 'atoms';

import { LoadingData, LoadingTable, InfiniteScrollContainer } from 'molecules';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

type Accessors =
  | 'id'
  | 'num'
  | 'name'
  | 'contractId'
  | 'phone'
  | 'group'
  | 'paymentDate'
  | 'expiredDays'
  | 'amount';

export type IRow = {
  [key in Accessors]: React.ReactNode;
};

export interface IColumn {
  accessor: Accessors;
  flexible?: boolean;
  align?: 'left' | 'right' | 'center';
  Header?: string | React.ReactElement;
  columns?: IColumn[];
  marginRight?: string;
  marginLeft?: string;
  columnWidth?: string;
}

export interface IDebtorsTableComponent {
  columns: IColumn[];
  rows: IRow[];
  isFlexible: boolean;
  noDataText: string;
  loadMore: () => void;
  hasMore: boolean;
  loading?: boolean;
}

const getCellWrapper = (columnId: Accessors): FC => {
  switch (columnId) {
    case 'id':
      return CourseTextWrapper;
    case 'num':
      return CourseTextWrapper;
    case 'name':
      return CourseDefaultWrapper;
    case 'contractId':
      return CourseTextWrapper;
    case 'phone':
      return CourseTextWrapper;
    case 'group':
      return CourseTextWrapper;
    case 'paymentDate':
      return CourseDefaultWrapper;
    case 'expiredDays':
      return CourseDefaultWrapper;
    case 'amount':
      return CourseDefaultWrapper;
    default:
      return CourseDefaultWrapper;
  }
};

export const DebtorsTableComponent = memo(
  ({
    columns,
    rows: rowsSource,
    isFlexible,
    noDataText,
    loadMore,
    hasMore,
    loading,
  }: IDebtorsTableComponent) => {
    const {
      getTableProps,
      headers,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data: rowsSource,
    });

    return (
      <Wrapper>
        <InfiniteScrollContainer
          loadMore={loadMore}
          hasMore={hasMore}
          dataLength={rows.length}
          loading={loading || false}
        >
          <Table {...getTableProps()} flexible={isFlexible}>
            <TableHead>
              <TableRow type="row">
                {headers.map((column: any) => {
                  const {
                    key: cellKey,
                    ...restCellProps
                  } = column.getHeaderProps({
                    type: 'headerCell',
                    flexible: column.flexible && isFlexible,
                    width: column.columnWidth,
                    margin: {
                      right: column.marginRight,
                      left: column.marginLeft,
                    },
                  });
                  return (
                    <TableCell key={cellKey} {...restCellProps}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {!rows.length && !loading && (
                <TableRow key="noData">
                  <TableCell type="cell" width="100%" align="center">
                    {noDataText}
                  </TableCell>
                </TableRow>
              )}
              {!rows.length && loading && (
                <TableRow>
                  <TableCell width="100%" align="center">
                    <LoadingTable />
                  </TableCell>
                </TableRow>
              )}
              {Boolean(rows.length) &&
                rows.map((row: Row<IRow>) => {
                  prepareRow(row);
                  const { key: rowKey, ...restRowProps } = row.getRowProps();

                  return (
                    <TableRow key={rowKey} type="row" {...restRowProps}>
                      {row.cells.map((cell: any) => {
                        const {
                          key: cellKey,
                          ...restCellProps
                        } = cell.getCellProps({
                          width: cell.column.columnWidth,
                          margin: {
                            right: cell.column.marginRight,
                            left: cell.column.marginLeft,
                          },
                          flexible: cell.column.flexible && isFlexible,
                        });

                        const columnId = cell.column.id;
                        const CellWrapper = getCellWrapper(columnId);
                        return (
                          <TableCell key={cellKey} {...restCellProps}>
                            <CellWrapper>{cell.render('Cell')}</CellWrapper>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </InfiniteScrollContainer>
        {Boolean(rows.length) && loading && <LoadingData />}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 60px;
`;

const CourseDefaultWrapper = styled.span`
  font-family: ${getFontFamily()};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${Colors.Black};
`;

const CourseTextWrapper = styled(CourseDefaultWrapper)`
  color: ${Colors.Blue};
`;
