import * as React from 'react';
import { memo } from 'react';

import { Redirect, useLocation } from 'react-router-dom';

import { GuestTemplate } from 'templates';
import { RecoveryPasswordForm } from 'molecules';

import { getAuthPath, PATHS } from 'router/config';
import { queryService } from 'services/QueryService';
import { useAuthState } from 'core/hooks';

export const RecoveryPassword = memo(() => {
  const { pathname } = useLocation();
  const {
    data: { isLoggedIn },
  } = useAuthState();

  if (isLoggedIn) {
    return <Redirect to={getAuthPath(PATHS.main)} />;
  }

  const token = queryService.getToken(pathname);

  if (!token) {
    return <Redirect to={getAuthPath(PATHS.login)} />;
  }

  return (
    <GuestTemplate>
      <RecoveryPasswordForm token={token} />
    </GuestTemplate>
  );
});
