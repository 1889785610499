import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';
import { AdmissionStatusTypes } from 'types';
import { Icon, IconsNames } from 'atoms';
import { Colors } from 'services/ColorService';

import { useTranslation } from 'react-i18next';

export interface IAdmissionStatus {
  status: AdmissionStatusTypes;
}

export const AdmissionStatus = memo(({ status }: IAdmissionStatus) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Icon
        name={
          status === AdmissionStatusTypes.ADMITTED
            ? IconsNames.Success
            : IconsNames.Error
        }
        width={12}
        height={12}
      />
      <Text status={status}>
        {status === AdmissionStatusTypes.ADMITTED
          ? t('admissionStatus.admitted')
          : t('admissionStatus.not_admitted')}
      </Text>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span<IAdmissionStatus>`
  color: ${({ status }) =>
    status === AdmissionStatusTypes.ADMITTED
      ? Colors.SuccessDefault
      : Colors.ErrorDefault};
  margin: 0 0 0 8px;
`;
