import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { IColors } from 'types';

import { ActivityIndicator } from '../ActivityIndicator';
import { Title } from '../Title';

export enum ButtonSizes {
  sm = 'sm',
  md = 'md',
}

export interface IButtonStyle {
  type?: 'submit' | 'reset' | 'button';
  small?: boolean;
  disabled?: boolean;
  colors: IColors;
  variant?: 'primary' | 'outline';
  sidePaddings?: number;
  size?: ButtonSizes;
}

export interface IButton extends IButtonStyle {
  children: React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
  loadingSize?: number;
  tooltip?: {
    text: string;
    width?: string;
  };
  className?: string;
}

export const Button = memo(
  ({
    children,
    small = false,
    onClick,
    colors,
    disabled = false,
    loading = false,
    loadingSize = 18,
    type,
    variant = 'primary',
    tooltip,
    sidePaddings = 32,
    className,
    size = ButtonSizes.md,
    ...rest
  }: IButton) => (
    <TitleContainer
      className={className}
      small={small}
      sidePaddings={sidePaddings}
      colors={colors}
      variant={variant}
      disabled={disabled}
      onClick={() => onClick?.()}
      type={type}
      size={size}
      {...rest}
    >
      {tooltip && <TitleWrapper {...tooltip} />}
      {loading ? (
        <ActivityIndicator size={loadingSize} color={colors?.color} />
      ) : (
        children
      )}
    </TitleContainer>
  ),
);

const TitleContainer = styled.button<IButtonStyle>`
  position: relative;
  line-height: 24px;
  font-family: ${getFontFamily('semibold')};
  font-size: 14px;
  font-style: normal;
  line-height: 17px;
  white-space: nowrap;
  box-sizing: border-box;
  min-height: ${({ size }) => (size === ButtonSizes.md ? '48px' : '32px')};
  background-color: ${({ colors, disabled, variant }) => {
    if (!colors) {
      return 'white';
    }

    const { backgroundColor, bgDisabledColor } = colors;
    if (disabled) {
      if (variant === 'outline') {
        return bgDisabledColor || 'white';
      }

      return bgDisabledColor || Colors.DeactivatedGrey;
    }

    if (!disabled && backgroundColor) {
      return backgroundColor;
    }

    return 'white';
  }};
  color: ${({ colors, disabled }) => {
    if (!colors) {
      return 'white';
    }

    const { color, disabledColor } = colors;
    return disabled && disabledColor ? disabledColor : color;
  }};
  width: 100%;
  cursor: pointer;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  height: ${({ small }) => (small ? '30px' : '48px')};
  padding-right: ${({ sidePaddings }) => `${sidePaddings}px`};
  padding-left: ${({ sidePaddings }) => `${sidePaddings}px`};
  border: ${({ colors, disabled, variant }) => {
    if (variant === 'primary') {
      return 'none';
    }

    if (colors) {
      const { color, disabledColor } = colors;
      return `2px solid ${disabled ? disabledColor : color}`;
    }
  }};
  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: 0.8;
    background-color: ${({ colors, variant, disabled }) => {
      if (disabled) {
        return 'default';
      }

      if (variant !== 'outline') {
        return colors?.backgroundColor;
      }

      return colors?.color;
    }};
    color: ${({ colors, variant, disabled }) => {
      if (disabled) {
        return 'default';
      }

      if (variant !== 'outline') {
        return colors?.color;
      }

      return colors?.backgroundColor;
    }};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const TitleWrapper = styled((props) => <Title {...props} />)`
  display: none;

  ${TitleContainer}:hover & {
    display: block;
  }
`;
