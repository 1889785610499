import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';

import { IEditAgreementPopupValues } from 'components/molecules';
import {
  loadAccountsPayables,
  StudentsQuery,
} from 'core/operations/queries/students';
import {
  EditStudentWithAgreementMutationVariables,
  popupData,
  PopupNames,
  useEditStudentWithAgreementMutation,
} from 'core';
import { useParseServerErrors } from 'services/ErrorsService';
import { ErrorsType } from 'types';

type editStudentWithAgreementType = [
  (variables: EditStudentWithAgreementMutationVariables) => void,
  {
    success: boolean;
    loading: boolean;
    errors?: ErrorsType<IEditAgreementPopupValues> | null;
  },
];

export const useEditStudentWithAgreement = (): editStudentWithAgreementType => {
  const studentId =
    useReactiveVar(popupData)[PopupNames.editAgreement]?.studentId || '';
  const [
    editStudentWithAgreementMutation,
    { data, loading },
  ] = useEditStudentWithAgreementMutation({
    onError: (err) => err,
    refetchQueries: [
      {
        query: StudentsQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: loadAccountsPayables,
        variables: { id: studentId },
      },
    ],
  });

  const errors = useParseServerErrors(
    data?.updateStudentWithAgreement?.customErrors,
  );

  const editStudentWithAgreement = useCallback(
    (variables: EditStudentWithAgreementMutationVariables) =>
      editStudentWithAgreementMutation({
        variables,
      }),
    [editStudentWithAgreementMutation],
  );

  return [
    editStudentWithAgreement,
    {
      success: Boolean(data?.updateStudentWithAgreement?.success),
      loading,
      errors,
    },
  ];
};
