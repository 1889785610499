import React, { memo, useCallback } from 'react';

import { PopupNames } from 'core';
import { usePopupStateOperations } from 'core/hooks';

import { StudentWarningPopupComponent } from './StudentWarningPopup';

export const StudentWarningPopup = memo(() => {
  const {
    operations: { showPopup, hidePopup },
  } = usePopupStateOperations();

  const onClose = useCallback(() => hidePopup(PopupNames.StudentWarningPopup), [
    hidePopup,
  ]);

  const onCancel = useCallback(() => showPopup(PopupNames.studentPayment), [
    showPopup,
  ]);

  return <StudentWarningPopupComponent onClose={onClose} onCancel={onCancel} />;
});
