import React, { memo, useCallback, useEffect } from 'react';

import styled from 'styled-components';

import { SearchNames, useSearch, useApplications } from 'core/hooks';

import { ApplicationsTable } from 'components/molecules';

export const Applications = memo(() => {
  const [
    fetchMoreApplications,
    { data: applications, hasMore, after, loading, error },
  ] = useApplications();

  const { searchQuery } = useSearch(SearchNames.teachers);

  const loadMore = useCallback(() => {
    const query = searchQuery.trim();
    fetchMoreApplications({ query, after });
  }, [searchQuery, after, fetchMoreApplications]);

  useEffect(() => {
    const query = searchQuery.trim();
    fetchMoreApplications({ query });
  }, [searchQuery, fetchMoreApplications]);

  return (
    <Wrapper>
      <ApplicationsTable
        data={applications || []}
        loading={loading}
        error={error}
        loadMore={loadMore}
        hasMore={hasMore}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-top: 16px;
`;
