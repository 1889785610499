import { client } from 'core/graphql/client';

export interface ITokens {
  access_token: string;
  refresh_token: string;
}

class AuthorizationService {
  private storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  saveTokens(tokens: ITokens) {
    this.storage.setItem('access_token', tokens.access_token);
    this.storage.setItem('refresh_token', tokens.refresh_token);
  }

  logOut() {
    client.clearStore();
    this.storage.removeItem('access_token');
    this.storage.removeItem('refresh_token');
  }

  getRefreshToken(): string {
    const refreshToken = this.storage.getItem('refresh_token');
    return refreshToken || '';
  }
}

export const authService = new AuthorizationService();
