import { useCallback } from 'react';

import {
  AllTeachersQueryVariables,
  useAllTeachersQuery,
  useGetCoursesForTeacherQuery,
} from 'core';

import { ICourse, ITeacherUser } from 'components/types';

import { transformTeachersNode } from './transform';

type GetTeachersHookType = [
  (vars: AllTeachersQueryVariables) => void,
  {
    data: ITeacherUser[] | null;
    hasMore: boolean;
    after: string;
    loading: boolean;
    error: string | undefined;
  },
];

export const useTeachers = (): GetTeachersHookType => {
  const { data, loading, error, fetchMore } = useAllTeachersQuery({
    fetchPolicy: 'cache-first',
    variables: {
      first: 10,
    },
    notifyOnNetworkStatusChange: true,
  });

  const fetchMoreTeachers = useCallback(
    ({ query, after }: AllTeachersQueryVariables) => {
      fetchMore({
        variables: {
          query,
          after,
        },
      });
    },
    [fetchMore],
  );

  return [
    fetchMoreTeachers,
    {
      data: transformTeachersNode(data) || null,
      hasMore: data?.allTeachers?.pageInfo.hasNextPage || false,
      after: data?.allTeachers?.pageInfo.endCursor || '',
      loading,
      error: error?.message || undefined,
    },
  ];
};

type IGetCoursesForTeacherHookType = {
  data: ICourse[] | undefined;
  loading: boolean;
  error: string | undefined;
};

export const useGetCoursesForTeacher = (
  first?: number,
): IGetCoursesForTeacherHookType => {
  const { data, loading, error } = useGetCoursesForTeacherQuery({
    fetchPolicy: 'network-only',
    variables: {
      first,
    },
  });

  return {
    data: data?.allCourses?.edges.map(
      (course): ICourse => ({
        id: course?.node?.id || '',
        name: course?.node?.name || '',
      }),
    ),
    loading,
    error: error?.message,
  };
};
