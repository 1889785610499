import * as React from 'react';
import { memo } from 'react';
import { Row, useTable, CellProps } from 'react-table';

import styled from 'styled-components';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconsNames,
  IconButton,
} from 'atoms';

import { LoadingData, LoadingTable, InfiniteScrollContainer } from 'molecules';

import { IconSizes } from 'components/types';
import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

type Accessors =
  | 'id'
  | 'group'
  | 'startDate'
  | 'numberOfLessons'
  | 'numberOfStudents'
  | 'lessonDays'
  | 'agreementsSum'
  | 'publishedLms'
  | 'publishedLmsForTeacher'
  | 'publishedLmsForMethodist';

export type IRow = {
  [key in Accessors]: React.ReactNode;
} & {
  publishedLms: boolean;
  publishedLmsForTeacher: boolean;
  publishedLmsForMethodist: boolean;
  id: string;
};

export interface IColumn {
  accessor: Accessors;
  flexible?: boolean;
  align?: 'left' | 'right' | 'center';
  Header?: string | React.ReactElement;
  columns?: IColumn[];
  marginRight?: string;
  marginLeft?: string;
  columnWidth?: string;
  Cell?: string | ((props: CellProps<IRow>) => React.ReactElement);
}

export interface IGroupsTableComponent {
  columns: IColumn[];
  rows: IRow[];
  isFlexible: boolean;
  noDataText: string;
  loadMore: () => void;
  hasMore: boolean;
  onEditButtonClick: (groupId: string) => void;
  loading?: boolean;
}

export const GroupsTableComponent = memo(
  ({
    isFlexible,
    noDataText,
    rows: rowsSource,
    columns,
    loadMore,
    hasMore,
    onEditButtonClick,
    loading,
  }: IGroupsTableComponent) => {
    const {
      getTableProps,
      headers,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data: rowsSource,
    });

    return (
      <Wrapper>
        <InfiniteScrollContainer
          loadMore={loadMore}
          hasMore={hasMore}
          dataLength={rows.length}
          loading={loading || false}
        >
          <Table {...getTableProps()} flexible={isFlexible} rowShadowOnHover>
            <TableHead>
              <TableRow type="row">
                {headers.map((column: any) => {
                  const {
                    key: cellKey,
                    ...restCellProps
                  } = column.getHeaderProps({
                    type: 'headerCell',
                    flexible: column.flexible && isFlexible,
                    width: column.columnWidth,
                    margin: {
                      right: column.marginRight,
                      left: column.marginLeft,
                    },
                  });

                  return (
                    <TableCell key={cellKey} {...restCellProps}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {!rows.length && !loading && (
                <TableRow key="noData">
                  <TableCell type="cell" width="100%" align="center">
                    {noDataText}
                  </TableCell>
                </TableRow>
              )}
              {!rows.length && loading && (
                <TableRow>
                  <TableCell width="100%" align="center">
                    <LoadingTable />
                  </TableCell>
                </TableRow>
              )}
              {Boolean(rows.length) &&
                rows.map((row: Row<IRow>) => {
                  prepareRow(row);
                  const { key: rowKey, ...restRowProps } = row.getRowProps();

                  return (
                    <GroupsTableRow key={rowKey} type="row" {...restRowProps}>
                      {row.cells.map((cell: any) => {
                        const {
                          key: cellKey,
                          ...restCellProps
                        } = cell.getCellProps({
                          width: cell.column.columnWidth,
                          flexible: cell.column.flexible && isFlexible,
                          margin: {
                            right: cell.column.marginRight,
                            left: cell.column.marginLeft,
                          },
                        });

                        return (
                          <TableCell key={cellKey} {...restCellProps}>
                            <GroupNameWrapper>
                              {cell.render('Cell')}
                            </GroupNameWrapper>
                          </TableCell>
                        );
                      })}
                      <RowButtons>
                        <IconButtonStyled
                          size={IconSizes.lg}
                          name={IconsNames.Edit}
                          onClick={() => onEditButtonClick(row.original.id)}
                        />
                      </RowButtons>
                    </GroupsTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </InfiniteScrollContainer>
        {Boolean(rows.length) && loading && <LoadingData />}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 60px;
`;

const GroupNameWrapper = styled.span`
  font-family: ${getFontFamily('medium')};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${Colors.Black};
`;

const GroupsTableRow = styled((props) => (
  <TableRow {...props}>{props.children}</TableRow>
))`
  position: relative;
`;

const RowButtons = styled.td`
  position: absolute;
  top: 12px;
  right: 24px;
  bottom: 12px;
  display: none;
  align-items: center;
  ${GroupsTableRow}:hover & {
    display: flex;
  }
`;

const IconButtonStyled = styled((props) => <IconButton {...props} />)`
  display: flex;
  height: 32px;
  width: 32px;
  border-radius: 100%;

  :hover {
    & svg {
      & > path {
        fill: ${Colors.White};
      }
      & > circle {
        fill: ${Colors.Blue};
      }
    }
  }
`;
