import React, { memo } from 'react';
import Select, { components, ValueType, OptionTypeBase } from 'react-select';

import styled, { css } from 'styled-components';

import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';

export type SelectType = 'primary' | 'default';

export interface ISelectOption<OptionValueType> {
  label: string;
  value: OptionValueType;
  disabled?: boolean;
}

export interface IReactSelect<OptionValueType> extends IReactSelectStyle {
  options?: ISelectOption<OptionValueType>[];
  placeholder?: string;
  defaultValue?: ValueType<OptionTypeBase, false>;
  isDisabled?: boolean;
  value?: ValueType<OptionTypeBase, false>;
  onChange?: (option: ValueType<OptionTypeBase, false>) => void;
  loading?: boolean;
  className?: string;
  name?: string;
}

export interface IReactSelectStyle {
  width?: string;
  hasError?: boolean;
  type?: SelectType;
  styledComponents?: { [key: string]: React.ReactNode };
  hideIndicator?: boolean;
}

export const ReactSelectComponent = <OptionsValueType extends {}>({
  options,
  type,
  hasError,
  width,
  placeholder = '',
  defaultValue,
  isDisabled,
  value,
  onChange,
  loading = false,
  className,
  name,
  styledComponents = {},
  hideIndicator = false,
}: IReactSelect<OptionsValueType>) => (
  <Select
    placeholder={placeholder}
    options={options}
    type={type}
    hasError={hasError}
    width={width}
    defaultValue={defaultValue}
    onChange={onChange}
    isDisabled={isDisabled}
    isOptionDisabled={(option) => option.disabled}
    value={value}
    components={{
      Control,
      Input,
      Placeholder,
      ValueContainer,
      SingleValue,
      Menu,
      MenuList,
      Option,
      DropdownIndicator,
      IndicatorSeparator: () => null,
      ...styledComponents,
    }}
    isLoading={loading}
    className={className}
    name={name}
    hideIndicator={hideIndicator}
  />
);

export const ReactSelect = memo(ReactSelectComponent);

const Control = styled(components.Control)<{
  selectProps: {
    menuIsOpen?: boolean;
    hasError?: boolean;
    type?: SelectType;
    width: string;
  };
}>`
  width: ${({ selectProps: { width } }) => width || '100%'};
  border-radius: 0px !important;
  height: 48px;
  ${({ selectProps: { hasError, type, menuIsOpen, isDisabled } }) => {
    if (hasError) {
      if (type === 'primary') {
        return css`
          background-color: ${Colors.Red} !important;
          border: 1px solid ${Colors.Red} !important;
        `;
      }
      if (isDisabled) {
        return css`
          background-color: ${Colors.LightBlue} !important;
          border: 1px solid ${Colors.Red} !important;
        `;
      }
      return css`
        border: 1px solid ${Colors.Red} !important;
      `;
    }

    if (isDisabled) {
      if (type === 'primary') {
        return css`
          background-color: ${Colors.DeactivatedGrey} !important;
          border: 1px solid ${Colors.DeactivatedGrey} !important;
        `;
      }

      return css`
        background-color: ${Colors.LightBlue} !important;
        border: 1px solid ${Colors.LightGray} !important;
      `;
    }

    if (type) {
      if (type === 'primary') {
        return css`
          background-color: ${Colors.Blue} !important;
          border: 1px solid ${Colors.Blue} !important;
        `;
      }
    }

    return css`
      border: 1px solid ${menuIsOpen ? Colors.Blue : Colors.LightGray} !important;
    `;
  }};
`;

const ValueContainer = styled(components.ValueContainer)`
  padding: 2px 16px !important;
`;

const Input = styled(components.Input)<{
  selectProps: { type: SelectType };
}>`
  font-family: ${getFontFamily()};
  font-size: 14px;
  color: ${({ selectProps: { type } }) =>
    type === 'primary' ? Colors.White : Colors.Black} !important;
`;

const Placeholder = styled(components.Placeholder)<{
  selectProps: { type: SelectType };
}>`
  font-family: ${getFontFamily()};
  font-size: 14px;
  color: ${({ selectProps: { type, isDisabled } }) => {
    if (type === 'primary') {
      return Colors.White;
    }

    if (isDisabled) {
      return Colors.Gray;
    }

    return Colors.Black;
  }} !important;
`;

const SingleValue = styled(components.SingleValue)<{
  selectProps: { type: SelectType };
}>`
  margin: 0 !important;
  font-family: ${getFontFamily()};
  font-size: 14px;
  cursor: pointer;
  color: ${({ selectProps: { type } }) =>
    type === 'primary' ? Colors.White : Colors.Black} !important;
`;

const Menu = styled(components.Menu)`
  background-color: ${Colors.White};
  border-radius: 0 !important;
  margin: 0 !important;
  border: 1px solid ${Colors.LightGray};
`;

const MenuList = styled(components.MenuList)`
  :hover {
    ::-webkit-scrollbar-thumb {
      border-right: 6px white solid;
      border-left: 6px white solid;
      background-clip: padding-box;
      background: ${Colors.MainBackground};
    }
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: transparent;
  }
  ::-webkit-scrollbar {
    width: 16px;
    background-color: ${Colors.White};
  }
`;

const Option = styled(components.Option)<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) =>
    isSelected ? Colors.MainBackground : 'transparent'}!important;
  color: ${({ isDisabled }) =>
    isDisabled ? Colors.DeactivatedGrey : Colors.Black} !important;
  font-family: ${getFontFamily()};
  padding: 8px 16px !important;
  line-height: 24px;
  border-bottom: 1px solid ${Colors.LightGray};
  cursor: ${({ isDisabled }) =>
    isDisabled ? 'not-allowed' : 'default'} !important;
  :last-child {
    border-bottom: none;
  }
  :hover {
    background-color: ${({ isDisabled }) =>
      !isDisabled ? Colors.MainBackground : 'transparent'}!important;
  }
`;

const DropdownIndicator = styled(components.DropdownIndicator)<{
  selectProps: { type: SelectType };
}>`
  ${({ selectProps: { hideIndicator, isDisabled } }) => {
    if (isDisabled && hideIndicator) {
      return css`
        display: none !important;
      `;
    }
  }};

  padding-right: 16px;
  color: ${({ selectProps: { type, isDisabled } }) => {
    if (type === 'primary') {
      return Colors.White;
    }

    if (isDisabled) {
      return Colors.DeactivatedGrey;
    }

    return Colors.Blue;
  }} !important;
`;
