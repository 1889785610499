import * as React from 'react';
import { memo } from 'react';
import styled, { css } from 'styled-components';

import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';

import { CheckBox } from 'atoms';

export interface ICheckboxWithText {
  value?: boolean;
  name?: string;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const CheckboxWithText = memo(
  ({
    value,
    name,
    disabled = false,
    className,
    children,
    onClick,
  }: ICheckboxWithText) => (
    <Wrapper className={className} disabled={disabled} onClick={onClick}>
      <CheckBox checked={value || false} name={name} disabled={disabled} />
      <Text>{children}</Text>
    </Wrapper>
  ),
);

const Wrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  user-select: none;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: default;
        pointer-events: none;

        & > {
          ${Text} {
            color: ${Colors.DeactivatedGrey};
          }
        }
      `;
    }

    return css`
      cursor: pointer;
      pointer-events: auto;
    `;
  }}
`;

const Text = styled.span`
  margin-left: 12px;
  font-family: ${getFontFamily('regular')};
  font-size: 14px;
  line-height: 24px;
  color: ${Colors.Black};
`;
