import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { Icon, IconProps } from 'atoms';

export interface ISettingsTab {
  active?: boolean;
  icon: IconProps;
  value: number;
  name: string;
}

export const SettingsTab = memo(
  ({ active, icon, value, name }: ISettingsTab) => (
    <StyledSettingsTab active={active} icon={icon} value={value} name={name}>
      <IconWrapper>
        <Icon
          name={icon.name}
          height={icon.height}
          width={icon.width}
          color={active ? Colors.White : Colors.Blue}
        />
      </IconWrapper>
      <Counter>{value}</Counter>
      <Name>{name}</Name>
    </StyledSettingsTab>
  ),
);

const IconWrapper = styled.div``;

const Counter = styled.span`
  margin-top: auto;
  font-family: ${getFontFamily('semibold')};
  font-size: 36px;
  line-height: 24px;
`;

const Name = styled.span`
  margin: 8px 0;
  font-family: ${getFontFamily('bold')};
  font-size: 14px;
  line-height: 24px;
`;

const StyledSettingsTab = styled.div<ISettingsTab>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  padding: 23px;
  ${({ active }) => `
    background-color: ${active ? Colors.Blue : Colors.White};
    border-color: ${active ? Colors.Blue : Colors.LightGray};

    & > ${Counter} {
      color: ${active ? Colors.White : Colors.Blue};
    }
  
    & > ${Name} {
      color: ${active ? Colors.White : Colors.Black};
    }
  `};
`;
