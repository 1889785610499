import { useEffect, useState } from 'react';
import { appState } from 'core/state';
import { useRefreshTokenMutation } from 'core/graphql';
import { authService } from 'services/AuthorizationService';
import { useAuthState } from '../State';
import { useGetAppScope } from './useGetAppScope';

export const useRefreshToken = (): boolean => {
  const { bootstrapped } = appState();
  const { appScope } = useGetAppScope();
  const refresh_token: string = authService.getRefreshToken();
  const refreshTokenExists = Boolean(refresh_token);

  const {
    operations: { setLogout, setAccessToken },
  } = useAuthState();

  const [tokenRefreshed, setTokenRefreshed] = useState(false);

  const [refreshToken, { data, error }] = useRefreshTokenMutation({
    variables: {
      refresh_token,
      scope: appScope,
    },
  });

  useEffect(() => {
    if (refreshTokenExists && !bootstrapped) {
      refreshToken();
    }
    if (!refreshTokenExists && !bootstrapped) {
      setLogout();
      setTokenRefreshed(true);
    }
  }, [refreshToken, refreshTokenExists, bootstrapped, setLogout]);

  useEffect(() => {
    if (data && data.refreshToken && !error) {
      authService.saveTokens({
        access_token: data.refreshToken?.token!,
        refresh_token: data.refreshToken?.refreshToken!,
      });
      setAccessToken(data.refreshToken.token!);
    }

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      authService.logOut();
      setLogout();
    }

    setTokenRefreshed(true);
  }, [data, error, setAccessToken, setLogout]);

  return tokenRefreshed;
};
