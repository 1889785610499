import * as React from 'react';

import styled, { keyframes } from 'styled-components';

export interface IActivityIndicator {
  color?: string;
  size?: number;
}

export const ActivityIndicator = ({
  color = '#8e8e93',
  size = 48,
}: IActivityIndicator) => (
  <Spinner color={color} size={size}>
    <Blade />
    <Blade />
    <Blade />
    <Blade />
    <Blade />
    <Blade />
    <Blade />
    <Blade />
    <Blade />
    <Blade />
    <Blade />
    <Blade />
  </Spinner>
);

const spin = keyframes`
    0% {
      opacity: 0.85; }
    50% {
      opacity: 0.25; }
    100% {
      opacity: 0.25; }
`;

const Blade = styled.div`
  position: absolute;
  top: 37%;
  left: 44.5%;
  width: 10%;
  height: 25%;
  border-radius: 50%/20%;
  animation: ${spin} 1s linear infinite;
  will-change: opacity;

  &:nth-child(1) {
    transform: rotate(30deg) translate(0, -150%);
    animation-delay: -1.6666666667s;
  }
  &:nth-child(2) {
    transform: rotate(60deg) translate(0, -150%);
    animation-delay: -1.5833333333s;
  }
  &:nth-child(3) {
    transform: rotate(90deg) translate(0, -150%);
    animation-delay: -1.5s;
  }
  &:nth-child(4) {
    transform: rotate(120deg) translate(0, -150%);
    animation-delay: -1.4166666667s;
  }
  &:nth-child(5) {
    transform: rotate(150deg) translate(0, -150%);
    animation-delay: -1.3333333333s;
  }
  &:nth-child(6) {
    transform: rotate(180deg) translate(0, -150%);
    animation-delay: -1.25s;
  }
  &:nth-child(7) {
    transform: rotate(210deg) translate(0, -150%);
    animation-delay: -1.1666666667s;
  }
  &:nth-child(8) {
    transform: rotate(240deg) translate(0, -150%);
    animation-delay: -1.0833333333s;
  }
  &:nth-child(9) {
    transform: rotate(270deg) translate(0, -150%);
    animation-delay: -1s;
  }
  &:nth-child(10) {
    transform: rotate(300deg) translate(0, -150%);
    animation-delay: -0.9166666667s;
  }
  &:nth-child(11) {
    transform: rotate(330deg) translate(0, -150%);
    animation-delay: -0.8333333333s;
  }
  &:nth-child(12) {
    transform: rotate(360deg) translate(0, -150%);
    animation-delay: -0.75s;
  }
`;

const Spinner = styled.div<{ color: string; size: number }>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  ${Blade} {
    background-color: ${({ color }) => color};
  }
`;
