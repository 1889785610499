import * as React from 'react';
import { memo, useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { IPerformance } from 'types';
import { AdmissionStatus } from 'atoms';

import {
  PerformancesTableComponent,
  IPerformanceRow,
  IPerformanceColumn,
} from './PerformancesTable';

export interface IPerformancesTable {
  data: IPerformance[];
  loading?: boolean;
  error?: string;
  loadMore: () => void;
  hasMore: boolean;
}

const getColumnsSource = (t: TFunction): IPerformanceColumn[] => [
  {
    Header: `${t('table.performances.studentName')}`,
    accessor: 'name',
    flexible: true,
  },
  {
    Header: `${t('table.performances.group')}`,
    accessor: 'group',
    columnWidth: '112px',
    marginRight: '56px',
    marginLeft: '56px',
  },
  {
    Header: `${t('table.performances.passRate')}`,
    accessor: 'passRate',
    columnWidth: '102px',
  },
  {
    Header: `${t('table.performances.courseProgress')}`,
    accessor: 'courseProgress',
    columnWidth: '108px',
    marginRight: '56px',
    marginLeft: '56px',
  },
  {
    Header: `${t('table.performances.attendance')}`,
    accessor: 'attendance',
    columnWidth: '80px',
    marginRight: '56px',
  },
  {
    Header: `${t('table.performances.graduationProject')}`,
    accessor: 'graduationProject',
    columnWidth: '60px',
    marginRight: '56px',
  },
];

export const PerformancesTable = memo(
  ({ data, loading, error, loadMore, hasMore }: IPerformancesTable) => {
    const { t } = useTranslation();
    const columns: IPerformanceColumn[] = useMemo(
      () => [
        ...getColumnsSource(t),
        {
          Header: `${t('table.performances.admissionStatus')}`,
          accessor: 'admissionStatus',
          columnWidth: '130px',
          marginRight: '72px',
          Cell: ({ row }) => (
            <AdmissionStatus status={row.original.admissionStatus} />
          ),
        },
      ],
      [t],
    );
    const rows: IPerformanceRow[] = useMemo(
      () => data.map((student, idx) => ({ ...student, num: idx + 1 })),
      [data],
    );

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <PerformancesTableComponent
        rows={rows}
        columns={columns}
        isFlexible
        noDataText={t('table.performances.noData')}
        loadMore={loadMore}
        hasMore={hasMore}
        loading={loading}
      />
    );
  },
);
