import React, { memo, useCallback, useEffect, useState } from 'react';
import { ValueType, OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Button, Text, Heading, CheckBox, Row, ISelectOption } from 'atoms';
import { PopupContainer, PopupErrors } from 'molecules';

import { Colors } from 'services/ColorService';

import {
  AccountsPayableType,
  ICourseOption,
  OptionsType,
  ErrorsType,
  Currencies,
} from 'types';
import {
  ContractForm,
  PartialLessonsForm,
  CompanyFormContainer,
  PaymentsForm,
  AccountsPayablesHandler,
  IPayments,
} from 'molecules/StudentPaymentPopup';
import { StudentForm } from './StudentForm';
import { ChargebackForm } from './ChargebackForm';

type InputHandler<T> = (value: T, inputName: string) => void;

type PaymentInputHandler<T> = (
  value: T,
  inputName: string,
  index: number,
) => void;

export interface IEditAgreementPopup {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onChangeInput: InputHandler<
    string | boolean | ValueType<OptionTypeBase, false>
  >;
  onChangePayment: PaymentInputHandler<string | Date | null>;
  onClose: () => void;
  onAddPayment: () => void;
  onAddAccountPayable: () => void;
  onChangeAccountPayable: AccountsPayablesHandler<
    ValueType<OptionTypeBase, false>
  >;
  onCloseAccountPayment: (index: number) => void;
  onClosePayment: (index: number) => void;
  onChangeCurrency: (currency: ValueType<OptionTypeBase, false>) => void;
  values: IEditAgreementPopupValues;
  errors?: ErrorsType<IEditAgreementPopupValues> | null;
  accountsPayables?: AccountsPayableType[];
  errorMessage: string | null;
  coursesLoading?: boolean;
  coursesOptions: ICourseOption[];
  companiesOptions: ISelectOption<string>[];
  lessonsOptions: OptionsType;
  countriesOptions: ISelectOption<string>[];
  isGroupStarted: boolean;
  repaid: boolean;
}

export interface IEditAgreementPopupValues {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
  payments: IPayments[];
  agreementTitle: string;
  date: Date | null;
  course: ValueType<OptionTypeBase, false>;
  group: ValueType<OptionTypeBase, false>;
  promocode: string;
  price: string;
  isPaymentByLegal: boolean;
  isVAT: boolean;
  company: ValueType<OptionTypeBase, false>;
  country: ISelectOption<string> | null;
  comment: string;
  currency: ISelectOption<Currencies> | null;
  isNotResident: boolean;
  refundDate: Date | null;
  refundAmount: string;
  isTerminatedAgreement: boolean;
  partialAttendance: boolean;
  accountsPayable: string;
  firstLesson: ValueType<OptionTypeBase, false>;
  lastLesson: ValueType<OptionTypeBase, false>;
  accountsPayables: AccountsPayableType[];
}

export interface IEditAgreementDisabledFields {
  currency: boolean;
  promocode: boolean;
  price: boolean;
}

export const EditAgreementPopupComponent = memo(
  ({
    values,
    errors = {},
    errorMessage,
    coursesLoading = false,
    coursesOptions,
    companiesOptions,
    lessonsOptions,
    countriesOptions,
    isGroupStarted,
    accountsPayables,
    repaid,
    onClose,
    handleSubmit,
    onChangeInput,
    onChangePayment,
    onAddPayment,
    onClosePayment,
    onAddAccountPayable,
    onChangeAccountPayable,
    onCloseAccountPayment,
    onChangeCurrency,
  }: IEditAgreementPopup) => {
    const { t } = useTranslation();
    const [isShowLegalForm, setShowLegalForm] = useState(false);
    const [
      disabledFields,
      setDisabledFields,
    ] = useState<IEditAgreementDisabledFields>({
      currency: false,
      price: false,
      promocode: false,
    });

    useEffect(() => {
      if (values.isTerminatedAgreement) {
        setDisabledFields({
          currency: true,
          price: true,
          promocode: true,
        });
        return;
      }

      if (!values.isTerminatedAgreement) {
        setDisabledFields({
          currency: false,
          price: false,
          promocode: false,
        });

        if (values.payments.find((payment) => payment.isPaid)) {
          setDisabledFields((prevState) => ({ ...prevState, currency: true }));
        }

        if (
          values.payments.length &&
          values.payments.every((payment) => payment.isPaid)
        ) {
          setDisabledFields({
            currency: true,
            price: true,
            promocode: true,
          });
        }
      }
    }, [values.payments, values.isTerminatedAgreement]);

    const changePartialAttendance = useCallback(() => {
      onChangeInput(!values.partialAttendance, 'partialAttendance');

      if (values.partialAttendance) {
        onChangeInput(null, 'firstLesson');
        onChangeInput(null, 'lastLesson');
      }
    }, [onChangeInput, values.partialAttendance]);

    const onChangeTerminated = useCallback(() => {
      if (values.isTerminatedAgreement) {
        onChangeInput('', 'accountsPayable');
        onChangeInput('', 'refundAmount');
        onChangeInput('', 'refundDate');
      }

      onChangeInput(!values.isTerminatedAgreement, 'isTerminatedAgreement');
    }, [onChangeInput, values.isTerminatedAgreement]);

    return (
      <PopupContainer onClose={onClose} title={t('studentPopup.editStudent')}>
        <StudentForm
          values={values}
          errors={errors}
          onChangeInput={onChangeInput}
        />

        <Separator />

        <Heading>{t('agreementPopup.editAgreement')}</Heading>

        <ContractForm
          values={values}
          errors={errors}
          coursesLoading={coursesLoading}
          coursesOptions={coursesOptions}
          isGroupStarted={isGroupStarted}
          onChangeInput={onChangeInput}
          countriesOptions={countriesOptions}
          onChangeCurrency={onChangeCurrency}
          disabledFields={disabledFields}
        />

        <CheckboxRow>
          <WrappedCheckbox
            checked={values.isPaymentByLegal}
            onClick={() =>
              onChangeInput(!values.isPaymentByLegal, 'isPaymentByLegal')
            }
          />
          <Text size="md">{t('agreementPopup.isPaymentByLegal')}</Text>

          <WrappedCheckbox
            right
            disabled={isGroupStarted || values.isTerminatedAgreement}
            checked={values.partialAttendance}
            onClick={changePartialAttendance}
          />
          <Text size="md">{t('agreementPopup.partialAttendance')}</Text>
        </CheckboxRow>

        {values.partialAttendance && (
          <PartialLessonsForm
            values={values}
            errors={errors}
            lessonsOptions={lessonsOptions}
            isGroupStarted={isGroupStarted}
            onChangeInput={onChangeInput}
          />
        )}

        {values.isPaymentByLegal && (
          <CompanyFormContainer
            company={values?.company}
            companyError={Boolean(errors?.company)}
            isShowLegalForm={isShowLegalForm}
            isEdititng
            setShowLegalForm={setShowLegalForm}
            companiesOptions={companiesOptions}
            onChangeCompany={onChangeInput}
          />
        )}

        <CheckboxRow>
          <WrappedCheckbox
            checked={values.isVAT}
            onClick={() => onChangeInput(!values.isVAT, 'isVAT')}
          />
          <Text size="md">{t('agreementPopup.isVat')}</Text>
          <WrappedCheckboxRight
            checked={values.isNotResident}
            onClick={() =>
              onChangeInput(!values.isNotResident, 'isNotResident')
            }
          />
          <Text size="md">{t('agreementPopup.isNotResident')}</Text>
        </CheckboxRow>

        <Separator />

        <PaymentsForm
          values={values}
          errors={errors}
          accountsPayables={accountsPayables}
          onChangePayment={onChangePayment}
          onAddPayment={onAddPayment}
          onClosePayment={onClosePayment}
          onAddAccountPayable={onAddAccountPayable}
          onChangeAccountPayable={onChangeAccountPayable}
          onCloseAccountPayment={onCloseAccountPayment}
        />

        <Separator />

        {values.isTerminatedAgreement && (
          <ChargebackForm
            values={values}
            errors={errors}
            repaid={repaid}
            onChangeInput={onChangeInput}
          />
        )}

        <ButtonsRow>
          <WrappedCheckbox
            checked={values.isTerminatedAgreement}
            onClick={onChangeTerminated}
            disabled={repaid}
          />
          <Text size="md">{t('agreementPopup.isTerminatedAgreement')}</Text>

          <WrapperSendButton
            type="submit"
            onClick={() => handleSubmit()}
            disabled={isShowLegalForm}
            colors={{
              backgroundColor: Colors.Blue,
              color: Colors.White,
            }}
          >
            {t('agreementPopup.button.save')}
          </WrapperSendButton>
        </ButtonsRow>

        <Row>
          <PopupErrors>{errorMessage}</PopupErrors>
        </Row>
      </PopupContainer>
    );
  },
);

const CheckboxRow = styled.div`
  display: flex;
  margin-top: 32px;
`;

const WrappedCheckbox = styled((props) => <CheckBox {...props} />)`
  margin-right: 12px;
  margin-left: ${({ right }) => (right ? '24px' : '0')};
`;

const WrappedCheckboxRight = styled((props) => <WrappedCheckbox {...props} />)`
  margin-left: 16px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${Colors.LightGray};
  margin: 40px -64px;
`;

const WrapperSendButton = styled((props) => <Button {...props} />)`
  margin-left: auto;
  width: 209px;
`;

const ButtonsRow = styled((props) => <Row {...props} />)`
  margin-top: 32px;
`;
