import * as React from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { getFontFamily } from 'services/FontService';
import { IColors } from 'types';
import { Colors } from 'services/ColorService';
import { Icon, IconsNames } from 'atoms';

export interface IMenuItem extends IMenuItemStyle {
  children: string;
  to: string;
}

export interface IMenuItemStyle {
  colors?: IColors;
  isActive?: boolean;
  disabled?: boolean;
  isMulti?: IMenuItem[];
}

export const MenuItem = ({
  to,
  children,
  colors = { defaultColor: '#263446', activeColor: '#88959E' },
  isActive,
  disabled,
  isMulti,
}: IMenuItem) => (
  <StyledMenuItem
    isActive={isActive}
    colors={colors}
    disabled={disabled}
    isMulti={isMulti}
  >
    <StyledLink to={to}>
      {children}
      {isMulti ? (
        <IconWrapper>
          <Icon
            name={IconsNames.ArrowIcon}
            width={10}
            height={6}
            color={Colors.Blue}
          />
        </IconWrapper>
      ) : null}
    </StyledLink>
    {isMulti ? (
      <SubItemList>
        {isMulti.map((item) => (
          <SubItem key={`${children}-${item.children}`}>
            <StyledLink to={item.to}>{item.children}</StyledLink>
          </SubItem>
        ))}
      </SubItemList>
    ) : null}
  </StyledMenuItem>
);

const StyledLink = styled(Link)`
  box-sizing: border-box;
  padding: 8px;
  font-family: ${getFontFamily('bold')};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${Colors.Black};
`;

const SubItemList = styled.ul`
  position: absolute;
  top: calc(100% - 8px);
  right: 0;
  display: flex;
  flex-direction: column;
  min-width: 190px;
  border: 1px solid ${Colors.LightGray};
  box-shadow: 0px 4px 8px ${Colors.LightShadow};
  list-style: none;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s 0.15s, top 0s 0.15s, opacity 0s 0.15s;
`;

const SubItem = styled.li`
  height: 40px;
  background-color: ${Colors.White};

  &:hover {
    background-color ${Colors.MainBackground}
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.LightGray};
  }

  & > ${StyledLink} {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 0 16px;
    font-family: ${getFontFamily('medium')};
    font-weight: normal;
    color: ${Colors.Black};
  }
`;

const IconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  margin: 0 8px 0 12px;
  transform: rotate(-180deg);
  transition: transform 0.25s ease-in-out 0.15s;
`;

const StyledMenuItem = styled.li<IMenuItemStyle>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 1;

  ${({ isMulti }) =>
    isMulti
      ? `border: 1px solid;
       border-color: transparent;
       transition: border-color 0s 0.15s;
    `
      : ``}

  & > ${StyledLink} {
    color: ${({ colors, isActive }) =>
      isActive ? colors?.activeColor : colors?.defaultColor};
    opacity: ${({ disabled }) => (disabled ? '0.1' : '1')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  }

  &:not(:last-child) {
    margin-right: 32px;
  }

  &:hover {
    ${({ isMulti }) =>
      isMulti
        ? `
          border-color: ${Colors.LightGray};
          transition: border-color 0s 0s;

          ${SubItemList} {
            top: calc(100% + 8px);
            opacity: 1;
            visibility: visible;
            transition: visibility 0s 0s, top 0.25s ease-in-out,
              opacity 0.3s ease-in-out;
          }

          ${IconWrapper} {
            transform: rotate(0);
            transition: transform 0.25s ease-in-out;
          }
        `
        : ``}
  }
`;
