import { makeVar } from '@apollo/client';

export interface IAuthState {
  isLoggedIn: boolean | null;
  access_token: string;
}

export const defaultAuthState: IAuthState = Object.freeze({
  isLoggedIn: null,
  access_token: '',
});

export const authStateVar = makeVar(defaultAuthState);
