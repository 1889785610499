import { useCallback, useEffect, useState } from 'react';

import { popupData, PopupNames, usePaymentsVerificationLazyQuery } from 'core';
import { Currencies, IVerifiedPaymentInfo } from 'types';

type VerifyPaymentsHookType = [
  (payments: IVerifiedPaymentInfo[]) => void,
  {
    data: IVerifiedPaymentInfo[];
    loading: boolean;
    error: string | undefined;
  },
];

export const useVerifyPayments = (): VerifyPaymentsHookType => {
  const popupPayments = popupData()[PopupNames.checkPayment]?.payments;

  const [
    verifyPayments,
    { data, loading, error },
  ] = usePaymentsVerificationLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [payments, setPayments] = useState<IVerifiedPaymentInfo[] | null>(null);
  const [verifiedPayments, setVerifiedPayments] = useState<
    IVerifiedPaymentInfo[]
  >([]);

  const getPaymentsInfo = useCallback(
    (defaultPayments: IVerifiedPaymentInfo[]) => {
      setPayments(defaultPayments);

      const contracts = defaultPayments.map(
        (defaultPayment) => defaultPayment.contractNumber,
      );

      verifyPayments({
        variables: {
          contracts,
        },
      });
    },
    [setPayments, verifyPayments],
  );

  useEffect(() => {
    if (popupPayments) {
      getPaymentsInfo(popupPayments);
    }
  }, [popupPayments, getPaymentsInfo]);

  useEffect(() => {
    if (data && payments) {
      const newVerifiedPayments = payments.map(
        (payment): IVerifiedPaymentInfo => {
          const verifiedPayment = data.paymentsVerification?.find(
            (dataPayment) =>
              dataPayment?.agreementTitle === payment.contractNumber,
          );

          if (verifiedPayment) {
            return {
              deferredPaymentId: payment.deferredPaymentId,
              contractId: verifiedPayment.agreement_id || undefined,
              date: payment.date || new Date(),
              username: verifiedPayment?.fullname || '',
              contractNumber: payment.contractNumber || '',
              contractCurrency: (verifiedPayment?.currency as Currencies) || '',
              sum: Number(payment.sum) || 0,
              sumCurrency: (payment.sumCurrency as Currencies) || '',
              parsedUsername: payment.parsedUsername,
              unparsed: payment.unparsed || '',
            };
          }

          return {
            ...payment,
            contractId: undefined,
            username: '',
            contractCurrency: '' as Currencies,
          };
        },
      );

      if (newVerifiedPayments) {
        setVerifiedPayments(newVerifiedPayments);
      }
    }
  }, [data, payments, setVerifiedPayments]);

  return [
    getPaymentsInfo,
    {
      data: verifiedPayments,
      loading,
      error: error?.message || undefined,
    },
  ];
};
