import * as React from 'react';
import {
  components,
  ValueType,
  OptionTypeBase,
  IndicatorProps,
} from 'react-select';

import styled from 'styled-components';

import { TIME_OPTIONS } from 'components/constants/common';
import { Select } from '../Select';
import { Icon, IconsNames } from '../Icon';

export interface ITimePicker {
  onChange: (value: ValueType<OptionTypeBase, false>) => void;
  value?: ValueType<OptionTypeBase, false>;
  hasError?: boolean;
  className?: string;
}

const DropdownIndicator = (props: IndicatorProps<OptionTypeBase, false>) => {
  const { isFocused } = props;

  if (isFocused) {
    return (
      <components.DropdownIndicator {...props}>
        <WrappedIcon name={IconsNames.Clock} width={16} height={16} />
      </components.DropdownIndicator>
    );
  }

  return (
    <components.DropdownIndicator {...props}>
      <WrappedIcon name={IconsNames.ClockPrimary} width={16} height={16} />
    </components.DropdownIndicator>
  );
};

export const TimePicker = ({
  className,
  value,
  hasError,
  onChange,
}: ITimePicker) => (
  <StyledTimePicker
    type="default"
    value={value}
    hasError={hasError}
    className={className}
    options={TIME_OPTIONS}
    onChange={onChange}
    styledComponents={{ DropdownIndicator }}
  />
);

const StyledTimePicker = styled((props) => <Select {...props} />)`
  width: 128px;
`;

const WrappedIcon = styled((props) => <Icon {...props} />)`
  margin: 8px;
`;
