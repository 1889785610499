import * as React from 'react';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, useTable } from 'react-table';
import styled from 'styled-components';

import {
  Table,
  TableBody,
  TableHead,
  IconsNames,
  TableCell,
  TableRow,
  IconButton,
  Title,
  Icon,
} from 'atoms';

import { InfiniteScrollContainer, LoadingData, LoadingTable } from 'molecules';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { IStudentAgreement, IconSizes, Gradients } from 'components/types';

export type StudentsAccessors =
  | 'id'
  | 'name'
  | 'contractTitle'
  | 'phone'
  | 'group'
  | 'budgetAmount'
  | 'paidAmount'
  | 'terminated';

export type IStudentsRow = {
  [key in StudentsAccessors]: React.ReactNode;
};

export interface IStudentsColumn {
  accessor: StudentsAccessors;
  flexible?: boolean;
  align?: 'left' | 'right' | 'center';
  Header?: string | React.ReactElement;
  columns?: IStudentsColumn[];
  marginRight?: string;
  marginLeft?: string;
  columnWidth?: string;
}

export interface IStudentsTableComponent {
  columns: IStudentsColumn[];
  rows: IStudentsRow[];
  isFlexible: boolean;
  noDataText: string;
  hasMore: boolean;
  loading?: boolean;
  loadMore: () => void;
  onClickEdit: (data: IStudentAgreement) => void;
  onClickAddAgreement: (studentId: string) => void;
}

const getCellWrapper = (columnId: StudentsAccessors): FC => {
  switch (columnId) {
    case 'id':
      return CourseTextWrapper;
    case 'name':
      return UsernameWrapper;
    case 'contractTitle':
      return CourseTextWrapper;
    case 'phone':
      return CourseTextWrapper;
    case 'group':
      return CourseTextWrapper;
    case 'budgetAmount':
      return CourseDefaultWrapper;
    case 'paidAmount':
      return CourseDefaultWrapper;
    default:
      return CourseDefaultWrapper;
  }
};

export const DebtorsTableComponent = memo(
  ({
    columns,
    rows: rowsSource,
    isFlexible,
    noDataText,
    hasMore,
    loading,
    loadMore,
    onClickEdit,
    onClickAddAgreement,
  }: IStudentsTableComponent) => {
    const { t } = useTranslation();
    const {
      getTableProps,
      headers,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data: rowsSource,
    });

    return (
      <Wrapper>
        <InfiniteScrollContainer
          loadMore={loadMore}
          hasMore={hasMore}
          dataLength={rows.length}
          loading={loading || false}
        >
          <Table {...getTableProps()} flexible={isFlexible} rowShadowOnHover>
            <TableHead>
              <TableRow type="row">
                {headers.map((column: any) => {
                  const {
                    key: cellKey,
                    ...restCellProps
                  } = column.getHeaderProps({
                    type: 'headerCell',
                    flexible: column.flexible && isFlexible,
                    width: column.columnWidth,
                    margin: {
                      right: column.marginRight,
                      left: column.marginLeft,
                    },
                  });
                  return (
                    <TableCell key={cellKey} {...restCellProps}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {!rows.length && !loading && (
                <TableRow key="noData">
                  <TableCell type="cell" width="100%" align="center">
                    {noDataText}
                  </TableCell>
                </TableRow>
              )}
              {!rows.length && loading && (
                <TableRow>
                  <TableCell width="100%" align="center">
                    <LoadingTable />
                  </TableCell>
                </TableRow>
              )}
              {Boolean(rows.length) &&
                rows.map((row: Row<IStudentsRow>) => {
                  prepareRow(row);
                  const { key: rowKey, ...restRowProps } = row.getRowProps();
                  const studentId = row.original.id || '';

                  return (
                    <WrappedTableRow
                      key={rowKey}
                      terminated={row.original.terminated}
                      {...restRowProps}
                    >
                      {row.cells.map((cell: any) => {
                        const {
                          key: cellKey,
                          ...restCellProps
                        } = cell.getCellProps({
                          width: cell.column.columnWidth,
                          margin: {
                            right: cell.column.marginRight,
                            left: cell.column.marginLeft,
                          },
                          flexible: cell.column.flexible && isFlexible,
                        });

                        const isFio = cell.column.id === 'name';
                        const contractTerminated =
                          row.original.terminated || false;

                        const columnId = cell.column.id;
                        const CellWrapper = getCellWrapper(columnId);
                        return (
                          <TableCell
                            key={cellKey}
                            {...restCellProps}
                            align="left"
                          >
                            <CellWrapper>
                              {cell.render('Cell')}
                              {isFio && contractTerminated && (
                                <ErrorIconWrapper>
                                  <TitleWrapper
                                    text={t(
                                      'table.students.contractTerminated',
                                    )}
                                  />
                                  <Icon
                                    name={IconsNames.Error}
                                    width={16}
                                    height={16}
                                  />
                                </ErrorIconWrapper>
                              )}
                            </CellWrapper>
                          </TableCell>
                        );
                      })}
                      <WrappedOptions>
                        <IconButtonStyled
                          name={IconsNames.Clip}
                          size={IconSizes.lg}
                          onClick={() =>
                            onClickAddAgreement(studentId as string)
                          }
                        />
                        <WrappedEdit>
                          <IconButtonStyled
                            name={IconsNames.Edit}
                            size={IconSizes.lg}
                            onClick={() =>
                              onClickEdit(row.original as IStudentAgreement)
                            }
                          />
                        </WrappedEdit>
                      </WrappedOptions>
                    </WrappedTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </InfiniteScrollContainer>
        {Boolean(rows.length) && loading && <LoadingData />}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 60px;
`;

const CourseDefaultWrapper = styled.span`
  font-family: ${getFontFamily()};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${Colors.Black};
`;

const UsernameWrapper = styled(CourseDefaultWrapper)`
  position: relative;
  width: auto;
`;

const CourseTextWrapper = styled(CourseDefaultWrapper)`
  color: ${Colors.Blue};
`;

const WrappedEdit = styled.div`
  margin-left: 24px;
  height: 32px;
`;

const WrappedTableRow = styled((props) => (
  <TableRow {...props}>{props.children}</TableRow>
))`
  position: relative;
  background: ${({ terminated }) => terminated && Gradients.InactiveRed};
`;

const WrappedOptions = styled.td`
  position: absolute;
  top: 12px;
  right: 24px;
  bottom: 12px;
  display: none;
  align-items: center;
  ${WrappedTableRow}:hover & {
    display: flex;
  }
`;

const IconButtonStyled = styled((props) => <IconButton {...props} />)`
  display: flex;
  height: 32px;
  width: 32px;
  border-radius: 100%;

  :hover {
    & svg {
      & > path {
        fill: ${Colors.White};
      }
      & > circle {
        fill: ${Colors.Blue};
      }
    }
  }
`;

const ErrorIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% + 16px);
  height: 16px;
  width: 16px;
`;

const TitleWrapper = styled((props) => <Title {...props} />)`
  display: none;

  ${ErrorIconWrapper}:hover & {
    display: block;
  }
`;
