import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
  AddApplicationFields,
  IAddApplicationFieldsValues,
  IAddApplicationFields,
  PopupContainer,
  PopupErrors,
} from 'molecules';

import { ErrorsType } from 'types';

import {
  Button,
  CheckboxWithText,
  DateSelect,
  Form,
  InputLabel,
  Title,
} from 'atoms';

import { Colors } from 'services/ColorService';

export interface IEditApplicationPopup extends IAddApplicationFields {
  disabledButton: boolean;
  isCompleted: boolean;
  isGroupStarted: boolean;
  values: IEditApplicationPopupValues;
  errors?: ErrorsType<IEditApplicationPopupValues> | null;
  errorsMessage: string | null;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  deleteAvailable: boolean;
  onDeleteClick: () => void;
  onClose: () => void;
}

export interface IEditApplicationPopupValues
  extends IAddApplicationFieldsValues {
  isTerminated: boolean;
  lastLessonDate?: Date | null;
}

export const EditApplicationPopupComponent = memo(
  ({
    values,
    courses,
    groups,
    servicePlaces,
    currencies,
    teacherTypes,
    errors,
    onInputChange,
    onDeleteClick,
    onClose,
    handleSubmit,
    errorsMessage,
    isCompleted,
    isGroupStarted,
    deleteAvailable,
    disabledButton,
  }: IEditApplicationPopup) => {
    const { t } = useTranslation();

    return (
      <PopupContainer
        onClose={onClose}
        title={t('applicationPopup.editApplication')}
      >
        <FormWrapper onSubmit={handleSubmit}>
          <AddApplicationFields
            values={values}
            courses={courses}
            groups={groups}
            servicePlaces={servicePlaces}
            currencies={currencies}
            teacherTypes={teacherTypes}
            errors={errors}
            onInputChange={onInputChange}
            disableFields={isCompleted}
            isGroupStarted={isGroupStarted}
          />

          {values?.isTerminated && (
            <LastLessonField>
              <InputLabel>
                {t('applicationPopup.fields.lastLessonDate.label')}
              </InputLabel>
              <DateSelect
                value={values?.lastLessonDate || null}
                placeholder={t(
                  'applicationPopup.fields.lastLessonDate.placeholder',
                )}
                onChange={(value) => {
                  onInputChange('lastLessonDate', value);
                }}
                hasError={Boolean(errors?.lastLessonDate)}
              />
            </LastLessonField>
          )}

          <WrapperSendButton>
            <CheckboxWithText
              value={values?.isTerminated}
              onClick={() => {
                onInputChange('isTerminated', !values?.isTerminated);
              }}
              disabled={isCompleted}
            >
              {t('applicationPopup.terminateApplication')}
            </CheckboxWithText>

            {!isCompleted && (
              <ButtonsWrapper>
                <DeleteButton
                  onClick={onDeleteClick}
                  type="button"
                  variant="outline"
                  colors={{
                    backgroundColor: Colors.White,
                    color: Colors.Blue,
                    disabledColor: Colors.DeactivatedGrey,
                  }}
                  disabled={deleteAvailable}
                >
                  {deleteAvailable && (
                    <TitleStyled
                      text={t('applicationPopup.validation.deleteError')}
                    />
                  )}
                  {t('applicationPopup.button.delete')}
                </DeleteButton>
                <SubmitButton
                  type="submit"
                  variant="primary"
                  colors={{
                    color: Colors.White,
                    activeColor: Colors.Blue,
                    backgroundColor: Colors.Blue,
                    bgDisabledColor: Colors.DeactivatedGrey,
                  }}
                  disabled={disabledButton}
                >
                  {t('applicationPopup.button.save')}
                </SubmitButton>
              </ButtonsWrapper>
            )}
          </WrapperSendButton>
          <PopupErrorsStyled>{errorsMessage}</PopupErrorsStyled>
        </FormWrapper>
      </PopupContainer>
    );
  },
);

const FormWrapper = styled(Form)`
  margin-top: 32px;
`;

const SubmitButton = styled(Button)`
  width: 224px;
  margin-left: 16px;
`;

const PopupErrorsStyled = styled(PopupErrors)`
  margin-top: 24px;
`;

const LastLessonField = styled.div`
  margin-top: 18px;
`;

const WrapperSendButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-left: 32px;
`;

const TitleStyled = styled((props) => <Title {...props} />)`
  display: none;
  bottom: calc(100% + 12px);
  width: 275px;
  white-space: initial;
`;

const DeleteButton = styled(Button)`
  width: 127px;
  margin-left: auto;

  &:hover {
    ${TitleStyled} {
      display: block;
    }
  }
`;
