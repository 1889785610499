import { useEffect, useCallback, useReducer } from 'react';
import { useLoginMutation } from 'core';
import i18n from '../../../i18n';

interface IConfirmEmailAction {
  type: 'wrongToken';
}

interface IConfirmEmailErrors {
  token: string;
}

const confirmEmailErrorsReducer = (
  state: IConfirmEmailErrors | null,
  action: IConfirmEmailAction,
) => {
  switch (action.type) {
    case 'wrongToken':
      return {
        ...state,
        token: `${i18n.t('common.wrongToken')}`,
      };
    default:
      return null;
  }
};

type ConfirmEmailHookType = [
  (token: string) => void,
  {
    access_token: string | null;
    refresh_token: string | null;
    loading: boolean;
    isCalled: boolean;
    errors: IConfirmEmailErrors | null;
  },
];

export const useConfirmEmail = (): ConfirmEmailHookType => {
  const result = useLoginMutation({ errorPolicy: 'all' });
  const [confirmEmailFn, { loading, called: isCalled, error }] = result;

  const [errors, dispatch] = useReducer(confirmEmailErrorsReducer, null);

  useEffect(() => {
    if (error) {
      dispatch({ type: 'wrongToken' });
    }
  }, [error, dispatch]);

  const confirmEmail = useCallback(
    (token: string) => confirmEmailFn({ variables: { token } } as any),
    [confirmEmailFn],
  );

  return [
    confirmEmail,
    {
      access_token: '' || null,
      refresh_token: '' || null,
      loading,
      isCalled,
      errors,
    },
  ];
};
