import { gql } from '@apollo/client';

export const PerformancesQuery = gql`
  query PaginatedPerformances(
    $query: String
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    allStudentsAchievement(
      query: $query
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          student {
            user {
              firstName
              lastName
              middleName
            }
          }
          group {
            id
            name
            course {
              educationForm
            }
          }
          attendancesInfo
          courseWorksAverage
          hwSummary
          graduationProjectMark
        }
      }
    }
  }
`;
