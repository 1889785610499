import { useCallback } from 'react';
import { appState } from 'core';
import { authService } from 'services/AuthorizationService';
import { useAuthState } from '../State';

type LogOutHookType = () => void;

export const useLogOut = (): LogOutHookType => {
  const {
    operations: { setLogout },
  } = useAuthState();

  const logOut = useCallback(() => {
    authService.logOut();
    setLogout();
    appState({ ...appState(), bootstrapped: false });
  }, [setLogout]);

  return logOut;
};
