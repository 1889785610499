import { usePlannedYearContributionsLazyQuery } from 'core';

import { IPlannedContribution } from 'components/types';

import { useCallback } from 'react';
import { transformPlannedYearContributions } from './transform';

type GetPlannedYearContributionsHookType = {
  getYearContributions: (year: number) => void;
  data: IPlannedContribution[];
  loading: boolean;
};

export const useGetPlannedYearContributions = (): GetPlannedYearContributionsHookType => {
  const [
    getContributions,
    { data, loading },
  ] = usePlannedYearContributionsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const getYearContributions = useCallback(
    (year: number) => {
      getContributions({
        variables: {
          year,
        },
      });
    },
    [getContributions],
  );

  return {
    getYearContributions,
    data: data ? transformPlannedYearContributions(data) : [],
    loading,
  };
};
