import { CustomErrorType } from 'core/graphql';

import { ServerCodes, ErrorsType, FieldErrorTypes } from 'types';
import { IUserPopupValues } from 'molecules';

import { invalidPhoneError } from 'components/constants';
import i18n from '../../../i18n';

type ICreateUserAction = {
  type: ServerCodes;
};

interface IUsersSettingsErrorAction {
  type: 'notFound' | 'none';
}

export const usersSettingsErrorsReducer = (
  state: string | null,
  action: IUsersSettingsErrorAction,
) => {
  switch (action.type) {
    case 'notFound':
      return `${i18n.t('common.notFound')}`;
    case 'none':
      return null;
    default:
      return null;
  }
};

export const userErrorReducer = (
  state: ErrorsType<IUserPopupValues> | null,
  action: ICreateUserAction,
): ErrorsType<IUserPopupValues> | null => {
  switch (action.type) {
    case ServerCodes.phoneInvalid:
      return invalidPhoneError;
    case ServerCodes.phoneExist:
      return {
        phone: {
          message: `${i18n.t('common.userWithPhoneExist')}`,
          type: FieldErrorTypes.SERVER_ERROR,
        },
      };
    case ServerCodes.emailExist:
      return {
        email: {
          message: `${i18n.t('common.userWithMailExist')}`,
          type: FieldErrorTypes.SERVER_ERROR,
        },
      };
    case ServerCodes.alreadyExist:
      return {
        phone: {
          message: `${i18n.t('common.userHasAnotherRole')}`,
          type: FieldErrorTypes.SERVER_ERROR,
        },
        email: {
          message: `${i18n.t('common.userHasAnotherRole')}`,
          type: FieldErrorTypes.SERVER_ERROR,
        },
      };
    case ServerCodes.userExist:
      return {
        phone: {
          message: `${i18n.t('common.userWithMailAndPhoneExist')}`,
          type: FieldErrorTypes.SERVER_ERROR,
        },
        email: {
          message: `${i18n.t('common.userWithMailAndPhoneExist')}`,
          type: FieldErrorTypes.SERVER_ERROR,
        },
      };
    case ServerCodes.otherAccess:
      return {
        phone: {
          message: `${i18n.t('common.userHasAnotherRole')}`,
          type: FieldErrorTypes.SERVER_ERROR,
        },
        email: {
          message: `${i18n.t('common.userHasAnotherRole')}`,
          type: FieldErrorTypes.SERVER_ERROR,
        },
      };

    default:
      return null;
  }
};

export const getUserErrors = (errors: CustomErrorType[]) => {
  const invalidPhone = errors.some(
    (error) => error.code === 'invalid' && error.field === 'phone',
  );
  const alreadyExist = errors.some(
    (error) => error.code === 'already_exist' && error.field === 'user',
  );
  const uniqueEmail = errors.some(
    (error) => error.code === 'unique' && error.field === 'email',
  );
  const uniquePhone = errors.some(
    (error) => error.code === 'unique' && error.field === 'phone',
  );
  const uniqueSum = Number(uniqueEmail) + Number(uniquePhone);
  const otherAccess = errors.some(
    (error) => error.code === 'other_access' && error.field === '__all__',
  );

  return {
    invalidPhone,
    alreadyExist,
    uniqueEmail,
    uniquePhone,
    uniqueSum,
    otherAccess,
  };
};
