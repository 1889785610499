import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

import { Colors } from 'services/ColorService';

export interface IStoriesGuestTemplate {
  children: React.ReactElement;
}

export const StoriesGuestTemplate = memo(
  ({ children }: IStoriesGuestTemplate) => (
    <Paper>
      <Wrapper>{children}</Wrapper>
    </Paper>
  ),
);

export const Paper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.MainBackground};
`;

const Wrapper = styled.div`
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  box-shadow: 0px 3px 9px rgba(225, 226, 230, 0.48);
`;
