import { ISelectOption } from 'components/atoms';
import { ValueType, OptionTypeBase } from 'react-select';

export enum SortMode {
  None = 0,
  Asc = 1,
  Desc = 2,
}

export enum ValidationTypes {
  None = -1,
  Required = 0,
  Email = 1,
  Date = 2,
  Password = 3,
  ConfirmPassword = 4,
  Names = 5,
  ToggleCheckBoxes = 6,
  PhoneNumbers = 7,
  PaymentData = 8,
}

export enum MenuItems {
  Debtors = 'debtors',
  Finances = 'finances',
  Users = 'users',
  Settings = 'settings',
  Monitoring = 'monitoring',
  Default = 'debtors',
  Students = 'students',
  Courses = 'courses',
  Groups = 'groups',
  Teachers = 'teachers',
  Journals = 'journals',
  Performance = 'performance',
}

export enum PaymentTabs {
  All = 'payments',
  First = 'payment-1',
  Second = 'payment-2',
  Third = 'payment-3',
  Fourth = 'payment-4',
}

export enum LessonDays {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export enum IconSizes {
  xl = '48',
  lg = '32',
  md = '24',
  sm = '16',
}

export interface IColors {
  defaultColor?: string;
  activeColor?: string;
  color?: string;
  backgroundColor?: string;
  bgDisabledColor?: string;
  disabledColor?: string;
}

export interface IDebtor {
  id: string;
  name: string;
  contractId: string;
  phone: string;
  group: string;
  paymentDate: string;
  expiredDays: string;
  amount: string;
  payments: IPayment[];
}

export enum PublishedLmsType {
  teacher = 'teacher',
  student = 'student',
  methodist = 'methodist',
}

export interface IGroup {
  id: string;
  group: string;
  startDate: string;
  publishedLms: boolean;
  publishedLmsForTeacher: boolean;
  publishedLmsForMethodist: boolean;
  publishedLmsType?: PublishedLmsType;
  numberOfLessons: string;
  numberOfStudents: string;
  lessonDays: number[];
  agreementsSum: string;
}

export interface IApplication {
  id: string;
  applicationNumber: string;
  fullName: string;
  groupNumber: string;
  lessonsCount: string;
  teacherType: TeacherEmploymentTypes;
  price: string;
  isTerminated: boolean;
  isCompleted: boolean;
}

export interface IJournal {
  id: string;
  name: string;
  teachersString: string;
  course: string;
}

export interface ILessonForModule {
  id: string;
  name: string;
  teacherName: string;
}

export interface IModule {
  id: string;
  name: string;
  lessons: ILessonForModule[];
}

export interface ILessonsDays {
  id: string;
  weekDay: number;
  startTime: ValueType<OptionTypeBase, false>;
  endTime: ValueType<OptionTypeBase, false>;
}

export interface IEditingJournal {
  id: string;
  comment: string;
  lessonsDays: ILessonsDays[];
}

export interface IEditingGroup {
  id: string;
  lessonsDays: ILessonsDays[];
  name: string;
  price: string;
  currency: ISelectOption<string> | null;
  deleteAvailable?: boolean | null;
  course: ISelectOption<string>;
  startDate: Date;
}

export interface IAttendances {
  [lessonId: string]: {
    id: string;
    attendance: boolean;
  };
}

export interface ILesson {
  id: string;
  date: Date;
  lessonNumber: number | null;
}

export interface IStudent {
  id: string;
  name: string;
  contractTitle: string;
  group: string;
  phone: string;
  budgetAmount: string;
  paidAmount: string;
  terminated: boolean;
}

export interface IAgreement {
  id: string;
  name: string;
  contractTitle: string;
  group: string;
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  budgetAmount: string;
  paidAmount: string;
  terminated: boolean;
  studentId: string;
  agreementId?: string;
}

export interface IStudentForJournal {
  name: string;
  agreementIsActive?: boolean | null;
  attendances: IAttendances;
}

export interface IPayment {
  id: string;
  summ: string;
  factSumm: string;
}

export interface ICourseFull extends ICourse {
  paidFor: string;
  dealSum: string;
  factSum: string;
  month: string;
  year: string;
  students: IStudentFinance[];
}

export interface ICourse {
  id: string;
  name: string;
}

export interface IStudentFinance {
  id: string;
  name: string;
  paidFor: string;
  dealSum: string;
  factSum: string;
}

export interface IFormField<T> {
  title?: string;
  value: T;
  validationType: ValidationTypes;
}

export interface IConfirmPassword {
  password: string;
  confirmPassword: string;
}

export interface IFullInfoUser extends IUser {
  id: string;
  username: string;
  isActive: boolean;
  isStudent: boolean;
  isTeacher: boolean;
  isAccountant: boolean;
  isManager: boolean;
  verified: boolean;
}

export interface IUser {
  pk?: number;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  roles: UserRole[];
}

export interface IUserData {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  roles: UserRole[];
  displayOnSite?: boolean;
}

export interface IPerformance {
  id: string;
  name: string;
  group: string;
  passRate: string;
  courseProgress: string;
  attendance: string;
  graduationProject: string;
  admissionStatus: AdmissionStatusTypes;
}

export enum AdmissionStatusTypes {
  ADMITTED = 'ADMITTED',
  NOT_ADMITTED = 'NOT_ADMITTED',
}

export enum Gradients {
  White = `linear-gradient(
    270deg,
    #ffffff 74.74%,
    rgba(255, 255, 255, 0) 100%
  )`,
  Danger = `linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9)
  ), #F03738`,
  Success = `linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9)
  ), #3CC13B`,
  SuccessLight = `linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9)
  ), #0D53FC`,
  InactiveRed = `linear-gradient(
    0deg,
    rgba(235, 87, 87, 0.1),
    rgba(235, 87, 87, 0.1)
  ), #ffffff`,
}

export enum Currencies {
  BYN = 'BYN',
  EUR = 'EUR',
  USD = 'USD',
  RUB = 'RUB',
  PLN = 'PLN',
}

export enum UserRole {
  STUDENT = 'Student',
  TEACHER = 'Teacher',
  ADMIN = 'Admin',
  ACCOUNTANT = 'AccountManager',
  HRMANAGER = 'HRManager',
  FINANCIER = 'FinanceManager',
  SALESMANAGER = 'SalesManager',
  METHODIST = 'Methodist',
}

export interface IPaymentInfo {
  date: Date;
  contractNumber: string;
  sum: number;
  sumCurrency: Currencies;
  unparsed: string;
  parsedUsername?: string;
}

export interface IVerifiedPaymentInfo extends IPaymentInfo {
  deferredPaymentId?: string;
  contractId?: string;
  contractCurrency?: Currencies;
  username?: string;
}

export interface ITeacherEditableData {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
  agreementId: string;
  agreementTitle: string;
  agreementDate: string;
  courses: ICourse[];
  isDismissed: boolean;
  displayOnSite: boolean;
  canBeChanged: boolean;
  deleteAvailable: boolean;
}

export interface ITeacherUser {
  id: string;
  fullName: string;
  phone: string;
  email: string;
  courses: string;
  isDismissed: boolean;
  accessToLms: boolean;
}

export interface IStudentAgreement {
  id: string;
  studentId: string;
  agreementId?: string;
  name: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  phone: string;
  email: string;
  contractTitle: string;
  budgetAmount: string;
  paidAmount: string;
  group: string;
  terminated: boolean;
}

export interface ICourseOption {
  value: string;
  label: string;
  groups: {
    value: string;
    label: string;
    price: number;
    currency: Currencies | undefined;
  }[];
}

export interface IGroupsOptions {
  value: string;
  label: string;
  lessonsCount: number;
  appointedLessonsCount: number;
}

export interface IApplicationOptions {
  value: string;
  label: string;
  teacherName: string;
  freeLessonsCount: number;
}

export interface IStudentFullNameWithId {
  studentId: string;
  fullName: string;
}

export interface IGroupModuleLessons {
  lessonId: string;
  checked: boolean;
  lessonName: string;
  lessonNumber?: number | null;
  additional?: boolean | null;
  lmsDescription: string;
  teacher: ISelectOption<string> | null;
  isNewLesson?: boolean;
  teacherAttachments?: number[];
  studentAttachments?: number[];
  taskTypes?: string[];
}
export interface IGroupModule {
  id: string;
  name: string;
  showInput: boolean;
  lessons: IGroupModuleLessons[];
  disabledLessons: IGroupModuleLessons[];
}

export type OptionsType = {
  value: string;
  label: string;
  isDisabled?: boolean;
}[];

export type CompanyOptionsType = {
  value: string;
  label: string;
  comment?: string;
}[];

export interface ICreateGroup {
  groupInput: {
    name: string;
    price: number;
    currency: string;
    startDate: string | null;
    course_id: number;
    daysOfClass: {
      weekDay: number;
      startTime?: string;
      endTime?: string;
    }[];
  };
  moduleInput: {
    name: string;
    lessons: {
      name: string;
      lmsDescription: string;
      studentsAttachments: number[];
      teachersAttachments: number[];
      taskTypes: string[];
    }[];
  }[];
}

export type AgreementDataType = {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
  agreementTitle: string;
  date: Date;
  price: string;
  isPaymentByLegal: boolean;
  isVAT: boolean;
  currency: ISelectOption<Currencies>;
  refundCurrency: ValueType<OptionTypeBase, false>;
  group: ISelectOption<string> | null;
  course: ValueType<OptionTypeBase, false>;
  refundAmount: number;
  isTerminatedAgreement: boolean;
  comment: string;
  promocode: string;
  company: ValueType<OptionTypeBase, false>;
  country: ISelectOption<string> | null;
  isResident: boolean;
  refundDate: Date | null;
  partialAttendance: boolean;
  firstLesson: ValueType<OptionTypeBase, false>;
  lastLesson: ValueType<OptionTypeBase, false>;
  isGroupStarted: boolean;
  accountsPayable: string;
  usedPayables: AccountsPayableType[];
  repaid: boolean;
  payments: {
    id: string;
    date: Date;
    sum: number;
    isPaid: boolean;
  }[];
};

export type AccountsPayableType = {
  value: string;
  label: string;
  amount: string;
  currency: ISelectOption<Currencies>;
  repaid?: boolean;
};

export type serverErrorType = {
  message?: string | null;
  code?: string | null;
  field?: string | null;
};

export enum ServerCodes {
  nameExist = 'name_exist',
  requiredName = 'name_required',
  phoneExist = 'phone_exist',
  phoneInvalid = 'phone_invalid',
  emailExist = 'email_exist',
  regNumberExist = 'registration_number_exist',
  userExist = 'user_exist',
  groupExist = 'group_exist',
  alreadyExist = 'already_exist',
  companyExist = 'company_exist',
  teacherExist = 'teacher_exist',
  lessonExist = 'lesson_exist',
  teacherAgreementInactive = 'teacher_agreement_inactive',
  teacherAgreementActive = 'teacher_agreement_active',
  invalidPhone = 'invalid_phone',
  agreementTitleExist = 'agreement_title_exist',
  applicationTitleExist = 'application_title_exist',
  lessonCountExceeds = 'lesson_count_exceeds',
  lessonCountFew = 'few_lessons_count',
  invalidCurrency = 'invalid_currency',
  otherAccess = 'other_access',
  success = 'success',
  none = 'none',
}

export enum ToastTypes {
  success = 'Success',
  info = 'Info',
  warning = 'Warning',
  error = 'Error',
}

export interface ICountry {
  id: string;
  title: string;
}

export enum TeacherTypeLabels {
  LegalEntityType = 'Юр. лицо',
  NaturalPersonType = 'Физ. лицо',
  SelfEmployedType = 'Самозанятый',
  IndividualEntrepreneurType = 'ИП',
}

export enum TeacherEmploymentTypes {
  LegalEntityType = 'Le',
  NaturalPersonType = 'Np',
  SelfEmployedType = 'Se',
  IndividualEntrepreneurType = 'Ie',
}

export interface IToastData {
  type: ToastTypes;
  title: string;
  text?: string;
}

export enum NetworkErrors {
  internalServerError = 500,
  serviceUnavailable = 503,
}

export type CoursesType = {
  id: string;
  name: string;
  educationForm: string;
  categoryName: string;
};

export enum FieldErrorTypes {
  NOT_ERROR = 'notError',
  EMPTY = 'empty',
  VALIDATION = 'validation',
  SERVER_ERROR = 'serverError',
}

export enum ApplicationDeleteAvailable {
  DeleteAvailable = 'delete_available',
  DeleteNotAvailable = 'delete_not_available',
  DeleteWithTeacher = 'delete_with_teacher',
}

export interface IFieldError {
  type?:
    | FieldErrorTypes.NOT_ERROR
    | FieldErrorTypes.EMPTY
    | FieldErrorTypes.VALIDATION
    | FieldErrorTypes.SERVER_ERROR;
  fieldName?: string;
  message?: string;
}

export type ErrorsType<T> = {
  [key in keyof T]?: T[key] extends any[]
    ? ErrorsType<T[key][number]>[]
    : T[key] extends object
    ? ErrorsType<T[key]>
    : IFieldError;
};

export interface IPlannedContribution {
  period: string;
  planByn: number;
  factByn: number;
  planRub: number;
  factRub: number;
  planUsd: number;
  factUsd: number;
  planEur: number;
  factEur: number;
  planPln: number;
  factPln: number;
}

export interface IPromocode {
  id: string;
  code: string;
  discount: number;
  isPriceFixed: boolean;
  amount: {
    [key in Currencies]: number;
  };
}

export enum GlobalToastTypeNames {
  Login,
  UserAdd,
  UserEdit,
  UserRoleAdd,
  UserDelete,
  StudentAgreementAdd,
  StudentAgreementEdit,
  StudentAgreementCompany,
  TeacherAdd,
  TeacherEdit,
  TeacherRoleAdd,
  TeacherAgreementAdd,
  TeacherApplicationEdit,
  TeacherApplicationAdd,
  TeacherApplicationDelete,
  GroupAdd,
  GroupEdit,
  GroupDelete,
  GroupFromLMSAdd,
  GroupFromLMSRemove,
  JournalEdit,
  PaymentsAdd,
  PaymentsDeferredAdd,
  NetworkError,
  NetworkConnection,
  Finances,
  Other,
}
