import { weekDays } from 'components/constants';
import * as React from 'react';
import { Colors } from 'services/ColorService';

import styled, { css } from 'styled-components';

export interface IWeekDaySelect {
  onClick: (index: number) => void;
  hasError?: boolean;
  selected: number | null;
  className?: string;
}

export const WeekDaySelect = ({
  hasError,
  className,
  selected,
  onClick,
}: IWeekDaySelect) => (
  <Container className={className} hasError={hasError || false}>
    {weekDays.map((day, index) => (
      <DayWrapper
        selected={index === selected}
        key={day}
        onClick={() => onClick(index)}
      >
        {day.toUpperCase()}
      </DayWrapper>
    ))}
  </Container>
);

const Container = styled.div<{ hasError: boolean }>`
  display: flex;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  height: 48px;
  width: 350px;

  ${({ hasError }) => {
    if (hasError) {
      return css`
        border-color: ${Colors.Red};
      `;
    }

    return css`
      border-color: ${Colors.LightGray};
    `;
  }}
`;

const DayWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.LightGray};
  width: 50px;
  background-color: ${({ selected }) =>
    selected ? Colors.Blue : Colors.White};
  color: ${({ selected }) => (selected ? Colors.White : Colors.Black)};
`;
