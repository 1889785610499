import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import {
  useLogin,
  useGoToForgotPassword,
  useSortErrors,
  useToasts,
  useGetAppScope,
} from 'core/hooks';
import { ErrorsType, GlobalToastTypeNames, ToastTypes } from 'types';

import {
  YupFieldErrorTypes,
  yupValidation,
} from 'services/YupValidationService';

import { ILoginFormValues, LoginFormComponent } from './LoginForm';

const getLoginPopupSchema = (t: TFunction) =>
  Yup.object().shape({
    email: yupValidation.email(`${t('loginForm.fields.email.label')}`, t),
    password: Yup.string().required({
      type: YupFieldErrorTypes.EMPTY,
      fieldName: `${t('loginForm.fields.password.label')}`,
    }),
  });

export const LoginForm = memo(() => {
  const { t } = useTranslation();
  const [login, { loading, errors: serverErrors }] = useLogin();
  const { appScope } = useGetAppScope();
  const goToForgotPassword = useGoToForgotPassword();

  const { addToast } = useToasts();

  const forgotPasswordOnclick = useCallback(() => {
    goToForgotPassword();
  }, [goToForgotPassword]);

  const onSubmitHandler = useCallback(
    (values: ILoginFormValues) => {
      if (!loading) {
        if (appScope) {
          login({ ...values, scope: appScope });
          return;
        }
        addToast({
          data: {
            title: `${t('loginForm.authorizationError')}`,
            text: `${t('loginForm.scopeNotFound')}`,
            type: ToastTypes.error,
          },
          type: GlobalToastTypeNames.Login,
        });
      }
    },
    [loading, appScope, login, addToast, t],
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getLoginPopupSchema(t),
    onSubmit: onSubmitHandler,
  });

  const handleChange = useCallback(
    (value: string, name: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<ILoginFormValues>();

  useEffect(() => {
    sortErrors(errors as ErrorsType<ILoginFormValues>);
  }, [errors, sortErrors]);

  return (
    <LoginFormComponent
      handleSubmit={handleSubmit}
      forgotPasswordOnClick={forgotPasswordOnclick}
      onChangeInput={handleChange}
      values={values}
      loading={loading}
      errors={sortedErrors || serverErrors}
      errorsMessage={message || serverErrors?.email?.message}
    />
  );
});
