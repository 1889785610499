export const handleFinancialNumberValue = (value: string): string | null => {
  const parsedValue = parseFloat(value);

  if (Number.isNaN(parsedValue)) {
    return null;
  }

  const regExp = new RegExp(/^\d+\.\d{1,2}$/);
  const isInvalidFinancial = !regExp.test(value);

  if (isInvalidFinancial) {
    const isValueInteger = Number.isInteger(parsedValue);
    const resultValue = isValueInteger
      ? parsedValue.toFixed(1)
      : parsedValue.toFixed(2);

    return resultValue;
  }

  return value;
};
