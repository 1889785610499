import * as React from 'react';

import styled, { css } from 'styled-components';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { transFormAlignProp } from 'services/TransformService';
import { IColors } from 'types';

type AlignTypes = 'left' | 'center' | 'right';
type TextType = 'default' | 'error' | 'info' | 'disabled';
type FontType = 'regular' | 'medium' | 'bold' | 'light' | 'semibold' | 'heavy';

export interface IText extends ITextStyle {
  children: React.ReactNode;
  className?: string;
}

interface ITextStyle {
  align?: AlignTypes;
  type?: TextType;
  colors?: IColors;
  size?: 'sm' | 'md' | 'lg';
  weight?: string;
  margin?: string;
  font?: FontType;
}

const getTextColor = (type?: TextType) => {
  switch (type) {
    case 'default':
      return Colors.Black;
    case 'error':
      return Colors.Red;
    case 'info':
      return Colors.Blue;
    case 'disabled':
      return Colors.Gray;
    default:
      return Colors.Black;
  }
};

const getTextFont = (size?: 'sm' | 'md' | 'lg') => {
  switch (size) {
    case 'sm':
      return '12';
    case 'md':
      return '14';
    case 'lg':
      return '16';
    default:
      return '12';
  }
};

const getTextLineHeight = (size?: 'sm' | 'md' | 'lg') => {
  switch (size) {
    case 'sm':
      return '20';
    case 'md':
      return '24';
    case 'lg':
      return '28';
    default:
      return '20';
  }
};

export const Text = styled.p<ITextStyle>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }) =>
    align ? transFormAlignProp(align) : 'flex-start'};
  white-space: normal;
  text-align: ${({ align }) => align || 'left'};
  color: ${({ type }) => (type ? getTextColor(type) : Colors.Black)};
  ${({ colors }) =>
    colors &&
    css`
      color: ${colors?.color || 'initial'};
    `}
  background-color: ${Colors.Transparent};
  font-family: ${({ font }) => getFontFamily(font || 'regular')};
  font-style: normal;
  font-weight: ${({ weight }) => weight || 'normal'};
  margin: ${({ margin }) => margin || 'initial'};
  font-size: ${({ size }) => getTextFont(size)}px;
  line-height: ${({ size }) => getTextLineHeight(size)}px;
`;
