import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Colors } from 'services/ColorService';

import { PopupContainer, PopupErrors } from 'molecules';
import { Button, Form } from 'atoms';

import { ITeacherAddFields, TeacherAddFields } from './TeacherAddFields';

export interface ITeacherAddPopup extends ITeacherAddFields {
  errorMessage?: string | null;
  loading?: boolean;
  disabledButton: boolean;
  className?: string;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onClose: () => void;
}

export const TeacherAddPopupComponent = memo(
  ({
    values,
    errors,
    errorMessage,
    onInputChange,
    handleSubmit,
    onClose,
    loading,
    className,
    disabledButton,
  }: ITeacherAddPopup) => {
    const { t } = useTranslation();
    return (
      <PopupContainer title={t('teacherPopup.addTeacher')} onClose={onClose}>
        <FormWrapper onSubmit={handleSubmit} className={className}>
          <TeacherAddFields
            values={values}
            errors={errors}
            onInputChange={onInputChange}
          />
          <SubmitButton
            type="submit"
            variant="primary"
            colors={{
              color: Colors.White,
              activeColor: Colors.Blue,
              backgroundColor: Colors.Blue,
              bgDisabledColor: Colors.DeactivatedGrey,
            }}
            loading={loading}
            disabled={disabledButton}
          >
            {t('teacherPopup.addTeacherType')}
          </SubmitButton>
        </FormWrapper>
        <PopupErrorsStyled>{errorMessage}</PopupErrorsStyled>
      </PopupContainer>
    );
  },
);

const FormWrapper = styled(Form)`
  margin-top: 32px;
`;

const SubmitButton = styled(Button)`
  width: 258px;
  margin: 32px 0 0 auto;
`;

const PopupErrorsStyled = styled(PopupErrors)`
  margin-top: 24px;
`;
