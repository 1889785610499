import { useSettingsMetaQuery } from 'core/graphql';

type loadMetaType = {
  totalCount: {
    courses: number;
    groups: number;
    journals: number;
  };
};

export const useLoadMetaHook = (): loadMetaType => {
  const { data } = useSettingsMetaQuery({ errorPolicy: 'all' });

  const result = () => {
    if (!data) {
      return { courses: 0, groups: 0, teachers: 0, journals: 0 };
    }

    return {
      courses: data?.metaCourses?.totalCount || 0,
      groups: data?.metaGroups?.totalCount || 0,
      journals: data?.metaGroups?.totalCount || 0,
    };
  };

  return { totalCount: result() };
};
