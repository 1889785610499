import { popupState } from 'core';
import { getKeys } from 'services/TransformService';

export const hideOtherPopUps = (state = popupState) => {
  const popups = { ...state() };

  getKeys(popups).forEach((key) => {
    popups[key] = false;
  });

  return popups;
};
