import { useCallback, useEffect, useReducer } from 'react';

import { DeferredPaymentInput, useAddDeferredPaymentsMutation } from 'core';
import { PaymentsDeferredQuery } from 'core/operations';
import {
  IVerifiedPaymentInfo,
  ServerCodes,
  ErrorsType,
  FieldErrorTypes,
} from 'types';

import { DateService } from 'services/DateService';
import i18n from '../../../../i18n';

type AddDeferredPaymentsHookType = [
  (payments: IVerifiedPaymentInfo[]) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<{}> | null;
  },
];

interface IAddDeferredPaymentErrorsAction {
  type: ServerCodes;
}

const AddDeferredPaymentErrorsReducer = (
  state: ErrorsType<{}> | null,
  action: IAddDeferredPaymentErrorsAction,
): ErrorsType<{}> | null => {
  switch (action.type) {
    case ServerCodes.invalidCurrency:
      return {
        message: `${i18n.t('common.wrongCurrency')}`,
        type: FieldErrorTypes.SERVER_ERROR,
      };
    default:
      return null;
  }
};

export const useAddDeferredPayments = (): AddDeferredPaymentsHookType => {
  const [errors, dispatch] = useReducer(AddDeferredPaymentErrorsReducer, null);
  const [
    addDeferredPaymentsMutation,
    { data, loading },
  ] = useAddDeferredPaymentsMutation({
    refetchQueries: [
      {
        query: PaymentsDeferredQuery,
      },
    ],
  });

  useEffect(() => {
    const customErrors = data?.createDeferredPayments?.customErrors;
    if (customErrors?.length) {
      if (
        customErrors?.some(
          (error) =>
            error?.code === 'invalid_choice' && error.field === 'currency',
        )
      ) {
        dispatch({ type: ServerCodes.invalidCurrency });
        return;
      }
      dispatch({ type: ServerCodes.none });
    }
  }, [data?.createDeferredPayments?.customErrors]);

  const addDeferredPayments = useCallback(
    (payments: IVerifiedPaymentInfo[]) => {
      const deferred: DeferredPaymentInput[] = payments.map(
        (payment: IVerifiedPaymentInfo) => ({
          deferredPaymentId: payment.deferredPaymentId,
          date: DateService.toISO(payment.date),
          studentFullname: payment.username,
          agreementTitle: payment.contractNumber,
          sum: payment.sum,
          currency: payment.sumCurrency,
          userInput: payment.unparsed,
        }),
      );

      addDeferredPaymentsMutation({
        variables: {
          payments: deferred,
        },
      });
    },
    [addDeferredPaymentsMutation],
  );

  return [
    addDeferredPayments,
    {
      success: data?.createDeferredPayments?.success || false,
      loading,
      errors,
    },
  ];
};
