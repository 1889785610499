import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

import { IColors } from 'types';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { transFormAlignProp } from 'services/TransformService';

type AlignTypes = 'left' | 'center' | 'right';

export interface ICounter extends ICounterStyle {
  children: number | string;
  className?: string;
}

export interface ICounterStyle {
  colors?: IColors;
  align?: AlignTypes;
}

export const Counter = memo(
  ({ children, className, colors, align }: ICounter) => (
    <CounterStyled colors={colors} align={align} className={className}>
      {String(children)}
    </CounterStyled>
  ),
);

const CounterStyled = styled.p<ICounterStyle>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }) => transFormAlignProp(align)};
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${({ colors }) => colors?.color || Colors.Blue};
  background-color: ${({ colors }) =>
    colors?.backgroundColor || Colors.Transparent};
  font-family: ${getFontFamily('medium')};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
`;
