import { Maybe } from 'core';

export const transformContributionsNums = (data: Maybe<number>[]): number[] =>
  data.reduce((result: number[], contributionNum) => {
    if (contributionNum) {
      return [...result, contributionNum];
    }

    return result;
  }, []);
