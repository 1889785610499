import { useCallback } from 'react';

import {
  useChangeJournalMutation,
  ChangeJournalMutationVariables,
} from 'core/graphql';
import { EditingJournalQuery, JournalQuery } from 'core/operations';
import { useParseServerErrors } from 'services/ErrorsService';
import { IEditJournalPopupValues } from 'components/molecules/EditJournalPopup';
import { ErrorsType } from 'types';

type UpdateJournalType = [
  (variables: ChangeJournalMutationVariables) => void,
  {
    success: boolean;
    loading: boolean;
    errors?: ErrorsType<IEditJournalPopupValues> | null;
  },
];

export const useUpdateJournalMutation = (): UpdateJournalType => {
  const [changeJournalMutation, { data, loading }] = useChangeJournalMutation({
    onError: (err) => err,
  });

  const serverErrors = useParseServerErrors(data?.updateJournal?.customErrors);

  const updateJournal = useCallback(
    (variables: ChangeJournalMutationVariables) =>
      changeJournalMutation({
        variables,
        refetchQueries: [
          {
            query: JournalQuery,
            variables: {
              groupId: variables.groupId.toString(),
            },
          },
          {
            query: EditingJournalQuery,
            variables: {
              groupId: variables.groupId,
            },
          },
        ],
      }),
    [changeJournalMutation],
  );

  return [
    updateJournal,
    {
      success: Boolean(data?.updateJournal?.success),
      loading,
      errors: serverErrors,
    },
  ];
};
