import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { UserRole } from 'types';

import { Button } from 'atoms';
import { PopupContainer } from 'molecules';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { translateRoleToValue } from 'services/TransformService';

export interface IUserDeletePopupComponent {
  roles: UserRole[];
  loading: boolean;
  onClose: () => void;
  onCancel: () => void;
  onDelete: () => void;
}

export const UserDeletePopupComponent = memo(
  ({
    roles,
    loading,
    onClose,
    onCancel,
    onDelete,
  }: IUserDeletePopupComponent) => {
    const { t } = useTranslation();
    return (
      <PopupContainer title={t('deleteUserPopup.deleteUser')} onClose={onClose}>
        <Text>
          {roles.length > 1
            ? t('deleteUserPopup.youAreGoingToDeleteRoles')
            : t('deleteUserPopup.youAreGoingToDelete')}
          <BoldText>
            {roles.length > 1
              ? roles
                  .filter(
                    (role) =>
                      role !== UserRole.STUDENT && role !== UserRole.TEACHER,
                  )
                  .map((role) => translateRoleToValue(role))
                  .join(', ')
              : translateRoleToValue(roles[0])}
          </BoldText>
          {t('deleteUserPopup.continue')}
        </Text>
        <ButtonsWrapper>
          <CancelButtonWrapper>
            <Button
              onClick={onCancel}
              type="button"
              variant="outline"
              colors={{
                backgroundColor: Colors.White,
                color: Colors.Blue,
              }}
            >
              {t('deleteUserPopup.button.cancel')}
            </Button>
          </CancelButtonWrapper>
          <DeleteButtonWrapper>
            <Button
              onClick={onDelete}
              loading={loading}
              type="button"
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
              }}
            >
              {t('deleteUserPopup.button.yes')}
            </Button>
          </DeleteButtonWrapper>
        </ButtonsWrapper>
      </PopupContainer>
    );
  },
);

const Text = styled.p`
  margin-top: 24px;
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  line-height: 24px;
`;

const BoldText = styled.span`
  font-family: ${getFontFamily('bold')};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
`;

const CancelButtonWrapper = styled.div`
  width: 122px;
`;

const DeleteButtonWrapper = styled.div`
  width: 153px;
  margin-left: 16px;
`;
