import * as React from 'react';
import { memo, useCallback } from 'react';

import styled from 'styled-components';

import { Icon, IconsNames } from 'atoms';

import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';
import { translateRoleToValue } from 'services/TransformService';

export interface IToggleCheckBox {
  value: string;
  active: boolean;
  error: boolean;
  onChange: (value: string) => void;
}

export const ToggleCheckbox = memo(
  ({ value, active, error, onChange }: IToggleCheckBox) => {
    const handleClick = useCallback(() => {
      onChange(value);
    }, [value, onChange]);

    return (
      <ToggleCheckBoxWrapper
        active={active}
        error={error}
        onClick={handleClick}
      >
        <Text active={active}>{translateRoleToValue(value)}</Text>
        <IconWrapper active={active}>
          <Icon name={IconsNames.CloseIcon} height={24} width={24} />
        </IconWrapper>
      </ToggleCheckBoxWrapper>
    );
  },
);

const Text = styled.span<{ active: boolean }>`
  ${({ active }) => {
    if (active) {
      return `
        font-family: ${getFontFamily('semibold')};
        color: ${Colors.White};
      `;
    }

    return `
      font-family: ${getFontFamily('medium')};
      color: ${Colors.Black};
    `;
  }}
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
`;

const IconWrapper = styled.div<{ active: boolean }>`
  ${({ active }) => {
    if (active) {
      return `
        display: block;
      `;
    }

    return `
      display: none;
    `;
  }}

  height: 24px;
  width: 24px;
  margin-left: 16px;
  opacity: 0.74;

  path {
    stroke: ${Colors.White};
  }
`;

const ToggleCheckBoxWrapper = styled.div<{ active: boolean; error: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  padding: 11px 15px;
  border-width: 1px;
  border-style: solid;

  ${({ active, error }) => {
    if (active) {
      return `
        border-color: ${Colors.Blue};
        background-color: ${Colors.Blue};
      `;
    }

    if (error) {
      return `
        border-color: ${Colors.Red};
      `;
    }

    return `
      border-color: ${Colors.LightGray};
      background-color: ${Colors.White};
    `;
  }}

  cursor: pointer;

  &:hover {
    ${IconWrapper} {
      opacity: 1;
    }
  }
`;
