import * as React from 'react';
import { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { DateWithPicker, Icon, IconsNames, Text } from 'components/atoms';

import { ILesson, IStudentForJournal } from 'components/types';

import {
  ChangeAttendanceMutationVariables,
  ChangeDateOfLessonMutationVariables,
} from 'core/graphql';

import { DateService } from 'services/DateService';
import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';

import { JournalTableComponent, IColumn, IRow, Accesors } from './JournalTable';

export interface IJournalTable {
  students: IStudentForJournal[];
  lessons: ILesson[];
  loading?: boolean;
  error?: string;
  hasMore: boolean;
  afterStudent: string;
  fetchMoreStudents: (lessonsId: number[], after?: string) => void;
  updateAttendance: (variables: ChangeAttendanceMutationVariables) => void;
  changeLessonDate: (
    variables: Omit<ChangeDateOfLessonMutationVariables, 'groupId'>,
  ) => void;
}

const TABLE_LESSONS_COUNT = 8;

export const JournalTable = memo(
  ({
    lessons,
    students,
    loading,
    error,
    hasMore,
    afterStudent,
    fetchMoreStudents,
    updateAttendance,
    changeLessonDate,
  }: IJournalTable) => {
    const { t } = useTranslation();
    const currentDate: Date = useMemo(() => new Date(), []);

    const [tableFirstDay, changeTableFirstDay] = useState(() => {
      const lessonsCount = lessons.length;
      const currentLessonIndex = lessons
        .map((lesson) => DateService.isSameOrAfter(lesson.date, currentDate))
        .indexOf(true);

      if (currentLessonIndex < TABLE_LESSONS_COUNT) {
        return 0;
      }

      if (lessonsCount - currentLessonIndex < TABLE_LESSONS_COUNT) {
        return lessonsCount - Math.min(lessonsCount, TABLE_LESSONS_COUNT);
      }

      const firstShownLesson =
        Math.trunc(currentLessonIndex / TABLE_LESSONS_COUNT) *
        TABLE_LESSONS_COUNT;

      return firstShownLesson;
    });

    const [isLessonsChancged, setLessonsChanged] = useState(true);
    const shownLessons = lessons.slice(
      tableFirstDay,
      tableFirstDay + TABLE_LESSONS_COUNT,
    );

    const onClickPrev = useCallback(() => {
      if (tableFirstDay > 0) {
        changeTableFirstDay(Math.max(tableFirstDay - TABLE_LESSONS_COUNT, 0));
        setLessonsChanged(true);
      }
    }, [changeTableFirstDay, tableFirstDay]);

    const onClickNext = useCallback(() => {
      if (tableFirstDay + TABLE_LESSONS_COUNT < lessons.length) {
        changeTableFirstDay(tableFirstDay + TABLE_LESSONS_COUNT);
        setLessonsChanged(true);
      }
    }, [changeTableFirstDay, lessons.length, tableFirstDay]);

    const onSaveLessonDate = useCallback(
      ({ newDate, oldDate }: { newDate: Date; oldDate: Date }) => {
        changeLessonDate({
          newDate: DateService.toISO(newDate),
          oldDate: DateService.toISO(oldDate),
        });
      },
      [changeLessonDate],
    );

    const CourseStartEndDays = shownLessons.length
      ? DateService.dateRange(
          shownLessons[0].date,
          shownLessons[shownLessons.length - 1].date,
        )
      : '';

    const headerDates = useMemo(() => {
      const lessonsToShow = shownLessons.map((lesson, index) => {
        const previousLessonDate =
          lessons[tableFirstDay + index - 1]?.date || new Date();
        const disabledDate = DateService.isAfter(new Date(), previousLessonDate)
          ? new Date()
          : previousLessonDate;

        return {
          Header: (
            <HeaderLessonWrapper value={lesson.date}>
              <LessonNumber
                disabled={DateService.isAfter(
                  new Date(),
                  lesson.date || new Date(),
                )}
              >
                {lesson.lessonNumber ? lesson.lessonNumber : '-'}
              </LessonNumber>
              <DateWithPicker
                key={lesson.id}
                value={lesson.date}
                disabledDate={disabledDate}
                onSave={(newDate) =>
                  onSaveLessonDate({
                    oldDate: lesson.date,
                    newDate,
                  })
                }
              />
            </HeaderLessonWrapper>
          ),
          accessor: (`attendances[${lesson.id}]` as unknown) as Accesors,
          columnWidth: '128px',
        };
      });

      return lessonsToShow;
    }, [lessons, onSaveLessonDate, shownLessons, tableFirstDay]);

    const columns: IColumn[] = useMemo(
      () => [
        {
          Header: lessons.length ? (
            <DateRangeWrapper>
              <IconWrapper onClick={onClickPrev} type="left">
                <Icon
                  name={
                    tableFirstDay > 0
                      ? IconsNames.ArrowIconActive
                      : IconsNames.ArrowIcon
                  }
                  width={16}
                  height={16}
                />
              </IconWrapper>

              <Text size="md" weight="bold">
                {CourseStartEndDays}
              </Text>

              <IconWrapper onClick={onClickNext} type="right">
                <Icon
                  name={
                    tableFirstDay + TABLE_LESSONS_COUNT < lessons.length
                      ? IconsNames.ArrowIconActive
                      : IconsNames.ArrowIcon
                  }
                  width={16}
                  height={16}
                />
              </IconWrapper>
            </DateRangeWrapper>
          ) : (
            ''
          ),
          accessor: Accesors.name,
          columnWidth: '248px',
          paddingRight: '16px',
        },
        ...headerDates,
      ],
      [
        CourseStartEndDays,
        headerDates,
        lessons.length,
        onClickNext,
        onClickPrev,
        tableFirstDay,
      ],
    );

    const rows: IRow[] = useMemo(() => students, [students]);

    const loadMore = useCallback(
      (after = '') => {
        fetchMoreStudents(
          shownLessons.map(({ id }) => +id),
          after,
        );
      },
      [fetchMoreStudents, shownLessons],
    );

    useEffect(() => {
      if (!loading && isLessonsChancged) {
        loadMore();
        setLessonsChanged(false);
      }
    }, [isLessonsChancged, loadMore, loading]);

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <JournalTableComponent
        rows={rows}
        columns={columns}
        isFlexible
        noDataText={t('table.journal.noData')}
        hasMore={hasMore}
        afterStudent={afterStudent}
        loading={loading || false}
        loadMore={loadMore}
        updateAttendance={updateAttendance}
      />
    );
  },
);

const DateRangeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
`;

const HeaderLessonWrapper = styled.div<{ value: Date }>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LessonNumber = styled.span<{ disabled: boolean }>`
  font-family: ${getFontFamily('regular')};
  font-size: 12px;
  line-height: 16px;
  color: ${({ disabled }) =>
    disabled ? Colors.DeactivatedGrey : Colors.Black};
`;

const IconWrapper = styled.span<{
  type: 'left' | 'right';
}>`
  margin-right: ${({ type }) => (type === 'right' ? '24px' : '0')};
  cursor: pointer;
  transform: rotate(${({ type }) => (type === 'left' ? '-90deg' : '90deg')});
`;
