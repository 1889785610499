import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  Input,
  InputLabel,
  TextArea,
  Text,
  Icon,
  IconsNames,
  Title,
  InputPhone,
} from 'components/atoms';
import { PopupErrors } from 'molecules';

import { getFontFamily } from 'services/FontService';
import { ErrorsType } from 'types';

type InputHandler<T> = (value: T, inputName: string) => void;

interface ICompanyForm {
  values: ICompanyFormValues;
  errors?: ErrorsType<ICompanyFormValues> | null;
  errorMessage?: string | null;
  isEdititngCompany: boolean;
  loading: boolean;
  onChangeInput: InputHandler<string>;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

export interface ICompanyFormValues {
  name: string;
  responsiblePerson: string;
  phone: string;
  email: string;
  registrationNumber: string;
  comment: string;
}

export const CompanyForm = memo(
  ({
    values,
    errors,
    errorMessage,
    loading,
    isEdititngCompany,
    onChangeInput,
    handleSubmit,
  }: ICompanyForm) => {
    const { t } = useTranslation();
    if (loading && isEdititngCompany) {
      return <div>Loading...</div>;
    }

    return (
      <>
        <Container onSubmit={handleSubmit}>
          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.name)}>
              {t('companyForm.fields.name.label')}
            </InputLabel>
            <Input
              placeholder={t('companyForm.fields.name.placeholder')}
              type="text"
              value={values.name}
              name="name"
              onChange={({ target: { value } }) => onChangeInput(value, 'name')}
              hasError={Boolean(errors?.name)}
            />
          </WrapperInput>

          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.responsiblePerson)}>
              {t('companyForm.fields.responsiblePerson.label')}
            </InputLabel>
            <Input
              placeholder={t(
                'companyForm.fields.responsiblePerson.placeholder',
              )}
              type="text"
              value={values.responsiblePerson}
              name="responsiblePerson"
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'responsiblePerson')
              }
              hasError={Boolean(errors?.responsiblePerson)}
            />
          </WrapperInput>

          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.phone)}>
              {t('companyForm.fields.phone.label')}
            </InputLabel>
            <InputPhone
              placeholder={t('companyForm.fields.phone.placeholder')}
              value={values.phone}
              name="phone"
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'phone')
              }
              hasError={Boolean(errors?.phone)}
            />
          </WrapperInput>

          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.email)}>
              {t('companyForm.fields.email.label')}
            </InputLabel>
            <Input
              placeholder={t('companyForm.fields.email.placeholder')}
              type="text"
              value={values.email}
              name="email"
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'email')
              }
              hasError={Boolean(errors?.email)}
            />
          </WrapperInput>

          <WrapperInput>
            <InputLabel hasError={Boolean(errors?.registrationNumber)}>
              {t('companyForm.fields.registrationNumber.label')}
            </InputLabel>
            <RegistrationNumberContainer>
              <Input
                placeholder={t(
                  'companyForm.fields.registrationNumber.placeholder',
                )}
                type="text"
                value={values.registrationNumber}
                name="registrationNumber"
                onChange={({ target: { value } }) =>
                  onChangeInput(value, 'registrationNumber')
                }
                disabled={isEdititngCompany}
                hasError={Boolean(errors?.registrationNumber)}
              />

              {isEdititngCompany && (
                <InfoIconContainer>
                  <DisabledRegistrationNumberComment
                    text={t('companyForm.cannotBeEdited')}
                  />

                  <Icon name={IconsNames.InfoGrey} width={16} height={16} />
                </InfoIconContainer>
              )}
            </RegistrationNumberContainer>
          </WrapperInput>
        </Container>

        <WrappedComment size="md">
          {t('companyForm.fields.comment.label')}
        </WrappedComment>
        <TextArea
          name="comment"
          placeholder=""
          value={values.comment}
          rows={3}
          onChange={({ target: { value } }) => onChangeInput(value, 'comment')}
        />

        <PopupErrorsStyled>{errorMessage}</PopupErrorsStyled>
      </>
    );
  },
);

const Container = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  margin-top: 24px;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrappedComment = styled((props) => <Text {...props} />)`
  font-family: ${getFontFamily('bold')};
  margin-top: 24px;
`;

const RegistrationNumberContainer = styled.div`
  position: relative;
`;

const InfoIconContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const DisabledRegistrationNumberComment = styled((props) => (
  <Title {...props} />
))`
  display: none;
  bottom: 40px;

  ${InfoIconContainer}:hover & {
    display: block;
  }
`;

const PopupErrorsStyled = styled((props) => <PopupErrors {...props} />)`
  margin-top: 24px;
`;
