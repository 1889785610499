import { gql } from '@apollo/client';

export const JournalsQuery = gql`
  query PaginatedJournals($query: String, $first: Int, $after: String) {
    allGroups(query: $query, first: $first, after: $after) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          course {
            name
          }
          teachers {
            fullName
          }
        }
      }
    }
  }
`;

export const JournalQuery = gql`
  query PaginatedJournal($groupId: ID!) {
    journal(groupId: $groupId) {
      id
      groupName
      comment
      edges {
        node {
          id
          date
          lessonNumber
        }
      }
    }
  }
`;

export const StudentsForJournalQuery = gql`
  query studentsForJournal(
    $lessonsId: [Int]!
    $first: Int
    $after: String
    $groupId: Int!
  ) {
    studentsForJournal(
      lessonsId: $lessonsId
      first: $first
      after: $after
      groupId: $groupId
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          agreementIsActive
          user {
            id
            firstName
            lastName
            middleName
          }
          attendances {
            edges {
              node {
                id
                attendance
                lesson {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const EditingJournalQuery = gql`
  query loadEditingJournal($groupId: ID!) {
    group(groupId: $groupId) {
      id
      comment
      classesDays {
        id
        weekDay
        startTime
        endTime
      }
      modules {
        id
        name
        lessons {
          edges {
            node {
              id
              name
              lessonNumber
              additional
              date
              lmsDescription
              teacherApplication {
                id
                teacher {
                  fullName
                }
              }
            }
          }
        }
      }
    }
  }
`;
