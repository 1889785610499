import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Button, CloseButton } from 'atoms';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

export interface ICheckedPaymentsControl {
  count: number;
  onValidate: () => void;
  onClose: () => void;
  className?: string;
}

export const CheckedPaymentsControl = memo(
  ({ count, onValidate, onClose, className }: ICheckedPaymentsControl) => {
    const { t } = useTranslation();
    return (
      <Wrapper className={className}>
        <Inner>
          <Counter>
            {t('paymentsControl.choosePayment')} {count}
          </Counter>
          <ButtonValidate
            type="button"
            variant="primary"
            onClick={onValidate}
            colors={{
              backgroundColor: Colors.Blue,
              disabledColor: Colors.DeactivatedGrey,
              color: Colors.White,
            }}
          >
            {t('paymentsControl.check')}
          </ButtonValidate>
          <ButtonClose onClick={onClose} />
        </Inner>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  background-color: ${Colors.White};
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  max-width: 1312px;
  margin: 0 auto;
  padding: 16px 0;
`;

const Counter = styled.p`
  margin: 0;
  font-family: ${getFontFamily('bold')};
  font-size: 12px;
  line-height: 24px;
`;

const ButtonValidate = styled(Button)`
  height: 40px;
  min-height: 40px;
  width: 192px;
  padding: 0 14px;
  margin-left: 24px;
  font-size: 13px;
`;

const ButtonClose = styled(CloseButton)`
  margin-left: auto;
`;
