import * as React from 'react';
import { memo, useCallback } from 'react';
import Select, {
  components,
  OptionTypeBase,
  MultiValueProps,
  OptionProps,
} from 'react-select';
import * as Ramda from 'ramda';

import styled, { css } from 'styled-components';

import { CheckBox, Icon, IconsNames, ISelectOption } from 'atoms';

import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';

export interface ISelectCheckbox<T> extends ISelectCheckboxStyle {
  options?: ISelectOption<T>[];
  value: ISelectOption<T>[] | null;
  placeholder?: string;
  defaultValue?: ISelectOption<string>;
  disabled?: boolean;
  onChange: (values: ISelectOption<string>[] | null) => void;
  className?: string;
  name?: string;
}

export interface ISelectCheckboxStyle {
  hasError?: boolean;
}

export const SelectCheckboxComponent = <T extends {}>({
  options,
  value,
  hasError,
  placeholder = '',
  defaultValue,
  disabled,
  onChange,
  className,
  name,
}: ISelectCheckbox<T>) => {
  const handleChange = useCallback(
    (selectedOptions) => {
      if (selectedOptions?.length < 1) {
        onChange(null);
        return;
      }

      const transformedOptions = selectedOptions?.map(
        (selectedOption: OptionTypeBase): ISelectOption<T> => ({
          label: selectedOption.label,
          value: selectedOption.value,
          disabled: selectedOption.disabled,
        }),
      );

      onChange(transformedOptions);
    },
    [onChange],
  );

  const isOptionSelectedHandler = useCallback(
    (option: ISelectOption<T>, values: ISelectOption<T>[]) => {
      const isSelected = values.find((selectedOption) =>
        Ramda.equals(selectedOption.value, option.value),
      );

      return isSelected;
    },
    [],
  );

  return (
    <SelectStyled
      isMulti
      isClearable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      placeholder={placeholder}
      options={options}
      hasError={hasError}
      defaultValue={defaultValue}
      onChange={handleChange}
      components={{
        Control,
        ValueContainer,
        MultiValue,
        Option,
        Menu,
        MenuList,
        Input,
        Placeholder,
        SingleValue,
        IndicatorsContainer,
        IndicatorSeparator: undefined,
        DropdownIndicator,
      }}
      className={className}
      name={name}
      value={value}
      isDisabled={disabled}
      isSearchable={false}
      backspaceRemovesValue={false}
      isOptionSelected={isOptionSelectedHandler}
    />
  );
};

export const SelectCheckbox = memo(SelectCheckboxComponent);

export const SelectStyled = styled(Select)<{ isDisabled: boolean }>`
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        ${MultiValueSpan} {
          color: ${Colors.Gray};
          opacity: 0.8;
        }

        ${DropdownIcon} {
          fill: ${Colors.DeactivatedGrey};
        }
      `;
    }
  }}
`;

const MultiValue = (props: MultiValueProps<any, any>) => {
  const { data } = props;

  return <MultiValueSpan>{data.label}</MultiValueSpan>;
};

const Option = ({
  data,
  isSelected,
  selectOption,
}: OptionProps<OptionTypeBase, boolean>) => {
  const onClickHandler = useCallback(() => {
    selectOption({ value: data.value, label: data.label });
  }, [selectOption, data]);

  return (
    <OptionWrapper
      onClick={!data.disabled ? onClickHandler : undefined}
      selected={isSelected}
      disabled={data.disabled}
    >
      <CheckBox checked={isSelected} disabled={data.disabled} />
      <OptionTextStyled>{data.label}</OptionTextStyled>
    </OptionWrapper>
  );
};

const IndicatorsContainer = styled(components.IndicatorsContainer)`
  margin-right: 16px;
`;

const DropdownIndicator = () => <DropdownIcon name={IconsNames.ArrowDown} />;

const DropdownIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  fill: ${Colors.Blue};
`;

const Control = styled(components.Control)`
  min-height: 48px !important;
  border: 1px solid ${Colors.LightGray} !important;
  border-radius: 0 !important;
  background-color: ${Colors.White};
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer !important;

  &:hover {
    border-color: ${Colors.Blue} !important;
  }

  ${({ menuIsOpen, selectProps: { hasError }, isDisabled }) => {
    if (hasError) {
      return css`
        border-color: ${Colors.Red} !important;
      `;
    }

    if (menuIsOpen) {
      return css`
        border-color: ${Colors.Blue} !important;
      `;
    }

    if (isDisabled) {
      return css`
        background-color: ${Colors.LightBlue} !important;
      `;
    }
  }}
`;

const ValueContainer = styled(components.ValueContainer)`
  padding: 0 16px !important;
`;

const MultiValueSpan = styled.span`
  display: block;
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  line-height: 24px;

  &:not(:last-of-type):after {
    content: ', ';
    display: inline-block;
    white-space: pre;
  }
`;

const OptionWrapper = styled.div<{ selected?: boolean; disabled?: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;

  ${({ disabled = false }) => {
    if (disabled) {
      return css`
        background-color: ${Colors.LightBlue};
        color: ${Colors.Gray};

        cursor: default;
      `;
    }

    return css`
      cursor: pointer;

      &:hover {
        background-color: ${Colors.MainBackground};
      }
    `;
  }};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${Colors.LightGray};
  }
`;

const OptionTextStyled = styled.span`
  margin-left: 16px;
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  line-height: 24px;
`;

const Menu = styled(components.Menu)`
  margin: 0 !important;
  padding: 8px 0 !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: 0px 4px 8px ${Colors.LightGray} !important;
  background-color: ${Colors.White} !important;
`;

const MenuList = styled(components.MenuList)`
  max-height: 176px !important;
`;

const Input = styled(components.Input)`
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  color: ${Colors.Black};
`;

const Placeholder = styled(components.Placeholder)`
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  color: ${Colors.Black};
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        color: ${Colors.Gray} !important;
      `;
    }
  }};
`;

const SingleValue = styled(components.SingleValue)`
  margin: 0 !important;
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  cursor: pointer;
  color: ${Colors.Black};
`;
