import { useEffect, useMemo } from 'react';
import { IEditingJournal, IGroupModule, IGroupModuleLessons } from 'types';

import { useLoadEditingJournalQuery } from 'core/graphql';
import { TIME_OPTIONS } from 'components/constants';
import { modulesStateVar } from 'core/state/moduleState';
import { DateService } from 'services/DateService';

type EditingJournalHookType = {
  data: IEditingJournal | null;
  loading?: boolean;
  error?: string;
};

export const useLoadEditingJournal = (
  groupId: string,
): EditingJournalHookType => {
  const { data, loading, error } = useLoadEditingJournalQuery({
    variables: { groupId },
  });

  const parsedModules = useMemo(
    () =>
      data?.group?.modules.reduce(
        (modulesArray: { [key: string]: IGroupModule }, module) => {
          const { id: moduleId, name } = module;
          const { lessons, disabledLessons } = module.lessons.edges.reduce(
            (
              lessonsObj: {
                lessons: IGroupModuleLessons[];
                disabledLessons: IGroupModuleLessons[];
              },
              lesson,
            ) => {
              if (!lesson?.node) {
                return lessonsObj;
              }

              if (
                DateService.isAfter(
                  new Date(),
                  DateService.parseDate(lesson.node.date, 'ISO'),
                )
              ) {
                return {
                  disabledLessons: [
                    ...lessonsObj.disabledLessons,
                    {
                      lessonId: lesson.node?.id,
                      lessonName: lesson.node.name,
                      lessonNumber: lesson.node.lessonNumber,
                      additional: lesson.node.additional,
                      lmsDescription: lesson.node.lmsDescription || '',
                      teacher: lesson.node.teacherApplication?.id
                        ? {
                            value: lesson.node.teacherApplication.id,
                            label:
                              lesson.node.teacherApplication.teacher.fullName ||
                              '',
                          }
                        : null,
                      checked: false,
                    },
                  ],
                  lessons: lessonsObj.lessons,
                };
              }

              return {
                disabledLessons: lessonsObj.disabledLessons,
                lessons: [
                  ...lessonsObj.lessons,
                  {
                    lessonId: lesson.node?.id,
                    lessonName: lesson.node.name,
                    lessonNumber: lesson.node.lessonNumber,
                    additional: lesson.node.additional,
                    lmsDescription: lesson.node.lmsDescription || '',
                    teacher: lesson.node.teacherApplication?.id
                      ? {
                          value: lesson.node.teacherApplication.id,
                          label:
                            lesson.node.teacherApplication.teacher.fullName ||
                            '',
                        }
                      : null,
                    checked: false,
                  },
                ],
              };
            },
            { lessons: [], disabledLessons: [] },
          );

          return {
            ...modulesArray,
            [moduleId]: {
              id: moduleId,
              name,
              lessons,
              disabledLessons,
              showInput: false,
            },
          };
        },
        {},
      ) || {},
    [data?.group?.modules],
  );

  const parseData = useMemo((): IEditingJournal | null => {
    if (!data?.group) {
      return null;
    }

    const { id, comment, classesDays } = data.group;

    const lessonsDays = classesDays.map(
      ({ id: lessonDayId, weekDay, startTime, endTime }) => ({
        id: lessonDayId,
        weekDay,
        startTime:
          TIME_OPTIONS.find((option) => option.value === startTime) || null,
        endTime:
          TIME_OPTIONS.find((option) => option.value === endTime) || null,
      }),
    );

    return {
      id,
      comment: comment || '',
      lessonsDays,
    };
  }, [data]);

  useEffect(() => {
    modulesStateVar({ modules: parsedModules });
  }, [parsedModules]);

  return {
    data: parseData,
    loading,
    error: error?.message,
  };
};
