import { useCallback } from 'react';
import { IJournal } from 'types';

import {
  usePaginatedJournalsQuery,
  GroupEdge,
  PaginatedJournalsQueryVariables,
  PageInfo,
} from 'core/graphql';

type JournalsHookType = {
  data: IJournal[];
  loading?: boolean;
  error?: string;
  totalCount?: number | null;
  pageInfo?: Pick<
    PageInfo,
    'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
  >;
  operations: {
    fetchMoreData: (vars: PaginatedJournalsQueryVariables) => void;
  };
};

const toIJournalArray = (edge: GroupEdge): IJournal | undefined => {
  const { node } = edge;
  if (node) {
    return {
      id: node.id,
      name: node.name,
      teachersString:
        node.teachers?.map((teacher) => teacher?.fullName || '').join(', ') ||
        '',
      course: node.course.name,
    };
  }
};

const useGetJournalsFromApi = () => {
  const { data, loading, error, fetchMore } = usePaginatedJournalsQuery({
    variables: {
      first: 10,
    },
  });

  const result = (): IJournal[] => {
    const edges = data?.allGroups?.edges;
    if (!edges) {
      return [];
    }

    return edges.reduce((acc: IJournal[], edge) => {
      if (edge) {
        const journals = toIJournalArray(edge as GroupEdge);
        if (journals) {
          return [...acc, journals];
        }
      }

      return acc;
    }, []);
  };

  return {
    fetchMore,
    result: result(),
    loading,
    error,
    pageInfo: data?.allGroups?.pageInfo,
    totalCount: data?.allGroups?.totalCount,
  };
};

export const useJournals = (): JournalsHookType => {
  const {
    result,
    loading,
    error,
    totalCount,
    fetchMore = () => null,
    pageInfo,
  } = useGetJournalsFromApi();

  const fetchMoreData = useCallback(
    ({ query, after }: PaginatedJournalsQueryVariables) => {
      fetchMore({
        variables: {
          query,
          after,
        },
      });
    },
    [fetchMore],
  );

  return {
    data: result || [],
    loading,
    pageInfo,
    error: error?.message,
    totalCount,
    operations: {
      fetchMoreData,
    },
  };
};
