import * as React from 'react';
import { memo, useCallback } from 'react';

import styled, { keyframes } from 'styled-components';

import { useToasts } from 'core/hooks';

import { IAppGlobalToast } from 'core';
import { ToastComponent } from './Toast';

const enterAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const leaveAnimation = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Toast = memo(
  ({ id, data: { type, title, text } }: IAppGlobalToast) => {
    const { removeToast } = useToasts();
    const onAnimationEnd = useCallback(() => {
      removeToast(id);
    }, [id, removeToast]);

    return (
      <Wrapper
        onClick={() => removeToast(id)}
        onAnimationEnd={(e) =>
          Boolean(e.animationName === leaveAnimation.getName()) &&
          onAnimationEnd()
        }
      >
        <ToastComponentStyled type={type} title={title} text={text} />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;

  &:not(:first-of-type) {
    margin-top: 20px;
  }

  cursor: pointer;
  pointer-events: auto;
  animation: ${leaveAnimation} 0.6s ease-in 3.4s,
    ${enterAnimation} 0.4s ease-out forwards;
`;

const ToastComponentStyled = styled(ToastComponent)`
  width: 100%;
`;
