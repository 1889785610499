import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { PopupNames } from 'core';
import {
  useAddDeferredPayments,
  useAddPayments,
  usePopupStateOperations,
  useSortErrors,
  useUnparsedPayments,
  useVerifyPayments,
  useToasts,
} from 'core/hooks';
import { ToastTypes, ErrorsType, GlobalToastTypeNames } from 'types';

import { parseDebtorsInfo } from 'services/ParsingService';

import { IPaymentCheckTableValues } from '../PaymentCheckTable/PaymentCheckTableContainer';
import { PaymentCheckPopupComponent } from './PaymentCheckPopup';

const initialValues: IPaymentCheckTableValues = {
  payments: [],
};

enum PaymentTypes {
  all = 'all',
  payment = 'payment',
  deferredPayment = 'deferredPayment',
}

export const PaymentCheckPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [addPaymentsAvailable, setAddPaymentsStatus] = useState<boolean>(true);
  const [showCheck, setCheckState] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<PaymentTypes | null>(null);

  const {
    operations: { hidePopup },
  } = usePopupStateOperations();

  const [clearAddPaymentPopupData] = useUnparsedPayments();

  const [
    addPayments,
    {
      success: paymentsAddSuccess,
      loading: paymentsAddLoading,
      error: paymentsAddError,
    },
  ] = useAddPayments();

  const [
    addDeferredPayments,
    {
      success: deferredPaymentsAddSuccess,
      loading: deferredPaymentsAddLoading,
      errors: deferredPaymentsAddErrors,
    },
  ] = useAddDeferredPayments();

  const [
    verifyPayments,
    { data: verifiedPayments, loading: verifyLoading },
  ] = useVerifyPayments();

  const onClose = useCallback(() => {
    clearAddPaymentPopupData();
    hidePopup(PopupNames.checkPayment);
  }, [hidePopup, clearAddPaymentPopupData]);

  const onSubmit = useCallback(
    (values: IPaymentCheckTableValues) => {
      const deferredPaymentsToAdd = values.payments.filter(
        (payment) =>
          (payment.differentCurrency && !payment.acceptCurrencies) ||
          payment.contractNotFound,
      );
      const paymentsToAdd = values.payments.filter(
        (payment) =>
          (!payment.differentCurrency ||
            (payment.differentCurrency && payment.acceptCurrencies)) &&
          !payment.contractNotFound,
      );

      if (deferredPaymentsToAdd.length) {
        addDeferredPayments(deferredPaymentsToAdd);
      }

      if (paymentsToAdd.length) {
        addPayments(paymentsToAdd);
      }

      switch (true) {
        case Boolean(paymentsToAdd.length && deferredPaymentsToAdd.length):
          return setPaymentType(PaymentTypes.all);
        case Boolean(deferredPaymentsToAdd.length):
          return setPaymentType(PaymentTypes.deferredPayment);
        case Boolean(paymentsToAdd.length):
          return setPaymentType(PaymentTypes.payment);
        default:
          return setPaymentType(null);
      }
    },
    [addPayments, addDeferredPayments],
  );

  const { values, errors, handleSubmit, setFieldValue, setValues } = useFormik({
    initialValues,
    enableReinitialize: false,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit,
  });

  const handleChange = useCallback(
    (name: string, value: string | boolean, disableSubmit: boolean = true) => {
      setFieldValue(name, value);
      if (disableSubmit) {
        setAddPaymentsStatus(false);
        setCheckState(true);
      }
    },
    [setFieldValue],
  );

  const onUpdatePaymentsClick = useCallback(() => {
    verifyPayments(values.payments);
  }, [values, verifyPayments]);

  useEffect(() => {
    if (!(paymentsAddLoading && deferredPaymentsAddLoading)) {
      if (paymentsAddSuccess) {
        addToast({
          data: {
            title: `${t('paymentPopup.paymentAddSuccess')}`,
            type: ToastTypes.success,
          },
          type: GlobalToastTypeNames.PaymentsAdd,
        });
      }

      if (deferredPaymentsAddSuccess) {
        addToast({
          data: {
            title: `${t('paymentPopup.paymentDeferredAddSuccess')}`,
            type: ToastTypes.success,
          },
          type: GlobalToastTypeNames.PaymentsDeferredAdd,
        });
      }
    }
  }, [
    paymentsAddSuccess,
    paymentsAddLoading,
    deferredPaymentsAddSuccess,
    deferredPaymentsAddLoading,
    clearAddPaymentPopupData,
    hidePopup,
    addToast,
    t,
  ]);

  useEffect(() => {
    if (paymentType === PaymentTypes.all) {
      if (paymentsAddSuccess && deferredPaymentsAddSuccess) {
        onClose();
      }
    }

    if (paymentType === PaymentTypes.payment) {
      if (paymentsAddSuccess) {
        onClose();
      }
    }

    if (paymentType === PaymentTypes.deferredPayment) {
      if (deferredPaymentsAddSuccess) {
        onClose();
      }
    }
  }, [
    paymentType,
    paymentsAddSuccess,
    paymentsAddLoading,
    deferredPaymentsAddSuccess,
    deferredPaymentsAddLoading,
    clearAddPaymentPopupData,
    hidePopup,
    onClose,
  ]);

  useEffect(() => {
    if (paymentsAddError) {
      addToast({
        data: {
          title: `${t('paymentPopup.paymentAddError')}`,
          text: paymentsAddError,
          type: ToastTypes.error,
        },
        type: GlobalToastTypeNames.PaymentsAdd,
      });
    }
  }, [paymentsAddError, addToast, t]);

  useEffect(() => {
    if (verifiedPayments && !verifyLoading) {
      const newPayments = verifiedPayments.map((verifiedPayment) => {
        const { parsedUsername } = parseDebtorsInfo(
          verifiedPayment.unparsed,
          verifiedPayment.date,
        )[0];

        return {
          ...verifiedPayment,
          acceptCurrencies: false,
          contractNotFound: !verifiedPayment.contractId,
          differentUsername: parsedUsername !== verifiedPayment.username,
          differentCurrency:
            verifiedPayment.contractCurrency !== verifiedPayment.sumCurrency,
          parsedUsername,
          wasEdited: false,
        };
      });

      if (newPayments.length) {
        setValues((previousValues) => ({
          payments: newPayments.map((payment, index) => ({
            ...payment,
            acceptCurrencies: Boolean(
              previousValues.payments[index]?.acceptCurrencies &&
                !previousValues.payments[index].wasEdited,
            ),
          })),
        }));

        setAddPaymentsStatus(true);
      }
    }
  }, [verifyLoading, verifiedPayments, setValues]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setCheckState(true);
      return;
    }

    if (addPaymentsAvailable) {
      setCheckState(false);
    }
  }, [errors, addPaymentsAvailable, setCheckState]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IPaymentCheckTableValues>();

  useEffect(() => {
    sortErrors({
      ...(errors as ErrorsType<IPaymentCheckTableValues>),
      ...deferredPaymentsAddErrors,
    });
  }, [errors, deferredPaymentsAddErrors, sortErrors]);

  return (
    <PaymentCheckPopupComponent
      values={values}
      errors={sortedErrors}
      errorMessage={message}
      onClose={onClose}
      isAddPaymentsAvailable={addPaymentsAvailable}
      showCheck={showCheck}
      onUpdateClick={onUpdatePaymentsClick}
      onInputChange={handleChange}
      loading={
        paymentsAddLoading || deferredPaymentsAddLoading || verifyLoading
      }
      handleSubmit={handleSubmit}
    />
  );
});
