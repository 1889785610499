import {
  FieldErrorTypes,
  IConfirmPassword,
  IFieldError,
  ValidationTypes,
} from 'types';
import i18n from '../i18n';

// eslint-disable-next-line no-useless-escape, no-empty-character-class
export const _emailRegExp = /^([-!#-\'*+\/-9=?A-Z^-~]{1,64}(\.[-!#-\'*+\/-9=?A-Z^-~]{1,64})*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/;
export const _dateRegExP = /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/;
export const _passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{0,}$/;
export const _namesRegExp = /^[ ,.'-]*[a-zа-яёЁ][a-zа-яёЁ ,.'-]*$/i;
export const _groupNumberRegExp = /^[a-z0-9–/-]+$/i;
export const _phoneNumbersRegExp = /^[\d \-+()]+$/i;
export const _paymentCopyPast = /.+\/.+\/.+\/.+/g;
export const _agreementTitleRegExp = /^[^/]+$/;

const REQUIRED_FIELD = `${i18n.t('input.validation.fieldIsRequired')}`;
export const WRONG_EMAIL = `${i18n.t('input.validation.email.invalid')}`;
const WRONG_PASSWORD_FORMAT = `${i18n.t(
  'input.validation.password.wrongFormat',
)}`;
const WRONG_PASSWORD_LENGTH = `${i18n.t(
  'input.validation.password.wrongLength',
)}`;
const PASSWORDS_NOT_SAME = `${i18n.t('input.validation.password.dontMatch')}`;
export const WRONG_DATE_FORMAT = `${i18n.t(
  'input.validation.date.wrongFormat',
)}`;
export const WRONG_SYMBOLS = `${i18n.t('input.validation.wrongSymbols')}`;
export const WRONG_FORMAT = `${i18n.t('input.validation.wrongFormat')}`;

class ValidationService {
  private validateRequired<T>(field: T): IFieldError {
    if (!field) {
      return { type: FieldErrorTypes.EMPTY, message: REQUIRED_FIELD };
    }

    return { type: FieldErrorTypes.NOT_ERROR };
  }

  private validateEmail<T>(email: T): IFieldError {
    if (typeof email === 'string') {
      if (!email) {
        return { type: FieldErrorTypes.EMPTY, message: REQUIRED_FIELD };
      }
      if (!_emailRegExp.test(email)) {
        return { type: FieldErrorTypes.VALIDATION, message: WRONG_EMAIL };
      }

      return { type: FieldErrorTypes.NOT_ERROR };
    }

    return { type: FieldErrorTypes.VALIDATION, message: WRONG_EMAIL };
  }

  private validateDate<T>(date: T): IFieldError {
    if (typeof date === 'string') {
      if (!date) {
        return { type: FieldErrorTypes.EMPTY, message: REQUIRED_FIELD };
      }
      if (!_dateRegExP.test(date)) {
        return { type: FieldErrorTypes.VALIDATION, message: WRONG_DATE_FORMAT };
      }

      return { type: FieldErrorTypes.NOT_ERROR };
    }

    return { type: FieldErrorTypes.VALIDATION, message: WRONG_DATE_FORMAT };
  }

  private validatePassword<T>(password: T): IFieldError {
    if (typeof password === 'string') {
      if (!password) {
        return { type: FieldErrorTypes.EMPTY, message: REQUIRED_FIELD };
      }
      if (!_passwordRegExp.test(password)) {
        return {
          type: FieldErrorTypes.VALIDATION,
          message: WRONG_PASSWORD_FORMAT,
        };
      }
      if (password.length < 8) {
        return {
          type: FieldErrorTypes.VALIDATION,
          message: WRONG_PASSWORD_LENGTH,
        };
      }
      return { type: FieldErrorTypes.NOT_ERROR };
    }

    return { type: FieldErrorTypes.VALIDATION, message: WRONG_PASSWORD_FORMAT };
  }

  private validateConfirmPassword<T>(passwords: T): IFieldError {
    const isConfirmPassword = (
      p: IConfirmPassword | T,
    ): p is IConfirmPassword => (p as IConfirmPassword).password !== undefined;

    if (isConfirmPassword(passwords)) {
      const { password, confirmPassword } = passwords;
      if (!confirmPassword) {
        return { type: FieldErrorTypes.EMPTY, message: REQUIRED_FIELD };
      }
      if (password !== confirmPassword) {
        return {
          type: FieldErrorTypes.VALIDATION,
          message: PASSWORDS_NOT_SAME,
        };
      }
      return { type: FieldErrorTypes.NOT_ERROR };
    }

    return { type: FieldErrorTypes.EMPTY, message: REQUIRED_FIELD };
  }

  private validatePhoneNumbers<T>(phoneNumber: T): IFieldError {
    if (typeof phoneNumber === 'string') {
      if (!phoneNumber) {
        return { type: FieldErrorTypes.EMPTY, message: REQUIRED_FIELD };
      }

      if (!_phoneNumbersRegExp.test(phoneNumber)) {
        return { type: FieldErrorTypes.VALIDATION, message: WRONG_SYMBOLS };
      }
    }

    return { type: FieldErrorTypes.NOT_ERROR };
  }

  private validateNames<T>(name: T): IFieldError {
    if (typeof name === 'string') {
      if (!name) {
        return { type: FieldErrorTypes.EMPTY, message: REQUIRED_FIELD };
      }

      if (!_namesRegExp.test(name)) {
        return { type: FieldErrorTypes.VALIDATION, message: WRONG_SYMBOLS };
      }
    }

    return { type: FieldErrorTypes.NOT_ERROR };
  }

  private validateToggleCheckBoxes<T>(valueList: T): IFieldError {
    if (Object.values(valueList).some((checkBoxValue) => checkBoxValue)) {
      return { type: FieldErrorTypes.NOT_ERROR };
    }

    return { type: FieldErrorTypes.EMPTY, message: REQUIRED_FIELD };
  }

  private validateFieldError<T>(
    type: ValidationTypes,
    value: T,
  ): IFieldError | null {
    switch (type) {
      case ValidationTypes.None:
        return null;
      case ValidationTypes.Required:
        return this.validateRequired<T>(value);
      case ValidationTypes.Email:
        return this.validateEmail<T>(value);
      case ValidationTypes.Date:
        return this.validateDate<T>(value);
      case ValidationTypes.Password:
        return this.validatePassword<T>(value);
      case ValidationTypes.ConfirmPassword:
        return this.validateConfirmPassword<T>(value);
      case ValidationTypes.PhoneNumbers:
        return this.validatePhoneNumbers<T>(value);
      case ValidationTypes.Names:
        return this.validateNames<T>(value);
      case ValidationTypes.ToggleCheckBoxes:
        return this.validateToggleCheckBoxes<T>(value);
      default:
        return null;
    }
  }

  public validateField<T>(field: {
    type: ValidationTypes;
    value: T;
    title?: string;
  }): IFieldError | null {
    const fieldError = this.validateFieldError(field.type, field.value);
    if (fieldError) {
      fieldError.fieldName = field.title;
      if (fieldError.type !== FieldErrorTypes.NOT_ERROR) {
        return fieldError;
      }
    }
    return null;
  }
}

export const validationService = new ValidationService();
