import React, { memo, useEffect, useLayoutEffect } from 'react';

import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';

import {
  ConfirmEmail,
  CreatePassword,
  ErrorPage,
  RecoveryPassword,
  Splash,
} from 'pages';
import { GlobalModal, GlobalToast } from 'molecules';
import { appState } from 'core';

import { useAuthState, useBootstrapHook } from 'core/hooks';

import { AuthRouter } from './AuthRouter';
import { getAuthPath, PATHS } from './config';
import { AppRouter } from './AppRouter';

export const RootRouter = memo(() => {
  const {
    tokenRefreshed,
    data: { appState: appStateData, user },
    operations: { setBootstrapped },
  } = useBootstrapHook();
  const { data: authState } = useAuthState();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (
      tokenRefreshed &&
      !appStateData.bootstrapped &&
      authState.isLoggedIn !== null &&
      user !== null
    ) {
      setBootstrapped();
    }
  }, [
    appStateData,
    authState.isLoggedIn,
    setBootstrapped,
    tokenRefreshed,
    user,
  ]);
  const history = useHistory();
  const currentState = appState();

  useEffect(() => {
    if (appStateData.networkError) {
      history.push(`/${appStateData.networkError}`);
      appState({
        ...currentState,
        networkError: null,
      });
    }
  }, [history, appStateData.networkError, currentState]);

  return appStateData.bootstrapped ? (
    <>
      <Route
        exact
        path="/500"
        render={() => (
          <ErrorPage status={500} isLoggedIn={authState.isLoggedIn} />
        )}
      />
      <Route
        exact
        path="/503"
        render={() => (
          <ErrorPage status={503} isLoggedIn={authState.isLoggedIn} />
        )}
      />
      <Route
        render={() => {
          if (appStateData.bootstrapped) {
            if (pathname.includes(getAuthPath(PATHS.create_password))) {
              return <CreatePassword />;
            }

            if (pathname.includes(getAuthPath(PATHS.confirm_email))) {
              return (
                <ConfirmEmail
                  to={getAuthPath(PATHS.create_password) as PATHS}
                />
              );
            }

            if (authState.isLoggedIn) {
              return <AppRouter />;
            }

            if (pathname.includes(getAuthPath(PATHS.confirm_email))) {
              return (
                <ConfirmEmail
                  to={getAuthPath(PATHS.create_password) as PATHS}
                />
              );
            }

            if (pathname.includes(getAuthPath(PATHS.recovery_password))) {
              return <RecoveryPassword />;
            }

            return <Redirect to={getAuthPath(PATHS.login)} />;
          }
          return <Splash />;
        }}
      />
      <AuthRouter />
      <GlobalToast />
      <GlobalModal />
    </>
  ) : (
    <Splash />
  );
});
