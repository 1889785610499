import { useCallback } from 'react';

import {
  PaginatedCoursesQueryVariables,
  usePaginatedCoursesQuery,
  PageInfo,
} from 'core/graphql';
import { CoursesType } from 'components/types';

type CoursesHookType = {
  courses: CoursesType[];
  loading?: boolean;
  error?: string;
  pageInfo?: Pick<
    PageInfo,
    'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
  >;
  operations: {
    fetchMoreData: (vars: PaginatedCoursesQueryVariables) => void;
  };
  totalCount?: number | null;
};

const useGetCoursesFromApi = (variables?: { first?: number | null }) => {
  const { data, loading, error, fetchMore } = usePaginatedCoursesQuery({
    fetchPolicy: 'cache-first',
    variables: variables || {
      query: '',
    },
    notifyOnNetworkStatusChange: true,
  });

  const courses =
    data?.allCourses?.edges?.reduce(
      (result: CoursesType[], edge) =>
        edge && edge.node
          ? [
              ...result,
              {
                id: edge.node.id,
                name: edge.node.name || '',
                educationForm: edge.node.educationForm || '',
                categoryName: edge.node.category?.name || '',
              },
            ]
          : result,
      [],
    ) || [];

  return {
    fetchMore,
    result: courses,
    loading,
    error: error?.message || undefined,
    pageInfo: data?.allCourses?.pageInfo,
    totalCount: data?.allCourses?.totalCount,
  };
};

export const useCourses = (variables?: {
  first?: number | null;
}): CoursesHookType => {
  const {
    result,
    loading,
    pageInfo,
    totalCount,
    error,
    fetchMore = () => null,
  } = useGetCoursesFromApi(variables);

  const fetchMoreData = useCallback(
    ({ query, after }: PaginatedCoursesQueryVariables) => {
      fetchMore({
        variables: {
          query,
          after,
        },
      });
    },
    [fetchMore],
  );

  return {
    courses: result || [],
    loading,
    error,
    pageInfo,
    operations: {
      fetchMoreData,
    },
    totalCount,
  };
};
