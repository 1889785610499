import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Button } from 'atoms';
import { PopupContainer } from 'molecules';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

export interface IStudentExistPopup {
  onClose: () => void;
  onAddContract: () => void;
  email?: string;
  phone?: string;
  name?: string;
}

export const StudentExistPopupComponent = memo(
  ({ onClose, onAddContract, email, phone, name }: IStudentExistPopup) => {
    const { t } = useTranslation();
    const showEmailOrPhone = () => {
      if (phone) {
        return (
          <>
            {t('studentExistPopup.withPhone')} <BlueText>{phone}</BlueText>
          </>
        );
      }

      if (email) {
        return (
          <>
            {t('studentExistPopup.withEmail')} <BlueText>{email}</BlueText>
          </>
        );
      }
    };

    return (
      <PopupContainer
        title={t('studentExistPopup.studentExist')}
        onClose={onClose}
      >
        <StyledText margin="24px 0 0 0">
          {t('studentExistPopup.student')} <BlueText>{name}</BlueText>
          {showEmailOrPhone()} {t('studentExistPopup.wantToAddAgreement')}
        </StyledText>

        <WrapperRow margin="32px 0 0 0">
          <WrapperCanlcelButton>
            <Button
              onClick={onClose}
              variant="outline"
              colors={{
                backgroundColor: Colors.White,
                color: Colors.Blue,
              }}
            >
              {t('studentExistPopup.button.cancel')}
            </Button>
          </WrapperCanlcelButton>

          <WrapperAddButton>
            <Button
              onClick={onAddContract}
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
              }}
            >
              {t('studentExistPopup.button.add')}
            </Button>
          </WrapperAddButton>
        </WrapperRow>
      </PopupContainer>
    );
  },
);

const WrapperRow = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  margin: ${({ margin }) => margin || 'initial'};
`;

const StyledText = styled.div<{ margin?: string }>`
  font-size: 14px;
  font-family: ${getFontFamily('medium')};
  margin: ${({ margin }) => margin || 'initial'};
`;

const WrapperCanlcelButton = styled.div`
  width: 122px;
  margin-left: auto;
  margin-right: 16px;
`;

const WrapperAddButton = styled.div`
  width: 163px;
`;

const BlueText = styled.span`
  color: ${Colors.Blue};
  white-space: nowrap;
`;
