import { useMemo } from 'react';
import { AgreementDataType } from 'components/types';
import { useLoadAgreementByIdQuery } from 'core/graphql';
import { transformAgreementData } from './transform';

type agreementHookType = {
  agreementData: AgreementDataType | null;
  loading: boolean;
};

export const useGetAgreementData = (agreementId: string): agreementHookType => {
  const { data, loading } = useLoadAgreementByIdQuery({
    variables: { agreementId },
    fetchPolicy: 'network-only',
  });

  const agreementData: AgreementDataType | null = useMemo(
    () => (data ? transformAgreementData(data) : null),
    [data],
  );

  return { agreementData, loading };
};
