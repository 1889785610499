import { AdmissionStatusTypes, IPerformance } from 'types';
import { StudentsAchievementEdge } from 'core/graphql';

interface IGetAdmissionStatus {
  hwSummary?: number | null;
  courseWorksAverage?: number | null;
  attendancesInfo?: number | null;
  graduationProject?: number | null;
}

const getAdmissionStatus = (
  props: IGetAdmissionStatus,
): AdmissionStatusTypes => {
  const variablesArray = Object.values(props).filter(
    (variable) => variable !== null,
  );
  if (!variablesArray.length) {
    return AdmissionStatusTypes.NOT_ADMITTED;
  }

  const result = variablesArray.every((value) => value >= 70);

  return result
    ? AdmissionStatusTypes.ADMITTED
    : AdmissionStatusTypes.NOT_ADMITTED;
};

export const transformPerformancesEdges = (
  edges: StudentsAchievementEdge[],
): IPerformance[] =>
  edges.reduce((performancesArray: IPerformance[], edge): IPerformance[] => {
    if (edge) {
      const { node } = edge;
      if (node) {
        return [
          ...performancesArray,
          {
            id: node.id || '',
            name:
              `${node.student.user?.lastName} ${node.student.user?.firstName} ${
                node.student.user?.middleName || ''
              }` || '',
            group: node.group.name || '',
            passRate: node.hwSummary !== null ? `${node.hwSummary}%` : '-',
            courseProgress:
              node.courseWorksAverage !== null
                ? `${node.courseWorksAverage}%`
                : '-',
            attendance: `${node.attendancesInfo}%`,
            graduationProject:
              node.graduationProjectMark !== null
                ? `${node.graduationProjectMark}%`
                : '-',
            admissionStatus: getAdmissionStatus({
              hwSummary: node.hwSummary,
              courseWorksAverage: node.courseWorksAverage,
              attendancesInfo:
                node.group.course.educationForm === 'offline'
                  ? node.attendancesInfo
                  : null,
              graduationProject: node.graduationProjectMark,
            }),
          },
        ];
      }
    }

    return performancesArray;
  }, []);
