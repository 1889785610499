import * as React from 'react';
import { memo } from 'react';
import { Row, useTable } from 'react-table';

import styled from 'styled-components';

import { Table, TableBody, TableCell, TableHead, TableRow } from 'atoms';

import { InfiniteScrollContainer, LoadingData, LoadingTable } from 'molecules';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { CoursesType } from 'components/types';

export type IRow = CoursesType;

export interface IColumn {
  accessor: 'id' | 'name' | 'educationForm' | 'categoryName';
  flexible?: boolean;
  align?: 'left' | 'right' | 'center';
  Header?: string | React.ReactElement;
  columns?: IColumn[];
  marginRight?: string;
  marginLeft?: string;
  columnWidth?: string;
}

export interface ICoursesTableComponent {
  columns: IColumn[];
  rows: IRow[];
  isFlexible: boolean;
  noDataText: string;
  loadMore: () => void;
  hasMore: boolean;
  loading?: boolean;
}

export const CoursesTableComponent = memo(
  ({
    isFlexible,
    noDataText,
    rows: rowsSource,
    columns,
    loadMore,
    hasMore,
    loading,
  }: ICoursesTableComponent) => {
    const {
      getTableProps,
      headers,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data: rowsSource,
    });

    return (
      <Wrapper>
        <InfiniteScrollContainer
          loadMore={loadMore}
          hasMore={hasMore}
          dataLength={rows.length}
          loading={loading || false}
        >
          <Table {...getTableProps()} flexible={isFlexible}>
            <TableHead>
              <TableRow type="row">
                {headers.map((column: any) => {
                  const {
                    key: cellKey,
                    ...restCellProps
                  } = column.getHeaderProps({
                    type: 'headerCell',
                    flexible: column.flexible && isFlexible,
                    width: column.columnWidth,
                    margin: {
                      right: column.marginRight,
                      left: column.marginLeft,
                    },
                  });

                  return (
                    <TableCell key={cellKey} {...restCellProps}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {!rows.length && !loading && (
                <TableRow key="noData">
                  <TableCell type="cell" width="100%" align="center">
                    {noDataText}
                  </TableCell>
                </TableRow>
              )}
              {!rows.length && loading && (
                <TableRow>
                  <TableCell width="100%" align="center">
                    <LoadingTable />
                  </TableCell>
                </TableRow>
              )}
              {Boolean(rows.length) &&
                rows.map((row: Row<IRow>) => {
                  prepareRow(row);
                  const { key: rowKey, ...restRowProps } = row.getRowProps();

                  return (
                    <TableRow key={rowKey} type="row" {...restRowProps}>
                      {row.cells.map((cell: any) => {
                        const {
                          key: cellKey,
                          ...restCellProps
                        } = cell.getCellProps({
                          width: cell.column.columnWidth,
                          flexible: cell.column.flexible && isFlexible,
                          margin: {
                            right: cell.column.marginRight,
                            left: cell.column.marginLeft,
                          },
                        });

                        return (
                          <TableCell key={cellKey} {...restCellProps}>
                            <CourseNameWrapper>
                              {cell.render('Cell')}
                            </CourseNameWrapper>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </InfiniteScrollContainer>
        {Boolean(rows.length) && loading && <LoadingData />}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 60px;
`;

const CourseNameWrapper = styled.span`
  font-family: ${getFontFamily('medium')};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${Colors.Black};
`;
