import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { useCreatePassword, useGoToLogin, useSortErrors } from 'core/hooks';

import { ErrorsType } from 'types';
import {
  ICreatePasswordFormValues,
  CreatePasswordFormComponent,
} from './CreatePasswordForm';
import { getRecoveryPasswordSchema } from '../RecoveryPasswordForm/RecoveryPasswordFormContainer';

export const CreatePasswordForm = memo(({ token }: { token: string }) => {
  const { t } = useTranslation();
  const [
    createPassword,
    { success, loading, errors: serverErrors },
  ] = useCreatePassword();
  const goToLogin = useGoToLogin();

  const onSubmitHandler = useCallback(
    (values: ICreatePasswordFormValues) => {
      if (!loading) {
        createPassword({ values, token });
      }
    },
    [loading, createPassword, token],
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getRecoveryPasswordSchema(t),
    onSubmit: onSubmitHandler,
  });

  const handleChange = useCallback(
    (value: string, name: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (success) goToLogin();
  }, [success, goToLogin]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<ICreatePasswordFormValues>();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      sortErrors(errors as ErrorsType<ICreatePasswordFormValues>);
      return;
    }

    if (serverErrors) {
      sortErrors(serverErrors);
    }
  }, [errors, serverErrors, sortErrors]);

  return (
    <CreatePasswordFormComponent
      handleSubmit={handleSubmit}
      onChangeInput={handleChange}
      values={values}
      loading={loading}
      errors={sortedErrors}
      errorsMessage={message}
    />
  );
});
