import * as React from 'react';
import { memo, useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import { GuestTemplate } from 'templates';
import { LoginForm } from 'molecules';
import { useGoToMain, useLoginState } from 'core/hooks';
import { PATHS } from 'router/config';

export const LoginPage = memo(() => {
  const goToMain = useGoToMain();
  const { isLoggedIn } = useLoginState();

  useEffect(() => {
    if (isLoggedIn) {
      goToMain();
    }
  }, [isLoggedIn, goToMain]);

  if (isLoggedIn) {
    return <Redirect to={PATHS.main} />;
  }

  return (
    <GuestTemplate>
      <LoginForm />
    </GuestTemplate>
  );
});
