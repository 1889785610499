import * as React from 'react';
import { memo } from 'react';

import { Redirect, useLocation } from 'react-router-dom';

import { GuestTemplate } from 'templates';

import { queryService } from 'services/QueryService';
import { getAuthPath, PATHS } from 'router/config';
import { useConfirmEmail } from 'core/hooks';

export const ConfirmEmail = memo(({ to }: { to: PATHS }) => {
  const { pathname } = useLocation();
  const [
    confirmEmail,
    {
      access_token,
      refresh_token,
      loading,
      isCalled: confirmationRequested,
      errors,
    },
  ] = useConfirmEmail();

  const tokenToConfirmEmail = queryService.getToken(pathname);
  if (!tokenToConfirmEmail) {
    return <Redirect to={getAuthPath(PATHS.login)} />;
  }

  if (!confirmationRequested) {
    confirmEmail(tokenToConfirmEmail);
  }

  if (loading) {
    return (
      <GuestTemplate>
        <p>Loading...</p>
      </GuestTemplate>
    );
  }

  if (access_token && refresh_token) {
    return <Redirect to={`${to}&token=${access_token}`} />;
  }

  return (
    <GuestTemplate>
      <p>
        {!confirmationRequested
          ? 'Start the Confirming...'
          : `Wrong Token ${JSON.stringify(errors)}`}
      </p>
    </GuestTemplate>
  );
});
