import { useCallback } from 'react';

import { useCheckPaymentsDebtMutation } from 'core';

import { ErrorsType, IPaymentInfo } from 'types';
import { useParseServerErrors } from 'services/ErrorsService';
import { IPaymentAddPopupValues } from 'molecules';

type CheckPaymentsHookType = [
  (payments: IPaymentInfo[]) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<IPaymentAddPopupValues> | null;
  },
];

export const useCheckPaymentsDebt = (): CheckPaymentsHookType => {
  const [
    checkPaymentsMutation,
    { data, loading },
  ] = useCheckPaymentsDebtMutation();

  const errors = useParseServerErrors(data?.checkStudentDebt?.customErrors);
  const checkPaymentsDebt = useCallback(
    (payments: IPaymentInfo[]) => {
      checkPaymentsMutation({
        variables: {
          agreements: payments.map(({ contractNumber }) => contractNumber),
        },
      });
    },
    [checkPaymentsMutation],
  );

  return [
    checkPaymentsDebt,
    {
      success: data?.checkStudentDebt?.success || false,
      loading,
      errors,
    },
  ];
};
