import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

export interface ITableHead {
  children: React.ReactNode;
  className?: string;
}

export const TableHead = memo(
  ({ children, className, ...props }: ITableHead) => (
    <TableHeadStyle className={className} {...props}>
      {children}
    </TableHeadStyle>
  ),
);

const TableHeadStyle = styled.thead`
  position: relative;
`;
