import { useEffect } from 'react';

import { TeacherEmploymentTypes } from 'components/types';
import { popupData, PopupNames, useGetTeacherDataLazyQuery } from 'core';

type GetTeacherTypeNamesHookType = {
  data: string[];
  teacherId: string;
  loading: boolean;
};

export const useGetTeacherTypeNames = (): GetTeacherTypeNamesHookType => {
  const data = popupData()[PopupNames.appendApplicationPopup];
  const teacherId = data?.teacherId;

  const [
    getTypes,
    { data: teacherTypes, loading },
  ] = useGetTeacherDataLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (teacherId) {
      getTypes({
        variables: {
          teacherId,
        },
      });
    }
  }, [teacherId, getTypes]);

  const teacherTypesData = teacherTypes?.teacher?.employmentTypes?.reduce(
    (acc: string[], cur) => {
      if (cur) {
        return [
          ...acc,
          TeacherEmploymentTypes[
            cur?.__typename as keyof typeof TeacherEmploymentTypes
          ],
        ];
      }
      return acc;
    },
    [],
  );

  return {
    data: teacherTypesData || [],
    teacherId: teacherId || '',
    loading,
  };
};
