import * as React from 'react';
import { memo } from 'react';

import styled, { css } from 'styled-components';

import { Icon, IconsNames } from 'atoms/Icon';
import { Colors } from 'services/ColorService';

export interface ICheckBox {
  name?: string;
  variant?: 'default' | 'tableCheckBox';
  checked: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  hasError?: boolean;
}

export const CheckBox = memo(
  ({
    name,
    className,
    hasError,
    checked,
    variant = 'default',
    disabled = false,
    onClick,
    ...props
  }: ICheckBox) => (
    <CheckBoxWrapper
      className={className}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      <HiddenCheckbox checked={checked} {...props} readOnly />
      <StyledCheckBox
        name={name}
        variant={variant}
        checked={checked}
        hasError={hasError}
      >
        {checked && (
          <IconWrapper>
            <IconStyled
              name={
                variant === 'default'
                  ? IconsNames.TickIcon
                  : IconsNames.SuccessTick
              }
              width={9}
              height={8}
            />
          </IconWrapper>
        )}
      </StyledCheckBox>
    </CheckBoxWrapper>
  ),
);

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckBoxWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        ${StyledCheckBox} {
          border-color: ${Colors.DeactivatedGrey};
        }

        ${IconStyled} > path {
          stroke: ${Colors.DeactivatedGrey};
        }
      `;
    }
  }}
`;

const StyledCheckBox = styled.div<ICheckBox>`
  display: flex;
  position: relative;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 1px solid
    ${({ checked, variant }) => {
      if (variant === 'tableCheckBox') {
        if (checked) {
          return Colors.SuccessDefault;
        }

        return Colors.Red;
      }

      if (checked) {
        return Colors.Blue;
      }

      return Colors.LightGray;
    }};
  background-color: ${Colors.White};
  ${({ hasError }) => hasError && `border-color: ${Colors.Red}`};
`;

const IconWrapper = styled.div`
  display: flex;
  top: 3px;
  left: 3px;
  position: absolute;
`;

const IconStyled = styled(Icon)``;
