import { gql } from '@apollo/client';

export const AddPaymentsMutation = gql`
  mutation AddPayments($payments: [PaymentInput]) {
    createPayments(paymentsInput: $payments) {
      success
      errors
    }
  }
`;

export const AddDeferredPaymentsMutation = gql`
  mutation AddDeferredPayments($payments: [DeferredPaymentInput]) {
    createDeferredPayments(deferredPaymentsInput: $payments) {
      success
      customErrors {
        field
        message
        code
      }
    }
  }
`;

export const CheckPaymentsDebtMutation = gql`
  mutation CheckPaymentsDebt($agreements: [String]!) {
    checkStudentDebt(agreementsInput: $agreements) {
      customErrors {
        code
        field
        message
      }
      success
    }
  }
`;
