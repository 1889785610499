import * as React from 'react';
import { memo } from 'react';

import { Row, useTable } from 'react-table';

import styled from 'styled-components';

import {
  CheckBox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'atoms';

import { LoadingData, LoadingTable, InfiniteScrollContainer } from 'molecules';

type Accessors =
  | 'checkbox'
  | 'id'
  | 'date'
  | 'username'
  | 'contract'
  | 'sum'
  | 'unparsed';

export type IRow = {
  [key in Accessors]: React.ReactNode;
};

export interface IColumn {
  accessor: Accessors;
  flexible?: boolean;
  align?: 'left' | 'right' | 'center';
  Header?: string | React.ReactElement;
  columns?: IColumn[];
  marginRight?: number;
  marginLeft?: number;
  columnWidth?: number;
}

export interface IPaymentsDeferredTableComponent {
  columns: IColumn[];
  rows: IRow[];
  isFlexible: boolean;
  noDataText: string;
  loadMore: () => void;
  hasMore: boolean;
  headerCheckboxValue: boolean;
  onHeaderCheckboxChange: () => void;
  loading?: boolean;
  userPermission?: boolean;
}

export const PaymentsDeferredTableComponent = memo(
  ({
    columns,
    rows: rowsSource,
    isFlexible,
    noDataText,
    loadMore,
    hasMore,
    headerCheckboxValue,
    onHeaderCheckboxChange,
    loading,
    userPermission,
  }: IPaymentsDeferredTableComponent) => {
    const {
      getTableProps,
      headers,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data: rowsSource,
    });

    return (
      <Wrapper>
        <InfiniteScrollContainer
          loadMore={loadMore}
          hasMore={hasMore}
          dataLength={rows.length}
          loading={loading || false}
        >
          <Table {...getTableProps()} flexible={isFlexible}>
            <TableHead>
              <TableRow type="row">
                {headers.map((column: any) => {
                  const width = column.columnWidth
                    ? `${column.columnWidth}px`
                    : undefined;
                  const marginRight = column.marginRight
                    ? `${column.marginRight}px`
                    : undefined;
                  const marginLeft = column.marginLeft
                    ? `${column.marginLeft}px`
                    : undefined;

                  const {
                    key: cellKey,
                    ...restCellProps
                  } = column.getHeaderProps({
                    type: 'headerCell',
                    flexible: column.flexible && isFlexible,
                    width,
                    margin: {
                      right: marginRight,
                      left: marginLeft,
                    },
                  });

                  if (column.id === 'checkbox') {
                    return (
                      <TableCell key={cellKey} {...restCellProps}>
                        <CheckBox
                          checked={headerCheckboxValue}
                          onClick={() => onHeaderCheckboxChange()}
                          disabled={userPermission}
                        />
                      </TableCell>
                    );
                  }

                  return (
                    <TableCell key={cellKey} {...restCellProps}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {!rows.length && !loading && (
                <TableRow key="noData">
                  <TableCell type="cell" width="100%" align="center">
                    {noDataText}
                  </TableCell>
                </TableRow>
              )}
              {!rows.length && loading && (
                <TableRow>
                  <TableCell width="100%" align="center">
                    <LoadingTable />
                  </TableCell>
                </TableRow>
              )}
              {Boolean(rows.length) &&
                rows.map((row: Row<IRow>) => {
                  prepareRow(row);
                  const { key: rowKey, ...restRowProps } = row.getRowProps();

                  return (
                    <TableRow key={rowKey} type="row" {...restRowProps}>
                      {row.cells.map((cell: any) => {
                        const width = cell.column.columnWidth
                          ? `${cell.column.columnWidth}px`
                          : undefined;
                        const marginRight = cell.column.marginRight
                          ? `${cell.column.marginRight}px`
                          : undefined;
                        const marginLeft = cell.column.marginLeft
                          ? `${cell.column.marginLeft}px`
                          : undefined;

                        const {
                          key: cellKey,
                          ...restCellProps
                        } = cell.getCellProps({
                          width,
                          margin: {
                            right: marginRight,
                            left: marginLeft,
                          },
                          flexible: cell.column.flexible && isFlexible,
                        });

                        return (
                          <TableCell key={cellKey} {...restCellProps}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </InfiniteScrollContainer>
        {Boolean(rows.length) && loading && <LoadingData />}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin-bottom: 60px;
`;
