/* eslint-disable @typescript-eslint/no-unused-vars */
import { gql } from '@apollo/client';

export const companiesQuery = gql`
  query getAllCompanies {
    allCompanies {
      edges {
        node {
          id
          name
          comment
        }
      }
    }
  }
`;

export const loadCompanyData = gql`
  query getCompanyById($id: ID!) {
    company(id: $id) {
      id
      name
      responsiblePerson
      email
      phone
      registrationNumber
      comment
    }
  }
`;
