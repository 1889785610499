import { useGetCountriesForTeachersQuery } from 'core/graphql';

import { OptionsType } from 'types';

import { transformCountriesData } from './transform';

type CountriesNodeType = {
  countries: OptionsType;
  loading: boolean;
  error: string | undefined;
};

export const useCountriesOptions = (): CountriesNodeType => {
  const { data, loading, error } = useGetCountriesForTeachersQuery({
    fetchPolicy: 'cache-and-network',
  });

  const countries = transformCountriesData(data);

  return {
    countries,
    error: error?.message,
    loading,
  };
};
