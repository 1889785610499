import { useCallback, useEffect, useReducer } from 'react';

import {
  CompanyInput,
  useAddCompanyMutation,
  CustomErrorType,
} from 'core/graphql';
import { companiesQuery } from 'core/operations/queries/companies';
import { ErrorsType, ServerCodes } from 'types';
import { ICompanyFormValues } from 'molecules';
import {
  createCompanyReducer,
  getCompanyMutationErrors,
} from '../companyErrors';

type CreateNewCompanyType = [
  (companyInput: CompanyInput) => {},
  {
    success: boolean;
    loading: boolean;
    errors?: ErrorsType<ICompanyFormValues> | null;
    clearErrors: () => void;
  },
];

export const useCreateNewCompany = (): CreateNewCompanyType => {
  const [addCompanyMutation, { data, loading }] = useAddCompanyMutation({
    onError: (err) => err,
    refetchQueries: [
      {
        query: companiesQuery,
        variables: {},
      },
    ],
  });

  const [errors, dispatch] = useReducer(createCompanyReducer, null);

  const clearErrors = useCallback(
    () => dispatch({ type: ServerCodes.none }),
    [],
  );

  useEffect(() => {
    const serverErrors = data?.createCompany?.customErrors;
    if (serverErrors && serverErrors.length) {
      const errorFound = getCompanyMutationErrors(
        serverErrors as CustomErrorType[],
      );

      dispatch({ type: errorFound });
    }
  }, [data?.createCompany?.customErrors]);

  const createNewCompany = useCallback(
    (companyInput: CompanyInput) =>
      addCompanyMutation({ variables: { companyInput } }),
    [addCompanyMutation],
  );

  return [
    createNewCompany,
    {
      success: Boolean(data?.createCompany?.success),
      loading,
      errors,
      clearErrors,
    },
  ];
};
