import { LoadEditingApplicationQuery } from 'core';

import {
  IGroupsOptions,
  TeacherEmploymentTypes,
  ApplicationDeleteAvailable,
} from 'types';

import { ISelectOption } from 'atoms';
import { TEACHER_TYPE_OPTIONS } from 'components/constants';
import { DateService } from 'services/DateService';

export type IEditingApplicationData = {
  applicationNumber: string;
  date: Date | null;
  course: ISelectOption<string> | null;
  group: IGroupsOptions | null;
  isGroupStarted: boolean;
  teacherType: ISelectOption<string> | null;
  allTeacherTypes: ISelectOption<string>[] | null;
  lessonsNumber: string;
  servicePlace?: ISelectOption<string> | null;
  servicePrice: string;
  riaPrice?: string;
  currency: ISelectOption<string> | null;
  comment: string;
  isTerminated: boolean;
  lastLessonDate: Date | null;
  isCompleted: boolean;
  deleteAvailable: ApplicationDeleteAvailable;
};

export const transformApplication = (
  data: LoadEditingApplicationQuery | undefined,
): IEditingApplicationData | null => {
  if (data?.teacherApplication) {
    const {
      title,
      date,
      teacher,
      group,
      employmentType,
      lessonsCount,
      country,
      currency,
      servicePrice,
      riaPrice,
      comment,
      isTerminated,
      lastLessonDate,
      isCompleted,
      deleteAvailable,
      lessons,
      appointedLessonsCount,
    } = data.teacherApplication;

    const teacherTypesData = teacher.employmentTypes?.reduce(
      (acc: string[], cur) => {
        if (cur) {
          return [
            ...acc,
            TeacherEmploymentTypes[
              cur?.__typename as keyof typeof TeacherEmploymentTypes
            ],
          ];
        }
        return acc;
      },
      [],
    );

    const teacherTypes = () =>
      TEACHER_TYPE_OPTIONS.filter((option) => {
        if (teacherTypesData) {
          return teacherTypesData.includes(option.value);
        }
        return true;
      });
    const getEmploymentType = () =>
      TEACHER_TYPE_OPTIONS.find(
        (option) =>
          option.value ===
          TeacherEmploymentTypes[
            employmentType?.__typename as keyof typeof TeacherEmploymentTypes
          ],
      ) || null;
    return {
      applicationNumber: title || '',
      date,
      course: group.course
        ? { value: group.course.id, label: group.course.name }
        : null,
      group: {
        value: group.id,
        label: group.name,
        lessonsCount: group.freeLessonsCount
          ? group.freeLessonsCount + appointedLessonsCount
          : appointedLessonsCount,
        appointedLessonsCount,
      },
      isGroupStarted: lessons.edges[0]
        ? DateService.isBefore(lessons.edges[0].node?.date, new Date())
        : false,

      teacherType: employmentType ? getEmploymentType() : null,
      allTeacherTypes: teacherTypes(),
      lessonsNumber: lessonsCount ? String(lessonsCount) : '',
      servicePlace: country?.id
        ? { value: country?.id, label: country?.title }
        : null,
      servicePrice: servicePrice ? String(servicePrice) : '',
      riaPrice: riaPrice ? String(riaPrice) : '',
      currency: {
        value: currency.charAt(0) + currency.substring(1).toLowerCase(),
        label: currency,
      },
      comment: comment || '',
      isTerminated: isTerminated || false,
      lastLessonDate: lastLessonDate || null,
      isCompleted: isCompleted || false,
      deleteAvailable:
        deleteAvailable || ApplicationDeleteAvailable.DeleteNotAvailable,
    };
  }

  return null;
};
