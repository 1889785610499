import * as React from 'react';
import { memo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Button, CloseButton, Heading } from 'atoms';

import { UserRole } from 'components/types';

import { getFontFamily } from 'services/FontService';
import { translateRoleToValue } from 'services/TransformService';
import { Colors } from 'services/ColorService';

export interface IUserExistPopup {
  unique: {
    phone?: string;
    email?: string;
  };
  existRoles: UserRole[];
  appendRoles: UserRole[];
  loading?: boolean;
  className?: string;
  onClose: () => void;
  onCancelClick: () => void;
  onAcceptClick: () => void;
}

export const UserExistPopupComponent = memo(
  ({
    unique,
    existRoles,
    appendRoles,
    loading,
    className,
    onClose,
    onCancelClick,
    onAcceptClick,
  }: IUserExistPopup) => {
    const { t } = useTranslation();
    const getFirstSentence = useCallback(() => {
      if (unique.phone && unique.email) {
        return (
          <Trans
            i18nKey="userExistPopup.hasMailAndPhone"
            values={{ phone: unique.phone, email: unique.email }}
            components={{
              boldPhone: <BlueText>{unique.phone}</BlueText>,
              boldEmail: <BlueText>{unique.email}</BlueText>,
            }}
          />
        );
      }

      if (unique.phone && !unique.email) {
        return (
          <Trans
            i18nKey="userExistPopup.hasPhone"
            values={{ phone: unique.phone }}
            components={{
              bold: <BlueText>{unique.phone}</BlueText>,
            }}
          />
        );
      }

      if (!unique.phone && unique.email) {
        return (
          <Trans
            i18nKey="userExistPopup.hasMail"
            values={{ email: unique.email }}
            components={{
              bold: <BlueText>{unique.email}</BlueText>,
            }}
          />
        );
      }
    }, [unique]);

    const translatedExistRoles = existRoles
      .map((role: UserRole) => translateRoleToValue(role))
      .join(', ');
    const translatedAppendRoles = appendRoles
      .map((role: UserRole) => translateRoleToValue(role))
      .join(', ');

    return (
      <Wrapper className={className}>
        <InnerWrapper>
          <CloseButtonStyled onClick={onClose} />
          <Heading>{t('userExistPopup.userIsInSystem')}</Heading>
          <Text>
            {getFirstSentence()}
            {existRoles.length > 1
              ? t('userExistPopup.andHasRoles')
              : t('userExistPopup.andHasRole')}
            <BoldText>&quot;{translatedExistRoles}&quot;</BoldText>.
            {appendRoles.length > 1
              ? t('userExistPopup.wantToAddRoles')
              : t('userExistPopup.wantToAddRole')}
            <BoldText>&quot;{translatedAppendRoles}&quot;</BoldText>?
          </Text>
          <BottomWrapper>
            <CancelButton
              onClick={onCancelClick}
              type="button"
              variant="outline"
              colors={{
                backgroundColor: Colors.White,
                color: Colors.Blue,
              }}
            >
              {t('userExistPopup.button.cancel')}
            </CancelButton>
            <AcceptButton
              onClick={onAcceptClick}
              type="button"
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
              }}
              loading={loading}
            >
              {t('userExistPopup.button.add')}
            </AcceptButton>
          </BottomWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 48px 64px 72px;
  background-color: ${Colors.White};
`;

const InnerWrapper = styled.div`
  position: relative;
`;

const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const Text = styled.p`
  margin-top: 24px;
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  line-height: 24px;
`;

const BlueText = styled.span`
  color: ${Colors.Blue};
`;

const BoldText = styled.span`
  font-family: ${getFontFamily('bold')};
`;

const BottomWrapper = styled.div`
  display: flex;
  margin-top: 32px;
`;

const CancelButton = styled(Button)`
  width: 122px;
  margin-left: auto;
`;

const AcceptButton = styled(Button)`
  width: 163px;
  margin-left: 16px;
`;
