import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { ValueType, OptionTypeBase } from 'react-select';

import { useFormik } from 'formik';

import {
  useModule,
  IModuleHook,
  useIsPopupOpen,
  useSortErrors,
} from 'core/hooks';
import { ErrorsType, IApplicationOptions } from 'types';

import { PopupNames } from 'core';
import { ModuleComponent, IModuleValues } from './Module';

export interface IModuleContainer extends IModuleHook {
  applicationOptions?: IApplicationOptions[];
  onSubmitHandler: () => void;
}

export const Module = memo(({ index, onSubmitHandler }: IModuleContainer) => {
  const {
    onShowInput,
    onAddLesson,
    onAddLessonForEditing,
    onToggleAllLessons,
    onSortEnd,
    onSetTeacher,
    onChooseLesson,
    onCloseInput,
    name,
    showInput,
    allLessons,
    applicationOptions,
    showWithoutTeacherOption,
    lessons,
    disabledLessons,
    errors,
  } = useModule({ index });
  const isEditGroupPopupOpened = useIsPopupOpen(PopupNames.editGroupPopup);
  const isEditJournalPopupOpened = useIsPopupOpen(PopupNames.editJournalPopup);
  const isAnyEditPopupOpened =
    isEditGroupPopupOpened || isEditJournalPopupOpened;

  const newApplicationOptions = useMemo(
    () => applicationOptions.filter((e) => e.freeLessonsCount),
    [applicationOptions],
  );

  const { values, setFieldValue } = useFormik({
    initialValues: {
      application: null,
      lessonInputName: '',
      isAdditional: false,
    },

    onSubmit: onSubmitHandler,
    enableReinitialize: false,
  });

  const handleChange = useCallback(
    (
      value: string | boolean | number | ValueType<OptionTypeBase, false>,
      Name: string,
    ) => {
      setFieldValue(Name, value);
    },

    [setFieldValue],
  );

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IModuleValues>();

  useEffect(() => {
    if (!errors && lessons) {
      handleChange('', 'lessonInputName');
      handleChange(false, 'isAdditional');
    }
  }, [errors, lessons, handleChange]);

  useEffect(() => {
    if (!lessons.some(({ checked }) => checked)) {
      handleChange(null, 'application');
    }
  }, [lessons, handleChange]);

  useEffect(() => {
    if (errors) {
      sortErrors(errors as ErrorsType<IModuleValues>);
      return;
    }

    sortErrors(null);
  }, [errors, sortErrors]);

  return (
    <ModuleComponent
      onShowInput={onShowInput}
      onAddLesson={isAnyEditPopupOpened ? onAddLessonForEditing : onAddLesson}
      isPopupAdd={!isAnyEditPopupOpened}
      showInput={showInput}
      onSortEnd={onSortEnd}
      nameModule={name}
      onChangeInput={handleChange}
      errors={sortedErrors}
      errorsMessage={message}
      values={values}
      onSetTeacher={onSetTeacher}
      lessons={lessons}
      disabledLessons={disabledLessons}
      onChooseLesson={onChooseLesson}
      allLessons={allLessons}
      onToggleAllLessons={onToggleAllLessons}
      applicationOptions={newApplicationOptions || []}
      showWithoutTeacherOption={showWithoutTeacherOption}
      onCloseInput={onCloseInput}
    />
  );
});
