import { useCallback, useMemo } from 'react';

import { useGetCompanyByIdLazyQuery } from 'core/graphql';

import { CompanyDataType, transformCompany } from './transform';

type useCompanyType = [
  (id: string) => void,
  {
    company: CompanyDataType;
    loading: boolean;
  },
];

export const useCompany = (): useCompanyType => {
  const [getCompanyById, { data, loading }] = useGetCompanyByIdLazyQuery();

  const company = useMemo(() => transformCompany(data), [data]);

  const getCompanyData = useCallback(
    (id: string) => getCompanyById({ variables: { id } }),
    [getCompanyById],
  );

  return [getCompanyData, { company, loading }];
};
