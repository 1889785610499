import { GetCompanyByIdQuery } from 'core';

export type CompanyDataType = {
  name: string;
  responsiblePerson: string;
  phone: string;
  email: string;
  registrationNumber: string;
  comment: string | null;
} | null;

export const transformCompany = (
  data: GetCompanyByIdQuery | undefined,
): CompanyDataType => {
  if (data?.company) {
    return {
      name: data.company.name || '',
      email: data.company.email || '',
      phone: data.company.phone || '',
      registrationNumber: data.company.registrationNumber.toString() || '',
      responsiblePerson: data.company.responsiblePerson || '',
      comment: data.company.comment || null,
    };
  }
  return null;
};
