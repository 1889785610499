import { AgreementDataType, Currencies } from 'components/types';
import { LoadAgreementByIdQuery } from 'core/graphql';
import { DateService } from 'services/DateService';
import { parseEdges } from 'services/ParsingService';

interface IPaymentsWithPrevSum {
  id: string;
  date: Date;
  sum: number;
  prevPaymentsSum: number;
}

export const transformAgreementData = (
  data: LoadAgreementByIdQuery,
): AgreementDataType | null => {
  if (!data.agreement) {
    return null;
  }

  const {
    student,
    agreementTitle,
    date,
    budgetAmount,
    paidAmount,
    paymentByCompany,
    vat,
    group,
    currency,
    chargeback,
    terminated,
    comment,
    promocode,
    company,
    country,
    resident,
    contributions,
    partialAttendance,
    firstLesson,
    lastLesson,
    startAccountsPayable,
    accountsPayable,
    terminatedDate,
  } = data.agreement;

  const parsedPayments = parseEdges(
    contributions.edges,
    (node: {
      id: string;
      date: string;
      amountForeign?: number;
      amountByn?: number;
    }) => ({
      id: node.id,
      date: DateService.parseDate(node.date, 'ISO'),
      sum: node.amountForeign || node.amountByn || 0,
    }),
  );

  const paymentsWithPrevSum = parsedPayments.reduce(
    (paymentsArray: IPaymentsWithPrevSum[], payment, currentIndex) => {
      if (currentIndex === 0) {
        return [
          ...paymentsArray,
          { ...payment, prevPaymentsSum: Number(payment.sum) },
        ];
      }
      return [
        ...paymentsArray,
        {
          ...payment,
          prevPaymentsSum:
            Number(payment.sum) +
            Number(paymentsArray[currentIndex - 1].prevPaymentsSum),
        },
      ];
    },
    [],
  );

  const payments = paymentsWithPrevSum.map((payment) => ({
    id: payment.id,
    date: payment.date,
    sum: payment.sum,
    isPaid: payment.prevPaymentsSum <= paidAmount,
  }));

  const usedPayables = accountsPayable.map(
    ({
      id,
      amountForeign,
      currency: accountsPayableCurrency,
      startedAgreement,
    }) => ({
      value: id,
      label: startedAgreement.agreementTitle,
      amount: amountForeign?.toString() || '',
      currency: {
        value: Currencies[accountsPayableCurrency],
        label: Currencies[accountsPayableCurrency],
      },
      repaid: true,
    }),
  );

  return {
    firstName: student.user?.firstName || '',
    middleName: student.user?.middleName || '',
    lastName: student.user?.lastName || '',
    email: student.user?.email || '',
    phone: student.user?.phone || '',
    agreementTitle,
    date: DateService.parseDate(date, 'ISO'),
    price: budgetAmount?.toString() || '',
    isPaymentByLegal: paymentByCompany,
    isVAT: vat,
    group: {
      value: group.id,
      label: group.name,
    },
    isGroupStarted: DateService.isBefore(
      DateService.parseDate(group.startDate, 'ISO'),
      new Date(),
    ),
    currency: { value: Currencies[currency], label: Currencies[currency] },
    comment: comment || '',
    refundCurrency: chargeback?.currency
      ? { value: chargeback.currency, label: chargeback.currency }
      : null,
    isTerminatedAgreement: terminated,
    isResident: resident,
    promocode: promocode || '',
    company: company?.id ? { value: company.id, label: company.name } : null,
    country: country?.id ? { value: country.id, label: country.title } : null,
    partialAttendance,
    firstLesson: firstLesson?.id
      ? {
          value: firstLesson.id,
          label: firstLesson.lessonNumber
            ? `${firstLesson.lessonNumber}. ${firstLesson.name}`
            : `- ${firstLesson.name}`,
        }
      : null,
    lastLesson: lastLesson?.id
      ? {
          value: lastLesson.id,
          label: lastLesson.lessonNumber
            ? `${lastLesson.lessonNumber}. ${lastLesson.name}`
            : `- ${lastLesson.name}`,
        }
      : null,
    refundDate: terminated
      ? DateService.parseDate(terminatedDate, 'ISO')
      : null,
    refundAmount: chargeback?.amountForeign || chargeback?.amountByn || 0,
    course: { value: group.course.id, label: group.course.name },
    payments,
    accountsPayable: startAccountsPayable?.amountForeign?.toString() || '',
    repaid: startAccountsPayable?.repaid || false,
    usedPayables,
  };
};
