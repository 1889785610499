import { MockedResponse } from '@apollo/client/testing';
import { AgreementCurrency, PaginatedContributionsQuery } from 'core';
import { DebtorsQuery } from 'core/operations';

export const mockContributionsQueryRequest: MockedResponse<PaginatedContributionsQuery> = {
  request: {
    query: DebtorsQuery,
    variables: {
      first: 2,
      query: '',
    },
  },
  result: {
    data: {
      allContributions: {
        pageInfo: {
          startCursor: '',
          endCursor: '',
          hasNextPage: false,
          hasPreviousPage: false,
        },
        edges: [
          {
            cursor: '',
            node: {
              id: '1096',
              date: '2021-10-04',
              complete: false,
              delay: 143,
              remainder: 2301,
              amountByn: 5000,
              amountForeign: 0,
              person: {
                firstName: 'Имя',
                lastName: 'Фамилия',
                middleName: 'Отчество',
                phone: '+375291850983',
              },
              agreementTitle: '33029387',
              agreement: {
                currency: AgreementCurrency.Byn,
              },
              group: {
                id: '405',
                name: '2001ios',
              },
            },
          },
          {
            cursor: '',
            node: {
              id: '465',
              date: '2021-11-24',
              complete: false,
              delay: 92,
              remainder: 600,
              amountByn: 600,
              amountForeign: 0,
              person: {
                firstName: 'Eric',
                lastName: 'Morales',
                middleName: 'Ramirez',
                phone: '+375332153633',
              },
              agreementTitle: 'ERIC договор Python',
              agreement: {
                currency: AgreementCurrency.Byn,
              },
              group: {
                id: '286',
                name: 'newGroup',
              },
            },
          },
        ],
      },
    },
  },
};
