import { useCallback } from 'react';
import { IUserData, UserRole, ErrorsType } from 'types';
import {
  useCheckUserExistLazyQuery,
  CheckUserExistQueryVariables,
} from 'core/graphql';
import { IAddStudentPopupValues } from 'components/molecules';
import { useParseServerErrors } from 'services/ErrorsService';

type CheckUserExistType = [
  (variables: CheckUserExistQueryVariables) => void,
  {
    user?: IUserData | null;
    code?: string;
    loading: boolean;
    errors: ErrorsType<IAddStudentPopupValues> | null;
  },
];

export const useCheckCredentials = (): CheckUserExistType => {
  const [verifyStudent, { data, loading }] = useCheckUserExistLazyQuery();
  const checkUserExist = useCallback(
    (variables: CheckUserExistQueryVariables) => verifyStudent({ variables }),
    [verifyStudent],
  );
  const errors = useParseServerErrors(data?.verifyStudent?.customErrors);

  const user = data?.verifyStudent?.user || data?.verifyStudent?.student?.user;

  return [
    checkUserExist,
    {
      user: user && {
        id: data?.verifyStudent?.student?.id || user.id,
        firstName: user.firstName,
        middleName: user.middleName || '',
        lastName: user.lastName,
        email: user.email,
        phone: user.phone || '',
        roles: (user.roles as UserRole[]) || [],
      },
      code: data?.verifyStudent?.code,
      loading,
      errors,
    },
  ];
};
