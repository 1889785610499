import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { getFontFamily } from 'services/FontService';

import { Icon, IconsNames } from '../Icon';

export interface IExitButtonProps {
  onClick: () => void;
}

export const ExitButton = ({ onClick }: IExitButtonProps) => {
  const { t } = useTranslation();

  return (
    <StyledButton onClick={onClick}>
      <ButtonText>{t('button.exit')}</ButtonText>
      <IconWrapper>
        <Icon name={IconsNames.ExitIcon} width={16} height={18} />
      </IconWrapper>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  height: 100%;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  padding: 0;
`;

const ButtonText = styled.p`
  font-family: ${getFontFamily('bold')};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  text-decoration: none;
  margin: 0 12px 0 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
`;
