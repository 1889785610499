import { makeVar } from '@apollo/client';

import { IApplicationOptions } from 'types';

export interface IApplicationOptionsState {
  applicationOptions: IApplicationOptions[];
}

export const defaultApplicationOptionsState: IApplicationOptionsState = Object.freeze(
  {
    applicationOptions: [],
  },
);

export const applicationOptionsStateVar = makeVar(
  defaultApplicationOptionsState,
);
