import {
  UserNode,
  useFindUserDataLazyQuery,
  useMeLazyQuery,
} from 'core/graphql';
import { useReactiveVar } from '@apollo/client';

import { useCallback, useEffect } from 'react';
import { userStateVar } from 'core/state/userState';
import { useAppState, useAuthState } from 'core/hooks';

interface IFindUserDataVariables {
  email?: string;
  phone?: string;
}

type findUserDataType = [
  (variables: IFindUserDataVariables) => void,
  {
    userData: UserNode | undefined;
    loading: boolean;
    error: string | undefined;
  },
];

type userType = [
  {
    user: UserNode | null | undefined;
    loading: boolean;
    error: string;
  },
];

export const useFindUserData = (): findUserDataType => {
  const [
    findUserDataQuery,
    { data, loading, error },
  ] = useFindUserDataLazyQuery({
    fetchPolicy: 'network-only',
  });

  const findUserData = useCallback(
    (variables: IFindUserDataVariables) => {
      findUserDataQuery({
        variables,
      });
    },
    [findUserDataQuery],
  );

  return [
    findUserData,
    {
      userData:
        data?.users?.edges.map((edge) => edge!.node as UserNode)[0] ||
        undefined,
      loading,
      error: error?.message || undefined,
    },
  ];
};

export const useUser = (): userType => {
  const [getUser, { data, error, loading }] = useMeLazyQuery({
    fetchPolicy: 'network-only',
  });
  const {
    data: { bootstrapped },
  } = useAppState();
  const {
    data: { isLoggedIn },
  } = useAuthState();
  const user = useReactiveVar(userStateVar);

  useEffect(() => {
    if (!bootstrapped) {
      if (isLoggedIn === true) {
        getUser();
      }
      if (isLoggedIn === false) {
        userStateVar({ ...userStateVar(), me: undefined });
      }
      if (isLoggedIn === null) {
        userStateVar({ ...userStateVar(), me: null });
      }
    }
  }, [isLoggedIn, bootstrapped, getUser]);

  useEffect(() => {
    if (data && data.me) {
      userStateVar({ ...userStateVar(), me: data.me });
    }

    if (error) {
      userStateVar({ ...userStateVar(), me: null });
    }
  }, [data, error, loading]);

  return [
    {
      user: user.me,
      loading,
      error: error?.message || '',
    },
  ];
};
