import * as React from 'react';
import { memo, useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { IVerifiedPaymentInfo, ErrorsType } from 'types';

import { CheckBox, IconButton, IconsNames, Input, Select, Title } from 'atoms';

import { PaymentCheckTableComponent, IColumn, IRow } from './PaymentCheckTable';

export interface IVerifiedPaymentInfoExtended extends IVerifiedPaymentInfo {
  acceptCurrencies?: boolean;
  contractNotFound?: boolean;
  differentUsername?: boolean;
  differentCurrency?: boolean;
  wasEdited?: boolean;
}

export interface IPaymentCheckTableValues {
  payments: IVerifiedPaymentInfoExtended[];
}

export interface IPaymentCheckTable {
  values: IPaymentCheckTableValues;
  errors?: ErrorsType<IPaymentCheckTableValues> | null;
  errorMessage?: string | null;
  loading?: boolean;
  onInputChange: (
    name: string,
    value: string | boolean,
    disableSubmit?: boolean,
  ) => void;
  className?: string;
}

const getColumnsSource = (t: TFunction): IColumn[] => [
  {
    Header: `${t('table.payments.studentName')}`,
    accessor: 'username',
    columnWidth: 288,
    headerCellPaddingRight: 16,
    headerCellPaddingLeft: 16,
  },
  {
    Header: `${t('table.payments.contractNumber')}`,
    accessor: 'contractNumber',
    columnWidth: 218,
    marginRight: 16,
    marginLeft: 16,
  },
  {
    Header: `${t('table.payments.sum')}`,
    accessor: 'sum',
    columnWidth: 192,
  },
  {
    accessor: 'acceptCurrencies',
    columnWidth: 16,
    marginRight: 16,
    marginLeft: 16,
  },
  {
    accessor: 'unparsed',
    flexible: true,
    marginRight: 16,
  },
  {
    accessor: 'info',
    columnWidth: 24,
  },
];

export const PaymentCheckTable = memo(
  ({ values, loading, onInputChange, className }: IPaymentCheckTable) => {
    const { t } = useTranslation();
    const columns: IColumn[] = useMemo(() => getColumnsSource(t), [t]);
    const rows: IRow[] = useMemo(
      () =>
        values.payments.map((payment, index) => {
          const fieldNames = {
            username: `payments.${index}.username`,
            contractNumber: `payments.${index}.contractNumber`,
            contractCurrency: `payments.${index}.contractCurrency`,
            sum: `payments.${index}.sum`,
            sumCurrency: `payments.${index}.sumCurrency`,
            acceptCurrencies: `payments.${index}.acceptCurrencies`,
            unparsed: `payments.${index}.unparsed`,
            wasEdited: `payments.${index}.wasEdited`,
          };

          const differentUsernameText = `${t('table.payments.dataDontMatch')}`;

          return {
            deferredPaymentId: payment.deferredPaymentId,
            contractId: payment.contractId,
            date: payment.date,
            username: (
              <Input
                value={payment.username || ''}
                onChange={({ target: { value } }) =>
                  onInputChange(fieldNames.username, value)
                }
                hasError={payment.contractNotFound}
                hasWarning={payment.differentUsername}
                type="text"
                name={fieldNames.username}
                disabled
              />
            ),
            contractNumber: (
              <>
                <Input
                  value={payment.contractNumber}
                  onChange={({ target: { value } }) => {
                    onInputChange(fieldNames.contractNumber, value);
                    onInputChange(fieldNames.wasEdited, true);
                  }}
                  hasError={payment.contractNotFound}
                  type="text"
                  name={fieldNames.contractNumber}
                />
                <SelectCurrency
                  value={{
                    label: payment.contractCurrency,
                    value: payment.contractCurrency,
                  }}
                  hasError={
                    (payment.contractNotFound || payment.differentCurrency) &&
                    !payment.acceptCurrencies
                  }
                  type="primary"
                  isDisabled
                  hideIndicator
                />
              </>
            ),
            sum: (
              <>
                <Input
                  value={
                    Number.isInteger(payment.sum)
                      ? Number(payment.sum).toFixed(1)
                      : String(payment.sum)
                  }
                  name={fieldNames.sum}
                  type="text"
                  onChange={({ target: { value } }) =>
                    onInputChange(fieldNames.sum, value)
                  }
                  hasError={payment.contractNotFound}
                  disabled
                />
                <SelectCurrency
                  value={{
                    label: payment.sumCurrency,
                    value: payment.sumCurrency,
                  }}
                  hasError={
                    (payment.contractNotFound || payment.differentCurrency) &&
                    !payment.acceptCurrencies
                  }
                  type="primary"
                  isDisabled
                  hideIndicator
                />
              </>
            ),
            acceptCurrencies:
              (!payment.contractNotFound && payment.differentCurrency) ||
              payment.acceptCurrencies ? (
                <CheckBox
                  checked={Boolean(payment.acceptCurrencies)}
                  name={fieldNames.acceptCurrencies}
                  onClick={() =>
                    onInputChange(
                      fieldNames.acceptCurrencies,
                      !payment.acceptCurrencies,
                      false,
                    )
                  }
                />
              ) : null,
            unparsed: (
              <UnparsedWrapper>
                <UnparsedTitle text={payment.unparsed} />
                <Input
                  value={payment.unparsed}
                  type="text"
                  onChange={({ target: { value } }) =>
                    onInputChange(fieldNames.unparsed, value)
                  }
                  hasError={payment.contractNotFound}
                  hasWarning={payment.differentUsername}
                  name={fieldNames.unparsed}
                  disabled
                />
              </UnparsedWrapper>
            ),
            info: (payment.contractNotFound && (
              <IconInfo name={IconsNames.CircleError} />
            )) ||
              (payment.differentCurrency && !payment.acceptCurrencies && (
                <IconInfo name={IconsNames.CircleError} />
              )) ||
              (payment.differentUsername && (
                <IconInfo
                  name={IconsNames.CircleWarning}
                  title={differentUsernameText}
                />
              )) || <IconInfo name={IconsNames.CircleSuccess} />,
          };
        }),
      [values, onInputChange, t],
    );

    if (loading) {
      return <p>Loading...</p>;
    }

    return (
      <PaymentCheckTableComponent
        rows={rows}
        columns={columns}
        isFlexible
        className={className}
      />
    );
  },
);

const IconInfo = styled((props) => <IconButton {...props} />)`
  height: 24px;
  width: 24px;
`;

const SelectCurrency = styled((props) => <Select {...props} />)`
  flex-shrink: 0;
  width: 64px;
`;

const UnparsedTitle = styled((props) => <Title {...props} />)`
  bottom: calc(100% - 10px);
  display: none;
`;

const UnparsedWrapper = styled.div`
  position: relative;

  &:hover {
    & > ${UnparsedTitle} {
      display: block;
    }
  }
`;
