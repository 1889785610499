import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Colors } from 'services/ColorService';

import { Button } from 'atoms';
import {
  PaymentCheckTable,
  IPaymentCheckTable,
  PopupErrors,
  PopupContainer,
} from 'molecules';

export interface IPaymentCheckPopup extends IPaymentCheckTable {
  isAddPaymentsAvailable: boolean;
  showCheck: boolean;
  onClose: () => void;
  onUpdateClick: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

export const PaymentCheckPopupComponent = memo(
  ({
    values,
    errors,
    errorMessage,
    loading,
    isAddPaymentsAvailable,
    showCheck,
    onInputChange,
    onClose,
    onUpdateClick,
    handleSubmit,
  }: IPaymentCheckPopup) => {
    const { t } = useTranslation();
    return (
      <PopupContainer
        title={t('paymentPopup.checkPayment')}
        onClose={onClose}
        width="1372px"
      >
        <PaymentCheckTableStyled
          values={values}
          errors={errors}
          onInputChange={onInputChange}
        />
        <BottomWrapper>
          <BottomButtons>
            {showCheck && (
              <CheckButton
                type="button"
                variant="outline"
                onClick={onUpdateClick}
                colors={{
                  backgroundColor: Colors.White,
                  color: Colors.Blue,
                }}
              >
                {t('paymentPopup.button.checkPayment')}
              </CheckButton>
            )}
            <AddButton
              type="button"
              onClick={handleSubmit}
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
              }}
              disabled={!isAddPaymentsAvailable}
              loading={loading}
            >
              {t('paymentPopup.button.addPayment')}
            </AddButton>
          </BottomButtons>
        </BottomWrapper>
        <PopupErrorsStyled>{errorMessage}</PopupErrorsStyled>
      </PopupContainer>
    );
  },
);

const PaymentCheckTableStyled = styled(PaymentCheckTable)`
  margin-top: 16px;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
`;

const BottomButtons = styled.div`
  display: flex;
`;

const CheckButton = styled(Button)`
  width: 208px;
`;

const AddButton = styled(Button)`
  width: 197px;
  margin-left: 16px;
`;

const PopupErrorsStyled = styled(PopupErrors)`
  margin-top: 24px;
`;
