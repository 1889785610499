import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { popupData, PopupNames } from 'core';
import {
  useDeleteApplication,
  usePopupStateOperations,
  useToasts,
} from 'core/hooks';

import { ConfirmPopupComponent } from 'molecules';
import {
  GlobalToastTypeNames,
  ToastTypes,
  ApplicationDeleteAvailable,
} from 'components/types';

export const ApplicationDeletePopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const data = popupData()[PopupNames.applicationDeletePopup];

  const {
    operations: { showPopup, hidePopup },
  } = usePopupStateOperations();

  const [
    deleteApplication,
    { success: deleteSuccess, loading: deleteLoading },
  ] = useDeleteApplication();

  const onConfirm = useCallback(() => {
    if (data?.applicationId) {
      deleteApplication(data.applicationId);
      return;
    }

    addToast({
      data: {
        title: `${t('applicationDeletePopup.deleteError.title')}`,
        text: `${t('applicationDeletePopup.deleteError.message')}`,
        type: ToastTypes.error,
      },
      type: GlobalToastTypeNames.TeacherApplicationDelete,
    });
  }, [data?.applicationId, deleteApplication, addToast, t]);

  const onCancel = useCallback(() => {
    showPopup(PopupNames.editApplicationPopup);
  }, [showPopup]);

  useEffect(() => {
    if (deleteSuccess) {
      addToast({
        data: {
          title: `${t('applicationDeletePopup.deleteSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.TeacherApplicationDelete,
      });
      hidePopup(PopupNames.applicationDeletePopup);
    }
  }, [deleteSuccess, hidePopup, addToast, t]);

  return (
    <ConfirmPopupComponent
      title={t('applicationDeletePopup.deleteApplication')}
      confirmText={t('applicationDeletePopup.button.delete')}
      onConfirm={onConfirm}
      onCancel={onCancel}
      loading={deleteLoading}
    >
      {data?.code === ApplicationDeleteAvailable.DeleteAvailable && (
        <span>{t('applicationDeletePopup.youAreGoingToDelete')}</span>
      )}
      {data?.code === ApplicationDeleteAvailable.DeleteWithTeacher && (
        <span>
          {t('applicationDeletePopup.youAreGoingToDeleteWithTeacher')}
        </span>
      )}
    </ConfirmPopupComponent>
  );
});
