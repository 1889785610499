import { useCallback } from 'react';

import { TeachersMetaQueryVariables } from 'core';

import { useTeachersMetaQuery } from 'core/graphql';

type teachersMetaType = [
  (vars: TeachersMetaQueryVariables) => void,
  {
    totalCount: {
      users: number;
      applications: number;
    };
  },
];

export const useTeachersMeta = (): teachersMetaType => {
  const { data, fetchMore } = useTeachersMetaQuery({
    fetchPolicy: 'cache-first',
    variables: {
      query: null,
    },
  });

  const fetchMoreMeta = useCallback(
    ({ query }: TeachersMetaQueryVariables) => {
      fetchMore({
        variables: {
          query,
        },
      });
    },
    [fetchMore],
  );

  const result = () => {
    if (!data) {
      return { users: 0, applications: 0 };
    }

    return {
      users: data.metaTeachers?.totalCount || 0,
      applications: data.metaTeachers?.applicationsCount || 0,
    };
  };

  return [fetchMoreMeta, { totalCount: result() }];
};
