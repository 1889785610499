import { searchState } from 'core/state';
import { useReactiveVar } from '@apollo/client';

export enum SearchNames {
  settings = 'settingsSearchQuery',
  users = 'usersSearchQuery',
  students = 'studentsSearchQuery',
  teachers = 'teachersSearchQuery',
  performance = 'performanceSearchQuery',
}

type searchHookType = {
  searchQuery: string;
  setSearchQuery: (newSearch: string) => void;
};

export const useSearch = (searchType: SearchNames): searchHookType => {
  const search = useReactiveVar(searchState);

  return {
    searchQuery: search[searchType],
    setSearchQuery: (newSearch) =>
      searchState({ ...searchState(), [searchType]: newSearch }),
  };
};
