import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

export interface ITextArea extends ITextAreaStyle {
  name: string;
  value: string;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows: number;
}

interface ITextAreaStyle {
  color?: string;
  disabled?: boolean;
  hasError?: boolean;
}

export const TextArea = memo(
  ({ name, value, placeholder, onChange, rows, ...rest }: ITextArea) => (
    <StyledTextArea
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      rows={rows}
      spellCheck="false"
      {...rest}
    />
  ),
);

const StyledTextArea = styled.textarea<ITextAreaStyle>`
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  border: 1px solid
    ${({ hasError }) => (hasError ? Colors.Red : Colors.LightGray)};
  font-family: ${getFontFamily('regular')};
  font-size: 14px;
  line-height: 24px;
  color: ${({ color }) => color || Colors.Black};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  outline: none;
  opacity: ${({ disabled }) => (disabled ? '0.8' : '1')};
  user-select: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  resize: none;

  &:focus {
    border-color: ${({ hasError }) =>
      hasError ? Colors.Red : Colors.LightGray};
  }
`;
