import { gql } from '@apollo/client';

export const UpdateAttendanceMutation = gql`
  mutation changeAttendance($attendanceId: Int!, $attendance: Boolean!) {
    updateAttendance(attendanceId: $attendanceId, attendance: $attendance) {
      success
      customErrors {
        field
        message
        code
      }
    }
  }
`;

export const changeLessonDatesMutation = gql`
  mutation changeDateOfLesson(
    $groupId: Int!
    $newDate: Date!
    $oldDate: Date!
  ) {
    changeLessonDates(groupId: $groupId, newDate: $newDate, oldDate: $oldDate) {
      success
      customErrors {
        field
        message
        code
      }
    }
  }
`;

export const UpdateJournalMutation = gql`
  mutation changeJournal(
    $groupId: Int!
    $daysOfClass: [DayOfClassInput!]!
    $modules: [UpdateModuleInput]!
    $comment: String!
  ) {
    updateJournal(
      groupId: $groupId
      daysOfClass: $daysOfClass
      modules: $modules
      comment: $comment
    ) {
      success
      customErrors {
        field
        message
        code
      }
    }
  }
`;
