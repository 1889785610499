import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';

import { IStudentPaymentPopupValues } from 'components/molecules';
import {
  MetaStudentsQuery,
  StudentsQuery,
} from 'core/operations/queries/students';
import {
  CreateAgreementInputBase,
  popupData,
  PopupNames,
  useAddAgreementMutation,
  useAddRoleAndAgreementMutation,
  useAddStudentWithAgreementMutation,
} from 'core';
import { useParseServerErrors } from 'services/ErrorsService';
import { ErrorsType } from 'types';

type CreateAgreementType = [
  (agreementInput: CreateAgreementInputBase) => void,
  {
    success: boolean;
    loading: boolean;
    showWarning?: boolean;
    errors?: ErrorsType<IStudentPaymentPopupValues> | null;
  },
];

export const useCreateAgreement = (): CreateAgreementType => {
  const [addAgreement, addAgreementData] = useAddAgreement();
  const [
    addRoleAndAgreement,
    addRoleAndAgreementData,
  ] = useAddRoleAndAgreement();
  const [
    addStudentAndAgreement,
    addStudentAndAgreementData,
  ] = useAddStudentAndAgreement();

  const agreementData = useReactiveVar(popupData)[PopupNames.studentPayment];

  switch (true) {
    case Boolean(agreementData?.studentId):
      return [addAgreement, addAgreementData];
    case Boolean(agreementData?.userId):
      return [addRoleAndAgreement, addRoleAndAgreementData];
    default:
      return [
        addStudentAndAgreement,
        { ...addStudentAndAgreementData, showWarning: true },
      ];
  }
};

const useAddAgreement = (): CreateAgreementType => {
  const [addAgreementMutation, { data, loading }] = useAddAgreementMutation({
    onError: (err) => err,
    refetchQueries: [
      {
        query: StudentsQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: MetaStudentsQuery,
      },
    ],
  });
  const studentId =
    useReactiveVar(popupData)[PopupNames.studentPayment]?.studentId || null;

  const errors = useParseServerErrors(data?.createAgreement?.customErrors);

  const createAgreement = useCallback(
    (values: CreateAgreementInputBase) => {
      if (!studentId) {
        return;
      }

      addAgreementMutation({
        variables: {
          agreementInput: { studentId, ...values },
        },
      });
    },
    [addAgreementMutation, studentId],
  );

  return [
    createAgreement,
    {
      success: Boolean(data?.createAgreement?.success),
      loading,
      errors,
    },
  ];
};

const useAddRoleAndAgreement = (): CreateAgreementType => {
  const [
    addRoleAndAgreementMutation,
    { data, loading },
  ] = useAddRoleAndAgreementMutation({
    onError: (err) => err,
    refetchQueries: [
      {
        query: StudentsQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: MetaStudentsQuery,
      },
    ],
  });
  const userId =
    useReactiveVar(popupData)[PopupNames.studentPayment]?.userId || null;

  const errors = useParseServerErrors(
    data?.appendStudentRoleAndAgreement?.customErrors,
  );

  const addRoleAndCreateAgreement = useCallback(
    (agreementInput: CreateAgreementInputBase) => {
      if (!userId) {
        return;
      }

      addRoleAndAgreementMutation({
        variables: {
          userId,
          agreementInput,
        },
      });
    },
    [addRoleAndAgreementMutation, userId],
  );

  return [
    addRoleAndCreateAgreement,
    {
      success: Boolean(data?.appendStudentRoleAndAgreement?.success),
      loading,
      errors,
    },
  ];
};

const useAddStudentAndAgreement = (): CreateAgreementType => {
  const [
    addStudentAndAgreementMutation,
    { data, loading },
  ] = useAddStudentWithAgreementMutation({
    onError: (err) => err,
    refetchQueries: [
      {
        query: StudentsQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: MetaStudentsQuery,
      },
    ],
  });

  const studentInput =
    useReactiveVar(popupData)[PopupNames.studentPayment]?.studentInput || null;

  const errors = useParseServerErrors(
    data?.createStudentWithAgreement?.customErrors,
  );

  const addRoleAndCreateAgreement = useCallback(
    (agreementInput: CreateAgreementInputBase) => {
      if (!studentInput) {
        return;
      }

      addStudentAndAgreementMutation({
        variables: {
          studentInput,
          agreementInput,
        },
      });
    },
    [addStudentAndAgreementMutation, studentInput],
  );

  return [
    addRoleAndCreateAgreement,
    {
      success: Boolean(data?.createStudentWithAgreement?.success),
      loading,
      errors,
    },
  ];
};
