import * as React from 'react';
import { memo, useMemo, useCallback } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { IApplication } from 'types';

import { usePopupStateOperations } from 'core/hooks';
import { PopupNames } from 'core';
import { ApplicationsTableComponent, IColumn, IRow } from './ApplicationsTable';

export interface IApplicationsTable {
  data: IApplication[];
  loading?: boolean;
  error?: string;
  loadMore: () => void;
  hasMore: boolean;
}

const getColumnsSource = (t: TFunction): IColumn[] => [
  {
    Header: `${t('table.applications.number')}`,
    accessor: 'applicationNumber',
    columnWidth: '190px',
  },
  {
    Header: `${t('table.applications.teacherFullName')}`,
    accessor: 'fullName',
    columnWidth: '280px',
    marginRight: '56px',
    marginLeft: '56px',
  },
  {
    Header: `${t('table.applications.group')}`,
    accessor: 'groupNumber',
    columnWidth: '112px',
  },
  {
    Header: `${t('table.applications.lessonsCount')}`,
    accessor: 'lessonsCount',
    columnWidth: '112px',
    marginRight: '56px',
    marginLeft: '56px',
  },
  {
    Header: `${t('table.applications.teacherType')}`,
    accessor: 'teacherType',
    flexible: true,
    columnWidth: '140px',
  },
  {
    Header: `${t('table.applications.price')}`,
    accessor: 'price',
    flexible: true,
    columnWidth: '88px',
    marginRight: '56px',
  },
];

export const ApplicationsTable = memo(
  ({ data, loading, error, loadMore, hasMore }: IApplicationsTable) => {
    const { t } = useTranslation();
    const columns: IColumn[] = useMemo(() => getColumnsSource(t), [t]);
    const rows: IRow[] = useMemo(
      () =>
        data.map((debtor, idx) => ({
          ...debtor,
          num: idx + 1,
        })),
      [data],
    );

    const {
      operations: { showPopup },
    } = usePopupStateOperations();

    const onEditButtonClick = useCallback(
      (applicationId: string) => {
        showPopup(PopupNames.editApplicationPopup, { applicationId });
      },
      [showPopup],
    );

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <ApplicationsTableComponent
        rows={rows}
        columns={columns}
        isFlexible
        noDataText={t('table.applications.noData')}
        loadMore={loadMore}
        hasMore={hasMore}
        onEditButtonClick={onEditButtonClick}
        loading={loading}
      />
    );
  },
);
