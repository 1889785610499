import { gql } from '@apollo/client';

export const AddUniqueTeacherMutation = gql`
  mutation addUniqueTeacher(
    $application: ApplicationInputType!
    $employmentTypes: EmploymentInputType!
    $teacher: NewTeacherInput!
  ) {
    createUniqueTeacher(
      application: $application
      employmentTypes: $employmentTypes
      teacher: $teacher
    ) {
      success
      customErrors {
        field
        message
        code
      }
    }
  }
`;

export const AddTeacherRoleMutation = gql`
  mutation addNewTeacherRole(
    $application: ApplicationInputType!
    $employmentTypes: EmploymentInputType!
    $siteDisplay: Boolean
    $userId: ID!
  ) {
    addTeacherRole(
      application: $application
      employmentTypes: $employmentTypes
      siteDisplay: $siteDisplay
      userId: $userId
    ) {
      success
      customErrors {
        field
        message
        code
      }
    }
  }
`;

export const AddTeacherExistMutation = gql`
  mutation AddTeacherExist(
    $application: ApplicationInputType!
    $employmentTypes: ExistEmploymentInputType!
    $teacherId: ID!
  ) {
    addTeacherExist(
      application: $application
      employmentTypes: $employmentTypes
      teacherId: $teacherId
    ) {
      success
      customErrors {
        field
        message
        code
      }
    }
  }
`;

export const UpdateTeacherMutation = gql`
  mutation editTeacherWithType(
    $employmentInput: ExistEmploymentInputType!
    $teacherId: ID
    $teacherInput: TeacherWithTypeInput!
  ) {
    updateTeacherWithType(
      teacherId: $teacherId
      employmentInput: $employmentInput
      teacherInput: $teacherInput
    ) {
      success
      errors
      customErrors {
        field
        message
        code
      }
    }
  }
`;
