import * as React from 'react';
import { ReactNode } from 'react';

import styled from 'styled-components';

export interface IMenuItemListProps {
  children: ReactNode[];
}

export const MenuItemList = ({ children }: IMenuItemListProps) => (
  <MenuGroup>{children}</MenuGroup>
);

const MenuGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -8px;
`;
