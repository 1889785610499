import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IRecoveryPasswordFormValues } from 'molecules';

import {
  IYupFieldError,
  YupFieldErrorTypes,
} from 'services/YupValidationService';

import { usePasswordResetMutation } from '../../graphql/generated/hooks';
import i18n from '../../../i18n';

interface IRecoveryPasswordVars {
  values: IRecoveryPasswordFormValues;
  token: string;
}

interface IRecoveryPasswordAction {
  type: 'passwordError' | 'confirmPasswordError' | 'none';
}

export const recoveryPasswordErrorsReducer = (
  state: IYupFieldError | null,
  action: IRecoveryPasswordAction,
) => {
  switch (action.type) {
    case 'passwordError':
      return {
        type: YupFieldErrorTypes.SERVER_ERROR,
        message: `${i18n.t('common.wrongPass')}`,
      };
    case 'confirmPasswordError':
      return {
        type: YupFieldErrorTypes.SERVER_ERROR,
        message: `${i18n.t('common.passesDontMatch')}`,
      };
    case 'none':
      return null;
    default:
      return null;
  }
};

export const useRecoveryPassword = (): any => {
  const { t } = useTranslation();
  const result = usePasswordResetMutation();
  const [setNewPassword, { data, loading }] = result;
  const recoveryPassword = useCallback(
    ({
      values: { newPassword, confirmPassword },
      token,
    }: IRecoveryPasswordVars) => {
      setNewPassword({
        variables: {
          newPassword1: newPassword,
          newPassword2: confirmPassword,
          token,
        },
      });
    },
    [setNewPassword],
  );
  const errors = useMemo(() => {
    if (data?.passwordReset?.errors) {
      return {
        newPassword: {
          type: YupFieldErrorTypes.SERVER_ERROR,
          message: `${t('common.passSameAsEmail')}`,
        },
        confirmPassword: {
          type: YupFieldErrorTypes.SERVER_ERROR,
          message: `${t('common.passSameAsEmail')}`,
        },
      };
    }

    return null;
  }, [data?.passwordReset?.errors, t]);
  return [
    recoveryPassword,
    {
      success: Boolean(data?.passwordReset?.success),
      loading,
      errors,
    },
  ];
};
