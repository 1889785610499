import * as React from 'react';
import { memo, ReactNode, RefObject } from 'react';

import { RouteChildrenProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { TabItem, TabsButton, TabsButtonActive } from 'atoms';

import { Colors } from 'services/ColorService';
import { gluePaths } from 'services/TransformService';

export type TabComponent =
  | ((props: RouteChildrenProps<any>) => ReactNode)
  | ReactNode;

export interface ITabsData {
  to: string;
  label: ReactNode;
}

export interface ITabsComponent extends ITabsComponentStyle {
  tabs: ITabsData[];
  tabsAlt?: ITabsData[];
  path: string;
  url: string;
  component: TabComponent;
  activeTab?: string;
  scrollBlockRef: RefObject<HTMLDivElement>;
  isTabsScrolling: boolean;
  tabsButtonOnClick: (clickedArrow: TabsButtonActive) => void;
  tabsButtonActive: TabsButtonActive;
  withYears?: boolean;
}

interface ITabsComponentStyle {
  width?: string;
}

export const TabsComponent = memo(
  ({
    width,
    tabs,
    tabsAlt,
    activeTab,
    path,
    component,
    scrollBlockRef,
    isTabsScrolling,
    tabsButtonOnClick,
    tabsButtonActive,
    withYears,
  }: ITabsComponent) => (
    <TabsStyled width={width}>
      <TabsHeader>
        <TabsBorder withYears={withYears}>
          <TabsOverflowWrapper>
            <TabsOuter active={tabsButtonActive} />
            <TabsHeaderWrapper
              active={tabsButtonActive}
              ref={scrollBlockRef}
              isTabsScrolling={isTabsScrolling}
            >
              <TabsWrapper>
                <TabsMain>
                  {tabs.map(({ label, to }) => (
                    <TabItem
                      key={to}
                      isActive={activeTab === to}
                      to={gluePaths(path, to)}
                    >
                      {label}
                    </TabItem>
                  ))}
                </TabsMain>
              </TabsWrapper>
            </TabsHeaderWrapper>
          </TabsOverflowWrapper>
          {isTabsScrolling && (
            <TabsButtonWrapper>
              <TabsButton
                onClick={tabsButtonOnClick}
                active={tabsButtonActive}
              />
            </TabsButtonWrapper>
          )}
          {tabsAlt && (
            <TabsAlt>
              {tabsAlt.map(({ label, to }) => (
                <TabItem
                  key={to}
                  isActive={activeTab === to}
                  to={gluePaths(path, to)}
                >
                  {label}
                </TabItem>
              ))}
            </TabsAlt>
          )}
        </TabsBorder>
      </TabsHeader>
      <TabsBody>{component}</TabsBody>
    </TabsStyled>
  ),
);

const TabsStyled = styled.div<ITabsComponentStyle>`
  width: ${({ width }) => width || '100%'};
  box-sizing: border-box;
  background-color: ${Colors.Transparent};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TabsHeader = styled.div`
  width: 100%;
  box-sizing: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 24px;
`;

const TabsBorder = styled.div<{ withYears?: boolean }>`
  margin-left: ${({ withYears }) =>
    withYears
      ? '134px' // 134 - 110(YearListMinWidth) + 24(YearListMargin)
      : '0px'};
  border-bottom: 1px solid ${Colors.LightGray};
  overflow: hidden;
  display: flex;
  width: 100%;
`;

const TabsOverflowWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const TabsHeaderWrapper = styled.div<{
  active: TabsButtonActive;
  isTabsScrolling: boolean;
}>`
  flex-grow: 1;
  box-sizing: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: ${({ isTabsScrolling }) =>
    isTabsScrolling ? 'scroll' : 'hidden'};
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

const TabsOuter = styled.div<{ active: TabsButtonActive }>`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &::before {
    transition: opacity 0.1s;
    opacity: 0;
    position: absolute;
    z-index: 1;
    content: '';
    width: 30px;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(242, 245, 249, 1) 0%,
      rgba(0, 36, 35, 0) 50%
    );
  }

  &::after {
    transition: opacity 0.1s;
    opacity: 0;
    position: absolute;
    z-index: 1;
    content: '';
    width: 30px;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(0, 36, 35, 0) 0%,
      rgba(242, 245, 249, 1) 50%
    );
  }

  ${({ active }) =>
    (active === TabsButtonActive.Left || active === TabsButtonActive.All) &&
    css`
      &::before {
        opacity: 1;
      }
    `}

  ${({ active }) =>
    (active === TabsButtonActive.Right || active === TabsButtonActive.All) &&
    css`
      &::after {
        opacity: 1;
      }
    `}
`;

const TabsButtonWrapper = styled.div`
  padding: 11px 0 0 60px;
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TabsList = styled.ul`
  box-sizing: inherit;
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  align-items: inherit;
  margin-bottom: 0;
  li {
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
`;

const TabsMain = styled(TabsList)``;
const TabsAlt = styled(TabsList)`
  margin-left: 52px;
`;

const TabsBody = styled.div`
  width: 100%;
  box-sizing: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
