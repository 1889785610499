import { useCallback, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AddTeacherExistMutationVariables,
  useAddTeacherExistMutation,
  CustomErrorType,
} from 'core';
import {
  ApplicationsQuery,
  TeachersMetaQuery,
  TeachersQuery,
} from 'core/operations';

import {
  ServerCodes,
  ErrorsType,
  ToastTypes,
  GlobalToastTypeNames,
} from 'types';
import {
  IAddApplicationFieldsValues,
  ITeacherAddFieldsValues,
} from 'molecules';

import {
  applicationTeacherErrorsReducer,
  getApplicationErrors,
  useToasts,
} from 'core/hooks';

type AddTeacherExistHookType = [
  (variables: AddTeacherExistMutationVariables) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<
      ITeacherAddFieldsValues & IAddApplicationFieldsValues
    > | null;
  },
];

export const useAddTeacherExist = (): AddTeacherExistHookType => {
  const { t } = useTranslation();
  const [errors, dispatch] = useReducer(applicationTeacherErrorsReducer, null);
  const { addToast } = useToasts();

  const [mutation, { data, loading }] = useAddTeacherExistMutation({
    refetchQueries: [
      {
        query: TeachersQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: ApplicationsQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: TeachersMetaQuery,
      },
    ],
  });

  const addTeacherExist = useCallback(
    (variables: AddTeacherExistMutationVariables) => {
      mutation({ variables });
    },
    [mutation],
  );

  useEffect(() => {
    const serverErrors = data?.addTeacherExist?.customErrors;
    if (serverErrors && serverErrors.length) {
      const errorsFound = getApplicationErrors(
        serverErrors as CustomErrorType[],
      );
      switch (true) {
        case errorsFound.applicationExist:
          dispatch({ type: ServerCodes.applicationTitleExist });
          break;
        case errorsFound.lessonCountExceeds:
          dispatch({ type: ServerCodes.lessonCountExceeds });
          break;

        default:
          addToast({
            data: {
              title: `${t('common.somethingWentWrong')}`,
              type: ToastTypes.error,
            },
            type: GlobalToastTypeNames.TeacherAdd,
          });
      }
    }
  }, [data?.addTeacherExist?.customErrors, addToast, t]);

  return [
    addTeacherExist,
    {
      success: data?.addTeacherExist?.success || false,
      loading,
      errors,
    },
  ];
};
