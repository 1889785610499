import { IUserData, ServerCodes } from 'components/types';

import {
  IAddApplicationFieldsValues,
  ITeacherAddTypeValuesFields,
} from 'molecules';

import { popupData, PopupNames } from 'core';

type GetApplicationPopupDataHookType = {
  values?: IAddApplicationFieldsValues;
  user?: IUserData;
  teacherId?: string;
  code?: ServerCodes;
  types?: ITeacherAddTypeValuesFields;
};

export const useGetApplicationPopupData = (): GetApplicationPopupDataHookType => {
  const data = popupData()[PopupNames.addApplicationPopup];

  return {
    values: data?.values,
    user: data?.user,
    code: data?.code,
    types: data?.types,
  };
};
