import * as React from 'react';
import {
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
  RefObject,
} from 'react';

import { useRouteMatch, useLocation, matchPath } from 'react-router-dom';

import { TabsButtonActive } from 'atoms';

import { gluePaths } from 'services/TransformService';
import { TabsComponent, ITabsData, TabComponent } from './Tabs';

export interface ITabs {
  children?: TabComponent;
  tabs: ITabsData[];
  tabsAlt?: ITabsData[];
  width?: string;
  withYears?: boolean;
  tabBase?: string;
  onMainTabsChange?: (tabIndex: number | undefined) => void;
}

const checkOverflow = (block: HTMLDivElement): boolean => {
  const { scrollWidth, clientWidth } = block;
  const isHasOverflow = scrollWidth > clientWidth;
  return isHasOverflow;
};

const checkForScrollPosition = (block: HTMLDivElement): TabsButtonActive => {
  const { scrollLeft, scrollWidth, clientWidth } = block;

  const canScrollLeft = scrollLeft > 0;
  const canScrollRight = scrollLeft < scrollWidth - clientWidth;

  if (canScrollLeft && canScrollRight) {
    return TabsButtonActive.All;
  }

  if (canScrollLeft && !canScrollRight) {
    return TabsButtonActive.Left;
  }

  if (!canScrollLeft && canScrollRight) {
    return TabsButtonActive.Right;
  }

  return TabsButtonActive.None;
};

export const Tabs = memo(
  ({
    children,
    width,
    tabs,
    tabsAlt,
    withYears,
    tabBase,
    onMainTabsChange,
  }: ITabs) => {
    const { pathname } = useLocation();
    const { url, path } = useRouteMatch();
    const tabBasePath = tabBase || path;

    const [hasOverflow, setHasOverflow] = useState(false);
    const [activeTabButton, setActiveTabButton] = useState<TabsButtonActive>(
      TabsButtonActive.None,
    );

    const scrollBlockRef = useRef<HTMLDivElement>(null);
    const timeout = useRef<null | number>(null);

    const active = useMemo(() => {
      const mainActiveTab = tabs.find(({ to }) =>
        matchPath(pathname, { path: gluePaths(tabBasePath || url, to) }),
      );

      if (mainActiveTab) {
        return mainActiveTab;
      }

      const altActiveTab = tabsAlt?.find(({ to }) =>
        matchPath(pathname, { path: gluePaths(tabBasePath || url, to) }),
      );

      return altActiveTab;
    }, [url, tabs, tabsAlt, pathname, tabBasePath]);

    useEffect(() => {
      if (active) {
        const index = tabs.indexOf(active);

        if (onMainTabsChange) {
          onMainTabsChange(index);
        }
      }
    }, [active, tabs, onMainTabsChange]);

    const tabsButtonOnClick = useCallback((clickedButton: TabsButtonActive) => {
      if (scrollBlockRef && scrollBlockRef.current) {
        if (clickedButton === TabsButtonActive.Left) {
          scrollBlockRef.current.scrollBy({ left: -186, behavior: 'smooth' });
        }
        if (clickedButton === TabsButtonActive.Right) {
          scrollBlockRef.current.scrollBy({ left: 186, behavior: 'smooth' });
        }
      }
    }, []);

    const updateOverflow = useCallback(
      (scrollBlock: RefObject<HTMLDivElement>) => {
        if (scrollBlock && scrollBlock.current) {
          const node = scrollBlock.current;

          const isHasOveflow = checkOverflow(node);
          setHasOverflow(isHasOveflow);

          const checkPosition = checkForScrollPosition(node);
          setActiveTabButton(checkPosition);
        }
      },
      [],
    );

    const scrollHandler = useCallback(() => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = window.setTimeout(() => {
        updateOverflow(scrollBlockRef);
      }, 50);
    }, [updateOverflow]);

    useEffect(() => {
      if (scrollBlockRef && scrollBlockRef.current) {
        const node = scrollBlockRef.current;
        node.addEventListener('scroll', scrollHandler);
        return () => {
          node.removeEventListener('scroll', scrollHandler);
        };
      }
    }, [scrollHandler]);

    useEffect(() => {
      updateOverflow(scrollBlockRef);
    }, [tabs, updateOverflow]);

    return (
      <TabsComponent
        width={width}
        tabs={tabs}
        tabsAlt={tabsAlt}
        path={tabBasePath}
        url={url}
        activeTab={active?.to}
        component={children}
        scrollBlockRef={scrollBlockRef}
        tabsButtonActive={activeTabButton}
        tabsButtonOnClick={tabsButtonOnClick}
        isTabsScrolling={hasOverflow}
        withYears={withYears}
      />
    );
  },
);
