import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { OptionTypeBase } from 'react-select';

import { ErrorsType, TeacherHireType, TeacherLEType } from 'types';

import { InputLabel, ISelectOption, TextArea, Input } from 'atoms';

export interface ILeFieldsValues extends Partial<TeacherLEType> {}

export interface ILeFields {
  values?: ILeFieldsValues;
  disableFields?: boolean;
  index: number;
  errors?: ErrorsType<ILeFieldsValues> | null;
  onInputChange: (
    name: string,
    value?: string | boolean | ISelectOption<string> | null | OptionTypeBase,
  ) => void;
}

export const LeFields = memo(
  ({ values, disableFields, index, errors, onInputChange }: ILeFields) => {
    const { t } = useTranslation();
    const name = `teacherTypes[${index}].${TeacherHireType.Le}`;
    return (
      <>
        <div>
          <InputLabel>{t('teacherTypePopup.leFields.title.label')}</InputLabel>
          <Input
            placeholder={t('teacherTypePopup.leFields.title.placeholder')}
            max={100}
            type="text"
            value={values?.title || ''}
            name={`${name}.title`}
            onChange={({ target: { value } }) =>
              onInputChange(`${name}.title`, value)
            }
            hasError={Boolean(errors?.title)}
            disabled={disableFields}
          />
        </div>
        <div>
          <InputLabel>{t('teacherTypePopup.leFields.inn.label')}</InputLabel>
          <Input
            placeholder={t('teacherTypePopup.leFields.inn.placeholder')}
            max={100}
            type="text"
            value={values?.inn || ''}
            name={`${name}.inn`}
            onChange={({ target: { value } }) =>
              onInputChange(`${name}.inn`, value)
            }
            hasError={Boolean(errors?.inn)}
            disabled={disableFields}
          />
        </div>
        <div>
          <InputLabel>
            {t('teacherTypePopup.leFields.accountNumber.label')}
          </InputLabel>
          <Input
            placeholder={t(
              'teacherTypePopup.leFields.accountNumber.placeholder',
            )}
            max={100}
            type="text"
            value={values?.accountNumber || ''}
            name={`${name}.accountNumber`}
            onChange={({ target: { value } }) =>
              onInputChange(`${name}.accountNumber`, value)
            }
            hasError={Boolean(errors?.accountNumber)}
            disabled={disableFields}
          />
        </div>
        <div>
          <InputLabel>{t('teacherTypePopup.leFields.bic.label')}</InputLabel>
          <Input
            placeholder={t('teacherTypePopup.leFields.bic.placeholder')}
            max={100}
            type="text"
            value={values?.bic || ''}
            name={`${name}.bic`}
            onChange={({ target: { value } }) =>
              onInputChange(`${name}.bic`, value)
            }
            hasError={Boolean(errors?.bic)}
            disabled={disableFields}
          />
        </div>
        <div>
          <InputLabel>
            {t('teacherTypePopup.leFields.bankTitle.label')}
          </InputLabel>
          <Input
            placeholder={t('teacherTypePopup.leFields.bankTitle.placeholder')}
            max={100}
            type="text"
            value={values?.bankTitle || ''}
            name={`${name}.bankTitle`}
            onChange={({ target: { value } }) =>
              onInputChange(`${name}.bankTitle`, value)
            }
            hasError={Boolean(errors?.bankTitle)}
            disabled={disableFields}
          />
        </div>
        <TextAreaWrapper>
          <InputLabel>
            {t('teacherTypePopup.leFields.registrationAddress.label')}
          </InputLabel>
          <TextArea
            placeholder={t(
              'teacherTypePopup.leFields.registrationAddress.placeholder',
            )}
            rows={1}
            value={values?.registrationAddress || ''}
            name={`${name}.registrationAddress`}
            onChange={({ target: { value } }) =>
              onInputChange(`${name}.registrationAddress`, value)
            }
            hasError={Boolean(errors?.registrationAddress)}
            disabled={disableFields}
          />
        </TextAreaWrapper>
      </>
    );
  },
);

const TextAreaWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;
