import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';

import { IconSizes } from 'components/types';

import { Title } from 'atoms';

import { Icon, IconsNames } from '../Icon';

export interface IIconButton {
  name: IconsNames;
  size?: IconSizes;
  onClick: () => void;
  title?: string;
  type?: 'submit' | 'reset' | 'button';
}

export const IconButton = memo(
  ({
    name,
    size = IconSizes.md,
    onClick,
    type = 'button',
    title,
    ...props
  }: IIconButton) => (
    <StyledButton onClick={onClick} type={type} {...props}>
      {title && <TitleStyled text={title} />}
      <Icon name={name} width={size} height={size} />
    </StyledButton>
  ),
);

const TitleStyled = styled((props) => <Title {...props} />)`
  display: none;
`;

const StyledButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  outline: none;

  &:hover {
    ${TitleStyled} {
      display: block;
    }
  }
`;
