import { useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { useDeleteUserMutation } from 'core/graphql';

import { popupData, PopupNames } from 'core/state';
import { UsersSettingsQuery } from 'core/operations';

import { UserRole } from 'types';

import { useGetUser } from './GetUserData';

type DeleteUserType = [
  () => void,
  {
    user: {
      userRoles: UserRole[];
    };
    success: boolean;
    getUserLoading: boolean;
    loading: boolean;
    error: string;
  },
];

export const useDeleteUser = (): DeleteUserType => {
  const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
    onError: (err) => err,
    refetchQueries: [
      {
        query: UsersSettingsQuery,
        variables: {
          first: 10,
        },
      },
    ],
  });

  const userId = useReactiveVar(popupData)[PopupNames.editUser];
  const [getUserById, { user, getUserLoading }] = useGetUser();

  useEffect(() => {
    if (userId) {
      getUserById(userId);
    }
  }, [userId, getUserById]);

  const deleteUser = useCallback(() => {
    deleteUserMutation({
      variables: {
        pk: String(user?.pk),
      },
    });
  }, [deleteUserMutation, user]);

  return [
    deleteUser,
    {
      user: {
        userRoles: user?.roles || [],
      },
      success: Boolean(data?.deleteUserSettings?.success),
      getUserLoading,
      loading,
      error: error?.message || '',
    },
  ];
};
