import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

import { Search, StudentsTable } from 'molecules';
import { MainTemplate } from 'templates';

import {
  useStudents,
  usePopupStateOperations,
  useSearch,
  SearchNames,
} from 'core/hooks';
import { PopupNames } from 'core';
import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';

export const Students = memo(() => {
  const { t } = useTranslation();
  const {
    data: students,
    loading,
    totalCount = 0,
    agreementsCount = 0,
    error,
    pageInfo,
    operations: { fetchMoreData },
  } = useStudents();
  const {
    operations: { showPopup },
  } = usePopupStateOperations();

  const showAddStudentPopup = useCallback(() => {
    showPopup(PopupNames.addStudent);
  }, [showPopup]);

  const { searchQuery, setSearchQuery } = useSearch(SearchNames.students);

  const onChangeHandler = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery],
  );

  const onClearSearchHandler = useCallback(() => {
    setSearchQuery('');
    fetchMoreData({ query: null });
  }, [setSearchQuery, fetchMoreData]);

  const fetchMore = useCallback(() => {
    const vars = {
      query: searchQuery || null,
      after: pageInfo?.endCursor,
    };

    fetchMoreData(vars);
  }, [fetchMoreData, pageInfo?.endCursor, searchQuery]);

  useEffect(() => {
    const trimedQuery = searchQuery.trim();
    const query = trimedQuery || null;

    fetchMoreData({ query });
  }, [searchQuery, fetchMoreData]);

  return (
    <MainTemplate>
      <StudentsWrapper>
        <HeadingWrapper>
          <Heading>{t('pages.students.students')}</Heading>
          <Counter>{String(totalCount)}</Counter>

          <Heading>{t('pages.students.agreements')}</Heading>
          <Counter>{String(agreementsCount)}</Counter>
        </HeadingWrapper>
        <SearchWrapper>
          <Search
            value={searchQuery}
            onChange={onChangeHandler}
            clear={onClearSearchHandler}
            placeholder={t('pages.students.searchStudent')}
            buttonProps={{
              label: `${t('pages.students.addStudent')}`,
              onClick: showAddStudentPopup,
            }}
          />
        </SearchWrapper>
        <TableWrapper>
          <StudentsTable
            data={students}
            loading={loading}
            error={error}
            hasMore={pageInfo?.hasNextPage || false}
            loadMore={fetchMore}
          />
        </TableWrapper>
      </StudentsWrapper>
    </MainTemplate>
  );
});

const StudentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
`;

const baseWrapperStyles = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HeadingWrapper = styled.div`
  margin-top: 48px;
`;

const Heading = styled.span`
  font-family: ${getFontFamily('bold')};
  font-size: 20px;
  line-height: 40px;
  margin-right: 16px;
`;

const Counter = styled.span`
  color: ${Colors.Blue};
  font-family: ${getFontFamily('medium')};
  font-size: 20px;
  line-height: 32px;
  margin-right: 64px;
`;

const SearchWrapper = styled.div`
  ${baseWrapperStyles};
  margin-top: 16px;
`;
const TableWrapper = styled.div`
  ${baseWrapperStyles};
  margin-top: 36px;
`;
