import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import {
  UpdateApplicationInputType,
  useUpdateTeacherApplicationMutation,
  CustomErrorType,
} from 'core';
import { useToasts } from 'core/hooks';
import { ApplicationsQuery } from 'core/operations';

import {
  ErrorsType,
  ServerCodes,
  ToastTypes,
  GlobalToastTypeNames,
} from 'types';

import { IEditApplicationPopupValues } from 'molecules/EditApplicationPopup/EditApplicationPopup';
import {
  applicationTeacherErrorsReducer,
  getApplicationErrors,
} from 'core/hooks/Applications/reducer';

type UpdateApplicationHookType = [
  (application: UpdateApplicationInputType) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<IEditApplicationPopupValues> | null;
  },
];

export const useUpdateApplication = (): UpdateApplicationHookType => {
  const { t } = useTranslation();
  const [errors, dispatch] = useReducer(applicationTeacherErrorsReducer, null);
  const { addToast } = useToasts();

  const [
    updateApplication,
    { data, loading },
  ] = useUpdateTeacherApplicationMutation({
    refetchQueries: [
      {
        query: ApplicationsQuery,
        variables: {
          first: 10,
        },
      },
    ],
  });

  const update = useCallback(
    (application: UpdateApplicationInputType) => {
      updateApplication({
        variables: { application },
      });
    },
    [updateApplication],
  );

  useEffect(() => {
    const serverErrors = data?.updateTeacherApplication?.customErrors;
    if (serverErrors && serverErrors.length) {
      const errorsFound = getApplicationErrors(
        serverErrors as CustomErrorType[],
      );
      switch (true) {
        case errorsFound.applicationExist:
          dispatch({ type: ServerCodes.applicationTitleExist });
          break;
        case errorsFound.lessonCountExceeds:
          dispatch({ type: ServerCodes.lessonCountExceeds });
          break;
        case errorsFound.lessonCountFew:
          dispatch({ type: ServerCodes.lessonCountFew });
          break;

        default:
          addToast({
            data: {
              title: `${t('common.somethingWentWrong')}`,
              type: ToastTypes.error,
            },
            type: GlobalToastTypeNames.TeacherApplicationEdit,
          });
      }
    }
  }, [data?.updateTeacherApplication?.customErrors, addToast, t]);

  return [
    update,
    {
      success: data?.updateTeacherApplication?.success || false,
      loading,
      errors,
    },
  ];
};
