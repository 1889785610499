import React, { memo, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { useFormik } from 'formik';
import { ValueType, OptionTypeBase } from 'react-select';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { PopupNames } from 'core';
import { TIME_OPTIONS, CURRENCY_OPTIONS } from 'components/constants';

import {
  Currencies,
  ToastTypes,
  FieldErrorTypes,
  ErrorsType,
  GlobalToastTypeNames,
} from 'types';

import {
  YupFieldErrorTypes,
  yupValidation,
} from 'services/YupValidationService';
import { WRONG_SYMBOLS, _groupNumberRegExp } from 'services/ValidationService';

import {
  usePopupStateOperations,
  useGetCoursesForTeacher,
  useGetCourseForGroup,
  useCreateGroup,
  useSortErrors,
  useToasts,
} from 'core/hooks';

import { DateService } from 'services/DateService';
import { AddGroupPopupComponent, IAddGroupPopupValues } from './AddGroupPopup';

export const getGroupAddSchema = (t: TFunction) =>
  Yup.object().shape({
    course: Yup.object()
      .nullable()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('groupPopup.fields.course.label')}`,
      }),
    groupNumber: Yup.string()
      .nullable()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('groupPopup.fields.groupNumber.label')}`,
      })
      .matches(_groupNumberRegExp, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: WRONG_SYMBOLS,
      })),
    price: Yup.string()
      .nullable()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('groupPopup.fields.price.label')}`,
      })
      .test({ ...yupValidation.validateNumber(t) }),
    startDate: Yup.string()
      .nullable()
      .required({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('groupPopup.fields.startDate.label')}`,
      }),
    lessonsDays: yupValidation.lessonsDays(t),
  });

export const AddGroupPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const {
    operations: { hidePopup },
  } = usePopupStateOperations();

  const onClose = useCallback(() => hidePopup(PopupNames.addGroup), [
    hidePopup,
  ]);

  const [getCourseDataForGroup, { data, modules }] = useGetCourseForGroup();

  const { data: courses } = useGetCoursesForTeacher();

  const [
    createGroup,
    { success, loading, errors: serverErrors },
  ] = useCreateGroup();

  const onSubmitHandler = useCallback(
    (values: IAddGroupPopupValues) => {
      if (loading) {
        return;
      }
      createGroup({
        groupInput: {
          name: values.groupNumber || '',
          price: Number(values.price),
          currency: values.currency?.value
            ? values.currency?.value
            : Currencies.BYN,
          startDate: DateService.toISO(values.startDate),
          course_id: Number(values.course?.value),
          daysOfClass: values.lessonsDays.map((lessonDay) => ({
            weekDay: lessonDay.weekDay,
            startTime: lessonDay?.startTime?.value,
            endTime: lessonDay?.endTime?.value,
          })),
        },
        moduleInput: Object.values(modules).map((module) => ({
          name: module.name,
          lessons: module.lessons.map((lesson) => ({
            name: lesson.lessonName,
            lmsDescription: lesson.lmsDescription,
            additional: lesson.additional,
            studentsAttachments: lesson.studentAttachments || [],
            teachersAttachments: lesson.teacherAttachments || [],
            taskTypes: lesson.taskTypes || [],
          })),
        })),
      });
    },
    [createGroup, loading, modules],
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      course: null,
      groupNumber: null,
      price: null,
      currency: null,
      startDate: null,
      lessonsDays: Array(2)
        .fill({
          weekDay: null,
          startTime: TIME_OPTIONS[19],
          endTime: TIME_OPTIONS[22],
        })
        .map((item, index) => ({ ...item, id: index })),
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getGroupAddSchema(t),
    onSubmit: onSubmitHandler,
    enableReinitialize: false,
  });
  useEffect(() => {
    if (values.course) {
      getCourseDataForGroup(values.course);
    }
  }, [getCourseDataForGroup, values.course]);

  useEffect(() => {
    if (data?.price) {
      setFieldValue('price', data.price);
    }
    if (!data?.price) {
      setFieldValue('price', '');
    }
  }, [setFieldValue, data?.price]);

  useEffect(() => {
    if (data?.currency) {
      setFieldValue(
        'currency',
        CURRENCY_OPTIONS.find((currency) => currency.value === data.currency),
      );
    }
    if (!data?.currency) {
      setFieldValue('currency', null);
    }
  }, [setFieldValue, data?.currency]);

  const onAddLessonDay = useCallback(
    () =>
      setFieldValue('lessonsDays', [
        ...values.lessonsDays,
        {
          weekDay: null,
          startTime: TIME_OPTIONS[19],
          endTime: TIME_OPTIONS[22],
          id: values.lessonsDays.length,
        },
      ]),
    [values, setFieldValue],
  );

  const onDeleteLessonDay = useCallback(() => {
    setFieldValue('lessonsDays', R.dropLast(1, values.lessonsDays));
  }, [values, setFieldValue]);

  const handleChange = useCallback(
    (
      value:
        | ValueType<OptionTypeBase, false>
        | string
        | Date
        | null
        | undefined,
      name: string,
    ) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (success) {
      addToast({
        data: {
          title: `${t('groupPopup.groupAddSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.GroupAdd,
      });
      onClose();
    }
  }, [onClose, success, addToast, t]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IAddGroupPopupValues>();

  useEffect(() => {
    const clientErrors = Object.values(errors).length;
    if (clientErrors) {
      sortErrors(errors as ErrorsType<IAddGroupPopupValues>);
      return;
    }
    if (serverErrors) {
      sortErrors(serverErrors);
      return;
    }

    sortErrors(null);
  }, [errors, serverErrors, sortErrors]);

  return (
    <AddGroupPopupComponent
      onClose={onClose}
      onChangeInput={handleChange}
      handleSubmit={handleSubmit}
      onAddLessonDay={onAddLessonDay}
      onDeleteLessonDay={onDeleteLessonDay}
      values={values}
      errors={sortedErrors}
      errorMessage={message}
      courses={courses}
      modules={modules}
    />
  );
});
