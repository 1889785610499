import { gql } from '@apollo/client';
import { USER_FIELDS } from '../fragments';

export const UsersSettingsQuery = gql`
  ${USER_FIELDS}
  query PaginatedUsersSettings($query: String, $first: Int, $after: String) {
    allUsersSettings(first: $first, query: $query, after: $after) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...UserFields
        }
      }
    }
  }
`;
