import React, { memo } from 'react';
import styled from 'styled-components';
import { OptionTypeBase, ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';

import {
  DateSelect,
  Heading,
  Icon,
  IconButton,
  IconsNames,
  Input,
  PaymentNumber,
  Row,
  Text,
  Title,
} from 'components/atoms';

import { AccountsPayableType, IconSizes, ErrorsType } from 'types';
import { Colors } from 'services/ColorService';
import { IPayments } from './StudentPaymentPopup';
import { AccountsPayablesForm } from './AccountsPayablesForm';

export type AccountsPayablesHandler<T> = (value: T, index: number) => void;
export type PaymentInputHandler<T> = (
  value: T,
  inputName: string,
  index: number,
) => void;

interface IPaymentsForm {
  values: IPaymentFormValues;
  errors?: ErrorsType<IPaymentFormValues & { price: number }> | null;
  accountsPayables?: AccountsPayableType[];
  onChangeAccountPayable: AccountsPayablesHandler<
    ValueType<OptionTypeBase, false>
  >;
  onChangePayment: PaymentInputHandler<string | Date | null>;
  onAddPayment: () => void;
  onClosePayment: (index: number) => void;
  onAddAccountPayable: () => void;
  onCloseAccountPayment: (index: number) => void;
}

export interface IPaymentFormValues {
  payments: IPayments[];
  accountsPayables: AccountsPayableType[];
}

export const PaymentsForm = memo(
  ({
    values,
    errors,
    accountsPayables,
    onChangePayment,
    onChangeAccountPayable,
    onAddPayment,
    onClosePayment,
    onAddAccountPayable,
    onCloseAccountPayment,
  }: IPaymentsForm) => {
    const { t } = useTranslation();
    return (
      <>
        <Heading>{t('paymentForm.paymentsPlan')}</Heading>
        <AccountsPayablesForm
          values={values}
          errors={errors}
          accountsPayables={accountsPayables}
          onAddAccountPayable={onAddAccountPayable}
          onChangeAccountPayable={onChangeAccountPayable}
          onCloseAccountPayment={onCloseAccountPayment}
        />
        <PaymentsTitle>
          <WrapperColumn width="48px">
            <TextBold>№</TextBold>
          </WrapperColumn>
          <WrapperColumn width="336px">
            <TextBold>{t('paymentForm.paymentDate')}</TextBold>
          </WrapperColumn>
          <WrapperColumn width="352px">
            <TextBold>{t('paymentForm.paymentSum')}</TextBold>
          </WrapperColumn>
        </PaymentsTitle>

        <Container>
          {values.payments.map(
            ({ date, sum, id, isPaid }: IPayments, index: number) => (
              <React.Fragment key={id}>
                <Row>
                  <PaymentNumber number={index + 1} />
                  <WrappedDateSelect
                    onChange={(value: Date) =>
                      onChangePayment(value, 'date', index)
                    }
                    value={date}
                    hasError={Boolean(errors?.payments?.[index]?.date)}
                    disabled={isPaid}
                  />
                </Row>

                <InputWithButtton>
                  <Input
                    placeholder={t('paymentForm.fields.payment.placeholder')}
                    type="text"
                    value={sum}
                    name="payment"
                    hasError={Boolean(
                      errors?.price?.fieldName === 'payments' ||
                        errors?.payments?.[index]?.sum,
                    )}
                    onChange={({ target: { value } }) =>
                      onChangePayment(value, 'sum', index)
                    }
                    onKeyPress={(event) =>
                      !new RegExp(/[0-9.]/).test(event.key) &&
                      event.preventDefault()
                    }
                    financialNumber
                    disabled={isPaid}
                  />

                  <CloseIconWrapper>
                    {isPaid ? (
                      <IconWrapper>
                        <TitleWrapper text={t('paymentForm.isPaid')} />

                        <Icon name={IconsNames.InfoGrey} />
                      </IconWrapper>
                    ) : (
                      <IconButton
                        name={IconsNames.CloseIconSquare}
                        size={IconSizes.xl}
                        onClick={() => onClosePayment(index)}
                      />
                    )}
                  </CloseIconWrapper>
                </InputWithButtton>
              </React.Fragment>
            ),
          )}

          <Row>
            <IconButtonStyled
              name={IconsNames.PlusLargeIcon}
              size={IconSizes.xl}
              onClick={onAddPayment}
            />
            <Text size="md" colors={{ color: Colors.PrimaryBlack }}>
              {t('paymentForm.button.addAnotherPayment')}
            </Text>
          </Row>
        </Container>
      </>
    );
  },
);

const CloseIconWrapper = styled.div`
  margin-left: 16px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  margin: 32px 0 48px 0;
`;

const InputWithButtton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WrappedDateSelect = styled((props) => <DateSelect {...props} />)`
  flex-grow: 1;
`;

const WrapperColumn = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  flex-direction: column;
`;

const PaymentsTitle = styled.div`
  padding: 14px 16px;
  margin-top: 16px;
  display: flex;
  background-color: ${Colors.MainBackground};
`;

const TextBold = styled((props) => <Text {...props} />)`
  font-size: 12px;
  font-weight: 800;
`;

const IconButtonStyled = styled((props) => <IconButton {...props} />)`
  margin-right: 16px;
`;

const IconWrapper = styled.div`
  width: 48px;
  display: flex;
  justify-content: center;
  position: relative;

  :hover {
    & svg {
      & > path {
        fill: ${Colors.Blue};
      }
    }
  }
`;

const TitleWrapper = styled((props) => <Title {...props} />)`
  display: none;

  ${IconWrapper}:hover & {
    display: block;
  }
`;
