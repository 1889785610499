import React, { memo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { ValueType, OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';

import { PopupNames } from 'core';
import {
  useCheckCredentials,
  usePopupStateOperations,
  useSortErrors,
} from 'core/hooks';
import { getStudentDataSchema } from 'services/YupValidationService';
import { UserRole, ErrorsType } from 'types';

import {
  AddStudentPopupComponent,
  IAddStudentPopupValues,
} from './AddStudentPopup';

export const AddStudentPopup = memo(() => {
  const { t } = useTranslation();
  const {
    operations: { hidePopup, showPopup },
  } = usePopupStateOperations();
  const onClose = useCallback(() => hidePopup(PopupNames.addStudent), [
    hidePopup,
  ]);
  const [
    checkUserExist,
    { user, code, loading, errors: serverErrors },
  ] = useCheckCredentials();

  const onSubmit = useCallback(
    ({ email, phone }: { email: string; phone: string }) => {
      if (!loading) {
        checkUserExist({ email, phone });
      }
    },
    [checkUserExist, loading],
  );

  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      phone: '',
    },
    validateOnChange: false,
    validationSchema: getStudentDataSchema(t),
    onSubmit,
    enableReinitialize: true,
  });

  const handleChange = useCallback(
    (
      value: string | boolean | number | ValueType<OptionTypeBase, false>,
      name: string,
    ) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (code === 'success') {
      showPopup(PopupNames.studentPayment, {
        studentInput: {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
        },
      });
    }

    if (user) {
      const unique = values.phone === user.phone ? 'phone' : 'email';
      if (code === 'user_exist') {
        showPopup(PopupNames.appendStudent, {
          unique: { [unique]: user[unique] },
          pk: user?.id,
          phone: user?.phone || '',
          email: user?.email || '',
          existRoles: (user?.roles as UserRole[]) || [],
          applyRoles: [UserRole.STUDENT],
        });
      }

      if (code === 'student_exist') {
        showPopup(PopupNames.studentExist, {
          unique: { [unique]: user[unique] },
          name: `${user?.lastName} ${user?.firstName} ${user?.middleName}`,
          studentId: user?.id,
        });
      }
    }
  }, [code, showPopup, user, values]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IAddStudentPopupValues>();

  useEffect(() => {
    if (serverErrors) {
      sortErrors(serverErrors);
    }
  }, [serverErrors, sortErrors]);

  useEffect(() => {
    sortErrors(errors as ErrorsType<IAddStudentPopupValues>);
  }, [errors, sortErrors]);

  return (
    <AddStudentPopupComponent
      handleSubmit={handleSubmit}
      onChangeInput={handleChange}
      values={values}
      onClose={onClose}
      errors={sortedErrors}
      errorsMessage={message}
    />
  );
});
