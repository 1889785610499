import { Currencies, IPaymentInfo } from 'components/types';

export const parseDebtorsInfo = (
  str: string,
  paymentsDate: Date,
): IPaymentInfo[] => {
  const regex = /(?<username>.+)\/(?<contract>.+)\/(?<cost>.+)\/(?<currency>.+)/g;
  const results = str.matchAll(regex);

  const resultsArr = Array.from(results).map(
    (result): IPaymentInfo => ({
      date: paymentsDate,
      contractNumber: (result.groups && result.groups.contract.trim()) || '',
      sum: Number(result.groups && result.groups.cost.trim()),
      sumCurrency: (result.groups &&
        result.groups.currency.trim()) as Currencies,
      parsedUsername: (result.groups && result.groups.username.trim()) || '',
      unparsed: result[0].trim(),
    }),
  );

  return resultsArr;
};

export const parseEdges = <
  NodeType,
  EdgeType extends ({ node?: NodeType | null } | null)[],
  parsedEdgeType
>(
  edges: EdgeType,
  callback: (node: NodeType) => parsedEdgeType,
) =>
  edges.reduce((lessons: parsedEdgeType[], edge) => {
    if (!edge?.node) {
      return lessons;
    }

    return [...lessons, callback(edge.node)];
  }, []);
