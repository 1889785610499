import queryString from 'query-string';

class QueryService {
  private queryString: typeof queryString;

  constructor() {
    this.queryString = queryString;
  }

  private parseUrl(url: string) {
    return this.queryString.parse(url, { decode: true, arrayFormat: 'none' });
  }

  public getParam(url: string, paramName: string) {
    const params = this.parseUrl(url);
    const param = params[paramName];
    if (Array.isArray(param)) {
      return param.length > 0 ? param[0] : null;
    }
    if (!param) {
      return null;
    }
    return param;
  }

  public getToken(url: string): string | null {
    return this.getParam(url, 'token');
  }

  public getCode(url: string): string | null {
    return this.getParam(url, 'code');
  }

  public getTo(url: string): string | null {
    return this.getParam(url, 'state');
  }
}

export const queryService = new QueryService();
