import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { PopupNames } from 'core';
import {
  SearchNames,
  useAddRolesUser,
  usePopupStateOperations,
  useSearch,
  useToasts,
} from 'core/hooks';

import { UserExistPopupComponent } from 'molecules';
import { GlobalToastTypeNames, ToastTypes } from 'components/types';

export const UserRolesPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const {
    operations: { showPopup, hidePopup },
  } = usePopupStateOperations();
  const [
    addRoles,
    { unique, user, loading, success, error },
  ] = useAddRolesUser();

  const { setSearchQuery } = useSearch(SearchNames.users);

  const handleClose = useCallback(() => {
    hidePopup(PopupNames.addRolesUser);
  }, [hidePopup]);

  const handleCancel = useCallback(() => {
    showPopup(PopupNames.addUser);
  }, [showPopup]);

  const handleApply = useCallback(() => {
    addRoles();
  }, [addRoles]);

  useEffect(() => {
    if (success) {
      setSearchQuery('');
      addToast({
        data: {
          title: `${t('userExistPopup.rolesAddSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.UserRoleAdd,
      });
      hidePopup(PopupNames.addRolesUser);
    }

    if (error) {
      addToast({
        data: {
          title: `${t('userExistPopup.error')}`,
          type: ToastTypes.error,
        },
        type: GlobalToastTypeNames.UserRoleAdd,
      });
    }
  }, [success, error, hidePopup, setSearchQuery, addToast, t]);

  return user ? (
    <UserExistPopupWrapper>
      <UserExistPopupComponent
        unique={{
          phone: (unique.phone && user.phone) || undefined,
          email: (unique.email && user.email) || undefined,
        }}
        existRoles={user.existRoles}
        appendRoles={user.applyRoles}
        loading={loading}
        onClose={handleClose}
        onCancelClick={handleCancel}
        onAcceptClick={handleApply}
      />
    </UserExistPopupWrapper>
  ) : null;
});

const UserExistPopupWrapper = styled.div`
  width: 864px;
`;
