import * as React from 'react';
import { memo, useCallback } from 'react';

import { PopupNames } from 'core';
import { usePopupData, usePopupStateOperations } from 'core/hooks';

import { UserRolesPopupComponent } from './UserRolesPopup';

export const AppendStudentPopup = memo(() => {
  const user = usePopupData(PopupNames.appendStudent);

  const {
    operations: { showPopup, hidePopup },
  } = usePopupStateOperations();

  const handleClose = useCallback(() => {
    hidePopup(PopupNames.appendStudent);
  }, [hidePopup]);

  const handleCancel = useCallback(() => {
    showPopup(PopupNames.addStudent);
  }, [showPopup]);

  const handleApply = useCallback(() => {
    showPopup(PopupNames.studentPayment, { userId: user?.pk });
  }, [showPopup, user?.pk]);

  return user ? (
    <UserRolesPopupComponent
      unique={user.unique}
      user={{
        phone: user.phone || '',
        email: user.email || '',
        existRoles: user.existRoles,
        applyRoles: user.applyRoles,
      }}
      loading={false}
      onClose={handleClose}
      onCancel={handleCancel}
      onApply={handleApply}
    />
  ) : null;
});
