import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { PopupNames } from 'core';
import {
  useCheckPaymentsDebt,
  usePopupStateOperations,
  useSortErrors,
  useUnparsedPayments,
} from 'core/hooks';

import { Currencies, IPaymentInfo, ErrorsType, FieldErrorTypes } from 'types';

import { WRONG_FORMAT, _paymentCopyPast } from 'services/ValidationService';
import { yupValidation, _numbersRegExp } from 'services/YupValidationService';
import { parseDebtorsInfo } from 'services/ParsingService';

import {
  IPaymentAddPopupValues,
  PaymentAddPopupComponent,
} from './PaymentAddPopup';

export const PaymentAddPopup = memo(() => {
  const { t } = useTranslation();
  const {
    operations: { showPopup, hidePopup },
  } = usePopupStateOperations();

  const [setPopupData, { unparsedPayments: popupData }] = useUnparsedPayments();

  const onClose = useCallback(() => {
    hidePopup(PopupNames.addPayment);
  }, [hidePopup]);

  const paymentAddSchema = Yup.object().shape({
    date: yupValidation.date(`${t('paymentPopup.fields.date.label')}`),
    data: Yup.string()
      .required(() => ({
        type: FieldErrorTypes.EMPTY,
        fieldName: `${t('paymentPopup.fields.paymentData.label')}`,
      }))
      .matches(_paymentCopyPast, () => ({
        type: FieldErrorTypes.VALIDATION,
        message: WRONG_FORMAT,
      })),
    parsedPayments: Yup.array()
      .of(Yup.object())
      .test(
        '',
        {
          type: FieldErrorTypes.VALIDATION,
          message: `${t(
            'paymentPopup.validation.paymentData.incorrectFormat',
          )}`,
          field: 'data',
        },
        (values) => {
          const isValid = values?.every((payment) =>
            _numbersRegExp.test(String(payment.sum)),
          );
          return Boolean(isValid);
        },
      )
      .test(
        'isValidPayments',
        {
          type: FieldErrorTypes.VALIDATION,
          message: `${t(
            'paymentPopup.validation.paymentData.incorrectCurrency',
          )}`,
          field: 'data',
        },
        (values) =>
          !(
            values?.some(
              (value) =>
                !(String(value.sumCurrency).toUpperCase() in Currencies),
            ) || false
          ),
      ),
  });

  const [
    checkPaymentsDebt,
    { success, errors: checkPaymentsDebtErrors },
  ] = useCheckPaymentsDebt();

  const onSubmitHandler = useCallback(
    (values: IPaymentAddPopupValues) => {
      setPopupData(values.data);
      showPopup(PopupNames.checkPayment, { payments: values.parsedPayments });
    },
    [setPopupData, showPopup],
  );

  const { values, errors, handleSubmit, setFieldValue, submitForm } = useFormik(
    {
      initialValues: {
        date: new Date(),
        data: popupData || '',
        parsedPayments: [],
      },
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: paymentAddSchema,
      onSubmit: onSubmitHandler,
    },
  );

  const handleChange = useCallback(
    (value: string | Date | null, name: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const onButtonClick = useCallback(() => {
    const debtorsInfo: IPaymentInfo[] = parseDebtorsInfo(
      values.data,
      values.date,
    );

    if (debtorsInfo.length) {
      checkPaymentsDebt(debtorsInfo);
    }

    const newParsedPayments = debtorsInfo.map((payment) => ({
      ...payment,
      sumCurrency: payment.sumCurrency.toUpperCase(),
    }));

    setFieldValue('parsedPayments', newParsedPayments, true);
  }, [values, setFieldValue, checkPaymentsDebt]);

  useEffect(() => {
    if (success) {
      if (values.parsedPayments.length && !Object.keys(errors).length) {
        submitForm();
      }
    }
  }, [values.parsedPayments, errors, submitForm, success]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IPaymentAddPopupValues>();

  useEffect(() => {
    sortErrors({
      ...(errors as ErrorsType<IPaymentAddPopupValues>),
      ...checkPaymentsDebtErrors,
    });
  }, [errors, sortErrors, checkPaymentsDebtErrors]);

  return (
    <PaymentAddPopupComponent
      values={values}
      onClose={onClose}
      handleSubmit={handleSubmit}
      onInputChange={handleChange}
      onButtonClick={onButtonClick}
      errors={sortedErrors}
      errorMessage={message}
    />
  );
});
