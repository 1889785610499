import { useCallback, useMemo } from 'react';

import {
  CourseType,
  Maybe,
  GroupType,
  useGetCoursesForOptionsLazyQuery,
} from 'core/graphql';

import { Currencies } from 'types';

import { parseEdges } from 'services/ParsingService';

type CourseNodeType = { __typename?: 'CourseType' } & Pick<
  CourseType,
  'id' | 'name'
> & {
    groups: { __typename?: 'MethodistGroupTypeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'MethodistGroupTypeEdge' } & {
            node?: Maybe<
              { __typename?: 'MethodistGroupType' } & Pick<
                GroupType,
                'id' | 'name' | 'price' | 'currency'
              >
            >;
          }
        >
      >;
    };
  };

export const useCoursesForOptions = () => {
  const [getCoursesQuery, { data, loading }] = useGetCoursesForOptionsLazyQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const getCourses = useCallback(
    (date: string) => {
      getCoursesQuery({
        variables: {
          agreementDate: date,
        },
      });
    },
    [getCoursesQuery],
  );

  const courses = useMemo(
    () =>
      parseEdges(
        data?.allCourses?.edges || [],
        ({ id, name, groups }: CourseNodeType) => ({
          value: id,
          label: name,
          groups: parseEdges(
            groups.edges,
            (group: Pick<GroupType, 'id' | 'name' | 'price' | 'currency'>) => ({
              value: group.id,
              label: group.name,
              price: group.price || 0,
              currency: group.currency
                ? Currencies[group.currency as keyof typeof Currencies]
                : undefined,
            }),
          ),
        }),
      ),
    [data?.allCourses?.edges],
  );

  return {
    getCourses,
    data: courses,
    loading,
  };
};
