import { IApplication, OptionsType, TeacherEmploymentTypes } from 'types';
import {
  ApplicationEdge,
  GetCoursesForTeacherQuery,
  GetCourseDataForApplicationQuery,
} from 'core/graphql';

export const transformApplicationsEdges = (edges: ApplicationEdge[]) =>
  edges.reduce((acc: IApplication[], edge) => {
    if (edge) {
      const applicationArr = transformApplicationsNode(edge as ApplicationEdge);
      if (applicationArr) {
        return [...acc, applicationArr];
      }
    }

    return acc;
  }, []);

export const transformApplicationsNode = (
  application: ApplicationEdge,
): IApplication | undefined => {
  const { node } = application;
  if (node) {
    return {
      id: node.id,
      applicationNumber: node.title,
      fullName: node.teacher.fullName || '',
      groupNumber: node.group.name,
      lessonsCount: `${node.lessonsCount}/${node.appointedLessonsCount}`,
      price: node.servicePrice
        ? `${
            Number.isInteger(node.servicePrice)
              ? Number(node.servicePrice).toFixed(1)
              : node.servicePrice
          } ${node.currency}`
        : '',
      teacherType:
        TeacherEmploymentTypes[
          node.employmentType?.__typename as keyof typeof TeacherEmploymentTypes
        ],
      isCompleted: node.isCompleted || false,
      isTerminated: node.isTerminated,
    };
  }
};

export const transformCoursesData = (
  data: GetCoursesForTeacherQuery,
): OptionsType | undefined =>
  data?.allCourses?.edges.map((course) => ({
    value: course?.node?.id || '',
    label: course?.node?.name || '',
  }));

export const transformGroupsData = (data: GetCourseDataForApplicationQuery) =>
  data?.course?.groups.edges.map((group) => ({
    value: group?.node?.id || '',
    label: group?.node?.name || '',
    lessonsCount: group?.node?.freeLessonsCount || 0,
    appointedLessonsCount: 0,
  }));
