import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { PATHS } from 'router/config';

export const useGoToLogin = () => {
  const history = useHistory();

  const goToLogin = useCallback(() => {
    history.push(PATHS.login);
  }, [history]);

  return goToLogin;
};
