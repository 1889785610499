import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ICreateGroup,
  ServerCodes,
  ToastTypes,
  ErrorsType,
  GlobalToastTypeNames,
} from 'types';

import { useAddGroupMutation, CustomErrorType } from 'core/graphql';

import { useToasts } from 'core/hooks';
import { IAddGroupPopupValues } from 'molecules';

import { GroupsQuery, SettingsMetaQuery } from 'core/operations/queries';

import { groupErrorReducer, getGroupErrors } from './reducer';

type ICreateGroupHookType = [
  (group: ICreateGroup) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<IAddGroupPopupValues> | null;
  },
];

export const useCreateGroup = (): ICreateGroupHookType => {
  const { t } = useTranslation();
  const [addGroupMutation, { data, loading }] = useAddGroupMutation({
    refetchQueries: [
      {
        query: GroupsQuery,
        variables: {
          first: 10,
        },
      },
      {
        query: SettingsMetaQuery,
      },
    ],
  });
  const [errors, dispatch] = useReducer(groupErrorReducer, null);
  const { addToast } = useToasts();

  useEffect(() => {
    const serverErrors = data?.createGroup?.customErrors;
    if (serverErrors && serverErrors.length) {
      const errorsFound = getGroupErrors(serverErrors as CustomErrorType[]);

      switch (true) {
        case errorsFound.groupExist:
          dispatch({ type: ServerCodes.groupExist });
          break;
        case errorsFound.lessonExist:
          dispatch({ type: ServerCodes.lessonExist });
          break;

        default:
          addToast({
            data: {
              title: `${t('common.somethingWentWrong')}`,
              type: ToastTypes.error,
            },
            type: GlobalToastTypeNames.GroupAdd,
          });
      }
    }
  }, [data?.createGroup?.customErrors, addToast, t]);

  const createGroup = useCallback(
    (group: ICreateGroup) => {
      addGroupMutation({
        variables: {
          groupInput: {
            name: group.groupInput.name,
            price: group.groupInput.price,
            currency: group.groupInput.currency,
            startDate: group.groupInput.startDate,
            course_id: group.groupInput.course_id,
            daysOfClass: group.groupInput.daysOfClass,
          },
          moduleInput: group.moduleInput,
        },
      });
    },
    [addGroupMutation],
  );

  return [
    createGroup,
    {
      success: data?.createGroup?.success || false,
      loading,
      errors,
    },
  ];
};
