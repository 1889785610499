import { gql } from '@apollo/client';

export const TeachersQuery = gql`
  query allTeachers(
    $query: String
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    allTeachers(
      query: $query
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          accessToLms
          fullName
          isDismissed
          user {
            phone
            email
          }
          courses
        }
      }
    }
  }
`;

export const VerifyTeacherQuery = gql`
  query verifyTeacher(
    $firstName: String!
    $middleName: String
    $lastName: String!
    $phone: String!
    $email: String!
  ) {
    verifyTeacher(
      teacherInput: {
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        phone: $phone
        email: $email
      }
    ) {
      code
      customErrors {
        field
        message
        code
      }
      user {
        id
        firstName
        middleName
        lastName
        phone
        email
        roles
      }
      teacher {
        id
        user {
          firstName
          middleName
          lastName
          phone
          email
        }
      }
    }
  }
`;

export const GetTeacherData = gql`
  query getTeacherData($teacherId: ID) {
    teacher(teacherId: $teacherId) {
      employmentTypes {
        __typename
      }
    }
  }
`;

export const GetTeacherTypes = gql`
  query GetTeacherTypes($teacherId: ID!) {
    teacher(teacherId: $teacherId) {
      employmentTypes {
        ... on IndividualEntrepreneurType {
          __typename
          id
          registrationAddress
          accountNumber
          bankTitle
          bic
          inn
        }

        ... on LegalEntityType {
          __typename
          id
          registrationAddress
          accountNumber
          bankTitle
          bic
          tittle
          inn
        }

        ... on SelfEmployedType {
          __typename
          id
          registrationAddress
          accountNumber
          bankTitle
          bic
          nationality {
            id
            title
          }
          passportNumber
          inn
        }

        ... on NaturalPersonType {
          __typename
          id
          registrationAddress
          accountNumber
          bankTitle
          bic
          nationality {
            id
            title
          }
          passportNumber
        }
      }
    }
  }
`;

export const GetEditTeacher = gql`
  query GetEditTeacher($teacherId: ID!) {
    teacher(teacherId: $teacherId) {
      id
      user {
        firstName
        lastName
        middleName
        email
        phone
      }
      accessToLms
      siteDisplay
      employmentTypes {
        ... on IndividualEntrepreneurType {
          __typename
          id
          registrationAddress
          accountNumber
          bankTitle
          bic
          inn
        }

        ... on LegalEntityType {
          __typename
          id
          registrationAddress
          accountNumber
          bankTitle
          bic
          tittle
          inn
        }

        ... on SelfEmployedType {
          __typename
          id
          registrationAddress
          accountNumber
          bankTitle
          bic
          nationality {
            id
            title
          }
          passportNumber
          inn
        }

        ... on NaturalPersonType {
          __typename
          id
          registrationAddress
          accountNumber
          bankTitle
          bic
          nationality {
            id
            title
          }
          passportNumber
        }
      }
    }
  }
`;
