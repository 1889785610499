import React, { memo, useEffect, useCallback } from 'react';

import styled from 'styled-components';

import { CoursesTable } from 'molecules';
import { useCourses, useSearch, SearchNames } from 'core/hooks';

export const Courses = memo(() => {
  const {
    courses,
    loading,
    error,
    pageInfo,
    operations: { fetchMoreData },
  } = useCourses();
  const { searchQuery } = useSearch(SearchNames.settings);

  const loadMore = useCallback(() => {
    const vars = {
      query: searchQuery || null,
      after: pageInfo?.endCursor,
    };

    fetchMoreData(vars);
  }, [fetchMoreData, pageInfo?.endCursor, searchQuery]);

  useEffect(() => {
    const query = searchQuery.trim();

    fetchMoreData({ query });
  }, [fetchMoreData, searchQuery]);

  return (
    <CoursesTableWrapper>
      <CoursesTable
        courses={courses}
        loading={loading}
        error={error}
        hasMore={pageInfo?.hasNextPage || false}
        loadMore={loadMore}
      />
    </CoursesTableWrapper>
  );
});

const CoursesTableWrapper = styled.div`
  margin-top: 24px;
`;
