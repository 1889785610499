import * as React from 'react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { ToastTypes, UserRole, ErrorsType, GlobalToastTypeNames } from 'types';

import { PopupNames } from 'core';
import {
  useEditUser,
  usePopupStateOperations,
  useSearch,
  SearchNames,
  useSortErrors,
  useToasts,
} from 'core/hooks';

import {
  UserPopupComponent,
  IToggleCheckBoxButtons,
  IUserPopupValues,
} from 'molecules';
import { getUserSchema } from '../UserPopup/userSchema';

export const UserEditPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const {
    operations: { showPopup, hidePopup },
  } = usePopupStateOperations();

  const [
    editUser,
    { user, errors: updateUserErrors, getUserLoading, loading, success },
  ] = useEditUser();

  const { setSearchQuery } = useSearch(SearchNames.users);

  const onSubmitHandler = useCallback(
    (values: IUserPopupValues) => {
      const userRoles = Object.keys(values.roles).filter(
        (role) => values.roles[role],
      );

      const userFields = {
        firstName: values.firstname,
        middleName: values.middlename,
        lastName: values.lastname,
        email: values.email,
        phone: values.phone,
        roles: userRoles as UserRole[],
      };

      if (user) {
        editUser({ ...userFields, pk: user.pk });
      }
    },
    [user, editUser],
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      firstname: user?.firstName || '',
      lastname: user?.lastName || '',
      middlename: user?.middleName || '',
      email: user?.email || '',
      phone: user?.phone || '',
      roles: {
        [UserRole.ADMIN]: user?.roles.includes(UserRole.ADMIN) || false,
        [UserRole.ACCOUNTANT]:
          user?.roles.includes(UserRole.ACCOUNTANT) || false,
        [UserRole.FINANCIER]: user?.roles.includes(UserRole.FINANCIER) || false,
        [UserRole.HRMANAGER]: user?.roles.includes(UserRole.HRMANAGER) || false,
        [UserRole.SALESMANAGER]:
          user?.roles.includes(UserRole.SALESMANAGER) || false,
        [UserRole.METHODIST]: user?.roles.includes(UserRole.METHODIST) || false,
      },
    },
    validationSchema: getUserSchema(t),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
  });

  const disabledButton: boolean = useMemo(() => {
    const newValues = R.omit(['roles', 'middlename'], values);
    const firstCheck = R.values(newValues).every((currentValue) =>
      Boolean(currentValue),
    );
    const secondCheck = R.values(values.roles).some((currentValue) =>
      Boolean(currentValue),
    );
    const result = firstCheck && secondCheck;
    return result;
  }, [values]);

  useEffect(() => {
    if (success) {
      setSearchQuery('');
      addToast({
        data: {
          title: `${t('userPopup.userUpdateSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.UserEdit,
      });
      hidePopup(PopupNames.addUser);
    }
  }, [success, hidePopup, setSearchQuery, addToast, t]);

  const handleChange = useCallback(
    (value: string | IToggleCheckBoxButtons, name: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const handleDeleteClick = useCallback(() => {
    showPopup(PopupNames.deleteUser);
  }, [showPopup]);

  const onCloseHandler = useCallback(() => {
    hidePopup(PopupNames.addUser);
  }, [hidePopup]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IUserPopupValues>();

  useEffect(() => {
    const clientErrors = Object.values(errors).length;
    if (clientErrors) {
      sortErrors(errors as ErrorsType<IUserPopupValues>);
      return;
    }
    if (updateUserErrors) {
      sortErrors(updateUserErrors);
      return;
    }

    sortErrors(null);
  }, [errors, updateUserErrors, sortErrors]);

  return getUserLoading ? null : (
    <UserPopupComponent
      editing={Boolean(user)}
      onDelete={handleDeleteClick}
      onClose={onCloseHandler}
      onInputChange={handleChange}
      handleSubmit={handleSubmit}
      values={values}
      disabledButton={!disabledButton}
      errors={sortedErrors}
      errorMessage={message}
      loading={loading}
    />
  );
});
