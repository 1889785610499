import { useReactiveVar } from '@apollo/client';
import { authStateVar, appState } from '../../state';

export interface IAppStateOperations {
  setBootstrappedToTrue: () => void;
  setBootstrappedToFalse: () => void;
}

export const getAppStateOperations = (
  stateVar = appState,
): IAppStateOperations => {
  const setBootstrappedTo = (bootstrapped: boolean) => () => {
    stateVar({ ...stateVar(), bootstrapped });
  };

  const setBootstrappedToTrue = setBootstrappedTo(true);
  const setBootstrappedToFalse = setBootstrappedTo(false);

  return { setBootstrappedToTrue, setBootstrappedToFalse };
};

const { setBootstrappedToTrue, setBootstrappedToFalse } = getAppStateOperations(
  appState,
);

export const useAppState = (stateVar = appState) => {
  const data = useReactiveVar(stateVar);

  return {
    data,
    operations: {
      setBootstrapped: setBootstrappedToTrue,
      setNotBootstrapped: setBootstrappedToFalse,
    },
  };
};

export interface IAuthStateOperations {
  setLogout: () => void;
  setAccessToken: (access_token: string) => void;
}

export const getAuthStateOpeations = (
  authState = authStateVar,
): IAuthStateOperations => {
  const setAuthState = (access_token: string, isLoggedIn: boolean) => {
    authState({ ...authState(), access_token, isLoggedIn });
  };

  const setAccessToken = (access_token: string) =>
    setAuthState(access_token, true);

  const setLogout = () => setAuthState('', false);

  return { setAccessToken, setLogout };
};

const { setLogout, setAccessToken } = getAuthStateOpeations(authStateVar);

export const useAuthState = (authState = authStateVar) => {
  const data = useReactiveVar(authState);

  return {
    data,
    operations: {
      setAccessToken,
      setLogout,
    },
  };
};
