import { useCallback } from 'react';

import { nanoid } from 'nanoid';

import { appState } from 'core';
import { IAppGlobalToast } from 'core/state';

type NewToastType = Omit<IAppGlobalToast, 'id'>;

type AddNewToastHookType = {
  addToast: (toast: NewToastType) => void;
  removeToast: (toastId: string) => void;
};

export const useToasts = (): AddNewToastHookType => {
  const addToast = useCallback((newToast: NewToastType) => {
    const toastToAdd: IAppGlobalToast = {
      id: nanoid(6),
      ...newToast,
    };

    const { toasts, ...currentState } = appState();

    if (toasts && toasts.length) {
      const updatedToasts = toasts.reduce(
        (result: IAppGlobalToast[]) => [
          ...result.filter(
            (existingToast) => existingToast.type !== newToast.type,
          ),
          {
            ...newToast,
            id: nanoid(6),
          },
        ],
        toasts,
      );

      appState({
        ...currentState,
        toasts: updatedToasts || [],
      });

      return;
    }

    appState({
      ...currentState,
      toasts: [toastToAdd],
    });
  }, []);

  const removeToast = useCallback((toastId: string) => {
    const currentState = appState();

    appState({
      ...currentState,
      toasts:
        currentState.toasts?.filter((toast) => toast.id !== toastId) || [],
    });
  }, []);

  return {
    addToast,
    removeToast,
  };
};
