import { useGetUserByIdLazyQuery } from 'core/graphql';

import { useCallback } from 'react';

import { IUser, UserRole } from 'types';

type GetUserByIdType = [
  (userId: string) => void,
  {
    user: IUser | null;
    getUserLoading: boolean;
  },
];

export const useGetUser = (): GetUserByIdType => {
  const [getUserByIdQuery, { data, loading }] = useGetUserByIdLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const getUserById = useCallback(
    (userId: string) => {
      getUserByIdQuery({ variables: { userId } });
    },
    [getUserByIdQuery],
  );

  const userById = data?.user
    ? {
        pk: data.user.pk || undefined,
        firstName: data.user.firstName || '',
        middleName: data.user.middleName || '',
        lastName: data.user.lastName || '',
        email: data.user.email || '',
        phone: data.user.phone || '',
        roles:
          ((data?.user?.roles as unknown) as UserRole[]) ||
          (([] as unknown) as UserRole[]),
      }
    : null;

  return [
    getUserById,
    {
      user: userById || null,
      getUserLoading: loading,
    },
  ];
};
