import * as React from 'react';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { PopupNames } from 'core';
import { usePopupStateOperations } from 'core/hooks';

import { ITeacherUser } from 'types';

import { TableTeachersComponent, IColumn, IRow } from './TableTeachers';

export interface ITableTeachers {
  data: ITeacherUser[];
  loading?: boolean;
  error?: string;
  loadMore: () => void;
  hasMore: boolean;
}

const getColumnsSource = (t: TFunction): IColumn[] => [
  {
    Header: `${t('table.teachers.name')}`,
    accessor: 'fullName',
    flexible: true,
  },
  {
    Header: `${t('table.teachers.phone')}`,
    accessor: 'phone',
    columnWidth: '132px',
    marginRight: '64px',
    marginLeft: '64px',
  },
  {
    Header: `${t('table.teachers.email')}`,
    accessor: 'email',
    columnWidth: '208px',
  },
  {
    Header: `${t('table.teachers.courses')}`,
    accessor: 'courses',
    flexible: true,
    marginRight: '100px',
    marginLeft: '64px',
  },
];

export const TableTeachers = memo(
  ({ data, loading, error, loadMore, hasMore }: ITableTeachers) => {
    const { t } = useTranslation();
    const columns: IColumn[] = useMemo(() => getColumnsSource(t), [t]);
    const rows: IRow[] = useMemo(() => data, [data]);

    const {
      operations: { showPopup },
    } = usePopupStateOperations();

    const onEditButtonClick = useCallback(
      (teacherId) => {
        showPopup(PopupNames.teacherEditPopup, { teacherId });
      },
      [showPopup],
    );

    const onClickAddApplication = useCallback(
      (teacherId) => {
        showPopup(PopupNames.appendApplicationPopup, { teacherId });
      },
      [showPopup],
    );

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <TableTeachersComponent
        rows={rows}
        columns={columns}
        isFlexible
        noDataText={t('table.teachers.noData')}
        loadMore={loadMore}
        hasMore={hasMore}
        onEditButtonClick={onEditButtonClick}
        onClickAddApplication={onClickAddApplication}
        loading={loading}
      />
    );
  },
);
