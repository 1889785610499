import { useAddUserRolesMutation } from 'core/graphql';

import { useCallback } from 'react';

import { useReactiveVar } from '@apollo/client';
import { popupData, PopupNames } from 'core/state';
import { UsersSettingsQuery } from 'core/operations';

import { UserRole } from 'types';

type AddUserRolesUserType = [
  () => void,
  {
    unique: {
      email: boolean;
      phone: boolean;
    };
    user:
      | {
          phone: string;
          email: string;
          existRoles: UserRole[];
          applyRoles: UserRole[];
          studentId?: string;
        }
      | undefined;
    loading: boolean;
    success: boolean;
    error: string;
  },
];

export const useAddRolesUser = (): AddUserRolesUserType => {
  const addRolesData = useReactiveVar(popupData)[PopupNames.addRolesUser];

  const [
    addUserRolesMutation,
    { data, loading, error },
  ] = useAddUserRolesMutation({
    refetchQueries: [
      {
        query: UsersSettingsQuery,
        variables: {
          first: 10,
        },
      },
    ],
  });

  const addRoles = useCallback(() => {
    addUserRolesMutation({
      variables: {
        roles: addRolesData?.applyRoles.join(',') || '',
        userId: addRolesData?.pk || '',
      },
    });
  }, [addUserRolesMutation, addRolesData]);

  return [
    addRoles,
    {
      unique: {
        email: addRolesData?.unique.email || false,
        phone: addRolesData?.unique.phone || false,
      },
      user: {
        phone: addRolesData?.phone || '',
        email: addRolesData?.email || '',
        existRoles: addRolesData?.existRoles || [],
        applyRoles: addRolesData?.applyRoles || [],
      },
      loading,
      success: Boolean(data?.appendRolesUserSettings?.user?.id) || false,
      error: error?.message || '',
    },
  ];
};
