import React, { memo, useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { IPlannedContribution } from 'types';

import { FinanceTableComponent, IColumn, IRow } from './FinanceTable';

export const getColumnsSource = (t: TFunction): IColumn[] => {
  if (process.env.REACT_APP_LANG === 'pl') {
    return [
      {
        Header: `${t('table.finance.month')}`,
        accessor: 'period',
        flexible: true,
      },
      {
        Header: `${t('table.finance.planCurrencyFirst')}`,
        accessor: 'planPln',
        columnWidth: 128,
        marginLeft: 48,
      },
      {
        Header: `${t('table.finance.factCurrencyFirst')}`,
        accessor: 'factPln',
        columnWidth: 128,
        marginLeft: 48,
      },
      {
        Header: `${t('table.finance.planCurrencyThird')}`,
        accessor: 'planUsd',
        columnWidth: 120,
        marginLeft: 48,
      },
      {
        Header: `${t('table.finance.factCurrencyThird')}`,
        accessor: 'factUsd',
        columnWidth: 120,
        marginLeft: 48,
      },
      {
        Header: `${t('table.finance.planCurrencyFourth')}`,
        accessor: 'planEur',
        columnWidth: 120,
        marginLeft: 48,
      },
      {
        Header: `${t('table.finance.factCurrencyFourth')}`,
        accessor: 'factEur',
        columnWidth: 120,
        marginLeft: 48,
      },
    ];
  }
  return [
    {
      Header: `${t('table.finance.month')}`,
      accessor: 'period',
      flexible: true,
    },
    {
      Header: `${t('table.finance.planCurrencyFirst')}`,
      accessor: 'planByn',
      columnWidth: 88,
      marginLeft: 48,
    },
    {
      Header: `${t('table.finance.factCurrencyFirst')}`,
      accessor: 'factByn',
      columnWidth: 88,
      marginLeft: 48,
    },
    {
      Header: `${t('table.finance.planCurrencySecond')}`,
      accessor: 'planRub',
      columnWidth: 104,
      marginLeft: 48,
    },
    {
      Header: `${t('table.finance.factCurrencySecond')}`,
      accessor: 'factRub',
      columnWidth: 104,
      marginLeft: 48,
    },
    {
      Header: `${t('table.finance.planCurrencyThird')}`,
      accessor: 'planUsd',
      columnWidth: 80,
      marginLeft: 48,
    },
    {
      Header: `${t('table.finance.factCurrencyThird')}`,
      accessor: 'factUsd',
      columnWidth: 80,
      marginLeft: 48,
    },
    {
      Header: `${t('table.finance.planCurrencyFourth')}`,
      accessor: 'planEur',
      columnWidth: 80,
      marginLeft: 48,
    },
    {
      Header: `${t('table.finance.factCurrencyFourth')}`,
      accessor: 'factEur',
      columnWidth: 80,
      marginLeft: 48,
    },
  ];
};

export interface IFinanceTableMonth {
  contribution: IPlannedContribution;
  month: number;
  loading: boolean;
}

export interface IFinanceTableProps {
  data: IFinanceTableMonth[];
  year: number;
  loading?: boolean;
  className?: string;
}

export const FinanceTable = memo(
  ({ data, year, loading, className }: IFinanceTableProps) => {
    const { t } = useTranslation();
    const columns: IColumn[] = useMemo(() => getColumnsSource(t), [t]);
    const rows: IRow[] = useMemo(
      () =>
        data.map((item) => ({
          ...item.contribution,
          month: item.month,
          loading: item.loading,
        })),
      [data],
    );

    return (
      <FinanceTableComponent
        year={year}
        rows={rows}
        columns={columns}
        noDataText={t('table.finance.noData')}
        loading={loading}
        className={className}
      />
    );
  },
);
