import * as React from 'react';
import { memo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

import { MainTemplate } from 'templates';
import { Search, UsersSettingsTable } from 'molecules';
import { Heading } from 'atoms';

import {
  SearchNames,
  usePopupStateOperations,
  useSearch,
  useUsersSettings,
} from 'core/hooks';
import { PopupNames } from 'core';

export const UsersSettings = memo(() => {
  const { t } = useTranslation();
  const {
    data: users,
    loading,
    error,
    pageInfo,
    operations: { fetchMoreData },
  } = useUsersSettings();

  const {
    operations: { showPopup },
  } = usePopupStateOperations();

  const { searchQuery, setSearchQuery } = useSearch(SearchNames.users);

  const onChangeHandler = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery],
  );

  const onClearSearchHandler = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const fetchMore = useCallback(() => {
    const vars = {
      query: searchQuery,
      after: pageInfo?.endCursor,
    };

    fetchMoreData(vars);
  }, [fetchMoreData, pageInfo?.endCursor, searchQuery]);

  const onAddUserClick = useCallback(() => {
    showPopup(PopupNames.addUser);
  }, [showPopup]);

  const editUserHandler = useCallback(
    (userId: string) => {
      showPopup<string>(PopupNames.editUser, userId);
    },
    [showPopup],
  );

  useEffect(() => {
    const query = searchQuery.trim();

    fetchMoreData({ query });
  }, [fetchMoreData, searchQuery]);

  return (
    <MainTemplate>
      <SettingsWrapper>
        <HeadingWrapper>
          <Heading>{t('pages.users.users')}</Heading>
        </HeadingWrapper>
        <SearchWrapper>
          <Search
            value={searchQuery}
            onChange={onChangeHandler}
            clear={onClearSearchHandler}
            placeholder={t('pages.users.searchUser')}
            buttonProps={{
              label: `${t('pages.users.addUser')}`,
              onClick: onAddUserClick,
            }}
          />
        </SearchWrapper>
        <TableWrapper>
          <UsersSettingsTable
            data={users}
            loading={loading}
            error={error}
            editUser={editUserHandler}
            hasMore={pageInfo?.hasNextPage || false}
            loadMore={fetchMore}
          />
        </TableWrapper>
      </SettingsWrapper>
    </MainTemplate>
  );
});

const SettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
`;

const baseWrapperStyles = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HeadingWrapper = styled.div`
  ${baseWrapperStyles};
  margin-top: 48px;
`;

const SearchWrapper = styled.div`
  ${baseWrapperStyles};
  margin-top: 16px;
`;

const TableWrapper = styled.div`
  ${baseWrapperStyles};
  margin-top: 36px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
