export enum PATHS {
  splash_screen = 'SPLASH_SCREEN',
  login = '/login',
  forgot_password = '/forgot-password',
  confirm_email = '/confirm-email',
  create_password = '/password-set',
  recovery_password = '/password-reset',
  restore = '/restore',
  select_provider = '/select-provider',
  edit = 'EDIT',
  main = '/',
  availability = '/availability',
  users = '/users',
  settings = '/settings',
  settingsCourses = '/settings/courses',
  settingsGroups = '/settings/groups',
  settingsJournals = '/settings/journals',
  profile = '/profile',
  no_shops = '/no-shops',
  debtors = '/debtors',
  contributions = '/debtors/contributions',
  deferred = '/debtors/deferred',
  journal = '/journal',
  finances = '/finances',
  monitoring = '/monitoring',
  default = '/debtors',
  students = '/students',
  teachers = '/teachers',
  teachersUsers = '/teachers/users',
  teachersApplications = '/teachers/applications',
  performance = '/performance',
}

export function getAuthPath(path: string = '') {
  return `${path}`;
}

export enum PATHS_PARAMS {
  contributionNum = ':contributionNum',
}
