import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { Link, useLocation, Switch, Route } from 'react-router-dom';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Teachers, Applications } from 'pages';

import styled from 'styled-components';
import { PATHS } from 'router/config';

import { Heading, IconProps, IconsNames } from 'atoms';
import { SettingsTab, Search } from 'molecules';
import { MainTemplate } from 'templates';
import {
  usePopupStateOperations,
  useSearch,
  SearchNames,
  useTeachersMeta,
} from 'core/hooks';
import { PopupNames } from 'core';

enum tabNames {
  users = 'users',
  applications = 'applications',
}

interface ISettingsItems {
  path: string;
  icon: IconProps;
  totalCountName: tabNames;
  name: string;
}

const getSettingsTabs = (t: TFunction): ISettingsItems[] => [
  {
    path: PATHS.teachersUsers,
    icon: {
      name: IconsNames.ProfileIcon,
      height: 24,
      width: 24,
    },
    totalCountName: tabNames.users,
    name: `${t('pages.teachersOptions.teachers')}`,
  },
  {
    path: PATHS.teachersApplications,
    icon: {
      name: IconsNames.ApplicationIcon,
      height: 24,
      width: 24,
    },
    totalCountName: tabNames.applications,
    name: `${t('pages.teachersOptions.applications')}`,
  },
];

export const TeachersOption = memo(() => {
  const { t } = useTranslation();
  const {
    operations: { showPopup },
  } = usePopupStateOperations();
  const loc = useLocation().pathname;
  const { searchQuery, setSearchQuery } = useSearch(SearchNames.teachers);
  const [fetchMoreMeta, { totalCount }] = useTeachersMeta();

  const showTeacherAddPopup = useCallback(() => {
    showPopup(PopupNames.teacherAddPopup);
  }, [showPopup]);

  const onChangeHandler = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery],
  );

  const onClearSearchHandler = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const getTabData = useCallback(
    (path: string) => {
      switch (path) {
        case '/teachers/users':
          return {
            placeholder: `${t('pages.teachersOptions.searchTeacher')}`,
            buttonProps: {
              label: `${t('pages.teachersOptions.addTeacher')}`,
              onClick: showTeacherAddPopup,
            },
          };

        case '/teachers/applications':
          return {
            placeholder: `${t('pages.teachersOptions.searchApplication')}`,
          };

        default:
          return {
            placeholder: '',
            buttonLabel: '',
            onClick: () => {},
          };
      }
    },
    [showTeacherAddPopup, t],
  );

  const tabData = getTabData(loc);

  useEffect(() => {
    const query = searchQuery.trim();
    fetchMoreMeta({ query });
  }, [searchQuery, fetchMoreMeta]);

  return (
    <MainTemplate>
      <PlatformSettingsWrapper>
        <HeadingWrapper>
          <Heading>
            {t('pages.teachersOptions.teachersAndApplications')}
          </Heading>
        </HeadingWrapper>
        <SettingsTabs>
          {getSettingsTabs(t).map((item) => (
            <StyledLink to={item.path} key={item.path}>
              <SettingsTab
                active={loc === item.path}
                icon={item.icon}
                value={totalCount[item.totalCountName]}
                name={item.name}
              />
            </StyledLink>
          ))}
        </SettingsTabs>
        <SearchWrapper>
          <Search
            value={searchQuery}
            onChange={onChangeHandler}
            clear={onClearSearchHandler}
            placeholder={tabData?.placeholder}
            buttonProps={tabData.buttonProps}
          />
        </SearchWrapper>

        <Switch>
          <Route path={PATHS.teachersUsers} component={Teachers} />
        </Switch>
        <Switch>
          <Route path={PATHS.teachersApplications} component={Applications} />
        </Switch>
      </PlatformSettingsWrapper>
    </MainTemplate>
  );
});

const PlatformSettingsWrapper = styled.div`
  width: 100%;
`;

const SettingsTabs = styled.div`
  display: flex;
  margin-top: 24px;
`;

const HeadingWrapper = styled.div`
  margin-top: 48px;
`;

const StyledLink = styled(Link)`
  height: 176px;
  width: 304px;
  margin-left: 32px;
  text-decoration: none;

  &:first-child {
    margin-left: 0;
  }
`;

const SearchWrapper = styled.div`
  margin-top: 48px;
`;
