import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

import { Search, PerformancesTable } from 'molecules';
import { MainTemplate } from 'templates';

import { usePerformances, useSearch, SearchNames } from 'core/hooks';
import { getFontFamily } from 'services/FontService';

export const Performance = memo(() => {
  const { t } = useTranslation();
  const [
    fetchMoreData,
    { data: performances, loading, error, hasMore, after },
  ] = usePerformances();

  const { searchQuery, setSearchQuery } = useSearch(SearchNames.performance);

  const onChangeHandler = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery],
  );

  const onClearSearchHandler = useCallback(() => {
    setSearchQuery('');
    fetchMoreData({ query: null });
  }, [setSearchQuery, fetchMoreData]);

  const loadMore = useCallback(() => {
    const query = searchQuery.trim();
    fetchMoreData({ query, after });
  }, [fetchMoreData, after, searchQuery]);

  useEffect(() => {
    const query = searchQuery.trim();
    fetchMoreData({ query });
  }, [searchQuery, fetchMoreData]);

  return (
    <MainTemplate>
      <Wrapper>
        <HeadingWrapper>
          <Heading>{t('pages.performance.studentPerformance')}</Heading>
        </HeadingWrapper>
        <SearchWrapper>
          <Search
            value={searchQuery}
            onChange={onChangeHandler}
            clear={onClearSearchHandler}
            placeholder={t('pages.performance.searchPerformance')}
          />
        </SearchWrapper>
        <TableWrapper>
          <PerformancesTable
            data={performances}
            loading={loading}
            error={error}
            hasMore={hasMore}
            loadMore={loadMore}
          />
        </TableWrapper>
      </Wrapper>
    </MainTemplate>
  );
});

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
`;

const baseWrapperStyles = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HeadingWrapper = styled.div`
  margin-top: 48px;
`;

const Heading = styled.span`
  font-family: ${getFontFamily('bold')};
  font-size: 20px;
  line-height: 40px;
  margin-right: 16px;
`;

const SearchWrapper = styled.div`
  ${baseWrapperStyles};
  margin-top: 16px;
`;
const TableWrapper = styled.div`
  ${baseWrapperStyles};
  margin-top: 36px;
`;
