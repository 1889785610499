import * as React from 'react';
import { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { PATHS } from 'router/config';
import { useActiveMenu, useLogOut, useUser } from 'core/hooks';
import { MenuItems, UserRole } from 'types';

import { MenuItem } from 'molecules';
import { MenuComponent } from './Menu';

const getMenuByRolesStructure = (t: TFunction) => [
  {
    name: MenuItems.Students,
    path: PATHS.students,
    text: `${t('menu.students')}`,
    roles: [
      UserRole.ADMIN,
      UserRole.ACCOUNTANT,
      UserRole.SALESMANAGER,
      UserRole.FINANCIER,
    ],
  },
  {
    name: MenuItems.Debtors,
    path: PATHS.debtors,
    text: `${t('menu.debtors')}`,
    roles: [
      UserRole.ADMIN,
      UserRole.ACCOUNTANT,
      UserRole.SALESMANAGER,
      UserRole.FINANCIER,
    ],
  },

  {
    name: MenuItems.Users,
    path: PATHS.users,
    text: `${t('menu.users')}`,
    roles: [UserRole.ADMIN],
  },
  {
    name: MenuItems.Teachers,
    path: PATHS.teachers,
    text: `${t('menu.teachers')}`,
    roles: [
      UserRole.ADMIN,
      UserRole.ACCOUNTANT,
      UserRole.SALESMANAGER,
      UserRole.HRMANAGER,
    ],
  },
  {
    name: MenuItems.Settings,
    path: PATHS.settings,
    text: `${t('menu.settings')}`,
    subtree: [
      {
        name: MenuItems.Courses,
        path: PATHS.settingsCourses,
        text: `${t('menu.courses')}`,
      },
      {
        name: MenuItems.Groups,
        path: PATHS.settingsGroups,
        text: `${t('menu.groups')}`,
      },
      {
        name: MenuItems.Journals,
        path: PATHS.settingsJournals,
        text: `${t('menu.journals')}`,
      },
    ],
    roles: [
      UserRole.ADMIN,
      UserRole.ACCOUNTANT,
      UserRole.SALESMANAGER,
      UserRole.HRMANAGER,
    ],
  },
  {
    name: MenuItems.Finances,
    path: PATHS.finances,
    text: `${t('menu.finances')}`,
    roles: [UserRole.ADMIN, UserRole.FINANCIER],
  },
  {
    name: MenuItems.Performance,
    path: PATHS.performance,
    text: `${t('menu.performance')}`,
    roles: [UserRole.ADMIN, UserRole.HRMANAGER, UserRole.ACCOUNTANT],
  },
];

export const Menu = memo(() => {
  const { t } = useTranslation();
  const [menuItems, setMenuItems] = useState<ReactNode[] | null>([null]);
  const [{ user, loading }] = useUser();

  const logOut = useLogOut();
  const activeMenu = useActiveMenu();

  const getMenuByRoles = useCallback(
    (userRoles: UserRole[]): ReactNode[] => {
      const finalMenu = getMenuByRolesStructure(t).filter((menuItem) => {
        const matchedRoles = userRoles.filter((role) =>
          menuItem.roles.includes(role),
        );
        return matchedRoles.length > 0;
      });

      return finalMenu.map((menuItem) => (
        <MenuItem
          key={menuItem.path}
          to={menuItem.path}
          isMulti={
            menuItem.subtree
              ? menuItem.subtree.map((subItem) => ({
                  children: subItem.text,
                  to: subItem.path,
                }))
              : undefined
          }
          isActive={menuItem.name === activeMenu}
        >
          {menuItem.text}
        </MenuItem>
      ));
    },
    [activeMenu, t],
  );

  useEffect(() => {
    if (!loading && user?.roles && user.roles.length > 0) {
      setMenuItems(getMenuByRoles(user?.roles as UserRole[]));
    }
  }, [user, loading, getMenuByRoles]);

  return (
    <MenuComponent
      activeMenu={activeMenu}
      onExit={logOut}
      menuItems={menuItems || []}
    />
  );
});
