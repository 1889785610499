import { useReactiveVar } from '@apollo/client';
import { IPopupData, popupData } from 'core';

export const usePopupData = <IPopupDataKeys extends keyof IPopupData>(
  popup: IPopupDataKeys,
): IPopupData[IPopupDataKeys] => {
  const data = useReactiveVar(popupData)[popup];

  return data;
};
