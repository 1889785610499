import * as React from 'react';
import { memo } from 'react';

import * as Icons from './Icons';

export enum INames {
  ExitIcon = 'ExitIcon',
  EditLargeIcon = 'EditLargeIcon',
  CloseIcon = 'CloseIcon',
  CloseIconSquare = 'CloseIconSquare',
  Splash = 'SplashIcon',
  ToastClose = 'ToastClose',
  Toast = 'Toast',
  ArrowIcon = 'ArrowIcon',
  ArrowIconActive = 'ArrowIconActive',
  TickIcon = 'TickIcon',
  SearchIcon = 'SearchIcon',
  PlusIcon = 'PlusIcon',
  PlusLargeIcon = 'PlusLargeIcon',
  MinusIcon = 'MinusIcon',
  BurgerIcon = 'BurgerIcon',
  BurgerIconClose = 'BurgerIconClose',
  ArrowIconWhiteActive = 'ArrowIconWhiteActive',
  DeleteIcon = 'DeleteIcon',
  EditIcon = 'EditIcon',
  VideoIcon = 'VideoIcon',
  UserIcon = 'UserIcon',
  ProfileIcon = 'ProfileIcon',
  CalendarIcon = 'CalendarIcon',
  CalendarIcon2 = 'CalendarIcon2',
  SortableIcon = 'SortableIcon',
  InfoIcon = 'InfoIcon',
  WarningIcon = 'WarningIcon',
  ErrorIcon = 'ErrorIcon',
  Success = 'Success',
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
  Edit = 'Edit',
  Clip = 'Clip',
  ArrowDown = 'ArrowDown',
  Comment = 'Comment',
  InfoGrey = 'InfoGrey',
  Table = 'Table',
  SuccessTick = 'SuccessTick',
  CircleSuccess = 'CircleSuccess',
  CircleError = 'CircleError',
  CircleWarning = 'CircleWarning',
  ClockPrimary = 'ClockPrimary',
  Clock = 'Clock',
  CommentPrimary = 'CommentPrimary',
  Messaging = 'Messaging',
  Loading = 'Loading',
  EmptyCell = 'EmptyCell',
  PlusRoundedIcon = 'PlusRoundedIcon',
  MinusRoundedIcon = 'MinusRoundedIcon',
  ApplicationIcon = 'ApplicationIcon',
  Completed = 'Completed',
  WarningAccess = 'WarningAccess',
}

export interface IIconProps {
  name: INames;
  width?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
}

const IconsMap: { [key: string]: any } = Icons;

export const Icon = memo(
  ({ name, width = 25, height = 25, color, className }: IIconProps) => {
    const IconComponent = IconsMap[name];

    return (
      <IconComponent
        width={width}
        height={height}
        fill={color}
        className={className}
      />
    );
  },
);
