import * as React from 'react';
import { memo, useCallback } from 'react';
import { Link, useLocation, Switch, Route } from 'react-router-dom';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Courses, Groups, Journals } from 'pages';

import styled from 'styled-components';
import { PATHS } from 'router/config';

import { Heading, IconProps, IconsNames } from 'atoms';
import { SettingsTab, Search } from 'molecules';
import { MainTemplate } from 'templates';
import {
  usePopupStateOperations,
  useSearch,
  SearchNames,
  useLoadMetaHook,
} from 'core/hooks';
import { PopupNames } from 'core';

enum tabNames {
  courses = 'courses',
  groups = 'groups',
  journals = 'journals',
}

interface ISettingsItems {
  path: string;
  icon: IconProps;
  totalCountName: tabNames;
  name: string;
}

const getSettingsTabs = (t: TFunction): ISettingsItems[] => [
  {
    path: PATHS.settingsCourses,
    icon: {
      name: IconsNames.VideoIcon,
      height: 24,
      width: 24,
    },
    name: `${t('pages.settings.courses')}`,
    totalCountName: tabNames.courses,
  },
  {
    path: PATHS.settingsGroups,
    icon: {
      name: IconsNames.UserIcon,
      height: 24,
      width: 24,
    },
    totalCountName: tabNames.groups,
    name: `${t('pages.settings.groups')}`,
  },
  {
    path: PATHS.settingsJournals,
    icon: {
      name: IconsNames.CalendarIcon,
      height: 24,
      width: 24,
    },
    totalCountName: tabNames.journals,
    name: `${t('pages.settings.journals')}`,
  },
];

export const PlatformSettings = memo(() => {
  const { t } = useTranslation();
  const {
    operations: { showPopup },
  } = usePopupStateOperations();
  const loc = useLocation().pathname;
  const { searchQuery, setSearchQuery } = useSearch(SearchNames.settings);
  const { totalCount } = useLoadMetaHook();

  const showAddGroupPopup = useCallback(() => {
    showPopup(PopupNames.addGroup);
  }, [showPopup]);

  const onChangeHandler = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery],
  );

  const onClearSearchHandler = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const getTabData = useCallback(
    (path: string) => {
      switch (path) {
        case '/settings/courses':
          return {
            placeholder: `${t('pages.settings.searchCourse')}`,
          };

        case '/settings/groups':
          return {
            placeholder: `${t('pages.settings.searchGroup')}`,
            buttonProps: {
              label: `${t('pages.settings.addGroup')}`,
              onClick: showAddGroupPopup,
            },
          };

        case '/settings/journals':
          return {
            placeholder: `${t('pages.settings.searchJournal')}`,
          };

        default:
          return {
            placeholder: '',
            buttonLabel: '',
            onClick: () => {},
          };
      }
    },
    [showAddGroupPopup, t],
  );

  const tabData = getTabData(loc);

  return (
    <MainTemplate>
      <PlatformSettingsWrapper>
        <HeadingWrapper>
          <Heading>{t('pages.settings.platformSettings')}</Heading>
        </HeadingWrapper>
        <SettingsTabs>
          {getSettingsTabs(t).map((item) => (
            <StyledLink to={item.path} key={item.path}>
              <SettingsTab
                active={loc === item.path}
                icon={item.icon}
                value={totalCount[item.totalCountName]}
                name={item.name}
              />
            </StyledLink>
          ))}
        </SettingsTabs>
        <SearchWrapper>
          <Search
            value={searchQuery}
            onChange={onChangeHandler}
            clear={onClearSearchHandler}
            placeholder={tabData?.placeholder}
            buttonProps={tabData.buttonProps}
          />
        </SearchWrapper>

        <Switch>
          <Route path={PATHS.settingsCourses} component={Courses} />
        </Switch>
        <Switch>
          <Route path={PATHS.settingsGroups} component={Groups} />
        </Switch>
        <Switch>
          <Route path={PATHS.settingsJournals} component={Journals} />
        </Switch>
      </PlatformSettingsWrapper>
    </MainTemplate>
  );
});

const PlatformSettingsWrapper = styled.div`
  width: 100%;
`;

const SettingsTabs = styled.div`
  display: flex;
  margin-top: 24px;
`;

const HeadingWrapper = styled.div`
  margin-top: 48px;
`;

const StyledLink = styled(Link)`
  height: 176px;
  width: 304px;
  margin-left: 32px;
  text-decoration: none;

  &:first-child {
    margin-left: 0;
  }
`;

const SearchWrapper = styled.div`
  margin-top: 48px;
`;
