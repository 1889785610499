import { useCallback } from 'react';
import { IStudent, IStudentAgreement } from 'types';
import {
  usePaginatedStudentsQuery,
  useMetaStudentsQuery,
  StudentEdge,
  PaginatedStudentsQueryVariables,
  PageInfo,
  AgreementType,
} from 'core/graphql';
import { handleFinancialNumberValue } from 'services/NumberService';

type StudentsHookType = {
  data: IStudent[];
  loading?: boolean;
  error?: string;
  totalCount?: number | null;
  agreementsCount?: number | null;
  pageInfo?: Pick<
    PageInfo,
    'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
  >;
  operations: {
    fetchMoreData: (vars: PaginatedStudentsQueryVariables) => void;
  };
};

interface IDuplicateRemovalReducer {
  result: IStudent[];
  takenContracts: string[];
}

const removeDuplicates = (data: IStudent[]): IStudent[] => {
  const { result } = data.reduce(
    (
      // eslint-disable-next-line @typescript-eslint/no-shadow
      { result, takenContracts }: IDuplicateRemovalReducer,
      nextStudent: IStudent,
    ): IDuplicateRemovalReducer => {
      const isTaken = takenContracts.includes(nextStudent.contractTitle);

      return {
        result: isTaken ? result : [...result, nextStudent],
        takenContracts:
          isTaken || nextStudent.contractTitle === ''
            ? takenContracts
            : [...takenContracts, nextStudent.contractTitle],
      };
    },
    { result: [], takenContracts: [] },
  );

  return result;
};

const useGetStudentsFromApi = () => {
  const { data, loading, error, fetchMore } = usePaginatedStudentsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      first: 10,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: metadata, fetchMore: fetchMoreMeta } = useMetaStudentsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      query: null,
    },
    notifyOnNetworkStatusChange: true,
  });

  const result = (): IStudent[] => {
    const edges = data?.allStudents?.edges;
    if (!edges) {
      return [];
    }

    return removeDuplicates(
      edges
        .map((edge) => toIStudentArray(edge as StudentEdge))
        .reduce(
          (studentsArr: IStudent[], next: IStudent[]) => [
            ...studentsArr,
            ...next,
          ],
          [],
        ),
    );
  };

  return {
    fetchMore,
    fetchMoreMeta,
    result: result(),
    loading,
    error,
    pageInfo: data?.allStudents?.pageInfo,
    totalCount: metadata?.metaStudents?.totalCount || 0,
    agreementsCount: metadata?.metaStudents?.agreementsCount || 0,
  };
};

const toIStudentArray = (edge: StudentEdge | null): IStudentAgreement[] => {
  if (!edge) return [];
  const { node } = edge;
  if (node && node.user) {
    const { agreements, user } = node;

    if (!agreements.length) {
      const { firstName, lastName, middleName, email, phone } = user;

      return [
        {
          id: node.id,
          studentId: node.id,
          firstName,
          lastName,
          middleName: middleName || '',
          email,
          name: `${lastName} ${firstName} ${middleName || ''}`,
          contractTitle: '',
          phone: phone || '',
          budgetAmount: '',
          paidAmount: '',
          group: '',
          terminated: false,
        },
      ];
    }

    return agreements.reduce(
      (
        acc: IStudentAgreement[],
        {
          agreementTitle: contractTitle,
          currency,
          terminated,
          budgetAmount,
          paidAmount,
          id: agreementId,
          group: { name: groupName },
        }: AgreementType,
      ) => {
        const { firstName, lastName, middleName, email } = user;

        const student: IStudentAgreement = {
          id: node.id,
          agreementId,
          studentId: node.id,
          firstName,
          lastName,
          middleName: middleName || '',
          email,
          name: `${lastName} ${firstName} ${middleName || ''}`,
          contractTitle,
          group: groupName,
          phone: user?.phone || '',
          budgetAmount: `${handleFinancialNumberValue(
            budgetAmount,
          )} ${currency}`,
          paidAmount: `${handleFinancialNumberValue(paidAmount)} ${currency}`,
          terminated,
        };

        return [...acc, student];
      },
      [],
    );
  }
  return [];
};

export const useStudents = (): StudentsHookType => {
  const {
    result,
    loading,
    totalCount,
    agreementsCount,
    fetchMore = () => null,
    fetchMoreMeta = () => null,
    pageInfo,
  } = useGetStudentsFromApi();

  const fetchMoreData = useCallback(
    ({ query, after }: PaginatedStudentsQueryVariables) => {
      fetchMore({
        variables: {
          query,
          after,
        },
      });

      fetchMoreMeta({
        variables: {
          query,
        },
      });
    },
    [fetchMore, fetchMoreMeta],
  );

  return {
    data: result || [],
    loading,
    pageInfo,
    totalCount,
    agreementsCount,
    operations: {
      fetchMoreData,
    },
  };
};
