import * as React from 'react';

import styled, { css } from 'styled-components';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { transFormAlignProp } from 'services/TransformService';

export interface ITableCell extends ITableCellStyle {
  children: React.ReactNode;
  type?: CellType;
}
export interface ITableCellStyle {
  align?: 'left' | 'center' | 'right';
  width?: string;
  flexible?: boolean;
  margin?: {
    right?: string;
    left?: string;
  };
  padding?: {
    right?: string;
    left?: string;
  };
  className?: string;
  borderColor?: Colors;
  textColor?: Colors;
}

type CellType = 'cell' | 'headerCell';

export const TableCell = styled.td<ITableCell>`
  width: ${({ width }) => width || '150px'};
  display: flex;
  flex-direction: row;
  flex-grow: ${({ flexible }) => (flexible ? 1 : 'unset')};
  justify-content: ${({ align }) => transFormAlignProp(align)};
  align-items: center;
  font-family: ${getFontFamily('regular')};
  box-sizing: border-box;
  font-family: ${({ type }) =>
    type === 'headerCell' ? getFontFamily('bold') : getFontFamily('regular')};
  font-style: normal;
  font-weight: ${({ type }) => (type === 'headerCell' ? '800' : '400')};
  font-size: ${({ type }) => (type === 'headerCell' ? '12px' : '14px')};
  line-height: ${({ type }) => (type === 'headerCell' ? '24px' : '16px')};
  color: ${({ textColor = Colors.Black }) => textColor};
  border: ${({ borderColor }) =>
    borderColor ? `0.5px solid ${borderColor}` : 'none'};
  text-align: ${({ align }) => align};

  ${({ margin }) =>
    margin?.right &&
    css`
      margin-right: ${margin.right};
    `}

  ${({ margin }) =>
    margin?.left &&
    css`
      margin-left: ${margin.left};
    `}
  
  ${({ padding }) =>
    padding?.right &&
    css`
      padding-right: ${padding.right};
    `}

  ${({ padding }) =>
    padding?.left &&
    css`
      padding-left: ${padding.left};
    `}
`;
