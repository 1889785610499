import { gql } from '@apollo/client';
import { USER_FIELDS } from '../fragments';

export const RefreshMutation = gql`
  mutation refreshToken($refresh_token: String!, $scope: String!) {
    refreshToken(refreshToken: $refresh_token, scope: $scope) {
      token
      refreshToken
      payload
    }
  }
`;

export const ForgotPasswordMutation = gql`
  mutation sendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

export const RecoveryPasswordMutation = gql`
  mutation passwordSet(
    $newPassword1: String!
    $newPassword2: String!
    $token: String!
  ) {
    passwordSet(
      newPassword1: $newPassword1
      newPassword2: $newPassword2
      token: $token
    ) {
      success
      errors
    }
  }
`;

export const PasswordResetMutation = gql`
  mutation passwordReset(
    $newPassword1: String!
    $newPassword2: String!
    $token: String!
  ) {
    passwordReset(
      newPassword1: $newPassword1
      newPassword2: $newPassword2
      token: $token
    ) {
      success
      errors
    }
  }
`;

export const ConfirmEmailMutation = gql`
  mutation verifyToken($token: String!) {
    verifyToken(token: $token) {
      success
      errors
    }
  }
`;

export const LoginMutation = gql`
  ${USER_FIELDS}
  mutation login($password: String!, $email: String!, $scope: String!) {
    customLogin(password: $password, email: $email, scope: $scope) {
      success
      errors
      token
      refreshToken
      user {
        ...UserFields
      }
    }
  }
`;
