import { useRefreshToken } from '.';
import { useAppState } from '../State';
import { useUser } from '../Users';

export const useBootstrapHook = () => {
  const tokenRefreshed = useRefreshToken();
  const [{ user }] = useUser();
  const { data: appState, operations } = useAppState();
  return { tokenRefreshed, data: { appState, user }, operations };
};
