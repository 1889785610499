import {
  serverErrorType,
  ToastTypes,
  ErrorsType,
  FieldErrorTypes,
  IFieldError,
  GlobalToastTypeNames,
} from 'types';
import { CustomErrorType, Maybe } from 'core/graphql';
import { CreateNewUserErrorsType, useToasts } from 'core/hooks';
import { useEffect, useMemo } from 'react';
import i18n from '../i18n';

export const checkForUniqueErrors = (
  errors: CreateNewUserErrorsType,
): boolean[] => {
  const uniqueEmail = errors.email && errors.email[0].code === 'unique';
  const uniquePhone = errors.phone && errors.phone[0].code === 'unique';

  return [uniqueEmail, uniquePhone];
};

export const getUniqueServerError = (
  uniquePhone: boolean,
  uniqueEmail: boolean,
): { email?: IFieldError; phone?: IFieldError } | undefined => {
  const errorType = FieldErrorTypes.SERVER_ERROR;
  if (uniquePhone && !uniqueEmail) {
    return {
      phone: {
        type: errorType,
        message: `${i18n.t('common.userWithPhoneExist')}`,
      },
    };
  }
  if (!uniquePhone && uniqueEmail) {
    return {
      email: {
        type: errorType,
        message: `${i18n.t('common.userWithMailExist')}`,
      },
    };
  }

  if (uniquePhone && uniqueEmail) {
    return {
      email: {
        type: errorType,
        message: `${i18n.t('common.userWithMailOrPhoneExist')}`,
      },
      phone: {
        type: errorType,
        message: `${i18n.t('common.userWithMailOrPhoneExist')}`,
      },
    };
  }

  return {
    email: {
      type: errorType,
      message: `${i18n.t('common.unhandledError')}`,
    },
    phone: {
      type: errorType,
      message: `${i18n.t('common.unhandledError')}`,
    },
  };
};

export const useParseServerErrors = <IValues>(
  serverErrors?: Maybe<Array<Maybe<CustomErrorType>>>,
) => {
  const { addToast } = useToasts();
  const errors = useMemo(
    () =>
      serverErrors?.reduce(
        (
          sortedErrors: {
            formErrors: ErrorsType<IValues>;
            serverErrors: serverErrorType[];
          },
          error,
        ) => {
          if (error?.code && error.field) {
            switch (error.code) {
              case 'invalid':
                return {
                  ...sortedErrors,
                  formErrors: {
                    ...sortedErrors.formErrors,
                    [error.field]: {
                      type: FieldErrorTypes.VALIDATION,
                      fieldName: error.field,
                    },
                  },
                };

              case 'unique':
                return {
                  ...sortedErrors,
                  formErrors: {
                    ...sortedErrors.formErrors,
                    [error.field]: {
                      type: FieldErrorTypes.VALIDATION,
                      fieldName: error.field,
                      message: `${i18n.t('common.useByOtherUser')}`,
                    },
                  },
                };

              case 'unique_together':
                return {
                  ...sortedErrors,
                  formErrors: {
                    ...sortedErrors.formErrors,
                    agreementTitle: {
                      type: FieldErrorTypes.VALIDATION,
                      fieldName: 'agreementTitle',
                      message: `${i18n.t('common.agreementExist')}`,
                    },
                  },
                };

              case 'unique_together_group':
                return {
                  ...sortedErrors,
                  formErrors: {
                    ...sortedErrors.formErrors,
                    group: {
                      type: FieldErrorTypes.VALIDATION,
                      fieldName: 'group',
                      message: `${i18n.t('common.studentIsAlreadyInGroup')}`,
                    },
                  },
                };

              case 'unique_student_group':
                return {
                  ...sortedErrors,
                  formErrors: {
                    ...sortedErrors.formErrors,
                    group: {
                      type: FieldErrorTypes.SERVER_ERROR,
                      fieldName: 'group',
                      message: `${i18n.t(
                        'common.studentIsAlreadyInThisGroup',
                      )}`,
                    },
                  },
                };

              case 'student_already_has_learned':
                return {
                  ...sortedErrors,
                  formErrors: {
                    ...sortedErrors.formErrors,
                    group: {
                      type: FieldErrorTypes.SERVER_ERROR,
                      fieldName: 'group',
                      message: `${i18n.t('common.studentWasInGroup')}`,
                    },
                  },
                };

              case 'already_graduated':
                return {
                  ...sortedErrors,
                  formErrors: {
                    ...sortedErrors.formErrors,
                    group: {
                      type: FieldErrorTypes.VALIDATION,
                      fieldName: 'group',
                      message: `${i18n.t('common.groupIsGraduated')}`,
                    },
                  },
                };

              case 'invalid_agreement':
                return {
                  ...sortedErrors,
                  formErrors: {
                    ...sortedErrors.formErrors,
                    parsedPayments: {
                      type: FieldErrorTypes.SERVER_ERROR,
                      fieldName: error.field,
                      message: error.message,
                    },
                  },
                };

              case 'invalid_agreements':
                return {
                  ...sortedErrors,
                  formErrors: {
                    ...sortedErrors.formErrors,
                    parsedPayments: {
                      type: FieldErrorTypes.SERVER_ERROR,
                      fieldName: error.field,
                      message: error.message,
                    },
                  },
                };

              default:
                return {
                  ...sortedErrors,
                  serverErrors: [...sortedErrors.serverErrors, error],
                };
            }
          }

          return sortedErrors;
        },
        { formErrors: {}, serverErrors: [] },
      ) || { formErrors: {}, serverErrors: [] },
    [serverErrors],
  );

  useEffect(() => {
    if (errors.serverErrors.length) {
      addToast({
        data: {
          title: `${i18n.t('common.somethingWentWrong')}`,
          type: ToastTypes.error,
        },
        type: GlobalToastTypeNames.Other,
      });
    }
  }, [errors.serverErrors, addToast]);

  if (!Object.keys(errors.formErrors).length) {
    return null;
  }

  return errors.formErrors;
};
