import * as React from 'react';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { UserRole } from 'types';

import { Heading, CloseButton, Button } from 'atoms';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { translateRoleToValue } from 'services/TransformService';

export interface IUserRolesPopup {
  unique: {
    email: boolean;
    phone: boolean;
  };
  user: {
    phone: string;
    email: string;
    existRoles: UserRole[];
    applyRoles: UserRole[];
  };
  loading: boolean;
  onClose: () => void;
  onCancel: () => void;
  onApply: () => void;
}

export const UserRolesPopupComponent = memo(
  ({ unique, user, loading, onClose, onCancel, onApply }: IUserRolesPopup) => {
    const { t } = useTranslation();
    const existRoles = user.existRoles
      .map((role) => translateRoleToValue(role))
      .join(', ');
    const applyRoles = user.applyRoles
      .map((role) => translateRoleToValue(role))
      .join(', ');

    return (
      <Wrapper>
        <InnerWrapper>
          <CloseButtonWrapper>
            <CloseButton onClick={onClose} />
          </CloseButtonWrapper>
          <Heading>{t('userExistPopup.userIsInSystem')}</Heading>
          {unique.email && !unique.phone && (
            <Text>
              <Trans
                i18nKey="userExistPopup.hasMail"
                values={{ email: unique.email }}
                components={{
                  bold: <BlueText>{unique.email}</BlueText>,
                }}
              />
              {user.existRoles.length > 1
                ? t('userExistPopup.andHasRoles')
                : t('userExistPopup.andHasRole')}
              “<BoldText>{existRoles}</BoldText>”.{' '}
              {user.applyRoles.length > 1
                ? t('userExistPopup.wantToAddRoles')
                : t('userExistPopup.wantToAddRole')}
              “<BoldText>{applyRoles}</BoldText>”?
            </Text>
          )}
          {unique.phone && !unique.email && (
            <Text>
              <Trans
                i18nKey="userExistPopup.hasPhone"
                values={{ phone: unique.phone }}
                components={{
                  bold: <BlueText>{unique.phone}</BlueText>,
                }}
              />
              {user.existRoles.length > 1
                ? t('userExistPopup.andHasRoles')
                : t('userExistPopup.andHasRole')}
              “<BoldText>{existRoles}</BoldText>”.{' '}
              {user.applyRoles.length > 1
                ? t('userExistPopup.wantToAddRoles')
                : t('userExistPopup.wantToAddRole')}
              “<BoldText>{applyRoles}</BoldText>”?
            </Text>
          )}
          {unique.phone && unique.email && (
            <Text>
              <Trans
                i18nKey="userExistPopup.hasMailAndPhone"
                values={{ phone: unique.phone, email: unique.email }}
                components={{
                  boldPhone: <BlueText>{unique.phone}</BlueText>,
                  boldEmail: <BlueText>{unique.email}</BlueText>,
                }}
              />
              {user.existRoles.length > 1
                ? t('userExistPopup.andHasRoles')
                : t('userExistPopup.andHasRole')}
              “<BoldText>{existRoles}</BoldText>”.{' '}
              {user.applyRoles.length > 1
                ? t('userExistPopup.wantToAddRoles')
                : t('userExistPopup.wantToAddRole')}
              “<BoldText>{applyRoles}</BoldText>”?
            </Text>
          )}
          <ButtonsWrapper>
            <CancelButton>
              <Button
                onClick={onCancel}
                type="button"
                variant="outline"
                colors={{
                  backgroundColor: Colors.White,
                  color: Colors.Blue,
                }}
              >
                {t('userExistPopup.button.cancel')}
              </Button>
            </CancelButton>
            <ApplyButton>
              <Button
                onClick={onApply}
                loading={loading}
                type="button"
                colors={{
                  backgroundColor: Colors.Blue,
                  color: Colors.White,
                }}
              >
                {t('userExistPopup.button.add')}
              </Button>
            </ApplyButton>
          </ButtonsWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 864px;
  padding: 48px 64px 72px;
  background-color: ${Colors.White};
`;

const InnerWrapper = styled.div`
  position: relative;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const Text = styled.p`
  margin-top: 24px;
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  line-height: 24px;
`;

const BlueText = styled.span`
  color: ${Colors.Blue};
`;

const BoldText = styled.span`
  font-family: ${getFontFamily('bold')};
  white-space: nowrap;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
`;

const CancelButton = styled.div`
  width: 122px;
`;

const ApplyButton = styled.div`
  width: 153px;
  margin-left: 16px;
`;
