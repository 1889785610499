import * as React from 'react';
import { ReactNode } from 'react';

import styled from 'styled-components';

import { MenuItems } from 'types';
import { ExitButton } from 'atoms';
import { MenuItemList } from 'molecules';

import { Colors } from 'services/ColorService';

export interface IMenuComponent {
  activeMenu?: MenuItems;
  menuItems: ReactNode[];
  onExit: () => void;
}

export const MenuComponent = ({ menuItems, onExit }: IMenuComponent) => (
  <Wrapper>
    <Container>
      <MenuItemList>{menuItems}</MenuItemList>
      <ExitButton onClick={onExit} />
    </Container>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: ${Colors.White};
  padding: 21px 2px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: inherit;
`;
