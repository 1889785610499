import React, { memo } from 'react';
import styled from 'styled-components';

import { Heading, CloseButton } from 'atoms';

import { Colors } from 'services/ColorService';

interface IPopupContainerStyle {
  width?: string;
}

export interface IPopupContainer extends IPopupContainerStyle {
  title?: string;
  onClose: () => void;
  children: React.ReactNode;
}

export const PopupContainer = memo(
  ({ title, onClose, children, width }: IPopupContainer) => (
    <Wrapper width={width}>
      <InnerWrapper>
        {title && <Heading>{title}</Heading>}
        <CloseButtonStyled onClick={onClose} />
        {children}
      </InnerWrapper>
    </Wrapper>
  ),
);

const Wrapper = styled.div<IPopupContainerStyle>`
  width: ${({ width }) => width || '864px'};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 48px 64px 72px 64px;
  border: none;
  background-color: ${Colors.White};
`;

const InnerWrapper = styled.div`
  position: relative;
`;

const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
