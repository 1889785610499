import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { PopupNames } from 'core';
import {
  usePopupStateOperations,
  useSortErrors,
  useCheckTeacherCredentials,
  usePopupData,
} from 'core/hooks';

import { yupValidation } from 'services/YupValidationService';
import { translateRoleToValue } from 'services/TransformService';

import { ServerCodes, UserRole, ErrorsType } from 'types';

import { ISelectOption } from 'components/atoms';
import { TeacherAddPopupComponent } from './TeacherAddPopup';
import { ITeacherAddFieldsValues } from './TeacherAddFields';

export const getTeacherAddSchema = (t: TFunction) =>
  Yup.object().shape({
    firstName: yupValidation.firstName(
      `${t('teacherPopup.fields.firstName.labelSecond')}`,
      t,
    ),
    middleName: yupValidation.middleName(
      `${t('teacherPopup.fields.middleName.labelSecond')}`,
      t,
    ),
    lastName: yupValidation.lastName(
      `${t('teacherPopup.fields.lastName.labelSecond')}`,
      t,
    ),
    phone: yupValidation.phone(`${t('teacherPopup.fields.phone.label')}`, t),
    email: yupValidation.email(`${t('teacherPopup.fields.email.label')}`, t),
  });

export const TeacherAddPopup = memo(() => {
  const { t, i18n } = useTranslation();
  const savedValues = usePopupData(PopupNames.teacherAddPopup);
  const [disabledButton, setDisabledButton] = useState(true);

  const [
    verifyTeacher,
    { code, user, teacher, errors: verifyError, loading },
  ] = useCheckTeacherCredentials();

  const {
    operations: { showPopup, hidePopup },
  } = usePopupStateOperations();

  const onClose = useCallback(() => {
    hidePopup(PopupNames.teacherAddPopup);
  }, [hidePopup]);

  const onSubmitHandler = useCallback(
    (values: ITeacherAddFieldsValues) => {
      verifyTeacher({
        firstName: values.firstName || '',
        middleName: values.middleName,
        lastName: values.lastName || '',
        email: values.email || '',
        phone: values.phone || '',
      });
    },
    [verifyTeacher],
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: savedValues || {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getTeacherAddSchema(t),
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
  });

  const handleChange = useCallback(
    (name: string, value?: string | boolean | ISelectOption<string>[]) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (values.phone && values.email && values.firstName && values.lastName) {
      setDisabledButton(false);
    }
    if (
      !values.phone ||
      !values.email ||
      !values.firstName ||
      !values.lastName
    ) {
      setDisabledButton(true);
    }
  }, [values, disabledButton]);

  useEffect(() => {
    if (code === ServerCodes.success) {
      showPopup(PopupNames.teacherAddTypePopup, {
        user: values,
        code,
      });

      return;
    }

    const phone = user?.phone || teacher?.phone;
    const email = user?.email || teacher?.email;
    const numberText = phone && `${t('teacherPopup.number')} ${phone}`;
    const emailText = email && `${t('teacherPopup.mail')} ${email}`;
    const bothTextRU =
      numberText && emailText && `${numberText} и ${emailText}`;
    const bothTextPL =
      numberText && emailText && `${numberText} i ${emailText}`;

    const credentialsTextRU = `с ${bothTextRU || numberText || emailText}`;
    const credentialsTextPL = `z ${bothTextPL || numberText || emailText}`;

    if (code === ServerCodes.userExist) {
      const roles = user?.roles
        ?.map((role) => translateRoleToValue(role))
        .join(', ');

      showPopup(PopupNames.confirmPopup, {
        title: `${t('teacherPopup.teacherIsInSystem')}`,
        children: `${t('teacherPopup.addAnotherAccess', {
          roles,
          credentialsText:
            i18n.language === 'ru' ? credentialsTextRU : credentialsTextPL,
        })}
            "${translateRoleToValue(UserRole.TEACHER)}"?`,
        confirmText: `${t('teacherPopup.button.add')}`,
        onConfirm: () =>
          showPopup(PopupNames.teacherAddTypePopup, {
            user: { id: user?.id, ...values },
            code,
          }),
        onCancel: () => showPopup(PopupNames.teacherAddPopup, values),
      });
    }

    if (code === ServerCodes.teacherExist) {
      const teacherFullname = `${teacher?.firstName || ''} ${
        teacher?.middleName || ''
      } ${teacher?.lastName || ''}`;

      showPopup(PopupNames.confirmPopup, {
        title: `${t('teacherPopup.teacherIsInSystem')}`,
        children: `${t('teacherPopup.addAnotherType', {
          teacherFullname,
          credentialsText:
            i18n.language === 'ru' ? credentialsTextRU : credentialsTextPL,
        })}`,
        confirmText: `${t('teacherPopup.button.add')}`,
        onConfirm: () =>
          showPopup(PopupNames.teacherAddTypePopup, {
            user: { id: teacher?.id },
            code,
          }),
        onCancel: () => showPopup(PopupNames.teacherAddPopup, values),
      });
    }
  }, [code, user, teacher, values, showPopup, t, i18n]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<ITeacherAddFieldsValues>();

  useEffect(() => {
    const clientErrors = Object.values(errors).length;
    if (clientErrors) {
      sortErrors(errors as ErrorsType<ITeacherAddFieldsValues>);
      return;
    }

    if (verifyError) {
      sortErrors(verifyError as ErrorsType<ITeacherAddFieldsValues>);
      return;
    }

    sortErrors(null);
  }, [errors, verifyError, sortErrors]);

  return (
    <TeacherAddPopupComponent
      values={values}
      errors={sortedErrors}
      errorMessage={message}
      onInputChange={handleChange}
      handleSubmit={handleSubmit}
      onClose={onClose}
      loading={loading}
      disabledButton={disabledButton}
    />
  );
});
