import { useCallback } from 'react';
import { IAttendances, IStudentForJournal } from 'types';

import { PageInfo, useStudentsForJournalQuery } from 'core/graphql';

type JournalStudentsHookType = {
  students: IStudentForJournal[];
  loading: boolean;
  pageInfo?: Pick<PageInfo, 'endCursor' | 'hasNextPage'>;
  fetchMoreStudents: (lessonsId: number[], after?: string) => void;
};

const useGetStudentsForJournal = (lessons: string[], groupId: number) => {
  const lessonsId = lessons.map((lesson) => +lesson);
  const { data, loading, fetchMore } = useStudentsForJournalQuery({
    variables: {
      lessonsId,
      groupId,
      first: 10,
    },
    notifyOnNetworkStatusChange: true,
  });

  const getStudentsFromData = (): IStudentForJournal[] => {
    const edges = data?.studentsForJournal?.edges;
    if (!edges) {
      return [];
    }

    return edges.reduce((students: IStudentForJournal[], studentEdge) => {
      if (studentEdge && studentEdge.node && studentEdge.node.user) {
        const {
          user: { firstName, lastName, middleName },
          attendances,
          agreementIsActive,
        } = studentEdge.node;

        if (!attendances) {
          return students;
        }

        const studentName = `${lastName || ''} ${firstName || ''} ${
          middleName || ''
        }`;

        const attendancesObject = attendances.edges.reduce(
          (result: IAttendances, attendanceEdge) => {
            if (attendanceEdge?.node?.id) {
              const {
                id,
                attendance,
                lesson: { id: lessonId },
              } = attendanceEdge.node;
              return {
                ...result,
                [lessonId]: { id, attendance: Boolean(attendance) },
              };
            }

            return result;
          },
          {},
        );

        return [
          ...students,
          {
            name: studentName,
            agreementIsActive,
            attendances: attendancesObject,
          },
        ];
      }

      return students;
    }, []);
  };

  return {
    students: getStudentsFromData(),
    loading,
    pageInfo: data?.studentsForJournal?.pageInfo,
    fetchMore,
  };
};

export const useJournalStudents = (
  groupId: number,
): JournalStudentsHookType => {
  const { students, loading, pageInfo, fetchMore } = useGetStudentsForJournal(
    [],
    groupId,
  );

  const fetchMoreStudents = useCallback(
    (lessonsId, after = '') => {
      fetchMore({
        variables: {
          lessonsId,
          after,
        },
      });
    },
    [fetchMore],
  );

  return {
    students,
    loading,
    pageInfo,
    fetchMoreStudents,
  };
};
