import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Colors } from 'services/ColorService';

import { Button, Form } from 'atoms';
import { PopupContainer, PopupErrors } from 'molecules';

import {
  IAddApplicationFields,
  AddApplicationFields,
} from './AddApplicationFields';

export interface IAddApplicationPopup extends IAddApplicationFields {
  errorsMessage: string | null;
  loading: boolean;
  disabledButton: boolean;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onClose: () => void;
}

export const AddApplicationPopupComponent = memo(
  ({
    values,
    courses,
    groups,
    servicePlaces,
    currencies,
    teacherTypes,
    disabledButton,
    errors,
    errorsMessage,
    loading,
    onInputChange,
    handleSubmit,
    onClose,
  }: IAddApplicationPopup) => {
    const { t } = useTranslation();
    return (
      <PopupContainer
        title={t('applicationPopup.createApplication')}
        onClose={onClose}
      >
        <FormWrapper onSubmit={handleSubmit}>
          <AddApplicationFields
            values={values}
            courses={courses}
            groups={groups}
            servicePlaces={servicePlaces}
            currencies={currencies}
            teacherTypes={teacherTypes}
            errors={errors}
            onInputChange={onInputChange}
          />
          <SubmitButton
            type="submit"
            variant="primary"
            colors={{
              color: Colors.White,
              activeColor: Colors.Blue,
              backgroundColor: Colors.Blue,
              bgDisabledColor: Colors.DeactivatedGrey,
            }}
            loading={loading}
            disabled={disabledButton}
          >
            {t('applicationPopup.saveApplication')}
          </SubmitButton>
          <PopupErrorsStyled>{errorsMessage}</PopupErrorsStyled>
        </FormWrapper>
      </PopupContainer>
    );
  },
);

const FormWrapper = styled(Form)`
  margin-top: 32px;
`;

const SubmitButton = styled(Button)`
  width: 224px;
  margin: 32px 0 0 auto;
`;

const PopupErrorsStyled = styled(PopupErrors)`
  margin-top: 24px;
`;
