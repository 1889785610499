/* eslint-disable @typescript-eslint/no-unused-vars */
import { gql } from '@apollo/client';

export const SettingsMetaQuery = gql`
  query settingsMeta {
    metaCourses {
      totalCount
    }
    metaGroups {
      totalCount
    }
  }
`;
