import * as React from 'react';
import { memo } from 'react';

import { Redirect, useLocation } from 'react-router-dom';

import { GuestTemplate } from 'templates';
import { CreatePasswordForm } from 'molecules';

import { getAuthPath, PATHS } from 'router/config';
import { queryService } from 'services/QueryService';

export const CreatePassword = memo(() => {
  const { pathname } = useLocation();

  const token = queryService.getToken(pathname);
  if (!token) {
    return <Redirect to={getAuthPath(PATHS.login)} />;
  }

  return (
    <GuestTemplate>
      <CreatePasswordForm token={token} />
    </GuestTemplate>
  );
});
