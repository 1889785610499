import { useCallback, useMemo } from 'react';
import { FormikErrors } from 'formik';
import { OptionTypeBase, ValueType } from 'react-select';
import * as R from 'ramda';

import { PopupNames } from 'core';
import { usePopupStateOperations } from 'core/hooks';

import { ISelectOption } from 'components/atoms';
import { IAddApplicationFieldsValues } from 'molecules';

import { TEACHER_TYPE_OPTIONS } from 'components/constants';
import { useGetTeacherTypeNames } from '../Teachers';

type AppendApplicationHelpersType = {
  disabledButton: boolean;
  teacherTypes: ISelectOption<string>[];
  handleChange: (
    name: string,
    value?: string | boolean | ISelectOption<string> | null | OptionTypeBase,
  ) => void;
  onClose: () => void;
};

interface IAppendApplicationArguments {
  values: IAddApplicationFieldsValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<IAddApplicationFieldsValues>>;
}

export const useAppendApplicationHelpers = ({
  values,
  setFieldValue,
}: IAppendApplicationArguments): AppendApplicationHelpersType => {
  const {
    operations: { hidePopup },
  } = usePopupStateOperations();

  const { data: teacherTypesDate } = useGetTeacherTypeNames();

  const disabledButton: boolean = useMemo(() => {
    const newValues = R.omit(['comment', 'servicePlace', 'riaPrice'], values);
    return Object.values(newValues).every((currentValue) =>
      Boolean(currentValue),
    );
  }, [values]);

  const teacherTypes = useMemo(
    () =>
      TEACHER_TYPE_OPTIONS.filter((option) => {
        if (teacherTypesDate) {
          return teacherTypesDate.includes(option.value);
        }
        return true;
      }),
    [teacherTypesDate],
  );

  const handleChange = useCallback(
    (
      name: string,
      value?:
        | string
        | boolean
        | ISelectOption<string>
        | Date
        | null
        | ValueType<OptionTypeBase, false>,
    ) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const onClose = useCallback(
    () => hidePopup(PopupNames.appendApplicationPopup),
    [hidePopup],
  );

  return {
    disabledButton: !disabledButton,
    teacherTypes,
    handleChange,
    onClose,
  };
};
