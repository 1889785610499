import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { OptionTypeBase, ValueType } from 'react-select';

import * as R from 'ramda';

import { Currencies, EmploymentTypes, PopupNames } from 'core';
import {
  useCountriesOptions,
  useGetCoursesForApplication,
  useGetGroupsForApplication,
  useLoadEditingApplication,
  usePopupData,
  usePopupStateOperations,
  useSortErrors,
  useToasts,
  useUpdateApplication,
} from 'core/hooks';

import {
  ErrorsType,
  GlobalToastTypeNames,
  ToastTypes,
  ApplicationDeleteAvailable,
} from 'types';

import {
  EditApplicationPopupComponent,
  IEditApplicationPopupValues,
} from 'molecules/EditApplicationPopup';
import { getApplicationSchema } from 'molecules/AddApplicationPopup';
import { ISelectOption } from 'atoms';
import { CURRENCY_OPTIONS_TEACHER } from 'components/constants';
import { DateService } from 'services/DateService';

export const EditApplicationPopup = memo(() => {
  const { t } = useTranslation();

  const { addToast } = useToasts();
  const {
    operations: { hidePopup, showPopup },
  } = usePopupStateOperations();

  const applicationId = usePopupData(PopupNames.editApplicationPopup)
    ?.applicationId;

  const { data, loading } = useLoadEditingApplication(applicationId || '0');
  const { countries } = useCountriesOptions();
  const { courses } = useGetCoursesForApplication();
  const [getGroups, { groups }] = useGetGroupsForApplication();

  const [
    updateApplication,
    { success: updateSuccess, errors: serverErrors },
  ] = useUpdateApplication();

  const onSubmit = useCallback(
    (values: IEditApplicationPopupValues) => {
      if (applicationId) {
        updateApplication({
          teacherType:
            EmploymentTypes[
              values.teacherType?.value as keyof typeof EmploymentTypes
            ],
          title: values.applicationNumber,
          date: DateService.toISO(values.date),
          group: values.group?.value || '',
          lessonsCount: Number(values.lessonsNumber),
          country: values.servicePlace?.value || '',
          servicePrice: Number(values.servicePrice),
          riaPrice: Number(values.riaPrice),
          currency:
            Currencies[values.currency?.value as keyof typeof Currencies],
          comment: values.comment,
          applicationId: String(applicationId),
          isTerminated: values.isTerminated,
          lastLessonDate: values.lastLessonDate
            ? DateService.toISO(values.lastLessonDate)
            : null,
        });
      }
    },
    [applicationId, updateApplication],
  );

  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormik<IEditApplicationPopupValues>({
    initialValues: {
      applicationNumber: data?.applicationNumber || '',
      date: data?.date || null,
      course: data?.course || null,
      group: data?.group || null,
      teacherType: data?.teacherType || null,
      lessonsNumber: data?.lessonsNumber || '',
      servicePlace: data?.servicePlace || null,
      servicePrice: data?.servicePrice || '',
      riaPrice: data?.riaPrice || '',
      currency: data?.currency || null,
      comment: data?.comment || '',
      isTerminated: data?.isTerminated || false,
      lastLessonDate: data?.lastLessonDate,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getApplicationSchema(t),
    onSubmit,
    enableReinitialize: true,
  });

  const handleChange = useCallback(
    (
      name: string,
      value?:
        | string
        | boolean
        | ISelectOption<string>
        | Date
        | null
        | ValueType<OptionTypeBase, false>,
    ) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const onClose = useCallback(
    () => hidePopup(PopupNames.editApplicationPopup),
    [hidePopup],
  );

  const handleDeleteClick = useCallback(() => {
    showPopup(PopupNames.applicationDeletePopup, {
      applicationId,
      code: data?.deleteAvailable,
    });
  }, [applicationId, data?.deleteAvailable, showPopup]);

  const disabledButton: boolean = useMemo(() => {
    const newValues = R.omit(
      ['comment', 'servicePlace', 'riaPrice', 'isTerminated', 'lastLessonDate'],
      values,
    );
    return Object.values(newValues).every((currentValue) =>
      Boolean(currentValue),
    );
  }, [values]);

  useEffect(() => {
    if (values.course && values.date) {
      getGroups({
        id: values.course.value,
        groupsDate: DateService.toISO(values.date),
      });
    }
  }, [getGroups, values.course, values.date]);

  useEffect(() => {
    if (updateSuccess) {
      addToast({
        data: {
          title: `${t('applicationPopup.updatedSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.TeacherApplicationEdit,
      });
      onClose();
    }
  }, [updateSuccess, onClose, addToast, t]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IEditApplicationPopupValues>();

  useEffect(() => {
    sortErrors({
      ...(errors as ErrorsType<IEditApplicationPopupValues>),
      ...serverErrors,
    });
  }, [errors, serverErrors, sortErrors]);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <EditApplicationPopupComponent
      isCompleted={data?.isCompleted || false}
      values={values}
      errors={sortedErrors}
      errorsMessage={message}
      handleSubmit={handleSubmit}
      onDeleteClick={handleDeleteClick}
      deleteAvailable={Boolean(
        data?.deleteAvailable === ApplicationDeleteAvailable.DeleteNotAvailable,
      )}
      onClose={onClose}
      disabledButton={!disabledButton}
      courses={courses || []}
      groups={groups || []}
      isGroupStarted={data?.isGroupStarted || false}
      teacherTypes={data?.allTeacherTypes || []}
      servicePlaces={countries}
      currencies={CURRENCY_OPTIONS_TEACHER}
      onInputChange={handleChange}
    />
  );
});
