import { useCallback, useMemo } from 'react';
import { OptionsType } from 'types';
import { useLoadLessonsOptionsLazyQuery } from 'core/graphql';
import { DateService } from 'services/DateService';

type LessonsOptionsType = [
  (groupId: string) => void,
  {
    lessonsOptions: OptionsType;
    loading: boolean;
  },
];

export const useLessonsOptions = (): LessonsOptionsType => {
  const [loadLessons, { data, loading }] = useLoadLessonsOptionsLazyQuery();

  const loadLessonsOptions = useCallback(
    (groupId: string) => loadLessons({ variables: { groupId } }),
    [loadLessons],
  );

  const lessonsOptions = useMemo(
    () =>
      data?.group?.lessons?.map(({ id, name, lessonNumber, date }) => ({
        value: id,
        label: lessonNumber ? `${lessonNumber}. ${name}` : `- ${name}`,
        disabled: DateService.isBefore(
          DateService.parseDate(date, 'ISO'),
          new Date(),
        ),
      })) || [],
    [data?.group?.lessons],
  );

  return [
    loadLessonsOptions,
    {
      lessonsOptions,
      loading,
    },
  ];
};
