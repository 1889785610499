import React, { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { OptionTypeBase, ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import {
  Icon,
  IconButton,
  IconsNames,
  Input,
  InputLabel,
  Select,
  Text,
  Title,
} from 'components/atoms';
import { AccountsPayableType, Gradients, IconSizes, ErrorsType } from 'types';
import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { AccountsPayablesHandler, IPaymentFormValues } from './PaymentsForm';

interface IAccountsPayablesForm {
  values: IPaymentFormValues;
  errors?: ErrorsType<IPaymentFormValues & { price: number }> | null;
  accountsPayables?: AccountsPayableType[];
  onAddAccountPayable: () => void;
  onChangeAccountPayable: AccountsPayablesHandler<
    ValueType<OptionTypeBase, false>
  >;
  onCloseAccountPayment: (index: number) => void;
}

const InfoIcon = ({ t }: { t: TFunction }) => (
  <IconWrapper>
    <TitleWrapper text={t('paymentForm.repaidAccountPayable')} />

    <Icon name={IconsNames.InfoGrey} />
  </IconWrapper>
);

export const AccountsPayablesForm = memo(
  ({
    values,
    errors,
    accountsPayables,
    onAddAccountPayable,
    onChangeAccountPayable,
    onCloseAccountPayment,
  }: IAccountsPayablesForm) => {
    const { t } = useTranslation();
    const accountsPayablesOptions = useMemo(
      () =>
        accountsPayables?.reduce((options: AccountsPayableType[], option) => {
          if (
            values.accountsPayables.find(({ value }) => value === option.value)
          ) {
            return [...options, { ...option, disabled: true }];
          }

          return [...options, option];
        }, []),
      [accountsPayables, values.accountsPayables],
    );

    const infoText =
      accountsPayables?.length === 1
        ? t('paymentForm.studentHasAccountPayable')
        : t('paymentForm.studentHasSeveralAccountPayable');

    const showAddAccountPayableButton = useCallback(() => {
      if (
        accountsPayables?.length &&
        accountsPayables.length >
          values.accountsPayables.filter(({ repaid }) => !repaid).length
      ) {
        return (
          <>
            <PlusButton
              name={IconsNames.PlusLargeIcon}
              size={IconSizes.xl}
              onClick={onAddAccountPayable}
            />
            <Text size="md" colors={{ color: Colors.PrimaryBlack }}>
              {t('paymentForm.button.addAccountPayable')}
            </Text>
          </>
        );
      }

      return null;
    }, [
      accountsPayables?.length,
      onAddAccountPayable,
      values.accountsPayables,
      t,
    ]);

    const showInfo = useCallback(() => {
      if (!accountsPayables?.length) {
        return null;
      }

      return (
        <AccountsPayablesInfo>
          <Icon name={IconsNames.InfoIcon} height={16} width={16} />

          <InfoTextWrapper>{infoText}</InfoTextWrapper>
        </AccountsPayablesInfo>
      );
    }, [accountsPayables?.length, infoText]);

    if (!accountsPayables?.length && !values.accountsPayables.length) {
      return null;
    }

    return (
      <>
        {showInfo()}

        <AccountsPayablesWrapper>
          {values.accountsPayables?.map((accountsPayable, index) => (
            <React.Fragment key={accountsPayable.value}>
              <WrapperInput>
                <InputLabel>
                  {t('paymentForm.fields.accountsPayables.label')}
                </InputLabel>
                <Select
                  placeholder={t(
                    'paymentForm.fields.accountsPayables.placeholder',
                  )}
                  value={accountsPayable?.label ? accountsPayable : null}
                  options={accountsPayablesOptions}
                  onChange={(value) => onChangeAccountPayable(value, index)}
                  isDisabled={accountsPayable.repaid}
                />
              </WrapperInput>

              <WrapperInput>
                <InputLabel
                  hasError={Boolean(
                    errors?.price &&
                      accountsPayable.amount &&
                      (errors.price.fieldName === 'accountsPayables' ||
                        errors.price.fieldName === 'payments'),
                  )}
                >
                  {t('paymentForm.fields.amount.label')}
                </InputLabel>

                <InputWithButtton>
                  <Input
                    value={accountsPayable.amount}
                    disabled={Boolean(accountsPayable.amount)}
                    type="text"
                    placeholder={t('paymentForm.fields.amount.placeholder')}
                    onChange={() => {}}
                    hasError={Boolean(
                      errors?.price &&
                        accountsPayable.amount &&
                        (errors.price.fieldName === 'accountsPayables' ||
                          errors.price.fieldName === 'payments'),
                    )}
                    financialNumber
                  />

                  <Select
                    type="primary"
                    width="64px"
                    value={accountsPayable.currency}
                    isDisabled={Boolean(accountsPayable.currency)}
                    onChange={() => {}}
                    hasError={Boolean(
                      errors?.accountsPayables?.[index].currency,
                    )}
                    hideIndicator
                  />

                  <CloseIconWrapper>
                    {!accountsPayable.repaid ? (
                      <IconButton
                        name={IconsNames.CloseIconSquare}
                        size={IconSizes.xl}
                        onClick={() => onCloseAccountPayment(index)}
                      />
                    ) : (
                      <InfoIcon t={t} />
                    )}
                  </CloseIconWrapper>
                </InputWithButtton>
              </WrapperInput>
            </React.Fragment>
          ))}
        </AccountsPayablesWrapper>

        <AccountsPayablesButtonWrapper>
          {showAddAccountPayableButton()}
        </AccountsPayablesButtonWrapper>
      </>
    );
  },
);

const PlusButton = styled((props) => <IconButton {...props} />)`
  margin-right: 16px;
`;

const CloseIconWrapper = styled.div`
  margin-left: 16px;
`;

const InputWithButtton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 48px;
  display: flex;
  justify-content: center;
  position: relative;

  :hover {
    & svg {
      & > path {
        fill: ${Colors.Blue};
      }
    }
  }
`;

const TitleWrapper = styled((props) => <Title {...props} />)`
  display: none;

  ${IconWrapper}:hover & {
    display: block;
  }
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
`;

const AccountsPayablesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  margin-bottom: 24px;
`;

const InfoTextWrapper = styled.div`
  margin-left: 8px;
`;

const AccountsPayablesButtonWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
`;

const AccountsPayablesInfo = styled.div`
  background: ${Gradients.SuccessLight};
  border: 1px solid ${Colors.Blue};
  border-radius: 2px;
  padding: 8px 16px;
  font-family: ${getFontFamily('semibold')};
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 16px;
`;
