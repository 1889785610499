import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { PATHS } from 'router/config';

export const useGoToForgotPassword = () => {
  const history = useHistory();

  const goToForgotPassword = useCallback(() => {
    history.push(PATHS.forgot_password);
  }, [history]);

  return goToForgotPassword;
};
