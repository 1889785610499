import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useGoToLogin, useRecoveryPassword, useSortErrors } from 'core/hooks';

import {
  YupFieldErrorTypes,
  _passwordMatch,
} from 'services/YupValidationService';

import { ErrorsType } from 'types';
import {
  IRecoveryPasswordFormValues,
  RecoveryPasswordFormComponent,
} from './RecoveryPasswordForm';

export const getRecoveryPasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    newPassword: Yup.string()
      .required({
        type: YupFieldErrorTypes.EMPTY,
        fieldName: `${t('passwordForm.fields.newPassword.label')}`,
      })
      .matches(_passwordMatch, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: `${t('passwordForm.validation.passDoesntMatch')}`,
      }))
      .min(8, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: `${t('passwordForm.validation.passIsShort')}`,
      })),
    confirmPassword: Yup.string()
      .required({
        type: YupFieldErrorTypes.EMPTY,
        fieldName: `${t('passwordForm.fields.confirmPassword.label')}`,
      })
      .test(
        'repeatPassword',
        {
          type: YupFieldErrorTypes.SERVER_ERROR,
          message: `${t('passwordForm.validation.passInvalid')}`,
        },
        (value, context) => {
          const { newPassword } = context.parent;
          return newPassword === value;
        },
      ),
  });

export const RecoveryPasswordForm = memo(({ token }: { token: string }) => {
  const { t } = useTranslation();
  const [
    recoveryPassword,
    { success, loading, errors: serverErrors },
  ] = useRecoveryPassword();
  const goToLogin = useGoToLogin();

  const onSubmitHandler = useCallback(
    (values: IRecoveryPasswordFormValues) => {
      if (!loading) {
        recoveryPassword({ values, token });
      }
    },
    [loading, recoveryPassword, token],
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getRecoveryPasswordSchema(t),
    onSubmit: onSubmitHandler,
  });

  const handleChange = useCallback(
    (value: string, name: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (success) goToLogin();
  }, [success, goToLogin]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IRecoveryPasswordFormValues>();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      sortErrors(errors as ErrorsType<IRecoveryPasswordFormValues>);
      return;
    }

    if (serverErrors) {
      sortErrors(serverErrors);
    }
  }, [errors, serverErrors, sortErrors]);

  return (
    <RecoveryPasswordFormComponent
      handleSubmit={handleSubmit}
      onChangeInput={handleChange}
      values={values}
      loading={loading}
      errors={sortedErrors}
      errorsMessage={message}
    />
  );
});
