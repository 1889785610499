import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Text, Form, Heading, Input, InputLabel } from 'atoms';

import { ErrorsType } from 'types';
import { Colors } from 'services/ColorService';
import { ILoginFormValues } from '../LoginForm';

export interface IForgotPasswordForm extends IForgotPasswordFormStyle {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onChangeInput: (value: string, inputName: string) => void;
  values: IForgotPasswordFormValues;
  isMessageSent?: boolean;
  loading?: boolean;
  errors?: ErrorsType<ILoginFormValues> | null;
  errorsMessage?: string | null;
}

export interface IForgotPasswordFormStyle {
  width?: string;
}

export interface IWrapper {
  margin?: string;
  width?: string;
}

export interface IForgotPasswordFormValues {
  email: string;
}

export const ForgotPasswordFormComponent = memo(
  ({
    handleSubmit,
    onChangeInput,
    values,
    isMessageSent = false,
    loading = false,
    errors,
    errorsMessage,
    width,
  }: IForgotPasswordForm) => {
    const { t } = useTranslation();
    return (
      <ForgotPasswordFormStyled width={width}>
        <Wrapper margin="0 0 40px 0">
          <Heading>{t('forgotPasswordForm.forgotPass')}</Heading>
        </Wrapper>
        <Form onSubmit={handleSubmit}>
          <Wrapper>
            <InputLabel hasError={Boolean(errors?.email)}>
              {t('forgotPasswordForm.fields.email.label')}
            </InputLabel>
            <Input
              type="text"
              placeholder={t('forgotPasswordForm.fields.email.placeholder')}
              value={values.email}
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'email')
              }
              name="email"
              hasError={Boolean(errors?.email)}
            />
            <Wrapper margin="16px 0 0 0">
              {!errors?.email && (
                <Text>
                  {isMessageSent
                    ? t('forgotPasswordForm.checkEmail')
                    : t('forgotPasswordForm.letterWillBeSent')}
                </Text>
              )}
              <Text type="error">{errorsMessage}</Text>
            </Wrapper>
          </Wrapper>
          <Wrapper margin="40px 0 0 0">
            <Button
              type="submit"
              loading={loading}
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
              }}
            >
              {isMessageSent
                ? t('forgotPasswordForm.button.enter')
                : t('forgotPasswordForm.button.sendLetter')}
            </Button>
          </Wrapper>
        </Form>
      </ForgotPasswordFormStyled>
    );
  },
);

const ForgotPasswordFormStyled = styled.div<IForgotPasswordFormStyle>`
  width: ${({ width }) => width || '100%'};
  box-sizing: border-box;
  padding: 48px 64px 80px 64px;
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Wrapper = styled.div<IWrapper>`
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin || 'initial'};
`;
