import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';

import { Icon, IconsNames } from '../Icon';

export interface ICloseButtonProps {
  onClick: () => void;
  className?: string;
}

export const CloseButton = ({ onClick, className }: ICloseButtonProps) => {
  const { t } = useTranslation();
  return (
    <StyledButton onClick={onClick} className={className}>
      <ButtonText>{t('button.close')}</ButtonText>
      <IconWrapper>
        <Icon name={IconsNames.CloseIcon} width={24} height={24} />
      </IconWrapper>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  outline: none;
`;

const ButtonText = styled.span`
  font-family: ${getFontFamily('bold')};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${Colors.Black};
  text-decoration: none;
  margin: 0 8px 0 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-top: 2px;
  display: flex;
  align-items: center;
`;
