import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  usePopupStateOperations,
  useDeleteGroup,
  useSearch,
  SearchNames,
  useToasts,
} from 'core/hooks';
import { PopupNames } from 'core';
import { GlobalToastTypeNames, ToastTypes } from 'components/types';

import { DeleteGroupPopupComponent } from './DeleteGroupPopup';

export const DeleteGroupPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [deleteGroup, { success, loading, error }] = useDeleteGroup();
  const { setSearchQuery } = useSearch(SearchNames.settings);

  const {
    operations: { hidePopup },
  } = usePopupStateOperations();

  const onClose = useCallback(() => hidePopup(PopupNames.deleteGroup), [
    hidePopup,
  ]);

  const onConfirm = useCallback(() => {
    if (!loading) {
      deleteGroup();
    }
  }, [deleteGroup, loading]);

  useEffect(() => {
    if (error) {
      addToast({
        data: {
          title: 'Error',
          text: error.message,
          type: ToastTypes.error,
        },
        type: GlobalToastTypeNames.GroupDelete,
      });
    }
  }, [error, addToast]);

  useEffect(() => {
    if (success) {
      setSearchQuery('');
      addToast({
        data: {
          title: `${t('deleteGroupPopup.success')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.GroupDelete,
      });
      onClose();
    }
  }, [onClose, success, setSearchQuery, addToast, t]);

  return <DeleteGroupPopupComponent onClose={onClose} onConfirm={onConfirm} />;
});
