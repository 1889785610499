import { GetCountriesForTeachersQuery, CountryEdge } from 'core';

import { OptionsType } from 'types';

export const transformCountriesData = (
  data: GetCountriesForTeachersQuery | undefined,
): OptionsType => {
  const edges = data?.allCountries?.edges;
  if (!edges) {
    return [];
  }

  return edges.reduce((acc: OptionsType, edge) => {
    if (edge) {
      const groupArr = toOptionsTypeArray(edge as CountryEdge);
      if (groupArr) {
        return [...acc, groupArr];
      }
    }

    return acc;
  }, []);
};

const toOptionsTypeArray = (
  edge: CountryEdge,
): { value: string; label: string } | undefined => {
  const { node } = edge;
  if (node) {
    return {
      value: node.id,
      label: node.title,
    };
  }
};
