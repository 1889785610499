import { gql } from '@apollo/client';

export const ApplicationsQuery = gql`
  query PaginatedTeachersApplications(
    $query: String
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    allTeachersApplications(
      query: $query
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          title
          lessonsCount
          servicePrice
          currency
          isTerminated
          isCompleted
          appointedLessonsCount
          teacher {
            fullName
          }
          group {
            name
          }
          employmentType {
            __typename
          }
        }
      }
    }
  }
`;

export const GetApplicationsForGroup = gql`
  query getApplicationsForGroup(
    $byGroupId: Float
    $isTerminated: Boolean
    $groupsFilter: Boolean
  ) {
    allTeachersApplications(
      byGroupId: $byGroupId
      isTerminated: $isTerminated
      groupsFilter: $groupsFilter
    ) {
      edges {
        node {
          id
          title
          lessonsCount
          appointedLessonsCount
          teacher {
            fullName
          }
        }
      }
    }
  }
`;

export const EditingApplicationQuery = gql`
  query loadEditingApplication(
    $applicationId: ID!
    $first: Int
    $orderBy: String
  ) {
    teacherApplication(applicationId: $applicationId) {
      id
      title
      date
      lessonsCount
      servicePrice
      riaPrice
      currency
      comment
      isTerminated
      lastLessonDate
      isCompleted
      deleteAvailable
      appointedLessonsCount
      country {
        id
        title
      }
      teacher {
        fullName
        employmentTypes {
          __typename
        }
      }
      group {
        id
        name
        freeLessonsCount
        startDate
        course {
          id
          name
        }
      }
      lessons(first: $first, orderBy: $orderBy) {
        edges {
          node {
            date
          }
        }
      }
      employmentType {
        __typename
      }
    }
  }
`;
