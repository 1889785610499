import * as React from 'react';
import { memo, useMemo } from 'react';

import styled, { css } from 'styled-components';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

import { ToastTypes } from 'types';

import { Icon, IconsNames } from 'atoms';

export interface IToastComponent {
  type: ToastTypes;
  title: string;
  text?: string;
  className?: string;
}

export const ToastComponent = memo(
  ({ type, title, text, className }: IToastComponent) => {
    const toastIconName = useMemo(() => {
      switch (type) {
        case ToastTypes.success:
          return text ? IconsNames.Success : IconsNames.CircleSuccess;
        case ToastTypes.info:
          return text ? IconsNames.Info : IconsNames.InfoIcon;
        case ToastTypes.warning:
          return text ? IconsNames.Warning : IconsNames.WarningIcon;
        case ToastTypes.error:
          return text ? IconsNames.Error : IconsNames.ErrorIcon;
        default:
          return IconsNames.Info;
      }
    }, [type, text]);

    return (
      <div className={className}>
        <InnerWrapper $type={type} $text={text}>
          {text ? (
            <>
              <ToastIcon name={toastIconName} />
              <Content>
                <Title>{title}</Title>
                <Text>{text}</Text>
              </Content>
            </>
          ) : (
            <>
              <ToastIconSmall name={toastIconName} />
              <ContentSmall>
                <TitleSmall>{title}</TitleSmall>
              </ContentSmall>
            </>
          )}
        </InnerWrapper>
      </div>
    );
  },
);

const InnerWrapper = styled.div<{ $type: ToastTypes; $text?: string }>`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: ${({ $text }) => ($text ? `16px 24px` : `8px 16px`)};
  border-width: 1px;
  border-style: solid;

  ${({ $type }) => {
    switch ($type) {
      case ToastTypes.success:
        return css`
          border-color: ${Colors.SuccessDefault};
          background-color: #ebf9eb;
        `;
      case ToastTypes.info:
        return css`
          border-color: ${Colors.Blue};
          background-color: ${Colors.BasicBlueLight};
        `;
      case ToastTypes.warning:
        return css`
          border-color: ${Colors.WarningDefault};
          background-color: #fef8e8;
        `;
      case ToastTypes.error:
        return css`
          border-color: ${Colors.ErrorDefault};
          background-color: #fdebeb;
        `;
      default:
        return css``;
    }
  }};
`;

const ToastIcon = styled((props) => <Icon {...props} />)`
  width: 24px;
  height: 24px;
`;

const ToastIconSmall = styled((props) => <Icon {...props} />)`
  width: 16px;
  height: 16px;
  margin: 4px 0;
  flex-shrink: 0;
`;

const Content = styled.div`
  margin-left: 16px;
`;

const ContentSmall = styled.div`
  margin-left: 8px;
`;

const Title = styled.span`
  font-weight: 600;
  color: ${Colors.Black};
  font-family: ${getFontFamily('semibold')};
  font-size: 16px;
  line-height: 24px;
`;

const TitleSmall = styled(Title)`
  font-size: 14px;
  line-height: 24px;
`;

const Text = styled.p`
  margin-top: 8px;
  font-family: ${getFontFamily('regular')};
  font-size: 12px;
  line-height: 16px;
`;
