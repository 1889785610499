import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { useForgotPassword, useGoToLogin, useSortErrors } from 'core/hooks';
import { ErrorsType } from 'types';

import { yupValidation } from 'services/YupValidationService';

import {
  IForgotPasswordFormValues,
  ForgotPasswordFormComponent,
} from './ForgotPasswordForm';
import { ILoginFormValues } from '../LoginForm';

const getForgotPasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    email: yupValidation.email(
      `${t('forgotPasswordForm.fields.email.label')}`,
      t,
    ),
  });

export const ForgotPasswordForm = memo(() => {
  const { t } = useTranslation();
  const [
    forgotPassword,
    { success, loading, errors: serverErrors },
  ] = useForgotPassword();
  const goToLogin = useGoToLogin();

  const onSubmitHandler = useCallback(
    (values: IForgotPasswordFormValues) => {
      if (!loading) {
        const func = success ? goToLogin : () => forgotPassword(values);
        func();
      }
    },
    [loading, forgotPassword, goToLogin, success],
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      email: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getForgotPasswordSchema(t),
    onSubmit: onSubmitHandler,
  });

  const handleChange = useCallback(
    (value: string, name: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<ILoginFormValues>();

  useEffect(() => {
    sortErrors(serverErrors);
  }, [serverErrors, sortErrors]);

  useEffect(() => {
    sortErrors(errors as ErrorsType<ILoginFormValues>);
  }, [errors, sortErrors]);

  return (
    <ForgotPasswordFormComponent
      handleSubmit={handleSubmit}
      onChangeInput={handleChange}
      values={values}
      isMessageSent={success}
      loading={loading}
      errors={sortedErrors}
      errorsMessage={message}
    />
  );
});
