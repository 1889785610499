import { IAddApplicationFieldsValues } from 'molecules';
import { ServerCodes, ErrorsType, FieldErrorTypes } from 'types';
import { CustomErrorType } from 'core/graphql';
import i18n from '../../../i18n';

interface ITeacherErrorsAction {
  type: ServerCodes;
}

export const applicationTeacherErrorsReducer = (
  state: ErrorsType<IAddApplicationFieldsValues> | null,
  action: ITeacherErrorsAction,
): ErrorsType<IAddApplicationFieldsValues> | null => {
  switch (action.type) {
    case ServerCodes.applicationTitleExist:
      return {
        applicationNumber: {
          type: FieldErrorTypes.SERVER_ERROR,
          message: `${i18n.t('common.applicationExist')}`,
        },
      };
    case ServerCodes.lessonCountExceeds:
      return {
        lessonsNumber: {
          type: FieldErrorTypes.SERVER_ERROR,
          message: `${i18n.t('common.lessonCountExceeds')}`,
        },
      };
    case ServerCodes.lessonCountFew:
      return {
        lessonsNumber: {
          type: FieldErrorTypes.SERVER_ERROR,
          message: `${i18n.t('common.teacherApplicationExceeds')}`,
        },
      };
    default:
      return null;
  }
};

export const getApplicationErrors = (errors: CustomErrorType[]) => {
  const applicationExist = errors.some(
    (error) => error.code === 'unique' && error.field === 'title',
  );
  const lessonCountExceeds = errors.some(
    (error) =>
      error.code === 'exceeds_available' && error.field === 'lessons_count',
  );
  const lessonCountFew = errors.some(
    (error) =>
      error.code === 'few_lessons_count' && error.field === 'lessons_count',
  );
  return {
    applicationExist,
    lessonCountExceeds,
    lessonCountFew,
  };
};
