import * as React from 'react';
import { memo, useCallback, ChangeEvent } from 'react';

import styled, { css } from 'styled-components';

import { Button, Icon, IconsNames, Input } from 'atoms';

import { Colors } from 'services/ColorService';

export interface ISearch {
  value: string;
  onChange: (value: string) => void;
  onSearch?: () => void;
  onFocus?: () => void;
  clear?: () => void;
  placeholder?: string;
  buttonProps?: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
  };
}

export const Search = memo(
  ({
    value,
    onChange,
    onSearch,
    onFocus,
    clear,
    buttonProps,
    placeholder,
  }: ISearch) => {
    const onChangeHandler = useCallback(
      ({ target: { value: targetValue } }: ChangeEvent<HTMLInputElement>) => {
        onChange(targetValue);
      },
      [onChange],
    );

    return (
      <SearchStyled>
        {value ? (
          <CloseIconWrapper onClick={clear}>
            <Icon name={IconsNames.CloseIcon} />
          </CloseIconWrapper>
        ) : (
          <SearchIconWrapper onClick={onSearch}>
            <Icon name={IconsNames.SearchIcon} />
          </SearchIconWrapper>
        )}
        <InputWrapper>
          <InputRestyled
            type="text"
            placeholder={placeholder || 'Enter any text...'}
            value={value}
            onChange={onChangeHandler}
            onEnter={onSearch}
            onFocus={onFocus}
          />
        </InputWrapper>
        {buttonProps && (
          <ButtonWrapper>
            <ButtonRestyled
              colors={{
                color: Colors.White,
                backgroundColor: Colors.Blue,
              }}
              onClick={buttonProps.onClick}
              disabled={buttonProps.disabled}
            >
              {buttonProps.label}
            </ButtonRestyled>
          </ButtonWrapper>
        )}
      </SearchStyled>
    );
  },
);

const SearchStyled = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 8px 8px 24px;
  border-radius: 8px;
  background: ${Colors.White};
  border: 1px solid ${Colors.LightGray};
`;

const InputRestyled = styled(Input)`
  border: none;
  line-height: 17px;
  padding: 11px 16px 11px 12px;
  :focus {
    outline: none;
  }
`;

const ButtonRestyled = styled(Button)`
  white-space: nowrap;
  min-height: 40px;
  padding: 0 16px 0 16px;
  font-size: 13px;
  line-height: 16px;
`;

const baseWrapperStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled.div`
  ${baseWrapperStyles}
  width: 100%;
`;

const ButtonWrapper = styled.div`
  ${baseWrapperStyles}
`;

const CloseIconWrapper = styled.div`
  ${baseWrapperStyles}
`;

const SearchIconWrapper = styled.div`
  ${baseWrapperStyles}
`;
