import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { LoadingIndicator } from 'atoms';

import { getFontFamily } from 'services/FontService';

interface ILoadingTable {
  className?: string;
}

export const LoadingTable = memo(({ className }: ILoadingTable) => {
  const { t } = useTranslation();
  return (
    <Wrapper className={className}>
      <LoadingCircle />
      <Header>{t('loadingData')}</Header>
      <Subtext>
        {t('pleaseWait')}
        <br />
        {t('loadingProcess')}
      </Subtext>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 104px 0 140px;
  text-align: center;
`;

const LoadingCircle = styled((props) => <LoadingIndicator {...props} />)`
  height: 136px;
  width: 136px;
`;

const Header = styled.span`
  margin-top: 52px;
  font-family: ${getFontFamily('bold')};
  font-size: 20px;
  line-height: 24px;
`;

const Subtext = styled.p`
  margin-top: 16px;
  font-family: ${getFontFamily('medium')};
`;
