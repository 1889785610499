import * as React from 'react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import {
  PopupNames,
  popupData,
  ExistEmploymentInputType,
  TeacherWithTypeInput,
} from 'core';
import {
  useToasts,
  useLoadEditingTeacher,
  useCountriesOptions,
  usePopupStateOperations,
  useUpdateTeacherWithType,
  useSortErrors,
} from 'core/hooks';

import { ISelectOption } from 'components/atoms';
import {
  TEACHER_TYPE_OPTIONS,
  TEACHER_TYPE_COUNT_FIELDS,
} from 'components/constants';
import {
  TeacherHireType,
  ToastTypes,
  ErrorsType,
  GlobalToastTypeNames,
} from 'types';
import { yupValidation } from 'services/YupValidationService';
import { ITeacherAddTypeValuesFields } from 'molecules';
import {
  TeacherEditPopupComponent,
  ITeacherEditValues,
} from './TeacherEditPopup';

export const getTeacherEditSchema = (t: TFunction) =>
  Yup.object().shape({
    firstName: yupValidation.firstName(
      `${t('teacherPopup.fields.firstName.labelSecond')}`,
      t,
    ),
    middleName: yupValidation.middleName(
      `${t('teacherPopup.fields.middleName.labelSecond')}`,
      t,
    ),
    lastName: yupValidation.lastName(
      `${t('teacherPopup.fields.lastName.labelSecond')}`,
      t,
    ),
    phone: yupValidation.phone(`${t('teacherPopup.fields.phone.label')}`, t),
    email: yupValidation.email(`${t('teacherPopup.fields.email.label')}`, t),
  });

export const TeacherEditPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const teacherId = popupData()[PopupNames.teacherEditPopup];
  const [getEditTeacher, { data, loading }] = useLoadEditingTeacher();

  const { countries } = useCountriesOptions();
  const {
    operations: { hidePopup },
  } = usePopupStateOperations();

  useEffect(() => {
    if (teacherId) {
      getEditTeacher(teacherId.teacherId);
    }
  }, [teacherId, getEditTeacher]);

  const [
    updateTeacher,
    { success, errors: serverErrors },
  ] = useUpdateTeacherWithType();

  const onSubmit = useCallback(
    (values: ITeacherEditValues) => {
      const employmentTypes = values.teacherTypes.reduce((acc, cur) => {
        if (cur.type) {
          return {
            ...acc,
            [cur.type.value]: cur[cur.type.value as keyof typeof cur],
          };
        }
        return {};
      }, {}) as ITeacherAddTypeValuesFields;

      const employmentInput: ExistEmploymentInputType = {
        individualEntrepreneur: employmentTypes?.[TeacherHireType.Ie]
          ? {
              registrationAddress:
                employmentTypes?.[TeacherHireType.Ie].registrationAddress || '',
              accountNumber:
                employmentTypes?.[TeacherHireType.Ie].accountNumber || '',
              bankTitle: employmentTypes?.[TeacherHireType.Ie].bankTitle || '',
              bic: employmentTypes?.[TeacherHireType.Ie].bic || '',
              inn: employmentTypes?.[TeacherHireType.Ie].inn || '',
              existId: employmentTypes?.[TeacherHireType.Ie].id,
            }
          : undefined,
        selfEmployed: employmentTypes?.[TeacherHireType.Se]
          ? {
              registrationAddress:
                employmentTypes?.[TeacherHireType.Se].registrationAddress || '',
              accountNumber:
                employmentTypes?.[TeacherHireType.Se].accountNumber || '',
              bankTitle: employmentTypes?.[TeacherHireType.Se].bankTitle || '',
              bic: employmentTypes?.[TeacherHireType.Se].bic || '',
              nationality:
                employmentTypes?.[TeacherHireType.Se].nationality?.id || '',
              passportNumber:
                employmentTypes?.[TeacherHireType.Se].passportNumber || '',
              inn: employmentTypes?.[TeacherHireType.Se].inn || '',
              existId: employmentTypes?.[TeacherHireType.Se].id,
            }
          : undefined,
        naturalPerson: employmentTypes?.[TeacherHireType.Np]
          ? {
              registrationAddress:
                employmentTypes?.[TeacherHireType.Np].registrationAddress || '',
              accountNumber:
                employmentTypes?.[TeacherHireType.Np].accountNumber || '',
              bankTitle: employmentTypes?.[TeacherHireType.Np].bankTitle || '',
              bic: employmentTypes?.[TeacherHireType.Np].bic || '',
              nationality:
                employmentTypes?.[TeacherHireType.Np].nationality?.id || '',
              passportNumber:
                employmentTypes?.[TeacherHireType.Np].passportNumber || '',
              existId: employmentTypes?.[TeacherHireType.Np].id,
            }
          : undefined,
        legalEntity: employmentTypes?.[TeacherHireType.Le]
          ? {
              registrationAddress:
                employmentTypes?.[TeacherHireType.Le].registrationAddress || '',
              accountNumber:
                employmentTypes?.[TeacherHireType.Le].accountNumber || '',
              bankTitle: employmentTypes?.[TeacherHireType.Le].bankTitle || '',
              bic: employmentTypes?.[TeacherHireType.Le].bic || '',
              tittle: employmentTypes?.[TeacherHireType.Le].title || '',
              inn: employmentTypes?.[TeacherHireType.Le].inn || '',
              existId: employmentTypes?.[TeacherHireType.Le].id,
            }
          : undefined,
      };

      const teacherInput: TeacherWithTypeInput = {
        firstName: values.firstName || '',
        lastName: values.lastName || '',
        middleName: values.middleName || '',
        email: values.email || '',
        phone: values.phone || '',
        siteDisplay: values.displayOnSite || false,
        accessToLms: values.accessToLms || false,
      };

      updateTeacher({
        employmentInput,
        teacherId: teacherId?.teacherId,
        teacherInput,
      });
    },
    [updateTeacher, teacherId?.teacherId],
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      teacherTypes: Array(1)
        .fill({
          [TeacherHireType.Le]: {},
          [TeacherHireType.Ie]: {},
          [TeacherHireType.Np]: {},
          [TeacherHireType.Se]: {},
          type: null,
        })
        .map((item, index) => ({ ...item, id: index })),
      firstName: data?.firstName || '',
      middleName: data?.middleName || '',
      lastName: data?.lastName || '',
      phone: data?.phone || '',
      email: data?.email || '',
      displayOnSite: data?.displayOnSite || false,
      accessToLms: data?.accessToLms || false,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getTeacherEditSchema(t),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!data?.teacherTypes.length) {
      return;
    }

    const newValues = data?.teacherTypes.map((existingType, index) => ({
      [TeacherHireType.Le]: {},
      [TeacherHireType.Ie]: {},
      [TeacherHireType.Np]: {},
      [TeacherHireType.Se]: {},
      ...existingType,
      type:
        TEACHER_TYPE_OPTIONS.find(
          (typeOption) => typeOption.value === Object.keys(existingType)[0],
        ) || null,
      id: String(index),
      disabled: true,
    }));

    setFieldValue('teacherTypes', newValues);
  }, [data?.teacherTypes, setFieldValue]);

  const onClose = useCallback(() => hidePopup(PopupNames.teacherEditPopup), [
    hidePopup,
  ]);

  const handleChange = useCallback(
    (
      name: string,
      value?: string | boolean | ISelectOption<string> | null | OptionTypeBase,
    ) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const onAddType = useCallback(
    () =>
      setFieldValue('teacherTypes', [
        ...values.teacherTypes,
        {
          [TeacherHireType.Le]: {},
          [TeacherHireType.Ie]: {},
          [TeacherHireType.Np]: {},
          [TeacherHireType.Se]: {},
          type: null,
          id: values.teacherTypes.length,
        },
      ]),
    [values, setFieldValue],
  );

  const onDeleteType = useCallback(
    (id) => {
      const newValues = values.teacherTypes.filter(
        (type, index) => index !== id,
      );
      setFieldValue('teacherTypes', newValues);
    },
    [values.teacherTypes, setFieldValue],
  );

  const teacherTypesOptions = useMemo(
    () =>
      TEACHER_TYPE_OPTIONS.reduce((acc: ISelectOption<string>[], cur) => {
        const isDisabled = Boolean(
          values.teacherTypes.find((e) => e.type?.value === cur.value),
        );
        return [...acc, { ...cur, disabled: isDisabled }];
      }, []),
    [values.teacherTypes],
  );

  const disabledButtonHelper = useCallback((currentValue) => {
    switch (currentValue.type.value) {
      case TeacherHireType.Ie:
        return (
          Object.values(currentValue[TeacherHireType.Ie]).length >=
            TEACHER_TYPE_COUNT_FIELDS[TeacherHireType.Ie] &&
          Object.values(currentValue[TeacherHireType.Ie]).every((cur) =>
            Boolean(cur),
          )
        );
      case TeacherHireType.Se:
        return (
          Object.values(currentValue[TeacherHireType.Se]).length >=
            TEACHER_TYPE_COUNT_FIELDS[TeacherHireType.Se] &&
          Object.values(currentValue[TeacherHireType.Se]).every((cur) =>
            Boolean(cur),
          )
        );
      case TeacherHireType.Np:
        return (
          // TODO: validate values in a better way
          Object.values(currentValue[TeacherHireType.Np]).length >=
            TEACHER_TYPE_COUNT_FIELDS[TeacherHireType.Np] &&
          Object.values(currentValue[TeacherHireType.Np]).every((cur) =>
            Boolean(cur),
          )
        );
      case TeacherHireType.Le:
        return (
          Object.values(currentValue[TeacherHireType.Le]).length >=
            TEACHER_TYPE_COUNT_FIELDS[TeacherHireType.Le] &&
          Object.values(currentValue[TeacherHireType.Le]).every((cur) =>
            Boolean(cur),
          )
        );
      default:
        return false;
    }
  }, []);

  const disabledTypesButton: boolean = useMemo(
    () =>
      values.teacherTypes.every((currentValue) =>
        currentValue.type ? disabledButtonHelper(currentValue) : false,
      ),
    [values.teacherTypes, disabledButtonHelper],
  );

  const disabledUserButton: boolean = useMemo(
    () =>
      !values.phone || !values.email || !values.firstName || !values.lastName,
    [values],
  );

  const disabledButton: boolean = useMemo(
    () => disabledUserButton || !disabledTypesButton,
    [disabledUserButton, disabledTypesButton],
  );

  useEffect(() => {
    if (success) {
      addToast({
        data: {
          title: `${t('teacherPopup.teacherUpdateSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.TeacherEdit,
      });
      hidePopup(PopupNames.teacherEditPopup);
    }
  }, [success, hidePopup, addToast, t]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<ITeacherEditValues>();

  useEffect(() => {
    sortErrors({
      ...(errors as ErrorsType<ITeacherEditValues>),
      ...serverErrors,
    });
  }, [errors, serverErrors, sortErrors]);

  if (loading) {
    return <h1>Loading..</h1>;
  }

  return (
    <TeacherEditPopupComponent
      values={values}
      countries={countries}
      teacherTypesOptions={teacherTypesOptions}
      errors={sortedErrors}
      errorMessage={message}
      disabledButton={disabledButton}
      onInputChange={handleChange}
      handleSubmit={handleSubmit}
      onClose={onClose}
      onAddType={onAddType}
      onDeleteType={onDeleteType}
    />
  );
});
