import styled from 'styled-components';

export interface IRow {
  margin?: string;
}

export const Row = styled.div<IRow>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: ${({ margin }) => margin || 'initial'};
`;
