import * as React from 'react';

import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';

export interface ITabItem extends ITabItemStyle {
  children: React.ReactNode;
  to: string;
}

export interface ITabItemStyle {
  isActive?: boolean;
}

export const TabItem = ({ children, isActive, to, ...rest }: ITabItem) => (
  <StyledTabItem isActive={isActive}>
    <StyledLink to={to} {...rest}>
      {children}
    </StyledLink>
  </StyledTabItem>
);

const StyledLink = styled(Link)`
  width: 100%;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  box-sizing: inherit;
  text-align: center;
  font-family: ${getFontFamily('semibold')};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  padding: 13px;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
`;

const StyledTabItem = styled.li<ITabItemStyle>`
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  ${StyledLink} {
    color: ${({ isActive }) => (isActive ? Colors.Blue : Colors.Gray)};
    border-bottom: ${({ isActive }) =>
      isActive ? `2px solid ${Colors.Blue}` : `none`};
  }
`;
