import { popupState, popupData } from 'core';
import { IPopupState } from 'core/state';
import { hideOtherPopUps } from './hideOtherPopUps';
import { IPopupStateOperations } from './PopupHooks';

export const getPopupStateOperations = (
  state = popupState,
): IPopupStateOperations => {
  const setPopup = <T>(
    isOpen: boolean,
    popup: keyof IPopupState,
    data: T | null,
  ) => {
    state({ ...hideOtherPopUps(), [popup]: isOpen });
    if (data) {
      popupData({ ...popupData(), [popup]: data });
    }
  };

  const showPopup = <T>(popup: keyof IPopupState, data: T | null = null) =>
    setPopup<T>(true, popup, data);
  const hidePopup = <T>(popup: keyof IPopupState) => {
    setPopup<T>(false, popup, null);
    popupData({ ...popupData(), [popup]: undefined });
  };

  return { showPopup, hidePopup };
};
