import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { PATHS } from 'router/config';

export const useGoToMain = () => {
  const history = useHistory();

  const goToMain = useCallback(() => {
    history.push(PATHS.main);
  }, [history]);

  return goToMain;
};
