/* eslint-disable @typescript-eslint/no-unused-vars */
import { gql } from '@apollo/client';

export const createUserMutation = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $middleName: String!
    $email: String!
    $roles: String!
    $phone: String!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      email: $email
      roles: $roles
      phone: $phone
    ) {
      success
      errors
      customErrors {
        field
        message
        code
      }
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUserSettings(
    $pk: ID!
    $firstName: String!
    $lastName: String!
    $middleName: String!
    $email: String!
    $roles: String!
    $phone: String!
  ) {
    updateUserSettings(
      userId: $pk
      userSettingsInput: {
        firstName: $firstName
        lastName: $lastName
        middleName: $middleName
        email: $email
        roles: $roles
        phone: $phone
      }
    ) {
      user {
        id
      }
      customErrors {
        field
        message
        code
      }
    }
  }
`;

export const deleteUserMutation = gql`
  mutation deleteUser($pk: ID!) {
    deleteUserSettings(userId: $pk) {
      success
      errors
    }
  }
`;

export const addUserRolesMutation = gql`
  mutation addUserRoles($roles: String!, $userId: ID!) {
    appendRolesUserSettings(roles: $roles, userId: $userId) {
      user {
        id
      }
    }
  }
`;
