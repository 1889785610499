import { IUserData, ServerCodes } from 'components/types';

import { ITeacherAddTypeValues } from 'molecules';

import { popupData, PopupNames } from 'core';

type GetTeacherTypesDataHookType = {
  values?: ITeacherAddTypeValues;
  user?: IUserData;
  teacherId?: string;
  code?: ServerCodes;
};

export const useGetTeacherTypesData = (): GetTeacherTypesDataHookType => {
  const data = popupData()[PopupNames.teacherAddTypePopup];

  return {
    values: data?.values,
    user: data?.user,
    code: data?.code,
  };
};
