import React, { memo, useCallback, useEffect } from 'react';

import styled from 'styled-components';

import { SearchNames, useSearch, useTeachers } from 'core/hooks';

import { TableTeachers } from 'components/molecules';

export const Teachers = memo(() => {
  const [
    fetchMoreTeachers,
    { data: teachers, hasMore, after, loading, error },
  ] = useTeachers();

  const { searchQuery } = useSearch(SearchNames.teachers);

  const loadMore = useCallback(() => {
    const query = searchQuery.trim();
    fetchMoreTeachers({ query, after });
  }, [searchQuery, after, fetchMoreTeachers]);

  useEffect(() => {
    const query = searchQuery.trim();
    fetchMoreTeachers({ query });
  }, [searchQuery, fetchMoreTeachers]);

  return (
    <Wrapper>
      <TableTeachers
        data={teachers || []}
        loading={loading}
        error={error}
        loadMore={loadMore}
        hasMore={hasMore}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-top: 16px;
`;
