import { gql } from '@apollo/client';

export const GroupsQuery = gql`
  query PaginatedGroups($query: String, $first: Int, $after: String) {
    allGroups(query: $query, first: $first, after: $after) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          startDate
          lessonsCount
          studentsCount
          classesDays {
            weekDay
          }
          publishedLms
          publishedLmsForTeacher
          publishedLmsForMethodist
          price
          currency
          contributionsSum
        }
      }
    }
  }
`;

export const EditingGroupQuery = gql`
  query loadEditingGroup($groupId: ID!) {
    group(groupId: $groupId) {
      id
      name
      price
      currency
      startDate
      deleteAvailable
      course {
        id
        name
      }
      classesDays {
        id
        weekDay
        startTime
        endTime
      }
      modules {
        id
        name
        lessons {
          edges {
            node {
              id
              name
              lessonNumber
              additional
              date
              lmsDescription
              teacherApplication {
                id
                teacher {
                  fullName
                }
              }
            }
          }
        }
      }
    }
  }
`;
