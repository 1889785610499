import { ICountry } from './common';

export enum TeacherHireType {
  Ie = 'Ie',
  Le = 'Le',
  Np = 'Np',
  Se = 'Se',
}

export type TeacherIEType = {
  id: string;
  registrationAddress: string;
  accountNumber: string;
  bankTitle: string;
  bic: string;
  inn: string;
};

export type TeacherLEType = {
  id: string;
  registrationAddress: string;
  accountNumber: string;
  bankTitle: string;
  bic: string;
  inn: string;
  title: string;
};

export type TeacherNPType = {
  id: string;
  registrationAddress: string;
  accountNumber: string;
  bankTitle: string;
  bic: string;
  nationality: ICountry;
  passportNumber: string;
};

export type TeacherSEType = {
  id: string;
  registrationAddress: string;
  accountNumber: string;
  bankTitle: string;
  bic: string;
  inn: string;
  nationality: ICountry;
  passportNumber: string;
};
