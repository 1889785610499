import * as React from 'react';
import { FC, memo } from 'react';

import { Row, useTable } from 'react-table';
import styled from 'styled-components';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  IconsNames,
} from 'atoms';

import { LoadingData, LoadingTable, InfiniteScrollContainer } from 'molecules';

import { IconSizes } from 'components/types';
import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

type Accessors = 'username' | 'role' | 'email';

export type IRow = {
  [key in Accessors]: React.ReactNode;
} & {
  id: string;
};

export interface IColumn {
  accessor: Accessors;
  flexible?: boolean;
  align?: 'left' | 'right' | 'center';
  Header?: string | React.ReactElement;
  columns?: IColumn[];
  marginRight?: string;
  marginLeft?: string;
  columnWidth?: string;
}

export interface IUsersSettingsTableComponent {
  columns: IColumn[];
  rows: IRow[];
  isFlexible: boolean;
  onUserEditClick: (userId: string) => void;
  noDataText: string;
  loadMore: () => void;
  hasMore: boolean;
  loading?: boolean;
}

const getCellWrapper = (columnId: Accessors): FC => {
  switch (columnId) {
    case 'email':
      return UserTextWrapper;
    default:
      return UserDefaultWrapper;
  }
};

export const UsersSettingsTableComponent = memo(
  ({
    columns,
    rows: rowsSource,
    isFlexible,
    onUserEditClick,
    noDataText,
    loadMore,
    hasMore,
    loading,
  }: IUsersSettingsTableComponent) => {
    const {
      getTableProps,
      headers,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data: rowsSource,
    });

    return (
      <Wrapper>
        <InfiniteScrollContainer
          loadMore={loadMore}
          hasMore={hasMore}
          dataLength={rows.length}
          loading={loading || false}
        >
          <Table {...getTableProps()} flexible={isFlexible} rowShadowOnHover>
            <TableHead>
              <TableRow type="row">
                {headers.map((column: any) => {
                  const {
                    key: cellKey,
                    ...restCellProps
                  } = column.getHeaderProps({
                    type: 'headerCell',
                    flexible: column.flexible && isFlexible,
                    width: column.columnWidth,
                    margin: {
                      right: column.marginRight,
                      left: column.marginLeft,
                    },
                  });
                  return (
                    <TableCell key={cellKey} {...restCellProps}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
                <TableCell key="sort" type="headerCell" width="72px">
                  {null}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {!rows.length && !loading && (
                <TableRow key="noData">
                  <TableCell type="cell" width="100%" align="center">
                    {noDataText}
                  </TableCell>
                </TableRow>
              )}
              {!rows.length && loading && (
                <TableRow>
                  <TableCell width="100%" align="center">
                    <LoadingTable />
                  </TableCell>
                </TableRow>
              )}
              {Boolean(rows.length) &&
                rows.map((row: Row<IRow>) => {
                  prepareRow(row);
                  const { key: rowKey, ...restRowProps } = row.getRowProps();
                  const { id } = row.original;

                  return (
                    <UsersTableRow key={rowKey} type="row" {...restRowProps}>
                      {row.cells.map((cell: any) => {
                        const {
                          key: cellKey,
                          ...restCellProps
                        } = cell.getCellProps({
                          width: cell.column.columnWidth,
                          flexible: cell.column.flexible && isFlexible,
                          margin: {
                            right: cell.column.marginRight,
                            left: cell.column.marginLeft,
                          },
                        });

                        const columnId = cell.column.id;
                        const CellWrapper = getCellWrapper(columnId);
                        return (
                          <TableCell key={cellKey} {...restCellProps}>
                            <CellWrapper>{cell.render('Cell')}</CellWrapper>
                          </TableCell>
                        );
                      })}
                      <RowButtons>
                        <IconButtonStyled
                          size={IconSizes.lg}
                          name={IconsNames.Edit}
                          onClick={() => onUserEditClick(id)}
                        />
                      </RowButtons>
                    </UsersTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </InfiniteScrollContainer>
        {Boolean(rows.length) && loading && <LoadingData />}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 60px;
`;

const UserDefaultWrapper = styled.span`
  font-family: ${getFontFamily()};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${Colors.Black};
`;

const UserTextWrapper = styled(UserDefaultWrapper)`
  color: ${Colors.Blue};
`;

const UsersTableRow = styled((props) => (
  <TableRow {...props}>{props.children}</TableRow>
))`
  position: relative;
`;

const RowButtons = styled.td`
  position: absolute;
  top: 12px;
  right: 24px;
  bottom: 12px;
  display: none;
  align-items: center;
  ${UsersTableRow}:hover & {
    display: flex;
  }
`;

const IconButtonStyled = styled((props) => <IconButton {...props} />)`
  display: flex;
  height: 32px;
  width: 32px;
  border-radius: 100%;

  :hover {
    & svg {
      & > path {
        fill: ${Colors.White};
      }
      & > circle {
        fill: ${Colors.Blue};
      }
    }
  }
`;
