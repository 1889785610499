import * as React from 'react';
import { memo, useCallback } from 'react';

import styled from 'styled-components';

import { ToggleCheckbox } from 'molecules';

export interface IToggleCheckBoxList {
  hasError: boolean;
  values: IToggleCheckBoxButtons;
  onChange: (values: IToggleCheckBoxButtons) => void;
}

export interface IToggleCheckBoxButtons {
  [key: string]: boolean;
}

export const ToggleCheckBoxList = memo(
  ({ hasError, values, onChange }: IToggleCheckBoxList) => {
    const handleToggleCheckBoxChange = useCallback(
      (checkBoxValue: string) => {
        onChange({ ...values, ...{ [checkBoxValue]: !values[checkBoxValue] } });
      },
      [values, onChange],
    );

    return (
      <Wrapper>
        {Object.keys(values).map((role) => (
          <StyledToggleCheckBox key={role}>
            <ToggleCheckbox
              value={role}
              error={hasError}
              active={Boolean(values[role])}
              onChange={handleToggleCheckBoxChange}
            />
          </StyledToggleCheckBox>
        ))}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledToggleCheckBox = styled.div`
  margin: 0 8px 8px 0;

  &:last-child {
    margin-right: 0;
  }
`;
