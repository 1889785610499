import { useCallback } from 'react';

import {
  PaginatedPerformancesQueryVariables,
  StudentsAchievementEdge,
  usePaginatedPerformancesQuery,
} from 'core/graphql';
import { IPerformance } from 'types';
import { transformPerformancesEdges } from './transform';

type PerformancesHookType = [
  (vars: PaginatedPerformancesQueryVariables) => void,
  {
    data: IPerformance[];
    loading?: boolean;
    error?: string;
    hasMore: boolean;
    after: string;
  },
];

export const usePerformances = (): PerformancesHookType => {
  const { data, loading, error, fetchMore } = usePaginatedPerformancesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      first: 10,
    },
    notifyOnNetworkStatusChange: true,
  });

  const result = (): IPerformance[] => {
    const edges = data?.allStudentsAchievement?.edges;
    if (!edges) {
      return [];
    }

    return transformPerformancesEdges(edges as StudentsAchievementEdge[]);
  };

  const fetchMoreData = useCallback(
    ({ query, after }: PaginatedPerformancesQueryVariables) => {
      fetchMore({
        variables: {
          query,
          after,
        },
      });
    },
    [fetchMore],
  );

  return [
    fetchMoreData,
    {
      data: result(),
      loading,
      error: error?.message,
      hasMore: data?.allStudentsAchievement?.pageInfo.hasNextPage || false,
      after: data?.allStudentsAchievement?.pageInfo.endCursor || '',
    },
  ];
};
