import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Button, Row, Text } from 'atoms';

import { Colors } from 'services/ColorService';
import { PopupContainer } from '../PopupContainer';

export interface IStudentWarningPopup {
  onClose: () => void;
  onCancel: () => void;
}

export const StudentWarningPopupComponent = memo(
  ({ onClose, onCancel }: IStudentWarningPopup) => {
    const { t } = useTranslation();
    return (
      <PopupContainer
        onClose={onClose}
        title={t('studentWarningPopup.studentWontBeSaved')}
      >
        <TextWrapper size="md">
          {t('studentWarningPopup.youAreGoingToClose')}
        </TextWrapper>

        <Row>
          <WrapperDeleteButton
            onClick={onCancel}
            variant="outline"
            colors={{
              backgroundColor: Colors.White,
              color: Colors.Blue,
            }}
          >
            {t('studentWarningPopup.button.cancel')}
          </WrapperDeleteButton>

          <WrapperSendButton
            onClick={onClose}
            colors={{
              backgroundColor: Colors.Blue,
              color: Colors.White,
            }}
          >
            {t('studentWarningPopup.button.yes')}
          </WrapperSendButton>
        </Row>
      </PopupContainer>
    );
  },
);

const TextWrapper = styled((props) => <Text {...props} />)`
  margin: 24px 0 32px 0;
`;

const WrapperDeleteButton = styled((props) => <Button {...props} />)`
  margin: 0 16px 0 auto;
  width: 122px;
`;

const WrapperSendButton = styled((props) => <Button {...props} />)`
  width: 155px;
`;
