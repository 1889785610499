import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IForgotPasswordFormValues } from 'molecules';
import { useSendPasswordResetEmailMutation } from 'core/graphql';
import { ErrorsType, FieldErrorTypes } from 'types';

type RecoveryPasswordHookType = [
  (vars: IForgotPasswordFormValues) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<IForgotPasswordFormValues> | null;
  },
];

export const useForgotPassword = (): RecoveryPasswordHookType => {
  const { t } = useTranslation();
  const result = useSendPasswordResetEmailMutation();
  const [forgotPasswordFn, { data, loading }] = result;

  const forgotPassword = useCallback(
    ({ email }: IForgotPasswordFormValues) =>
      forgotPasswordFn({ variables: { email } }),
    [forgotPasswordFn],
  );

  const errors = useMemo(() => {
    if (data?.sendPasswordResetEmail?.errors) {
      return {
        email: {
          type: FieldErrorTypes.VALIDATION,
          message: `${t('common.emailIsNotInSystem')}`,
        },
      };
    }

    return null;
  }, [data?.sendPasswordResetEmail?.errors, t]);

  return [
    forgotPassword,
    {
      success: Boolean(data?.sendPasswordResetEmail?.success),
      loading,
      errors,
    },
  ];
};
