import { AllTeachersQuery } from 'core';

import { ITeacherUser } from 'components/types';

export const transformTeachersNode = (
  teachers: AllTeachersQuery | undefined,
): ITeacherUser[] | undefined => {
  if (teachers?.allTeachers?.edges) {
    const teachersUsers = teachers.allTeachers.edges.reduce(
      (teachersUsersArr: ITeacherUser[], currentTeacher): ITeacherUser[] => {
        if (currentTeacher?.node) {
          const courses = currentTeacher.node.courses
            ? currentTeacher.node.courses.join(', ')
            : '';
          const newTeacher = {
            id: currentTeacher.node.id,
            fullName: currentTeacher.node.fullName || '',
            isDismissed: currentTeacher.node.isDismissed || false,
            phone: currentTeacher.node.user.phone || '',
            email: currentTeacher.node.user.email,
            accessToLms: currentTeacher.node.accessToLms,
            courses,
          };
          return [...teachersUsersArr, newTeacher];
        }

        return teachersUsersArr;
      },
      [],
    );

    return teachersUsers;
  }
};
