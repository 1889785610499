import * as React from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { IJournal } from 'types';

import { JournalsTableComponent, IColumn, IRow } from './JournalsTable';

export interface IJournalsTable {
  journals: IJournal[];
  loading?: boolean;
  error?: string;
  hasMore: boolean;
  loadMore: () => void;
  showEditJournalPopup: (journalId: string) => void;
}

const getColumnsSource = (t: TFunction): IColumn[] => [
  {
    Header: `${t('table.journal.name')}`,
    accessor: 'name',
    columnWidth: '160px',
  },
  {
    Header: `${t('table.journal.teachers')}`,
    accessor: 'teachersString',
    columnWidth: '480px',
    marginRight: '80px',
    marginLeft: '80px',
  },
  {
    Header: `${t('table.journal.course')}`,
    accessor: 'course',
    flexible: true,
    marginRight: '130px',
  },
];

export const JournalsTable = memo(
  ({
    journals,
    loading,
    error,
    hasMore,
    loadMore,
    showEditJournalPopup,
  }: IJournalsTable) => {
    const { t } = useTranslation();
    const columns: IColumn[] = useMemo(() => getColumnsSource(t), [t]);
    const rows: IRow[] = useMemo(() => journals, [journals]);

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <JournalsTableComponent
        rows={rows}
        columns={columns}
        isFlexible
        noDataText={t('table.journal.noDataJournals')}
        hasMore={hasMore}
        loadMore={loadMore}
        onClickEdit={showEditJournalPopup}
        loading={loading}
      />
    );
  },
);
