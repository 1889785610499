import React, { memo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { ValueType, OptionTypeBase } from 'react-select';
import * as R from 'ramda';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { PopupNames } from 'core';
import {
  useLoadEditingJournal,
  useModulesData,
  usePopupData,
  usePopupStateOperations,
  useSortErrors,
  useUpdateJournalMutation,
  useToasts,
  useApplicationsOptionsForGroup,
} from 'core/hooks';
import { ToastTypes, ErrorsType, GlobalToastTypeNames } from 'types';

import { yupValidation } from 'services/YupValidationService';

import {
  EditJournalPopupComponent,
  IEditJournalPopupValues,
} from './EditJournalPopup';

export const getJournalEditSchema = (t: TFunction) =>
  Yup.object().shape({
    lessonsDays: yupValidation.lessonsDays(t),
  });

export const EditJournalPopup = memo(() => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const {
    operations: { hidePopup },
  } = usePopupStateOperations();
  const onClose = useCallback(() => hidePopup(PopupNames.editJournalPopup), [
    hidePopup,
  ]);
  const journalId = usePopupData(PopupNames.editJournalPopup);
  const { data, loading } = useLoadEditingJournal(journalId || '0');
  const [
    updateJournal,
    { success, errors: serverErrors },
  ] = useUpdateJournalMutation();
  const modules = useModulesData();

  const {
    applicationOptionsState,
    loading: loadingApplications,
  } = useApplicationsOptionsForGroup(journalId as string);

  const onSubmit = useCallback(
    (values: IEditJournalPopupValues) => {
      const daysOfClass = values.lessonsDays.map(
        ({ weekDay, startTime, endTime }) => ({
          weekDay,
          startTime: startTime?.value || '19:00',
          endTime: endTime?.value || '22:00',
        }),
      );

      const modulesToUpdate = R.values(modules).map(
        ({ id, lessons, disabledLessons }) => {
          const lessonsToUpdate = [...disabledLessons, ...lessons].map(
            ({ lessonId, teacher, lessonName, isNewLesson, additional }) => {
              const lesson_id = Number(isNewLesson ? '0' : lessonId);

              if (teacher?.value) {
                return {
                  lesson_id,
                  name: lessonName,
                  application_id: teacher.value,
                  additional,
                };
              }

              return { lesson_id, name: lessonName, additional };
            },
          );

          return { module_id: Number(id), lessons: lessonsToUpdate };
        },
      );

      updateJournal({
        groupId: Number(journalId),
        comment: values.comment,
        daysOfClass,
        modules: modulesToUpdate,
      });
    },
    [journalId, modules, updateJournal],
  );

  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      lessonsDays: data?.lessonsDays || [],
      comment: data?.comment || '',
    },
    validateOnChange: false,
    validationSchema: getJournalEditSchema(t),
    onSubmit,
    enableReinitialize: true,
  });

  const handleChange = useCallback(
    (
      value:
        | string
        | boolean
        | number
        | undefined
        | ValueType<OptionTypeBase, false>,
      name: string,
    ) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (success) {
      addToast({
        data: {
          title: `${t('journalPopup.journalUpdateSuccess')}`,
          type: ToastTypes.success,
        },
        type: GlobalToastTypeNames.JournalEdit,
      });
      onClose();
    }
  }, [success, onClose, addToast, t]);

  const [
    sortErrors,
    { errors: sortedErrors, message },
  ] = useSortErrors<IEditJournalPopupValues>();

  useEffect(() => {
    if (serverErrors) {
      sortErrors(serverErrors);
    }
  }, [serverErrors, sortErrors]);

  useEffect(() => {
    sortErrors(errors as ErrorsType<IEditJournalPopupValues>);
  }, [errors, sortErrors]);

  if (loading || loadingApplications) {
    return <div>loading...</div>;
  }

  return (
    <EditJournalPopupComponent
      values={values}
      errors={sortedErrors}
      errorsMessage={message}
      modules={Object.keys(modules)}
      applicationOptions={applicationOptionsState}
      onClose={onClose}
      handleSubmit={handleSubmit}
      onChangeInput={handleChange}
    />
  );
});
