import { gql } from '@apollo/client';

export const USER_FIELDS = gql`
  fragment UserFields on UserNode {
    id
    username
    isActive
    firstName
    middleName
    lastName
    email
    roles
    verified
    isStaff
    dateJoined
    pk
    archived
    secondaryEmail
    lastLogin
    phone
  }
`;
