import { useRouteMatch } from 'react-router-dom';
import { MenuItems } from 'types';

export const useActiveMenu = (): MenuItems | undefined => {
  const match = useRouteMatch();
  const urlWithoutSlash = match.path.split('/:')[0].replace(`/`, '');

  const activeMenu = Object.values(MenuItems).find(
    (item) => item === urlWithoutSlash,
  );

  return activeMenu;
};
