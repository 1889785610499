import * as React from 'react';
import { memo, useMemo, useState, useCallback, useEffect } from 'react';

import { Route, Switch, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { MainTemplate } from 'templates';
import { UserRole } from 'types';
import {
  DebtorsPageDeferredWrapper,
  DebtorsTablePageWrapper,
  Search,
  Tabs,
} from 'molecules';
import { Counter, Heading } from 'atoms';

import { PopupNames } from 'core';
import {
  useGetContributionsMeta,
  useGetDebtorsMeta,
  usePopupStateOperations,
  useUser,
} from 'core/hooks';
import { PATHS } from 'router/config';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const getFirstDebtorsTab = (t: TFunction) => ({
  to: 'contributions/all',
  label: `${t('pages.debtors.allDebtors')}`,
});

const getDeferredDebtorsTab = (t: TFunction) => ({
  to: 'deferred',
  label: `${t('pages.debtors.deferred')}`,
});

export const Debtors = memo(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    operations: { showPopup },
  } = usePopupStateOperations();

  const [getMeta, { totalCount }] = useGetDebtorsMeta();
  const contributionsMeta = useGetContributionsMeta();
  const [{ user }] = useUser();

  const userPermission = useMemo(() => {
    if (user) {
      return (
        (user.roles.includes(UserRole.ACCOUNTANT) ||
          user.roles.includes(UserRole.SALESMANAGER)) &&
        !user.roles.includes(UserRole.ADMIN) &&
        !user.roles.includes(UserRole.FINANCIER)
      );
    }
  }, [user]);

  const [searchQuery, setSearchQuery] = useState('');
  const [contributionNum, setContributionNum] = useState<number | undefined>(
    undefined,
  );

  const paymentTabs = useMemo(
    () =>
      contributionsMeta.map((num) => ({
        to: `contributions/${num}`,
        label: `${t('pages.debtors.payment')} ${num}`,
      })),
    [contributionsMeta, t],
  );

  const tabs = useMemo(() => [getFirstDebtorsTab(t), ...paymentTabs], [
    paymentTabs,
    t,
  ]);

  const onChangeHandler = useCallback((value: string) => {
    setSearchQuery(value);
  }, []);

  const onClearSearchHandler = useCallback(() => {
    setSearchQuery('');
    getMeta();
  }, [setSearchQuery, getMeta]);

  const onMainTabsChange = useCallback(
    (tabIndex: number | undefined) => {
      if (tabIndex && tabIndex > 0) {
        setContributionNum(contributionsMeta[tabIndex - 1]);
        return;
      }

      setContributionNum(undefined);
    },
    [contributionsMeta, setContributionNum],
  );

  const getSearchPlaceholder = useCallback(() => {
    switch (true) {
      case pathname === PATHS.deferred:
        return `${t('pages.debtors.searchDeferred')}`;
      default:
        return `${t('pages.debtors.searchPayment')}`;
    }
  }, [pathname, t]);

  useEffect(() => {
    getMeta({ query: searchQuery, contributionNum });
  }, [searchQuery, contributionNum, getMeta]);

  return (
    <MainTemplate>
      <DebtorsWrapper>
        <HeadingWrapper>
          <Heading>
            {pathname.includes(PATHS.contributions)
              ? t('pages.debtors.debtors')
              : t('pages.debtors.deferredPayments')}
          </Heading>
          {pathname.includes(PATHS.contributions) && (
            <Count>{String(totalCount)}</Count>
          )}
        </HeadingWrapper>
        <SearchWrapper>
          {userPermission ? (
            <Search
              value={searchQuery}
              onChange={onChangeHandler}
              clear={onClearSearchHandler}
              placeholder={getSearchPlaceholder()}
            />
          ) : (
            <Search
              value={searchQuery}
              onChange={onChangeHandler}
              clear={onClearSearchHandler}
              placeholder={getSearchPlaceholder()}
              buttonProps={{
                label: `${t('pages.debtors.addPayment')}`,
                onClick: () => {
                  showPopup(PopupNames.addPayment);
                },
              }}
            />
          )}
        </SearchWrapper>
        <TabsWrapper>
          <Tabs
            tabs={tabs}
            tabsAlt={[getDeferredDebtorsTab(t)]}
            tabBase={PATHS.debtors}
            onMainTabsChange={onMainTabsChange}
          >
            <Switch>
              <Route path={PATHS.contributions}>
                <DebtorsTablePageWrapper
                  searchValue={searchQuery}
                  contributionNum={contributionNum}
                />
              </Route>
              <Route path={PATHS.deferred}>
                <DebtorsPageDeferredWrapper
                  userPermission={userPermission}
                  searchValue={searchQuery}
                />
              </Route>
            </Switch>
          </Tabs>
        </TabsWrapper>
      </DebtorsWrapper>
    </MainTemplate>
  );
});

const DebtorsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
`;

const baseWrapperStyles = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 48px;
`;

const Count = styled((props) => <Counter {...props} />)`
  margin-left: 12px;
`;

const SearchWrapper = styled.div`
  ${baseWrapperStyles};
  margin-top: 16px;
`;

const TabsWrapper = styled.div`
  ${baseWrapperStyles};
  margin-top: 36px;
`;
