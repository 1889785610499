import { useEffect, useMemo, useCallback } from 'react';
import { IGroupModule, IGroupModuleLessons, IEditingGroup } from 'types';

import { useReactiveVar } from '@apollo/client';

import {
  useLoadEditingGroupQuery,
  useGetCourseDataForGroupLazyQuery,
} from 'core/graphql';
import { modulesStateVar } from 'core/state/moduleState';

import { TIME_OPTIONS } from 'components/constants';
import { DateService } from 'services/DateService';
import { ISelectOption } from 'components/atoms';

type EditingGroupHookType = {
  data: IEditingGroup | null;
  loading?: boolean;
  error?: string;
};

type GetCourseByIdType = [
  (course: ISelectOption<string>) => void,
  {
    data: {
      id: string;
      name: string;
      price: number;
      currency: string | null;
      teachers: {
        value: string;
        label: string;
      }[];
    } | null;
    modules: {
      [key: string]: IGroupModule;
    };
  },
];

export const useLoadEditingGroup = (groupId: string): EditingGroupHookType => {
  const { data, loading, error } = useLoadEditingGroupQuery({
    variables: { groupId },
  });

  const parsedModules = useMemo(
    () =>
      data?.group?.modules.reduce(
        (modulesArray: { [key: string]: IGroupModule }, module, index) => {
          const { id: moduleId, name } = module;
          const { lessons, disabledLessons } = module.lessons.edges.reduce(
            (
              lessonsObj: {
                lessons: IGroupModuleLessons[];
                disabledLessons: IGroupModuleLessons[];
              },
              lesson,
            ) => {
              if (!lesson?.node) {
                return lessonsObj;
              }

              if (
                DateService.isAfter(
                  new Date(),
                  DateService.parseDate(lesson.node.date, 'ISO'),
                )
              ) {
                return {
                  disabledLessons: [
                    ...lessonsObj.disabledLessons,
                    {
                      lessonId: lesson.node?.id,
                      lessonName: lesson.node.name,
                      lessonNumber: lesson.node.lessonNumber,
                      additional: lesson.node.additional,
                      lmsDescription: lesson.node.lmsDescription || '',
                      teacher: lesson.node.teacherApplication?.id
                        ? {
                            value: lesson.node.teacherApplication?.id,
                            label:
                              lesson.node.teacherApplication?.teacher
                                .fullName || '',
                          }
                        : null,
                      checked: false,
                    },
                  ],
                  lessons: lessonsObj.lessons,
                };
              }

              return {
                disabledLessons: lessonsObj.disabledLessons,
                lessons: [
                  ...lessonsObj.lessons,
                  {
                    lessonId: lesson.node?.id,
                    lessonName: lesson.node.name,
                    lessonNumber: lesson.node.lessonNumber,
                    additional: lesson.node.additional,
                    lmsDescription: lesson.node.lmsDescription || '',
                    teacher: lesson.node.teacherApplication?.id
                      ? {
                          value: lesson.node.teacherApplication.id,
                          label:
                            lesson.node.teacherApplication.teacher.fullName ||
                            '',
                        }
                      : null,
                    checked: false,
                  },
                ],
              };
            },
            { lessons: [], disabledLessons: [] },
          );

          return {
            ...modulesArray,
            [index]: {
              id: moduleId,
              name,
              lessons,
              disabledLessons,
              showInput: false,
            },
          };
        },
        {},
      ) || {},
    [data?.group?.modules],
  );

  const parseData = useMemo(() => {
    if (!data?.group) {
      return null;
    }

    const {
      id,
      name,
      price,
      currency,
      classesDays,
      deleteAvailable,
      startDate,
      course,
    } = data.group;

    const lessonsDays = classesDays.map(
      ({ id: lessonDayId, weekDay, startTime, endTime }) => ({
        id: lessonDayId,
        weekDay,
        startTime:
          TIME_OPTIONS.find((option) => option.value === startTime) || null,
        endTime:
          TIME_OPTIONS.find((option) => option.value === endTime) || null,
      }),
    );

    const courseOption = { value: course.id, label: course.name };

    const currencyOption = currency
      ? { value: currency, label: currency }
      : null;

    return {
      id,
      name,
      price: String(price),
      currency: currencyOption,
      lessonsDays,
      deleteAvailable,
      course: courseOption,
      startDate: DateService.parseDate(startDate, 'ISO'),
    };
  }, [data]);

  useEffect(() => {
    modulesStateVar({ modules: parsedModules });
  }, [parsedModules]);

  return {
    data: parseData,
    loading,
    error: error?.message,
  };
};

export const useGetCourseForGroup = (): GetCourseByIdType => {
  const [
    getCourseDataForGroupQuery,
    { data },
  ] = useGetCourseDataForGroupLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { modules } = useReactiveVar(modulesStateVar);

  const modulesFromData = useMemo(
    () =>
      data?.course
        ? data.course.modules.reduce(
            (moduleAcc: { [key: string]: IGroupModule }, module, index) => {
              if (module) {
                return {
                  ...moduleAcc,
                  [index]: {
                    id: module.id,
                    name: module.name,
                    showInput: false,
                    lessons: module.lessons.edges.reduce(
                      (lessonsAcc: IGroupModuleLessons[], lesson) => {
                        if (lesson?.node?.id && lesson?.node?.name) {
                          const studentAttachments = lesson.node.studentAttachments.edges.reduce(
                            (items: number[], item) => {
                              if (item?.node?.id) {
                                return [...items, Number(item.node.id)];
                              }

                              return items;
                            },
                            [],
                          );

                          const teacherAttachments = lesson.node.teacherAttachments.edges.reduce(
                            (items: number[], item) => {
                              if (item?.node?.id) {
                                return [...items, Number(item.node.id)];
                              }

                              return items;
                            },
                            [],
                          );

                          return [
                            ...lessonsAcc,
                            {
                              lessonId: lesson.node.id,
                              checked: false,
                              lessonName: lesson.node.name,
                              lessonNumber: lesson.node.index,
                              additional: false,
                              lmsDescription: lesson.node.lmsDescription,
                              teacher: null,
                              studentAttachments,
                              teacherAttachments,
                              taskTypes: lesson.node.taskTypes,
                            },
                          ];
                        }
                        return lessonsAcc;
                      },
                      [],
                    ),
                  },
                };
              }
              return moduleAcc;
            },
            {},
          )
        : {},
    [data],
  );

  const getCourseDataForGroup = useCallback(
    (course: ISelectOption<string>) => {
      const id = course.value;
      getCourseDataForGroupQuery({ variables: { id } });
    },
    [getCourseDataForGroupQuery],
  );

  const transformDataToGroup = useCallback(() => {
    if (data && data.course) {
      return {
        id: data.course.id,
        name: data.course.name,
        price: data.course.price,
        currency: data.course.currency ? data.course.currency : null,
        teachers: data.course.teachers
          ? data.course.teachers.edges.reduce(
              (teachersOptions: ISelectOption<string>[], teacher) => {
                if (teacher?.node?.id && teacher.node?.fullName) {
                  return [
                    ...teachersOptions,
                    { value: teacher.node.id, label: teacher.node.fullName },
                  ];
                }
                return teachersOptions;
              },
              [],
            )
          : [],
      };
    }
    return null;
  }, [data]);

  useEffect(() => {
    if (modulesFromData) {
      modulesStateVar({
        ...modulesStateVar(),
        modules: modulesFromData,
      });
    }
  }, [modulesFromData]);

  return [getCourseDataForGroup, { data: transformDataToGroup(), modules }];
};
