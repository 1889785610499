import * as React from 'react';
import { memo, ReactNode } from 'react';
import styled from 'styled-components';

import { Button, Heading } from 'atoms';

import { getFontFamily } from 'services/FontService';
import { Colors } from 'services/ColorService';
import { useTranslation } from 'react-i18next';

export interface IConfirmPopup {
  title: string;
  confirmText?: string;
  cancelText?: string;
  children: ReactNode;
  loading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmPopupComponent = memo(
  ({
    title,
    confirmText,
    cancelText,
    children,
    loading,
    onConfirm,
    onCancel,
  }: IConfirmPopup) => {
    const { t } = useTranslation();
    return (
      <Wrapper>
        <Heading>{title}</Heading>
        <Text>{children}</Text>
        <Buttons>
          <ButtonCancel
            onClick={onCancel}
            variant="outline"
            colors={{
              backgroundColor: Colors.White,
              color: Colors.Blue,
            }}
          >
            {cancelText || t('button.cancel')}
          </ButtonCancel>
          <ButtonConfirm
            onClick={onConfirm}
            variant="primary"
            colors={{
              backgroundColor: Colors.Blue,
              color: Colors.White,
            }}
            loading={loading}
          >
            {confirmText || t('button.yes')}
          </ButtonConfirm>
        </Buttons>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 864px;
  padding: 48px 64px 72px;
  background-color: ${Colors.White};
`;

const Text = styled.p`
  margin-top: 24px;
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  line-height: 24px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 32px;
`;

const ButtonCancel = styled((props) => <Button {...props} />)`
  width: 122px;
`;
const ButtonConfirm = styled((props) => <Button {...props} />)`
  width: 164px;
  margin-left: 16px;
`;
