import * as React from 'react';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import { ICountry, ErrorsType, TeacherHireType, TeacherNPType } from 'types';

import { InputLabel, ISelectOption, Select, TextArea, Input } from 'atoms';
import { useTranslation } from 'react-i18next';

export interface INpFieldsValues extends Partial<TeacherNPType> {}

export interface INpFields {
  values?: INpFieldsValues;
  disableFields?: boolean;
  countries: ISelectOption<string>[];
  index: number;
  errors?: ErrorsType<INpFieldsValues> | null;
  onInputChange: (
    name: string,
    value?: string | boolean | ISelectOption<string> | null | ICountry,
  ) => void;
}

export const NpFields = memo(
  ({
    values,
    disableFields,
    countries,
    index,
    errors,
    onInputChange,
  }: INpFields) => {
    const { t } = useTranslation();
    const name = `teacherTypes[${index}].${TeacherHireType.Np}`;

    const nationalityValue = useMemo(
      () =>
        values?.nationality
          ? {
              value: values.nationality.id,
              label: values.nationality.title,
            }
          : null,
      [values?.nationality],
    );

    return (
      <>
        <div>
          <InputLabel>
            {t('teacherTypePopup.npFields.passportNumber.label')}
          </InputLabel>
          <Input
            placeholder={t(
              'teacherTypePopup.npFields.passportNumber.placeholder',
            )}
            max={100}
            type="text"
            value={values?.passportNumber || ''}
            name={`${name}.passportNumber`}
            onChange={({ target: { value } }) =>
              onInputChange(`${name}.passportNumber`, value)
            }
            hasError={Boolean(errors?.passportNumber)}
            disabled={disableFields}
          />
        </div>
        <div>
          <InputLabel>
            {t('teacherTypePopup.npFields.nationality.label')}
          </InputLabel>
          <Select
            value={nationalityValue}
            type="default"
            placeholder={t('teacherTypePopup.npFields.nationality.placeholder')}
            options={countries}
            onChange={(selectValue) =>
              onInputChange(`${name}.nationality`, {
                id: selectValue?.value,
                title: selectValue?.label,
              })
            }
            hasError={Boolean(errors?.nationality)}
            isDisabled={disableFields}
          />
        </div>
        <div>
          <InputLabel>
            {t('teacherTypePopup.npFields.accountNumber.label')}
          </InputLabel>
          <Input
            placeholder={t(
              'teacherTypePopup.npFields.accountNumber.placeholder',
            )}
            max={100}
            type="text"
            value={values?.accountNumber || ''}
            name={`${name}.accountNumber`}
            onChange={({ target: { value } }) =>
              onInputChange(`${name}.accountNumber`, value)
            }
            hasError={Boolean(errors?.accountNumber)}
            disabled={disableFields}
          />
        </div>
        <div>
          <InputLabel>{t('teacherTypePopup.npFields.bic.label')}</InputLabel>
          <Input
            placeholder={t('teacherTypePopup.npFields.bic.placeholder')}
            max={100}
            type="text"
            value={values?.bic || ''}
            name={`${name}.bic`}
            onChange={({ target: { value } }) =>
              onInputChange(`${name}.bic`, value)
            }
            hasError={Boolean(errors?.bic)}
            disabled={disableFields}
          />
        </div>
        <div>
          <InputLabel>
            {t('teacherTypePopup.npFields.bankTitle.label')}
          </InputLabel>
          <Input
            placeholder={t('teacherTypePopup.npFields.bankTitle.placeholder')}
            max={100}
            type="text"
            value={values?.bankTitle || ''}
            name={`${name}.bankTitle`}
            onChange={({ target: { value } }) =>
              onInputChange(`${name}.bankTitle`, value)
            }
            hasError={Boolean(errors?.bankTitle)}
            disabled={disableFields}
          />
        </div>
        <TextAreaWrapper>
          <InputLabel>
            {t('teacherTypePopup.npFields.registrationAddress.label')}
          </InputLabel>
          <TextArea
            placeholder={t(
              'teacherTypePopup.npFields.registrationAddress.placeholder',
            )}
            rows={1}
            value={values?.registrationAddress || ''}
            name={`${name}.registrationAddress`}
            onChange={({ target: { value } }) =>
              onInputChange(`${name}.registrationAddress`, value)
            }
            hasError={Boolean(errors?.registrationAddress)}
            disabled={disableFields}
          />
        </TextAreaWrapper>
      </>
    );
  },
);

const TextAreaWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;
