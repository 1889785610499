import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { Login, ForgotPassword } from 'pages';
import { PATHS } from './config';

export const AuthRouter = () => (
  <Switch>
    <Route exact path={PATHS.login}>
      <Login />
    </Route>
    <Route exact path={PATHS.forgot_password}>
      <ForgotPassword />
    </Route>
  </Switch>
);
