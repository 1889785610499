import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { popupState, PopupNames } from 'core';
import {
  UserPopup,
  UserRolesPopup,
  UserEditPopup,
  UserDeletePopup,
  AddStudentPopup,
  DeleteGroupPopup,
  PaymentAddPopup,
  PaymentCheckPopup,
  StudentPaymentPopup,
  StudentExistPopup,
  AppendStudentPopup,
  EditAgreementPopup,
  AddGroupInLMSPopup,
  RemoveGroupFromLMSPopup,
  AddGroupPopup,
  CommentPopup,
  EditJournalPopup,
  EditGroupPopup,
  StudentWarningPopup,
  ConfirmPopup,
  TeacherAddPopup,
  TeacherAddTypePopup,
  AddApplicationPopup,
  AppendApplicationPopup,
  EditApplicationPopup,
  ApplicationDeletePopup,
  TeacherEditPopup,
} from 'molecules';

import { IPopupState } from 'core/state';
import { getKeys } from 'services/TransformService';
import { getPopupStateOperations } from './getPopupStateOperations';

export interface IPopupStateOperations {
  showPopup: <T>(popup: keyof IPopupState, data?: T | null) => void;
  hidePopup: (popup: keyof IPopupState) => void;
}

type PopupHookType = [FC | null, () => void];

const { showPopup, hidePopup } = getPopupStateOperations(popupState);

export const usePopupStateOperations = () => ({
  operations: { showPopup, hidePopup },
});

export const useIsPopupOpen = (popup: PopupNames): boolean =>
  useReactiveVar(popupState)[popup];

export const usePopup = (state = popupState): PopupHookType => {
  const popups = useReactiveVar(state);

  const getPopup = (key?: keyof IPopupState): [FC | null, () => void] => {
    switch (key) {
      case PopupNames.addUser:
        return [UserPopup, () => hidePopup(PopupNames.addUser)];
      case PopupNames.editUser:
        return [UserEditPopup, () => hidePopup(PopupNames.editUser)];
      case PopupNames.deleteUser:
        return [UserDeletePopup, () => hidePopup(PopupNames.deleteUser)];
      case PopupNames.addGroup:
        return [AddGroupPopup, () => hidePopup(PopupNames.addGroup)];
      case PopupNames.deleteGroup:
        return [DeleteGroupPopup, () => hidePopup(PopupNames.deleteGroup)];
      case PopupNames.addRolesUser:
        return [UserRolesPopup, () => hidePopup(PopupNames.addRolesUser)];
      case PopupNames.appendStudent:
        return [AppendStudentPopup, () => hidePopup(PopupNames.appendStudent)];
      case PopupNames.addStudent:
        return [AddStudentPopup, () => hidePopup(PopupNames.addStudent)];
      case PopupNames.addPayment:
        return [PaymentAddPopup, () => hidePopup(PopupNames.addPayment)];
      case PopupNames.addGroupInLMS:
        return [AddGroupInLMSPopup, () => hidePopup(PopupNames.addGroupInLMS)];
      case PopupNames.removeGroupFromLMS:
        return [
          RemoveGroupFromLMSPopup,
          () => hidePopup(PopupNames.removeGroupFromLMS),
        ];
      case PopupNames.checkPayment:
        return [PaymentCheckPopup, () => hidePopup(PopupNames.checkPayment)];
      case PopupNames.studentExist:
        return [StudentExistPopup, () => hidePopup(PopupNames.studentExist)];
      case PopupNames.editAgreement:
        return [EditAgreementPopup, () => hidePopup(PopupNames.editAgreement)];
      case PopupNames.studentPayment:
        return [
          StudentPaymentPopup,
          () => hidePopup(PopupNames.studentPayment),
        ];
      case PopupNames.commentPopup:
        return [CommentPopup, () => hidePopup(PopupNames.commentPopup)];
      case PopupNames.editJournalPopup:
        return [EditJournalPopup, () => hidePopup(PopupNames.editJournalPopup)];
      case PopupNames.editGroupPopup:
        return [EditGroupPopup, () => hidePopup(PopupNames.editGroupPopup)];
      case PopupNames.StudentWarningPopup:
        return [
          StudentWarningPopup,
          () => hidePopup(PopupNames.StudentWarningPopup),
        ];
      case PopupNames.confirmPopup:
        return [ConfirmPopup, () => hidePopup(PopupNames.confirmPopup)];
      case PopupNames.teacherAddPopup:
        return [TeacherAddPopup, () => hidePopup(PopupNames.teacherAddPopup)];
      case PopupNames.teacherAddTypePopup:
        return [
          TeacherAddTypePopup,
          () => hidePopup(PopupNames.teacherAddTypePopup),
        ];
      case PopupNames.addApplicationPopup:
        return [
          AddApplicationPopup,
          () => hidePopup(PopupNames.addApplicationPopup),
        ];
      case PopupNames.appendApplicationPopup:
        return [
          AppendApplicationPopup,
          () => hidePopup(PopupNames.appendApplicationPopup),
        ];
      case PopupNames.editApplicationPopup:
        return [
          EditApplicationPopup,
          () => hidePopup(PopupNames.editApplicationPopup),
        ];
      case PopupNames.applicationDeletePopup:
        return [
          ApplicationDeletePopup,
          () => hidePopup(PopupNames.applicationDeletePopup),
        ];
      case PopupNames.teacherEditPopup:
        return [TeacherEditPopup, () => hidePopup(PopupNames.teacherEditPopup)];
      default:
        return [null, () => undefined];
    }
  };

  const activePopupKey = getKeys(popups)
    .filter((key) => popups[key])
    .pop();

  const popup = getPopup(activePopupKey);

  return popup;
};
