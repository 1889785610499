import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ServerCodes,
  ToastTypes,
  ErrorsType,
  GlobalToastTypeNames,
} from 'types';

import {
  useChangeGroupMutation,
  CustomErrorType,
  ChangeGroupMutationVariables,
} from 'core/graphql';
import { useToasts } from 'core/hooks';
import { IEditGroupPopupValues } from 'molecules';
import { GroupsQuery, EditingGroupQuery } from 'core/operations/queries';

import { groupErrorReducer, getGroupErrors } from './reducer';

type UpdateGroupType = [
  (variables: ChangeGroupMutationVariables) => void,
  {
    success: boolean;
    loading: boolean;
    errors: ErrorsType<IEditGroupPopupValues> | null;
  },
];

export const useUpdateGroupMutation = (): UpdateGroupType => {
  const { t } = useTranslation();
  const [changeGroupMutation, { data, loading }] = useChangeGroupMutation({
    onError: (err) => err,
  });

  const [errors, dispatch] = useReducer(groupErrorReducer, null);
  const { addToast } = useToasts();

  useEffect(() => {
    const serverErrors = data?.updateGroup?.customErrors;
    if (serverErrors && serverErrors.length) {
      const errorsFound = getGroupErrors(serverErrors as CustomErrorType[]);

      switch (true) {
        case errorsFound.groupExist:
          dispatch({ type: ServerCodes.groupExist });
          break;
        case errorsFound.lessonExist:
          dispatch({ type: ServerCodes.lessonExist });
          break;

        default:
          addToast({
            data: {
              title: `${t('common.somethingWentWrong')}`,
              type: ToastTypes.error,
            },
            type: GlobalToastTypeNames.GroupEdit,
          });
      }
    }
  }, [data?.updateGroup?.customErrors, addToast, t]);

  const updateGroup = useCallback(
    (variables: ChangeGroupMutationVariables) =>
      changeGroupMutation({
        variables,
        refetchQueries: [
          {
            query: GroupsQuery,
            variables: {
              first: 10,
            },
          },
          {
            query: EditingGroupQuery,
            variables: {
              groupId: variables.groupId.toString(),
            },
          },
        ],
      }),

    [changeGroupMutation],
  );

  return [
    updateGroup,
    {
      success: Boolean(data?.updateGroup?.success),
      loading,
      errors,
    },
  ];
};
