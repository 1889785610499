import { MockedResponse } from '@apollo/client/testing';

import {
  DeferredPaymentCurrency,
  PaymentsDeferredQuery as PaymentsDeferredQueryType,
} from 'core';
import { PaymentsDeferredQuery } from 'core/operations';

export const mockRequestResponse: MockedResponse<PaymentsDeferredQueryType> = {
  request: {
    query: PaymentsDeferredQuery,
    variables: {
      first: 10,
      query: undefined,
    },
  },
  result: {
    data: {
      allDeferredPayments: {
        pageInfo: {
          startCursor: '',
          endCursor: '',
          hasNextPage: false,
          hasPreviousPage: false,
        },
        edges: [
          {
            node: {
              id: '164',
              date: '2022-02-23',
              studentFullname: null,
              agreementTitle: 'example555',
              currency: DeferredPaymentCurrency.Byn,
              sum: 300,
              userInput: 'username/example555/300/BYN',
              agreementCurrency: '',
            },
          },
        ],
      },
    },
  },
};
