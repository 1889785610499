/* eslint-disable default-case */
/* eslint-disable no-restricted-syntax */
import { ApolloClient, from } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink, fromPromise, NextLink, Operation } from 'apollo-link';
import { onError } from 'apollo-link-error';

import { nanoid } from 'nanoid';

import { authStateVar, appState } from 'core/state';
import { cache } from 'core/cache';
import { RefreshMutation } from 'core/operations';
import { authService } from 'services/AuthorizationService';
import { GlobalToastTypeNames, NetworkErrors, ToastTypes } from 'types';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPH_URI,
});

const middlewareLink = new ApolloLink((operation, forward) => {
  const { access_token } = authStateVar();
  const oldHeaders = operation.getContext().headers;
  if (access_token !== '') {
    operation.setContext({
      headers: {
        ...oldHeaders,
        Authorization: `JWT ${access_token}`,
      },
    });
  }
  return forward(operation);
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward, response }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { message } = err;

        const firtstPath = err.path && err.path[0];

        const accessTokenExpired = err.message === 'Error decoding signature';
        if (accessTokenExpired) {
          return refreshTokens(operation, forward);
        }

        const refreshTokenExpired =
          firtstPath === 'refreshToken' && message.includes('token is expired');

        if (refreshTokenExpired) {
          logout();
        }
      }
    }
    if (networkError) {
      if ('result' in networkError) {
        const { statusCode } = networkError;
        const currentState = appState();

        const serverNotAvaliable = statusCode === 500;
        if (serverNotAvaliable) {
          appState({
            ...currentState,
            networkError: NetworkErrors.internalServerError,
          });

          return;
        }

        const serviceUnavailable = statusCode === 503;
        if (serviceUnavailable) {
          appState({
            ...currentState,
            networkError: NetworkErrors.serviceUnavailable,
          });

          return;
        }

        const accessTokenExpired = statusCode === 403;
        // TODO: add checking for token expired, statusCode is not enough probably

        if (accessTokenExpired) {
          return refreshTokens(operation, forward);
        }

        appState({
          ...currentState,
          toasts: [
            ...(currentState.toasts || []),
            {
              id: nanoid(6),
              data: {
                title: 'Network error',
                text: `${networkError.statusCode}: ${networkError.message}`,
                type: ToastTypes.error,
              },
              type: GlobalToastTypeNames.NetworkError,
            },
          ],
        });
      }

      if (!response || !response.data) {
        const currentState = appState();
        appState({
          ...currentState,
          networkError: NetworkErrors.internalServerError,
        });
      }
    }
  },
);

export const client = new ApolloClient({
  link: from([errorLink, middlewareLink, httpLink as any]),
  cache,
  connectToDevTools: true,
});

const getNewToken = () =>
  client
    .mutate({
      mutation: RefreshMutation,
      variables: {
        refresh_token: authService.getRefreshToken(),
        scope: process.env.REACT_APP_SCOPE || 'ERP',
      },
    })
    .then((response) => {
      const {
        token: access_token,
        refreshToken: refresh_token,
      } = response.data.refreshToken;
      authStateVar({ ...authStateVar(), access_token });
      authService.saveTokens({ access_token, refresh_token });
      return { access_token, refresh_token };
    });

const refreshTokens = (operation: Operation, forward: NextLink) => {
  authStateVar({ ...authStateVar(), access_token: '' });
  return fromPromise(
    getNewToken()
      .then(({ access_token }) => {
        if (access_token) {
          return access_token;
        }

        return logout();
      })
      .catch(() => {
        logout();
      }),
  )
    .filter((value) => Boolean(value))
    .flatMap((access_token) => {
      const oldHeaders = operation.getContext().headers;
      operation.setContext({
        headers: {
          ...oldHeaders,
          Authorization: `JWT ${access_token}`,
        },
      });

      return forward(operation);
    });
};

const logout = () => {
  authStateVar({ ...authStateVar(), access_token: '', isLoggedIn: false });
  authService.logOut();
  appState({ ...appState(), bootstrapped: false });
};
