import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useToasts } from 'core/hooks';
import { GlobalToastTypeNames, ToastTypes } from 'components/types';

import './App.css';
import { RootRouter } from './router';

function App() {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const onConnectionChange = useCallback(() => {
    const isOnline = navigator.onLine;

    if (isOnline) {
      addToast({
        data: {
          title: `${t('networkConnection')}`,
          type: ToastTypes.info,
        },
        type: GlobalToastTypeNames.NetworkConnection,
      });
      return;
    }

    addToast({
      data: {
        title: `${t('networkConnectionBad')}`,
        type: ToastTypes.info,
      },
      type: GlobalToastTypeNames.NetworkConnection,
    });
  }, [addToast, t]);

  useEffect(() => {
    window.addEventListener('offline', onConnectionChange);
    window.addEventListener('online', onConnectionChange);

    return () => {
      window.removeEventListener('offline', onConnectionChange);
      window.removeEventListener('online', onConnectionChange);
    };
  }, [onConnectionChange]);

  return (
    <div style={{ height: '100%' }}>
      <RootRouter />
    </div>
  );
}

export default App;
