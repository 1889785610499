import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

export interface IForm extends IFormStyle {
  children: React.ReactNode[] | React.ReactNode;
  onSubmit: () => void;
  autoComplete?: boolean;
}

export interface IFormStyle {
  className?: string;
}

export const Form = memo(
  ({ onSubmit, children, autoComplete, className }: IForm) => (
    <FormStyled
      onSubmit={onSubmit}
      autoComplete={autoComplete ? 'on' : 'off'}
      className={className}
    >
      {children}
    </FormStyled>
  ),
);

const FormStyled = styled.form<IFormStyle>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 1px;
`;
