import React, { memo, useEffect, useCallback } from 'react';

import styled from 'styled-components';

import { JournalsTable } from 'molecules';

import {
  useJournals,
  useSearch,
  SearchNames,
  usePopupStateOperations,
} from 'core/hooks';
import { PopupNames } from 'core';

export const Journals = memo(() => {
  const {
    data: journals,
    loading,
    error,
    pageInfo,
    operations: { fetchMoreData },
  } = useJournals();
  const { searchQuery } = useSearch(SearchNames.settings);

  const {
    operations: { showPopup },
  } = usePopupStateOperations();

  const showEditJournalPopup = useCallback(
    (journalId) => {
      showPopup(PopupNames.editJournalPopup, journalId);
    },
    [showPopup],
  );

  const loadMore = useCallback(() => {
    const vars = {
      query: searchQuery || null,
      after: pageInfo?.endCursor,
    };

    fetchMoreData(vars);
  }, [fetchMoreData, pageInfo?.endCursor, searchQuery]);

  useEffect(() => {
    const query = searchQuery.trim();

    fetchMoreData({ query });
  }, [fetchMoreData, searchQuery]);

  return (
    <JournalsTableWrapper>
      <JournalsTable
        journals={journals}
        loading={loading}
        error={error}
        hasMore={pageInfo?.hasNextPage || false}
        loadMore={loadMore}
        showEditJournalPopup={showEditJournalPopup}
      />
    </JournalsTableWrapper>
  );
});

const JournalsTableWrapper = styled.div`
  margin-top: 24px;
`;
