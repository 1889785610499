import { useCallback } from 'react';

import {
  useGetCourseDataForApplicationLazyQuery,
  GetCourseDataForApplicationQueryVariables,
  GetCourseDataForApplicationQuery,
} from 'core';
import { IGroupsOptions } from 'types';
import { transformGroupsData } from './transform';

type IGetGroupsForApplicationHookType = [
  (variables: GetCourseDataForApplicationQueryVariables) => void,
  {
    groups: IGroupsOptions[] | undefined;
    loading: boolean;
    error: string | undefined;
  },
];

export const useGetGroupsForApplication = (): IGetGroupsForApplicationHookType => {
  const [
    getGroupsQuery,
    { data, error, loading },
  ] = useGetCourseDataForApplicationLazyQuery({
    fetchPolicy: 'network-only',
  });

  const getGroups = useCallback(
    (variables: GetCourseDataForApplicationQueryVariables) => {
      getGroupsQuery({ variables });
    },
    [getGroupsQuery],
  );

  const groups = transformGroupsData(data as GetCourseDataForApplicationQuery);

  return [
    getGroups,
    {
      groups,
      loading,
      error: error?.message,
    },
  ];
};
