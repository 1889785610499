import { useCallback } from 'react';

import { useReactiveVar } from '@apollo/client';
import { popupData, PopupNames } from 'core';

type UnparsedPaymentsHookType = [
  (unparsed?: string) => void,
  {
    unparsedPayments: string;
  },
];

export const useUnparsedPayments = (): UnparsedPaymentsHookType => {
  const unparsed = useReactiveVar(popupData)[PopupNames.addPayment];

  const setUparsedPayments = useCallback((unparsedData?: string) => {
    if (unparsedData) {
      popupData({ ...popupData(), [PopupNames.addPayment]: unparsedData });
      return;
    }

    popupData({ ...popupData(), [PopupNames.addPayment]: undefined });
  }, []);

  return [
    setUparsedPayments,
    {
      unparsedPayments: unparsed || '',
    },
  ];
};
