import * as React from 'react';
import { useCallback } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { userStateVar } from 'core';
import { UserRole } from 'types';

import {
  Debtors,
  UsersSettings,
  Students,
  PlatformSettings,
  Journal,
  ErrorPage,
  Finances,
  TeachersOption,
  Performance,
} from 'pages';

import { PATHS, PATHS_PARAMS } from './config';

const ROUTES = {
  debtors: (
    <Route path={PATHS.debtors} component={Debtors} key={PATHS.debtors} />
  ),
  debtorsContributions: (
    <Route
      path={`${PATHS.contributions}/${PATHS_PARAMS.contributionNum}`}
      component={Debtors}
      key={`${PATHS.contributions}/${PATHS_PARAMS.contributionNum}`}
    />
  ),
  students: (
    <Route
      path={`${PATHS.students}`}
      exact
      component={Students}
      key={`${PATHS.students}`}
    />
  ),
  users: (
    <Route path={PATHS.users} component={UsersSettings} key={PATHS.users} />
  ),
  settings: (
    <Route
      path={PATHS.settings}
      component={PlatformSettings}
      key={PATHS.settings}
    />
  ),
  journal: (
    <Route
      path={`${PATHS.journal}/:journalId`}
      component={Journal}
      key={PATHS.journal}
    />
  ),
  finances: (
    <Route path={PATHS.finances} component={Finances} key={PATHS.finances} />
  ),
  teachers: (
    <Route
      path={PATHS.teachers}
      component={TeachersOption}
      key={PATHS.teachers}
    />
  ),
  performance: (
    <Route
      path={PATHS.performance}
      component={Performance}
      key={PATHS.performance}
    />
  ),
};

export const AppRouter = () => {
  const getRoutesByRole = useCallback((role: UserRole | undefined) => {
    switch (role) {
      case UserRole.ADMIN:
        return [...Object.values(ROUTES)];
      case UserRole.ACCOUNTANT:
        return [
          ROUTES.students,
          ROUTES.debtors,
          ROUTES.debtorsContributions,
          ROUTES.settings,
          ROUTES.journal,
          ROUTES.teachers,
          ROUTES.performance,
        ];
      case UserRole.SALESMANAGER:
        return [
          ROUTES.students,
          ROUTES.debtors,
          ROUTES.debtorsContributions,
          ROUTES.settings,
          ROUTES.journal,
          ROUTES.teachers,
        ];
      case UserRole.HRMANAGER:
        return [
          ROUTES.settings,
          ROUTES.journal,
          ROUTES.teachers,
          ROUTES.performance,
        ];
      case UserRole.FINANCIER:
        return [
          ROUTES.debtors,
          ROUTES.debtorsContributions,
          ROUTES.students,
          ROUTES.finances,
        ];
      default:
        return [];
    }
  }, []);
  const userRoles = useReactiveVar(userStateVar).me?.roles;
  const availableRoutes = userRoles
    ? userRoles.map((role) => getRoutesByRole(role as UserRole))
    : [];
  const computedRoutes = availableRoutes.reduce(
    (prev, curr) => [...prev, ...curr],
    [],
  );

  const firstScreenIsStudents = useCallback(() => {
    const allKeys = computedRoutes.map((rout) => rout.key);
    if (allKeys) {
      return allKeys.includes(PATHS.students);
    }
  }, [computedRoutes]);

  return (
    <Switch>
      {firstScreenIsStudents() ? (
        <Redirect exact from={PATHS.main} to={PATHS.students} />
      ) : (
        <Redirect exact from={PATHS.main} to={PATHS.settingsCourses} />
      )}
      <Redirect exact from={PATHS.settings} to={PATHS.settingsCourses} />
      <Redirect exact from={PATHS.teachers} to={PATHS.teachersUsers} />
      <Redirect exact from={PATHS.debtors} to={`${PATHS.contributions}/all`} />
      {computedRoutes}
      <Route path="*" render={() => <ErrorPage status={404} />} />
    </Switch>
  );
};
