import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { appState, authStateVar, userStateVar } from 'core/state';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        appState: {
          read() {
            return appState();
          },
        },
        authState: {
          read() {
            return authStateVar();
          },
        },
        userState: {
          read() {
            return userStateVar();
          },
        },
        allContributions: relayStylePagination(),
        allDeferredPayments: relayStylePagination(),
        allStudents: relayStylePagination(),
        metaStudents: relayStylePagination(),
        allUsersSettings: relayStylePagination(),
        allCourses: relayStylePagination(),
        allGroups: relayStylePagination(),
        allTeachers: relayStylePagination(),
        studentsForJournal: relayStylePagination(),
        allTeachersApplications: relayStylePagination(),
        metaTeachers: relayStylePagination(),
        allStudentsAchievement: relayStylePagination(),
      },
    },
  },
});
