import { useReactiveVar } from '@apollo/client';
import { authStateVar } from 'core';

type LoginStateHookType = {
  isLoggedIn: boolean;
};

export const useLoginState = (): LoginStateHookType => {
  const { isLoggedIn } = useReactiveVar(authStateVar);

  return {
    isLoggedIn: isLoggedIn || false,
  };
};
