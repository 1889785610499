import * as React from 'react';
import { memo } from 'react';
import { Row, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Table, TableBody, TableCell, TableHead, TableRow } from 'atoms';

type Accessors =
  | 'username'
  | 'contractNumber'
  | 'sum'
  | 'acceptCurrencies'
  | 'unparsed'
  | 'info';

export type IRow = {
  [key in Accessors]: React.ReactNode;
} & {
  contractId?: string;
  deferredPaymentId?: string;
  date: Date;
};

export interface IColumn {
  accessor: Accessors;
  flexible?: boolean;
  align?: 'left' | 'right' | 'center';
  Header?: string | (() => React.ReactElement);
  columns?: IColumn[];
  columnWidth?: number;
  headerCellPaddingRight?: number;
  headerCellPaddingLeft?: number;
  marginRight?: number;
  marginLeft?: number;
}

export interface IPaymentCheckTableComponent {
  columns: IColumn[];
  rows: IRow[];
  isFlexible: boolean;
  className?: string;
}

export const PaymentCheckTableComponent = memo(
  ({
    columns,
    rows: rowsSource,
    isFlexible,
    className,
  }: IPaymentCheckTableComponent) => {
    const { t } = useTranslation();
    const {
      getTableProps,
      headers,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data: rowsSource,
    });

    return (
      <Table
        {...getTableProps()}
        flexible={isFlexible}
        variant="grey"
        className={className}
      >
        <TableHead>
          <TableRow type="row" borders="none">
            {headers.map((column: any) => {
              const width = column.columnWidth ? `${column.columnWidth}px` : 0;
              const paddingRight = column.headerCellPaddingRight || 0;
              const paddingLeft = column.headerCellPaddingLeft || 0;
              const marginRight = column.marginRight || 0;
              const marginLeft = column.marginLeft || 0;

              const { key: cellKey, ...restCellProps } = column.getHeaderProps({
                type: 'headerCell',
                flexible: column.flexible && isFlexible,
                width: `${width}px`,
                margin: {
                  right: `${marginRight}px`,
                  left: `${marginLeft}px`,
                },
                padding: {
                  right: `${paddingRight}px`,
                  left: `${paddingLeft}px`,
                },
              });

              return (
                <WrapperTableCell key={cellKey} {...restCellProps}>
                  {column.render('Header')}
                </WrapperTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.length < 1 && (
            <TableRow key="noData">
              <WrapperTableCell type="cell" width="100%" align="center">
                {t('table.payments.noData')}
              </WrapperTableCell>
            </TableRow>
          )}
          {rows.length > 0 &&
            rows.map((row: Row<IRow>) => {
              prepareRow(row);
              const { key: rowKey, ...restRowProps } = row.getRowProps();

              return (
                <TableRow
                  key={rowKey}
                  type="row"
                  borders="none"
                  {...restRowProps}
                >
                  {row.cells.map((cell: any) => {
                    const width = cell.column.columnWidth
                      ? `${cell.column.columnWidth}px`
                      : undefined;
                    const {
                      key: cellKey,
                      ...restCellProps
                    } = cell.getCellProps({
                      width,
                      margin: {
                        right: `${cell.column.marginRight}px`,
                        left: `${cell.column.marginLeft}px`,
                      },
                      flexible: cell.column.flexible && isFlexible,
                    });

                    return (
                      <WrapperTableCell key={cellKey} {...restCellProps}>
                        {cell.render('Cell')}
                      </WrapperTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );
  },
);

const WrapperTableCell = styled((props) => <TableCell {...props} />)`
  & > * {
    width: 100%;
  }
`;
