import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { OptionTypeBase } from 'react-select';

import { ErrorsType, IconSizes, TeacherHireType } from 'types';

import {
  InputLabel,
  ISelectOption,
  Select,
  IconButton,
  IconsNames,
} from 'atoms';

import { IeFields, IIeFieldsValues } from './IeFields';
import { ISeFieldsValues, SeFields } from './SeFields';
import { ILeFieldsValues, LeFields } from './LeFields';
import { INpFieldsValues, NpFields } from './NpFields';

export interface ITeacherAddTypeValuesFields {
  [TeacherHireType.Ie]: IIeFieldsValues;
  [TeacherHireType.Se]: ISeFieldsValues;
  [TeacherHireType.Le]: ILeFieldsValues;
  [TeacherHireType.Np]: INpFieldsValues;
  type: ISelectOption<string> | null;
}

export interface ITeacherAddTypeFields {
  values?: ITeacherAddTypeValuesFields;
  index: number;
  disableFields?: boolean;
  errors?: ErrorsType<ITeacherAddTypeValuesFields> | null;
  countries: ISelectOption<string>[];
  teacherTypesOptions: ISelectOption<string>[];
  teacherTypesInputDisabled: boolean;
  onInputChange: (
    name: string,
    value?: string | boolean | ISelectOption<string> | null | OptionTypeBase,
  ) => void;
  onDeleteType: (index: number) => void;
}

export const TeacherAddTypeFields = memo(
  ({
    values,
    disableFields,
    countries,
    teacherTypesOptions,
    teacherTypesInputDisabled,
    errors,
    index,
    onInputChange,
    onDeleteType,
  }: ITeacherAddTypeFields) => {
    const { t } = useTranslation();
    return (
      <>
        <FieldsWrapper>
          <div>
            <InputLabel>{t('teacherTypePopup.fields.type.label')}</InputLabel>
            <Select
              value={values?.type}
              type="default"
              placeholder={t('teacherTypePopup.fields.type.placeholder')}
              options={teacherTypesOptions}
              onChange={(selectValues) =>
                onInputChange(`teacherTypes[${index}].type`, selectValues)
              }
              hasError={Boolean(errors?.type)}
              isDisabled={teacherTypesInputDisabled}
            />
          </div>

          {!teacherTypesInputDisabled && index !== 0 && (
            <WrapperDeleteLessonDays>
              <IconButton
                name={IconsNames.CloseIconSquare}
                size={IconSizes.xl}
                onClick={() => onDeleteType(index)}
              />
            </WrapperDeleteLessonDays>
          )}
        </FieldsWrapper>
        <FieldsTypeWrapper>
          {values?.type?.value === TeacherHireType.Ie && (
            <IeFields
              values={values[TeacherHireType.Ie]}
              errors={errors?.[TeacherHireType.Ie]}
              index={index}
              onInputChange={onInputChange}
              disableFields={disableFields}
            />
          )}
          {values?.type?.value === TeacherHireType.Se && (
            <SeFields
              values={values[TeacherHireType.Se]}
              errors={errors?.[TeacherHireType.Se]}
              index={index}
              countries={countries}
              onInputChange={onInputChange}
              disableFields={disableFields}
            />
          )}
          {values?.type?.value === TeacherHireType.Np && (
            <NpFields
              values={values[TeacherHireType.Np]}
              errors={errors?.[TeacherHireType.Np]}
              index={index}
              countries={countries}
              onInputChange={onInputChange}
              disableFields={disableFields}
            />
          )}
          {values?.type?.value === TeacherHireType.Le && (
            <LeFields
              values={values[TeacherHireType.Le]}
              errors={errors?.[TeacherHireType.Le]}
              index={index}
              onInputChange={onInputChange}
              disableFields={disableFields}
            />
          )}
        </FieldsTypeWrapper>
      </>
    );
  },
);

const FieldsWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 24px;
  width: 100%;
`;

const FieldsTypeWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 24px;
  width: 100%;
`;

const WrapperDeleteLessonDays = styled.div`
  display: flex;
  align-items: flex-end;
`;
