import * as Yup from 'yup';
import { FieldErrorTypes } from 'types';
import { TFunction } from 'i18next';

import { DateService } from './DateService';
import {
  WRONG_SYMBOLS,
  _emailRegExp,
  _namesRegExp,
  _phoneNumbersRegExp,
} from './ValidationService';

export const _alphanumeric = /^[a-z0-9]+$/i;
export const _passwordMatch = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{}|~]{0,}$/;
export const _numbersRegExp = /^-{0,1}\d+(\.{1}\d{1,2}){0,1}$/;

export enum YupFieldErrorTypes {
  EMPTY = 'empty',
  VALIDATION = 'validation',
  ACCOUNT_PAYABLES = 'accountPayables',
  PAYMENTS_WRONG_FORMAT = 'payments_wrong_format',
  SERVER_ERROR = 'serverError',
}

export interface IYupFieldError {
  type: YupFieldErrorTypes;
  message?: string;
  fieldName?: string;
  fieldKey?: string;
}

class YupValidationService {
  public firstName = (fieldName: string, t: TFunction) =>
    Yup.string()
      .required(() => ({
        type: YupFieldErrorTypes.EMPTY,
        fieldName,
      }))
      .matches(_namesRegExp, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: WRONG_SYMBOLS,
      }))
      .max(30, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: `${t('input.validation.lessThan30', { fieldName })}`,
      }));

  public middleName = (fieldName: string, t: TFunction) =>
    Yup.string()
      .matches(_namesRegExp, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: WRONG_SYMBOLS,
      }))
      .max(30, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: `${t('input.validation.lessThan30', { fieldName })}`,
      }));

  public lastName = (fieldName: string, t: TFunction) =>
    Yup.string()
      .required(() => ({
        type: YupFieldErrorTypes.EMPTY,
        fieldName,
      }))
      .matches(_namesRegExp, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: WRONG_SYMBOLS,
      }))
      .max(30, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: `${t('input.validation.lessThan30', { fieldName })}`,
      }));

  public phone = (fieldName: string, t: TFunction) =>
    Yup.string()
      .required(() => ({
        type: YupFieldErrorTypes.EMPTY,
        fieldName,
      }))
      .matches(_phoneNumbersRegExp, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: `${t('input.validation.phone.invalid')}`,
      }))
      .max(17, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: `${t('input.validation.phone.wrongFormat', { fieldName })}`,
      }));

  public email = (fieldName: string, t: TFunction) =>
    Yup.string()
      .required(() => ({
        type: YupFieldErrorTypes.EMPTY,
        fieldName,
      }))
      .matches(_emailRegExp, () => ({
        type: YupFieldErrorTypes.VALIDATION,
        message: `${t('input.validation.email.invalid')}`,
      }));

  public date = (fieldName: string) =>
    Yup.date()
      .nullable()
      .required(() => ({
        type: YupFieldErrorTypes.EMPTY,
        fieldName,
      }));

  public lessonsDays = (t: TFunction) =>
    Yup.array()
      .nullable()
      .of(
        Yup.object()
          .shape({
            weekDay: yupValidation.weekDay(t),
          })
          .test(
            'weekDay',
            {
              weekDay: {
                type: YupFieldErrorTypes.VALIDATION,
                fieldName: `${t('groupPopup.fields.lessonDays.label')}`,
                message: `${t(
                  'groupPopup.validation.lessonDays.differentDays',
                )}`,
              },
            },
            (value, context) => {
              const { weekDay } = value;
              const parentArray = context.parent;
              const duplicateArray = parentArray.filter(
                (parent: { weekDay: number | null | undefined }) =>
                  parent.weekDay === weekDay,
              );

              return !(
                typeof weekDay === 'number' && duplicateArray.length > 1
              );
            },
          ),
      );

  public weekDay = (t: TFunction) =>
    Yup.number()
      .nullable()
      .required(() => ({
        type: YupFieldErrorTypes.EMPTY,
        fieldName: `${t('groupPopup.fields.lessonDays.label')}`,
      }));

  public startTime = (t: TFunction) =>
    Yup.object().test(
      'startTime',
      {
        type: YupFieldErrorTypes.VALIDATION,
        fieldName: `${t('groupPopup.fields.startTime.label')}`,
        message: `${t('groupPopup.validation.startTime.wrongTime')}`,
      },
      (value, context) => {
        const startTimeValue = DateService.parseTime(value.value);
        const endTimeValue = DateService.parseTime(
          context.parent.endTime.value,
        );

        return DateService.isBefore(startTimeValue, endTimeValue);
      },
    );

  public validateNumber = (t: TFunction) => {
    const name = 'validate number';
    const message = () => ({
      type: FieldErrorTypes.VALIDATION,
      message: `${t('input.validation.number.invalid')}`,
    });
    const test = (value: string | undefined | null) => {
      if (!value) {
        return true;
      }

      const isValid = _numbersRegExp.test(value);
      return isValid;
    };

    return { name, message, test };
  };
}

export const yupValidation = new YupValidationService();

export const getStudentDataSchema = (t: TFunction) =>
  Yup.object().shape({
    firstName: yupValidation.firstName(
      `${t('studentPopup.fields.firstName.label')}`,
      t,
    ),
    lastName: yupValidation.lastName(
      `${t('studentPopup.fields.lastName.label')}`,
      t,
    ),
    middleName: yupValidation.middleName(
      `${t('studentPopup.fields.middleName.label')}`,
      t,
    ),
    email: yupValidation.email(`${t('studentPopup.fields.email.label')}`, t),
    phone: yupValidation.phone(`${t('studentPopup.fields.phone.label')}`, t),
  });
