import { useCallback } from 'react';

import {
  ContributionEdge,
  usePaginatedContributionsLazyQuery,
} from 'core/graphql';
import { IDebtor } from 'types';

import { toIDebtor } from '../transform';

interface IDebtorsQueryArgs {
  first?: number;
  query?: string;
  contributionNum?: number;
}

type GetDebtorsHookType = [
  (args?: IDebtorsQueryArgs) => void,
  (args?: IDebtorsQueryArgs) => void,
  {
    data: IDebtor[];
    error: string;
    loading: boolean;
    hasMore: boolean;
  },
];

export const useGetDebtors = (): GetDebtorsHookType => {
  const [
    getContributionsQuery,
    { data, loading, error, fetchMore },
  ] = usePaginatedContributionsLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const result = (): IDebtor[] => {
    const edges = data?.allContributions?.edges;
    if (!edges) {
      return [];
    }

    return edges.map((edge) =>
      toIDebtor(edge as ContributionEdge),
    ) as IDebtor[];
  };

  const getContributions = useCallback(
    (args?: IDebtorsQueryArgs) => {
      getContributionsQuery({
        variables: {
          first: args?.first || 10,
          query: args?.query,
          contributionNum: args?.contributionNum,
        },
      });
    },
    [getContributionsQuery],
  );

  const fetchMoreDebtors = useCallback(
    (args?: IDebtorsQueryArgs) => {
      if (fetchMore) {
        fetchMore({
          variables: {
            query: args?.query,
            contributionNum: args?.contributionNum,
            after: data?.allContributions?.pageInfo.endCursor,
          },
        });
      }
    },
    [fetchMore, data?.allContributions?.pageInfo.endCursor],
  );

  return [
    getContributions,
    fetchMoreDebtors,
    {
      data: result(),
      error: error?.message || '',
      loading,
      hasMore: data?.allContributions?.pageInfo.hasNextPage || false,
    },
  ];
};
