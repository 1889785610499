import React, { memo, useMemo } from 'react';
import { ValueType, OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';

import { SortEnd } from 'react-sortable-hoc';
import styled from 'styled-components';

import { Button, Heading, InputLabel, Text, Select, CheckBox } from 'atoms';
import { PopupErrors } from 'molecules';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

import { ErrorsType, IFieldError, IApplicationOptions } from 'types';
import {
  SortableItem,
  SortableList,
  AddLesson,
  AddLessonInput,
  IModuleLessonBlock,
  IChooseLesson,
  DisabledLesson,
} from './ModuleAdditions';

type InputHandler<T> = (value: T, inputName: string) => void;

export interface IModule extends IChooseLesson {
  onSetTeacher: (values: IModuleValues) => void;
  onChangeInput: InputHandler<
    string | boolean | Date | null | ValueType<OptionTypeBase, false>
  >;

  errors?: ErrorsType<IModuleValues> | null;
  errorsMessage?: string | null;
  values: IModuleValues;
  lessons: IModuleLesson[];
  disabledLessons: IModuleLesson[];
  onShowInput: () => void;
  nameModule: number | string;
  onAddLesson: (values: IModuleValues) => void;
  showInput: boolean;
  onSortEnd: ({ oldIndex, newIndex }: SortEnd) => void;

  allLessons: boolean;
  onToggleAllLessons: () => void;
  applicationOptions: IApplicationOptions[];
  showWithoutTeacherOption: boolean;
  isPopupAdd: boolean;
  onCloseInput: () => void;
}

export interface IModuleValues {
  application: IApplicationOptions | null;
  lessonInputName: string;
  isAdditional: boolean;
}

export interface IModuleLesson extends IModuleLessonBlock {}

export type IModuleErrors = {
  lessonInputName?: IFieldError;
};

export const ModuleComponent = memo(
  ({
    onChangeInput,
    onSetTeacher,
    errors,
    errorsMessage,
    values,
    lessons,
    disabledLessons,
    onShowInput,
    nameModule,
    onAddLesson,
    showInput,
    onSortEnd,
    onChooseLesson,
    allLessons,
    onToggleAllLessons,
    applicationOptions,
    showWithoutTeacherOption,
    isPopupAdd,
    onCloseInput,
  }: IModule) => {
    const { t } = useTranslation();
    const options = useMemo(
      () =>
        showWithoutTeacherOption
          ? [
              {
                value: '',
                label: `${t('groupPopup.modules.fields.withoutTeacher.label')}`,
              },
              ...applicationOptions,
            ]
          : applicationOptions,
      [showWithoutTeacherOption, applicationOptions, t],
    );

    return (
      <>
        <WrapperRow>
          <Heading>
            {t('groupPopup.modules.moduleName')} {nameModule}
          </Heading>
        </WrapperRow>
        {lessons.some(({ checked }) => checked) && (
          <WrapperRow>
            <WrapperInput>
              <InputLabel hasError={Boolean(errors?.application)}>
                {t('groupPopup.modules.fields.application.label')}
              </InputLabel>
              <Select
                type="default"
                placeholder={t(
                  'groupPopup.modules.fields.application.placeholder',
                )}
                options={options}
                defaultValue={null}
                value={values.application}
                onChange={(value) => onChangeInput(value, 'application')}
                hasError={Boolean(errors?.application)}
              />
            </WrapperInput>

            <WrapperSendButton>
              <Button
                onClick={() => onSetTeacher(values)}
                disabled={!values.application}
                type="button"
                colors={{
                  backgroundColor: Colors.Blue,
                  color: Colors.White,
                }}
              >
                {t('groupPopup.modules.button.setTeacher')}
              </Button>
            </WrapperSendButton>
          </WrapperRow>
        )}

        <WrapperHeadingList>
          {!isPopupAdd && (
            <WrapperCheckBox>
              <CheckBox checked={allLessons} onClick={onToggleAllLessons} />
            </WrapperCheckBox>
          )}

          <WrapperColumn width="416px" isPopupAdd={isPopupAdd}>
            <TextBold>{t('groupPopup.modules.lesson')}</TextBold>
          </WrapperColumn>
          {!isPopupAdd && (
            <WrapperColumn width="216px">
              <TextBold>{t('groupPopup.modules.teacher')}</TextBold>
            </WrapperColumn>
          )}
        </WrapperHeadingList>
        <SortableList onSortEnd={onSortEnd} useDragHandle lockAxis="y">
          {disabledLessons.map((lesson) => (
            <DisabledLesson
              key={`item-${lesson.lessonName}`}
              lessonName={lesson.lessonName}
              lessonNumber={lesson.lessonNumber}
              teacher={lesson.teacher}
            />
          ))}
          {lessons.map((lesson, index) => (
            <SortableItem
              isPopupAdd={isPopupAdd}
              lessonId={lesson.lessonId}
              key={`item-${lesson.lessonName}`}
              index={index}
              lessonName={lesson.lessonName}
              lessonNumber={lesson.lessonNumber}
              isNewLesson={lesson.isNewLesson}
              teacher={lesson.teacher}
              checked={lesson.checked}
              onChooseLesson={onChooseLesson}
            />
          ))}
        </SortableList>
        {showInput ? (
          <AddLessonInput
            values={values}
            onAddLesson={onAddLesson}
            errors={errors}
            onChangeInput={onChangeInput}
            onCloseInput={onCloseInput}
          />
        ) : (
          <AddLesson onShowInput={onShowInput} />
        )}

        <PopupErrorsStyled>{errorsMessage}</PopupErrorsStyled>
      </>
    );
  },
);

const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: initial;
`;

const WrapperCheckBox = styled.div`
  margin: 16px 24px;
`;

const WrapperHeadingList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: ${Colors.MainBackground};
  margin: 24px 0 0 0;
`;

const WrapperColumn = styled.div<{ width?: string; isPopupAdd?: boolean }>`
  min-width: ${({ width }) => width || '100%'};
  margin: ${({ isPopupAdd }) => (isPopupAdd ? '16px 24px' : '0')};
  display: flex;
  flex-direction: column;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: initial;
  width: 591px;
`;

const WrapperSendButton = styled.div`
  width: 145px;
  margin: 32px 0 0 0;
`;

const TextBold = styled((props) => <Text {...props} />)`
  font-weight: 800;
  font-family: ${getFontFamily('bold')};
`;

const PopupErrorsStyled = styled((props) => <PopupErrors {...props} />)`
  margin-top: 32px;
`;
