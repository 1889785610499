import { ContributionEdge } from 'core/graphql';
import { IDebtor } from 'types';
import i18n from '../../../i18n';

export const toIDebtor = (
  edge: ContributionEdge | null,
): IDebtor | undefined => {
  if (!edge) return;
  const { node } = edge;
  if (node) {
    const { currency } = node.agreement;
    return {
      id: node.id,
      name: `${node.person?.lastName || ''} ${node.person?.firstName || ''} ${
        node.person?.middleName || ''
      }`,
      contractId: node.agreementTitle!,
      phone: node.person?.phone!,
      group: node.group?.name!,
      paymentDate: node.date,
      expiredDays: `${String(node.delay)!} ${i18n.t('common.day')}`,
      amount: `${node.remainder} ${currency}`,
      payments: [],
    };
  }
};
