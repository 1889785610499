import { useUpdateUserSettingsMutation, CustomErrorType } from 'core/graphql';

import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';
import { popupData, PopupNames } from 'core/state';
import { UsersSettingsQuery } from 'core/operations';

import {
  IUser,
  ServerCodes,
  ToastTypes,
  ErrorsType,
  GlobalToastTypeNames,
} from 'types';

import { IUserPopupValues } from 'molecules';

import { useToasts } from 'core/hooks';

import { userErrorReducer, getUserErrors } from './reducer';
import { useGetUser } from './GetUserData';

type EditUserType = [
  (newUserData: IUser) => void,
  {
    user: IUser | undefined;
    getUserLoading: boolean;
    loading: boolean;
    success: boolean;
    errors: ErrorsType<IUserPopupValues> | null;
  },
];

export const useEditUser = (): EditUserType => {
  const { t } = useTranslation();
  const userId = useReactiveVar(popupData)[PopupNames.editUser];
  const [updateUserMutation, { data, loading }] = useUpdateUserSettingsMutation(
    {
      refetchQueries: [
        {
          query: UsersSettingsQuery,
          variables: {
            first: 10,
          },
        },
      ],
    },
  );
  const [getUserById, { user, getUserLoading }] = useGetUser();
  const [errors, dispatch] = useReducer(userErrorReducer, null);
  const { addToast } = useToasts();

  useEffect(() => {
    if (userId) {
      getUserById(userId);
    }
  }, [userId, getUserById]);

  useEffect(() => {
    const serverErrors = data?.updateUserSettings?.customErrors;
    if (serverErrors && serverErrors.length) {
      const errorsFound = getUserErrors(serverErrors as CustomErrorType[]);

      switch (true) {
        case errorsFound.uniqueSum > 1:
          dispatch({ type: ServerCodes.userExist });
          break;
        case errorsFound.invalidPhone:
          dispatch({ type: ServerCodes.phoneInvalid });
          break;
        case errorsFound.alreadyExist:
          dispatch({ type: ServerCodes.alreadyExist });
          break;
        case errorsFound.uniqueEmail:
          dispatch({ type: ServerCodes.emailExist });
          break;
        case errorsFound.uniquePhone:
          dispatch({ type: ServerCodes.phoneExist });
          break;
        case errorsFound.otherAccess:
          dispatch({ type: ServerCodes.otherAccess });
          break;
        default:
          addToast({
            data: {
              title: `${t('common.somethingWentWrong')}`,
              type: ToastTypes.error,
            },
            type: GlobalToastTypeNames.UserEdit,
          });
      }
    }
  }, [data?.updateUserSettings?.customErrors, addToast, t]);

  const editUser = useCallback(
    (newUserData: IUser) => {
      updateUserMutation({
        variables: {
          pk: String(newUserData.pk),
          firstName: newUserData.firstName,
          middleName: newUserData.middleName,
          lastName: newUserData.lastName,
          email: newUserData.email,
          phone: newUserData.phone,
          roles: (newUserData?.roles && newUserData?.roles.join(',')) || '',
        },
      });
    },
    [updateUserMutation],
  );

  return [
    editUser,
    {
      user: user || undefined,
      getUserLoading,
      loading,
      success: Boolean(data?.updateUserSettings?.user) || false,
      errors,
    },
  ];
};
