import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationRu from './locales/ru/translation.json';
import translationPl from './locales/pl/translation.json';

i18n.use(initReactI18next).init({
  lng: process.env.REACT_APP_LANG || 'ru',
  fallbackLng: process.env.REACT_APP_LANG || 'ru',
  resources: {
    ru: {
      translation: translationRu,
    },
    pl: {
      translation: translationPl,
    },
  },
});

export default i18n;
