import React, { ReactNode, useState } from 'react';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Button } from 'components/atoms';

import { Colors } from 'services/ColorService';
import { DateService } from 'services/DateService';
import moment, { Moment } from 'moment';

export interface IDateWithPicker {
  value?: Date;
  disabledDate?: Date;
  onSave: (newDate: Date) => void;
}

export const DateWithPicker = ({
  value,
  onSave,
  disabledDate,
  ...rest
}: IDateWithPicker) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isMouseInside, setMousePosition] = useState(false);
  const [lessonDate, setLessonDate] = useState<Date | null>(null);

  return (
    <StyledDatePicker
      panelRender={(PanelRender: ReactNode) => (
        <div
          onMouseLeave={() => setMousePosition(false)}
          onMouseEnter={() => setMousePosition(true)}
        >
          <PanelWrapper>{PanelRender}</PanelWrapper>
        </div>
      )}
      value={(lessonDate && moment(lessonDate)) || (value && moment(value))}
      onChange={(newDate: Moment | null) =>
        newDate && setLessonDate(newDate?.toDate())
      }
      format="DD MMM (ddd)"
      bordered={false}
      suffixIcon={null}
      disabled={DateService.isAfter(new Date(), value || new Date())}
      disabledDate={(date: Date) =>
        disabledDate ? DateService.isSameOrAfter(disabledDate, date) : false
      }
      open={isOpen}
      onOpenChange={(open: boolean) => {
        if (!isMouseInside) {
          setIsOpen(open);

          if (!open) {
            setLessonDate(null);
          }
        }
      }}
      renderExtraFooter={() => (
        <SaveButton
          colors={{
            backgroundColor: Colors.Blue,
            color: Colors.White,
          }}
          onClick={() => {
            setIsOpen(false);

            if (lessonDate) {
              onSave(lessonDate);
            }

            setLessonDate(null);
          }}
        >
          {t('button.save')}
        </SaveButton>
      )}
      showToday={false}
      {...rest}
    />
  );
};

const PanelWrapper = styled.div`
  position: absolute;
  left: -102px;
  top: 40px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(136, 149, 158, 0.08);

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: -10px;
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${Colors.White};
  }
`;

const StyledDatePicker = styled((props) => <DatePicker {...props} />)`
  cursor: pointer;
  height: 24px;
  width: 128px;

  .ant-picker-input > input {
    font-weight: 700;
  }

  &.ant-picker-focused {
    .ant-picker-input > input {
      color: ${Colors.Blue};
    }
  }

  .ant-picker-clear {
    display: none;
  }
`;

const SaveButton = styled((props) => <Button small {...props} />)`
  height: 30px;
  width: 102px;
  min-height: auto;
  margin: 8px 0 8px auto;
  font-family: Roboto;
  font-size: 14px;
  line-height: 22px;
`;
