import * as React from 'react';
import { memo, useCallback, useMemo } from 'react';

import styled, { css } from 'styled-components';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { handleFinancialNumberValue } from 'services/NumberService';

export interface IInput extends IInputStyle {
  value: string;
  placeholder?: string;
  type: 'number' | 'text' | 'password' | 'email';
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onEnter?: (value: string) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  max?: number;
  financialNumber?: boolean;
}

export interface IInputStyle {
  color?: string;
  disabled?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
}

export const Input = memo(
  ({
    value,
    name,
    onChange,
    onEnter,
    onFocus,
    onBlur,
    max,
    financialNumber,
    ...rest
  }: IInput) => {
    const onKeyDownHandler = useCallback(
      ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
        if (key === 'Enter') {
          if (onEnter) onEnter(value);
        }
      },
      [value, onEnter],
    );

    const onFinancialNumberBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        const currentValue = event.target.value;
        const newValue = handleFinancialNumberValue(currentValue);

        if (newValue !== currentValue) {
          onChange({
            ...event,
            target: {
              ...event.target,
              value: newValue || '',
            },
          });
        }
      },
      [onChange],
    );

    const onBlurHandler:
      | React.FocusEventHandler<HTMLInputElement>
      | undefined = useMemo(() => {
      if (onBlur) {
        return onBlur;
      }

      if (financialNumber) {
        return onFinancialNumberBlur;
      }
    }, [financialNumber, onBlur, onFinancialNumberBlur]);

    return (
      <InputStyled
        value={value}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlurHandler}
        maxLength={max}
        onKeyDown={onKeyDownHandler}
        {...rest}
      />
    );
  },
);

const InputStyled = styled.input<IInputStyle>`
  width: 100%;
  padding: 11px 16px;
  box-sizing: border-box;
  background-color: ${Colors.White};
  border-width: 1px;
  border-style: solid;
  ${({ hasError, hasWarning }) => {
    if (hasError) {
      return css`
        border-color: ${Colors.Red};
      `;
    }

    if (hasWarning) {
      return css`
        border-color: ${Colors.WarningDefault};
      `;
    }

    return css`
      border-color: ${Colors.LightGray};
    `;
  }}
  color: ${({ color }) => color || Colors.Black};
  font-family: ${getFontFamily('regular')};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  outline: none;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        color: ${Colors.Black};
        background-color: ${Colors.LightBlue};
        user-select: none;
        cursor: default;
      `;
    }
  }}

  &::placeholder {
    color: ${Colors.Gray};
    opacity: 1;
  }
`;
