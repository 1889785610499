import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';

import { PopupNames } from 'core';
import {
  usePopupStateOperations,
  useCountriesOptions,
  useGetTeacherTypesData,
  useTeacherTypesHelpers,
  useGetTeacherTypes,
} from 'core/hooks';

import { ServerCodes, TeacherHireType } from 'types';
import { TEACHER_TYPE_OPTIONS } from 'components/constants';
import {
  TeacherAddTypePopupComponent,
  ITeacherAddTypeValues,
} from './TeacherAddTypePopup';

export const TeacherAddTypePopup = memo(() => {
  const { values: savedValues, user, code } = useGetTeacherTypesData();

  const { getTeacherTypes, data: existingTeacherTypes } = useGetTeacherTypes();

  useEffect(() => {
    if (code === ServerCodes.teacherExist && user?.id) {
      getTeacherTypes(user.id);
    }
  }, [user, code, getTeacherTypes]);

  const {
    operations: { showPopup },
  } = usePopupStateOperations();

  const { countries } = useCountriesOptions();

  const onSubmit = useCallback(
    (values: ITeacherAddTypeValues) => {
      const employmentTypes = values.teacherTypes.reduce((acc, cur) => {
        if (cur.type) {
          return {
            ...acc,
            [cur.type.value]: cur[cur.type.value as keyof typeof cur],
          };
        }
        return {};
      }, {});

      showPopup(PopupNames.addApplicationPopup, {
        types: employmentTypes,
        user,
        code,
      });
    },
    [showPopup, code, user],
  );

  const { values, handleSubmit, setValues, setFieldValue } = useFormik({
    initialValues: savedValues || {
      teacherTypes: Array(1)
        .fill({
          [TeacherHireType.Le]: {},
          [TeacherHireType.Ie]: {},
          [TeacherHireType.Np]: {},
          [TeacherHireType.Se]: {},
          type: null,
        })
        .map((item, index) => ({ ...item, id: index })),
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!existingTeacherTypes.length) {
      return;
    }

    const newValues: ITeacherAddTypeValues = {
      teacherTypes: existingTeacherTypes.map((existingType, index) => ({
        [TeacherHireType.Le]: {},
        [TeacherHireType.Ie]: {},
        [TeacherHireType.Np]: {},
        [TeacherHireType.Se]: {},
        ...existingType,
        type:
          TEACHER_TYPE_OPTIONS.find(
            (typeOption) => typeOption.value === Object.keys(existingType)[0],
          ) || null,
        id: String(index),
        disabled: true,
      })),
    };

    setValues(newValues);
  }, [existingTeacherTypes, setValues]);

  const {
    disabledButton,
    teacherTypesOptions,
    onAddType,
    onDeleteType,
    handleChange,
    onClose,
  } = useTeacherTypesHelpers({ values, user, code, setFieldValue });

  return (
    <TeacherAddTypePopupComponent
      values={values}
      countries={countries}
      teacherTypesOptions={teacherTypesOptions}
      errors={null}
      errorMessage=""
      disabledButton={disabledButton}
      onInputChange={handleChange}
      handleSubmit={handleSubmit}
      onClose={onClose}
      onAddType={onAddType}
      onDeleteType={onDeleteType}
    />
  );
});
