import React, { memo } from 'react';
import styled from 'styled-components';
import { ValueType, OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';

import { Input, InputLabel, InputPhone } from 'components/atoms';
import { ErrorsType } from 'types';

type InputHandler<T> = (value: T, inputName: string) => void;

interface IStudentForm {
  values: IStudentFormValues;
  errors?: ErrorsType<IStudentFormValues> | null;
  onChangeInput: InputHandler<
    string | boolean | ValueType<OptionTypeBase, false>
  >;
}

export interface IStudentFormValues {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
}

export const StudentForm = memo(
  ({ values, errors, onChangeInput }: IStudentForm) => {
    const { t } = useTranslation();
    return (
      <Container>
        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.lastName)}>
            {t('studentPopup.fields.lastName.label')}
          </InputLabel>
          <Input
            placeholder={t('studentPopup.fields.lastName.placeholder')}
            type="text"
            value={values.lastName}
            name="lastName"
            onChange={({ target: { value } }) =>
              onChangeInput(value, 'lastName')
            }
            hasError={Boolean(errors?.lastName)}
          />
        </WrapperInput>

        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.firstName)}>
            {t('studentPopup.fields.firstName.label')}
          </InputLabel>
          <Input
            placeholder={t('studentPopup.fields.firstName.placeholder')}
            type="text"
            value={values.firstName}
            name="firstName"
            onChange={({ target: { value } }) =>
              onChangeInput(value, 'firstName')
            }
            hasError={Boolean(errors?.firstName)}
          />
        </WrapperInput>

        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.middleName)}>
            {t('studentPopup.fields.middleName.label')}
          </InputLabel>
          <Input
            placeholder={t('studentPopup.fields.middleName.placeholder')}
            type="text"
            value={values.middleName}
            name="middleName"
            onChange={({ target: { value } }) =>
              onChangeInput(value, 'middleName')
            }
            hasError={Boolean(errors?.middleName)}
          />
        </WrapperInput>

        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.email)}>
            {t('studentPopup.fields.email.label')}
          </InputLabel>
          <Input
            placeholder={t('studentPopup.fields.email.placeholder')}
            type="email"
            value={values.email}
            name="email"
            onChange={({ target: { value } }) => onChangeInput(value, 'email')}
            hasError={Boolean(errors?.email)}
          />
        </WrapperInput>

        <WrapperInput>
          <InputLabel hasError={Boolean(errors?.phone)}>
            {t('studentPopup.fields.phone.label')}
          </InputLabel>
          <InputPhone
            placeholder={t('studentPopup.fields.phone.placeholder')}
            value={values.phone}
            name="phone"
            onChange={({ target: { value } }) => onChangeInput(value, 'phone')}
            hasError={Boolean(errors?.phone)}
          />
        </WrapperInput>
      </Container>
    );
  },
);

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  margin-top: 32px;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
`;
