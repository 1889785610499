import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';

import { useGetDebtors } from 'core/hooks';

import { DebtorsTable } from 'molecules';

interface IDebtorsTablePageWrapper {
  searchValue?: string;
  contributionNum?: number | undefined;
}

export const DebtorsTablePageWrapper = memo(
  ({ searchValue, contributionNum }: IDebtorsTablePageWrapper) => {
    const [
      getContributions,
      fetchMoreDebtors,
      { data, error, loading, hasMore },
    ] = useGetDebtors();

    useEffect(() => {
      getContributions({ query: searchValue, contributionNum });
    }, [searchValue, contributionNum, getContributions]);

    const onLoadMore = useCallback(() => {
      fetchMoreDebtors({ query: searchValue, contributionNum });
    }, [searchValue, contributionNum, fetchMoreDebtors]);

    return (
      <DebtorsTable
        data={data}
        loading={loading}
        error={error}
        loadMore={onLoadMore}
        hasMore={hasMore}
      />
    );
  },
);
