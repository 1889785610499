import { useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { AccountsPayableType, Currencies } from 'types';
import { popupData, PopupNames, useAccountsPayablesLazyQuery } from 'core';

type useAccountPayablesType = {
  accountsPayables: AccountsPayableType[];
  loading: boolean;
};

export const useAccountsPayables = (
  isEditing = false,
): useAccountPayablesType => {
  const agreementData = useReactiveVar(popupData)[
    isEditing ? PopupNames.editAgreement : PopupNames.studentPayment
  ];
  const [
    loadAccountsPayables,
    { data, loading },
  ] = useAccountsPayablesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const accountsPayables = useMemo(
    () =>
      data?.student?.accountsPayables?.reduce(
        (result: AccountsPayableType[], accountsPayable) => {
          if (accountsPayable && !accountsPayable.repaid) {
            return [
              ...result,
              {
                value: accountsPayable.id,
                label: accountsPayable.startedAgreement.agreementTitle,
                amount: accountsPayable.amountForeign?.toString() || '',
                currency: {
                  value: Currencies[accountsPayable.currency],
                  label: Currencies[accountsPayable.currency],
                },
              },
            ];
          }

          return result;
        },
        [],
      ) || [],
    [data?.student?.accountsPayables],
  );

  useEffect(() => {
    if (agreementData?.studentId) {
      loadAccountsPayables({ variables: { id: agreementData.studentId } });
    }
  }, [agreementData?.studentId, loadAccountsPayables]);

  return { accountsPayables, loading };
};
