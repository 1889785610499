import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Button } from 'atoms';
import { PopupContainer } from 'molecules';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';
import { transFormAlignProp } from 'services/TransformService';
import { PublishedLmsType } from 'types';

export interface IRemoveGroupFromLMSPopup
  extends IRemoveGroupFromLMSPopupStyle {
  onClose: () => void;
  onConfirm: () => void;
  groupName: string;
  publishedLmsType?: PublishedLmsType;
}

export interface IRemoveGroupFromLMSPopupStyle {
  width?: string;
}

export const RemoveGroupFromLMSPopupComponent = memo(
  ({
    onClose,
    onConfirm,
    width,
    groupName,
    publishedLmsType,
  }: IRemoveGroupFromLMSPopup) => {
    const { t } = useTranslation();
    return (
      <PopupContainer
        title={t('removeGroupPopup.removeLms')}
        width={width}
        onClose={onClose}
      >
        <WrapperText>
          <Text>
            {t('removeGroupPopup.youAreGoingToRemove')} “
            <BoldText>{groupName}</BoldText>”
            {publishedLmsType === PublishedLmsType.methodist
              ? t('removeGroupPopup.ifYouConfirmMethodist')
              : publishedLmsType === PublishedLmsType.teacher
              ? t('removeGroupPopup.ifYouConfirmTeacher')
              : t('removeGroupPopup.ifYouConfirmStudent')}
          </Text>
        </WrapperText>

        <WrapperRow align="right">
          <WrapperDeleteButton>
            <Button
              onClick={onClose}
              variant="outline"
              colors={{
                backgroundColor: Colors.White,
                color: Colors.Blue,
              }}
            >
              {t('removeGroupPopup.button.cancel')}
            </Button>
          </WrapperDeleteButton>

          <WrapperSendButton>
            <Button
              onClick={onConfirm}
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
              }}
            >
              {t('removeGroupPopup.button.remove')}
            </Button>
          </WrapperSendButton>
        </WrapperRow>
      </PopupContainer>
    );
  },
);

const WrapperRow = styled.div<{
  margin?: string;
  wrap?: boolean;
  align?: 'left' | 'right' | 'center';
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: ${({ align }) => (align ? transFormAlignProp(align) : 'center')};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  margin: ${({ margin }) => margin || 'initial'};
`;

const WrapperText = styled.div`
  margin: 0 0 32px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`;

const WrapperDeleteButton = styled.div`
  margin: 0 16px 0 auto;
  width: 122px;
`;

const WrapperSendButton = styled.div`
  width: 144px;
`;

const BoldText = styled.span`
  font-family: ${getFontFamily('bold')};
`;

const Text = styled.p`
  margin-top: 24px;
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
`;
