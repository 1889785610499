import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

export interface ITableBody {
  children: React.ReactNode;
  className?: string;
}

export const TableBody = memo(
  ({ children, className, ...props }: ITableBody) => (
    <TableBodyStyle className={className} {...props}>
      {children}
    </TableBodyStyle>
  ),
);

const TableBodyStyle = styled.tbody`
  display: inherit;
  flex-direction: inherit;
  box-sizing: inherit;
`;
