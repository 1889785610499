import { PlannedYearContributionsQuery } from 'core';
import { IPlannedContribution } from 'types';
import { MONTH_NAMES } from 'components/constants';

export const transformPlannedYearContributions = (
  data: PlannedYearContributionsQuery,
): IPlannedContribution[] => {
  const budget =
    data.financeBudget?.map((month) => ({
      period: month?.month,
      factByn: month?.BYN || 0,
      factRub: month?.RUB || 0,
      factUsd: month?.USD || 0,
      factEur: month?.EUR || 0,
      factPln: month?.PLN || 0,
    })) || [];

  const plan = data.financePlan?.map((month) => ({
    period: month?.month,
    planByn: month?.BYN || 0,
    planRub: month?.RUB || 0,
    planUsd: month?.USD || 0,
    planEur: month?.EUR || 0,
    planPln: month?.PLN || 0,
  }));

  const result =
    plan?.map((month, index) => ({
      ...month,
      ...budget[index],
      period: MONTH_NAMES[month.period ? month.period - 1 : index],
    })) || [];

  return result;
};
