import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Form, Heading, Input, InputLabel } from 'atoms';

import { ErrorsType } from 'types';
import { Colors } from 'services/ColorService';
import { PopupErrors } from '../PopupErrors';

export interface ILoginForm extends ILoginFormStyle {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onChangeInput: (value: string, inputName: string) => void;
  forgotPasswordOnClick: () => void;
  values: ILoginFormValues;
  loading?: boolean;
  errors?: ErrorsType<ILoginFormValues> | null;
  errorsMessage?: string | null;
}

export interface ILoginFormStyle {
  width?: string;
}

export interface IWrapper {
  margin?: string;
  width?: string;
}

export interface ILoginFormValues {
  email: string;
  password: string;
}

export const LoginFormComponent = memo(
  ({
    handleSubmit,
    forgotPasswordOnClick,
    onChangeInput,
    values,
    errors,
    errorsMessage,
    width,
    loading = false,
  }: ILoginForm) => {
    const { t } = useTranslation();
    return (
      <LoginFormStyled width={width}>
        <Wrapper margin="0 0 40px 0">
          <Heading>{t('loginForm.authorization')}</Heading>
        </Wrapper>
        <Form onSubmit={handleSubmit}>
          <Wrapper>
            <InputLabel hasError={Boolean(errors?.email)}>
              {t('loginForm.fields.email.label')}
            </InputLabel>
            <Input
              type="text"
              placeholder={t('loginForm.fields.email.placeholder')}
              value={values.email}
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'email')
              }
              name="email"
              hasError={Boolean(errors?.email)}
            />
          </Wrapper>
          <Wrapper margin="24px 0 0 0">
            <InputLabel
              hasError={Boolean(errors?.password)}
              extraLink={{
                title: `${t('loginForm.button.forgotPass')}`,
                onClick: forgotPasswordOnClick,
              }}
            >
              {t('loginForm.fields.password.label')}
            </InputLabel>
            <Input
              type="password"
              placeholder={t('loginForm.fields.password.placeholder')}
              value={values.password}
              onChange={({ target: { value } }) =>
                onChangeInput(value, 'password')
              }
              name="password"
              max={30}
              hasError={Boolean(errors?.password)}
            />
          </Wrapper>
          <PopupErrorsStyled>
            <PopupErrors>{errorsMessage}</PopupErrors>
          </PopupErrorsStyled>
          <Wrapper margin="40px 0 0 0">
            <Button
              type="submit"
              loading={loading}
              colors={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
              }}
            >
              {t('loginForm.button.enter')}
            </Button>
          </Wrapper>
        </Form>
      </LoginFormStyled>
    );
  },
);

const LoginFormStyled = styled.div<ILoginFormStyle>`
  width: ${({ width }) => width || '100%'};
  box-sizing: border-box;
  padding: 48px 64px 80px 64px;
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Wrapper = styled.div<IWrapper>`
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin || 'initial'};
`;

const PopupErrorsStyled = styled.div`
  margin: 32px 0 0 0;
`;
