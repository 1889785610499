import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

import { LoadingIndicator } from 'atoms';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

interface ILoadingDataProps {
  className?: string;
}

export const LoadingData = memo(({ className }: ILoadingDataProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper className={className}>
      <LoadingIcon />
      <Text>{t('loadingData')}</Text>
    </Wrapper>
  );
});

const Wrapper = styled.div<{ className?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  user-select: none;

  ${({ className }) => {
    if (!className) {
      return css`
        margin-top: 32px;
      `;
    }
  }}
`;

const LoadingIcon = styled((props) => <LoadingIndicator {...props} />)`
  height: 24px;
  width: 24px;
`;

const Text = styled.span`
  margin-left: 16px;
  font-family: ${getFontFamily('regular')};
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.Gray};
`;
