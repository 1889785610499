import { css } from 'styled-components';

export enum FontFamily {
  Montserrat = 'Montserrat',
}

export type FontFamilyWeight =
  | 'regular'
  | 'bold'
  | 'semibold'
  | 'light'
  | 'heavy'
  | 'medium';

interface IFont {
  fontFamily?: FontFamily;
  familyWeight?: FontFamilyWeight;
  fontWeight?: number;
  fontSize: number;
  lineHeight: number;
}

const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1);

export const getFontFamily = (weight?: FontFamilyWeight) => {
  if (weight) {
    return `${FontFamily.Montserrat}-${capitalize(weight)}`;
  }

  return `${FontFamily.Montserrat}-Medium`;
};

export const getFontFamilyForAll = (
  fontFamily: FontFamily,
  weight?: FontFamilyWeight,
) => {
  if (fontFamily === FontFamily.Montserrat) {
    return css`
      font-family: ${getFontFamily(weight)};
    `;
  }

  if (weight) {
    return css`
      font-family: ${fontFamily}-${capitalize(weight)};
    `;
  }

  return css`
    font-family: ${fontFamily};
  `;
};

export const getFontWeight = (fontWeight: number = 400) => css`
  font-weight: ${fontWeight};
`;

export const getFontSize = (fontSize: number) => css`
  font-size: ${fontSize}px;
`;

export const getLineHeight = (lineHeight: number) => css`
  line-height: ${lineHeight}px;
`;

export const getFont = ({
  fontFamily = FontFamily.Montserrat,
  familyWeight,
  fontWeight,
  fontSize,
  lineHeight,
}: IFont) => css`
  ${getFontFamilyForAll(fontFamily, familyWeight)};
  ${getFontWeight(fontWeight)};
  ${getFontSize(fontSize)};
  ${getLineHeight(lineHeight)};
`;
