import { useMemo } from 'react';
import { useFinanceYearsQuery } from 'core';
import { transformFinanceYears } from './transform';

type GetFinanceYearsHookType = {
  data: number[] | undefined;
  loading: boolean;
};

export const useGetFinanceYears = (): GetFinanceYearsHookType => {
  const { data, loading } = useFinanceYearsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const result: number[] | undefined = useMemo(
    () => transformFinanceYears(data),
    [data],
  );

  return {
    data: result,
    loading,
  };
};
