import * as React from 'react';
import { memo, useCallback, useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { PopupNames } from 'core';
import { usePopupStateOperations } from 'core/hooks';

import { IStudent, IStudentAgreement } from 'types';

import {
  DebtorsTableComponent,
  IStudentsColumn,
  IStudentsRow,
} from './StudentsTable';

export interface IStudentsTable {
  data: IStudent[];
  loading?: boolean;
  error?: string;
  loadMore: () => void;
  hasMore: boolean;
}

const getColumnsSource = (t: TFunction): IStudentsColumn[] => [
  {
    Header: `${t('table.students.name')}`,
    accessor: 'name',
    flexible: true,
  },
  {
    Header: `${t('table.students.contractTitle')}`,
    accessor: 'contractTitle',
    columnWidth: '160px',
    marginRight: '48px',
    marginLeft: '48px',
  },
  {
    Header: `${t('table.students.group')}`,
    accessor: 'group',
    columnWidth: '80px',
  },
  {
    Header: `${t('table.students.phone')}`,
    accessor: 'phone',
    columnWidth: '128px',
    marginRight: '48px',
    marginLeft: '48px',
  },
  {
    Header: `${t('table.students.budgetAmount')}`,
    accessor: 'budgetAmount',
    columnWidth: '112px',
    marginRight: '48px',
  },
  {
    Header: `${t('table.students.paidAmount')}`,
    accessor: 'paidAmount',
    columnWidth: '112px',
    marginRight: '112px',
  },
];

export const StudentsTable = memo(
  ({ data, loading, error, loadMore, hasMore }: IStudentsTable) => {
    const { t } = useTranslation();
    const columns: IStudentsColumn[] = useMemo(() => getColumnsSource(t), [t]);
    const rows: IStudentsRow[] = useMemo(
      () => data.map((debtor, idx) => ({ ...debtor, num: idx + 1 })),
      [data],
    );
    const {
      operations: { showPopup },
    } = usePopupStateOperations();

    const onClickEdit = useCallback(
      ({ agreementId, studentId }: IStudentAgreement) => {
        showPopup(PopupNames.editAgreement, { agreementId, studentId });
      },
      [showPopup],
    );

    const onClickAddAgreement = useCallback(
      (studentId) => {
        showPopup(PopupNames.studentPayment, { studentId });
      },
      [showPopup],
    );

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <DebtorsTableComponent
        rows={rows}
        columns={columns}
        isFlexible
        noDataText={t('table.students.noData')}
        loadMore={loadMore}
        hasMore={hasMore}
        loading={loading}
        onClickEdit={onClickEdit}
        onClickAddAgreement={onClickAddAgreement}
      />
    );
  },
);
