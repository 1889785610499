import * as React from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { CoursesType } from 'components/types';
import { CoursesTableComponent, IColumn, IRow } from './CoursesTable';

export interface ICoursesTable {
  courses: CoursesType[];
  loading?: boolean;
  error?: string;
  hasMore: boolean;
  loadMore: () => void;
}

const getColumnsSource = (t: TFunction): IColumn[] => [
  {
    Header: `${t('table.courses.courseName')}`,
    accessor: 'name',
    flexible: true,
  },
  {
    Header: `${t('table.courses.educationForm')}`,
    accessor: 'educationForm',
    columnWidth: '160px',
    marginRight: '80px',
    marginLeft: '80px',
  },
  {
    Header: `${t('table.courses.category')}`,
    accessor: 'categoryName',
    flexible: true,
    marginRight: '120px',
  },
];

export const CoursesTable = memo(
  ({ courses, loading, error, hasMore, loadMore }: ICoursesTable) => {
    const { t } = useTranslation();
    const columns: IColumn[] = useMemo(() => getColumnsSource(t), [t]);
    const rows: IRow[] = useMemo(() => courses, [courses]);

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <CoursesTableComponent
        rows={rows}
        columns={columns}
        isFlexible
        noDataText={t('table.courses.noData')}
        loadMore={loadMore}
        hasMore={hasMore}
        loading={loading}
      />
    );
  },
);
