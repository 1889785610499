import { PATHS } from 'router/config';
import { MenuItems, UserRole } from 'types';
import i18n from '../i18n';

type AlignTypes = 'left' | 'center' | 'right';

export const transFormAlignProp = (align?: AlignTypes) => {
  switch (align) {
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};

export const getKeys = <O extends {}>(object: O) =>
  Object.keys(object) as Array<keyof O>;

export const transformMenuItemToPath = (menuItem?: MenuItems): PATHS => {
  switch (menuItem) {
    case MenuItems.Debtors:
      return PATHS.debtors;
    case MenuItems.Settings:
      return PATHS.settings;
    case MenuItems.Monitoring:
      return PATHS.monitoring;
    case MenuItems.Finances:
      return PATHS.finances;
    default:
      return PATHS.default;
  }
};

export const gluePaths = (firstPath: string, secondPath: string) => {
  const gluedPath = `${firstPath}/${secondPath}`;

  if (gluedPath.includes('//')) {
    gluedPath.replace('//', '/');
  }

  return gluedPath;
};

export const translateRoleToValue = (role: string): string => {
  switch (role) {
    case UserRole.TEACHER:
      return `${i18n.t('common.roles.teacher')}`;
    case UserRole.STUDENT:
      return `${i18n.t('common.roles.student')}`;
    case UserRole.ACCOUNTANT:
      return `${i18n.t('common.roles.accountant')}`;
    case UserRole.ADMIN:
      return `${i18n.t('common.roles.admin')}`;
    case UserRole.FINANCIER:
      return `${i18n.t('common.roles.financier')}`;
    case UserRole.HRMANAGER:
      return `${i18n.t('common.roles.hrManager')}`;
    case UserRole.SALESMANAGER:
      return `${i18n.t('common.roles.saleManager')}`;
    case UserRole.METHODIST:
      return `${i18n.t('common.roles.methodist')}`;
    default:
      return role;
  }
};

export const translateValueToRole = (value: string): string => {
  switch (value) {
    case `${i18n.t('common.roles.teacher')}`:
      return UserRole.TEACHER;
    case `${i18n.t('common.roles.student')}`:
      return UserRole.STUDENT;
    case `${i18n.t('common.roles.accountant')}`:
      return UserRole.ACCOUNTANT;
    case `${i18n.t('common.roles.admin')}`:
      return UserRole.ADMIN;
    case `${i18n.t('common.roles.financier')}`:
      return UserRole.FINANCIER;
    case `${i18n.t('common.roles.hrManager')}`:
      return UserRole.HRMANAGER;
    case `${i18n.t('common.roles.saleManager')}`:
      return UserRole.SALESMANAGER;
    case `${i18n.t('common.roles.methodist')}`:
      return UserRole.METHODIST;
    default:
      return value;
  }
};
