import { useCallback } from 'react';

import {
  useChangeDateOfLessonMutation,
  ChangeDateOfLessonMutationVariables,
} from 'core/graphql';
import { JournalQuery } from 'core/operations';
import { useParseServerErrors } from 'services/ErrorsService';

type ChangeLessonDateType = [
  (variables: ChangeDateOfLessonMutationVariables) => void,
  {
    success: boolean;
    loading: boolean;
  },
];

export const useChangeLessonDate = (groupId: string): ChangeLessonDateType => {
  const [
    changeDateOfLessonMutation,
    { data, loading },
  ] = useChangeDateOfLessonMutation({
    onError: (err) => err,
    refetchQueries: [
      {
        query: JournalQuery,
        variables: {
          groupId,
        },
      },
    ],
  });

  useParseServerErrors(data?.changeLessonDates?.customErrors);

  const changeLessonDate = useCallback(
    (variables: ChangeDateOfLessonMutationVariables) =>
      changeDateOfLessonMutation({
        variables,
      }),
    [changeDateOfLessonMutation],
  );

  return [
    changeLessonDate,
    {
      success: Boolean(data?.changeLessonDates?.success),
      loading,
    },
  ];
};
