import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Colors } from 'services/ColorService';

import { Icon, IconsNames, Title } from 'atoms';

export const ContactSupport = memo(() => {
  const { t } = useTranslation();
  return (
    <Wrapper
      href="https://t.me/+TNNwDAOnFAZiZWRi"
      target="_blank"
      rel="noopener noreferrer"
    >
      <TitleStyled text={t('contactSupport')} position="left" />
      <IconStyled name={IconsNames.Messaging} />
    </Wrapper>
  );
});

const TitleStyled = styled((props) => <Title {...props} />)`
  display: none;
`;

const Wrapper = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  border-radius: 100%;
  box-shadow: 0px 4px 24px rgba(13, 83, 252, 0.4);
  background-color: ${Colors.Blue};
  cursor: pointer;

  &:hover > ${TitleStyled} {
    display: block;
  }
`;

const IconStyled = styled(Icon)`
  height: 27px;
  width: 28px;
`;
