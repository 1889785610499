import React, { memo, useCallback } from 'react';

import { PopupNames } from 'core';
import { usePopupData, usePopupStateOperations } from 'core/hooks';

import { CommentPopupComponent } from './CommentPopup';

export const CommentPopup = memo(() => {
  const {
    operations: { hidePopup },
  } = usePopupStateOperations();
  const onClose = useCallback(() => hidePopup(PopupNames.commentPopup), [
    hidePopup,
  ]);

  const popupData = usePopupData(PopupNames.commentPopup);

  return (
    <CommentPopupComponent
      title={popupData.title}
      comment={popupData.comment}
      onClose={onClose}
    />
  );
});
